import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
sena = new Array();
latitude = 5.2324649;
longitude = -73.2988174;
zoom = 6;
  constructor(public global: GlobalService) {
    this.global.initData();
   }

  ngOnInit(): void {
    const pdata9 = { option: 'sena'};
    console.log('SEDES', pdata9);
    this.global.consultar(pdata9, (err9, response9) => {
    console.log('Datos Sedes', response9);
    this.sena = response9;
    });
  }

  VerE(item){
  }
}
