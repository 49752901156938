import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
  selector: 'app-gensa-pc-revision',
  templateUrl: './gensa-pc-revision.component.html'
})
export class GensaPcRevisionComponent implements OnInit {

  clientClass;
  call: boolean = false;
  call1: boolean = false;
  openFlag: boolean = false;
  edit: boolean = false;
  formData: any[] = [];
  photosForm: any[] = [];
  srcImg: any[] = [];
  fotoUrl: any[] = [];
  Photo;
  evidence: boolean = false;
  ext;
  Nombre;
  TipoDoc;
  NumeroDoc;
  Telefono;
  Email;
  Ocupacion;
  Operador;
  Cobertura;
  Depto;
  Municipio;
  Vereda;
  Descripcion;
  U_latitud;
  U_longitud;
  U_altitud;
  Marca;
  Referencia;
  Serial;
  Tipo;
  Estado;
  Vivienda;
  Material;
  Otro_V;
  Uso;
  Ubicacion;
  Otro_U;
  Apartado;
  Altura;
  Cableado;
  Caja;
  Equipo_M;
  Riesgo;
  Pago_Consumo;
  Esquema;
  Calidad;
  Percepcion;
  UltimaLectura;
  NuevoMedidor;
  Credito;
  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.spinner.show();
    console.log(this.global.nuevoMed);
    if (this.global.idBenefit == undefined || this.global.idForm == undefined || this.global.nuevoMed == undefined) {
      this.route.navigateByUrl('/gensa-puerto-carreno');
      this.spinner.hide();
    } else {
      setTimeout(() => {
        this.clientClass = this.global.CLIENTDATALIWA;
        try {
          const pdata5 = { option: 'form_puerto_carreno', idBeneficiario: this.global.idBenefit, NuevoMedidor: this.global.nuevoMed };
          this.global.consultar(pdata5, (err5, response5) => {
            console.log('Datos form-puertocarreno', response5);
            this.formData = response5;
            const pdata4 = { option: 'fotos_puerto_carreno', idBeneficiario: this.global.idBenefit };
            this.global.consultar(pdata4, (err, response4) => {
              console.log('Datos fotos_puerto_carreno', response4);
              this.photosForm = response4;
              this.openFlag = true;
              this.spinner.hide();
            });
          });
        } catch (error) {
          console.log(error);
        }
      }, 500);
    }
  }

  animatedTab(numbr: number) {
    switch (numbr) {
      case 1:
        this.call = !this.call;
        break;
      case 2:
        this.call1 = !this.call1;
        break;

      default:
        break;
    }
  }

  editModule(numb: number) {
    switch (numb) {
      case 0:
        this.edit = !this.edit;
        this.Nombre = this.formData[0][9];
        this.TipoDoc = this.formData[0][10];
        this.NumeroDoc = this.formData[0][11];
        this.Telefono = this.formData[0][12];
        this.Email = this.formData[0][13];
        this.Ocupacion = this.formData[0][15];
        this.Operador = this.formData[0][16];
        this.Cobertura = this.formData[0][17];
        this.Depto = this.formData[0][18];
        this.Municipio = this.formData[0][19];
        this.Vereda = this.formData[0][20];
        this.Descripcion = this.formData[0][21];
        this.U_latitud = this.formData[0][6];
        this.U_longitud = this.formData[0][7];
        this.U_altitud = this.formData[0][8];
        this.Marca = this.formData[0][22];
        this.Referencia = this.formData[0][23];
        this.Serial = this.formData[0][24];
        this.Tipo = this.formData[0][25];
        this.Estado = this.formData[0][26];
        this.Vivienda = this.formData[0][27];
        this.Material = this.formData[0][28];
        this.Otro_V = this.formData[0][29];
        this.Uso = this.formData[0][30];
        this.Ubicacion = this.formData[0][31];
        this.Otro_U = this.formData[0][32];
        this.Apartado = this.formData[0][33];
        this.Altura = this.formData[0][34];
        this.Cableado = this.formData[0][35];
        this.Caja = this.formData[0][36];
        this.Equipo_M = this.formData[0][37];
        this.Riesgo = this.formData[0][38];
        this.Pago_Consumo = this.formData[0][39];
        this.Esquema = this.formData[0][40];
        this.Calidad = this.formData[0][41];
        this.Percepcion = this.formData[0][42];
        this.UltimaLectura = this.formData[0][45];
        this.NuevoMedidor = this.formData[0][44];
        this.Credito = this.formData[0][43];
        break;

      default:
        break;
    }
  }
  confirmEditForm() {
    if (this.Nombre == undefined || this.TipoDoc == undefined || this.NumeroDoc == undefined ||
      this.Telefono == undefined || this.Email == undefined || this.Ocupacion == undefined ||
      this.Operador == undefined || this.Cobertura == undefined || this.Depto == undefined ||
      this.Municipio == undefined || this.Vereda == undefined || this.Descripcion == undefined ||
      this.U_latitud == undefined || this.U_longitud == undefined || this.U_altitud == undefined ||
      this.Marca == undefined || this.Referencia == undefined || this.Serial == undefined ||
      this.Tipo == undefined || this.Estado == undefined || this.Vivienda == undefined ||
      this.Material == undefined || this.Otro_V == undefined || this.Uso == undefined ||
      this.Ubicacion == undefined || this.Otro_U == undefined || this.Apartado == undefined ||
      this.Altura == undefined || this.Cableado == undefined || this.Caja == undefined ||
      this.Equipo_M == undefined || this.Riesgo == undefined || this.Pago_Consumo == undefined ||
      this.Esquema == undefined || this.Calidad == undefined || this.Percepcion == undefined ||
      this.UltimaLectura == undefined || this.NuevoMedidor == undefined || this.Credito == undefined) {
      this.alert.descErr('Verifique que no hayan campos vacíos');
      this.Nombre = this.formData[0][9];
      this.TipoDoc = this.formData[0][10];
      this.NumeroDoc = this.formData[0][11];
      this.Telefono = this.formData[0][12];
      this.Email = this.formData[0][13];
      this.Ocupacion = this.formData[0][15];
      this.Operador = this.formData[0][16];
      this.Cobertura = this.formData[0][17];
      this.Depto = this.formData[0][18];
      this.Municipio = this.formData[0][19];
      this.Vereda = this.formData[0][20];
      this.Descripcion = this.formData[0][21];
      this.U_latitud = this.formData[0][6];
      this.U_longitud = this.formData[0][7];
      this.U_altitud = this.formData[0][8];
      this.Marca = this.formData[0][22];
      this.Referencia = this.formData[0][23];
      this.Serial = this.formData[0][24];
      this.Tipo = this.formData[0][25];
      this.Estado = this.formData[0][26];
      this.Vivienda = this.formData[0][27];
      this.Material = this.formData[0][28];
      this.Otro_V = this.formData[0][29];
      this.Uso = this.formData[0][30];
      this.Ubicacion = this.formData[0][31];
      this.Otro_U = this.formData[0][32];
      this.Apartado = this.formData[0][33];
      this.Altura = this.formData[0][34];
      this.Cableado = this.formData[0][35];
      this.Caja = this.formData[0][36];
      this.Equipo_M = this.formData[0][37];
      this.Riesgo = this.formData[0][38];
      this.Pago_Consumo = this.formData[0][39];
      this.Esquema = this.formData[0][40];
      this.Calidad = this.formData[0][41];
      this.Percepcion = this.formData[0][42];
      this.UltimaLectura = this.formData[0][45];
      this.NuevoMedidor = this.formData[0][44];
      this.Credito = this.formData[0][43];
      this.edit = false;
    } else {

      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Guardados SET '
            + 'Nombre = ' + '\'' + this.Nombre + '\','
            + 'TipoDoc = ' + '\'' + this.TipoDoc + '\','
            + 'NumeroDoc = ' + '\'' + this.NumeroDoc + '\','
            + 'Telefono = ' + '\'' + this.Telefono + '\','
            + 'Email = ' + '\'' + this.Email + '\','
            + 'Ocupacion = ' + '\'' + this.Ocupacion + '\','
            + 'Operador = ' + '\'' + this.Operador + '\','
            + 'Cobertura = ' + '\'' + this.Cobertura + '\','
            + 'Ubicacion = ' + '\'' + this.Ubicacion + '\','
            + 'Depto = ' + '\'' + this.Depto + '\','
            + 'Marca = ' + '\'' + this.Marca + '\','
            + 'Municipio = ' + '\'' + this.Municipio + '\','
            + 'Vereda = ' + '\'' + this.Vereda + '\','
            + 'Descripcion = ' + '\'' + this.Descripcion + '\','
            + 'U_latitud = ' + '\'' + this.U_latitud + '\','
            + 'U_longitud = ' + '\'' + this.U_longitud + '\','
            + 'U_altitud = ' + '\'' + this.U_altitud + '\','
            + 'Marca = ' + '\'' + this.Marca + '\','
            + 'Referencia = ' + '\'' + this.Referencia + '\','
            + 'Marca = ' + '\'' + this.Marca + '\','
            + 'Serial = ' + '\'' + this.Serial + '\','
            + 'Tipo = ' + '\'' + this.Tipo + '\','
            + 'Estado = ' + '\'' + this.Estado + '\','
            + 'Vivienda = ' + '\'' + this.Vivienda + '\','
            + 'Material = ' + '\'' + this.Material + '\','
            + 'Otro_V = ' + '\'' + this.Otro_V + '\','
            + 'Uso = ' + '\'' + this.Uso + '\','
            + 'Ubicacion = ' + '\'' + this.Ubicacion + '\','
            + 'Otro_U = ' + '\'' + this.Otro_U + '\','
            + 'Tipo = ' + '\'' + this.Tipo + '\','
            + 'Apartado = ' + '\'' + this.Apartado + '\','
            + 'Altura = ' + '\'' + this.Altura + '\','
            + 'Cableado = ' + '\'' + this.Cableado + '\','
            + 'Caja = ' + '\'' + this.Caja + '\','
            + 'Equipo_M = ' + '\'' + this.Equipo_M + '\','
            + 'Estado = ' + '\'' + this.Estado + '\','
            + 'Riesgo = ' + '\'' + this.Riesgo + '\','
            + 'Pago_Consumo = ' + '\'' + this.Pago_Consumo + '\','
            + 'Esquema = ' + '\'' + this.Esquema + '\','
            + 'Calidad = ' + '\'' + this.Calidad + '\','
            + 'Percepcion = ' + '\'' + this.Percepcion + '\','
            + 'UltimaLectura = ' + '\'' + this.UltimaLectura + '\','
            + 'NuevoMedidor = ' + '\'' + this.NuevoMedidor + '\','
            + 'Credito = ' + '\'' + this.Credito + '\''
            + ' WHERE Id_Beneficiario =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          console.log(pdata1);
          this.global.consultar(pdata1, (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('Los datos se actualizaron con éxito!');
              this.Nombre = this.formData[0][9];
              this.TipoDoc = this.formData[0][10];
              this.NumeroDoc = this.formData[0][11];
              this.Telefono = this.formData[0][12];
              this.Email = this.formData[0][13];
              this.Ocupacion = this.formData[0][15];
              this.Operador = this.formData[0][16];
              this.Cobertura = this.formData[0][17];
              this.Depto = this.formData[0][18];
              this.Municipio = this.formData[0][19];
              this.Vereda = this.formData[0][20];
              this.Descripcion = this.formData[0][21];
              this.U_latitud = this.formData[0][6];
              this.U_longitud = this.formData[0][7];
              this.U_altitud = this.formData[0][8];
              this.Marca = this.formData[0][22];
              this.Referencia = this.formData[0][23];
              this.Serial = this.formData[0][24];
              this.Tipo = this.formData[0][25];
              this.Estado = this.formData[0][26];
              this.Vivienda = this.formData[0][27];
              this.Material = this.formData[0][28];
              this.Otro_V = this.formData[0][29];
              this.Uso = this.formData[0][30];
              this.Ubicacion = this.formData[0][31];
              this.Otro_U = this.formData[0][32];
              this.Apartado = this.formData[0][33];
              this.Altura = this.formData[0][34];
              this.Cableado = this.formData[0][35];
              this.Caja = this.formData[0][36];
              this.Equipo_M = this.formData[0][37];
              this.Riesgo = this.formData[0][38];
              this.Pago_Consumo = this.formData[0][39];
              this.Esquema = this.formData[0][40];
              this.Calidad = this.formData[0][41];
              this.Percepcion = this.formData[0][42];
              this.UltimaLectura = this.formData[0][45];
              this.NuevoMedidor = this.formData[0][44];
              this.Credito = this.formData[0][43];
              this.ngOnInit();
              this.edit = false;
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  deleteBtn() {
    Swal.fire({
      title: '<strong>¿Está segur@ que desea eliminar el formulario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        setTimeout(() => {
          try {
            var query = "UPDATE db_liwa.Guardados SET IsDelete = '1' WHERE Id ='" + this.global.idForm + "';";
            const pdata1 = { option: 'insertar3', texto: query };
            this.global.consultar(pdata1, (err, response) => {
              if (err == null && response == true) {
                this.alert.descValid('Formulario eliminado correctamente');
                this.route.navigateByUrl('/gensa-puerto-carreno');
                this.spinner.hide();
              }
            });
          } catch (error) {
            console.log(error);
          }
        }, 1000);
      } else {
        this.ngOnInit();
      }
    });
  }
  approveBtn() {
    Swal.fire({
      title: '<strong>¿Está segur@ que desea aprobar la revisión del formulario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        setTimeout(() => {
          try {
            var query = "UPDATE db_liwa.Guardados SET Subido = '1' WHERE Id_Beneficiario ='" + this.global.idBenefit + "';";
            const pdata1 = { option: 'insertar3', texto: query };
            this.global.consultar(pdata1, (err, response) => {
              if (err == null && response == true) {
                this.alert.descValid('Formulario aprobado correctamente');
                this.route.navigateByUrl('/gensa-puerto-carreno');
                this.spinner.hide();
              }
            });
          } catch (error) {
            console.log(error);
          }
        }, 1000);
      } else {
        this.ngOnInit();
      }
    });
  }
  exploreImg() {
    let elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(this.srcImg);
        for (let i = 0; i < this.srcImg.length; i++) {
          this.Photo = this.srcImg[i];
          this.validExt(this.Photo.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.evidence = true;
  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }
  loadImg() {
    let idform = this.formData[0][1];
    if (this.evidence == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;
          for (let i = 0; i < this.srcImg.length; i++) {
            con1++;
            console.log('Foto #' + i, this.srcImg[i]);
            const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
            const imageName = i + '-' + moment().unix() + this.ext;
            const imageFile = FOTOO;
            const data = new FormData();
            data.append('image', imageFile);
            data.append('nombre', imageName);
            data.append('ruta', idform + '-' + moment());
            $.ajax({
              url: 'https://www.php.engenius.com.co/FOT/subir_foto_encuestas_pc.php',
              type: 'post',
              dataType: 'json',
              data,
              processData: false,
              contentType: false
            }).then((data1) => {
              console.log(data, data1);
              this.fotoUrl[i] = data1;
              con2++;
              if (this.fotoUrl[i] == 'BADEXT' || this.fotoUrl[i] == null || this.fotoUrl[i] == undefined || this.fotoUrl[i] == '' || this.fotoUrl[i] == 'NULL') {
                this.alert.descErr('Error al subir la foto, verifique nuevamente');
              } else {
                // # of Photos validator
                let idFotoEnc = idform + '-' + i + '-' + moment();
                const query = 'REPLACE INTO db_liwa.Fotos_Formulario (Id_Foto_Formulario, Id_Beneficiario, Id_Formulario, Rutalocal, Rutaserver, Estado, fecha, upload, edit, IsDelete)' +
                  ' VALUES (\'' +
                  idFotoEnc + '\',\'' +
                  this.global.idBenefit + '\',\'' +
                  idform + '\',\'' +
                  '-' + '\',\'' +
                  data1 + '\',\'' +
                  'uploaded' + '\',\'' +
                  moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
                  1 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\');';
                const pdata1 = { option: 'insertar3', texto: query };
                this.global.consultar(pdata1, (err, response) => {
                  console.log(response, query);
                  if (err == null && response == true) {
                    this.alert.descValid('Imagenes subidas correcamente');
                    this.ngOnInit();
                    this.spinner.hide();
                  } else {
                    this.alert.descErr('¡Error al subir imagenes! ');
                    this.ngOnInit();
                    this.spinner.hide();
                  }
                });
                if (con1 == con2 && con2 == this.srcImg.length) {
                  this.ngOnInit();
                  this.srcImg = [];
                  this.Photo = [];
                  this.evidence = false;
                  this.spinner.hide();
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        console.log(error);
      }
    }
  }
  eliminarFoto(data) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar la foto?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        const query = 'UPDATE db_liwa.Fotos_Formulario SET IsDelete = 1 WHERE Id_Foto_Formulario =\'' + data + '\' ;';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, (err, response) => {
          if (err == null && response == true) {
            this.alert.descValid('Foto eliminada correctamente');
            this.ngOnInit();
          }else {
            this.alert.descErr('Oops, hubo un error. Verifique nuevamente');
            this.ngOnInit();
          }
          });
      } else {
        this.ngOnInit();
      }
    });
  }
}
