import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
  selector: 'app-formulario-preliminar-nazareth',
  templateUrl: './formulario-preliminar-nazareth.component.html'
})
export class FormularioPreliminarNazarethComponent implements OnInit {
  clientClass;
  openFlag: boolean = false;
  formData: any[] = [];
  formSoc: any[] = [];
  formPredio: any[] = [];
  formVivienda: any[] = [];
  formEnergy: any[] = [];
  formParentesco: any[] = [];
  call: boolean = false;
  call1: boolean = true;
  call2: boolean = true;
  call3: boolean = true;
  call4: boolean = true;
  call5: boolean = true;
  call6: boolean = true;
  call7: boolean = true;
  call8: boolean = true;
  call9: boolean = true;
  call10: boolean = true;
  edit: boolean = false;
  edit1: boolean = false;
  edit2: boolean = false;
  edit3: boolean = false;
  edit4: boolean = false;
  edit5: boolean = false;
  edit6: boolean = false;
  edit7: boolean = false;
  edit8: boolean = false;
  edit9: boolean = false;
  edit10: boolean = false;
  edit11: boolean = false;
  edit12: boolean = false;
  fotoUrl: any[] = [];
  Photo;
  srcImg: any[] = [];
  Num_formulario;
  Dia;
  Mes;
  Ano;
  U_latitud;
  U_longitud;
  U_altitud;
  U_depto;
  U_codigo_depto;
  U_municipio;
  U_codigo_municipio;
  U_vereda;
  U_corregimiento;
  Autorizacion = '-';
  Edificacion = '-';
  Tipo_institucion = 'No aplica';
  Tipo_institucion_otro_cual = '-';
  Uso_viv_inst = '-';
  FE_electrica = '-';
  FE_cocinar_gaspropano_consumo_mes = 0;
  FE_cocinar_gaspropano_consumo_select = false;
  FE_cocinar_gasnatural_consumo_mes = 0;
  FE_cocinar_gasnatural_consumo_select = false;
  FE_cocinar_gasolina_consumo_mes = 0;
  FE_cocinar_gasolina_consumo_select = false;
  FE_cocinar_kerosene_consumo_mes = 0;
  FE_cocinar_kerosene_consumo_select = false;
  FE_cocinar_petroleo_consumo_mes = 0;
  FE_cocinar_petroleo_consumo_select = false;
  FE_cocinar_alcohol_consumo_mes = 0;
  FE_cocinar_alcohol_consumo_select = false;
  FE_cocinar_carbon_mineral_consumo_mes = 0;
  FE_cocinar_carbon_mineral_consumo_select = false;
  FE_cocinar_lena_comprada_consumo_mes = 0;
  FE_cocinar_lena_comprada_consumo_select = false;
  FE_cocinar_lena_auto_apropiada_consumo_mes = 0;
  FE_cocinar_lena_auto_apropiada_consumo_select = false;
  FE_cocinar_residuos_agro_consumo_mes = 0;
  FE_cocinar_residuos_agro_consumo_select = false;
  FE_cocinar_otro_consumo_mes = '-';
  FE_cocinar_otro_consumo_select = false;
  FE_cocinar_otro_costo_mes = '-';
  FE_cocinar_gaspropano_costo_mes = 0;
  FE_cocinar_gasnatural_costo_mes = 0;
  FE_cocinar_gasolina_costo_mes = 0;
  FE_cocinar_kerosene_costo_mes = 0;
  FE_cocinar_petroleo_costo_mes = 0;
  FE_cocinar_alcohol_costo_mes = 0;
  FE_cocinar_carbon_mineral_costo_mes = 0;
  FE_cocinar_lena_comprada_costo_mes = 0;
  FE_cocinar_lena_auto_apropiada_costo_mes = 0;
  FE_cocinar_residuos_agro_costo_mes = 0;
  FE_cocinar_otro_cual_costo_mes = 0;
  FE_cocinar_otros_ban = '-';
  FE_cocinar_prefiere: any[] = [];
  FE_cocinar_prefiere_string;
  FE_iluminar_prefiere_string;
  FE_iluminar_usa = new Array();
  FE_iluminar_bateria_select: boolean = false;
  FE_iluminar_bateria_consumo_mes = 0;
  FE_iluminar_planta_gasolina_consumo_mes = 0;
  FE_iluminar_planta_gasolina_select: boolean = false;
  FE_iluminar_kerosene_consumo_mes = 0;
  FE_iluminar_kerosene_select: boolean = false;
  FE_iluminar_petroleo_consumo_mes = 0;
  FE_iluminar_petroleo_select: boolean = false;
  FE_iluminar_alcohol_consumo_mes = 0;
  FE_iluminar_alcohol_select: boolean = false;
  FE_iluminar_planta_diesel_consumo_mes = 0;
  FE_iluminar_planta_diesel_select: boolean = false;
  FE_iluminar_velas_consumo_mes = 0;
  FE_iluminar_velas_select: boolean = false;
  FE_iluminar_otro_consumo_mes = 0;
  FE_iluminar_otro_select: boolean = false;
  FE_iluminar_otro_ban = '-';
  FE_iluminar_bateria_costo_mes = 0;
  FE_iluminar_planta_gasolina_costo_mes = 0;
  FE_iluminar_kerosene_costo_mes = 0;
  FE_iluminar_petroleo_costo_mes = 0;
  FE_iluminar_alcohol_costo_mes = 0;
  FE_iluminar_planta_diesel_costo_mes = 0;
  FE_iluminar_velas_costo_mes = 0;
  FE_iluminar_otro_costo_mes = 0;
  FE_iluminar_bateria_localizacion = '-';
  FE_iluminar_planta_gasolina_localizacion = '-';
  FE_iluminar_kerosene_localizacion = '-';
  FE_iluminar_petroleo_localizacion = '-';
  FE_iluminar_alcohol_localizacion = '-';
  FE_iluminar_planta_diesel_localizacion = '-';
  FE_iluminar_velas_localizacion = '-';
  FE_iluminar_otro_localizacion = '-';
  Frecuencia_horaria_cocinar = '-';
  Frecuencia_horaria_iluminar = '-';
  Medidor = '-';
  SerialM = '';
  TipoIns = '-';
  LecturaC = '';
  LecturaV = '';
  Irregular = '-';
  Instalaciones_Int = '-';
  Acometida = '-';
  Puesta_Tierra = '-';
  DistanciaN = '';
  Tipo_Usuario = '-';
  Tipo_Negocio = '';
  Nevera_Cantidad = '-';
  Nevera_Watts = '-';
  Nevera_Uso = '-';
  Refrigerador_Cantidad = '-';
  Refrigerador_Watts = '-';
  Refrigerador_Uso = '-';
  AireA_Cantidad = '-';
  AireA_Watts = '-';
  AireA_Uso = '-';
  Ventilador_Cantidad = '-';
  Ventilador_Watts = '-';
  Ventilador_Uso = '-';
  Electrobomba_Cantidad = '-';
  Electrobomba_Watts = '-';
  Electrobomba_Uso = '-';
  Televisor_Cantidad = '-';
  Televisor_Watts = '-';
  Televisor_Uso = '-';
  EquipoS_Cantidad = '-';
  EquipoS_Watts = '-';
  EquipoS_Uso = '-';
  Lavadora_Cantidad = '-';
  Lavadora_Watts = '-';
  Lavadora_Uso = '-';
  Licuadora_Cantidad = '-';
  Licuadora_Watts = '-';
  Licuadora_Uso = '-';
  Plancha_Cantidad = '-';
  Plancha_Watts = '-';
  Plancha_Uso = '-';
  HornoM_Cantidad = '-';
  HornoM_Watts = '-';
  HornoM_Uso = '-';
  HornoE_Cantidad = '-';
  HornoE_Watts = '-';
  HornoE_Uso = '-';
  Computador_Cantidad = '-';
  Computador_Watts = '-';
  Computador_Uso = '-';
  Celulares_Cantidad = '-';
  Celulares_Watts = '-';
  Celulares_Uso = '-';
  LamparasI_Cantidad = '-';
  LamparasI_Watts = '-';
  LamparasI_Uso = '-';
  LED_Cantidad = '-';
  LED_Watts = '-';
  LED_Uso = '-';
  Otro = '-';
  Otro_Cantidad = '-';
  Otro_Watts = '-';
  Otro_Uso = '-';
  Contaminacion_ruido = '-';
  Contaminacion_olores = '-';
  Planea_implementar_proyecto = '-';
  Cual_proyecto = '';
  Requiere_energia_proyecto = 'No Aplica';
  Voluntad_de_pago = '-';
  Uso_predio = '-';
  Estrato_predio = 'Estrato 1';
  Nombre_comunidad = '-';
  Territorialidad = '-';
  Tenencia_posesion = '-';
  Hogares_vivienda = '-';
  Personas_vivienda = '-';
  Paredes_exteriores = '-';
  Techo_cubierta = '-';
  Pisos = '-';
  Paretesco = '-';
  Afectacion_inundacion = '-';
  Afectacion_avalancha = '-';
  Afectacion_hundimiento = '-';
  Afectacion_tormentas = '-';
  Afectacion_descargas = '-';
  Telefono_fijo_propio = false;
  Telefono_fijo_comunitario = false;
  Celular = false;
  Internet_comunitario = false;
  Internet_propio = false;
  Ninguna_anteriores = false;
  Acueducto_domiciliario_publico = false;
  Medidor_agua = false;
  Pila_medidor_agua_publica = false;
  Aljibe = false;
  Rio_quebrada_manantial_nacimiento = false;
  Pozo_dentro_vivienda = false;
  Agua_lluvia = false;
  Carrotanque = false;
  Aguatero_embotellada_bolsa = false;
  Inodoro_conectado_alcantarillado = false;
  Inodoro_conectado_pozo_septico = false;
  Inodoro_sin_conexion = false;
  Letrina = false;
  Vivienda_institucion_sin_servicio_sanitario = false;
  Sanitario_inodoro_otro_cual_ban = false;
  Sanitario_inodoro_otro_cual = '-';
  Gas = '-';
  Acceso_agua = '-';
  Cantidad_agua_fuente = '-';
  Cantidad_agua_uso = '-';
  Fuente_agua = '-';
  Otra_fuente = '-';
  Profundidad = '-';
  Cual_profundidad = '-';
  Comunitario = '-';
  Uso_agua = new Array();
  Otro_uso = '-';
  Otro_uso_select = false;
  Puesto_agua = '-';
  Tiempo_agua = '-';
  Agua_consumo = '-';
  Distancia = '-';
  Tiempo_recoleccion = '-';
  Transporte_agua = '-';
  Otro_transporte = '-';
  Fuente_diferente = '-';
  Agua_semanal = '-';
  Fuente_alternativa = '-';
  Pago_fuente = '-';
  Cuanto = '-';
  Tratamiento_agua = '-';
  Otro_tratamiento = '-';
  Almacenamiento = '-';
  Almacenamiento_promedio = '-';
  Elementos_almacenamiento = '-';
  Limpieza_elementos = '-';
  Basura = '-';
  Recoleccion_basura = '-';
  Desechos_solidos = '-';
  Disposicion_desechos = '-';
  Aguas_negras = '-';
  Otra_disposicion = '-';
  Aguas_residuales = '-';
  Otro_residuales = '-';
  Permanencia_vivienda = '-';
  Ingresos_vivienda = 0;
  Reconocimiento = '0';
  Lengua_nativa = 'No Aplica';
  Nombre_lengua_nativa = '-';
  Problemas_uso_lena = 0;
  Organizacion = '0';
  Nombre_organizacion = 'No Aplica';
  Labores_Lab_domesticas_ninas = false;
  Labores_pagosycompras_ninas = false;
  Labores_lab_finca_ninas = false;
  Labores_transporte_ninas = false;
  Labores_admon_finca_ninas = false;
  Labores_comercia_ninas = false;
  Labores_estudia_ninas = false;
  Labores_formacion_hijos_ninas = false;
  Labores_cuiado_mayores_enfermos_ninas = false;
  Labores_otro_cual_ninas = false;
  Labores_Lab_domesticas_ninos = false;
  Labores_pagosycompras_ninos = false;
  Labores_lab_finca_ninos = false;
  Labores_transporte_ninos = false;
  Labores_admon_finca_ninos = false;
  Labores_comercia_ninos = false;
  Labores_estudia_ninos = false;
  Labores_formacion_hijos_ninos = false;
  Labores_cuiado_mayores_enfermos_ninos = false;
  Labores_otro_cual_ninos = false;
  Labores_Lab_domesticas_mujeres = false;
  Labores_pagosycompras_mujeres = false;
  Labores_lab_finca_mujeres = false;
  Labores_transporte_mujeres = false;
  Labores_admon_finca_mujeres = false;
  Labores_comercia_mujeres = false;
  Labores_estudia_mujeres = false;
  Labores_formacion_hijos_mujeres = false;
  Labores_cuiado_mayores_enfermos_mujeres = false;
  Labores_otro_cual_mujeres = false;
  Labores_Lab_domesticas_hombres = false;
  Labores_pagosycompras_hombres = false;
  Labores_lab_finca_hombres = false;
  Labores_transporte_hombres = false;
  Labores_admon_finca_hombres = false;
  Labores_comercia_hombres = false;
  Labores_estudia_hombres = false;
  Labores_formacion_hijos_hombres = false;
  Labores_cuiado_mayores_enfermos_hombres = false;
  Labores_otro_cual_hombres = false;
  Ganaderia_caza_silvicultura_pesca = false;
  Explotacion_minas_canteras = false;
  Fabricacion_articulos_textiles_prendas_vestir = false;
  Construccion = false;
  Venta_productos_no_fabricados_hogar = false;
  Elaboracion_procesamiento_alimentos_bebidas = false;
  Servicio_restaurante_bar_similares = false;
  Alojamiento = false;
  Arrendamientos = false;
  Servicios_actividades_reparacion_mantenimiento = false;
  Servicios_artisticos_peluqueria = false;
  Elaboracion_artesanias = false;
  Empleo_integrantes_familia = false;
  Agricultura = false;
  Fuente_ingreso_otro_cual_ban = false;
  Fuente_ingreso_otro_cual = '';
  Cultivo1 = '';
  Cultivo2 = '';
  Cultivo3 = '';
  Cultivo4 = '';
  Gastos_mes_arriendo = 0;
  Gastos_mes_acueducto = 0;
  Gastos_mes_alcantarillado = 0;
  Gastos_mes_gas = 0;
  Gastos_mes_salud = 0;
  Gastos_mes_transporte = 0;
  Gastos_mes_educacion = 0;
  Gastos_mes_alimentacion = 0;
  Gastos_mes_recreacion = 0;
  Gastos_mes_vestuario = 0;
  Gastos_mes_energia_electrica = 0;
  Gastos_mes_otro_cual = 0;
  Gastos_mes_total = 0;
  Gastos_mes_otro_ban = '-';
  Uso_adecuado_energia = '-';
  Escuchado_uso_racional_energia = '-';
  Conocer_generacionyuso_energia = '-';
  Temas_generacionyuso_energia = '';
  Compartir_saberes = '-';
  Nombre_beneficiario_usuario = '';
  Nombre_encuestado = '';
  Telefono_celular_encuestado = '-';
  Telefono_fijo_encuestado = '-';
  Cedula_encuestado = '-';
  Correo_encuestado = '';
  Constancia_uso_datos = false;
  Explicado_posible_solucion = false;
  Explicado_beneficio_limitacion = false;
  Aprobacion_proyecto = false;
  Autorizacion_fotos_videos = false;
  Firma;
  Cedula_firma = 0;
  Nombre_encuestador = '-';
  Telefono_celular_encuestador = '-';
  Telefono_fijo_encuestador = '-';
  Correo_encuestador = '-';
  Ciudad_vive_encuestador = '-';
  Comentarios_encuestador = '-';
  Reaccion_preguntas = '-';
  Reaccion_proyecto = '-';
  tarifa_mensual = '-';
  area_predio = '-';
  Porcentaje;
  IsTerm = 0;
  Fecha_Term;
  Parentesco = '-';
  Ocupacion = '-';
  Registro = '-';
  Escolaridad = '-';
  Edad = 0;
  Genero = '-';
  txt;
  idx;
  enterParentesco: boolean = false;
  fotosForm: any[] = [];
  fotosFirma: any[] = [];
  ext;
  evidence: boolean = false;
  evidenceId: boolean = false;
  srcImgid:any[] = [];
  fotoUrlid;
  Photoid;
  flagFirma:boolean = false;
  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    this.spinner.show();
    setTimeout(() => {
      if (this.global.idBenefit == undefined) {
        this.route.navigateByUrl('/listado-preliminar-nazareth');
        this.spinner.hide();
      } else {
        this.clientClass = this.global.CLIENTDATALIWA;
        const pdata = { option: 'form-nazareth-datos', idBeneficiario: this.global.idBenefit };
        this.global.consultar(pdata, (err, response) => {
          console.log('Datos form-nazareth-datos', response);
          this.formData = response;
          this.Constancia_uso_datos = (this.formData[0][9] === 'true');
          this.Explicado_posible_solucion = (this.formData[0][10] === 'true');
          this.Explicado_beneficio_limitacion = (this.formData[0][11] === 'true');
          this.Aprobacion_proyecto = (this.formData[0][12] === 'true');
          this.Autorizacion_fotos_videos = (this.formData[0][13] === 'true');
        });
        const pdata3 = { option: 'form-nazareth-predio', idBeneficiario: this.global.idBenefit };
        this.global.consultar(pdata3, (err, response2) => {
          console.log('Datos form-nazareth-predio', response2);
          this.formPredio = response2;
        });
        const pdata4 = { option: 'form-nazareth-vivienda', idBeneficiario: this.global.idBenefit };
        this.global.consultar(pdata4, (err, response3) => {
          console.log('Datos form-nazareth-vivienda', response3);
          this.formVivienda = response3;
          this.Telefono_fijo_propio = (this.formVivienda[0][23] === 'true');
          this.Telefono_fijo_comunitario = (this.formVivienda[0][24] === 'true');
          this.Celular = (this.formVivienda[0][25] === 'true');
          this.Internet_comunitario = (this.formVivienda[0][26] === 'true');
          this.Internet_propio = (this.formVivienda[0][27] === 'true');
          this.Ninguna_anteriores = (this.formVivienda[0][28] === 'true');
          this.Acueducto_domiciliario_publico = (this.formVivienda[0][29] === 'true');
          this.Medidor_agua = (this.formVivienda[0][30] === 'true');
          this.Pila_medidor_agua_publica = (this.formVivienda[0][31] === 'true');
          this.Aljibe = (this.formVivienda[0][32] === 'true');
          this.Rio_quebrada_manantial_nacimiento = (this.formVivienda[0][33] === 'true');
          this.Pozo_dentro_vivienda = (this.formVivienda[0][34] === 'true');
          this.Agua_lluvia = (this.formVivienda[0][35] === 'true');
          this.Carrotanque = (this.formVivienda[0][36] === 'true');
          this.Aguatero_embotellada_bolsa = (this.formVivienda[0][37] === 'true');
          this.Inodoro_conectado_alcantarillado = (this.formVivienda[0][38] === 'true');
          this.Inodoro_conectado_pozo_septico = (this.formVivienda[0][39] === 'true');
          this.Inodoro_sin_conexion = (this.formVivienda[0][40] === 'true');
          this.Letrina = (this.formVivienda[0][41] === 'true');
          this.Vivienda_institucion_sin_servicio_sanitario = (this.formVivienda[0][42] === 'true');
        });
        const pdata5 = { option: 'form-nazareth-energia', idBeneficiario: this.global.idBenefit };
        this.global.consultar(pdata5, (err, response4) => {
          console.log('Datos form-nazareth-vivienda-energía', response4);
          this.formEnergy = response4;
          let arrCocina: any[] = [];
          let arrIluminacion: any[] = [];
          arrCocina.push(this.formEnergy[0][26]);
          arrIluminacion.push(this.formEnergy[0][53]);
          for (let i = 0; i < arrCocina.length; i++) {
            if (arrCocina.length == 0) {
              continue;
            } else {
              switch (arrCocina[i]) {
                case 'Gas Propano':
                  this.FE_cocinar_gaspropano_consumo_select = true;
                  break;
                case 'Gas Natural':
                  this.FE_cocinar_gasnatural_consumo_select = true
                  break;
                case 'Gasolina':
                  this.FE_cocinar_gasolina_consumo_select = true;
                  break;
                case 'Kerosene':
                  this.FE_cocinar_kerosene_consumo_select = true;
                  break;
                case 'Petróleo':
                  this.FE_cocinar_petroleo_consumo_select = true;
                  break;
                case 'Alcohol':
                  this.FE_cocinar_alcohol_consumo_select = true;
                  break;
                case 'Carbón Mineral':
                  this.FE_cocinar_carbon_mineral_consumo_select = true;
                  break;
                case 'Leña Comprada':
                  this.FE_cocinar_lena_comprada_consumo_select = true;
                  break;
                case 'Leña Auto Apropiada':
                  this.FE_cocinar_lena_auto_apropiada_consumo_select = true;
                  break;
                case 'Residuos del Agro':
                  this.FE_cocinar_residuos_agro_consumo_select = true;
                  break;
                case 'Otro':
                  this.FE_cocinar_otro_consumo_select = true;

                  break;
                default:
                  break;
              }
            }

          }
          for (let i = 0; i < arrIluminacion.length; i++) {
            if (arrIluminacion.length == 0) {
              continue;
            } else {
              switch (arrIluminacion[i]) {
                case 'Pilas o baterías (linternas)':
                  this.FE_iluminar_bateria_select = true;
                  break;
                case 'Gasolina (planta eléctrica)':
                  this.FE_iluminar_planta_gasolina_select = true;
                  break;
                case 'Kerosene (lámpara – mechero)':
                  this.FE_iluminar_kerosene_select = true;
                  break;
                case 'Petróleo (lámpara – mechero)':
                  this.FE_iluminar_petroleo_select = true;
                  break;
                case 'Alcohol':
                  this.FE_iluminar_alcohol_select = true;
                  break;
                case 'Diesel (planta eléctrica)':
                  this.FE_iluminar_planta_diesel_select = true;
                  break;
                case 'Velas':
                  this.FE_iluminar_velas_select = true;
                  break;
                case 'Otro':
                  this.FE_iluminar_otro_select = true;
                  break;
                default:
                  break;
              }
            }

          }
          const pdata2 = { option: 'form-nazareth-sociodemografico', idBeneficiario: this.global.idBenefit };
          this.global.consultar(pdata2, (err, response1) => {
            console.log('Datos form-nazareth-sociodemografico', response1);
            this.formSoc = response1;
            this.Ganaderia_caza_silvicultura_pesca = (this.formSoc[0][3] === 'true');
            this.Explotacion_minas_canteras = (this.formSoc[0][4] === 'true');
            this.Fabricacion_articulos_textiles_prendas_vestir = (this.formSoc[0][5] === 'true');
            this.Labores_Lab_domesticas_hombres = (this.formSoc[0][6] === 'true');
            this.Construccion = (this.formSoc[0][7] === 'true');
            this.Venta_productos_no_fabricados_hogar = (this.formSoc[0][8] === 'true');
            this.Elaboracion_procesamiento_alimentos_bebidas = (this.formSoc[0][9] === 'true');
            this.Servicio_restaurante_bar_similares = (this.formSoc[0][10] === 'true');
            this.Alojamiento = (this.formSoc[0][11] === 'true');
            this.Arrendamientos = (this.formSoc[0][12] === 'true');
            this.Servicios_actividades_reparacion_mantenimiento = (this.formSoc[0][13] === 'true');
            this.Servicios_artisticos_peluqueria = (this.formSoc[0][14] === 'true');
            this.Elaboracion_artesanias = (this.formSoc[0][15] === 'true');
            this.Agricultura = (this.formSoc[0][16] === 'true');
            this.formParentesco = JSON.parse(this.formSoc[0][42]);
            this.Labores_Lab_domesticas_ninas = (this.formSoc[0][51] === 'true');
            this.Labores_Lab_domesticas_ninos = (this.formSoc[0][52] === 'true');
            this.Labores_Lab_domesticas_mujeres = (this.formSoc[0][53] === 'true');
            this.Labores_Lab_domesticas_hombres = (this.formSoc[0][54] === 'true');
            this.Labores_pagosycompras_ninas = (this.formSoc[0][55] === 'true');
            this.Labores_pagosycompras_ninos = (this.formSoc[0][56] === 'true');
            this.Labores_pagosycompras_mujeres = (this.formSoc[0][57] === 'true');
            this.Labores_pagosycompras_hombres = (this.formSoc[0][58] === 'true');
            this.Labores_lab_finca_ninas = (this.formSoc[0][59] === 'true');
            this.Labores_lab_finca_ninos = (this.formSoc[0][60] === 'true');
            this.Labores_lab_finca_mujeres = (this.formSoc[0][61] === 'true');
            this.Labores_lab_finca_hombres = (this.formSoc[0][62] === 'true');
            this.Labores_transporte_ninas = (this.formSoc[0][63] === 'true');
            this.Labores_transporte_ninos = (this.formSoc[0][64] === 'true');
            this.Labores_transporte_mujeres = (this.formSoc[0][65] === 'true');
            this.Labores_transporte_hombres = (this.formSoc[0][66] === 'true');
            this.Labores_admon_finca_ninas = (this.formSoc[0][67] === 'true');
            this.Labores_admon_finca_ninos = (this.formSoc[0][68] === 'true');
            this.Labores_admon_finca_mujeres = (this.formSoc[0][69] === 'true');
            this.Labores_admon_finca_hombres = (this.formSoc[0][70] === 'true');
            this.Labores_comercia_ninas = (this.formSoc[0][71] === 'true');
            this.Labores_comercia_ninos = (this.formSoc[0][72] === 'true');
            this.Labores_comercia_mujeres = (this.formSoc[0][73] === 'true');
            this.Labores_comercia_hombres = (this.formSoc[0][74] === 'true');
            this.Labores_estudia_ninas = (this.formSoc[0][75] === 'true');
            this.Labores_estudia_ninos = (this.formSoc[0][76] === 'true');
            this.Labores_estudia_mujeres = (this.formSoc[0][77] === 'true');
            this.Labores_estudia_hombres = (this.formSoc[0][78] === 'true');
            this.Labores_formacion_hijos_ninas = (this.formSoc[0][79] === 'true');
            this.Labores_formacion_hijos_ninos = (this.formSoc[0][80] === 'true');
            this.Labores_formacion_hijos_mujeres = (this.formSoc[0][81] === 'true');
            this.Labores_formacion_hijos_hombres = (this.formSoc[0][82] === 'true');
            this.Labores_cuiado_mayores_enfermos_ninas = (this.formSoc[0][83] === 'true');
            this.Labores_cuiado_mayores_enfermos_ninos = (this.formSoc[0][84] === 'true');
            this.Labores_cuiado_mayores_enfermos_mujeres = (this.formSoc[0][85] === 'true');
            this.Labores_cuiado_mayores_enfermos_hombres = (this.formSoc[0][86] === 'true');
            this.Labores_otro_cual_ninas = (this.formSoc[0][87] === 'true');
            this.Labores_otro_cual_ninos = (this.formSoc[0][88] === 'true');
            this.Labores_otro_cual_mujeres = (this.formSoc[0][89] === 'true');
            this.Labores_otro_cual_hombres = (this.formSoc[0][90] === 'true');
            if (this.formSoc.length != 0) {
              const pdata2 = { option: 'form-nazareth-fotos', idBeneficiario: this.global.idBenefit };
              const pdata = { option: 'form-nazareth-fotos-firma', idBeneficiario: this.global.idBenefit };
              this.global.consultar(pdata2, (err, response) => {
                this.fotosForm = response;

                this.global.consultar(pdata, (err13, response13) => {
                  if(response13.length === 0) {
                    console.log('undefined',response13);
                    this.enterParentesco = true;
                    this.openFlag = true;
                    this.flagFirma = false;
                    this.spinner.hide();
                  }else {
                    console.log('no undefined',response13);
                    this.fotosFirma.push(response13[0]);
                    this.enterParentesco = true;
                    this.openFlag = true;
                    this.flagFirma = true;
                    this.spinner.hide();
                  }
                });
              });
            }
          });
        });
      }
    }, 500);
  }
  animatedTab(numbr: number) {
    switch (numbr) {
      case 1:
        this.call = !this.call;
        break;
      case 2:
        this.call1 = !this.call1;
        break;
      case 3:
        this.call2 = !this.call2;
        break;
      case 4:
        this.call3 = !this.call3;
        break;
      case 5:
        this.call4 = !this.call4;
        break;
      case 6:
        this.call5 = !this.call5;
        break;
      case 7:
        this.call6 = !this.call6;
        break;
      case 8:
        this.call7 = !this.call7;
        break;
      case 9:
        this.call8 = !this.call8;
        break;
      case 10:
        this.call9 = !this.call9;
        break;
      case 11:
        this.call10 = !this.call10;
        break;

      default:
        break;
    }
  }
  editModule(numb: number) {
    switch (numb) {
      case 0:
        this.edit = !this.edit;
        this.Nombre_encuestado = this.formData[0][3];
        this.Cedula_encuestado = this.formData[0][7];
        this.Telefono_fijo_encuestado = this.formData[0][6];
        this.Correo_encuestado = this.formData[0][8];
        this.U_depto = this.formData[0][28];
        this.U_municipio = this.formData[0][30];
        this.U_vereda = this.formData[0][32];
        this.U_latitud = this.formData[0][25];
        this.U_longitud = this.formData[0][26];
        this.U_altitud = this.formData[0][27];
        break;
      case 1:
        this.edit1 = !this.edit1;
        this.FE_electrica = this.formEnergy[0][3];
        this.FE_cocinar_prefiere.push(this.formEnergy[0][26]);
        this.FE_cocinar_prefiere_string = this.FE_cocinar_prefiere.toString();
        this.FE_cocinar_gaspropano_consumo_mes = this.formEnergy[0][4];
        this.FE_cocinar_gaspropano_costo_mes = this.formEnergy[0][16];
        this.FE_cocinar_gasnatural_consumo_mes = this.formEnergy[0][5];
        this.FE_cocinar_gasnatural_costo_mes = this.formEnergy[0][17];
        this.FE_cocinar_gasolina_consumo_mes = this.formEnergy[0][6];
        this.FE_cocinar_gasolina_costo_mes = this.formEnergy[0][18];
        this.FE_cocinar_kerosene_consumo_mes = this.formEnergy[0][7];
        this.FE_cocinar_kerosene_costo_mes = this.formEnergy[0][19];
        this.FE_cocinar_petroleo_consumo_mes = this.formEnergy[0][8];
        this.FE_cocinar_petroleo_costo_mes = this.formEnergy[0][20];
        this.FE_cocinar_alcohol_consumo_mes = this.formEnergy[0][9];
        this.FE_cocinar_alcohol_costo_mes = this.formEnergy[0][21];
        this.FE_cocinar_carbon_mineral_consumo_mes = this.formEnergy[0][10];
        this.FE_cocinar_carbon_mineral_costo_mes = this.formEnergy[0][22];
        this.FE_cocinar_lena_comprada_consumo_mes = this.formEnergy[0][11];
        this.FE_cocinar_lena_comprada_costo_mes = this.formEnergy[0][23];
        this.FE_cocinar_lena_auto_apropiada_consumo_mes = this.formEnergy[0][12];
        this.FE_cocinar_residuos_agro_consumo_mes = this.formEnergy[0][13];
        this.FE_cocinar_otros_ban = this.formEnergy[0][27];
        this.FE_cocinar_otro_consumo_mes = this.formEnergy[0][14];
        this.FE_cocinar_otro_costo_mes = this.formEnergy[0][25];
        this.FE_iluminar_usa.push(this.formEnergy[0][53]);
        this.FE_iluminar_prefiere_string = this.FE_iluminar_usa.toString();
        this.FE_iluminar_bateria_consumo_mes = this.formEnergy[0][28];
        this.FE_iluminar_bateria_costo_mes = this.formEnergy[0][36];
        this.FE_iluminar_bateria_localizacion = this.formEnergy[0][44];
        this.FE_iluminar_planta_gasolina_consumo_mes = this.formEnergy[0][29];
        this.FE_iluminar_planta_gasolina_costo_mes = this.formEnergy[0][37];
        this.FE_iluminar_planta_gasolina_localizacion = this.formEnergy[0][45];
        this.FE_iluminar_kerosene_consumo_mes = this.formEnergy[0][30];
        this.FE_iluminar_kerosene_costo_mes = this.formEnergy[0][38];
        this.FE_iluminar_kerosene_localizacion = this.formEnergy[0][46];
        this.FE_iluminar_petroleo_consumo_mes = this.formEnergy[0][31];
        this.FE_iluminar_petroleo_costo_mes = this.formEnergy[0][39];
        this.FE_iluminar_petroleo_localizacion = this.formEnergy[0][46];
        this.FE_iluminar_alcohol_consumo_mes = this.formEnergy[0][32];
        this.FE_iluminar_alcohol_costo_mes = this.formEnergy[0][40];
        this.FE_iluminar_alcohol_localizacion = this.formEnergy[0][47];
        this.FE_iluminar_planta_diesel_consumo_mes = this.formEnergy[0][33];
        this.FE_iluminar_planta_diesel_costo_mes = this.formEnergy[0][41];
        this.FE_iluminar_planta_diesel_localizacion = this.formEnergy[0][48];
        this.FE_iluminar_velas_consumo_mes = this.formEnergy[0][34];
        this.FE_iluminar_velas_costo_mes = this.formEnergy[0][42];
        this.FE_iluminar_velas_localizacion = this.formEnergy[0][49];
        this.FE_iluminar_otro_ban = this.formEnergy[0][52];
        this.FE_iluminar_otro_localizacion = this.formEnergy[0][51];
        this.FE_iluminar_otro_consumo_mes = this.formEnergy[0][35];
        this.FE_iluminar_otro_costo_mes = this.formEnergy[0][43];
        this.tarifa_mensual = this.formEnergy[0][123];
        this.Voluntad_de_pago = this.formEnergy[0][124];
        this.Frecuencia_horaria_cocinar = this.formEnergy[0][125];
        this.Frecuencia_horaria_iluminar = this.formEnergy[0][126];
        break;
      case 2:
        this.edit2 = !this.edit2;
        this.Medidor = this.formEnergy[0][54];
        this.SerialM = this.formEnergy[0][55];
        this.TipoIns = this.formEnergy[0][56];
        this.LecturaC = this.formEnergy[0][57];
        this.LecturaV = this.formEnergy[0][58];
        this.Irregular = this.formEnergy[0][59];
        this.Instalaciones_Int = this.formEnergy[0][60];
        this.Acometida = this.formEnergy[0][61];
        this.Puesta_Tierra = this.formEnergy[0][62];
        this.DistanciaN = this.formEnergy[0][63];
        this.Tipo_Usuario = this.formEnergy[0][64];
        this.Tipo_Negocio = this.formEnergy[0][65];
        this.Nevera_Cantidad = this.formEnergy[0][66];
        this.Nevera_Watts = this.formEnergy[0][67];
        this.Nevera_Uso = this.formEnergy[0][68];
        this.Refrigerador_Cantidad = this.formEnergy[0][69];
        this.Refrigerador_Watts = this.formEnergy[0][70];
        this.Refrigerador_Uso = this.formEnergy[0][71];
        this.AireA_Cantidad = this.formEnergy[0][72];
        this.AireA_Watts = this.formEnergy[0][73];
        this.AireA_Uso = this.formEnergy[0][74];
        this.Ventilador_Cantidad = this.formEnergy[0][75];
        this.Ventilador_Watts = this.formEnergy[0][76];
        this.Ventilador_Uso = this.formEnergy[0][77];
        this.Electrobomba_Cantidad = this.formEnergy[0][78];
        this.Electrobomba_Watts = this.formEnergy[0][79];
        this.Electrobomba_Uso = this.formEnergy[0][80];
        this.Televisor_Cantidad = this.formEnergy[0][81];
        this.Televisor_Watts = this.formEnergy[0][82];
        this.Televisor_Uso = this.formEnergy[0][83];
        this.EquipoS_Cantidad = this.formEnergy[0][84];
        this.EquipoS_Watts = this.formEnergy[0][85];
        this.EquipoS_Uso = this.formEnergy[0][86];
        this.Lavadora_Cantidad = this.formEnergy[0][87];
        this.Lavadora_Watts = this.formEnergy[0][88];
        this.Lavadora_Uso = this.formEnergy[0][89];
        this.Licuadora_Cantidad = this.formEnergy[0][90];
        this.Licuadora_Watts = this.formEnergy[0][91];
        this.Licuadora_Uso = this.formEnergy[0][92];
        this.Plancha_Cantidad = this.formEnergy[0][93];
        this.Plancha_Watts = this.formEnergy[0][94];
        this.Plancha_Uso = this.formEnergy[0][95];
        this.HornoM_Cantidad = this.formEnergy[0][96];
        this.HornoM_Watts = this.formEnergy[0][97];
        this.HornoM_Uso = this.formEnergy[0][98];
        this.HornoE_Cantidad = this.formEnergy[0][99];
        this.HornoE_Watts = this.formEnergy[0][100];
        this.HornoE_Uso = this.formEnergy[0][101];
        this.Computador_Cantidad = this.formEnergy[0][102];
        this.Computador_Watts = this.formEnergy[0][103];
        this.Computador_Uso = this.formEnergy[0][104];
        this.Celulares_Cantidad = this.formEnergy[0][105];
        this.Celulares_Watts = this.formEnergy[0][106];
        this.Celulares_Uso = this.formEnergy[0][107];
        this.LamparasI_Cantidad = this.formEnergy[0][108];
        this.LamparasI_Watts = this.formEnergy[0][109];
        this.LamparasI_Uso = this.formEnergy[0][110];
        this.LED_Cantidad = this.formEnergy[0][111];
        this.LED_Watts = this.formEnergy[0][112];
        this.LED_Uso = this.formEnergy[0][113];
        this.Otro_Cantidad = this.formEnergy[0][115];
        this.Otro_Watts = this.formEnergy[0][116];
        break;
      case 3:
        this.edit3 = !this.edit3;
        this.Contaminacion_ruido = this.formEnergy[0][118];
        this.Contaminacion_olores = this.formEnergy[0][119];
        this.Planea_implementar_proyecto = this.formEnergy[0][120];
        this.Cual_proyecto = this.formEnergy[0][121];
        this.Requiere_energia_proyecto = this.formEnergy[0][122];
        this.tarifa_mensual = this.formEnergy[0][123];
        this.Voluntad_de_pago = this.formEnergy[0][124];
        break;
      case 4:
        this.edit4 = !this.edit4;
        this.Uso_predio = this.formPredio[0][3];
        this.Estrato_predio = this.formPredio[0][4];
        break;
      case 5:
        this.edit5 = !this.edit5;
        this.Nombre_comunidad = this.formVivienda[0][3];
        this.Territorialidad = this.formVivienda[0][4];
        this.Tenencia_posesion = this.formVivienda[0][5];
        this.Hogares_vivienda = this.formVivienda[0][6];
        this.Personas_vivienda = this.formVivienda[0][7];
        this.Paredes_exteriores = this.formVivienda[0][8];
        this.Techo_cubierta = this.formVivienda[0][9];
        this.Pisos = this.formVivienda[0][10];
        this.Afectacion_inundacion = this.formVivienda[0][11];
        this.Afectacion_avalancha = this.formVivienda[0][12];
        this.Afectacion_hundimiento = this.formVivienda[0][13];
        this.Afectacion_tormentas = this.formVivienda[0][14];
        this.Afectacion_descargas = this.formVivienda[0][15];
        this.area_predio = this.formVivienda[0][16];
        break;
      case 6:
        this.edit6 = !this.edit6;
        this.Telefono_fijo_propio = (this.formVivienda[0][23] === 'true');
        this.Telefono_fijo_comunitario = (this.formVivienda[0][24] === 'true');
        this.Celular = (this.formVivienda[0][25] === 'true');
        this.Internet_comunitario = (this.formVivienda[0][26] === 'true');
        this.Internet_propio = (this.formVivienda[0][27] === 'true');
        this.Ninguna_anteriores = (this.formVivienda[0][28] === 'true');
        this.Acueducto_domiciliario_publico = (this.formVivienda[0][29] === 'true');
        this.Medidor_agua = (this.formVivienda[0][30] === 'true');
        this.Pila_medidor_agua_publica = (this.formVivienda[0][31] === 'true');
        this.Aljibe = (this.formVivienda[0][32] === 'true');
        this.Rio_quebrada_manantial_nacimiento = (this.formVivienda[0][33] === 'true');
        this.Pozo_dentro_vivienda = (this.formVivienda[0][34] === 'true');
        this.Agua_lluvia = (this.formVivienda[0][35] === 'true');
        this.Carrotanque = (this.formVivienda[0][36] === 'true');
        this.Aguatero_embotellada_bolsa = (this.formVivienda[0][37] === 'true');
        this.Inodoro_conectado_alcantarillado = (this.formVivienda[0][38] === 'true');
        this.Inodoro_conectado_pozo_septico = (this.formVivienda[0][39] === 'true');
        this.Inodoro_sin_conexion = (this.formVivienda[0][40] === 'true');
        this.Letrina = (this.formVivienda[0][41] === 'true');
        this.Vivienda_institucion_sin_servicio_sanitario = (this.formVivienda[0][42] === 'true');
        this.Gas = this.formVivienda[0][44];
        break;
      case 7:
        this.edit7 = !this.edit7;
        this.Permanencia_vivienda = this.formSoc[0][43];
        this.Ingresos_vivienda = this.formSoc[0][44];
        this.Reconocimiento = this.formSoc[0][45];
        this.Lengua_nativa = this.formSoc[0][46];
        this.Nombre_lengua_nativa = this.formSoc[0][47];
        this.Problemas_uso_lena = this.formSoc[0][48];
        this.Organizacion = this.formSoc[0][49];
        this.Nombre_organizacion = this.formSoc[0][50];
        break;
      case 8:
        this.edit8 = !this.edit8;
        this.Labores_Lab_domesticas_ninas = (this.formSoc[0][51] === 'true');
        this.Labores_Lab_domesticas_ninos = (this.formSoc[0][52] === 'true');
        this.Labores_Lab_domesticas_mujeres = (this.formSoc[0][53] === 'true');
        this.Labores_Lab_domesticas_hombres = (this.formSoc[0][54] === 'true');
        this.Labores_pagosycompras_ninas = (this.formSoc[0][55] === 'true');
        this.Labores_pagosycompras_ninos = (this.formSoc[0][56] === 'true');
        this.Labores_pagosycompras_mujeres = (this.formSoc[0][57] === 'true');
        this.Labores_pagosycompras_hombres = (this.formSoc[0][58] === 'true');
        this.Labores_lab_finca_ninas = (this.formSoc[0][59] === 'true');
        this.Labores_lab_finca_ninos = (this.formSoc[0][60] === 'true');
        this.Labores_lab_finca_mujeres = (this.formSoc[0][61] === 'true');
        this.Labores_lab_finca_hombres = (this.formSoc[0][62] === 'true');
        this.Labores_transporte_ninas = (this.formSoc[0][63] === 'true');
        this.Labores_transporte_ninos = (this.formSoc[0][64] === 'true');
        this.Labores_transporte_mujeres = (this.formSoc[0][65] === 'true');
        this.Labores_transporte_hombres = (this.formSoc[0][66] === 'true');
        this.Labores_admon_finca_ninas = (this.formSoc[0][67] === 'true');
        this.Labores_admon_finca_ninos = (this.formSoc[0][68] === 'true');
        this.Labores_admon_finca_mujeres = (this.formSoc[0][69] === 'true');
        this.Labores_admon_finca_hombres = (this.formSoc[0][70] === 'true');
        this.Labores_comercia_ninas = (this.formSoc[0][71] === 'true');
        this.Labores_comercia_ninos = (this.formSoc[0][72] === 'true');
        this.Labores_comercia_mujeres = (this.formSoc[0][73] === 'true');
        this.Labores_comercia_hombres = (this.formSoc[0][74] === 'true');
        this.Labores_estudia_ninas = (this.formSoc[0][75] === 'true');
        this.Labores_estudia_ninos = (this.formSoc[0][76] === 'true');
        this.Labores_estudia_mujeres = (this.formSoc[0][77] === 'true');
        this.Labores_estudia_hombres = (this.formSoc[0][78] === 'true');
        this.Labores_formacion_hijos_ninas = (this.formSoc[0][79] === 'true');
        this.Labores_formacion_hijos_ninos = (this.formSoc[0][80] === 'true');
        this.Labores_formacion_hijos_mujeres = (this.formSoc[0][81] === 'true');
        this.Labores_formacion_hijos_hombres = (this.formSoc[0][82] === 'true');
        this.Labores_cuiado_mayores_enfermos_ninas = (this.formSoc[0][83] === 'true');
        this.Labores_cuiado_mayores_enfermos_ninos = (this.formSoc[0][84] === 'true');
        this.Labores_cuiado_mayores_enfermos_mujeres = (this.formSoc[0][85] === 'true');
        this.Labores_cuiado_mayores_enfermos_hombres = (this.formSoc[0][86] === 'true');
        this.Labores_otro_cual_ninas = (this.formSoc[0][87] === 'true');
        this.Labores_otro_cual_ninos = (this.formSoc[0][88] === 'true');
        this.Labores_otro_cual_mujeres = (this.formSoc[0][89] === 'true');
        this.Labores_otro_cual_hombres = (this.formSoc[0][90] === 'true');
        break;
      case 9:
        this.edit9 = !this.edit9;
        this.Ganaderia_caza_silvicultura_pesca = (this.formSoc[0][3] === 'true');
        this.Explotacion_minas_canteras = (this.formSoc[0][4] === 'true');
        this.Fabricacion_articulos_textiles_prendas_vestir = (this.formSoc[0][5] === 'true');
        this.Labores_Lab_domesticas_hombres = (this.formSoc[0][6] === 'true');
        this.Construccion = (this.formSoc[0][7] === 'true');
        this.Venta_productos_no_fabricados_hogar = (this.formSoc[0][8] === 'true');
        this.Elaboracion_procesamiento_alimentos_bebidas = (this.formSoc[0][9] === 'true');
        this.Servicio_restaurante_bar_similares = (this.formSoc[0][10] === 'true');
        this.Alojamiento = (this.formSoc[0][11] === 'true');
        this.Arrendamientos = (this.formSoc[0][12] === 'true');
        this.Servicios_actividades_reparacion_mantenimiento = (this.formSoc[0][13] === 'true');
        this.Servicios_artisticos_peluqueria = (this.formSoc[0][14] === 'true');
        this.Elaboracion_artesanias = (this.formSoc[0][15] === 'true');
        this.Agricultura = (this.formSoc[0][16] === 'true');
        this.Fuente_ingreso_otro_cual = this.formSoc[0][17];
        this.Gastos_mes_arriendo = this.formSoc[0][22];
        this.Gastos_mes_acueducto = this.formSoc[0][23];
        this.Gastos_mes_alcantarillado = this.formSoc[0][24];
        this.Gastos_mes_gas = this.formSoc[0][25];
        this.Gastos_mes_salud = this.formSoc[0][26];
        this.Gastos_mes_transporte = this.formSoc[0][27];
        this.Gastos_mes_educacion = this.formSoc[0][28];
        this.Gastos_mes_alimentacion = this.formSoc[0][29];
        this.Gastos_mes_recreacion = this.formSoc[0][30];
        this.Gastos_mes_vestuario = this.formSoc[0][31];
        this.Gastos_mes_energia_electrica = this.formSoc[0][32];
        this.Gastos_mes_otro_ban = this.formSoc[0][35];
        this.Gastos_mes_otro_cual = this.formSoc[0][33];
        this.Gastos_mes_otro_cual = this.formSoc[0][34];
        break;
      case 10:
        this.edit10 = !this.edit10;
        this.Uso_adecuado_energia = this.formEnergy[0][133];
        this.Escuchado_uso_racional_energia = this.formEnergy[0][134];
        this.Conocer_generacionyuso_energia = this.formEnergy[0][135];
        this.Temas_generacionyuso_energia = this.formEnergy[0][136];
        this.Compartir_saberes = this.formEnergy[0][137];
        break;
      case 11:
        this.edit11 = !this.edit11;
        this.Nombre_beneficiario_usuario = this.formData[0][3];
        this.Nombre_encuestado = this.formData[0][4];
        this.Telefono_celular_encuestado = this.formData[0][5];
        this.Telefono_fijo_encuestado = this.formData[0][6];
        this.Cedula_encuestado = this.formData[0][7];
        this.Correo_encuestado = this.formData[0][8];
        this.Constancia_uso_datos = (this.formData[0][9] === 'true');
        this.Explicado_posible_solucion = (this.formData[0][10] === 'true');
        this.Explicado_beneficio_limitacion = (this.formData[0][11] === 'true');
        this.Aprobacion_proyecto = (this.formData[0][12] === 'true');
        this.Autorizacion_fotos_videos = (this.formData[0][13] === 'true');
        this.Reaccion_preguntas = this.formData[0][17];
        this.Reaccion_proyecto = this.formData[0][18];
        this.Comentarios_encuestador = this.formData[0][16];
        break;
      case 12:
        this.edit12 = !this.edit12;
        this.Acceso_agua = this.formVivienda[0][51];
        this.Cantidad_agua_fuente = this.formData[0][52];
        this.Cantidad_agua_uso = this.formData[0][53];
        this.Fuente_agua = this.formData[0][54];
        this.Otra_fuente = this.formData[0][55];
        this.Profundidad = this.formData[0][56];
        this.Comunitario = this.formData[0][57];
        this.Cual_profundidad = this.formData[0][58];
        this.Uso_agua = this.formData[0][59];
        this.Otra_fuente = this.formData[0][60];
        this.Puesto_agua = this.formData[0][61];
        this.Tiempo_agua = this.formData[0][62];
        this.Agua_consumo = this.formData[0][63];
        this.Distancia = this.formData[0][64];
        this.Tiempo_recoleccion = this.formVivienda[0][65];
        this.Transporte_agua = this.formData[0][66];
        this.Otro_transporte = this.formData[0][67];
        this.Fuente_diferente = this.formData[0][68];
        this.Agua_semanal = this.formData[0][69];
        this.Fuente_alternativa = this.formData[0][70];
        this.Pago_fuente = this.formData[0][71];
        this.Cuanto = this.formData[0][72];
        this.Tratamiento_agua = this.formData[0][73];
        this.Otro_tratamiento = this.formData[0][74];
        this.Almacenamiento = this.formData[0][75];
        this.Almacenamiento_promedio = this.formData[0][76];
        this.Elementos_almacenamiento = this.formData[0][77];
        this.Limpieza_elementos = this.formData[0][78];
        this.Basura = this.formData[0][79];
        this.Recoleccion_basura = this.formData[0][80];
        this.Desechos_solidos = this.formData[0][81];
        this.Disposicion_desechos = this.formData[0][82];
        this.Aguas_negras = this.formData[0][83];
        this.Otro_tratamiento = this.formData[0][84];
        this.Aguas_residuales = this.formData[0][85];
        this.Otro_residuales = this.formData[0][86];
        break;

      default:
        break;
    }
  }
  confirmEdit1() {
    if (this.FE_electrica == undefined || this.FE_cocinar_prefiere_string == undefined || this.FE_cocinar_gaspropano_consumo_mes == undefined ||
      this.FE_cocinar_gaspropano_costo_mes == undefined || this.FE_cocinar_gasnatural_consumo_mes == undefined || this.FE_cocinar_gasnatural_costo_mes == undefined ||
      this.FE_cocinar_gasolina_consumo_mes == undefined || this.FE_cocinar_gasolina_costo_mes == undefined || this.FE_cocinar_kerosene_consumo_mes == undefined ||
      this.FE_cocinar_kerosene_costo_mes == undefined || this.FE_cocinar_petroleo_consumo_mes == undefined || this.FE_cocinar_petroleo_costo_mes == undefined ||
      this.FE_cocinar_alcohol_consumo_mes == undefined || this.FE_cocinar_alcohol_costo_mes == undefined || this.FE_cocinar_carbon_mineral_consumo_mes == undefined ||
      this.FE_cocinar_carbon_mineral_costo_mes == undefined || this.FE_cocinar_lena_comprada_consumo_mes == undefined || this.FE_cocinar_lena_comprada_costo_mes == undefined ||
      this.FE_cocinar_lena_auto_apropiada_consumo_mes == undefined || this.FE_cocinar_residuos_agro_consumo_mes == undefined || this.FE_cocinar_otros_ban == undefined ||
      this.FE_cocinar_otro_consumo_mes == undefined || this.FE_cocinar_otro_costo_mes == undefined || this.FE_iluminar_bateria_consumo_mes == undefined ||
      this.FE_iluminar_bateria_costo_mes == undefined || this.FE_iluminar_bateria_localizacion == undefined || this.FE_iluminar_planta_gasolina_consumo_mes == undefined ||
      this.FE_iluminar_planta_gasolina_costo_mes == undefined || this.FE_iluminar_planta_gasolina_localizacion == undefined || this.FE_iluminar_kerosene_consumo_mes == undefined ||
      this.FE_iluminar_kerosene_costo_mes == undefined || this.FE_iluminar_kerosene_localizacion == undefined || this.FE_iluminar_petroleo_consumo_mes == undefined ||
      this.FE_iluminar_petroleo_costo_mes == undefined || this.FE_iluminar_petroleo_localizacion == undefined || this.FE_iluminar_alcohol_consumo_mes == undefined ||
      this.FE_iluminar_alcohol_costo_mes == undefined || this.FE_iluminar_alcohol_localizacion == undefined || this.FE_iluminar_planta_diesel_consumo_mes == undefined ||
      this.FE_iluminar_planta_diesel_costo_mes == undefined || this.FE_iluminar_planta_diesel_localizacion == undefined || this.FE_iluminar_velas_consumo_mes == undefined ||
      this.FE_iluminar_velas_costo_mes == undefined || this.FE_iluminar_velas_localizacion == undefined || this.FE_iluminar_otro_ban == undefined ||
      this.FE_iluminar_otro_localizacion == undefined || this.FE_iluminar_otro_consumo_mes == undefined || this.FE_iluminar_otro_costo_mes == undefined ||
      this.tarifa_mensual == undefined || this.Voluntad_de_pago == undefined || this.Frecuencia_horaria_cocinar == undefined ||
      this.Frecuencia_horaria_iluminar == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.FE_electrica = this.formEnergy[0][3];
      this.FE_cocinar_prefiere.push(this.formEnergy[0][26]);
      this.FE_cocinar_prefiere_string = this.FE_cocinar_prefiere.toString();
      this.FE_cocinar_gaspropano_consumo_mes = this.formEnergy[0][4];
      this.FE_cocinar_gaspropano_costo_mes = this.formEnergy[0][16];
      this.FE_cocinar_gasnatural_consumo_mes = this.formEnergy[0][5];
      this.FE_cocinar_gasnatural_costo_mes = this.formEnergy[0][17];
      this.FE_cocinar_gasolina_consumo_mes = this.formEnergy[0][6];
      this.FE_cocinar_gasolina_costo_mes = this.formEnergy[0][18];
      this.FE_cocinar_kerosene_consumo_mes = this.formEnergy[0][7];
      this.FE_cocinar_kerosene_costo_mes = this.formEnergy[0][19];
      this.FE_cocinar_petroleo_consumo_mes = this.formEnergy[0][8];
      this.FE_cocinar_petroleo_costo_mes = this.formEnergy[0][20];
      this.FE_cocinar_alcohol_consumo_mes = this.formEnergy[0][9];
      this.FE_cocinar_alcohol_costo_mes = this.formEnergy[0][21];
      this.FE_cocinar_carbon_mineral_consumo_mes = this.formEnergy[0][10];
      this.FE_cocinar_carbon_mineral_costo_mes = this.formEnergy[0][22];
      this.FE_cocinar_lena_comprada_consumo_mes = this.formEnergy[0][11];
      this.FE_cocinar_lena_comprada_costo_mes = this.formEnergy[0][23];
      this.FE_cocinar_lena_auto_apropiada_consumo_mes = this.formEnergy[0][12];
      this.FE_cocinar_residuos_agro_consumo_mes = this.formEnergy[0][13];
      this.FE_cocinar_otros_ban = this.formEnergy[0][27];
      this.FE_cocinar_otro_consumo_mes = this.formEnergy[0][14];
      this.FE_cocinar_otro_costo_mes = this.formEnergy[0][25];
      this.FE_iluminar_usa.push(this.formEnergy[0][53]);
      this.FE_iluminar_prefiere_string = this.FE_iluminar_usa.toString();
      this.FE_iluminar_bateria_consumo_mes = this.formEnergy[0][28];
      this.FE_iluminar_bateria_costo_mes = this.formEnergy[0][36];
      this.FE_iluminar_bateria_localizacion = this.formEnergy[0][44];
      this.FE_iluminar_planta_gasolina_consumo_mes = this.formEnergy[0][29];
      this.FE_iluminar_planta_gasolina_costo_mes = this.formEnergy[0][37];
      this.FE_iluminar_planta_gasolina_localizacion = this.formEnergy[0][45];
      this.FE_iluminar_kerosene_consumo_mes = this.formEnergy[0][30];
      this.FE_iluminar_kerosene_costo_mes = this.formEnergy[0][38];
      this.FE_iluminar_kerosene_localizacion = this.formEnergy[0][46];
      this.FE_iluminar_petroleo_consumo_mes = this.formEnergy[0][31];
      this.FE_iluminar_petroleo_costo_mes = this.formEnergy[0][39];
      this.FE_iluminar_petroleo_localizacion = this.formEnergy[0][46];
      this.FE_iluminar_alcohol_consumo_mes = this.formEnergy[0][32];
      this.FE_iluminar_alcohol_costo_mes = this.formEnergy[0][40];
      this.FE_iluminar_alcohol_localizacion = this.formEnergy[0][47];
      this.FE_iluminar_planta_diesel_consumo_mes = this.formEnergy[0][33];
      this.FE_iluminar_planta_diesel_costo_mes = this.formEnergy[0][41];
      this.FE_iluminar_planta_diesel_localizacion = this.formEnergy[0][48];
      this.FE_iluminar_velas_consumo_mes = this.formEnergy[0][34];
      this.FE_iluminar_velas_costo_mes = this.formEnergy[0][42];
      this.FE_iluminar_velas_localizacion = this.formEnergy[0][49];
      this.FE_iluminar_otro_ban = this.formEnergy[0][52];
      this.FE_iluminar_otro_localizacion = this.formEnergy[0][51];
      this.FE_iluminar_otro_consumo_mes = this.formEnergy[0][35];
      this.FE_iluminar_otro_costo_mes = this.formEnergy[0][43];
      this.tarifa_mensual = this.formEnergy[0][123];
      this.Voluntad_de_pago = this.formEnergy[0][124];
      this.Frecuencia_horaria_cocinar = this.formEnergy[0][125];
      this.Frecuencia_horaria_iluminar = this.formEnergy[0][126];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.energia SET '
            + 'FE_electrica = ' + '\'' + this.FE_electrica + '\','
            + 'FE_cocinar_prefiere = ' + '\'' + this.FE_cocinar_prefiere_string + '\','
            + 'FE_cocinar_gaspropano_consumo_mes = ' + '\'' + this.FE_cocinar_gaspropano_consumo_mes + '\','
            + 'FE_cocinar_gaspropano_costo_mes = ' + '\'' + this.FE_cocinar_gaspropano_costo_mes + '\','
            + 'FE_cocinar_gasnatural_consumo_mes = ' + '\'' + this.FE_cocinar_gasnatural_consumo_mes + '\','
            + 'FE_cocinar_gasnatural_costo_mes = ' + '\'' + this.FE_cocinar_gasnatural_costo_mes + '\','
            + 'FE_cocinar_gasolina_consumo_mes = ' + '\'' + this.FE_cocinar_gasolina_consumo_mes + '\','
            + 'FE_cocinar_gasolina_costo_mes = ' + '\'' + this.FE_cocinar_gasolina_costo_mes + '\','
            + 'FE_cocinar_kerosene_consumo_mes = ' + '\'' + this.FE_cocinar_kerosene_consumo_mes + '\','
            + 'FE_cocinar_kerosene_costo_mes = ' + '\'' + this.FE_cocinar_kerosene_costo_mes + '\','
            + 'FE_cocinar_petroleo_consumo_mes = ' + '\'' + this.FE_cocinar_petroleo_consumo_mes + '\','
            + 'FE_cocinar_petroleo_costo_mes = ' + '\'' + this.FE_cocinar_petroleo_costo_mes + '\','
            + 'FE_cocinar_alcohol_consumo_mes = ' + '\'' + this.FE_cocinar_alcohol_consumo_mes + '\','
            + 'FE_cocinar_alcohol_costo_mes = ' + '\'' + this.FE_cocinar_alcohol_costo_mes + '\','
            + 'FE_cocinar_carbon_mineral_consumo_mes = ' + '\'' + this.FE_cocinar_carbon_mineral_consumo_mes + '\','
            + 'FE_cocinar_carbon_mineral_costo_mes = ' + '\'' + this.FE_cocinar_carbon_mineral_costo_mes + '\','
            + 'FE_cocinar_lena_comprada_consumo_mes = ' + '\'' + this.FE_cocinar_lena_comprada_consumo_mes + '\','
            + 'FE_cocinar_lena_comprada_costo_mes = ' + '\'' + this.FE_cocinar_lena_comprada_costo_mes + '\','
            + 'FE_cocinar_lena_auto_apropiada_consumo_mes = ' + '\'' + this.FE_cocinar_lena_auto_apropiada_consumo_mes + '\','
            + 'FE_cocinar_residuos_agro_consumo_mes = ' + '\'' + this.FE_cocinar_residuos_agro_consumo_mes + '\','
            + 'FE_cocinar_otros_ban = ' + '\'' + this.FE_cocinar_otros_ban + '\','
            + 'FE_cocinar_otro_consumo_mes = ' + '\'' + this.FE_cocinar_otro_consumo_mes + '\','
            + 'FE_cocinar_otro_cual_costo_mes = ' + '\'' + this.FE_cocinar_otro_costo_mes + '\','
            + 'FE_iluminar_usa = ' + '\'' + this.FE_iluminar_prefiere_string + '\','
            + 'FE_iluminar_bateria_consumo_mes = ' + '\'' + this.FE_iluminar_bateria_consumo_mes + '\','
            + 'FE_iluminar_bateria_costo_mes = ' + '\'' + this.FE_iluminar_bateria_costo_mes + '\','
            + 'FE_iluminar_bateria_localizacion = ' + '\'' + this.FE_iluminar_bateria_localizacion + '\','
            + 'FE_iluminar_planta_gasolina_consumo_mes = ' + '\'' + this.FE_iluminar_planta_gasolina_consumo_mes + '\','
            + 'FE_iluminar_planta_gasolina_costo_mes = ' + '\'' + this.FE_iluminar_planta_gasolina_costo_mes + '\','
            + 'FE_iluminar_planta_gasolina_localizacion = ' + '\'' + this.FE_iluminar_planta_gasolina_localizacion + '\','
            + 'FE_iluminar_kerosene_consumo_mes = ' + '\'' + this.FE_iluminar_kerosene_consumo_mes + '\','
            + 'FE_iluminar_kerosene_costo_mes = ' + '\'' + this.FE_iluminar_kerosene_costo_mes + '\','
            + 'FE_iluminar_kerosene_localizacion = ' + '\'' + this.FE_iluminar_kerosene_localizacion + '\','
            + 'FE_iluminar_petroleo_consumo_mes = ' + '\'' + this.FE_iluminar_petroleo_consumo_mes + '\','
            + 'FE_iluminar_petroleo_costo_mes = ' + '\'' + this.FE_iluminar_petroleo_costo_mes + '\','
            + 'FE_iluminar_petroleo_localizacion = ' + '\'' + this.FE_iluminar_petroleo_localizacion + '\','
            + 'FE_iluminar_alcohol_consumo_mes = ' + '\'' + this.FE_iluminar_alcohol_consumo_mes + '\','
            + 'FE_iluminar_alcohol_costo_mes = ' + '\'' + this.FE_iluminar_alcohol_costo_mes + '\','
            + 'FE_iluminar_alcohol_localizacion = ' + '\'' + this.FE_iluminar_alcohol_localizacion + '\','
            + 'FE_iluminar_planta_diesel_consumo_mes = ' + '\'' + this.FE_iluminar_planta_diesel_consumo_mes + '\','
            + 'FE_iluminar_planta_diesel_costo_mes = ' + '\'' + this.FE_iluminar_planta_diesel_costo_mes + '\','
            + 'FE_iluminar_planta_diesel_localizacion = ' + '\'' + this.FE_iluminar_planta_diesel_localizacion + '\','
            + 'FE_iluminar_velas_consumo_mes = ' + '\'' + this.FE_iluminar_velas_consumo_mes + '\','
            + 'FE_iluminar_velas_costo_mes = ' + '\'' + this.FE_iluminar_velas_costo_mes + '\','
            + 'FE_iluminar_velas_localizacion = ' + '\'' + this.FE_iluminar_velas_localizacion + '\','
            + 'FE_iluminar_otro_ban = ' + '\'' + this.FE_iluminar_otro_ban + '\','
            + 'FE_iluminar_otro_localizacion = ' + '\'' + this.FE_iluminar_otro_localizacion + '\','
            + 'FE_iluminar_otro_consumo_mes = ' + '\'' + this.FE_iluminar_otro_consumo_mes + '\','
            + 'FE_iluminar_otro_costo_mes = ' + '\'' + this.FE_iluminar_otro_costo_mes + '\','
            + 'tarifa_mensual = ' + '\'' + this.tarifa_mensual + '\','
            + 'Voluntad_de_pago = ' + '\'' + this.Voluntad_de_pago + '\','
            + 'Frecuencia_horaria_cocinar = ' + '\'' + this.Frecuencia_horaria_cocinar + '\','
            + 'Frecuencia_horaria_iluminar = ' + '\'' + this.Frecuencia_horaria_iluminar + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit1 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit1 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit2() {
    if (
      this.Medidor == undefined || this.SerialM == undefined || this.TipoIns == undefined ||
      this.LecturaC == undefined || this.LecturaV == undefined || this.Irregular == undefined ||
      this.Instalaciones_Int == undefined || this.Acometida == undefined || this.Puesta_Tierra == undefined ||
      this.DistanciaN == undefined || this.Tipo_Usuario == undefined || this.Tipo_Negocio == undefined ||
      this.Nevera_Cantidad == undefined || this.Nevera_Watts == undefined || this.Nevera_Uso == undefined ||
      this.Refrigerador_Cantidad == undefined || this.Refrigerador_Watts == undefined || this.Refrigerador_Uso == undefined ||
      this.AireA_Cantidad == undefined || this.AireA_Watts == undefined || this.AireA_Uso == undefined ||
      this.Ventilador_Cantidad == undefined || this.Ventilador_Watts == undefined || this.Ventilador_Uso == undefined ||
      this.Electrobomba_Cantidad == undefined || this.Electrobomba_Watts == undefined || this.Electrobomba_Uso == undefined ||
      this.Televisor_Cantidad == undefined || this.Televisor_Watts == undefined || this.Televisor_Uso == undefined ||
      this.EquipoS_Cantidad == undefined || this.EquipoS_Watts == undefined || this.EquipoS_Uso == undefined ||
      this.Lavadora_Cantidad == undefined || this.Lavadora_Watts == undefined || this.Lavadora_Uso == undefined ||
      this.Licuadora_Cantidad == undefined || this.Licuadora_Watts == undefined || this.Licuadora_Uso == undefined ||
      this.Plancha_Cantidad == undefined || this.Plancha_Watts == undefined || this.Plancha_Uso == undefined ||
      this.HornoM_Cantidad == undefined || this.HornoM_Watts == undefined || this.HornoM_Uso == undefined ||
      this.HornoE_Cantidad == undefined || this.HornoE_Watts == undefined || this.HornoE_Uso == undefined ||
      this.Computador_Cantidad == undefined || this.Computador_Watts == undefined || this.Computador_Uso == undefined ||
      this.Celulares_Cantidad == undefined || this.Celulares_Watts == undefined || this.Celulares_Uso == undefined ||
      this.LamparasI_Cantidad == undefined || this.LamparasI_Watts == undefined || this.LamparasI_Uso == undefined ||
      this.LED_Cantidad == undefined || this.LED_Watts == undefined || this.LED_Uso == undefined ||
      this.Otro_Cantidad == undefined || this.Otro_Watts == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Medidor = this.formEnergy[0][54];
      this.SerialM = this.formEnergy[0][55];
      this.TipoIns = this.formEnergy[0][56];
      this.LecturaC = this.formEnergy[0][57];
      this.LecturaV = this.formEnergy[0][58];
      this.Irregular = this.formEnergy[0][59];
      this.Instalaciones_Int = this.formEnergy[0][60];
      this.Acometida = this.formEnergy[0][61];
      this.Puesta_Tierra = this.formEnergy[0][62];
      this.DistanciaN = this.formEnergy[0][63];
      this.Tipo_Usuario = this.formEnergy[0][64];
      this.Tipo_Negocio = this.formEnergy[0][65];
      this.Nevera_Cantidad = this.formEnergy[0][66];
      this.Nevera_Watts = this.formEnergy[0][67];
      this.Nevera_Uso = this.formEnergy[0][68];
      this.Refrigerador_Cantidad = this.formEnergy[0][69];
      this.Refrigerador_Watts = this.formEnergy[0][70];
      this.Refrigerador_Uso = this.formEnergy[0][71];
      this.AireA_Cantidad = this.formEnergy[0][72];
      this.AireA_Watts = this.formEnergy[0][73];
      this.AireA_Uso = this.formEnergy[0][74];
      this.Ventilador_Cantidad = this.formEnergy[0][75];
      this.Ventilador_Watts = this.formEnergy[0][76];
      this.Ventilador_Uso = this.formEnergy[0][77];
      this.Electrobomba_Cantidad = this.formEnergy[0][78];
      this.Electrobomba_Watts = this.formEnergy[0][79];
      this.Electrobomba_Uso = this.formEnergy[0][80];
      this.Televisor_Cantidad = this.formEnergy[0][81];
      this.Televisor_Watts = this.formEnergy[0][82];
      this.Televisor_Uso = this.formEnergy[0][83];
      this.EquipoS_Cantidad = this.formEnergy[0][84];
      this.EquipoS_Watts = this.formEnergy[0][85];
      this.EquipoS_Uso = this.formEnergy[0][86];
      this.Lavadora_Cantidad = this.formEnergy[0][87];
      this.Lavadora_Watts = this.formEnergy[0][88];
      this.Lavadora_Uso = this.formEnergy[0][89];
      this.Licuadora_Cantidad = this.formEnergy[0][90];
      this.Licuadora_Watts = this.formEnergy[0][91];
      this.Licuadora_Uso = this.formEnergy[0][92];
      this.Plancha_Cantidad = this.formEnergy[0][93];
      this.Plancha_Watts = this.formEnergy[0][94];
      this.Plancha_Uso = this.formEnergy[0][95];
      this.HornoM_Cantidad = this.formEnergy[0][96];
      this.HornoM_Watts = this.formEnergy[0][97];
      this.HornoM_Uso = this.formEnergy[0][98];
      this.HornoE_Cantidad = this.formEnergy[0][99];
      this.HornoE_Watts = this.formEnergy[0][100];
      this.HornoE_Uso = this.formEnergy[0][101];
      this.Computador_Cantidad = this.formEnergy[0][102];
      this.Computador_Watts = this.formEnergy[0][103];
      this.Computador_Uso = this.formEnergy[0][104];
      this.Celulares_Cantidad = this.formEnergy[0][105];
      this.Celulares_Watts = this.formEnergy[0][106];
      this.Celulares_Uso = this.formEnergy[0][107];
      this.LamparasI_Cantidad = this.formEnergy[0][108];
      this.LamparasI_Watts = this.formEnergy[0][109];
      this.LamparasI_Uso = this.formEnergy[0][110];
      this.LED_Cantidad = this.formEnergy[0][111];
      this.LED_Watts = this.formEnergy[0][112];
      this.LED_Uso = this.formEnergy[0][113];
      this.Otro_Cantidad = this.formEnergy[0][115];
      this.Otro_Watts = this.formEnergy[0][116];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.energia SET '
            + 'Medidor = ' + '\'' + this.Medidor + '\','
            + 'SerialM = ' + '\'' + this.SerialM + '\','
            + 'TipoIns = ' + '\'' + this.TipoIns + '\','
            + 'LecturaC = ' + '\'' + this.LecturaC + '\','
            + 'LecturaV = ' + '\'' + this.LecturaV + '\','
            + 'Irregular = ' + '\'' + this.Irregular + '\','
            + 'Instalaciones_Int = ' + '\'' + this.Instalaciones_Int + '\','
            + 'Acometida = ' + '\'' + this.Acometida + '\','
            + 'Puesta_Tierra = ' + '\'' + this.Puesta_Tierra + '\','
            + 'DistanciaN = ' + '\'' + this.DistanciaN + '\','
            + 'Tipo_Usuario = ' + '\'' + this.Tipo_Usuario + '\','
            + 'Tipo_Negocio = ' + '\'' + this.Tipo_Negocio + '\','
            + 'Nevera_Cantidad = ' + '\'' + this.Nevera_Cantidad + '\','
            + 'Nevera_Watts = ' + '\'' + this.Nevera_Watts + '\','
            + 'Nevera_Uso = ' + '\'' + this.Nevera_Uso + '\','
            + 'Refrigerador_Cantidad = ' + '\'' + this.Refrigerador_Cantidad + '\','
            + 'Refrigerador_Watts = ' + '\'' + this.Refrigerador_Watts + '\','
            + 'Refrigerador_Uso = ' + '\'' + this.Refrigerador_Uso + '\','
            + 'AireA_Cantidad = ' + '\'' + this.AireA_Cantidad + '\','
            + 'AireA_Watts = ' + '\'' + this.AireA_Watts + '\','
            + 'AireA_Uso = ' + '\'' + this.AireA_Uso + '\','
            + 'Ventilador_Cantidad = ' + '\'' + this.Ventilador_Cantidad + '\','
            + 'Ventilador_Watts = ' + '\'' + this.Ventilador_Watts + '\','
            + 'Ventilador_Uso = ' + '\'' + this.Ventilador_Uso + '\','
            + 'Electrobomba_Cantidad = ' + '\'' + this.Electrobomba_Cantidad + '\','
            + 'Electrobomba_Watts = ' + '\'' + this.Electrobomba_Watts + '\','
            + 'Electrobomba_Uso = ' + '\'' + this.Electrobomba_Uso + '\','
            + 'Televisor_Cantidad = ' + '\'' + this.Televisor_Cantidad + '\','
            + 'Televisor_Watts = ' + '\'' + this.Televisor_Watts + '\','
            + 'Televisor_Uso = ' + '\'' + this.Televisor_Uso + '\','
            + 'EquipoS_Cantidad = ' + '\'' + this.EquipoS_Cantidad + '\','
            + 'EquipoS_Watts = ' + '\'' + this.EquipoS_Watts + '\','
            + 'EquipoS_Uso = ' + '\'' + this.EquipoS_Uso + '\','
            + 'Lavadora_Cantidad = ' + '\'' + this.Lavadora_Cantidad + '\','
            + 'Lavadora_Watts = ' + '\'' + this.Lavadora_Watts + '\','
            + 'Lavadora_Uso = ' + '\'' + this.Lavadora_Uso + '\','
            + 'Licuadora_Cantidad = ' + '\'' + this.Licuadora_Cantidad + '\','
            + 'Licuadora_Watts = ' + '\'' + this.Licuadora_Watts + '\','
            + 'Licuadora_Uso = ' + '\'' + this.Licuadora_Uso + '\','
            + 'Plancha_Cantidad = ' + '\'' + this.Plancha_Cantidad + '\','
            + 'Plancha_Watts = ' + '\'' + this.Plancha_Watts + '\','
            + 'Plancha_Uso = ' + '\'' + this.Plancha_Uso + '\','
            + 'HornoM_Cantidad = ' + '\'' + this.HornoM_Cantidad + '\','
            + 'HornoM_Watts = ' + '\'' + this.HornoM_Watts + '\','
            + 'HornoM_Uso = ' + '\'' + this.HornoM_Uso + '\','
            + 'HornoE_Cantidad = ' + '\'' + this.HornoE_Cantidad + '\','
            + 'HornoE_Watts = ' + '\'' + this.HornoE_Watts + '\','
            + 'HornoE_Uso = ' + '\'' + this.HornoE_Uso + '\','
            + 'Computador_Cantidad = ' + '\'' + this.Computador_Cantidad + '\','
            + 'Computador_Watts = ' + '\'' + this.Computador_Watts + '\','
            + 'Computador_Uso = ' + '\'' + this.Computador_Uso + '\','
            + 'Celulares_Cantidad = ' + '\'' + this.Celulares_Cantidad + '\','
            + 'Celulares_Watts = ' + '\'' + this.Celulares_Watts + '\','
            + 'Celulares_Uso = ' + '\'' + this.Celulares_Uso + '\','
            + 'LamparasI_Cantidad = ' + '\'' + this.LamparasI_Cantidad + '\','
            + 'LamparasI_Watts = ' + '\'' + this.LamparasI_Watts + '\','
            + 'LamparasI_Uso = ' + '\'' + this.LamparasI_Uso + '\','
            + 'LED_Cantidad = ' + '\'' + this.LED_Cantidad + '\','
            + 'LED_Watts = ' + '\'' + this.LED_Watts + '\','
            + 'LED_Uso = ' + '\'' + this.LED_Uso + '\','
            + 'Otro_Cantidad = ' + '\'' + this.Otro_Cantidad + '\','
            + 'Otro_Watts = ' + '\'' + this.Otro_Watts + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit2 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit2 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit3() {
    if (
      this.Contaminacion_ruido == undefined || this.Contaminacion_olores == undefined || this.Planea_implementar_proyecto == undefined ||
      this.Cual_proyecto == undefined || this.Requiere_energia_proyecto == undefined || this.tarifa_mensual == undefined ||
      this.Voluntad_de_pago == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Contaminacion_ruido = this.formEnergy[0][118];
      this.Contaminacion_olores = this.formEnergy[0][119];
      this.Planea_implementar_proyecto = this.formEnergy[0][120];
      this.Cual_proyecto = this.formEnergy[0][121];
      this.Requiere_energia_proyecto = this.formEnergy[0][122];
      this.tarifa_mensual = this.formEnergy[0][123];
      this.Voluntad_de_pago = this.formEnergy[0][124];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.energia SET '
            + 'Contaminacion_ruido = ' + '\'' + this.Contaminacion_ruido + '\','
            + 'Contaminacion_olores = ' + '\'' + this.Contaminacion_olores + '\','
            + 'Planea_implementar_proyecto = ' + '\'' + this.Planea_implementar_proyecto + '\','
            + 'Cual_proyecto = ' + '\'' + this.Cual_proyecto + '\','
            + 'Requiere_energia_proyecto = ' + '\'' + this.Requiere_energia_proyecto + '\','
            + 'tarifa_mensual = ' + '\'' + this.tarifa_mensual + '\','
            + 'Voluntad_de_pago = ' + '\'' + this.Voluntad_de_pago + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit3 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit3 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit4() {
    if (
      this.Uso_predio == undefined ||
      this.Estrato_predio == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Uso_predio = this.formPredio[0][3];
      this.Estrato_predio = this.formPredio[0][4];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.caracteristicas_predio SET '
            + 'Uso_predio = ' + '\'' + this.Uso_predio + '\','
            + 'Estrato_predio = ' + '\'' + this.Estrato_predio + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit4 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit4 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit5() {
    if (
      this.Nombre_comunidad == undefined || this.Territorialidad == undefined || this.Tenencia_posesion == undefined ||
      this.Hogares_vivienda == undefined || this.Personas_vivienda == undefined || this.Paredes_exteriores == undefined ||
      this.Techo_cubierta == undefined || this.Pisos == undefined || this.Afectacion_inundacion == undefined ||
      this.Afectacion_avalancha == undefined || this.Afectacion_hundimiento == undefined || this.Afectacion_tormentas == undefined ||
      this.Afectacion_descargas == undefined || this.area_predio == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Nombre_comunidad = this.formVivienda[0][3];
      this.Territorialidad = this.formVivienda[0][4];
      this.Tenencia_posesion = this.formVivienda[0][5];
      this.Hogares_vivienda = this.formVivienda[0][6];
      this.Personas_vivienda = this.formVivienda[0][7];
      this.Paredes_exteriores = this.formVivienda[0][8];
      this.Techo_cubierta = this.formVivienda[0][9];
      this.Pisos = this.formVivienda[0][10];
      this.Afectacion_inundacion = this.formVivienda[0][11];
      this.Afectacion_avalancha = this.formVivienda[0][12];
      this.Afectacion_hundimiento = this.formVivienda[0][13];
      this.Afectacion_tormentas = this.formVivienda[0][14];
      this.Afectacion_descargas = this.formVivienda[0][15];
      this.area_predio = this.formVivienda[0][16];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.datos_vivienda_I SET '
            + 'Nombre_comunidad = ' + '\'' + this.Nombre_comunidad + '\','
            + 'Territorialidad = ' + '\'' + this.Territorialidad + '\','
            + 'Tenencia_posesion = ' + '\'' + this.Tenencia_posesion + '\','
            + 'Hogares_vivienda = ' + '\'' + this.Hogares_vivienda + '\','
            + 'Personas_vivienda = ' + '\'' + this.Personas_vivienda + '\','
            + 'Paredes_exteriores = ' + '\'' + this.Paredes_exteriores + '\','
            + 'Techo_cubierta = ' + '\'' + this.Techo_cubierta + '\','
            + 'Pisos = ' + '\'' + this.Pisos + '\','
            + 'Afectacion_inundacion = ' + '\'' + this.Afectacion_inundacion + '\','
            + 'Afectacion_avalancha = ' + '\'' + this.Afectacion_avalancha + '\','
            + 'Afectacion_hundimiento = ' + '\'' + this.Afectacion_hundimiento + '\','
            + 'Afectacion_tormentas = ' + '\'' + this.Afectacion_tormentas + '\','
            + 'Afectacion_descargas = ' + '\'' + this.Afectacion_descargas + '\','
            + 'area_predio = ' + '\'' + this.area_predio + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit5 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit5 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit6() {
    if (this.Telefono_fijo_propio == undefined || this.Telefono_fijo_comunitario == undefined || this.Celular == undefined ||
      this.Internet_comunitario == undefined || this.Internet_propio == undefined || this.Ninguna_anteriores == undefined ||
      this.Acueducto_domiciliario_publico == undefined || this.Medidor_agua == undefined || this.Pila_medidor_agua_publica == undefined ||
      this.Aljibe == undefined || this.Rio_quebrada_manantial_nacimiento == undefined || this.Pozo_dentro_vivienda == undefined ||
      this.Agua_lluvia == undefined || this.Carrotanque == undefined || this.Aguatero_embotellada_bolsa == undefined ||
      this.Inodoro_conectado_alcantarillado == undefined || this.Inodoro_conectado_pozo_septico == undefined || this.Inodoro_sin_conexion == undefined ||
      this.Letrina == undefined || this.Vivienda_institucion_sin_servicio_sanitario == undefined || this.Gas == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Telefono_fijo_propio = (this.formVivienda[0][23] === 'true');
      this.Telefono_fijo_comunitario = (this.formVivienda[0][24] === 'true');
      this.Celular = (this.formVivienda[0][25] === 'true');
      this.Internet_comunitario = (this.formVivienda[0][26] === 'true');
      this.Internet_propio = (this.formVivienda[0][27] === 'true');
      this.Ninguna_anteriores = (this.formVivienda[0][28] === 'true');
      this.Acueducto_domiciliario_publico = (this.formVivienda[0][29] === 'true');
      this.Medidor_agua = (this.formVivienda[0][30] === 'true');
      this.Pila_medidor_agua_publica = (this.formVivienda[0][31] === 'true');
      this.Aljibe = (this.formVivienda[0][32] === 'true');
      this.Rio_quebrada_manantial_nacimiento = (this.formVivienda[0][33] === 'true');
      this.Pozo_dentro_vivienda = (this.formVivienda[0][34] === 'true');
      this.Agua_lluvia = (this.formVivienda[0][35] === 'true');
      this.Carrotanque = (this.formVivienda[0][36] === 'true');
      this.Aguatero_embotellada_bolsa = (this.formVivienda[0][37] === 'true');
      this.Inodoro_conectado_alcantarillado = (this.formVivienda[0][38] === 'true');
      this.Inodoro_conectado_pozo_septico = (this.formVivienda[0][39] === 'true');
      this.Inodoro_sin_conexion = (this.formVivienda[0][40] === 'true');
      this.Letrina = (this.formVivienda[0][41] === 'true');
      this.Vivienda_institucion_sin_servicio_sanitario = (this.formVivienda[0][42] === 'true');
      this.Gas = this.formVivienda[0][44];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.servicios_publicos SET '
            + 'Telefono_fijo_propio = ' + '\'' + this.Telefono_fijo_propio + '\','
            + 'Telefono_fijo_comunitario = ' + '\'' + this.Telefono_fijo_comunitario + '\','
            + 'Celular = ' + '\'' + this.Celular + '\','
            + 'Internet_comunitario = ' + '\'' + this.Internet_comunitario + '\','
            + 'Internet_propio = ' + '\'' + this.Internet_propio + '\','
            + 'Paredes_exteriores = ' + '\'' + this.Paredes_exteriores + '\','
            + 'Ninguna_anteriores = ' + '\'' + this.Ninguna_anteriores + '\','
            + 'Acueducto_domiciliario_publico = ' + '\'' + this.Acueducto_domiciliario_publico + '\','
            + 'Medidor_agua = ' + '\'' + this.Medidor_agua + '\','
            + 'Aljibe = ' + '\'' + this.Aljibe + '\','
            + 'Rio_quebrada_manantial_nacimiento = ' + '\'' + this.Rio_quebrada_manantial_nacimiento + '\','
            + 'Pozo_dentro_vivienda = ' + '\'' + this.Pozo_dentro_vivienda + '\','
            + 'Agua_lluvia = ' + '\'' + this.Agua_lluvia + '\','
            + 'Carrotanque = ' + '\'' + this.Carrotanque + '\','
            + 'Aguatero_embotellada_bolsa = ' + '\'' + this.Aguatero_embotellada_bolsa + '\','
            + 'Inodoro_conectado_alcantarillado = ' + '\'' + this.Inodoro_conectado_alcantarillado + '\','
            + 'Inodoro_conectado_pozo_septico = ' + '\'' + this.Inodoro_conectado_pozo_septico + '\','
            + 'Inodoro_sin_conexion = ' + '\'' + this.Inodoro_sin_conexion + '\','
            + 'Letrina = ' + '\'' + this.Letrina + '\','
            + 'Vivienda_institucion_sin_servicio_sanitario = ' + '\'' + this.Vivienda_institucion_sin_servicio_sanitario + '\','
            + 'Gas = ' + '\'' + this.Gas + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit6 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit6 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit7() {
    
    if ( this.Permanencia_vivienda == undefined ||  this.Ingresos_vivienda == undefined ||  this.Reconocimiento == undefined ||
      this.Lengua_nativa == undefined ||  this.Nombre_lengua_nativa == undefined ||  this.Problemas_uso_lena == undefined ||
      this.Organizacion == undefined ||  this.Nombre_organizacion == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.c_sociodemograficas SET '
            + 'Permanencia_vivienda = ' + '\'' + this.Permanencia_vivienda + '\','
            + 'Ingresos_vivienda = ' + '\'' + this.Ingresos_vivienda + '\','
            + 'Reconocimiento = ' + '\'' + this.Reconocimiento + '\','
            + 'Lengua_nativa = ' + '\'' + this.Lengua_nativa + '\','
            + 'Nombre_lengua_nativa = ' + '\'' + this.Nombre_lengua_nativa + '\','
            + 'Problemas_uso_lena = ' + '\'' + this.Problemas_uso_lena + '\','
            + 'Organizacion = ' + '\'' + this.Organizacion + '\','
            + 'Nombre_organizacion = ' + '\'' + this.Nombre_organizacion + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit7 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit7 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit8() {
    if (
      this.Labores_Lab_domesticas_ninas == undefined || this.Labores_Lab_domesticas_ninos == undefined || this.Labores_Lab_domesticas_mujeres == undefined ||
      this.Labores_Lab_domesticas_hombres == undefined || this.Labores_pagosycompras_ninas == undefined || this.Labores_pagosycompras_ninos == undefined ||
      this.Labores_pagosycompras_mujeres == undefined || this.Labores_pagosycompras_hombres == undefined || this.Labores_lab_finca_ninas == undefined ||
      this.Labores_lab_finca_ninos == undefined || this.Labores_lab_finca_mujeres == undefined || this.Labores_lab_finca_hombres == undefined ||
      this.Labores_transporte_ninas == undefined || this.Labores_transporte_ninos == undefined || this.Labores_transporte_mujeres == undefined ||
      this.Labores_transporte_hombres == undefined || this.Labores_admon_finca_ninas == undefined || this.Labores_admon_finca_ninos == undefined ||
      this.Labores_admon_finca_mujeres == undefined || this.Labores_admon_finca_hombres == undefined || this.Labores_comercia_ninas == undefined ||
      this.Labores_comercia_ninos == undefined || this.Labores_comercia_mujeres == undefined || this.Labores_comercia_hombres == undefined ||
      this.Labores_estudia_ninas == undefined || this.Labores_estudia_ninos == undefined || this.Labores_estudia_mujeres == undefined ||
      this.Labores_estudia_hombres == undefined || this.Labores_formacion_hijos_ninas == undefined || this.Labores_formacion_hijos_ninos == undefined ||
      this.Labores_formacion_hijos_mujeres == undefined || this.Labores_formacion_hijos_hombres == undefined || this.Labores_cuiado_mayores_enfermos_ninas == undefined ||
      this.Labores_cuiado_mayores_enfermos_ninos == undefined || this.Labores_cuiado_mayores_enfermos_mujeres == undefined || this.Labores_cuiado_mayores_enfermos_hombres == undefined ||
      this.Labores_otro_cual_ninas == undefined || this.Labores_otro_cual_ninos == undefined || this.Labores_otro_cual_mujeres == undefined ||
      this.Labores_otro_cual_hombres == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Labores_Lab_domesticas_ninas = (this.formSoc[0][51] === 'true');
      this.Labores_Lab_domesticas_ninos = (this.formSoc[0][52] === 'true');
      this.Labores_Lab_domesticas_mujeres = (this.formSoc[0][53] === 'true');
      this.Labores_Lab_domesticas_hombres = (this.formSoc[0][54] === 'true');
      this.Labores_pagosycompras_ninas = (this.formSoc[0][55] === 'true');
      this.Labores_pagosycompras_ninos = (this.formSoc[0][56] === 'true');
      this.Labores_pagosycompras_mujeres = (this.formSoc[0][57] === 'true');
      this.Labores_pagosycompras_hombres = (this.formSoc[0][58] === 'true');
      this.Labores_lab_finca_ninas = (this.formSoc[0][59] === 'true');
      this.Labores_lab_finca_ninos = (this.formSoc[0][60] === 'true');
      this.Labores_lab_finca_mujeres = (this.formSoc[0][61] === 'true');
      this.Labores_lab_finca_hombres = (this.formSoc[0][62] === 'true');
      this.Labores_transporte_ninas = (this.formSoc[0][63] === 'true');
      this.Labores_transporte_ninos = (this.formSoc[0][64] === 'true');
      this.Labores_transporte_mujeres = (this.formSoc[0][65] === 'true');
      this.Labores_transporte_hombres = (this.formSoc[0][66] === 'true');
      this.Labores_admon_finca_ninas = (this.formSoc[0][67] === 'true');
      this.Labores_admon_finca_ninos = (this.formSoc[0][68] === 'true');
      this.Labores_admon_finca_mujeres = (this.formSoc[0][69] === 'true');
      this.Labores_admon_finca_hombres = (this.formSoc[0][70] === 'true');
      this.Labores_comercia_ninas = (this.formSoc[0][71] === 'true');
      this.Labores_comercia_ninos = (this.formSoc[0][72] === 'true');
      this.Labores_comercia_mujeres = (this.formSoc[0][73] === 'true');
      this.Labores_comercia_hombres = (this.formSoc[0][74] === 'true');
      this.Labores_estudia_ninas = (this.formSoc[0][75] === 'true');
      this.Labores_estudia_ninos = (this.formSoc[0][76] === 'true');
      this.Labores_estudia_mujeres = (this.formSoc[0][77] === 'true');
      this.Labores_estudia_hombres = (this.formSoc[0][78] === 'true');
      this.Labores_formacion_hijos_ninas = (this.formSoc[0][79] === 'true');
      this.Labores_formacion_hijos_ninos = (this.formSoc[0][80] === 'true');
      this.Labores_formacion_hijos_mujeres = (this.formSoc[0][81] === 'true');
      this.Labores_formacion_hijos_hombres = (this.formSoc[0][82] === 'true');
      this.Labores_cuiado_mayores_enfermos_ninas = (this.formSoc[0][83] === 'true');
      this.Labores_cuiado_mayores_enfermos_ninos = (this.formSoc[0][84] === 'true');
      this.Labores_cuiado_mayores_enfermos_mujeres = (this.formSoc[0][85] === 'true');
      this.Labores_cuiado_mayores_enfermos_hombres = (this.formSoc[0][86] === 'true');
      this.Labores_otro_cual_ninas = (this.formSoc[0][87] === 'true');
      this.Labores_otro_cual_ninos = (this.formSoc[0][88] === 'true');
      this.Labores_otro_cual_mujeres = (this.formSoc[0][89] === 'true');
      this.Labores_otro_cual_hombres = (this.formSoc[0][90] === 'true');
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.c_sociodemograficas SET '
            + 'Labores_Lab_domesticas_ninas = ' + '\'' + this.Labores_Lab_domesticas_ninas + '\','
            + 'Labores_Lab_domesticas_ninos = ' + '\'' + this.Labores_Lab_domesticas_ninos + '\','
            + 'Labores_Lab_domesticas_mujeres = ' + '\'' + this.Labores_Lab_domesticas_mujeres + '\','
            + 'Labores_Lab_domesticas_hombres = ' + '\'' + this.Labores_Lab_domesticas_hombres + '\','
            + 'Labores_pagosycompras_ninas = ' + '\'' + this.Labores_pagosycompras_ninas + '\','
            + 'Labores_pagosycompras_ninos = ' + '\'' + this.Labores_pagosycompras_ninos + '\','
            + 'Labores_pagosycompras_mujeres = ' + '\'' + this.Labores_pagosycompras_mujeres + '\','
            + 'Labores_pagosycompras_hombres = ' + '\'' + this.Labores_pagosycompras_hombres + '\','
            + 'Labores_lab_finca_ninas = ' + '\'' + this.Labores_lab_finca_ninas + '\','
            + 'Labores_lab_finca_ninos = ' + '\'' + this.Labores_lab_finca_ninos + '\','
            + 'Labores_lab_finca_mujeres = ' + '\'' + this.Labores_lab_finca_mujeres + '\','
            + 'Labores_lab_finca_hombres = ' + '\'' + this.Labores_lab_finca_hombres + '\','
            + 'Labores_transporte_ninas = ' + '\'' + this.Labores_transporte_ninas + '\','
            + 'Labores_transporte_ninos = ' + '\'' + this.Labores_transporte_ninos + '\','
            + 'Labores_transporte_mujeres = ' + '\'' + this.Labores_transporte_mujeres + '\','
            + 'Labores_transporte_hombres = ' + '\'' + this.Labores_transporte_hombres + '\','
            + 'Labores_admon_finca_ninas = ' + '\'' + this.Labores_admon_finca_ninas + '\','
            + 'Labores_admon_finca_ninos = ' + '\'' + this.Labores_admon_finca_ninos + '\','
            + 'Labores_admon_finca_mujeres = ' + '\'' + this.Labores_admon_finca_mujeres + '\','
            + 'Labores_admon_finca_hombres = ' + '\'' + this.Labores_admon_finca_hombres + '\','
            + 'Labores_comercia_ninas = ' + '\'' + this.Labores_comercia_ninas + '\','
            + 'Labores_comercia_ninos = ' + '\'' + this.Labores_comercia_ninos + '\','
            + 'Labores_comercia_mujeres = ' + '\'' + this.Labores_comercia_mujeres + '\','
            + 'Labores_comercia_hombres = ' + '\'' + this.Labores_comercia_hombres + '\','
            + 'Labores_estudia_ninas = ' + '\'' + this.Labores_estudia_ninas + '\','
            + 'Labores_estudia_ninos = ' + '\'' + this.Labores_estudia_ninos + '\','
            + 'Labores_estudia_mujeres = ' + '\'' + this.Labores_estudia_mujeres + '\','
            + 'Labores_estudia_hombres = ' + '\'' + this.Labores_estudia_hombres + '\','
            + 'Labores_formacion_hijos_ninas = ' + '\'' + this.Labores_formacion_hijos_ninas + '\','
            + 'Labores_formacion_hijos_ninos = ' + '\'' + this.Labores_formacion_hijos_ninos + '\','
            + 'Labores_formacion_hijos_mujeres = ' + '\'' + this.Labores_formacion_hijos_mujeres + '\','
            + 'Labores_formacion_hijos_hombres = ' + '\'' + this.Labores_formacion_hijos_hombres + '\','
            + 'Labores_cuiado_mayores_enfermos_ninas = ' + '\'' + this.Labores_cuiado_mayores_enfermos_ninas + '\','
            + 'Labores_cuiado_mayores_enfermos_ninos = ' + '\'' + this.Labores_cuiado_mayores_enfermos_ninos + '\','
            + 'Labores_cuiado_mayores_enfermos_mujeres = ' + '\'' + this.Labores_cuiado_mayores_enfermos_mujeres + '\','
            + 'Labores_cuiado_mayores_enfermos_hombres = ' + '\'' + this.Labores_cuiado_mayores_enfermos_hombres + '\','
            + 'Labores_otro_cual_ninas = ' + '\'' + this.Labores_otro_cual_ninas + '\','
            + 'Labores_otro_cual_ninos = ' + '\'' + this.Labores_otro_cual_ninos + '\','
            + 'Labores_otro_cual_mujeres = ' + '\'' + this.Labores_otro_cual_mujeres + '\','
            + 'Labores_otro_cual_hombres = ' + '\'' + this.Labores_otro_cual_hombres + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit8 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit8 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit9() {
    if (this.Ganaderia_caza_silvicultura_pesca == undefined || this.Explotacion_minas_canteras == undefined || this.Fabricacion_articulos_textiles_prendas_vestir == undefined ||
      this.Labores_Lab_domesticas_hombres == undefined || this.Construccion == undefined || this.Venta_productos_no_fabricados_hogar == undefined ||
      this.Elaboracion_procesamiento_alimentos_bebidas == undefined || this.Servicio_restaurante_bar_similares == undefined || this.Alojamiento == undefined ||
      this.Arrendamientos == undefined || this.Servicios_actividades_reparacion_mantenimiento == undefined || this.Servicios_artisticos_peluqueria == undefined || this.Elaboracion_artesanias == undefined ||
      this.Agricultura == undefined || this.Fuente_ingreso_otro_cual == undefined || this.Gastos_mes_arriendo == undefined ||
      this.Gastos_mes_acueducto == undefined || this.Gastos_mes_alcantarillado == undefined || this.Gastos_mes_gas == undefined ||
      this.Gastos_mes_salud == undefined || this.Gastos_mes_transporte == undefined || this.Gastos_mes_educacion == undefined ||
      this.Gastos_mes_alimentacion == undefined || this.Gastos_mes_recreacion == undefined || this.Gastos_mes_vestuario == undefined ||
      this.Gastos_mes_energia_electrica == undefined || this.Gastos_mes_otro_ban == undefined || this.Gastos_mes_otro_cual == undefined ||
      this.Gastos_mes_otro_cual == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Ganaderia_caza_silvicultura_pesca = (this.formSoc[0][3] === 'true');
      this.Explotacion_minas_canteras = (this.formSoc[0][4] === 'true');
      this.Fabricacion_articulos_textiles_prendas_vestir = (this.formSoc[0][5] === 'true');
      this.Labores_Lab_domesticas_hombres = (this.formSoc[0][6] === 'true');
      this.Construccion = (this.formSoc[0][7] === 'true');
      this.Venta_productos_no_fabricados_hogar = (this.formSoc[0][8] === 'true');
      this.Elaboracion_procesamiento_alimentos_bebidas = (this.formSoc[0][9] === 'true');
      this.Servicio_restaurante_bar_similares = (this.formSoc[0][10] === 'true');
      this.Alojamiento = (this.formSoc[0][11] === 'true');
      this.Arrendamientos = (this.formSoc[0][12] === 'true');
      this.Servicios_actividades_reparacion_mantenimiento = (this.formSoc[0][13] === 'true');
      this.Servicios_artisticos_peluqueria = (this.formSoc[0][14] === 'true');
      this.Elaboracion_artesanias = (this.formSoc[0][15] === 'true');
      this.Agricultura = (this.formSoc[0][16] === 'true');
      this.Fuente_ingreso_otro_cual = this.formSoc[0][17];
      this.Gastos_mes_arriendo = this.formSoc[0][22];
      this.Gastos_mes_acueducto = this.formSoc[0][23];
      this.Gastos_mes_alcantarillado = this.formSoc[0][24];
      this.Gastos_mes_gas = this.formSoc[0][25];
      this.Gastos_mes_salud = this.formSoc[0][26];
      this.Gastos_mes_transporte = this.formSoc[0][27];
      this.Gastos_mes_educacion = this.formSoc[0][28];
      this.Gastos_mes_alimentacion = this.formSoc[0][29];
      this.Gastos_mes_recreacion = this.formSoc[0][30];
      this.Gastos_mes_vestuario = this.formSoc[0][31];
      this.Gastos_mes_energia_electrica = this.formSoc[0][32];
      this.Gastos_mes_otro_ban = this.formSoc[0][35];
      this.Gastos_mes_otro_cual = this.formSoc[0][33];
      this.Gastos_mes_total = this.formSoc[0][34];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.economia SET '
            + 'Ganaderia_caza_silvicultura_pesca = ' + '\'' + this.Ganaderia_caza_silvicultura_pesca + '\','
            + 'Explotacion_minas_canteras = ' + '\'' + this.Explotacion_minas_canteras + '\','
            + 'Fabricacion_articulos_textiles_prendas_vestir = ' + '\'' + this.Fabricacion_articulos_textiles_prendas_vestir + '\','
            + 'Construccion = ' + '\'' + this.Construccion + '\','
            + 'Venta_productos_no_fabricados_hogar = ' + '\'' + this.Venta_productos_no_fabricados_hogar + '\','
            + 'Elaboracion_procesamiento_alimentos_bebidas = ' + '\'' + this.Elaboracion_procesamiento_alimentos_bebidas + '\','
            + 'Servicio_restaurante_bar_similares = ' + '\'' + this.Servicio_restaurante_bar_similares + '\','
            + 'Labores_pagosycompras_hombres = ' + '\'' + this.Labores_pagosycompras_hombres + '\','
            + 'Alojamiento = ' + '\'' + this.Alojamiento + '\','
            + 'Arrendamientos = ' + '\'' + this.Arrendamientos + '\','
            + 'Servicios_actividades_reparacion_mantenimiento = ' + '\'' + this.Servicios_actividades_reparacion_mantenimiento + '\','
            + 'Servicios_artisticos_peluqueria = ' + '\'' + this.Servicios_artisticos_peluqueria + '\','
            + 'Elaboracion_artesanias = ' + '\'' + this.Elaboracion_artesanias + '\','
            + 'Agricultura = ' + '\'' + this.Agricultura + '\','
            + 'Fuente_ingreso_otro_cual = ' + '\'' + this.Fuente_ingreso_otro_cual + '\','
            + 'Gastos_mes_arriendo = ' + '\'' + this.Gastos_mes_arriendo + '\','
            + 'Gastos_mes_acueducto = ' + '\'' + this.Gastos_mes_acueducto + '\','
            + 'Gastos_mes_alcantarillado = ' + '\'' + this.Gastos_mes_alcantarillado + '\','
            + 'Gastos_mes_gas = ' + '\'' + this.Gastos_mes_gas + '\','
            + 'Gastos_mes_salud = ' + '\'' + this.Gastos_mes_salud + '\','
            + 'Gastos_mes_transporte = ' + '\'' + this.Gastos_mes_transporte + '\','
            + 'Gastos_mes_educacion = ' + '\'' + this.Gastos_mes_educacion + '\','
            + 'Gastos_mes_alimentacion = ' + '\'' + this.Gastos_mes_alimentacion + '\','
            + 'Gastos_mes_recreacion = ' + '\'' + this.Gastos_mes_recreacion + '\','
            + 'Gastos_mes_vestuario = ' + '\'' + this.Gastos_mes_vestuario + '\','
            + 'Gastos_mes_energia_electrica = ' + '\'' + this.Gastos_mes_energia_electrica + '\','
            + 'Gastos_mes_otro_ban = ' + '\'' + this.Gastos_mes_otro_ban + '\','
            + 'Gastos_mes_otro_cual = ' + '\'' + this.Gastos_mes_otro_cual + '\','
            + 'Gastos_mes_total = ' + '\'' + this.Gastos_mes_total + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit9 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit9 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit10() {
    if (this.Uso_adecuado_energia == undefined || this.Escuchado_uso_racional_energia == undefined || this.Conocer_generacionyuso_energia == undefined ||
      this.Temas_generacionyuso_energia == undefined || this.Compartir_saberes == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Uso_adecuado_energia = this.formEnergy[0][133];
      this.Escuchado_uso_racional_energia = this.formEnergy[0][134];
      this.Conocer_generacionyuso_energia = this.formEnergy[0][135];
      this.Temas_generacionyuso_energia = this.formEnergy[0][136];
      this.Compartir_saberes = this.formEnergy[0][137];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.URE SET '
            + 'Uso_adecuado_energia = ' + '\'' + this.Uso_adecuado_energia + '\','
            + 'Escuchado_uso_racional_energia = ' + '\'' + this.Escuchado_uso_racional_energia + '\','
            + 'Conocer_generacionyuso_energia = ' + '\'' + this.Conocer_generacionyuso_energia + '\','
            + 'Temas_generacionyuso_energia = ' + '\'' + this.Temas_generacionyuso_energia + '\','
            + 'Compartir_saberes = ' + '\'' + this.Compartir_saberes + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit10 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit10 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit11() {
    if (
      this.Nombre_beneficiario_usuario == undefined || this.Nombre_encuestado == undefined || this.Telefono_celular_encuestado == undefined ||
      this.Telefono_fijo_encuestado == undefined || this.Cedula_encuestado == undefined || this.Correo_encuestado == undefined ||
      this.Constancia_uso_datos == undefined || this.Explicado_posible_solucion == undefined || this.Explicado_beneficio_limitacion == undefined ||
      this.Aprobacion_proyecto == undefined || this.Autorizacion_fotos_videos == undefined || this.Reaccion_preguntas == undefined ||
      this.Reaccion_proyecto == undefined || this.Comentarios_encuestador == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Nombre_beneficiario_usuario = this.formData[0][3];
      this.Nombre_encuestado = this.formData[0][4];
      this.Telefono_celular_encuestado = this.formData[0][5];
      this.Telefono_fijo_encuestado = this.formData[0][6];
      this.Cedula_encuestado = this.formData[0][7];
      this.Correo_encuestado = this.formData[0][8];
      this.Constancia_uso_datos = (this.formData[0][9] === 'true');
      this.Explicado_posible_solucion = (this.formData[0][10] === 'true');
      this.Explicado_beneficio_limitacion = (this.formData[0][11] === 'true');
      this.Aprobacion_proyecto = (this.formData[0][12] === 'true');
      this.Autorizacion_fotos_videos = (this.formData[0][13] === 'true');
      this.Reaccion_preguntas = this.formData[0][17];
      this.Reaccion_proyecto = this.formData[0][18];
      this.Comentarios_encuestador = this.formData[0][16];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.consentimiento SET '
            + 'Nombre_beneficiario_usuario = ' + '\'' + this.Nombre_beneficiario_usuario + '\','
            + 'Nombre_encuestado = ' + '\'' + this.Nombre_encuestado + '\','
            + 'Telefono_celular_encuestado = ' + '\'' + this.Telefono_celular_encuestado + '\','
            + 'Telefono_fijo_encuestado = ' + '\'' + this.Telefono_fijo_encuestado + '\','
            + 'Cedula_encuestado = ' + '\'' + this.Cedula_encuestado + '\','
            + 'Correo_encuestado = ' + '\'' + this.Correo_encuestado + '\','
            + 'Constancia_uso_datos = ' + '\'' + this.Constancia_uso_datos + '\','
            + 'Explicado_posible_solucion = ' + '\'' + this.Explicado_posible_solucion + '\','
            + 'Explicado_beneficio_limitacion = ' + '\'' + this.Explicado_beneficio_limitacion + '\','
            + 'Aprobacion_proyecto = ' + '\'' + this.Aprobacion_proyecto + '\','
            + 'Autorizacion_fotos_videos = ' + '\'' + this.Autorizacion_fotos_videos + '\','
            + 'Reaccion_preguntas = ' + '\'' + this.Reaccion_preguntas + '\','
            + 'Reaccion_proyecto = ' + '\'' + this.Reaccion_proyecto + '\','
            + 'Comentarios_encuestador = ' + '\'' + this.Comentarios_encuestador + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit11 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit11 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit12() {
    if (
      this.Acceso_agua == undefined || this.Cantidad_agua_fuente == undefined || this.Cantidad_agua_uso == undefined ||
      this.Fuente_agua == undefined || this.Otra_fuente == undefined || this.Profundidad == undefined ||
      this.Comunitario == undefined || this.Cual_profundidad == undefined || this.Uso_agua == undefined ||
      this.Otra_fuente == undefined || this.Puesto_agua == undefined || this.Tiempo_agua == undefined ||
      this.Agua_consumo == undefined || this.Distancia == undefined || this.Tiempo_recoleccion == undefined ||
      this.Transporte_agua == undefined || this.Otro_transporte == undefined || this.Fuente_diferente == undefined ||
      this.Agua_semanal == undefined || this.Fuente_alternativa == undefined || this.Pago_fuente == undefined ||
      this.Cuanto == undefined || this.Tratamiento_agua == undefined || this.Otro_tratamiento == undefined ||
      this.Almacenamiento == undefined || this.Almacenamiento_promedio == undefined || this.Elementos_almacenamiento == undefined ||
      this.Limpieza_elementos == undefined || this.Basura == undefined || this.Recoleccion_basura == undefined ||
      this.Desechos_solidos == undefined || this.Disposicion_desechos == undefined || this.Aguas_negras == undefined ||
      this.Otro_tratamiento == undefined || this.Aguas_residuales == undefined || this.Otro_residuales == undefined) {
      this.alert.descErr('Error, revise que no hayan campos vacíos');
      this.Acceso_agua = this.formVivienda[0][51];
      this.Cantidad_agua_fuente = this.formData[0][52];
      this.Cantidad_agua_uso = this.formData[0][53];
      this.Fuente_agua = this.formData[0][54];
      this.Otra_fuente = this.formData[0][55];
      this.Profundidad = this.formData[0][56];
      this.Comunitario = this.formData[0][57];
      this.Cual_profundidad = this.formData[0][58];
      this.Uso_agua = this.formData[0][59];
      this.Otra_fuente = this.formData[0][60];
      this.Puesto_agua = this.formData[0][61];
      this.Tiempo_agua = this.formData[0][62];
      this.Agua_consumo = this.formData[0][63];
      this.Distancia = this.formData[0][64];
      this.Tiempo_recoleccion = this.formVivienda[0][65];
      this.Transporte_agua = this.formData[0][66];
      this.Otro_transporte = this.formData[0][67];
      this.Fuente_diferente = this.formData[0][68];
      this.Agua_semanal = this.formData[0][69];
      this.Fuente_alternativa = this.formData[0][70];
      this.Pago_fuente = this.formData[0][71];
      this.Cuanto = this.formData[0][72];
      this.Tratamiento_agua = this.formData[0][73];
      this.Otro_tratamiento = this.formData[0][74];
      this.Almacenamiento = this.formData[0][75];
      this.Almacenamiento_promedio = this.formData[0][76];
      this.Elementos_almacenamiento = this.formData[0][77];
      this.Limpieza_elementos = this.formData[0][78];
      this.Basura = this.formData[0][79];
      this.Recoleccion_basura = this.formData[0][80];
      this.Desechos_solidos = this.formData[0][81];
      this.Disposicion_desechos = this.formData[0][82];
      this.Aguas_negras = this.formData[0][83];
      this.Otro_tratamiento = this.formData[0][84];
      this.Aguas_residuales = this.formData[0][85];
      this.Otro_residuales = this.formData[0][86];
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.agua SET '
            + 'Acceso_agua = ' + '\'' + this.Acceso_agua + '\','
            + 'Cantidad_agua_fuente = ' + '\'' + this.Cantidad_agua_fuente + '\','
            + 'Cantidad_agua_uso = ' + '\'' + this.Cantidad_agua_uso + '\','
            + 'Fuente_agua = ' + '\'' + this.Fuente_agua + '\','
            + 'Profundidad = ' + '\'' + this.Profundidad + '\','
            + 'Comunitario = ' + '\'' + this.Comunitario + '\','
            + 'Cual_profundidad = ' + '\'' + this.Cual_profundidad + '\','
            + 'Uso_agua = ' + '\'' + this.Uso_agua + '\','
            + 'Otra_fuente = ' + '\'' + this.Otra_fuente + '\','
            + 'Puesto_agua = ' + '\'' + this.Puesto_agua + '\','
            + 'Tiempo_agua = ' + '\'' + this.Tiempo_agua + '\','
            + 'Agua_consumo = ' + '\'' + this.Agua_consumo + '\','
            + 'Distancia = ' + '\'' + this.Distancia + '\','
            + 'Tiempo_recoleccion = ' + '\'' + this.Tiempo_recoleccion + '\','
            + 'Transporte_agua = ' + '\'' + this.Transporte_agua + '\','
            + 'Otro_transporte = ' + '\'' + this.Otro_transporte + '\','
            + 'Fuente_diferente = ' + '\'' + this.Fuente_diferente + '\','
            + 'Agua_semanal = ' + '\'' + this.Agua_semanal + '\','
            + 'Fuente_alternativa = ' + '\'' + this.Fuente_alternativa + '\','
            + 'Pago_fuente = ' + '\'' + this.Pago_fuente + '\','
            + 'Cuanto = ' + '\'' + this.Cuanto + '\','
            + 'Tratamiento_agua = ' + '\'' + this.Tratamiento_agua + '\','
            + 'Otro_tratamiento = ' + '\'' + this.Otro_tratamiento + '\','
            + 'Almacenamiento = ' + '\'' + this.Almacenamiento + '\','
            + 'Almacenamiento_promedio = ' + '\'' + this.Almacenamiento_promedio + '\','
            + 'Elementos_almacenamiento = ' + '\'' + this.Elementos_almacenamiento + '\','
            + 'Limpieza_elementos = ' + '\'' + this.Limpieza_elementos + '\','
            + 'Basura = ' + '\'' + this.Basura + '\','
            + 'Recoleccion_basura = ' + '\'' + this.Recoleccion_basura + '\','
            + 'Desechos_solidos = ' + '\'' + this.Desechos_solidos + '\','
            + 'Disposicion_desechos = ' + '\'' + this.Disposicion_desechos + '\','
            + 'Aguas_negras = ' + '\'' + this.Aguas_negras + '\','
            + 'Otro_tratamiento = ' + '\'' + this.Otro_tratamiento + '\','
            + 'Aguas_residuales = ' + '\'' + this.Aguas_residuales + '\','
            + 'Otro_residuales = ' + '\'' + this.Otro_residuales + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.edit10 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit10 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  select_fuente(funClose: Function, type: number) {
    switch (type) {
      case 1:
        if (this.FE_cocinar_prefiere.length == 0) {
          this.alert.descErr('No pueden haber ningún cuadro vacío');
          this.FE_cocinar_prefiere.push(this.formEnergy[0][26]);
          this.FE_cocinar_prefiere_string = this.FE_cocinar_prefiere.toString();
        } else {
          this.FE_cocinar_prefiere_string = this.FE_cocinar_prefiere.toString();
          console.log(this.FE_cocinar_prefiere);
          console.log(this.FE_cocinar_prefiere_string);
          funClose;
        }
        break;
      case 2:
        if (this.FE_iluminar_usa.length == 0) {
          this.alert.descErr('No pueden haber ningún cuadro vacío');
          this.FE_iluminar_usa.push(this.formEnergy[0][53]);
          this.FE_iluminar_prefiere_string = this.FE_iluminar_usa.toString();
        } else {
          this.FE_iluminar_prefiere_string = this.FE_iluminar_usa.toString();
          funClose;
        }
        break;

      default:
        break;
    }
  }
  openAddFamily(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-addFamily' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openFuenteCocinar(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-fuente_cocinar' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    for (let i = 0; i < this.FE_cocinar_prefiere.length; i++) {
      if (this.FE_cocinar_prefiere.length == 0) {
        continue;
      } else {
        switch (this.FE_cocinar_prefiere[i]) {
          case 'Gas Propano':
            this.FE_cocinar_gaspropano_consumo_select = true;
            break;
          case 'Gas Natural':
            this.FE_cocinar_gasnatural_consumo_select = true
            break;
          case 'Gasolina':
            this.FE_cocinar_gasolina_consumo_select = true;
            break;
          case 'Kerosene':
            this.FE_cocinar_kerosene_consumo_select = true;
            break;
          case 'Petróleo':
            this.FE_cocinar_petroleo_consumo_select = true;
            break;
          case 'Alcohol':
            this.FE_cocinar_alcohol_consumo_select = true;
            break;
          case 'Carbón Mineral':
            this.FE_cocinar_carbon_mineral_consumo_select = true;
            break;
          case 'Leña Comprada':
            this.FE_cocinar_lena_comprada_consumo_select = true;
            break;
          case 'Leña Auto Apropiada':
            this.FE_cocinar_lena_auto_apropiada_consumo_select = true;
            break;
          case 'Residuos del Agro':
            this.FE_cocinar_residuos_agro_consumo_select = true;
            break;
          case 'Otro':
            this.FE_cocinar_otro_consumo_select = true;

            break;
          default:
            break;
        }
      }

    }
  }
  openFuenteIluminar(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-fuente_iluminar' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    for (let i = 0; i < this.FE_iluminar_usa.length; i++) {
      if (this.FE_iluminar_usa.length == 0) {
        continue;
      } else {
        switch (this.FE_iluminar_usa[i]) {
          case 'Pilas o baterías (linternas)':
            this.FE_iluminar_bateria_select = true;
            break;
          case 'Gasolina (planta eléctrica)':
            this.FE_iluminar_planta_gasolina_select = true;
            break;
          case 'Kerosene (lámpara – mechero)':
            this.FE_iluminar_kerosene_select = true;
            break;
          case 'Petróleo (lámpara – mechero)':
            this.FE_iluminar_petroleo_select = true;
            break;
          case 'Alcohol':
            this.FE_iluminar_alcohol_select = true;
            break;
          case 'Diesel (planta eléctrica)':
            this.FE_iluminar_planta_diesel_select = true;
            break;
          case 'Velas':
            this.FE_iluminar_velas_select = true;
            break;
          case 'Otro':
            this.FE_iluminar_otro_select = true;
            break;
          default:
            break;
        }
      }

    }
  }
  addRelative() {
    console.log(this.Parentesco);
    console.log(this.Genero);
    console.log(this.Edad);
    console.log(this.Genero);
    console.log(this.Escolaridad);
    console.log(this.Ocupacion);
  }
  checkValue(e) {
    if (e.target.checked) {
      console.log(typeof (this.FE_cocinar_prefiere));
      this.FE_cocinar_prefiere.push(e.target.value.toString());
    } else {
      this.FE_cocinar_prefiere = this.filterCheck(e.target.value, this.FE_cocinar_prefiere);
    }
  }
  filterCheck(palabra: string, words: string[]) {
    const deleteWords = words.filter(e => e !== palabra);
    return deleteWords;
  }
  checkValuei(e) {
    if (e.target.checked) {
      console.log(typeof (this.FE_iluminar_usa));
      this.FE_iluminar_usa.push(e.target.value.toString());
    } else {
      this.FE_iluminar_usa = this.filterCheck(e.target.value, this.FE_iluminar_usa);
    }
  }
  approveBtn() {
    this.spinner.show();
    setTimeout(() => {
      try {
        const query = 'UPDATE db_nazareth.porcentaje SET '
          + "Porcentaje='1'"
          + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
        const pdata1 = { option3: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('¡La encuesta se aprobó con éxito!');
            this.route.navigateByUrl('/listado-preliminar-nazareth');
            this.spinner.hide();
          } else {
            this.alert.descErr('Error al aprobar, verifique nuevamente ');
            this.spinner.hide();
          }
        });
      } catch (error) {
        console.log(error);
        this.spinner.hide();
      }
    }, 600);
  }
  exploreImg() {
    let elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(this.srcImg);
        for (let i = 0; i < this.srcImg.length; i++) {
          this.Photo = this.srcImg[i];
          this.validExt(this.Photo.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.evidence = true;
  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }
  loadImg() {
    let idProjectFunc = this.formData[0][2];
    if (this.evidence == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;
          for (let i = 0; i < this.srcImg.length; i++) {
            con1++;
            console.log('Foto #' + i, this.srcImg[i]);
            const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
            const imageName = i + '-' + moment().unix() + this.ext;
            console.log(imageName);
            const imageFile = FOTOO;
            const data = new FormData();
            data.append('image', imageFile);
            data.append('nombre', imageName);
            data.append('ruta', idProjectFunc + '-' + moment());
            $.ajax({
              url: 'https://www.php.engenius.com.co/FOT/subir_foto_encuestas_naza.php',
              type: 'post',
              dataType: 'json',
              data,
              processData: false,
              contentType: false
            }).then((data1) => {
              console.log(data, data1);
              this.fotoUrl[i] = data1;
              con2++;
              if (this.fotoUrl[i] == 'BADEXT' || this.fotoUrl[i] == null || this.fotoUrl[i] == undefined || this.fotoUrl[i] == '' || this.fotoUrl[i] == 'NULL') {
                this.alert.descErr('Error al subir la foto, verifique nuevamente');
              } else {
                // # of Photos validator
                let idFotoEnc = idProjectFunc + '-' + i + '-' + moment();
                const query = 'REPLACE INTO db_nazareth.fotos_encuesta (Id_Foto_Encuesta, Id_Encuesta, Id_Proyecto_Funcionario, rutalocal, rutaserver, fecha, upload, edit, Servidor, Reporte, Girar, IsDelete)' +
                  ' VALUES (\'' +
                  idFotoEnc + '\',\'' +
                  this.global.idBenefit + '\',\'' +
                  idProjectFunc + '\',\'' +
                  '-' + '\',\'' +
                  data1 + '\',\'' +
                  'uploaded' + '\',\'' +
                  moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
                  1 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\');';
                const pdata1 = { option3: 'insertar3', texto: query };
                this.global.consultar(pdata1, async (err, response) => {
                  console.log(response, query);
                  if (err == null && response == true) {
                    this.alert.descValid('Imagenes subidas correcamente');
                  } else {
                    this.alert.descErr('¡Error al subir imagenes! ');
                  }
                });
                if (con1 == con2 && con2 == this.srcImg.length) {
                  this.ngOnInit();
                  this.srcImg = [];
                  this.Photo = [];
                  this.evidence = false;
                  this.spinner.hide();
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        console.log(error);
      }
    }
  }
  exploreImgId() {
    let elem: HTMLElement = document.querySelector('#idPhoto');
    elem.click();
  }
  onFileSelectedId(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let imagen = new Image();
        imagen.src = event.target.result;
        imagen.onload = () => {
          console.log( event.target.result);
          this.Photoid = event.target.result;
          this.srcImgid.push( this.Photoid);
          this.validExt(this.Photoid.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    this.evidenceId = true;
  }
  loadImgId() {
    this.spinner.show();
    let idProjectFunc = this.formData[0][2];
    if (this.Photoid == null || this.Photoid == undefined || this.Photoid == '' || this.ext == undefined) {
      this.alert.descErr('Error revise nuevamente que el archivo que va a subir es una imagen');
    } else {
      console.log('Foto', this.Photoid);
      const FOTOO = this.Photoid.slice(23, this.Photoid.length);
      //nombre de la imagen en el servidor this.Photo
      const imageName = idProjectFunc + '_' + moment().unix() + this.ext;
      //const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
      const imageFile = FOTOO;
      const data = new FormData();
      data.append('image', imageFile);
      data.append('nombre', imageName);
      data.append('ruta', idProjectFunc + '-' + moment());
      $.ajax({
        url: 'https://www.php.engenius.com.co/FOT/subir_foto_firma_naza.php',
        type: 'post',
        dataType: 'json',
        data,
        processData: false,
        contentType: false
      }).then((data1) => {
        console.log(data, data1);
        this.fotoUrlid = data1;
        if (this.fotoUrlid == 'BADEXT' || this.fotoUrlid == null || this.fotoUrlid == undefined || this.fotoUrlid == "" || this.fotoUrlid == 'NULL') {
          this.alert.descErr('Error al subir la foto, verifique nuevamente');
        } else {
          let idFotoEnc = idProjectFunc + '-' + moment();
          const query = 'REPLACE INTO db_nazareth.fotos_firma (Id_Foto_Firma, Id_Encuesta, Id_Proyecto_Funcionario, rutalocal, rutaserver, estado, fecha, upload, edit, IsDelete)' +
            ' VALUES (\'' +
            idFotoEnc + '\',\'' +
            this.global.idBenefit + '\',\'' +
            idProjectFunc + '\',\'' +
            '-' + '\',\'' +
            data1 + '\',\'' +
            0 + '\',\'' +
            moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
            1 + '\',\'' +
            0 + '\',\'' +
            0 + '\');';
          const pdata1 = { option3: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('Imagenes subidas correcamente');
              this.ngOnInit();
              this.srcImgid = [];
              this.Photoid = '';
              this.evidenceId = false;
              this.spinner.hide();
            } else {
              this.alert.descErr('¡Error al subir imagenes! ');
              this.ngOnInit();
              this.srcImgid = [];
              this.Photoid = '';
              this.evidenceId = false;
              this.spinner.hide();
            }
          });
        }
      });
    }
  }

}
