import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-inicial',
  templateUrl: './inicial.component.html',
  styleUrls: ['./inicial.component.scss']
})
export class InicialComponent implements OnInit {
  medidores = [];
  MEDIDORESEXCEL;
  clientClass;
  Subir = false;
  Registrar = false;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idProsec == undefined || this.global.nombreProyecto == undefined) {
      let valTemp: any;
      valTemp = sessionStorage.getItem('secTemp');
      if (valTemp != null) {
        valTemp = valTemp.split(',');
        this.global.idProsec = valTemp[0];
        this.global.nombreProyecto = valTemp[1];
        setTimeout(() => {
          let tempVar: any = [];
          tempVar.push(this.global.idProsec);
          tempVar.push(this.global.nombreProyecto);
          sessionStorage.setItem('secTemp', tempVar);
          this.clientClass = this.global.CLIENTDATALIWA[8];
          const pdata1 = { option: 'lis_table', idProyecto: this.global.idSeccional };
          console.log('listado', pdata1);
          this.global.consultar(pdata1, (err1, response1) => {
            console.log('Datos listado', response1);
            this.medidores = response1;
            $(function () {
              this.table = $('#seccionalTable').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
          });

          this.spinner.hide();
        }, 600);
      } else {
        this.ruta.navigateByUrl('/proyecto');
        this.spinner.hide();
      }
    } else {
      setTimeout(() => {
        let tempVar: any = [];
        tempVar.push(this.global.idProsec);
        tempVar.push(this.global.nombreProyecto);
        sessionStorage.setItem('secTemp', tempVar);
        this.clientClass = this.global.CLIENTDATALIWA[8];
        const pdata1 = { option: 'lis_table', idProyecto: this.global.idSeccional };
        console.log('listado', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos listado', response1);
          this.medidores = response1;
          $(function () {
            this.table = $('#seccionalTable').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
        });

        this.spinner.hide();
      }, 600);
    }
  }

  SubirExcel() {
    this.Subir = true;
  }
  arrayBuffer:any;
  file:File;
  incomingfile(event)
  {
  this.file= event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
          var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet,{raw:true});
          if(MEDIDORESEXCEL[0].hasOwnProperty('Cedula')){
            console.log('SI Cedula')
            if(MEDIDORESEXCEL[0].hasOwnProperty('Departamento')){
              console.log('SI Departamento')
              if(MEDIDORESEXCEL[0].hasOwnProperty('Latitud')){
                console.log('SI Latitud')
                if(MEDIDORESEXCEL[0].hasOwnProperty('Longitud')){
                  console.log('SI Longitud')
                  if(MEDIDORESEXCEL[0].hasOwnProperty('Municipio')){
                    console.log('SI Municipio')
                    if(MEDIDORESEXCEL[0].hasOwnProperty('Nombres y Apellidos')){
                      console.log('SI Nombres')
                      if(MEDIDORESEXCEL[0].hasOwnProperty('Vereda')){
                        console.log('SI Vereda')
                        if(MEDIDORESEXCEL[0].hasOwnProperty('ID Usuario')){
                          console.log('SI ID')
                          if(MEDIDORESEXCEL[0].hasOwnProperty('Poste')){
                            console.log('SI Poste')
                            if(MEDIDORESEXCEL[0].hasOwnProperty('Gabinete')){
                              console.log('SI Gabinete')
                              if(MEDIDORESEXCEL[0].hasOwnProperty('Panel 1')){
                                console.log('SI Panel1')
                                if(MEDIDORESEXCEL[0].hasOwnProperty('Panel 2')){
                                  console.log('SI Panel2')
                                  if(MEDIDORESEXCEL[0].hasOwnProperty('Panel 3')){
                                    console.log('SI Panel3')
                                    if(MEDIDORESEXCEL[0].hasOwnProperty('Panel 4')){
                                      console.log('SI Panel4')
                                      if(MEDIDORESEXCEL[0].hasOwnProperty('Batería 1')){
                                        console.log('SI Batería1')
                                        if(MEDIDORESEXCEL[0].hasOwnProperty('Batería 2')){
                                          console.log('SI Batería2')
                                          if(MEDIDORESEXCEL[0].hasOwnProperty('Inversor')){
                                            console.log('SI Inversor')
                                            if(MEDIDORESEXCEL[0].hasOwnProperty('Controlador')){
                                              console.log('SI Controlador')
                                              if(MEDIDORESEXCEL[0].hasOwnProperty('Medidor')){
                                                console.log('SI Medidor')
                                                if(MEDIDORESEXCEL[0].hasOwnProperty('Id_Inicial')){
                                                  console.log('SI Id_Inicial')
                                                  console.log('Seccional', this.global.idSeccional)
                                                  this.MEDIDORESEXCEL = MEDIDORESEXCEL;
                                                  this.Registrar = true;
                                                } else {
                                                  console.log('No Id_Inicial')
                                                  this.alerta.excelErr('Id_Inicial');
                                                }
                                              } else {
                                                console.log('No Medidor')
                                                this.alerta.excelErr('Medidor');
                                              }
                                            } else {
                                              console.log('No Controlador')
                                              this.alerta.excelErr('Controlador');
                                            }
                                          } else {
                                            console.log('No Inversor')
                                            this.alerta.excelErr('Inversor');
                                          }
                                        } else {
                                          console.log('No Batería2')
                                          this.alerta.excelErr('Batería2');
                                        }
                                      } else {
                                        console.log('No Batería1')
                                        this.alerta.excelErr('Batería1');
                                      }
                                    } else {
                                      console.log('No Panel4')
                                      this.alerta.excelErr('Panel4');
                                    }
                                  } else {
                                    console.log('No Panel3')
                                    this.alerta.excelErr('Panel3');
                                  }
                                } else {
                                  console.log('No Panel2')
                                  this.alerta.excelErr('Panel2');
                                }
                              } else {
                                console.log('No Panel1')
                                this.alerta.excelErr('Panel1');
                              }
                            } else {
                              console.log('No Gabinete')
                              this.alerta.excelErr('Gabinete');
                            }
                          } else {
                            console.log('No Poste')
                            this.alerta.excelErr('Poste');
                          }
                        } else {
                          console.log('No ID')
                          this.alerta.excelErr('ID');
                        }
                      } else {
                        console.log('No Vereda')
                        this.alerta.excelErr('Vereda');
                      }
                    } else {
                      console.log('No Nombres')
                      this.alerta.excelErr('Nombres');
                    }
                  } else {
                    console.log('No Municipio')
                    this.alerta.excelErr('Municipio');
                  }
                } else {
                  console.log('No Longitud')
                  this.alerta.excelErr('Longitud');
                }
              } else {
                console.log('No Latitud')
                this.alerta.excelErr('Latitud');
              }
            } else {
              console.log('No Departamento')
              this.alerta.excelErr('Departamento');
            }
          } else {
            console.log('No Cedula')
            this.alerta.excelErr('Cedula');
          }
      }
      fileReader.readAsArrayBuffer(this.file);
}

  RegistrarMedidores(){
          /*
          Apellidos: "Hernandez Osorio"
          Cedula: 1000687826
          Comunidad: "NA"
          Departamento: "Boyacá"
          Latitud: 1223456
          Longitud: 314234235
          Municipio: "Tunja"
          Nombres: "Miguel Felipe"
          Vereda: "Centro"
          */
    let query = 'REPLACE INTO db_liwa.Inicial(Id,Id_Seccional,Fecha,Nombres_Apellidos,Cedula,Departamento,Municipio,Vereda,Latitud,Longitud,IDUsuario,Poste,Gabinete,Panel1,Panel2,Panel3,Panel4,Batería1,Batería2,Inversor,Controlador,Medidor) VALUES ';
    if(this.Registrar == true) {
      for(let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
        query = query + "('" + this.MEDIDORESEXCEL[i]['Id_Inicial'] + "','" + this.global.idSeccional+ "', '"+ moment(new Date()).format("YYYY-MM-DD HH:mm:ss") +"','" + this.MEDIDORESEXCEL[i]['Nombres y Apellidos'] +"','" + this.MEDIDORESEXCEL[i]['Cedula'] + "','" + this.MEDIDORESEXCEL[i]['Departamento'] + "','" + this.MEDIDORESEXCEL[i]['Municipio'] + "','" + this.MEDIDORESEXCEL[i]['Vereda'] + "','" + this.MEDIDORESEXCEL[i]['Latitud'] + "','" + this.MEDIDORESEXCEL[i]['Longitud'] + "','" + this.MEDIDORESEXCEL[i]['ID Usuario'] + "','" + this.MEDIDORESEXCEL[i]['Poste'] + "','" + this.MEDIDORESEXCEL[i]['Gabinete'] + "','" + this.MEDIDORESEXCEL[i]['Panel 1'] + "','" + this.MEDIDORESEXCEL[i]['Panel 2'] + "','" + this.MEDIDORESEXCEL[i]['Panel 3'] + "','" + this.MEDIDORESEXCEL[i]['Panel 4'] + "','" + this.MEDIDORESEXCEL[i]['Batería 1'] + "','" + this.MEDIDORESEXCEL[i]['Batería 2'] + "','" + this.MEDIDORESEXCEL[i]['Inversor'] + "','" + this.MEDIDORESEXCEL[i]['Controlador'] + "','" + this.MEDIDORESEXCEL[i]['Medidor'] + "'),";
      }
      // setTimeout(() => {
        var queryDEF = query.substring(0, query.length - 1);
        queryDEF = queryDEF + ';'
        console.log(queryDEF);
        const pdata3 = { option: 'insertar3', texto: queryDEF };
        this.global.consultar(pdata3, async (err3, response3) => {
          console.log(response3, queryDEF);
          if (err3 == null && response3 == true) {
            this.alerta.closeValid();
            this.ngOnInit();
            this.MEDIDORESEXCEL = new Array();
            this.Registrar = false;
            this.Subir = false;
          } else {
            console.log(err3);
          }
      });
      // }, 500);
    } else {

    }
  }


  IsActiveView(data) {
    if (data == 1) {
      return 'Activado';
    }
    if (data == 0) {
      return 'Desactivado';
    }
  }
  isActiveBadge(data) {
    if (data == 1) {
      return 'badge badge-success';
    }
    if (data == 0) {
      return 'badge badge-danger';
    }
    this.ngOnInit();
  }

  deleteSec(idSec) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el registro?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idSec);
      } else {
        this.ngOnInit();
      }
    });
  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Inicial SET IsDelete = '1' WHERE Id ='" + data + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Registro eliminado correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);

  }


}
