import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-formato-sui',
  templateUrl: './formato-sui.component.html',
  styleUrls: ['./formato-sui.component.css']
})
export class FormatoSuiComponent implements OnInit {
  tiemposDeReporteMensual;
  tiemposDeReporteTimestral;
  tiemposDeReporteAnual;
  nameProyecto = this.global.nombreProyecto;
  listActiveMensual: boolean = false;
  listadoDatosMensuales: boolean = false;
  listActiveTrimes: boolean = false;
  listadoDatosTrimestrales: boolean = false;
  listActiveAnual: boolean = false;
  listadoDatosAnual: boolean = false
  clientClass;
  archivosMesuales;
  archivosAnuales;
  archivosTrimestrales;
  mesSeleccionado = 1;
  trimestreSeleccionado = 1;
  anioSeleccionado = 1;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta:GlobalAlertService,
    public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    if( this.global.idProsec == undefined){
      this.ruta.navigateByUrl('/proyecto');
      this.spinner.hide();
    }else{
      this.clientClass = this.global.CLIENTDATALIWA[8];
      this.consultarArchivos(); 
      this.spinner.hide();
    }
    $(function() {
      this.table = $('#formatoSui1').DataTable({
      });
    });
  }

  

  recursosMensuales() {
    if (this.listActiveAnual || this.listActiveTrimes) {
      this.listadoDatosAnual = false 
      this.listadoDatosTrimestrales = false
      this.listActiveAnual = false
      this.listActiveTrimes = false
      this.listActiveMensual = !this.listActiveMensual
    } else {
      this.listActiveMensual = !this.listActiveMensual 
    }
    
  } 
  recursosTrimestrales() { 
    if (this.listActiveMensual || this.listActiveAnual  ) {
      this.listadoDatosAnual = false 
      this.listadoDatosMensuales = false
      this.listActiveMensual = false 
      this.listActiveAnual = false
      this.listActiveTrimes = !this.listActiveTrimes
    } else {
      this.listActiveTrimes = !this.listActiveTrimes
    }
 
  }
  recursosAnuales() {
    if (this.listActiveMensual || this.listActiveTrimes ) {
      this.listadoDatosMensuales = false 
      this.listadoDatosTrimestrales = false
      this.listActiveMensual = false
      this.listActiveTrimes = false
      this.listActiveAnual = !this.listActiveAnual
    } else {
      this.listActiveAnual = !this.listActiveAnual
    }
 
  }
  consultarArchivos(){
    const tiempoReportesMensual = { option: 'insertar2', texto : "SELECT DISTINCT db_liwa.Formatos_Sui.Tiempo as Periodo, db_liwa.Proyectos_Sui.Tiempo as Fecha from db_liwa.Proyectos_Sui INNER JOIN db_liwa.Formatos_Sui ON db_liwa.Proyectos_Sui.IdFormato = db_liwa.Formatos_Sui.IdFormato WHERE db_liwa.Formatos_Sui.Tiempo = 'MENSUAL' AND db_liwa.Proyectos_Sui.IdProyecto = '"+this.global.idProsec+"';"}
    this.global.consultar(tiempoReportesMensual, (err, response) =>
    {
      this.tiemposDeReporteMensual = response;
      const tiempoReportesTrimestral = { option: 'insertar2', texto : "SELECT DISTINCT db_liwa.Formatos_Sui.Tiempo as Periodo, db_liwa.Proyectos_Sui.Tiempo as Fecha from db_liwa.Proyectos_Sui INNER JOIN db_liwa.Formatos_Sui ON db_liwa.Proyectos_Sui.IdFormato = db_liwa.Formatos_Sui.IdFormato WHERE db_liwa.Formatos_Sui.Tiempo = 'TRIMESTRAL' AND db_liwa.Proyectos_Sui.IdProyecto = '"+this.global.idProsec+"';"}
      this.global.consultar(tiempoReportesTrimestral, (err, response) =>
      {
        this.tiemposDeReporteTimestral = response;
        const tiempoReportesAnual = { option: 'insertar2', texto : "SELECT DISTINCT db_liwa.Formatos_Sui.Tiempo as Periodo, db_liwa.Proyectos_Sui.Tiempo as Fecha from db_liwa.Proyectos_Sui INNER JOIN db_liwa.Formatos_Sui ON db_liwa.Proyectos_Sui.IdFormato = db_liwa.Formatos_Sui.IdFormato WHERE db_liwa.Formatos_Sui.Tiempo = 'ANUAL' AND db_liwa.Proyectos_Sui.IdProyecto = '"+this.global.idProsec+"';"}
        this.global.consultar(tiempoReportesAnual, (err, response) =>
        {
          this.tiemposDeReporteAnual = response;
        });
      });
    });

  }


  buscarMes(){
    this.spinner.show()
 
      const pdata1 = { option: 'insertar2', texto: "SELECT *,IF(IdFormato IN(SELECT IdFormato FROM db_liwa.Proyectos_Sui WHERE Tiempo = '"+this.mesSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(IdFormato IN(SELECT IdFormato FROM db_liwa.Proyectos_Sui WHERE Tiempo = '"+this.mesSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0), (SELECT RutaRemota FROM db_liwa.Proyectos_Sui A WHERE Tiempo = '"+this.mesSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0 AND A.IdFormato = B.IdFormato), 'NO SUBIDO') FROM db_liwa.Formatos_Sui B WHERE IsDelete = 0 AND Tiempo = 'MENSUAL';"};
      this.global.consultar(pdata1, (err1, response1) => {
        this.listadoDatosMensuales = true;
        this.archivosMesuales = response1;
        this.spinner.hide()
        
      })  
  }
  buscarTrimestre(){
    this.spinner.show()
    const pdata1 = { option: 'insertar2', texto: "SELECT *,IF(IdFormato IN(SELECT IdFormato FROM db_liwa.Proyectos_Sui WHERE Tiempo = '"+this.trimestreSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(IdFormato IN(SELECT IdFormato FROM db_liwa.Proyectos_Sui WHERE Tiempo = '"+this.trimestreSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0), (SELECT RutaRemota FROM db_liwa.Proyectos_Sui A WHERE Tiempo = '"+this.trimestreSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0 AND A.IdFormato = B.IdFormato), 'NO SUBIDO') FROM db_liwa.Formatos_Sui B WHERE IsDelete = 0 AND Tiempo = 'TRIMESTRAL';"};
    this.global.consultar(pdata1, (err1, response1) => {
      this.listadoDatosTrimestrales = true;
      this.archivosTrimestrales = response1;
      this.spinner.hide()
    })  
  }
  buscarAnual(){
    this.spinner.show()
    const pdata1 = { option: 'insertar2', texto: "SELECT *,IF(IdFormato IN(SELECT IdFormato FROM db_liwa.Proyectos_Sui WHERE Tiempo = '"+this.anioSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(IdFormato IN(SELECT IdFormato FROM db_liwa.Proyectos_Sui WHERE Tiempo = '"+this.anioSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0), (SELECT RutaRemota FROM db_liwa.Proyectos_Sui A WHERE Tiempo = '"+this.anioSeleccionado+"' AND idProyecto='"+this.global.idProsec+"' AND IsDelete = 0 AND A.IdFormato = B.IdFormato), 'NO SUBIDO') FROM db_liwa.Formatos_Sui B WHERE IsDelete = 0 AND Tiempo = 'ANUAL';"};
    console.log(pdata1,'aQuery')
    this.global.consultar(pdata1, (err1, response1) => {
      this.listadoDatosAnual = true;
      this.archivosAnuales = response1;
      this.spinner.hide()
    })  
  }
  descargar(idFormato){

    window.open(idFormato, "_blank");
  }
 
}

