import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-detalle-tenderos',
  templateUrl: './detalle-tenderos.component.html',
  styleUrls: ['./detalle-tenderos.component.css']
})
export class DetalleTenderosComponent implements OnInit {

  isConsulted = false;
  infoTendero;  
  clientClass;
  Archivo;
  banderaFotos:boolean = false 

  constructor(private global: GlobalService, public ruta: Router, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.spinner.show()
    this.clientClass = this.global.CLIENTDATALIWA;
    this.infoTendero = JSON.parse(window.localStorage.getItem('informacion encuesta'))
    this.isConsulted = !this.isConsulted
    const pdata5 = { option: 'insertar2', texto:  `SELECT Rutaserver FROM db_liwa.Fotos_AOM WHERE Id_Encuesta = '${this.infoTendero[19]}'`};
             this.global.consultar(pdata5, (err2,response5) => {                 
               this.Archivo = response5
               response5.length > 0 ?
                this.banderaFotos = true
               : this.banderaFotos = false
               // console.log("archivo", this.Archivo.length);
                
             })
             this.spinner.hide()
  }
  volver(){
    this.ruta.navigateByUrl("/tenderos");
  }

}
