import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adm-mantenimiento',
  templateUrl: './adm-mantenimiento.component.html',
  styleUrls: ['./adm-mantenimiento.component.scss']
})
export class AdmMantenimientoComponent implements OnInit {
  clientClass;
  visitas;
  Mantenimiento:[];
  updateM;
  constructor(public global: GlobalService, public route: Router, private spinner: NgxSpinnerService, public alerta: GlobalAlertService) {
    this.global.initData();
   }

  ngOnInit(): void {
    const pdata9 = { option: 'visitas', client: this.global.CLIENTDATALIWA[0]};
      // console.log('MANTENIMIENTO', pdata9);
      this.global.consultar(pdata9, async  (err9, response9) => {
      // console.log('Datos Mantenimiento', response9);
      this.Mantenimiento = await response9;
      });
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    // setTimeout(() => {
      this.spinner.hide();
      $(function () {
        this.table = $('#maintenanceTable').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
        }
    });
  });
    // }, 1000);
   
  }

  ver(data){
    this.global.Id_Mantenimiento = data[0];
    if(data[8] != 'Terminado'){ this.global.isEnd = false}
    else{ this.global.isEnd = true }
    this.route.navigateByUrl('/ver-visita');
  }
  

  cerrar(item){
    this.updateM = moment().unix();
    const query = 'UPDATE db_liwa.Maintenance SET Status = "Terminado" '
    + ' WHERE (Id_Maintenance =\'' + item[0] + '\');';
  const pdata1 = { option: 'insertar3', texto: query };
  this.global.consultar(pdata1, (err, response) => {
    // console.log(response, query);
    if (err == null && response == true) {
      const query2 = 'UPDATE db_liwa.Tickets SET Id_Statuses = 11 '
    + ' WHERE (Id_Tickets =\'' + item[9] + '\');';
    const pdata2 = { option: 'insertar3', texto: query2 };
    this.global.consultar(pdata2, (err2, response2) => {
      // console.log(response2, query2);
      if (err2 == null && response2 == true) {
        const query3 = 'REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)' +
        ' VALUES (\'' +
        this.updateM + '\',\'' +
        item[0] + '\',\'' +
        this.global.Id_Usuario + '\',\'' +
        moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
        "El mantenimiento ha sido finalizado" + '\', \'' +
        "Terminado" + '\',\'' +
        0 + '\',\'' +
        1 + '\');';
        const pdata3 = { option: 'insertar3', texto: query3 };
        this.global.consultar(pdata3, (err3, response3) => {
          // console.log(response3, query3);
          if (err3 == null && response3 == true) {
                this.alerta.descValid('La visita de mantenimiento se cerró con éxito');
                this.ngOnInit();
        } else {
          console.log(err);
        }
      });
    }
  });
  }
});
}
  //////////// DELETE VALIDATION ///////////////////////////

  deleteVal(data:any){
    Swal.fire({
      title: '<strong>¿Está seguro que desea cerrar la visita?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor:'#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.cerrar(data);
      }else{
        
        this.ngOnInit();
        
      }
    });
  }
  //END DELETE VALIDATION //

}
