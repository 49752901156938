import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
  selector: 'app-formulario-preliminar-redes-nazareth',
  templateUrl: './formulario-preliminar-redes-nazareth.component.html'
})
export class FormularioPreliminarRedesNazarethComponent implements OnInit {
  clientClass;
  formData;
  Num_formulario;
  Dia;
  Mes;
  Ano;
  U_latitud;
  U_longitud;
  U_altitud;
  U_depto;
  U_codigo_depto;
  U_municipio;
  U_codigo_municipio;
  U_vereda;
  U_corregimiento;
  Tipo_Red;
  s_e;
  Circuito;
  Urbano;
  Conductor;
  Nodo;
  Concatenada;
  Disposicion;
  Tipo_Mat;
  Resistencia;
  Apoyo;
  Mtto;
  Estado;
  Tipo_Est;
  Estructura;
  Estado_Est;
  Fases;
  Calibre;
  Tipo_Mat_Cond;
  Cantidad;
  Conductor_Op;
  Estado_Cond;
  Edad;
  Cable;
  Puesta_Tierra;
  Templetes;
  Fibra_Op;
  Observaciones;
  Cantidad_Cond;
  Cod_Creg_Ap;
  Cod_Creg_Cond;
  NodoA;
  openFlag: boolean = false;
  call: boolean = false;
  call1: boolean = true;
  call2: boolean = true;
  edit: boolean = false;
  edit1: boolean = false;
  edit2: boolean = false;
  fotoUrl: any[] = [];
  Photo;
  srcImg: any[] = [];
  evidence: boolean = false;
  ext;
  fotosForm;
  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    this.spinner.show();
    if (this.global.idForm == undefined) {
      this.route.navigateByUrl('/listado-preliminar-redes-nazareth');
      this.spinner.hide();
    } else {
      setTimeout(() => {
        this.clientClass = this.global.CLIENTDATALIWA;
        const pdata5 = { option: 'form-redes-nazareth', idencuesta: this.global.idForm };
        this.global.consultar(pdata5, (err, response4) => {
          console.log('Datos form-redes-vivienda', response4);
          this.formData = response4;
          const pdata2 = { option: 'form-nazareth-fotos-redes', idBeneficiario: this.global.idForm };
          this.global.consultar(pdata2, (err, response) => {
            this.fotosForm = response;
            console.log('Fotos equipos', response);
            console.log('query', pdata2);
            this.openFlag = true;
            this.spinner.hide();
          });
        });
      }, 500);
    }
  }

  animatedTab(numbr: number) {
    switch (numbr) {
      case 1:
        this.call = !this.call;
        break;
      case 2:
        this.call1 = !this.call1;
        break;
      case 3:
        this.call2 = !this.call2;
        break;

      default:
        break;
    }
  }
  editModule(numb: number) {
    switch (numb) {
      case 0:
        this.edit = !this.edit;
        this.U_depto = this.formData[0][6];
        this.U_codigo_depto = this.formData[0][7];
        this.U_municipio = this.formData[0][8];
        this.U_codigo_municipio = this.formData[0][9];
        this.U_vereda = this.formData[0][10];
        this.U_corregimiento = this.formData[0][11];
        this.U_latitud = this.formData[0][3];
        this.U_longitud = this.formData[0][4];
        this.U_altitud = this.formData[0][5];
        break;
      case 1:
        this.edit1 = !this.edit1;
        this.Tipo_Red = this.formData[0][18];
        this.s_e = this.formData[0][19];
        this.Circuito = this.formData[0][20];
        this.Urbano = this.formData[0][21];
        this.Conductor = this.formData[0][22];
        this.Nodo = this.formData[0][23];
        this.NodoA = this.formData[0][24];
        this.Concatenada = this.formData[0][25];
        this.Disposicion = this.formData[0][26];
        this.Tipo_Mat = this.formData[0][27];
        this.Resistencia = this.formData[0][28];
        this.Apoyo = this.formData[0][29];
        this.Mtto = this.formData[0][30];
        this.Estado = this.formData[0][31];
        this.Tipo_Est = this.formData[0][32];
        this.Estructura = this.formData[0][33];
        this.Estado_Est = this.formData[0][34];
        this.Fases = this.formData[0][35];
        this.Calibre = this.formData[0][36];
        this.Tipo_Mat_Cond = this.formData[0][37];
        this.Cantidad = this.formData[0][38];
        this.Conductor_Op = this.formData[0][39];
        this.Estado_Cond = this.formData[0][40];
        this.Edad = this.formData[0][41];
        this.Cable = this.formData[0][42];
        this.Puesta_Tierra = this.formData[0][43];
        this.Templetes = this.formData[0][44];
        this.Fibra_Op = this.formData[0][45];
        this.Observaciones = this.formData[0][46];
        this.Cantidad_Cond = this.formData[0][47];
        this.Cod_Creg_Ap = this.formData[0][48];
        this.Cod_Creg_Cond = this.formData[0][49];
        break;
      case 2:
        this.edit2 = !this.edit2;
        break;

      default:
        break;
    }
  }
  confirmEditForm() {
    if (this.U_depto == undefined || this.U_codigo_depto == undefined || this.U_municipio == undefined ||
      this.U_codigo_municipio == undefined || this.U_vereda == undefined || this.U_corregimiento == undefined ||
      this.U_latitud == undefined || this.U_longitud == undefined || this.U_altitud == undefined) {
      this.alert.descErr('Verifique que no hayan campos vacíos');
      this.U_depto = this.formData[0][6];
      this.U_codigo_depto = this.formData[0][7];
      this.U_municipio = this.formData[0][8];
      this.U_codigo_municipio = this.formData[0][9];
      this.U_vereda = this.formData[0][10];
      this.U_corregimiento = this.formData[0][11];
      this.U_latitud = this.formData[0][3];
      this.U_longitud = this.formData[0][4];
      this.U_altitud = this.formData[0][5];
      this.edit = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.ubicacion SET '
            + 'U_latitud = ' + '\'' + this.U_latitud + '\','
            + 'U_longitud = ' + '\'' + this.U_longitud + '\','
            + 'U_altitud = ' + '\'' + this.U_altitud + '\','
            + 'U_depto = ' + '\'' + this.U_depto + '\','
            + 'U_codigo_depto = ' + '\'' + this.U_codigo_depto + '\','
            + 'U_municipio = ' + '\'' + this.U_municipio + '\','
            + 'U_codigo_municipio = ' + '\'' + this.U_codigo_municipio + '\','
            + 'U_vereda = ' + '\'' + this.U_vereda + '\','
            + 'U_corregimiento = ' + '\'' + this.U_corregimiento + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.U_depto = '';
              this.U_codigo_depto = '';
              this.U_municipio = '';
              this.U_codigo_municipio = '';
              this.U_vereda = '';
              this.U_corregimiento = '';
              this.U_latitud = '';
              this.U_longitud = '';
              this.U_altitud = '';
              this.edit = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit1Form() {
    if (
      this.Tipo_Red == undefined || this.s_e == undefined || this.Circuito == undefined ||
      this.Urbano == undefined || this.Conductor == undefined || this.Nodo == undefined ||
      this.NodoA == undefined || this.Concatenada == undefined || this.Disposicion == undefined ||
      this.Tipo_Mat == undefined || this.Resistencia == undefined || this.Apoyo == undefined ||
      this.Mtto == undefined || this.Estado == undefined || this.Tipo_Est == undefined ||
      this.Estructura == undefined || this.Estado_Est == undefined || this.Fases == undefined ||
      this.Calibre == undefined || this.Tipo_Mat_Cond == undefined || this.Cantidad == undefined ||
      this.Conductor_Op == undefined || this.Estado_Cond == undefined || this.Edad == undefined ||
      this.Cable == undefined || this.Puesta_Tierra == undefined || this.Templetes == undefined ||
      this.Fibra_Op == undefined || this.Observaciones == undefined || this.Cantidad_Cond == undefined ||
      this.Cod_Creg_Ap == undefined || this.Cod_Creg_Cond == undefined
    ) {
      this.alert.descErr('Verifique que no hayan campos vacíos');
      this.Tipo_Red = this.formData[0][18];
      this.s_e = this.formData[0][19];
      this.Circuito = this.formData[0][20];
      this.Urbano = this.formData[0][21];
      this.Conductor = this.formData[0][22];
      this.Nodo = this.formData[0][23];
      this.NodoA = this.formData[0][24];
      this.Concatenada = this.formData[0][25];
      this.Disposicion = this.formData[0][26];
      this.Tipo_Mat = this.formData[0][27];
      this.Resistencia = this.formData[0][28];
      this.Apoyo = this.formData[0][29];
      this.Mtto = this.formData[0][30];
      this.Estado = this.formData[0][31];
      this.Tipo_Est = this.formData[0][32];
      this.Estructura = this.formData[0][33];
      this.Estado_Est = this.formData[0][34];
      this.Fases = this.formData[0][35];
      this.Calibre = this.formData[0][36];
      this.Tipo_Mat_Cond = this.formData[0][37];
      this.Cantidad = this.formData[0][38];
      this.Conductor_Op = this.formData[0][39];
      this.Estado_Cond = this.formData[0][40];
      this.Edad = this.formData[0][41];
      this.Cable = this.formData[0][42];
      this.Puesta_Tierra = this.formData[0][43];
      this.Templetes = this.formData[0][44];
      this.Fibra_Op = this.formData[0][45];
      this.Observaciones = this.formData[0][46];
      this.Cantidad_Cond = this.formData[0][47];
      this.Cod_Creg_Ap = this.formData[0][48];
      this.Cod_Creg_Cond = this.formData[0][49];
      this.edit1 = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.redes SET '
            + 'Tipo_Red = ' + '\'' + this.Tipo_Red + '\','
            + 'S_E = ' + '\'' + this.s_e + '\','
            + 'Circuito = ' + '\'' + this.Circuito + '\','
            + 'Urbano = ' + '\'' + this.Urbano + '\','
            + 'Conductor = ' + '\'' + this.Conductor + '\','
            + 'Nodo = ' + '\'' + this.Nodo + '\','
            + 'NodoA = ' + '\'' + this.NodoA + '\','
            + 'Concatenada = ' + '\'' + this.Concatenada + '\','
            + 'Disposicion = ' + '\'' + this.Disposicion + '\','
            + 'Tipo_Mat = ' + '\'' + this.Tipo_Mat + '\','
            + 'Resistencia = ' + '\'' + this.Resistencia + '\','
            + 'Apoyo = ' + '\'' + this.Apoyo + '\','
            + 'Mtto = ' + '\'' + this.Mtto + '\','
            + 'Estado = ' + '\'' + this.Estado + '\','
            + 'Tipo_Est = ' + '\'' + this.Tipo_Est + '\','
            + 'Estructura = ' + '\'' + this.Estructura + '\','
            + 'Estado_Est = ' + '\'' + this.Estado_Est + '\','
            + 'Fases = ' + '\'' + this.Fases + '\','
            + 'Calibre = ' + '\'' + this.Calibre + '\','
            + 'Tipo_Mat_Cond = ' + '\'' + this.Tipo_Mat_Cond + '\','
            + 'Cantidad = ' + '\'' + this.Cantidad + '\','
            + 'Conductor_Op = ' + '\'' + this.Conductor_Op + '\','
            + 'Estado_Cond = ' + '\'' + this.Estado_Cond + '\','
            + 'Edad = ' + '\'' + this.Edad + '\','
            + 'Cable = ' + '\'' + this.Cable + '\','
            + 'Puesta_Tierra = ' + '\'' + this.Puesta_Tierra + '\','
            + 'Templetes = ' + '\'' + this.Templetes + '\','
            + 'Fibra_Op = ' + '\'' + this.Fibra_Op + '\','
            + 'Observaciones = ' + '\'' + this.Observaciones + '\','
            + 'Cantidad_Cond = ' + '\'' + this.Cantidad_Cond + '\','
            + 'Cod_Creg_Ap = ' + '\'' + this.Cod_Creg_Ap + '\','
            + 'Cod_Creg_Cond = ' + '\'' + this.Cod_Creg_Cond + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.Tipo_Red = '';
              this.s_e = '';
              this.Circuito = '';
              this.Urbano = '';
              this.Conductor = '';
              this.Nodo = '';
              this.NodoA = '';
              this.Concatenada = '';
              this.Disposicion = '';
              this.Tipo_Mat = '';
              this.Resistencia = '';
              this.Apoyo = '';
              this.Mtto = '';
              this.Estado = '';
              this.Tipo_Est = '';
              this.Estructura = '';
              this.Estado_Est = '';
              this.Fases = '';
              this.Calibre = '';
              this.Tipo_Mat_Cond = '';
              this.Cantidad = '';
              this.Conductor_Op = '';
              this.Estado_Cond = '';
              this.Edad = '';
              this.Cable = '';
              this.Puesta_Tierra = '';
              this.Templetes = '';
              this.Fibra_Op = '';
              this.Observaciones = '';
              this.Cantidad_Cond = '';
              this.Cod_Creg_Ap = '';
              this.Cod_Creg_Cond = '';
              this.edit1 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit1 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  approveBtn() {
    this.spinner.show();
    setTimeout(() => {

      try {
        const query = 'UPDATE db_nazareth.porcentaje SET '
          + "Porcentaje='1'"
          + ' WHERE Id_Encuesta =' + '\'' + this.global.idForm + '\';';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('¡La encuesta se aprobó con éxito!');
            this.route.navigateByUrl('/listado-preliminar-redes-nazareth');
            this.spinner.hide();
          } else {
            this.alert.descErr('Error al aprobar, verifique nuevamente ');
            this.spinner.hide();
          }
        });
      } catch (error) {
        console.log(error);
        this.spinner.hide();
      }
    }, 600);
  }
  exploreImg() {
    let elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(this.srcImg);
        for (let i = 0; i < this.srcImg.length; i++) {
          this.Photo = this.srcImg[i];
          this.validExt(this.Photo.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.evidence = true;
  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }
  loadImg() {
    let idProjectFunc = this.formData[0][2];
    if (this.evidence == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;
          for (let i = 0; i < this.srcImg.length; i++) {
            con1++;
            console.log('Foto #' + i, this.srcImg[i]);
            const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
            const imageName = i + '-' + moment().unix() + this.ext;
            console.log(imageName);
            const imageFile = FOTOO;
            const data = new FormData();
            data.append('image', imageFile);
            data.append('nombre', imageName);
            data.append('ruta', idProjectFunc + '-' + moment());
            $.ajax({
              url: 'https://www.php.engenius.com.co/FOT/subir_foto_encuestas_nazaR.php',
              type: 'post',
              dataType: 'json',
              data,
              processData: false,
              contentType: false
            }).then((data1) => {
              console.log(data, data1);
              this.fotoUrl[i] = data1;
              con2++;
              if (this.fotoUrl[i] == 'BADEXT' || this.fotoUrl[i] == null || this.fotoUrl[i] == undefined || this.fotoUrl[i] == '' || this.fotoUrl[i] == 'NULL') {
                this.alert.descErr('Error al subir la foto, verifique nuevamente');
              } else {
                // # of Photos validator
                let idFotoEnc = idProjectFunc + '-' + i + '-' + moment();
                const query = 'REPLACE INTO db_nazareth.fotos_redes (Id_Foto_Red, Id_Red, Id_Proyecto_Funcionario, rutalocal, rutaserver, fecha, upload, edit, Servidor, Reporte, Girar, IsDelete)' +
                  ' VALUES (\'' +
                  idFotoEnc + '\',\'' +
                  this.global.idForm + '\',\'' +
                  idProjectFunc + '\',\'' +
                  '-' + '\',\'' +
                  data1 + '\',\'' +
                  'uploaded' + '\',\'' +
                  moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
                  1 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\');';
                const pdata1 = { option: 'insertar3', texto: query };
                this.global.consultar(pdata1, async (err, response) => {
                  console.log(response, query);
                  if (err == null && response == true) {
                    this.alert.descValid('Imagenes subidas correcamente');
                  } else {
                    this.alert.descErr('¡Error al subir imagenes! ');
                  }
                });
                if (con1 == con2 && con2 == this.srcImg.length) {
                  this.ngOnInit();
                  this.srcImg = [];
                  this.Photo = [];
                  this.evidence = false;
                  this.spinner.hide();
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        console.log(error);
      }
    }
  }

}
