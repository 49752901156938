import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from "moment";
import { GlobalAlertService } from 'src/app/global-alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-crear-usuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.scss']
})

export class CrearUsuarioComponent implements OnInit {
  selectedFile;
  Name;
  usuarios;
  Identification;
  NickName: any = '';
  banderaError: boolean = false;
  Occupation;
  Phone;
  Email;
  Password;
  Country;
  Photo;
  User;
  IdClient;
  IdRole;
  ClientName;
  RoleName;
  isRepeat: boolean = false;
  clientes = new Array();
  roles = new Array();
  fotoUrl = '';
  srcImg = '../../../assets/img/gallery/imgPlaceholder.png';
  clientClass;
  ext;
  constructor(public global: GlobalService,
    public route: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    const pdata8 = { option: 'clientes' };
    this.global.consultar(pdata8, (err8, response8) => {
      if (this.global.Id_Client == '2') {
        this.clientes.push(response8[1]);
      } else {
        this.clientes = response8;
      }
    });
    const pdata9 = { option: 'roles' };
    this.global.consultar(pdata9, (err9, response9) => {
      console.log('Datos Roles', response9);
      this.roles = response9;
    });
    let inputs = document.querySelectorAll('input');
    inputs.forEach(input => input.value = '');
    const datos = { option: 'usuarios', client: this.global.Id_Client };
    this.global.consultar(datos, (err, response) => {
      this.usuarios = response;
    })

  }
  exploreImg() {
    let elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let imagen = new Image();
        imagen.src = event.target.result;
        imagen.onload = () => {
          this.Photo = event.target.result;
          this.srcImg = this.Photo;
          this.validExt(this.Photo.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }

  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
    console.log(this.ext);
  }
  Guardar() {
    console.log(this.Name);
    try {
      setTimeout(() => {
        if (this.RoleName == undefined ||this.Name == undefined || this.banderaError === true || this.Name == '' || this.Identification == undefined || this.NickName == '' || this.Occupation == '' || this.Phone == '' || this.Email == undefined || this.Email == '' || this.Password == undefined || this.Password == '' || this.Country == '') {
          //this.alert.AlertOneButton('Información', 'Campos Vacios');
          this.alerta.descErr('Error al crear nuevo usuario, verifique nuevamente que no hayan campos vacíos');
          this.spinner.hide()
        } else {
          const query = 'INSERT INTO db_liwa.User (Id_Client,Id_Role,UserName,UserIdentification,UserNickName,UserOccupation,UserPhone,UserEmail,UserPassword,UserCountry,UserPhoto,IsDelete,IsActive)' +
            ' VALUES (\'' +
            this.ClientName + '\',\'' +
            this.RoleName + '\',\'' +
            this.Name + '\',\'' +
            this.Identification + '\', \'' +
            this.NickName + '\',\'' +
            this.Occupation + '\',\'' +
            this.Phone + '\',\'' +
            this.Email + '\',MD5(\'' + this.Password + '\'),\'' +
            this.Country + '\',\'' +
            this.fotoUrl + '\',\'' +
            0 + '\',\'' +
            1 + '\');';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.spinner.hide();
              this.alerta.descValid('¡Usuario creado con éxito!');
              this.route.navigateByUrl('/usuarios');
            } else {

            }
          });
        }
      }, 400);

    } catch (error) {
      console.log("error:", +error);
    }
  }
  loadPhoto() {
    this.spinner.show();
    if (!this.fotoUrl){
      console.log('aja')
      this.Guardar();
    } else {
    for (let i = 0; i < this.usuarios.length; i++) {
      console.log(this.usuarios[i][5]);
      if (this.usuarios[i][5].toString() == this.NickName) {
        this.isRepeat = true;
      } else {
        this.isRepeat = false;
      }
    }
    if (this.isRepeat == true) {
      console.log(this.NickName);
      this.alerta.descErr('El usuario ya existe, intente con otro nombre de usuario');
    } else {
      if (this.Photo == null || this.Photo == undefined || this.Photo == '' || this.ext == undefined) {
        this.Guardar();
        // this.alerta.descErr('Error al subir la foto, verifique nuevamente');
      } else {
        console.log('Foto', this.Photo);
        const FOTOO = this.Photo.slice(23, this.Photo.length);
        //nombre de la imagen en el servidor this.Photo
        const imageName = this.NickName + '_' + moment().unix() + this.ext;
        //const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        const imageFile = FOTOO;
        const data = new FormData();
        data.append('image', imageFile);
        data.append('nombre', imageName);
        data.append('ruta', this.NickName);
        $.ajax({
          url: 'https://fotos.engenius.com.co/subir_foto_UserLW.php',
          type: 'post',
          dataType: 'json',
          data,
          processData: false,
          contentType: false
        }).then((data1) => {
          console.log(data, data1);
          this.fotoUrl = data1;
          if (this.fotoUrl == 'BADEXT' || this.fotoUrl == null || this.fotoUrl == undefined || this.fotoUrl == "" || this.fotoUrl == 'NULL') {
            this.alerta.descErr('Error al subir la foto, verifique nuevamente');
          } else {
            this.Guardar();
          }
        });
      }
    }
  }

}

validatorNickName() {
  const query =`SELECT UserNickName FROM db_liwa.User WHERE UserNickName = '${this.NickName}';`;
    const pdata = { option: "insertar2", texto: query };
    this.global.consultar(pdata, (err, response) => {
     if (response.length >= 1) {
      this.alerta.descErr('El Nombre del usuario ya existe, intente con otro nombre de usuario')
      this.banderaError = true 
     } else {
       this.banderaError = false
     }
    })
 
}
  goBack() {
    window.history.back();
  }


}

