import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ExcelService } from 'src/app/excel.service';
import { GlobalService } from 'src/app/global.service';
import { GlobalAlertService } from 'src/app/global-alert.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-listado-definitivo-nazareth',
  templateUrl: './listado-definitivo-nazareth.component.html'
})
export class ListadoDefinitivoNazarethComponent implements OnInit {
  clientClass;
  benefits:any[] = [];
  review:any[] = [];
  tableOn: boolean = false;
  Toexcel;
  Excel;
  idSeccional;
  idProject;
  projects:any[] =[];
  seccionales:any[] = [];
  seccionalOn:boolean;

  constructor(public global: GlobalService,
    private route: Router,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata3 = { option: 'tipoProyecto' };
      console.log('tipoProyecto', pdata3);
      this.global.consultar(pdata3, (err3, response3) => {
        console.log('Datos tipoProyecto', response3);
        this.projects = response3;
      });
      const pdata2 = { option: 'porcentaje-nazareth-definitivo' };
      console.log('porcentaje-nazareth', pdata2);
      this.global.consultar(pdata2, (err2, response2) => {
        this.global.porcentaje_nazareth = response2;
        const pdata1 = { option: 'beneficiarios-nazareth' };
        console.log('Beneficiarios', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos beneficiarios', response1);
          for (let i = 0; i < this.global.porcentaje_nazareth.length; i++) {
            for (let j = 0; j < response1.length; j++) {
              if (this.global.porcentaje_nazareth[i][0] == response1[j][0]) {
                this.benefits.push(response1[j])
              }
            }
          }
          if (this.benefits.length !== 0) {
            $(function () {
              this.table = $('#TUsuariosDNazareth').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
            this.tableOn = true;
            this.spinner.hide();
          }
        });
      });
    }, 1000);
    setTimeout(() => {      
      const pdata10 = {option: 'excelldn'};
      this.global.consultar(pdata10, (err10, response10) => {
        console.log('REVISIÓN EXCEL', response10);
        this.Excel = response10;
      });
    }, 1000);
  }
  downloadExcel() {
    console.log('Excel', this.Excel);
    this.Toexcel = [['Id', 'Nombre del beneficiario', 'Nombre del encuestado', 'Cedula', 'Fecha de actualización']];
    for (let p = 0; p < this.Excel.length; p++) {
      this.Toexcel.push(this.Excel[p]);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Listado Preliminar', 'Listado Preliminar');
      setTimeout(() => {
        this.Toexcel = [['Id', 'Nombre del beneficiario', 'Nombre del encuestado', 'Cedula', 'Fecha de actualización']];
      }, 1000);
    }, 1500);
  }
  ir(idBenefit) {
    this.global.idBenefit = idBenefit;
    this.route.navigateByUrl('/formulario-preliminar-nazareth');
  }
  vinculateValQ(idBenefit, content){
    this.global.idBenefit = idBenefit;
    console.log('id beneficiario', idBenefit );
    this.openFormVinc(content);
  }
  vinculate(){
    this.spinner.show();
    if(this.idSeccional == undefined || this.global.idBenefit == undefined || this.idProject == undefined){
      this.alert.descErr('Hay campos vacíos, verifique que exista el seccional o el proyecto');
    }else{
      const query = 'INSERT INTO db_liwa.Beneficiario_Seccional (Id_Beneficiario, Id_Seccional, Id_Proyecto, IsDelete)' +
      ' VALUES (\'' +
      this.global.idBenefit + '\',\'' +
      this.idSeccional + '\',\'' +
      this.idProject + '\',\'' +
      0 + '\');';
      const pdata1 = {option: 'insertar3', texto: query};
      this.global.consultar(pdata1, async (err, response) => {
        console.log(response, query);
        if (err == null && await response == true) {
          const query2 = 'UPDATE db_liwa.Beneficiario SET isSeccional = \'1'+'\' WHERE Id_Beneficiario = \'' + this.global.idBenefit + '\';';
          const pdata2 = {option: 'insertar3', texto: query2};
          this.global.consultar(pdata2, async (err2, response2) => {
            console.log(response2, query2);
            if (err2 == null && await response2 == true) {
              this.spinner.hide();
              this.alert.descValid('El beneficiario se vinculó éxitosamente');
              this.route.navigateByUrl('/adq-beneficiarios');
            } 
          });
        } 
      });
    }
  }
  openFormVinc(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-vinculate'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  searchSeccional(valueId) {
    const pdata4 = { option: 'seccionales', idProyecto: valueId };
    this.global.consultar(pdata4, (err4, response4) => {
      console.log('Datos seccionales', response4);
      if (response4.length === 0) {
        this.seccionales = [["","","No hay registros de seccionales para este proyecto"]]
      } else {
        this.seccionales = response4;
        this.seccionalOn = true;
      }
    })
  }

}
