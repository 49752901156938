import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-actualizacio-datos',
  templateUrl: './actualizacio-datos.component.html',
  styleUrls: ['./actualizacio-datos.component.css']
})
export class ActualizacioDatosComponent implements OnInit {
  clientClass;
  beneficiarios: [];
  edit: boolean = false;
  Fecha;
  Nombre_Completo: string;
  TipoDoc: string;
  Cedula: number;
  Telefono: number;
  Sexo: string;
  Correo: string;
  Ocupacion: string;
  idEncuesta

  constructor(public global: GlobalService,public modalService: NgbModal,  private spinner: NgxSpinnerService, private alert: GlobalAlertService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    const query = `SELECT * FROM db_liwa.AOM WHERE IsDelete=0;`; 
    const pdata = { option: 'insertar2', texto: query };
    // setTimeout(() => {
      this.global.consultar(pdata, (err, response) => {
        this.beneficiarios = response;
        // console.log(response, query , 'data de la seccion');
          $(function () {
            this.table = $('#treplanteo').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });   
        })
        // }, 200);
      
  }

  openEditRate(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-editRate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  editModule(numb: number) {
    numb == 0 ?
    this.edit = !this.edit : console.log('')
  }

  DatosModalEdicion (nombre,tipoDoc,cedula,telefono, correo, fecha, idEncuesta) {
  this.Nombre_Completo = nombre;
  this.TipoDoc = tipoDoc;
  this.Cedula = cedula;
  this.Telefono = telefono;
  this.Correo = correo;
  this.Fecha = fecha
  this.idEncuesta = idEncuesta
  }
  confirmEdit() {
    if (this.Fecha == undefined || this.Nombre_Completo == undefined || this.TipoDoc == undefined || this.Cedula == undefined ||
      this.Telefono == undefined  || this.Correo == undefined ) {
      this.alert.descErr('Verifique que no hayan campos vacíos');
      this.edit = false;
    } else {
      this.spinner.show();
      // setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.AOM SET '
            + 'Fecha = ' + '\'' + this.Fecha + '\','
            + 'Nombre_Completo = ' + '\'' + this.Nombre_Completo + '\','
            + 'TipoDoc = ' + '\'' + this.TipoDoc + '\','
            + 'Cedula = ' + '\'' + this.Cedula + '\','
            + 'Telefono = ' + '\'' + this.Telefono + '\','
            + 'Sexo = ' + '\'' + this.Sexo + '\','
            + 'Correo = ' + '\'' + this.Correo + '\','
            + 'Ocupacion = ' + '\'' + this.Ocupacion + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.idEncuesta + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          // console.log(pdata1);
          this.global.consultar(pdata1, async  (err, response) => {
            // console.log(response, query);
            if (err == null && await response == true) {
              this.alert.descValid('Los datos se actualizaron con éxito!');
              this.ngOnInit();
              this.edit = false;
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit = false;
              this.spinner.hide();
            }
          });
        } catch (e) {
          // console.log(e);
        }
      // }, 600);
    }
  }
}
