import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

@Component({
  selector: 'app-adm-fv',
  templateUrl: './adm-fv.component.html',
  styleUrls: ['./adm-fv.component.scss']
})
export class AdmFvComponent implements OnInit {
  Items=new Array();
  clientClass;
  listActive;
  mapActive;
  sena = new Array();
  latitude = 4.5997156;
  longitude = -71.4886296;
  zoom = 5;
  Style;
  constructor(public global: GlobalService, public route: Router, private spinner: NgxSpinnerService) { 
      this.global.initData();
    }
    

  ngOnInit(): void { 
    this.Style = this.global.mapStyle;
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    const pdata9 = { option: 'sena'};
    // console.log('SEDES', pdata9);
    this.global.consultar(pdata9, (err9, response9) => {
    // console.log('Datos Sedes', response9);
    this.sena = response9;
    });
    setTimeout(() => {
      this.spinner.hide();
     this.isList();
    }, 1500);
    
  
  }
  ver(data){
    // console.log(data);
    this.global.selectedSede = data;
    this.route.navigateByUrl('/ver-sede');
  }

  isList(){
    if (this.mapActive == false) {
      this.listActive = !this.listActive;
      $(function () {
        this.table = $('#seatsTable').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
        }
    });
  });
      } else {
        this.mapActive = false;
        this.listActive = true;
       //MAPA
  }}

  isMap(){
    if (this.listActive == false) {
      this.mapActive = !this.mapActive;
      //MAPA
      } else {
        this.listActive = false;
        this.mapActive = true;
        $(function () {
          this.table = $('#seatsTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
      });
    });
   }
}




}
