import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-proyecto',
  templateUrl: './proyecto.component.html'
})
export class ProyectoComponent implements OnInit {
  clientClass;
  proyectos = Array();
  constructor(public global: GlobalService,
              public ruta: Router,
              public alerta: GlobalAlertService, 
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    // console.log("que es est0",)
    this.proyectoasAom()
    
  }

  proyectoasAom() {
    const datos = {option: 'insertar2', texto: `SELECT * FROM db_liwa.tipoProyecto Where Id_Client = ${this.global.Id_Client} AND IsDelete=0 AND IsActive=1 OR IsActive=0;`};
    this.global.consultar( datos, async (err, response) => {
      this.proyectos = await response;
      setTimeout(() => {
        this.spinner.hide();
        $(function () {
          this.table = $('#projectTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            },
          "columnDefs": [
            { "width": "170px", "targets": 6 }
          ]
          });
        });
      }, 600);
    });
  }
  verFormatosSui(idProyecto, nombreProyecto) {
    // this.global.idSeccional = idsec
    this.global.nombreProyecto = nombreProyecto;
    this.global.idProsec = idProyecto;
    this.ruta.navigateByUrl('/formato-sui')
  }

   //////////// DELETE VALIDATION ///////////////////////////

deleteVal(data:any){
  Swal.fire({
    title: '<strong>¿Está seguro que desea eliminar el proyecto?</strong>',
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonColor:'#093579',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Thumbs up, great!',
    confirmButtonText: 'Si, estoy seguro'
  }).then((result) => {
    if (result.isConfirmed) {
      this.eliminar(data);
    }else{
      // this.ngOnInit();
    }
  });
}
activar(data) {
  var query = "UPDATE db_liwa.tipoProyecto SET IsActive = '1' WHERE id_tipoProyecto ='" + data +"';";
  const pdata1 = {option: 'insertar', texto: query};
  $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
  url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
    success( data, textStatus, jQxhr ) {
    },
    error( jqXhr, textStatus, errorThrown ) {
    }
    }).then((response) => {
      this.ngOnInit();
    }).catch((err) => {
    });
}
desactivar(data) {
  var query = "UPDATE db_liwa.tipoProyecto SET IsActive = '0' WHERE id_tipoProyecto ='" + data +"';";
    const pdata1 = {option: 'insertar', texto: query};
    $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
    url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
      success( data, textStatus, jQxhr ) {
      },
      error( jqXhr, textStatus, errorThrown ) {
      }
      }).then((response) => {
        this.ngOnInit();
      }).catch((err) => {

      });
}
eliminar(data) {
  this.spinner.show();
  // setTimeout(() => {
    var query = "UPDATE db_liwa.tipoProyecto SET IsDelete = '1' WHERE id_tipoProyecto ='" + data +"';";
    const pdata1 = {option: 'insertar', texto: query};
    $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
    url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
      success( data, textStatus, jQxhr ) {
      },
      error( jqXhr, textStatus, errorThrown ) {
      }
      }).then((response) => {
        this.alerta.descValid('El proyecto se eliminó correctamente');
        this.spinner.hide();
        this.ngOnInit();
      }).catch((err) => {
      });
  // }, 60);
 
}
//END DELETE VALIDATION //
  createProject(){
    this.ruta.navigateByUrl('/crear-tipo-proyecto');
  }
  IsActiveView(data){
    if(data == 1){
      return 'Activado';
    }
    if(data == 0){
      return 'Desactivado';
    }
  }
  isActiveBadge(data){
    if(data == 1){
      return 'badge badge-success';
    }
    if(data == 0){
      return 'badge badge-danger';
    }
    // this.ngOnInit();
  }
  editProject(data) {
    this.global.idEditProject = data;
    this.ruta.navigateByUrl('/editar-proyecto');
  }
  verSeccional(idProyecto, nombreProyecto){
    this.global.nombreProyecto = nombreProyecto;
    this.global.idProsec = idProyecto;
    this.ruta.navigateByUrl('/seccionales');
  }
}
