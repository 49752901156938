import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ajax } from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';

@Component({
  selector: 'app-enelar2',
  templateUrl: './enelar2.component.html',
  styleUrls: ['./enelar2.component.scss']
})
export class Enelar2Component implements OnInit {
  p = 1;
  clientClass;
  DataUser;
  lat = 6.5997156;
  lng = -71.4886296;
  Images;
  Id;
  type: any[] = [];
  categories: any[] = [];
  finalImages: any[] = [];
  categoriesOn: boolean = false;
  siteOn: boolean = false;
  State;
  Pines;
  constructor(public global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    public excelService: ExcelService) {
    this.global.initData();
    this.Id = this.global.ID_Enelar;
  }

  ngOnInit() {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    if (this.global.ID_Enelar == undefined || this.global.ID_Enelar == null) {
      this.route.navigateByUrl('/enelar');
      this.spinner.hide();
    }
    else {
      setTimeout(() => {
        const pdata1 = { option: 'user', Id_B: this.Id };
        ajax({
          data: pdata1, cache: false, dataType: 'json', type: 'post',
          url: 'https://www.php.engenius.com.co/Database.php',
          success(data, textStatus, jQxhr) {
            console.log('Datos Recibidos:', data);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.log(JSON.stringify(errorThrown));
          }
        }).then((response) => {
          console.log('DataUser', response);
          this.DataUser = response[0];
          this.siteOn = true;
          this.spinner.hide();
        });
        const pdata2 = { option: 'imagenes', Id_F: this.Id };
        ajax({
          data: pdata2, cache: false, dataType: 'json', type: 'post',
          url: 'https://www.php.engenius.com.co/Database.php',
          success(data, textStatus, jQxhr) {
            console.log('Datos Recibidos:', data);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.log(JSON.stringify(errorThrown));
          }
        }).then((response) => {
          console.log(response);
          this.Images = response;
          const pdata9 = { option: 'mediafile_type', Id_F: this.Id };
          ajax({
            data: pdata9, cache: false, dataType: 'json', type: 'post',
            url: 'https://www.php.engenius.com.co/Database.php',
            success(data2, textStatus, jQxhr) {
              console.log('Datos Recibidos:', data2);
            },
            error(jqXhr, textStatus, errorThrown1) {
              console.log(JSON.stringify(errorThrown1));
            }
          }).then((response2) => {
            console.log('TIPO DE ARCHIVO', response2);
            this.type = response2;
            this.uniqueMediaFile();
          });
        });
        const pdata3 = { option: 'state', Id_B: this.Id };
        ajax({
          data: pdata3, cache: false, dataType: 'json', type: 'post',
          url: 'https://www.php.engenius.com.co/Database.php',
          success(data, textStatus, jQxhr) {
            console.log('Datos Recibidos:', data);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.log(JSON.stringify(errorThrown));
          }
        }).then((response) => {
          console.log('State', response[0][1]);
          this.State = response[0][1];
        });
        const pdata4 = { option: 'pines' };
        ajax({
          data: pdata4, cache: false, dataType: 'json', type: 'post',
          url: 'https://www.php.engenius.com.co/Database.php',
          success(data, textStatus, jQxhr) {
            console.log('Datos Recibidos:', data);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.log(JSON.stringify(errorThrown));
          }
        }).then((response) => {
          console.log(response);
          this.Pines = response;
        });
      }, 1000);
    }
  }
  uniqueMediaFile() {
    let temp: any[] = [];
    for (let i = 0; i < this.Images.length; i++) {
      for (let j = 0; j < this.type.length; j++) {
        if (this.Images[i][2] === this.type[j][0]) {
          temp[j] = this.type[j][0];
        } else {
          continue;
        }
      }
    }
    this.confirmCategorie(temp);
  }
  confirmCategorie(temp) {
    let imgTemp: any[] = [];
    let imgCat: any[] = [];
    let idType:any[] = [];
    this.categories = temp.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    console.log('categorias------------ >', this.categories);
    console.log('tipo de fotooooos ---------------->', this.type);
    for (let i = 0; i < this.type.length; i++) {
      for (let j = 0; j < this.categories.length; j++) {
        if(this.categories[j] === this.type[i][0]){
          idType.push(this.type[i]);
        }else {
          continue;
        }
      }      
    }
    console.log('ARR TIPOS---------------->',idType);
    this.categories = idType;
    console.log('ARR CATEGORIES---------------->',this.categories)
    for (let k = 0; k < this.categories.length; k++) {
      console.log('categorias', this.categories[k]);
      for (let l = 0; l < this.Images.length; l++) {
        if (this.categories[k][0] === this.Images[l][2]) {
          imgTemp.push(this.Images[l]);
        } else {
          continue;
        }
      }
    }
    console.table('img total', imgTemp);
    this.finalImages = imgTemp;
    this.categoriesOn = true;
  }
  Atras() {
    this.route.navigateByUrl('/enelar');
  }

}