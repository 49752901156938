import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html'
})
export class UserManualComponent implements OnInit {
clientClass;
Manuals: [];
Products:[];
loaded =false;
  constructor(public global: GlobalService, private spinner: NgxSpinnerService) {
  this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8] ;  
    const pdata9 = { option: 'manuales', client: this.global.CLIENTDATALIWA[0] === "1" ? "7" : this.global.CLIENTDATALIWA[0]};
    console.log('MANUALES', pdata9);
    this.global.consultar(pdata9, (err9, response9) => {
    console.log('Datos Manual', response9);
    this.Manuals = response9;
    });
    const pdata10 = { option: 'productos', client: this.global.CLIENTDATALIWA[0] === "1" ? "7" : this.global.CLIENTDATALIWA[0] };
    console.log('Productos', pdata10);
    this.global.consultar(pdata10, (err10, response10) => {
      console.log('Datos Producto', response10);
      this.Products = response10;
      if(this.Products != undefined){
      
      setTimeout(() => {
        this.spinner.hide();
        $(function () {
          this.table = $('#TableManual').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
      });
    });
    this.loaded = true;
      }, 1250);
      }
    });
     
     
  }
  productTitle(data){
    if(this.loaded = true){
      for (let i = 0; i < this.Products?.length; i++) {
        if(data == this.Products[i][0]){
            return this.Products[i][1]
        }else{
          continue;
        }
      }
    }
  }
    

}
