import { AfterViewChecked, Directive, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import * as $ from 'jquery';
import { GlobalService } from '../global.service';
import { ListadoPreliminarComponent } from '../pages/listado-preliminar/listado-preliminar.component';

@Directive({
  selector: '[appGetDatatable]'
})
export class GetDatatableDirective implements OnInit, AfterViewChecked {
  @ViewChild('#TablePreliminar_filter') get: ElementRef;
  value;

  constructor(private elementRef: ElementRef,
    private render: Renderer2,
    private global: GlobalService,
    public listPreliminar: ListadoPreliminarComponent) {

  }
  ngOnInit() {
  }
  ngAfterViewChecked() {
    let value = localStorage.getItem('buscar');
    // console.log(value);
    setTimeout(() => {
      if (value !== undefined || value !== 'undefined' || value !== null) {
        this.value = $('.dataTables_filter input').val();
        localStorage.setItem('buscar', this.value);
        // console.log(this.value);
      } else {
        localStorage.removeItem('buscar');
      }
    }, 5000);

  }
}
