import { Component, OnInit } from '@angular/core';
import { GlobalAlertService } from "../../global-alert.service";
import { Router } from '@angular/router';


@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  email;

  constructor(public route: Router , public alerta:GlobalAlertService) { }

  ngOnInit(): void {
  }
  Confirmar(){
    let miRegla = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    var resultado = miRegla.test(this.email);
    if (resultado){
      this.alerta.descValid('Por favor, verifique en su bandeja de entrada los pasos para recuperar su contraseña');
    }else{
       this.alerta.descErr('Ingrese un correo válido y verifique el campo nuevamente');
    }

  }
  Atras(){
    window.history.back();
  }

}
