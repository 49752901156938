import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
  selector: 'app-formulario-preliminar',
  templateUrl: './formulario-preliminar.component.html'
})
export class FormularioPreliminarComponent implements OnInit {
  clientClass;
  formData: any[] = [];
  idForm;
  deviceData: any[] = [];
  devicePhotos: any[] = [];
  deviceUser: any[] = [];
  srcFirmImg: any[] = [];
  openFlag: boolean = false;
  deviceFlag: boolean = false;
  isFullFirm: boolean = false;
  editableDevice = [{}];
  idDisp;
  marca;
  referencia;
  serial;
  tipo;
  estado;
  fechaInstalacion;
  photoExist: any[] = [];
  firma: any[] = [];
  isEmpty: boolean = true;
  emptyPhotos: boolean = true;
  srcNewImg: any[] = [];
  srcEditImg: any[] = [];
  fotosFormulario: any[] = [];
  imgsEditUrls: any[] = [];
  imgsNewUrls: any[] = [];
  isFullNew: boolean = false;
  isFullEdit: boolean = false;
  editEvidence: boolean = false;
  fotoFirmUrl;
  pathFirmName;
  pathEditName;
  pathNewName;
  pathDetailDevices;
  newmarca;
  newreferencia;
  newserial;
  newtipo;
  newestado;
  newfechaInstalacion;
  modal: NgbModalRef;
  //edit variables
  editLCon: boolean = false;
  editCCon: boolean = false;
  editVVin: boolean = false;
  editCM: boolean = false;
  editMP: boolean = false;
  editEU: boolean = false;

  flagOtherGear: boolean;
  flagMedIn: boolean;
  flagMedOut: boolean;
  flagMedOther: boolean;
  enombreCompleto;
  etipoDocumento;
  enumDocumento;
  etelefono;
  emaile;
  eocupacion;
  editMunicipio;
  editcomoLlego;
  editDepartamento;
  editVereda;
  editBarrio;
  editLatitud;
  editLongitud;
  editOperador;
  editCobertura;
  editAltitud;
  eUsoPredio;
  esPropio;
  eMaterialPredio;
  eOtroMaterialPredio;
  eUbicacionMedidor;
  eUbicacionMedidorOtro;
  eDistanciaMed;
  eAlturaMed;
  eCableado;
  eCaja;
  eEquipoM;
  eRiesgo;
  ePagoConsumo;
  eEsquema;
  eCalidad;
  ePercepcion;
  eTv;
  eNevera;
  eVentilador;
  eLicuadora;
  eImpulsor;
  ePlancha;
  eBombillos;
  eTomasCarga;
  eLavadora;
  eComputador;
  eModem;
  eAireAc;
  eEquipoSonido;
  eOtroEquipo;
  eOtroEquipoA;
  emptyModel: any[] = [];

  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    console.log(this.global.searchValue);
    if (this.global.idBenefit == undefined || this.global.idForm == undefined) {
      let valTemp: any;
      let valOption: any;
      valTemp = sessionStorage.getItem('formPre');
      valOption = sessionStorage.getItem('optionPreForm');
      if (valTemp != null || valOption != null) {
        valTemp = valTemp.split(',');
        valOption = valOption.split(',');
        this.global.idBenefit = valTemp[0];
        this.global.idForm = valTemp[1];
        this.global.isCC = valOption[0];
        this.global.isLc = valOption[1];
        this.global.isDp = valOption[2];

        setTimeout(() => {
          let tempVar: any = [];
          let tempOption: any = [];
          tempVar.push(this.global.idBenefit);
          tempVar.push(this.global.idForm);
          sessionStorage.setItem('formPre', tempVar);
          tempOption.push(this.global.isCC);
          tempOption.push(this.global.isLc);
          tempOption.push(this.global.isDp);
          sessionStorage.setItem('optionPreForm', tempOption);

          this.clientClass = this.global.CLIENTDATALIWA;

          const pdata7 = { option: 'devices_user', idencuesta: this.global.idForm };
          console.log('devices_user', pdata7);
          this.global.consultar(pdata7, (err7, response7) => {
            console.log('Datos devices_user', response7);
            this.deviceUser = response7;
          });
          const pdata6 = { option: 'fotosformulario', idencuesta: this.global.idForm };
          console.log('fotosformulario', pdata6);
          this.global.consultar(pdata6, (err6, response6) => {
            console.log('Datos fotosformulario', response6);
            this.fotosFormulario = response6;
          });
          const pdata5 = { option: 'porcentaje', idencuesta: this.global.idForm };
          console.log('porcentaje', pdata5);
          this.global.consultar(pdata5, (err5, response5) => {
            console.log('Datos porcentaje', response5);
            this.global.porcentaje = response5;
          });
          const pdata4 = { option: 'fotosfirma', idencuesta: this.global.idForm };
          console.log('fotosfirma', pdata4);
          this.global.consultar(pdata4, (err4, response4) => {
            console.log('Datos fotosfirma', response4);
            this.firma = response4;
            if (this.firma.length != 0) {
              this.isEmpty = false;
            }
          });
          const pdata3 = { option: 'devices_photos', idencuesta: this.global.idForm };
          console.log('devices_photos', pdata3);
          this.global.consultar(pdata3, (err3, response3) => {
            console.log('devices_photos', response3);
            this.devicePhotos = response3;
          });
          const pdata2 = { option: 'devices', idencuesta: this.global.idForm };
          console.log('devices', pdata2);
          this.global.consultar(pdata2, (err2, response2) => {
            console.log('Datos devices', response2);
            this.deviceData = response2;
            this.deviceFlag = true;
          });
          setTimeout(() => {
            const pdata1 = { option: 'formulario', idencuesta: this.global.idBenefit };
            console.log('formulario', pdata1);
            this.global.consultar(pdata1, (err1, response1) => {
              console.log('Datos formulario', response1);
              this.formData = response1;
              this.openFlag = true;
              this.spinner.hide();
            });
          }, 60);

        }, 70);
      } else {
        this.route.navigateByUrl('/listado-preliminar');
        this.spinner.hide();
      }
    } else {
      setTimeout(() => {
        let tempVar: any = [];
        let tempOption: any = [];
        tempVar.push(this.global.idBenefit);
        tempVar.push(this.global.idForm);
        sessionStorage.setItem('formPre', tempVar);
        tempOption.push(this.global.isCC);
        tempOption.push(this.global.isLc);
        tempOption.push(this.global.isDp);
        sessionStorage.setItem('optionPreForm', tempOption);
        console.log('optionPreForm', tempOption);
        this.clientClass = this.global.CLIENTDATALIWA;

        const pdata7 = { option: 'devices_user', idencuesta: this.global.idForm };
        console.log('devices_user', pdata7);
        this.global.consultar(pdata7, (err7, response7) => {
          console.log('Datos devices_user', response7);
          this.deviceUser = response7;
        });
        const pdata6 = { option: 'fotosformulario', idencuesta: this.global.idForm };
        console.log('fotosformulario', pdata6);
        this.global.consultar(pdata6, (err6, response6) => {
          console.log('Datos fotosformulario', response6);
          this.fotosFormulario = response6;
        });
        const pdata5 = { option: 'porcentaje', idencuesta: this.global.idForm };
        console.log('porcentaje', pdata5);
        this.global.consultar(pdata5, (err5, response5) => {
          console.log('Datos porcentaje', response5);
          this.global.porcentaje = response5;
        });
        const pdata4 = { option: 'fotosfirma', idencuesta: this.global.idForm };
        console.log('fotosfirma', pdata4);
        this.global.consultar(pdata4, (err4, response4) => {
          console.log('Datos fotosfirma', response4);
          this.firma = response4;
          if (this.firma.length != 0) {
            this.isEmpty = false;
          }
        });
        const pdata3 = { option: 'devices_photos', idencuesta: this.global.idForm };
        console.log('devices_photos', pdata3);
        this.global.consultar(pdata3, (err3, response3) => {
          console.log('devices_photos', response3);
          this.devicePhotos = response3;
        });
        const pdata2 = { option: 'devices', idencuesta: this.global.idForm };
        console.log('devices', pdata2);
        this.global.consultar(pdata2, (err2, response2) => {
          console.log('Datos devices', response2);
          this.deviceData = response2;
          console.log('FOTOS EXISTENTES-----', this.deviceData);
          console.log('FOTOS EXISTENTES LENGHT-----', this.deviceData.length);
          this.deviceFlag = true;
        });
        setTimeout(() => {
          const pdata1 = { option: 'formulario', idencuesta: this.global.idBenefit };
          console.log('formulario', pdata1);
          this.global.consultar(pdata1, (err1, response1) => {
            console.log('Datos formulario', response1);
            this.formData = response1;
            this.openFlag = true;
            this.spinner.hide();
          });
        }, 60);

      }, 60);
    }
  }

  // EDITAR DISPOSITIVO


  openEditDevice(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-editDevice' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  editDevice(data, content) {
    console.log('Seleccionado', data, 'Id');
    this.idDisp = data;
    for (let i = 0; i < this.deviceData.length; i++) {
      if (this.deviceData[i][0] == data) {
        this.editableDevice[0] = this.deviceData[i];
      } else {
        continue;
      }
    }

    if (this.editableDevice[0] != undefined) {
      this.marca = this.editableDevice[0][2];
      this.referencia = this.editableDevice[0][3];
      this.serial = this.editableDevice[0][4];
      this.tipo = this.editableDevice[0][5];
      this.estado = this.editableDevice[0][6];
      this.fechaInstalacion = this.editableDevice[0][7];
    } else {
      this.editableDevice = [];
    }
    console.log(this.editableDevice);
    this.openEditDevice(content);
  }
  exploreImgDisp() {
    let elem: HTMLElement = document.querySelector('#exploreEditPhoto');
    elem.click();
  }
  onNewFileSelected1(event) {
    console.log(event);
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcEditImg[i] = imagen.src;
        console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.isFullEdit = true;

  }

  loadEditDevicePhoto() {
    let idEquipo = this.global.Id_Usuario + '-' + moment.now();
    if (this.isFullEdit == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;

          for (let i = 0; i < this.srcEditImg.length; i++) {
            con1++;
            this.pathEditName = this.global.Id_Usuario + '_' + moment().unix() + '-' + i;
            console.log('Foto #' + i, this.srcEditImg[i]);
            const FOTOO = this.srcEditImg[i].slice(23, this.srcEditImg[i].length);
            // nombre de la imagen en el servidor this.Photo
            const imageName = i + '-' + moment().unix() + '.jpg';
            const imageBlob = this.dataURItoBlob(FOTOO);
            const imageFile = FOTOO;
            const data = new FormData();
            console.log(imageFile, imageBlob);
            data.append('image', imageFile);
            data.append('nombre', imageName);
            data.append('ruta', this.pathEditName);
            console.log(this.pathEditName);
            console.log(imageFile);
            console.log(imageName);
            $.ajax({
              url: 'https://www.php.engenius.com.co/subir_foto_dispo_LIWA.php',
              type: 'post',
              dataType: 'json',
              data,
              processData: false,
              contentType: false
            }).then((data1) => {
              console.log(data, data1);
              this.imgsEditUrls[i] = data1;
              con2++;
              if (this.imgsEditUrls[i] == 'BADEXT' || this.imgsEditUrls[i] == null || this.imgsEditUrls[i] == undefined || this.imgsEditUrls[i] == '' || this.imgsEditUrls[i] == 'NULL') {
                this.alert.descErr('Error al subir la foto, verifique nuevamente');
              } else {
                try {
                  const query = 'REPLACE INTO db_liwa.Fotos_Equipos (Id_Foto_Equipo,Id_Dispositivo,Id_Formulario,Rutalocal,Rutaserver,Estado,Fecha,upload,edit,IsDelete,IsActive)' +
                    ' VALUES (\'' +
                    idEquipo + '\',\'' +
                    this.idDisp + '\',\'' +
                    this.global.idForm + '\',\'' +
                    imageName + '\',\'' +
                    data1 + '\',\'' +
                    1 + '\',\'' +
                    moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
                    1 + '\',\'' +
                    0 + '\',\'' +
                    0 + '\',\'' +
                    1 + '\');';
                  const pdata1 = { option: 'insertar3', texto: query };
                  this.global.consultar(pdata1, async (err, response) => {
                    console.log(response, query);
                    if (err == null && response == true) {
                      this.alert.descValid('Fotos cargadas correctamente');
                      this.ngOnInit();
                    } else {
                      this.alert.descErr('Hubo un error intente nuevamente');
                    }
                  });
                  if (con1 == con2 && con2 == this.srcEditImg.length) {

                    setTimeout(() => {
                      this.spinner.hide();
                      this.ngOnInit();
                    }, 1000);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        console.log(error);
      }
    }
  }
  confirmEditDevice() {
    if (this.marca == undefined || this.referencia == undefined || this.serial == undefined || this.tipo == undefined || this.estado == undefined) {
      this.alert.descErr('Error, verifique que no hayan campos vacíos e intente nuevamente');
    } else {
      if (this.isFullEdit == true) {
        const query = 'UPDATE db_liwa.Devices SET Marca = \'' + this.marca + '\', Referencia = \'' + this.referencia + '\', Serial = \'' + this.serial + '\',Tipo = \'' + this.tipo + '\',Estado = \'' + this.estado +
          '\'' + ' WHERE Id_Dispositivo = \'' + this.idDisp + '\'';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('El dispositivo se ha editado correctamente!');
            this.loadEditDevicePhoto();
          } else {
            this.alert.descErr('Hubo un problema, intente nuevamente');
          }
        });
      } else {
        const query = 'UPDATE db_liwa.Devices SET Marca = \'' + this.marca + '\', Referencia = \'' + this.referencia + '\', Serial = \'' + this.serial + '\',Tipo = \'' + this.tipo + '\',Estado = \'' + this.estado +
          '\'' + ' WHERE Id_Dispositivo = \'' + this.idDisp + '\'';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('El dispositivo se ha editado correctamente');
            this.ngOnInit();
          } else {
            this.alert.descErr('Hubo un problema, intente nuevamente');
          }
        });

      }

    }


  }
  // FIN EDITAR DISPOSITIVO

  //  NUEVO DISPOSITIVO

  openNewDevice(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-newDevice' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  exploreNewImg() {
    let elem: HTMLElement = document.querySelector('#exploreNewPhoto');
    elem.click();
  }
  onNewFileSelected(event) {
    console.log(event);
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcNewImg[i] = imagen.src;
        console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.isFullNew = true;
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  loadNewDevicePhoto() {
    let idEquipo = this.global.Id_Usuario + '-' + moment.now();
    if (this.isFullNew == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;

          for (let i = 0; i < this.srcNewImg.length; i++) {
            con1++;
            this.pathNewName = this.global.Id_Usuario + '_' + moment().unix() + '-' + i;
            console.log('Foto #' + i, this.srcNewImg[i]);
            const FOTOO = this.srcNewImg[i].slice(23, this.srcNewImg[i].length);
            // nombre de la imagen en el servidor this.Photo
            const imageName = i + '-' + moment().unix() + '.jpg';
            const imageBlob = this.dataURItoBlob(FOTOO);
            const imageFile = FOTOO;
            const data = new FormData();
            console.log(imageFile, imageBlob);
            data.append('image', imageFile);
            data.append('nombre', imageName);
            data.append('ruta', this.pathNewName);
            console.log(this.pathNewName);
            console.log(imageFile);
            console.log(imageName);
            $.ajax({
              url: 'https://www.php.engenius.com.co/subir_foto_dispo_LIWA.php',
              type: 'post',
              dataType: 'json',
              data,
              processData: false,
              contentType: false
            }).then((data1) => {
              console.log(data, data1);
              this.imgsNewUrls[i] = data1;
              con2++;
              if (this.imgsNewUrls[i] == 'BADEXT' || this.imgsNewUrls[i] == null || this.imgsNewUrls[i] == undefined || this.imgsNewUrls[i] == '' || this.imgsNewUrls[i] == 'NULL') {
                this.alert.descErr('Error al subir la foto, verifique nuevamente');
              } else {
                try {
                  const query = 'REPLACE INTO db_liwa.Fotos_Equipos (Id_Foto_Equipo,Id_Dispositivo,Id_Formulario,Rutalocal,Rutaserver,Estado,Fecha,upload,edit,IsDelete,IsActive)' +
                    ' VALUES (\'' +
                    idEquipo + '\',\'' +
                    this.global.idDispositivo + '\',\'' +
                    this.global.idForm + '\',\'' +
                    imageName + '\',\'' +
                    data1 + '\',\'' +
                    1 + '\',\'' +
                    moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
                    1 + '\',\'' +
                    0 + '\',\'' +
                    0 + '\',\'' +
                    1 + '\');';
                  const pdata1 = { option: 'insertar3', texto: query };
                  this.global.consultar(pdata1, async (err, response) => {
                    console.log(response, query);
                    if (err == null && response == true) {
                      this.alert.descValid('Fotos cargadas correctamente');
                      this.ngOnInit();
                    } else {
                      this.alert.descErr('Hubo un error intente nuevamente');
                    }
                  });
                  if (con1 == con2 && con2 == this.srcNewImg.length) {

                    setTimeout(() => {
                      this.spinner.hide();
                      this.ngOnInit();
                    }, 1000);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        console.log(error);
      }
    }
  }
  saveNewDevice() {


    if (this.newmarca == undefined || this.newreferencia == undefined || this.newserial == undefined || this.newtipo == undefined || this.newestado == undefined) {
      this.alert.descErr();
    } else {
      if (this.isFullNew == true) {
        this.global.idDispositivo = this.global.Id_Usuario + '-D-' + moment().format('x');
        const query = 'INSERT INTO db_liwa.Devices (Id_Dispositivo, Id_Formulario, Marca, Referencia, Serial, Tipo, Estado, Fecha_instalacion)' +
          ' VALUES (\'' +
          this.global.idDispositivo + '\', \'' +
          this.global.idForm + '\',\'' +
          this.newmarca + '\',\'' +
          this.newreferencia + '\',\'' +
          this.newserial + '\',\'' +
          this.newtipo + '\',\'' +
          this.newestado + '\');';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('¡El nuevo dispositivo se registró con éxito!');
            this.newmarca = '';
            this.newreferencia = '';
            this.newserial = '';
            this.newtipo = '';
            this.newestado = '';
            this.newfechaInstalacion = '';
            this.loadNewDevicePhoto();

          }
        });
      } else {
        this.global.idDispositivo = this.global.Id_Usuario + '-D-' + moment().format('x');
        const query = 'INSERT INTO db_liwa.Devices (Id_Dispositivo, Id_Formulario, Marca, Referencia, Serial, Tipo, Estado, Fecha_instalacion)' +
          ' VALUES (\'' +
          this.global.idDispositivo + '\', \'' +
          this.global.idForm + '\',\'' +
          this.newmarca + '\',\'' +
          this.newreferencia + '\',\'' +
          this.newserial + '\',\'' +
          this.newtipo + '\',\'' +
          this.newestado + '\');';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('¡El nuevo dispositivo se registró con éxito!');
            this.newmarca = '';
            this.newreferencia = '';
            this.newserial = '';
            this.newtipo = '';
            this.newestado = '';
            this.ngOnInit();
          }
        });
      }
    }

  }
  //  FIN NUEVO DISPOSITIVO


  // SUBIR FIRMA
  subirFirma() {
    let elem: HTMLElement = document.querySelector('#exploreFirmPhoto');
    elem.click();
  }
  onNewFileSelectedFirm(event) {
    console.log(event);
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcFirmImg[i] = imagen.src;
        console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.isFullFirm = true;
  }
  uploadFirm() {
    this.spinner.show();
    console.log(this.formData);
    if (this.isFullFirm == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      let idFirma = this.global.Id_Usuario + '-' + moment.now();
      console.log('Foto', this.srcFirmImg[0]);
      const FOTOO = this.srcFirmImg[0].slice(23, this.srcFirmImg[0].length);
      //nombre de la imagen en el servidor this.Photo
      const imageName = this.global.Id_Usuario + '_' + moment().unix() + '.jpg';
      const imageBlob = this.dataURItoBlob(FOTOO);
      //const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
      const imageFile = FOTOO;
      const data = new FormData();
      console.log(imageFile, imageBlob);
      data.append('image', imageFile);
      data.append('nombre', imageName);
      data.append('ruta', this.pathFirmName);
      $.ajax({
        url: 'https://www.php.engenius.com.co/subir_foto_firma_LIWA.php',
        type: 'post',
        dataType: 'json',
        data,
        processData: false,
        contentType: false
      }).then((data1) => {
        console.log(data, data1);
        this.fotoFirmUrl = data1;
        if (this.fotoFirmUrl == 'BADEXT' || this.fotoFirmUrl == null || this.fotoFirmUrl == undefined || this.fotoFirmUrl == "" || this.fotoFirmUrl == 'NULL') {
          this.alert.descErr('Error al subir la foto, verifique nuevamente');
        } else {
          try {
            const query = 'REPLACE INTO db_liwa.fotos_firma (Id_Foto_Firma,Id_Formulario,documento,rutalocal,rutaserver,estado,fecha,upload,edit)' +
              ' VALUES (\'' +
              idFirma + '\',\'' +
              this.global.idForm + '\',\'' +
              this.formData[0][15] + '\',\'' +
              imageName + '\',\'' +
              data1 + '\',\'' +
              1 + '\',\'' +
              moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
              1 + '\',\'' +
              0 + '\');';
            const pdata1 = { option: 'insertar3', texto: query };
            this.global.consultar(pdata1, async (err, response) => {
              console.log(response, query);
              if (err == null && response == true) {
                this.alert.descValid('Foto cargada correctamente');
                this.spinner.hide();
                this.ngOnInit();
              } else {
                this.alert.descErr('Hubo un error intente nuevamente');
                this.spinner.hide();
              }
            });
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  }

  // FIN SUBIR FIRMA

  // EDITAR INFO PERSONAL
  startCC() {
    this.editCCon = true;
    this.enombreCompleto = this.formData[0][14];
    this.etipoDocumento = this.formData[0][15];
    this.enumDocumento = this.formData[0][16];
    this.etelefono = this.formData[0][17];
    this.emaile = this.formData[0][18];
    this.eocupacion = this.formData[0][20];
  }
  confirmCC() {
    if (this.enombreCompleto == undefined || this.etipoDocumento == undefined || this.enumDocumento == undefined || this.etelefono == undefined || this.emaile == undefined || this.eocupacion == undefined) {
      this.alert.descErr();
      this.enombreCompleto = this.formData[0][14];
      this.etipoDocumento = this.formData[0][15];
      this.enumDocumento = this.formData[0][16];
      this.etelefono = this.formData[0][17];
      this.emaile = this.formData[0][18];
      this.eocupacion = this.formData[0][20];
      this.editCCon = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Beneficiario SET Nombre = ' + '\'' + this.enombreCompleto + '\','
            + 'TipoDoc = ' + '\'' + this.etipoDocumento + '\','
            + 'NumeroDoc = ' + '\'' + this.enumDocumento + '\','
            + 'Telefono = ' + '\'' + this.etelefono + '\','
            + 'Email = ' + '\'' + this.emaile + '\','
            + 'Ocupacion = ' + '\'' + this.eocupacion + '\''
            + 'WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.enombreCompleto = '';
              this.etipoDocumento = '';
              this.enumDocumento = '';
              this.etelefono = '';
              this.emaile = '';
              this.eocupacion = '';
              this.editCCon = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.editCCon = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
        }

      }, 500);

    }
  }
  startVV() {
    this.editVVin = true;
    this.eUsoPredio = this.formData[0][37];
    this.esPropio = this.formData[0][34];
    this.eMaterialPredio = this.formData[0][35];
    this.eOtroMaterialPredio = this.formData[0][36];
    this.eUbicacionMedidor = this.formData[0][38];
    this.eUbicacionMedidorOtro = this.formData[0][39];
    this.eAlturaMed = this.formData[0][41];
    this.eDistanciaMed = this.formData[0][40];

  }
  confirmVV() {
    if (this.eUsoPredio == undefined || this.esPropio == undefined || this.eMaterialPredio == undefined || this.eOtroMaterialPredio == undefined || this.eUbicacionMedidor == undefined || this.eUbicacionMedidorOtro == undefined || this.eAlturaMed == undefined || this.eDistanciaMed == undefined) {
      this.alert.descErr();
      this.eUsoPredio = this.formData[0][36];
      this.esPropio = this.formData[0][31];
      this.eMaterialPredio = this.formData[0][34];
      this.eOtroMaterialPredio = this.formData[0][35];
      this.eUbicacionMedidor = this.formData[0][37];
      this.eUbicacionMedidorOtro = this.formData[0][38];
      this.eAlturaMed = this.formData[0][40];
      this.eDistanciaMed = this.formData[0][39];
      this.editVVin = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Vivienda SET Vivienda = ' + '\'' + this.esPropio + '\','
            + 'Material = ' + '\'' + this.eMaterialPredio + '\','
            + 'Otro_V = ' + '\'' + this.eOtroMaterialPredio + '\','
            + 'Uso = ' + '\'' + this.eUsoPredio + '\','
            + 'Ubicacion = ' + '\'' + this.eUbicacionMedidor + '\','
            + 'Otro_U = ' + '\'' + this.eUbicacionMedidorOtro + '\','
            + 'Apartado = ' + '\'' + this.eDistanciaMed + '\','
            + 'Altura = ' + '\'' + this.eAlturaMed + '\''
            + 'WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.eUsoPredio = '';
              this.esPropio = '';
              this.eMaterialPredio = '';
              this.eOtroMaterialPredio = '';
              this.eUbicacionMedidor = '';
              this.eUbicacionMedidorOtro = '';
              this.eAlturaMed = '';
              this.eDistanciaMed = '';
              this.editVVin = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.editVVin = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
        }

      }, 500);

    }

  }
  startCM() {
    this.editCM = true;
    this.eCableado = this.formData[0][42];
    this.eCaja = this.formData[0][43];
    this.eEquipoM = this.formData[0][44];
    this.eRiesgo = this.formData[0][45];
  }
  confirmCM() {

    if (this.eCableado == undefined || this.eCaja == undefined || this.eEquipoM == undefined || this.eRiesgo == undefined) {
      this.alert.descErr();
      this.eCableado = this.formData[0][41];
      this.eCaja = this.formData[0][42];
      this.eEquipoM = this.formData[0][43];
      this.eRiesgo = this.formData[0][44];
      this.editCM = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Vivienda SET Cableado = ' + '\'' + this.eCableado + '\','
            + 'Caja = ' + '\'' + this.eCaja + '\','
            + 'Equipo_M = ' + '\'' + this.eEquipoM + '\','
            + 'Riesgo = ' + '\'' + this.eRiesgo + '\''
            + 'WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.eCableado = '';
              this.eCaja = '';
              this.eEquipoM = '';
              this.eRiesgo = '';
              this.editCM = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.editCM = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
        }

      }, 500);

    }

  }


  // FIN EDITAR INFO PERSONAL

  //EDITAR LOCALIZACIÓN
  startLC() {
    this.editLCon = true;
    this.editMunicipio = this.formData[0][24];
    this.editcomoLlego = this.formData[0][26];
    this.editVereda = this.formData[0][25];
    this.editDepartamento = this.formData[0][23];
    this.editOperador = this.formData[0][21];
    this.editCobertura = this.formData[0][22];
    this.editLatitud = this.formData[0][5];
    this.editLongitud = this.formData[0][6];
    this.editAltitud = this.formData[0][7]
  }
  confirmLC() {
    if (this.editMunicipio == undefined || this.editcomoLlego == undefined || this.editDepartamento == undefined || this.editVereda == undefined || this.editOperador == undefined || this.editCobertura == undefined || this.editAltitud == undefined || this.editLatitud == undefined || this.editLongitud == undefined) {
      this.alert.descErr();
      this.editMunicipio = this.formData[0][24];
      this.editVereda = this.formData[0][25];
      this.editcomoLlego = this.formData[0][26];
      this.editDepartamento = this.formData[0][23];
      this.editOperador = this.formData[0][21];
      this.editCobertura = this.formData[0][22];
      this.editLatitud = this.formData[0][5];
      this.editLongitud = this.formData[0][6];
      this.editLongitud = this.formData[0][7];
      this.editLCon = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Beneficiario SET Depto = ' + '\'' + this.editDepartamento + '\','
            + 'Municipio = ' + '\'' + this.editMunicipio + '\','
            + 'Vereda = ' + '\'' + this.editVereda + '\','
            + 'Descripcion = ' + '\'' + this.editcomoLlego + '\','
            + 'Operador = ' + '\'' + this.editOperador + '\','
            + 'Cobertura = ' + '\'' + this.editCobertura + '\''
            + 'WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              const query2 = 'UPDATE db_liwa.Formulario SET U_latitud = ' + '\'' + this.editLatitud + '\','
                + 'U_longitud = ' + '\'' + this.editLongitud + '\','
                + 'U_altitud = ' + '\'' + this.editAltitud + '\''
                + 'WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
              const pdata2 = { option: 'insertar3', texto: query2 };
              this.global.consultar(pdata2, async (err2, response2) => {
                console.log(response2, query2);
                if (err2 == null && response2 == true) {
                  this.alert.descValid('¡Los datos de localización se actualizaron con éxito!');
                  this.editMunicipio = '';
                  this.editcomoLlego = '';
                  this.editDepartamento = '';
                  this.editOperador = '';
                  this.editCobertura = '';
                  this.editLatitud = '';
                  this.editAltitud = '';
                  this.editLongitud = '';
                  this.editLCon = false;
                  this.ngOnInit();
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.editCCon = false;
                  this.spinner.hide();
                }
              });
            } else {
              this.alert.descErr();
              this.editCCon = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
        }

      }, 500);

    }
  }
  //FIN EDITAR LOCALIZACIÓN


  startMP() {
    this.editMP = true;
    this.ePagoConsumo = this.formData[0][51];
    this.eEsquema = this.formData[0][52];
    this.eCalidad = this.formData[0][53];
    this.ePercepcion = this.formData[0][54];
  }
  confirmMP() {
    if (this.ePagoConsumo == undefined || this.eEsquema == undefined || this.eCalidad == undefined || this.ePercepcion == undefined) {
      this.alert.descErr();
      this.ePagoConsumo = this.formData[0][51];
      this.eEsquema = this.formData[0][52];
      this.eCalidad = this.formData[0][53];
      this.ePercepcion = this.formData[0][54];
      this.editMP = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Metodologia SET Pago_Consumo = ' + '\'' + this.ePagoConsumo + '\','
            + 'Esquema = ' + '\'' + this.eEsquema + '\','
            + 'Calidad = ' + '\'' + this.eCalidad + '\','
            + 'Percepcion = ' + '\'' + this.ePercepcion + '\''
            + 'WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.ePagoConsumo = '';
              this.eEsquema = '';
              this.eCalidad = '';
              this.ePercepcion = '';
              this.editMP = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.editMP = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
        }

      }, 500);

    }
  }

  startEU() {
    this.editEU = true;
    this.eTv = this.deviceUser[0][2];
    this.eNevera = this.deviceUser[0][3];
    this.eVentilador = this.deviceUser[0][4];
    this.eLicuadora = this.deviceUser[0][5];
    this.eImpulsor = this.deviceUser[0][6];
    this.ePlancha = this.deviceUser[0][7];
    this.eBombillos = this.deviceUser[0][8];
    this.eTomasCarga = this.deviceUser[0][9];
    this.eLavadora = this.deviceUser[0][10];
    this.eComputador = this.deviceUser[0][11];
    this.eModem = this.deviceUser[0][12];
    this.eAireAc = this.deviceUser[0][13];
    this.eEquipoSonido = this.deviceUser[0][14];
    this.eOtroEquipo = this.deviceUser[0][15];
    this.eOtroEquipoA = this.deviceUser[0][16];
  }
  confirmEU() {
    if (this.eTv == undefined || this.eNevera == undefined || this.eVentilador == undefined || this.eLicuadora == undefined ||
      this.eImpulsor == undefined || this.ePlancha == undefined || this.eBombillos == undefined || this.eTomasCarga == undefined ||
      this.eLavadora == undefined || this.eComputador == undefined || this.eModem == undefined || this.eAireAc == undefined ||
      this.eEquipoSonido == undefined || this.eOtroEquipo == undefined || this.eOtroEquipoA == undefined) {
      this.alert.descErr();
      this.eTv = this.deviceUser[0][2];
      this.eNevera = this.deviceUser[0][3];
      this.eVentilador = this.deviceUser[0][4];
      this.eLicuadora = this.deviceUser[0][5];
      this.eImpulsor = this.deviceUser[0][6];
      this.ePlancha = this.deviceUser[0][7];
      this.eBombillos = this.deviceUser[0][8];
      this.eTomasCarga = this.deviceUser[0][9];
      this.eLavadora = this.deviceUser[0][10];
      this.eComputador = this.deviceUser[0][11];
      this.eModem = this.deviceUser[0][12];
      this.eAireAc = this.deviceUser[0][13];
      this.eEquipoSonido = this.deviceUser[0][14];
      this.eOtroEquipo = this.deviceUser[0][15];
      this.eOtroEquipoA = this.deviceUser[0][16];
      this.editEU = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_liwa.Devices_User SET TV = ' + '\'' + this.eTv + '\','
            + 'Nevera = ' + '\'' + this.eNevera + '\','
            + 'Ventilador = ' + '\'' + this.eVentilador + '\','
            + 'Licuadora = ' + '\'' + this.eLicuadora + '\','
            + 'Impulsor = ' + '\'' + this.eImpulsor + '\','
            + 'Plancha = ' + '\'' + this.ePlancha + '\','
            + 'Bombillos = ' + '\'' + this.eBombillos + '\','
            + 'Tomas = ' + '\'' + this.eTomasCarga + '\','
            + 'Lavadora = ' + '\'' + this.eLavadora + '\','
            + 'Computador = ' + '\'' + this.eComputador + '\','
            + 'Modem = ' + '\'' + this.eModem + '\','
            + 'Aire = ' + '\'' + this.eAireAc + '\','
            + 'Equipo_Sonido = ' + '\'' + this.eEquipoSonido + '\','
            + 'Otro = ' + '\'' + this.eOtroEquipo + '\','
            + 'Otro_A = ' + '\'' + this.eOtroEquipoA + '\''
            + ' WHERE Id_Formulario =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.eTv = '';
              this.eNevera = '';
              this.eVentilador = '';
              this.eLicuadora = '';
              this.eImpulsor = '';
              this.ePlancha = '';
              this.eBombillos = '';
              this.eTomasCarga = '';
              this.eLavadora = '';
              this.eComputador = '';
              this.eModem = '';
              this.eAireAc = '';
              this.eEquipoSonido = '';
              this.eOtroEquipo = '';
              this.eOtroEquipoA = '';
              this.editEU = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.editEU = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
        }
      }, 500);

    }


  }

  verFotoM3(data) {
    console.log('ruta', data);
    this.pathDetailDevices = data;
  }
  openSeeDetail(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-imgDetailDevices', size: 'xl', centered: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }



  validEditEU() {
    if (this.eOtroEquipo == '-') {
      this.flagOtherGear = false;
      this.eOtroEquipoA = '-';
    } else if (this.eOtroEquipo == '' || this.eOtroEquipo == undefined) {
      this.eOtroEquipo = '-'
      this.eOtroEquipoA = '-'
    } else {
      this.flagOtherGear = true;
    }
  }
  validEditMed() {
    if (this.eUbicacionMedidor == 'En la fachada de la vivienda') {
      this.flagMedIn = true;
      this.flagMedOut = false;
      this.flagMedOther = false;
      this.eDistanciaMed = '-';
      this.eUbicacionMedidorOtro = '-';
    } else if (this.eUbicacionMedidor == 'Apartado de la vivienda') {
      this.flagMedOut = true;
      this.flagMedIn = false;
      this.flagMedOther = false;
      this.eUbicacionMedidorOtro = '-';
    } else if (this.eUbicacionMedidorOtro == undefined || this.eDistanciaMed == undefined || this.eAlturaMed == undefined) {
      this.eAlturaMed = '-';
      this.eDistanciaMed = '-';
      this.eUbicacionMedidorOtro = '-';
    } else {
      this.flagMedOther = true;
      this.flagMedIn = false;
      this.flagMedOut = false;
      this.eDistanciaMed = '-';
      this.eAlturaMed = '-';
    }
  }
  // APROBAR METODOS
  aprobarCedula() {
    try {
      const query = 'UPDATE db_liwa.porcentaje SET Cedula = 1 '
        + ' WHERE Id_Formulario =\'' + this.global.idForm + '\';';
      const pdata1 = { option: 'insertar3', texto: query };
      this.global.consultar(pdata1, async (err, response) => {
        console.log(response, query);
        if (err == null && response == true) {
          this.alert.descValid('La revisión de la cédula se aprobó correctamente');
          this.route.navigateByUrl('/listado-preliminar');
        } else {
          this.alert.descErr('Verifique el formulario e intente nuevamente');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  aprobarLocalizacion() {
    const query = 'UPDATE db_liwa.porcentaje SET Localizacion = 1 '
      + ' WHERE Id_Formulario =\'' + this.global.idForm + '\';';
    const pdata1 = { option: 'insertar3', texto: query };
    this.global.consultar(pdata1, async (err, response) => {
      console.log(response, query);
      if (err == null && response == true) {
        this.alert.descErr('La revisión de la localización se aprobó correctamente');
        this.route.navigateByUrl('/listado-preliminar');
      } else {
        this.alert.descErr('Verifique el formulario e intente nuevamente');
      }
    });
  }
  aprobarDispositivos() {
    const query = 'UPDATE db_liwa.porcentaje SET Dispositivo = 1 '
      + ' WHERE Id_Formulario =\'' + this.global.idForm + '\';';
    const pdata1 = { option: 'insertar3', texto: query };
    this.global.consultar(pdata1, async (err, response) => {
      console.log(response, query);
      if (err == null && response == true) {
        this.alert.descValid('La revisión de los dispositivos se aprobó correctamente');
        this.route.navigateByUrl('/listado-preliminar');
      } else {
        this.alert.descErr('Verifique el formulario e intente nuevamente');
      }
    });
  }
  // FIN APROBAR MÉTODOS
}
