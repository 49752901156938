import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
// import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubirArchivosService {

  url:string = 'https://www.php.engenius.com.co/subirexcel.php';
  urlPdf:string = 'https://www.php.engenius.com.co/subirPdf.php';
  urlarchi:string = 'https://www.php.engenius.com.co/subirkml.php';

  constructor( private http:HttpClient) {
  }
  subirarchivo(datos:any):Observable<any>{
    console.log(this.urlarchi, datos);
    return this.http.post(this.urlarchi, datos);
  }
  subirImagen(datos:any):Observable<any>{
    return this.http.post(this.url, datos);
  }
  subirPDF(datos:any):Observable<any>{
    return this.http.post(this.urlPdf, datos);
  }
  consultarExcel(datos:any):Observable<any>{
    return this.http.post(this.url, datos);
  }
  modificarArchivo(datos:any):Observable<any>{
    return this.http.post(this.url, datos);
  }
  eliminarExcel(datos:any):Observable<any>{
    return this.http.post(this.url, datos);
  }
}

