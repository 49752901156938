import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import Swal from "sweetalert2";
@Component({
  selector: 'app-formulario-preliminar-disp-nazareth',
  templateUrl: './formulario-preliminar-disp-nazareth.component.html'
})
export class FormularioPreliminarDispNazarethComponent implements OnInit {

  clientClass;
  Num_formulario;
  Dia;
  Mes;
  Ano;
  U_latitud;
  U_longitud;
  U_altitud;
  U_depto;
  U_codigo_depto;
  U_municipio;
  U_codigo_municipio;
  U_vereda;
  U_corregimiento;
  S_E = '';
  Circuito = '';
  Urbano = '';
  Conductor = '';
  Nodo = '';
  Placa = '';
  Descrip = '';
  Concatenada = '';
  Ubicacion = '';
  Tag = '';
  Marca = '';
  Cantidad = '';
  Tipo = '';
  Clase = '';
  Serie = '';
  Capacidad = '';
  Funcionamiento = '';
  Mtto = '';
  Estado = '';
  Edad = '';
  Creg = '';
  devicesData: any[] = [];
  openFlag: boolean = false;
  call: boolean = false;
  call1: boolean = true;
  call2: boolean = true;
  edit: boolean = false;
  edit1: boolean = false;
  edit2: boolean = false;
  fotoUrl: any[] = [];
  Photo;
  srcImg: any[] = [];
  evidence: boolean = false;
  ext;
  fotosForm;
  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    this.spinner.show();
    if (this.global.idForm == undefined) {
      this.route.navigateByUrl('/listado-preliminar-disp-nazareth');
      this.spinner.hide();
    } else {
      setTimeout(() => {
        this.clientClass = this.global.CLIENTDATALIWA;
        const pdata5 = { option: 'form-disp-nazareth', idencuesta: this.global.idForm };
        this.global.consultar(pdata5, (err, response4) => {
          console.log('Datos form-equipos', response4);
          this.devicesData = response4;
          const pdata2 = { option: 'form-nazareth-fotos-equipos', idBeneficiario: this.global.idForm };
          this.global.consultar(pdata2, (err, response) => {
            this.fotosForm = response;
            console.log('Fotos equipos',response);
            console.log('query',pdata2);
            this.openFlag = true;
            this.spinner.hide();
          });
        });
      }, 500);
    }
  }

  animatedTab(numbr: number) {
    switch (numbr) {
      case 1:
        this.call = !this.call;
        break;
      case 2:
        this.call1 = !this.call1;
        break;
      case 3:
        this.call2= !this.call2;
        break;

      default:
        break;
    }
  }
  editModule(numb: number) {
    switch (numb) {
      case 0:
        this.edit = !this.edit;
        this.U_depto = this.devicesData[0][6];
        this.U_codigo_depto = this.devicesData[0][7];
        this.U_municipio = this.devicesData[0][8];
        this.U_codigo_municipio = this.devicesData[0][9];
        this.U_vereda = this.devicesData[0][10];
        this.U_corregimiento = this.devicesData[0][11];
        this.U_latitud = this.devicesData[0][3];
        this.U_longitud = this.devicesData[0][4];
        this.U_altitud = this.devicesData[0][5];
        break;
      case 1:
        this.edit1 = !this.edit1;
        this.S_E = this.devicesData[0][18];
        this.Circuito = this.devicesData[0][19];
        this.Urbano = this.devicesData[0][20];
        this.Conductor = this.devicesData[0][21];
        this.Nodo = this.devicesData[0][22];
        this.Placa = this.devicesData[0][23];
        this.Descrip = this.devicesData[0][24];
        this.Concatenada = this.devicesData[0][25];
        this.Ubicacion = this.devicesData[0][26];
        this.Tag = this.devicesData[0][27];
        this.Marca = this.devicesData[0][28];
        this.Cantidad = this.devicesData[0][29];
        this.Tipo = this.devicesData[0][30];
        this.Clase = this.devicesData[0][31];
        this.Serie = this.devicesData[0][32];
        this.Capacidad = this.devicesData[0][33];
        this.Funcionamiento = this.devicesData[0][34];
        this.Mtto = this.devicesData[0][35];
        this.Estado = this.devicesData[0][36];
        this.Edad = this.devicesData[0][37];
        this.Creg = this.devicesData[0][38];
        break;
      case 2:
        this.edit2 = !this.edit2;
        break;

      default:
        break;
    }
  }

  confirmEditForm() {
    if (this.U_depto == undefined || this.U_codigo_depto == undefined || this.U_municipio == undefined ||
      this.U_codigo_municipio == undefined || this.U_vereda == undefined || this.U_corregimiento == undefined ||
      this.U_latitud == undefined || this.U_longitud == undefined || this.U_altitud == undefined) {
      this.alert.descErr('Verifique que no hayan campos vacíos');
      this.U_depto = this.devicesData[0][6];
      this.U_codigo_depto = this.devicesData[0][7];
      this.U_municipio = this.devicesData[0][8];
      this.U_codigo_municipio = this.devicesData[0][9];
      this.U_vereda = this.devicesData[0][10];
      this.U_corregimiento = this.devicesData[0][11];
      this.U_latitud = this.devicesData[0][3];
      this.U_longitud = this.devicesData[0][4];
      this.U_altitud = this.devicesData[0][5];
      this.edit = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.ubicacion SET '
            + 'U_latitud = ' + '\'' + this.U_latitud + '\','
            + 'U_longitud = ' + '\'' + this.U_longitud + '\','
            + 'U_altitud = ' + '\'' + this.U_altitud + '\','
            + 'U_depto = ' + '\'' + this.U_depto + '\','
            + 'U_codigo_depto = ' + '\'' + this.U_codigo_depto + '\','
            + 'U_municipio = ' + '\'' + this.U_municipio + '\','
            + 'U_codigo_municipio = ' + '\'' + this.U_codigo_municipio + '\','
            + 'U_vereda = ' + '\'' + this.U_vereda + '\','
            + 'U_corregimiento = ' + '\'' + this.U_corregimiento + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.U_depto = '';
              this.U_codigo_depto = '';
              this.U_municipio = '';
              this.U_codigo_municipio = '';
              this.U_vereda = '';
              this.U_corregimiento = '';
              this.U_latitud = '';
              this.U_longitud = '';
              this.U_altitud = '';
              this.edit = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  confirmEdit1Form() {
    if (
      this.S_E == undefined || this.Circuito == undefined || this.Urbano == undefined ||
      this.Conductor == undefined || this.Nodo == undefined || this.Placa == undefined ||
      this.Descrip == undefined || this.Concatenada == undefined || this.Ubicacion == undefined ||
      this.Tag == undefined || this.Marca == undefined || this.Cantidad == undefined ||
      this.Tipo == undefined || this.Clase == undefined || this.Serie == undefined ||
      this.Capacidad == undefined || this.Funcionamiento == undefined || this.Mtto == undefined ||
      this.Estado == undefined || this.Edad == undefined || this.Creg == undefined
    ) {
      this.alert.descErr('Verifique que no hayan campos vacíos');
      this.S_E = this.devicesData[0][18];
      this.Circuito = this.devicesData[0][19];
      this.Urbano = this.devicesData[0][20];
      this.Conductor = this.devicesData[0][21];
      this.Nodo = this.devicesData[0][22];
      this.Placa = this.devicesData[0][23];
      this.Descrip = this.devicesData[0][24];
      this.Concatenada = this.devicesData[0][25];
      this.Ubicacion = this.devicesData[0][26];
      this.Tag = this.devicesData[0][27];
      this.Marca = this.devicesData[0][28];
      this.Cantidad = this.devicesData[0][29];
      this.Tipo = this.devicesData[0][30];
      this.Clase = this.devicesData[0][31];
      this.Serie = this.devicesData[0][32];
      this.Capacidad = this.devicesData[0][33];
      this.Funcionamiento = this.devicesData[0][34];
      this.Mtto = this.devicesData[0][35];
      this.Estado = this.devicesData[0][36];
      this.Edad = this.devicesData[0][37];
      this.Creg = this.devicesData[0][38];
      this.edit1 = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query = 'UPDATE db_nazareth.equipos SET '
            + 'S_E = ' + '\'' + this.S_E + '\','
            + 'Circuito = ' + '\'' + this.Circuito + '\','
            + 'Urbano = ' + '\'' + this.Urbano + '\','
            + 'Conductor = ' + '\'' + this.Conductor + '\','
            + 'Nodo = ' + '\'' + this.Nodo + '\','
            + 'Placa = ' + '\'' + this.Placa + '\','
            + 'Descrip = ' + '\'' + this.Descrip + '\','
            + 'Concatenada = ' + '\'' + this.Concatenada + '\','
            + 'Ubicacion = ' + '\'' + this.Ubicacion + '\','
            + 'Tag = ' + '\'' + this.Tag + '\','
            + 'Marca = ' + '\'' + this.Marca + '\','
            + 'Cantidad = ' + '\'' + this.Cantidad + '\','
            + 'Tipo = ' + '\'' + this.Tipo + '\','
            + 'Clase = ' + '\'' + this.Clase + '\','
            + 'Serie = ' + '\'' + this.Serie + '\','
            + 'Capacidad = ' + '\'' + this.Capacidad + '\','
            + 'Funcionamiento = ' + '\'' + this.Funcionamiento + '\','
            + 'Mtto = ' + '\'' + this.Mtto + '\','
            + 'Estado = ' + '\'' + this.Estado + '\','
            + 'Edad = ' + '\'' + this.Edad + '\','
            + 'Cod_Creg = ' + '\'' + this.Creg + '\''
            + ' WHERE Id_Encuesta =' + '\'' + this.global.idForm + '\';';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alert.descValid('¡Los datos se actualizaron con éxito!');
              this.S_E = '';
              this.Circuito = '';
              this.Urbano = '';
              this.Conductor = '';
              this.Nodo = '';
              this.Placa = '';
              this.Descrip = '';
              this.Concatenada = '';
              this.Ubicacion = '';
              this.Tag = '';
              this.Marca = '';
              this.Cantidad = '';
              this.Tipo = '';
              this.Clase = '';
              this.Serie = '';
              this.Capacidad = '';
              this.Funcionamiento = '';
              this.Mtto = '';
              this.Estado = '';
              this.Edad = '';
              this.Creg = '';
              this.edit1 = false;
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit1 = false;
              this.spinner.hide();
            }
          });
        } catch (error) {
          console.log(error);
          this.spinner.hide();
        }
      }, 600);
    }
  }
  approveBtn() {
    this.spinner.show();
    setTimeout(() => {

      try {
        const query = 'UPDATE db_nazareth.porcentaje SET '
          + "Porcentaje='1'"
          + ' WHERE Id_Encuesta =' + '\'' + this.global.idForm + '\';';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.alert.descValid('¡La encuesta se aprobó con éxito!');
            this.route.navigateByUrl('/listado-preliminar-disp-nazareth');
            this.spinner.hide();
          } else {
            this.alert.descErr('Error al aprobar, verifique nuevamente ');
            this.spinner.hide();
          }
        });
      } catch (error) {
        console.log(error);
        this.spinner.hide();
      }
    }, 600);
  }

  exploreImg() {
    let elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(this.srcImg);
        for (let i = 0; i < this.srcImg.length; i++) {
          this.Photo = this.srcImg[i];
          this.validExt(this.Photo.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.evidence = true;
  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }
  loadImg() {
    let idProjectFunc = this.devicesData[0][2];
    if (this.evidence == false) {
      this.alert.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;
          for (let i = 0; i < this.srcImg.length; i++) {
            con1++;
            console.log('Foto #' + i, this.srcImg[i]);
            const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
            const imageName = i + '-' + moment().unix() + this.ext;
            console.log(imageName);
            const imageFile = FOTOO;
            const data = new FormData();
            data.append('image', imageFile);
            data.append('nombre', imageName);
            data.append('ruta', idProjectFunc + '-' + moment());
            $.ajax({
              url: 'https://www.php.engenius.com.co/FOT/subir_foto_encuestas_nazaEq.php',
              type: 'post',
              dataType: 'json',
              data,
              processData: false,
              contentType: false
            }).then((data1) => {
              console.log(data, data1);
              this.fotoUrl[i] = data1;
              con2++;
              if (this.fotoUrl[i] == 'BADEXT' || this.fotoUrl[i] == null || this.fotoUrl[i] == undefined || this.fotoUrl[i] == '' || this.fotoUrl[i] == 'NULL') {
                this.alert.descErr('Error al subir la foto, verifique nuevamente');
              } else {
                // # of Photos validator
                let idFotoEnc = idProjectFunc + '-' + i + '-' + moment();
                const query = 'REPLACE INTO db_nazareth.fotos_equipos (Id_Foto_Equipo, Id_Equipo, Id_Proyecto_Funcionario, rutalocal, rutaserver, fecha, upload, edit, Servidor, Reporte, Girar, IsDelete)' +
                  ' VALUES (\'' +
                  idFotoEnc + '\',\'' +
                  this.global.idForm + '\',\'' +
                  idProjectFunc + '\',\'' +
                  '-' + '\',\'' +
                  data1 + '\',\'' +
                  'uploaded' + '\',\'' +
                  moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
                  1 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\',\'' +
                  0 + '\');';
                const pdata1 = { option: 'insertar3', texto: query };
                this.global.consultar(pdata1, async (err, response) => {
                  console.log(response, query);
                  if (err == null && response == true) {
                    this.alert.descValid('Imagenes subidas correcamente');
                  } else {
                    this.alert.descErr('¡Error al subir imagenes! ');
                  }
                });
                if (con1 == con2 && con2 == this.srcImg.length) {
                  this.ngOnInit();
                  this.srcImg = [];
                  this.Photo = [];
                  this.evidence = false;
                  this.spinner.hide();
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        console.log(error);
      }
    }
  }

}
