import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { GlobalAlertService } from 'src/app/global-alert.service';

@Component({
  selector: 'app-adm-ticket',
  templateUrl: './adm-ticket.component.html'
})
export class AdmTicketComponent implements OnInit {
  clientClass;
  importanceClass;
  importanceTitle;
  Tickets: any;
  activatedTix = [];
  archivedTix = [];
  ticketActive: boolean = true;
  ticketArchived = false;
  USERDATALIWA;
  estado;
  descriptionModal;
  modal: NgbModalRef;

  constructor(public global: GlobalService,
    public route: Router, private spinner: NgxSpinnerService, private modalService: NgbModal, private alerta: GlobalAlertService) {
    this.global.initData();

  }

  ngOnInit() {
    this.spinner.show();
    // setTimeout(() => {
      this.archivedTix = [];
      this.activatedTix = [];
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata9 = { option: 'tickets', user: this.global.Id_Usuario };
      this.global.consultar(pdata9, async (err9, response9) => {
        this.Tickets = await response9;
        for (let i = 0; i < this.Tickets.length; i++) {
          if (this.Tickets[i][8] == 0) {
            this.activatedTix.push(this.Tickets[i]);
          }
          if (this.Tickets[i][8] == 1) {
            this.archivedTix.push(this.Tickets[i]);
          }
        }
        console.log(this.activatedTix, 'doctor baider')
        setTimeout(() => {      
          $(function () {
            this.table = $('#TableTickets').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            })
          });
        }, 600);
        this.spinner.hide();
        // this.isActive();
      });

  }

  isActive() {
    if (this.ticketArchived == false) {
      this.ticketActive = !this.ticketActive;
      $(function () {
        this.table = $('#TableTickets').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        })
      });
    } else {
      this.ticketArchived = false;
      this.ticketActive = true;
      $(function () {
        this.table = $('#TableTickets').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        })
      });
    }
  }
  isArchived() {
    if (this.ticketActive == false) {
      this.ticketArchived = !this.ticketArchived;
      $(function () {
        this.table = $('#TableArchive').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        })
      });
    } else {
      this.ticketActive = false;
      this.ticketArchived = true;
      $(function () {
        this.table = $('#TableArchive').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        })
      });
    }

  }

  ver(data) {
    this.global.Id_Ticket = data[0];
    this.route.navigateByUrl('/ver-ticket');
  }
  openArchivedModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'archivedTicket' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  onModal(data: any, content) {
    console.log(data, 'esta es la data que llega')
    this.global.Id_Ticket = data;
    this.openArchivedModal(content);
  }
  archivar() {
    console.log( this.global.Id_Ticket, 'esta es el id global')
    if (this.descriptionModal == undefined || this.descriptionModal == '' && this.global.Id_Ticket == undefined) {
      this.alerta.descErr();
    } else {
      var query = "UPDATE db_liwa.Tickets_New SET IsArchived = '1' WHERE Id_Tickets ='" + this.global.Id_Ticket + "';";
      const pdata1 = { option: 'insertar3', texto: query };
      this.global.consultar(pdata1, async (err, response) => {
        if (err == null && await response == true) {
          const query2 = 'REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)' +
            ' VALUES (\'' +
            this.global.Id_Ticket + '\',\'' +
            this.descriptionModal + '\',\'' +
            moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
            12 + '\',\'' +
            0 + '\',\'' +
            1 + '\');';
          const pdata2 = { option: 'insertar3', texto: query2 };
          this.global.consultar(pdata2, async (err2, response2) => {
            // console.log(response2, query2);
            if (err2 == null && await response2 == true) {
              this.alerta.descValid('Recuerda: los tickets archivados se encuentran dando click en la pestaña de Tickets archivados');
              this.ngOnInit();
            }
          });
        }
      });
      this.estado = 'Archivado';
      this.enviarcorreo();
    }

  }
  enviarcorreo() {
    // setTimeout(() => {
      const pdata9 = { option: 'mailA', ticket: this.global.Id_Ticket, texto: this.estado, novedad: this.descriptionModal, user: this.global.USERDATALIWA[8] };
      this.global.consultar2(pdata9, async  (err2, response2) => {
        // console.log('Datos correo', response2, err2);
      });
    // }, 500);
  }
  openActivedModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'activedTicket' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  onModalA(data: any, content) {
    this.global.Id_Ticket = data;
    this.openActivedModal(content);
  }
  activar() {
    var query = "UPDATE db_liwa.Tickets_New SET IsArchived = '0' WHERE Id_Tickets ='" + this.global.Id_Ticket + "';";
    const pdata1 = { option: 'insertar3', texto: query };
    this.global.consultar(pdata1, async  (err, response) => {
      if (err == null && await response == true) {
        const query2 = 'REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)' +
          ' VALUES (\'' +
          this.global.Id_Ticket + '\',\'' +
          this.descriptionModal + '\',\'' +
          moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
          14 + '\',\'' +
          0 + '\',\'' +
          1 + '\');';
        const pdata2 = { option: 'insertar3', texto: query2 };
        this.global.consultar(pdata2, async  (err2, response2) => {
          // console.log(response2, query2);
          if (err2 == null && await response2 == true) {
            this.alerta.descValid('El ticket se ha activado con éxito')
            this.ngOnInit();
          }
        });
      }
    });
    this.estado = 'Activo';
    this.enviarcorreo();
  }

  eliminar(data) {
    let query = 'UPDATE db_liwa.Tickets_New SET IsDelete = \'1\' WHERE Id_Tickets =\'' + data + '\';';
    const pdata1 = { option: 'insertar', texto: query };
    $.ajax({
      data: pdata1, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
      success(data, textStatus, jQxhr) {
        // console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
      }
    }).then((response) => {
      this.ngOnInit();
    }).catch((err) => {

    });
  }
  //////////// DELETE VALIDATION ///////////////////////////

  deleteVal(data: any) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el ticket?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(data);
      } else {

        // this.ngOnInit();

      }
    });
  }
  //END DELETE VALIDATION //
  agendar(data) {
    this.global.Id_Ticket = data[0];
    this.global.withoutTicket = false;
    this.global.isAgended = true;
    this.route.navigateByUrl('/crear-visita');
  }

  importancia(data) {
    if (data == "AAA") {
      return "badge badge-danger";
    }
    if (data == "AA") {
      return "badge badge-warning";

    }
    if (data == "A") {
      return "badge badge-success";

    }
  }

  importanciaTitle(data) {
    if (data == "AAA") {
      return "URGENTE";
    }
    if (data == "AA") {
      return "MEDIO";
    }
    if (data == "A") {
      return "BAJO";
    }
  }
  formatt(item) {
    return moment(item).format('YYYY/MM/DD HH:mm')
  
  }

}
