import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-beneficiarios',
  templateUrl: './beneficiarios.component.html',
  styleUrls: ['./beneficiarios.component.scss']
})
export class BeneficiariosComponent implements OnInit {
  beneficiarios:any[] = [];
  clientClass;
  constructor(public global: GlobalService, 
              public ruta: Router, 
              public alerta: GlobalAlertService, 
              private spinner: NgxSpinnerService) { 
    
              }

  ngOnInit(): void {
    this.spinner.show();
    if( this.global.idSeccional == undefined || this.global.nombreSeccional == undefined){
      let valTemp: any;
      valTemp = sessionStorage.getItem('secTemp');
      if (valTemp != null) {
        valTemp = valTemp.split(',');
        this.global.idSeccional = valTemp[0];
        this.global.nombreSeccional = valTemp[1];
        let tempVar: any = [];
        tempVar.push(this.global.idSeccional);
        tempVar.push(this.global.nombreSeccional);
        sessionStorage.setItem('secTemp', tempVar);
        setTimeout(() => {
  
        this.clientClass = this.global.CLIENTDATALIWA[8];
        const pdata1 = { option: 'seccional_benefit', idSeccional: this.global.idSeccional};
        // console.log('beneficiarios', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          // console.log('Datos beneficiarios', response1);
          this.beneficiarios = response1;
          $(function () {
            this.table = $('#bseccionalTable').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
        });
        
        this.spinner.hide();
        }, 600);
      }
      this.ruta.navigateByUrl('/proyecto');
      this.spinner.hide();
    }else{
      let tempVar: any = [];
      tempVar.push(this.global.idSeccional);
      tempVar.push(this.global.nombreSeccional);
      sessionStorage.setItem('secTemp', tempVar);
      this.clientClass = this.global.CLIENTDATALIWA[8];
      setTimeout(() => {
      const pdata1 = { option: 'insertar2', texto: `SELECT Id_Encuesta, Nombre_Completo, Municipio, Depto, LastUpdate, Vereda  FROM  db_liwa.AOM WHERE Id_Seccional = ${this.global.idSeccional};`};
      // console.log('beneficiarios', pdata1);
      this.global.consultar(pdata1, async (err1, response1) => {
        console.log('Datos beneficiarios', response1);
        this.beneficiarios = await  response1;
        $(function () {
          this.table = $('#bseccionalTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
      });
      
      this.spinner.hide();
      }, 600);
    
    }
  }
  deleteBen(idBen){
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el beneficiario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor:'#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idBen);
      }else{
        this.ngOnInit();
      }
    });
 

  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Beneficiario SET IsDelete = '1' WHERE Id_Beneficiario ='" + data +"';";
      const pdata1 = {option: 'insertar', texto: query};
      $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
      url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success( data, textStatus, jQxhr ) {
        },
        error( jqXhr, textStatus, errorThrown ) {
        }
        }).then((response) => {
          // console.log(response);
          this.alerta.descValid('Beneficiario eliminado correctamente');
          this.ngOnInit();
          this.spinner.hide();
        }).catch((err) => {
        });
    }, 60);
   
  }
  verBen(idBen, idForm){
    this.global.idBenefit = idBen;
    this.global.idForm = idForm;
    this.ruta.navigateByUrl('/formulario-definitivo'); 
    // console.log(idBen, idForm);
  }

  desvincularBen(idBen){
    Swal.fire({
      title: '<strong>¿Está seguro que desea desvincular el beneficiario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor:'#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.desvincular(idBen);
      }else{
        this.ngOnInit();
      }
    });
 

  }
  desvincular(idBen){
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Beneficiario_Seccional SET IsDelete = '1' WHERE Id_Beneficiario ='" + idBen +"';";
      const pdata1 = {option: 'insertar', texto: query};
      $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
      url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success( data, textStatus, jQxhr ) {
        },
        error( jqXhr, textStatus, errorThrown ) {
        }
        }).then((response) => {
          setTimeout(() => {
            var query = "UPDATE db_liwa.Beneficiario SET IsSeccional = '0' WHERE Id_Beneficiario ='" + idBen +"';";
            const pdata1 = {option: 'insertar', texto: query};
            $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
            url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
              success( data, textStatus, jQxhr ) {
              },
              error( jqXhr, textStatus, errorThrown ) {
              }
              }).then((response) => {
                // console.log(response);
                this.alerta.descValid('Beneficiario desvinculado correctamente');
                this.spinner.hide();
                this.ngOnInit();
              }).catch((err) => {
              });
          }, 60);
        }).catch((err) => {
        });
    }, 60);
  }

}
