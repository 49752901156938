import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-administracion-tecnicos',
  templateUrl: './administracion-tecnicos.component.html',
  styleUrls: ['./administracion-tecnicos.component.css']
})
export class AdministracionTecnicosComponent implements OnInit {

  idProject
  clientClass;
  proyectos: string;
  idSeccional: string ="Seleccione una seccional...";
  idTecnico: string ="Selecciona un Tecnico..."
  text: string = "Seleccione un proyecto...";
  textS: string = "Seleccione una seccional...";
  textT: string = "Seleccione una tecnico...";
  tecnicos= Array();
  seccionales= Array();
  buttonSeccional:boolean = false;
  seccionalOn: boolean = false;
  tecnicoOn: boolean = false; 

  mantenimientos = Array()
  TotalVisitasTerminadasAyer: number;
  TotalVisitasTerminadas: number;
  TotalVisitasAgendadas= Array()
  TotalVisitasEjecucion= Array()
  MantetimientosCorrectivo= Array()
  MantetimientosPreventivos= Array()

  dummyContent = [
    { titulo: 'Visitas en Total', imagen: '../../../assets/img/icons/common/svgEnergy/001-solar energy.svg' },
    { titulo: 'Visitas Terminadas Ayer', imagen: '../../../assets/img/icons/common/svgEnergy/010-wind turbine.svg' },
    { titulo: 'Visitas Terminadas en Total', imagen: '../../../assets/img/icons/common/svgEnergy/012-recycle.svg' },
    { titulo: 'Visitas Agendadas Pendientes', imagen: '../../../assets/img/icons/common/svgEnergy/006-electrical energy.svg' },
    { titulo: 'Mantenimientos Correctivos Totales', imagen: '../../../assets/img/icons/common/svgEnergy/009-battery.svg' },
    { titulo: 'Mantenimientos Preventivos Totales', imagen: '../../../assets/img/icons/common/svgEnergy/011-renewable.svg' },
    
  ];

  constructor(protected global: GlobalService,
    private spinner: NgxSpinnerService,
    public route: Router,
    public alerta: GlobalAlertService,) { }

  ngOnInit(): void {
    this.spinner.show();
    this.global.mantenimientos = []
    this.clientClass = this.global.CLIENTDATALIWA[8];
    // setTimeout(() => {
      const pdata1 = { option: 'insertar2', texto:`SELECT * FROM db_liwa.Maintenance_new WHERE IsDelete = 0;`}
    this.global.consultar(pdata1, async (err, response) => {
      console.log(response, 'eetetetettet')
      this.mantenimientos = await response
      let resp = await response 
      let fechaactual1 = new Date();
      let opfecha = moment(fechaactual1).subtract(1, 'days');
      let FechaI = moment(opfecha).format('YYYY-MM-DD');
      this.TotalVisitasTerminadas = this.mantenimientos.filter( i => i[11] == 'Terminado').length
      this.TotalVisitasTerminadasAyer = this.mantenimientos.filter( i => i[11] == 'Terminado' && i[16].substr(0,10) == FechaI ).length
      this.TotalVisitasAgendadas = await resp.filter( i => i[11] == 'Agendado').length
      this.TotalVisitasEjecucion = await resp.length
      this.MantetimientosCorrectivo = await resp.filter( i => i[10] == 'Correctivo').length
      this.MantetimientosPreventivos = await resp.filter( i => i[10] == 'Preventivo').length
    });
      const datos = {option: 'insertar2', texto: `SELECT id_tipoProyecto, Nombre FROM db_liwa.tipoProyecto WHERE id_client = ${this.global.Id_Client};` }; 
      this.global.consultar(datos, async (err, response) => {
        this.proyectos = await response;
        this.spinner.hide();
      });
    // }, 600);
  }

  changeSelect(valueId) {
    this.spinner.show();
    this.idSeccional = "Seleccione una seccional...";
    this.buttonSeccional = false;
    // setTimeout(() => {
      const pdata4 = { option: 'insertar2', texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '${valueId}' AND IsDelete=0 AND IsActive=1;` };
      this.global.consultar(pdata4, async (err4, response4) => {
        // console.log('seccionalesXZCCXCCZXCXZCC', response4);
        let res:any [] = await response4;
        if (res.length == undefined || res.length == 0) {
          this.seccionales = [];
          this.seccionalOn = true;
          // this.tecnicoOn = true;
          this.spinner.hide();
        } else {
          // this.tecnicoOn = true;
          this.seccionales = await response4;
          this.seccionalOn = true;
          this.spinner.hide();
        }
      });
    // }, 600);

  }
  changeSelectTecnico(valueId) {
    this.spinner.show();
    // this.idSeccional = "Seleccione una seccional...";
    this.buttonSeccional = false;
    // setTimeout(() => {
      const pdata1 = { option: 'insertar2', texto:`SELECT A.Id_user, A.UserName, C.Nombre  FROM db_liwa.User A JOIN db_liwa.tipoProyecto B ON A.Id_Client = B.Id_Client JOIN db_liwa.Seccionales C ON B.id_tipoProyecto =C.Id_Proyecto Where C.Id_seccional = '${valueId}' AND A.Id_Role = 3`}
      this.global.consultar(pdata1, async (err4, response4) => {
        // console.log('seccionalesXZCCXCCZXCXZCC', response4);
        let res:any [] = await response4;
        if (res.length == undefined || res.length == 0) {
          this.seccionales = [];
          this.seccionalOn = true;
          this.tecnicoOn = true;
          this.spinner.hide();
        } else {
          
          this.tecnicoOn = true;
          this.tecnicos = await response4;
          this.seccionalOn = true;
          this.spinner.hide();
        }
      });
    // }, 600);

  }
  selectSeccional() {
    this.spinner.show()
    // console.log("estetetttttttt", this.idTecnico)
    const pdata1 = { option: 'insertar2', texto:`SELECT * FROM db_liwa.Maintenance_new WHERE IsDelete = 0 AND Id_Technical = ${this.idTecnico};`}
    this.global.consultar(pdata1, async (err, response) => {
      if (await response.length > 0)  {
       this.global.idTecnico = this.idTecnico;
       this.global.nombre_tecnico = this.tecnicos.filter(i => i[0] === this.idTecnico)
       this.global.mantenimientos = await response
      //  console.log(this.global.mantenimientos)
       this.spinner.hide()
       this.route.navigateByUrl('/mantenimiento-tecnico');
      } else {
       this.alerta.descErr("este tecnico no tiene ninguna visita agendada registrada")
       this.spinner.hide()  
      }
    })
    
    // sessionStorage.setItem('replanteoSeccional', this.idSeccional);
    
  }
}
