import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import { SubirArchivosService } from 'src/app/subir-archivos.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
 

@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: ['./formatos.component.css']
})
export class FormatosComponent implements OnInit {
FormatosBBDD = new Array();
FormatosInventario = new Array();
FormatosRH = new Array();
FormatosSocial = new Array();
FotosSeccional = new Array();
  trueimg:Boolean = false;
  loader:Boolean = false;
  myimg:string;
  final:Boolean = true;
  msn:string;
  clientClass;
  file;
  tipoFormato;
  nombreFormato = ['Geodatos Usuario','Acta de Entrega','Contrato de Condiciones Uniformes'];
  idFormato ;
  rellenoNombreFormato;
  requerimientosFormato = ['Datos del Usuario que contegan nombre completo,Cedula y telefono de contacto','Ubicacion Georeferenciadas en cordenadas geogaficas decimales, proyectos Magna Sirgas','Acta de Entrega','Contratos de Condiciones Uniformes (como dato)']
  rellenoRequerimiento;
  requeridoFormato;
  seccional;
  banderaFormato1:Boolean = false;
  banderaFormato2:Boolean = false;
  banderaFormato3:Boolean = false;
  banderaFormato4:Boolean = false;
  consultaArchivo1;
  consultaArchivo2;
  consultaArchivo3;
  formato;
  nombreArchivo;
  archivoActual1;
  archivoActual2;
  archivoActual3;
  fotosFromatoOn:Boolean = false;
  rutaimagen;
  numeroMedidor;
  numeroPaneles;
  capacidadInstalacion;
  marcaMedidor;
  tipoSolucion;
  datosMedidor:Boolean = false;
  

  constructor(private subir:SubirArchivosService,
    public alerta:GlobalAlertService,
     private global: GlobalService,
     private modalService: NgbModal,
     private spinner: NgxSpinnerService,
     public ruta: Router, ) { 
       this.seccional = this.global.idSeccional
     }



  ngOnInit() {
    this.spinner.show();
    if( this.global.idSeccional == undefined){
      this.ruta.navigateByUrl('/proyecto');
      this.spinner.hide();
    }else{
      this.consultarAntes();
      this.clientClass = this.global.CLIENTDATALIWA[8]; 
      setTimeout(() => {    
        $(function() {
          this.table = $('#userTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        setTimeout(() => {
          $(function() {
            this.table = $('#userTable2').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
        }, 600);
        setTimeout(() => {
          $(function() {
            this.table = $('#userTable3').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
        }, 600);
       setTimeout(() => {       
         $(function() {
           this.table = $('#userTable4').DataTable({
             'responsive': false,
             'destroy': true,
             'retrieve': true,
             'paging': true,
             'info': false,
             'pagingType': 'numbers',
             'language': {
               'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
             }
           });
         });
       }, 600);
      }, 800)
    
    this.spinner.hide();
   
    }
  }

  prueba(){
    console.log(this.FotosSeccional)
  }
  consultarAntes(){
    this.spinner.show()
    const pdataFotos = {option: 'insertar2', texto: "Select * from db_liwa.Archivos_Caracteristica WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0"}
    setTimeout(() => {
          this.global.consultar(pdataFotos, async (err1, response3) => {
          this.FotosSeccional = await response3;
        });
      },200);
    const pdataMedidor = {option: 'insertar2', texto: "Select * from db_liwa.Caracteristicas_AOM WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0"}
        this.global.consultar(pdataMedidor, (err1, response4) => {
          console.log("estos son los datos del medidor",response4);
          if(response4.length > 0) {
            this.datosMedidor = true;
            this.numeroMedidor = response4[0][2];
            this.numeroPaneles = response4[0][3];
            this.capacidadInstalacion =response4[0][4];
            this.marcaMedidor = response4[0][10];
            this.tipoSolucion = response4[0][9];
          }
    });
      
    const pdata1 = { option: 'insertar2', texto: "SELECT *,IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Ruta_remota FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Nombre_Archivo FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO') FROM db_liwa.Formatos_AOM B WHERE Modulo = 'BBDD' AND IsDelete = 0"};
    console.log('Data_BBDD','id seccionalglobna',this.global.idSeccional, pdata1);
    this.global.consultar(pdata1, (err1, response1) => {
      console.log('esta es la BBDD', response1);
      this.FormatosBBDD = response1;
      this.FormatosBBDD[0].push('https://php.engenius.com.co/Formatos/Excel/1A - 2A BBDD Usuarios - Georeferenciación.xlsx')
      this.FormatosBBDD[1].push('https://php.engenius.com.co/Formatos/Excel/3A - ACTA DE ENTREGApdf.pdf')
      this.FormatosBBDD[2].push('https://php.engenius.com.co/Formatos/Excel/4A - CCU.pdf')
      const pdata2 = { option: 'insertar2', texto: "SELECT *,IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Ruta_remota FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Nombre_Archivo FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO') FROM db_liwa.Formatos_AOM B WHERE Modulo = 'INVENTARIO' AND IsDelete = 0"};
      console.log('Data_INVENTARIO', pdata2);
      this.global.consultar(pdata2, (err2, response2) => {
        console.log(pdata2, response2);
        this.FormatosInventario = response2;
        this.FormatosInventario[0].push('https://php.engenius.com.co/Formatos/Excel/1B - Planos Generales Proyecto.pdf')
        this.FormatosInventario[1].push('https://php.engenius.com.co/Formatos/Excel/3B - Catalogo de Garantias.pdf')
        this.FormatosInventario[2].push('https://php.engenius.com.co/Formatos/Excel/4B - Ficha Tecnica Inversor.pdf')
        this.FormatosInventario[3].push('https://php.engenius.com.co/Formatos/Excel/5B- Estado de los Elementos.xlsx')
        this.FormatosInventario[4].push('https://php.engenius.com.co/Formatos/Excel/3A - ACTA DE ENTREGApdf.pdf')
        this.FormatosInventario[5].push('https://php.engenius.com.co/Formatos/Excel/3A - ACTA DE ENTREGApdf.pdf')

        const pdata3 = { option: 'insertar2', texto: "SELECT *,IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Ruta_remota FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Nombre_Archivo FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO') FROM db_liwa.Formatos_AOM B WHERE Modulo = 'RH' AND IsDelete = 0"};
        this.global.consultar(pdata3, (err2, response3) => {
          this.FormatosRH = response3
          this.FormatosRH[0].push('https://php.engenius.com.co/Formatos/Excel/1C - 4D  - Matriz Conctacto - Social.xlsx')
          this.FormatosRH[1].push('https://php.engenius.com.co/Formatos/Excel/2C - Plan Logistico.pdf')
          this.FormatosRH[2].push('https://php.engenius.com.co/Formatos/Excel/1A - 2A BBDD Usuarios - Georeferenciación.xlsx')
          
          const pdata4 = { option: 'insertar2', texto: "SELECT *,IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), 'SUBIDO', 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Ruta_remota FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO'), IF(Id_Formato IN(SELECT Id_Formato FROM db_liwa.Formatos_Seccional WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0), (SELECT Nombre_Archivo FROM db_liwa.Formatos_Seccional A WHERE Id_Seccional = '"+this.global.idSeccional+"' AND IsDelete = 0 AND A.Id_Formato = B.Id_Formato), 'NO SUBIDO') FROM db_liwa.Formatos_AOM B WHERE Modulo = 'SOCIAL' AND IsDelete = 0"};
          this.global.consultar(pdata4, (err2, response4) => {
            this.FormatosSocial = response4;
            this.FormatosSocial[0].push('https://php.engenius.com.co/Formatos/Excel/1D - PLAN de CAPACITACIÓN.pdf')
            this.FormatosSocial[1].push('https://php.engenius.com.co/Formatos/Excel/2D - Manual Usuario.pdf')
            this.FormatosSocial[2].push('https://php.engenius.com.co/Formatos/Excel/4A - CCU.pdf')
            this.FormatosSocial[3].push('https://php.engenius.com.co/Formatos/Excel/5D - PLAN DE CALIDAD V7.pdf')
          });
        });
      });
    });
   this.spinner.hide()
  }


modal(content){
  this.modalService.open(content, {ariaLabelledBy: 'modal-vinculate'}).result.then((result) => {
    // this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

// modalPopo(content){
//   this.modalService.open(content, {ariaLabelledBy: 'modal-vinculate'}).result.then((result) => {
//     // this.closeResult = `Closed with: ${result}`;
//   }, (reason) => {
//     // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//   });
// }

modal2(content){
  this.modalService.open(content, {ariaLabelledBy: 'modal-vinculate'}).result.then((result) => {
    // this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

referenciaArchivo(ev){
  console.log('hola luisillo',  ev.target)  
this.file = ev.target
}

// Metodo para consultar formatos existentes y activar banderas
consultar(){
    let form = new FormData();
    form.append('seccional',this.global.idSeccional);
    form.append('opcion','consultar');
    form.append('formato','1');
    this.subir.consultarExcel(form).subscribe(
      resp => {
        if(resp.length > 0){

            this.banderaFormato1 = true;
            this.consultaArchivo1 = resp[0][5];
            this.archivoActual1 = resp[0][4];
          }
          else this.banderaFormato1 = false;
        
      },
      error => {
        this.loader = false;
        alert('Error al consultar');
      }
    );
    form = new FormData();
    form.append('seccional',this.global.idSeccional);
    form.append('opcion','consultar');
    form.append('formato','2');
    this.subir.consultarExcel(form).subscribe(
      resp => {
        if(resp.length > 0){
            this.banderaFormato3 = true;
            this.consultaArchivo2 = resp[0][5];
            this.archivoActual2 = resp[0][4];
          }
          else this.banderaFormato3 = false;
      },
      error => {
        this.loader = false;
        alert('Error al consultar');
        
      }
    );
    form = new FormData();
    form.append('seccional',this.global.idSeccional);
    form.append('opcion','consultar');
    form.append('formato','3');
    this.subir.consultarExcel(form).subscribe(
      resp => {
        if(resp.length > 0){
            this.banderaFormato4 = true;
            this.consultaArchivo3 = resp[0][5];
            this.archivoActual3 = resp[0][4];
          }
          else this.banderaFormato4 = false;
      },
      error => {
        this.loader = false;
        alert('Error al consultar');
        
      }
    );
  }
//////////// DELETE VALIDATION ///////////////////////////

deleteVal(formato) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el formato?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(formato);
      } else {
        this.ngOnInit();
      }
    });
}
deleteFoto(idImg) {
  Swal.fire({
    title: '<strong>¿Está seguro que desea eliminar el formato?</strong>',
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonColor: '#093579',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Thumbs up, great!',
    confirmButtonText: 'Si, estoy seguro'
  }).then((result) => {
    if (result.isConfirmed) {
      this.eliminarFoto(idImg);
    } else {
      this.ngOnInit();
    }
  });
}
eliminar(formato){
    let form = new FormData();
    form.append('seccional',this.global.idSeccional);
    form.append('opcion','eliminar');
    form.append('idFormato',formato);
    //form.append('nombreArchivo','Geodatos de Usuario');
    
    this.subir.eliminarExcel(form).subscribe(
      resp => {
        // alert("entro a la resp")
        //   console.log(resp)
        this.alerta.descValid('el Formato se ha eliminado sin problemas')
          this.consultarAntes();
      },
      error => {
        // alert("Entro al error")
        // console.log(error);
        this.alerta.descErr('algo salio mal y no se ha podido borrar el Formato')
        this.consultarAntes();
        
      }
    );
}

modificar(tipoFormato){
  this.spinner.show()
  let img:any = this.file;
  if (img === undefined) this.alerta.descErr('para subir un archivo primero debes seleccionarlo') ,this.spinner.hide() 
  else{
    if(tipoFormato === 'Excel' && img.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      this.loader = true;
      let form = new FormData();
      form.append('file',img.files[0]);
      form.append('seccional',this.seccional);
      form.append('opcion','modificar');
      form.append('idFormato',this.idFormato);
      // console.log(this.secc)
      this.subir.modificarArchivo(form).subscribe(
        resp => {
          if(resp.status){
            this.alerta.descValid("el archivo fue cargado con exito")
            this.consultarAntes();
            this.spinner.hide()
            this.file = undefined

          }else if (resp === null || resp === undefined) {
            this.alerta.descErr('Algo anda mal! vuelva a intentar carga el archivo y valide que sea el tipo de formato requerido')
            this.spinner.hide()
            this.file = undefined
          }
          
        },
        error => {
          this.loader = false;
          alert('Imagen supera el tamaño permitido');
          this.spinner.hide()
          this.file = undefined
        }
      );

    } else if (tipoFormato === 'PDF' && img.files[0].type === 'application/pdf') {
      this.loader = true;
      let form = new FormData();
      form.append('file',img.files[0]);
      form.append('seccional',this.seccional);
      form.append('opcion','modificar');
      form.append('idFormato',this.idFormato);
      // console.log(this.secc)
      this.subir.modificarArchivo(form).subscribe(
        resp => {
          if(resp.status){
            this.alerta.descValid("el archivo fue cargado con exito")
            this.consultarAntes();
            this.spinner.hide()
            this.file = undefined

          }else if (resp === null || resp === undefined) {
            this.alerta.descErr('Algo anda mal! vuelva a intentar carga el archivo y valide que sea el tipo de formato requerido')
            this.spinner.hide()
            this.file = undefined
          }
          
        },
        error => {
          this.loader = false;
          alert('Imagen supera el tamaño permitido');
          this.spinner.hide()
          this.file = undefined
        }
      );
    } else {
      this.alerta.descErr('porfavor ingresa un tipo de archivo valido')
      this.spinner.hide()
    }

  }
}

subiendoandoExcel(tipoFormato){
    this.spinner.show()
    let img:any = this.file;
    // console.log(this.global.idProsec)
    if (img === undefined) this.alerta.descErr('para subir un archivo primero debes seleccionarlo') ,this.spinner.hide() 
    else {
      console.log(img.files[0].type, 'este es el error')
    if(tipoFormato === 'Excel' && img.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.loader = true;
      let form = new FormData();
      form.append('file',img.files[0]);
      form.append('seccional',this.seccional);
      form.append('opcion','insertar');
      form.append('idFormato',this.idFormato);
      // console.log(this.secc)
      this.subir.subirImagen(form).subscribe(
        resp => {
          this.loader = false;
          if(resp.status){
            this.alerta.descValid("el archivo fue cargado con exito")
            this.consultarAntes();
            this.spinner.hide()
            this.file = undefined
          }else if (resp === null || resp === undefined) {
            this.alerta.descErr('Algo anda mal! vuelva a intentar carga el archivo y valide que sea el tipo de formato requerido')
            this.spinner.hide()
            this.file = undefined 
          }
          
        },
        error => {
          this.loader = false;
          alert('Revisa bien el formato que seleccionaste porfavor');
          this.spinner.hide()
          this.file = undefined 
        }
      );

    } else if (tipoFormato === 'PDF' && img.files[0].type === 'application/pdf') {
      this.loader = true;
      let form = new FormData();
      form.append('file',img.files[0]);
      form.append('seccional',this.seccional);
      form.append('opcion','insertar');
      form.append('idFormato',this.idFormato);
      // console.log(this.secc)
      this.subir.subirImagen(form).subscribe(
        resp => {
          this.loader = false;
          if(resp.status){
            this.alerta.descValid("el archivo fue cargado con exito")
            this.consultarAntes();
            this.spinner.hide()
            this.file = undefined
          }else if (resp === null || resp === undefined) {
            this.alerta.descErr('Algo anda mal! vuelva a intentar carga el archivo y valide que sea el tipo de formato requerido')
            this.spinner.hide()
            this.file = undefined 
          }
          
        },
        error => {
          this.loader = false;
          alert('Revisa bien el formato que seleccionaste porfavor');
          this.spinner.hide()
          this.file = undefined 
        }
      );
    } else {
      this.alerta.descErr('porfavor ingresa un tipo de archivo valido')
      this.spinner.hide()
    }
  }
}

subirFoto(){
    this.spinner.show()
    let img:any = this.file;
    // console.log(this.global.idProsec)
    if (img === undefined) this.alerta.descErr('para subir un archivo primero debes seleccionarlo') ,this.spinner.hide() 
    else {
      // console.log(img.files[0], 'este es el error')
    if(img.files.length > 0){
      this.loader = true;
      let form = new FormData();
      form.append('file',img.files[0]);
      form.append('seccional',this.seccional);
      form.append('opcion','insertarFoto');;
      // console.log(this.secc)
      this.subir.subirImagen(form).subscribe(
        resp => {
          this.loader = false;
          if(resp.status){
            this.consultarAntes();
            this.alerta.descValid("el archivo fue cargado con exito")
            this.spinner.hide()
            this.file = undefined
          }else if (resp === null || resp === undefined) {
            this.alerta.descErr('Algo anda mal! vuelva a intentar carga el archivo y valide que sea el tipo de formato requerido')
            this.spinner.hide()
            this.file = undefined 
          }
          
        },
        error => {
          this.loader = false;
          alert('Revisa bien el formato que seleccionaste porfavor');
          this.spinner.hide()
          this.file = undefined 
        }
      );

    }
  }
}

rellenoNombre(nombreFormato) {
  return this.rellenoNombreFormato = nombreFormato
}
rellenoDelRequerimientos(requerimiento) {
  return this.rellenoRequerimiento = requerimiento
}
rellenoDelNombreFormato(nombreFormato) {
  return this.tipoFormato = nombreFormato
}

verArchivo(idFormato){
    window.open(idFormato, "_blank");
}
asignarFormato(idFormato){
  this.idFormato = idFormato
}
asignarNombreArchivo(nombreArchivo){
  
    this.nombreArchivo = nombreArchivo
  
}
eliminarFoto(idImg){
  
  let form = new FormData();
    form.append('seccional',this.global.idSeccional);
    form.append('opcion','eliminarFoto');
    form.append('idArchivo',idImg);
    //form.append('nombreArchivo','Geodatos de Usuario');
    
    this.subir.eliminarExcel(form).subscribe(
      resp => {
        // alert("entro a la resp")
        //   console.log(resp)
        this.alerta.descValid('el Formato se ha eliminado sin problemas')
          this.consultarAntes();
      },
      error => {
        // alert("Entro al error")
        // console.log(error);
        this.alerta.descErr('algo salio mal y no se ha podido borrar el Formato')
        this.consultarAntes();
        
      }
    );
  
}

subirPopover(){
  let form = new FormData();
  form.append('seccional',this.seccional);
  form.append('numeroMedidor',this.numeroMedidor);
  form.append('numeroPaneles',this.numeroPaneles);
  form.append('capacidadInstal',this.capacidadInstalacion);
  form.append('tiposolucion',this.tipoSolucion);
  form.append('marcaMedidor',this.marcaMedidor);
  form.append('opcion','insertarDatosPopover');
  // console.log(this.secc)
  this.subir.subirImagen(form).subscribe(
    resp => {
      if(resp.status){
        this.alerta.descValid("datos subidos con exito")
        this.consultarAntes();
        this.spinner.hide()
      }else if (resp === null || resp === undefined) {
        this.alerta.descErr('Algo anda mal! vuelva a intentar subir los datos')
        this.spinner.hide()

      }
  })

}
editarPopover(){
  let form = new FormData();
  form.append('seccional',this.seccional);
  form.append('numeroMedidor',this.numeroMedidor);
  form.append('numeroPaneles',this.numeroPaneles);
  form.append('capacidadInstal',this.capacidadInstalacion);
  form.append('tiposolucion',this.tipoSolucion);
  form.append('marcaMedidor',this.marcaMedidor);
  form.append('opcion','editarDatosPopover');
  // console.log(this.secc)
  this.subir.subirImagen(form).subscribe(
    resp => {
      if(resp.status){
        this.alerta.descValid("datos editados con exito")
        this.consultarAntes();
        this.spinner.hide()
      }else if (resp === null || resp === undefined) {
        this.alerta.descErr('Algo anda mal! vuelva a intentar subir los datos')
        this.spinner.hide()

      }
  })

}
}