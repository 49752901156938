import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { NgbTooltipConfig, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';
import { DomSanitizer } from '@angular/platform-browser';
import domtoimage from 'dom-to-image';
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-sede-providencia',
  templateUrl: './sede-providencia.component.html',
  styleUrls: ['./sede-providencia.component.scss'],
  providers: [NgbTooltipConfig, NgbPopoverConfig]
})
export class SedeProvidenciaComponent implements OnInit {
  imgInv1g = new Image();
  imgInv1c = new Image();
  imgInv2g = new Image();
  imgInv2c = new Image();
  imgInv3g = new Image();
  imgInv3c = new Image();
  imgInv4g = new Image();
  imgInv4c = new Image();
  imgEnergia = new Image();
  Egen;
  latitude = 13.3549212;
  longitude = -81.367067313;
  zoom = 15;
  Econ1;
  Econ2;
  Econ3;
  clientClass;
  filtro;
  indiceI;
  indiceJ;
  srcImgF;
  showComFlag: boolean = false;
  mostrarMenu;
  floatOn: boolean = false;
  floatOn1: boolean = false;
  floatOn2: boolean = false;
  floatOn3: boolean = false;
  floatOn4: boolean = false;
  FechaI;
  Filtro;
  FechaF;
  /*
this.Inv1[index][3]);//FECHA
this.Inv1[index][5]/100);//V3
this.Inv1[index][6]/100);//V2
this.Inv1[index][7]/100);//V1
this.Inv1[index][8]/10);//I3
this.Inv1[index][9]/10);//I2
this.Inv1[index][10]/10);//I1
this.Inv1[index][11]/100);//VPV
this.Inv1[index][12]/10);//IPV
this.Inv1[index][13]);//PPV
(this.Inv1[index][14]/10);//E_G
(this.Inv1[index][15]/10);//E_C1
(this.Inv1[index][16]/10);//E_C2
(this.Inv1[index][17]/10);//E_C3

this.Inv1[index][3],this.Inv1[index][5]/100,this.Inv1[index][6]/100,this.Inv1[index][7]/100,this.Inv1[index][8]/10,this.Inv1[index][9]/10,this.Inv1[index][10]/10,this.Inv1[index][11]/100,this.Inv1[index][12]/10,this.Inv1[index][13],(this.Inv1[index][14]/10,(this.Inv1[index][15]/10,(this.Inv1[index][16]/10,(this.Inv1[index][17]/10,  */
  Toexcel = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  Toexcel2 = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  Toexcel3 = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  Toexcel4 = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];


  DATA;
  filtros = [[0, 'Resumen del sistema'], [1, 'Consumo'], [2, 'Solar']];
  graficos = [[0, '../../../assets/img/gallery/grapgh1.PNG'],
  [1, '../../../assets/img/gallery/grapgh2.PNG'],
  [2, '../../../assets/img/gallery/grapgh3.PNG'],
  [3, '../../../assets/img/gallery/grapgh4.PNG'],
  [4, '../../../assets/img/gallery/grapgh5.PNG'],
  ];
  gridSolarData = [[0, 'A batería', '2.2 kWh', 'Uso directo', '1.2 kWh', 'Producción', '3.8 kWh', 'Consumo', '3.3 kWh'],
  [1, 'A batería', '3.2 kWh', 'Uso directo', '2.2 kWh', 'Producción', '4.8 kWh', 'Consumo', '4.3 kWh'],
  [2, 'A batería', '5.2 kWh', 'Uso directo', '4.2 kWh', 'Producción', '6.8 kWh', 'Consumo', '6.3 kWh'],
  [3, 'A batería', '6.2 kWh', 'Uso directo', '5.2 kWh', 'Producción', '7.8 kWh', 'Consumo', '7.3 kWh'],
  [4, 'A batería', '70.2 kWh', 'Uso directo', '63.2 kWh', 'Producción', '78.8 kWh', 'Consumo', '79.3 kWh']];
  dataGrid;
  infoSite = [];
  infoFlag = false;

  graphContent = [
    { titulo: 'PANEL SOLAR', imagen: '../../../assets/img/icons/common/svgEnergy/054-panel.svg' },
    { titulo: 'INVERSOR', imagen: '../../../assets/img/icons/common/svgEnergy/055-inversor.svg' },
    { titulo: 'SÁLIDA INSTALACIÓN', imagen: '../../../assets/img/icons/common/svgEnergy/056-salida.svg' },
  ];
  color = 'rgb(102, 102, 102, 0.8)';
  colorg = 'rgb(102, 102, 102, 0.2)';
  Items = new Array();
  PP = 0;
  PB = 0;
  PS = 0;
  Bandera = 0;
  picker;
  tema;
  SINDATOS = 0;
  Datos = new Array();
  Voltaje = new Array();
  Corriente = new Array();
  VoltajeP = new Array();
  CorrienteP = new Array();
  PotenciaP = new Array();
  VoltajeB = new Array();
  CorrienteB = new Array();
  PotenciaB = new Array();
  Fecha = new Array();
  Dsips;
  Interval;
  Estadistico;
  ULTIMO = 'Cargando......';

  Inv1;
  Inv2;
  Inv3;
  Inv4;
  backgoru = new Array();
  Colore = new Array();
  backgoru2 = new Array();
  Colore2 = new Array();
  backgoru3 = new Array();
  Colore3 = new Array();
  backgoru4 = new Array();
  Colore4 = new Array();
  backgoru5 = new Array();
  Colore5 = new Array();
  backgoru6 = new Array();
  Colore6 = new Array();
  backgoru7 = new Array();
  Colore7 = new Array();
  backgoru8 = new Array();
  Colore8 = new Array();
  a = new Array();
  fechaH;
  date1;
  picker1 = new Array();
  GraficaCorriente;
  GraficaPotencia;
  Graficabateria;
  GraficaPanel;
  GraficaVoltaje;
  MAXFECHA = new Date();
  FECHA = new Date();
  Potencia = new Array();
  Time1;
  Time2;
  Time3;
  Time4;
  Style;
  Bandera1: boolean;
  Bandera2: boolean;
  Bandera3: boolean;
  Bandera4: boolean;
  donutFlag: boolean = false;
  source = new Array();
  sma = new Array();


  enterFlag: boolean = false;
  btnDiagramFlag: boolean = false;
  filterContent = [{ nombre: 'Hoy' },
  { nombre: 'Ayer' },
  { nombre: 'Esta semana' },
  { nombre: 'Este mes' },
  { nombre: 'Este año' },
  { nombre: 'Última hora' },
  { nombre: 'Últimas 3 horas' },
  { nombre: 'Últimos 7 días' },
  { nombre: 'Últimos 30 días' },
  { nombre: 'Semana pasada' },
  { nombre: 'Mes pasado' },
  { nombre: 'Año pasado' },
  ];
  diagramActive = true;
  historicActive = false;
  consumeActive = true;
  generationActive = false;
  fromDate;
  toDate;
  showFlag = false;
  textoBoton = 'Seleccione Una Opción';
  arrProvidencia;
  constructor(
    private excelService: ExcelService,
    protected global: GlobalService,
    protected route: Router,
    private spinner: NgxSpinnerService,
    private alerta: GlobalAlertService,
    private configTool: NgbTooltipConfig,
    private configPop: NgbPopoverConfig) {
    this.global.initData();
    configTool.triggers = 'click';
    configPop.triggers = 'click';
    configPop.autoClose = 'outside';
    // Chart.defaults.global.defaultFontFamily = 'varela_roundregular';
  }

  ngOnInit(): void {
    this.spinner.show();
    window.scroll(0, 0);
    this.clientClass = this.global.CLIENTDATALIWA[8];
    setTimeout(() => {
      const pdata1 = { option: 'ULT_Providencia' };
      this.global.consultarSENA(pdata1, (err1, response1) => {
        console.log('Datos ULT_Providencia', response1);
        this.arrProvidencia = response1;
        this.Style = this.global.mapStyle;
        this.enterFlag = true;
        this.spinner.hide();
      });
    }, 1000);

  }
  showComp(numero: number) {
    for (let i = 0; i < this.graficos.length; i++) {
      if (numero == this.graficos[i][0]) {
        this.srcImgF = this.graficos[i][1];
        this.indiceI = i;
      }
    }
    for (let idx = 0; idx < this.gridSolarData.length; idx++) {
      if (this.gridSolarData[idx] !== undefined) {
        if (numero == this.gridSolarData[idx][0]) {
          this.dataGrid = this.gridSolarData[idx];
          this.indiceJ = idx;
        }
      }

      /*
      } */

    }
  }

  // PDFfotos() {
  //   this.imgInv1g = new Image();
  //   this.imgInv1c = new Image();
  //   this.imgInv2g = new Image();
  //   this.imgInv2c = new Image();
  //   this.imgInv3g = new Image();
  //   this.imgInv3c = new Image();
  //   this.imgInv4g = new Image();
  //   this.imgInv4c = new Image();
  //   this.spinner.show();
  //   try {
  //     const grafica1g = document.getElementById('inv1g');
  //     domtoimage.toPng(grafica1g).then((dataUrl1) => {
  //       this.imgInv1g.src = dataUrl1;
  //       const grafica1c = document.getElementById('inv1c');
  //       domtoimage.toPng(grafica1c).then((dataUrl1c) => {
  //         this.imgInv1c.src = dataUrl1c;
  //         const grafica2g = document.getElementById('inv2g');
  //         domtoimage.toPng(grafica2g).then((dataUrl2g) => {
  //           this.imgInv2g.src = dataUrl2g;
  //           const grafica2c = document.getElementById('inv2c');
  //           domtoimage.toPng(grafica2c).then((dataUrl2c) => {
  //             this.imgInv2c.src = dataUrl2c;
  //             const grafica3g = document.getElementById('inv3g');
  //             domtoimage.toPng(grafica3g).then((dataUrl3g) => {
  //               this.imgInv3g.src = dataUrl3g;
  //               const grafica3c = document.getElementById('inv3c');
  //               domtoimage.toPng(grafica3c).then((dataUrl3c) => {
  //                 this.imgInv3c.src = dataUrl3c;
  //                 const grafica4g = document.getElementById('inv4g');
  //                 domtoimage.toPng(grafica4g).then((dataUrl4g) => {
  //                   this.imgInv4g.src = dataUrl4g;
  //                   const grafica4c = document.getElementById('inv4c');
  //                   domtoimage.toPng(grafica4c).then((dataUrl4c) => {
  //                     this.imgInv4c.src = dataUrl4c;
  //                     setTimeout(() => {
  //                       const imgData = new Image();
  //                       imgData.crossOrigin = '';
  //                       imgData.src = 'https://fotos.engenius.com.co' + this.global.CLIENTDATALIWA[13];
  //                       const doc = new jsPDF();
  //                       doc.setFontSize(14);
  //                       const Title = ' Reporte Graficas Variables Electricas';
  //                       doc.setTextColor(255, 107, 0);
  //                       doc.text(Title, 105, 20, 'center');
  //                       const SubTitle = 'Sede: ' + this.infoSite[1];
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.setFontSize(12);
  //                       doc.text(SubTitle, 105, 30, 'center');
  //                       doc.setFontSize(12);
  //                       doc.autoTable({
  //                         body: [
  //                           ['Ciudad: ' + this.infoSite[2], 'Filtro de Fechas: ' + this.Filtro],
  //                           ['Fecha Inicial: ' + this.FechaI, 'Fecha Final: ' + this.FechaF],
  //                         ], styles: { fontSize: 12 }
  //                         , startY: 40
  //                         , theme: 'grid'
  //                       });
  //                       doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
  //                       doc.addImage(this.imgInv1g, 'PNG', 20, 70, 165, 88);
  //                       doc.addImage(this.imgInv1c, 'PNG', 20, 178, 165, 88);
  //                       doc.setTextColor(86, 86, 86);
  //                       doc.setFontSize(10);
  //                       doc.text('Consumo Inversor #1', 105, 170, 'center');
  //                       doc.text('Generación Inversor #1', 105, 68, 'center');
  //                       doc.setFontSize(12);
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.addPage();
  //                       doc.setFontSize(14);
  //                       doc.setTextColor(255, 107, 0);
  //                       doc.text(Title, 105, 20, 'center');
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.setFontSize(12);
  //                       doc.text(SubTitle, 105, 30, 'center');
  //                       doc.setFontSize(12);
  //                       doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
  //                       doc.addImage(this.imgInv2g, 'PNG', 20, 50, 165, 88);
  //                       doc.addImage(this.imgInv2c, 'PNG', 20, 158, 165, 88);
  //                       doc.setTextColor(86, 86, 86);
  //                       doc.setFontSize(10);
  //                       doc.text('Consumo Inversor #2', 105, 150, 'center');
  //                       doc.text('Generación Inversor #2', 105, 45, 'center');
  //                       doc.setFontSize(12);
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.addPage();
  //                       doc.setFontSize(14);
  //                       doc.setTextColor(255, 107, 0);
  //                       doc.text(Title, 105, 20, 'center');
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.setFontSize(12);
  //                       doc.text(SubTitle, 105, 30, 'center');
  //                       doc.setFontSize(12);
  //                       doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
  //                       doc.addImage(this.imgInv3g, 'PNG', 20, 50, 165, 88);
  //                       doc.addImage(this.imgInv3c, 'PNG', 20, 158, 165, 88);
  //                       doc.setTextColor(86, 86, 86);
  //                       doc.setFontSize(10);
  //                       doc.text('Consumo Inversor #3', 105, 150, 'center');
  //                       doc.text('Generación Inversor #3', 105, 45, 'center');
  //                       doc.setFontSize(12);
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.addPage();
  //                       doc.setFontSize(14);
  //                       doc.setTextColor(255, 107, 0);
  //                       doc.text(Title, 105, 20, 'center');
  //                       doc.setTextColor(0, 0, 0);
  //                       doc.setFontSize(12);
  //                       doc.text(SubTitle, 105, 30, 'center');
  //                       doc.setFontSize(12);
  //                       doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
  //                       doc.addImage(this.imgInv4g, 'PNG', 20, 50, 165, 88);
  //                       doc.addImage(this.imgInv4c, 'PNG', 20, 158, 165, 88);
  //                       doc.setTextColor(86, 86, 86);
  //                       doc.setFontSize(10);
  //                       doc.text('Consumo Inversor #4', 105, 150, 'center');
  //                       doc.text('Generación Inversor #4', 105, 45, 'center');
  //                       doc.setFontSize(12);
  //                       doc.setTextColor(0, 0, 0);
  //                       const pageCount = doc.internal.getNumberOfPages();
  //                       const arrr = new Array(pageCount);
  //                       for (let j = 0; j < pageCount; j++) {
  //                         arrr[j] = 'Hola';
  //                       }
  //                       setTimeout(() => {
  //                         arrr.forEach((element, o) => {
  //                           doc.setPage(o + 1);
  //                           doc.setFontSize(8);
  //                           const dia = moment();
  //                           dia.locale('es');
  //                           doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
  //                           doc.setFontSize(8);
  //                           doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
  //                         });
  //                       }, 100);
  //                       setTimeout(() => {
  //                         doc.save('INFORME_IPSE' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
  //                         setTimeout(() => {
  //                           this.spinner.hide();
  //                         }, 3500);
  //                       }, 500);
  //                     }, 500);
  //                   });
  //                 });
  //               });
  //             });
  //           });
  //         });
  //       });
  //     });
  //   } catch (err) {
  //     this.spinner.hide();
  //   }
  // }

  // PDFenergia() {
  //   this.spinner.show();
  //   try {
  //     const grafica4c = document.getElementById('energy');
  //     domtoimage.toPng(grafica4c).then((dataUrl4c) => {
  //       this.imgEnergia.src = dataUrl4c;
  //       setTimeout(() => {
  //         const imgData = new Image();
  //         imgData.crossOrigin = '';
  //         imgData.src = 'https://fotos.engenius.com.co' + this.global.CLIENTDATALIWA[13];
  //         const doc = new jsPDF();
  //         doc.setFontSize(14);
  //         const Title = ' Reporte Grafico de Energias';
  //         doc.setTextColor(255, 107, 0);
  //         doc.text(Title, 105, 20, 'center');
  //         const SubTitle = 'Sede: ' + this.infoSite[1];
  //         doc.setTextColor(0, 0, 0);
  //         doc.setFontSize(12);
  //         doc.text(SubTitle, 105, 30, 'center');
  //         doc.setFontSize(12);
  //         doc.autoTable({
  //           body: [
  //             ['Ciudad: ' + this.infoSite[2], 'Filtro de Fechas: ' + this.Filtro],
  //             ['Fecha Inicial: ' + this.FechaI, 'Fecha Final: ' + this.FechaF],
  //           ], styles: { fontSize: 12 }
  //           , startY: 40
  //           , theme: 'grid'
  //         });
  //         doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
  //         doc.addImage(this.imgEnergia, 'PNG', 0, 70, 204, 103);
  //         doc.setTextColor(86, 86, 86);
  //         doc.setFontSize(12);
  //         doc.text('Cuadro de Datos', 105, 185, 'center');
  //         doc.setTextColor(0, 0, 0);
  //         doc.autoTable({
  //           body: [
  //             ['Energía generada (Wh): ' + this.Egen, 'Energía Consumida Linea (Wh): ' + this.Econ1],
  //           ], styles: { fontSize: 12 }
  //           , startY: 193
  //           , theme: 'grid'
  //         });

  //         const pageCount = doc.internal.getNumberOfPages();
  //         const arrr = new Array(pageCount);
  //         for (let j = 0; j < pageCount; j++) {
  //           arrr[j] = 'Hola';
  //         }
  //         setTimeout(() => {
  //           arrr.forEach((element, o) => {
  //             doc.setPage(o + 1);
  //             doc.setFontSize(8);
  //             const dia = moment();
  //             dia.locale('es');
  //             doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
  //             doc.setFontSize(8);
  //             doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
  //           });
  //         }, 100);
  //         setTimeout(() => {
  //           doc.save('INFORME_ENERGIAS_IPSE' + '_' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
  //           setTimeout(() => {
  //             this.spinner.hide();
  //           }, 3500);
  //         }, 500);
  //       }, 500);
  //     });

  //   } catch (err) {
  //     this.spinner.hide();
  //   }
  // }

  // exportAsXLSX() {
  //   this.spinner.show();
  //   console.log('Excel', this.Inv1);
  //   console.log('Excel2', this.Inv2);
  //   console.log('Excel3', this.Inv3);
  //   console.log('Excel4', this.Inv4);
  //   this.Toexcel = [['IPSE PROVIDENCIA', 'Rango de Fechas Seleccionado:', this.Filtro, 'Fecha Inicial:', this.FechaI, 'Fecha Final:', this.FechaF], [], ['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //   this.Toexcel2 = [['IPSE PROVIDENCIA', 'Rango de Fechas Seleccionado:', this.Filtro, 'Fecha Inicial:', this.FechaI, 'Fecha Final:', this.FechaF], [], ['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //   this.Toexcel3 = [['IPSE PROVIDENCIA', 'Rango de Fechas Seleccionado:', this.Filtro, 'Fecha Inicial:', this.FechaI, 'Fecha Final:', this.FechaF], [], ['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //   this.Toexcel4 = [['IPSE PROVIDENCIA', 'Rango de Fechas Seleccionado:', this.Filtro, 'Fecha Inicial:', this.FechaI, 'Fecha Final:', this.FechaF], [], ['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];

  //   if (this.Inv1.length == 0) {
  //     this.Toexcel.push(['NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS']);
  //   } else {
  //     for (let index = 0; index < this.Inv1.length; index++) {
  //       this.Toexcel.push([this.Inv1[index][3], this.Inv1[index][5] / 100, this.Inv1[index][6] / 100, this.Inv1[index][7] / 100, this.Inv1[index][8] / 10, this.Inv1[index][9] / 10, this.Inv1[index][10] / 10, this.Inv1[index][11] / 100, this.Inv1[index][12] / 10, this.Inv1[index][13], this.Inv1[index][14] / 10, this.Inv1[index][15] / 10, this.Inv1[index][16] / 10, this.Inv1[index][17] / 10]);
  //     }
  //   }
  //   if (this.Inv2.length == 0) {
  //     this.Toexcel2.push(['NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS']);
  //   } else {
  //     for (let index2 = 0; index2 < this.Inv2.length; index2++) {
  //       this.Toexcel2.push([this.Inv2[index2][3], this.Inv2[index2][5] / 100, this.Inv2[index2][6] / 100, this.Inv2[index2][7] / 100, this.Inv2[index2][8] / 10, this.Inv2[index2][9] / 10, this.Inv2[index2][10] / 10, this.Inv2[index2][11] / 100, this.Inv2[index2][12] / 10, this.Inv2[index2][13], this.Inv2[index2][14] / 10, this.Inv2[index2][15] / 10, this.Inv2[index2][16] / 10, this.Inv2[index2][17] / 10]);
  //     }
  //   }
  //   if (this.Inv3.length == 0) {
  //     this.Toexcel3.push(['NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS']);
  //   } else {
  //     for (let index3 = 0; index3 < this.Inv3.length; index3++) {
  //       this.Toexcel3.push([this.Inv3[index3][3], this.Inv3[index3][5] / 100, this.Inv3[index3][6] / 100, this.Inv3[index3][7] / 100, this.Inv3[index3][8] / 10, this.Inv3[index3][9] / 10, this.Inv3[index3][10] / 10, this.Inv3[index3][11] / 100, this.Inv3[index3][12] / 10, this.Inv3[index3][13], this.Inv3[index3][14] / 10, this.Inv3[index3][15] / 10, this.Inv3[index3][16] / 10, this.Inv3[index3][17] / 10]);
  //     }
  //   }
  //   if (this.Inv4.length == 0) {
  //     this.Toexcel4.push(['NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS']);
  //   } else {
  //     for (let index4 = 0; index4 < this.Inv4.length; index4++) {
  //       this.Toexcel4.push([this.Inv4[index4][3], this.Inv4[index4][5] / 100, this.Inv4[index4][6] / 100, this.Inv4[index4][7] / 100, this.Inv4[index4][8] / 10, this.Inv4[index4][9] / 10, this.Inv4[index4][10] / 10, this.Inv4[index4][11] / 100, this.Inv4[index4][12] / 10, this.Inv4[index4][13], this.Inv4[index4][14] / 10, this.Inv4[index4][15] / 10, this.Inv4[index4][16] / 10, this.Inv4[index4][17] / 10]);
  //     }
  //   }

  //   setTimeout(() => {
  //     this.excelService.exportAsExcelFile4(this.Toexcel, this.Toexcel2, this.Toexcel3, this.Toexcel4, 'Inversor 1', 'Inversor 2', 'Inversor 3', 'Inversor 4', 'Variables_Electricas_Centro_para_la_Industria_Petroquimica');
  //     setTimeout(() => {
  //       this.spinner.hide();
  //       this.Toexcel = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //       this.Toexcel2 = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //       this.Toexcel3 = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //       this.Toexcel4 = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
  //     }, 1000);
  //   }, 1500);
  // }

  // validarSede() {
  //   for (let i = 0; i < this.global.sedes.length; i++) {
  //     if (this.global.sedes[i][0] == this.global.selectedSede) {
  //       this.infoSite = this.global.sedes[i];
  //       console.log(this.infoSite)
  //       this.infoFlag = true;
  //     } else {
  //       continue;
  //     }
  //   }
  // }


  // ColorLabels() {
  //   this.color = 'rgb(102, 102, 102, 0.8)';
  //   this.colorg = 'rgb(102, 102, 102, 0.2)';
  // }


  // //main tabs //
  // isDiagram() {
  //   if (this.historicActive == false) {
  //     this.diagramActive = !this.diagramActive;
  //     this.showFlag = false;
  //   } else {
  //     this.diagramActive = true;
  //     this.historicActive = false;
  //     this.showFlag = false;
  //   }
  // }
  // isHistoric() {

  //   if (this.diagramActive == false) {
  //     this.historicActive = !this.historicActive;

  //   } else {

  //     this.diagramActive = false;
  //     this.historicActive = true;

  //   }
  // }
  // //end main tabs
  // //secondary tabs //
  // isGeneration() {
  //   if (this.consumeActive == false) {
  //     this.generationActive = !this.generationActive;

  //   } else {
  //     this.generationActive = true;
  //     this.consumeActive = false;
  //     setTimeout(() => {
  //       this.Energia();
  //     }, 500);
  //   }
  // }
  // isConsume() {
  //   if (this.generationActive == false) {
  //     this.consumeActive = !this.consumeActive;

  //   } else {

  //     this.generationActive = false;
  //     this.consumeActive = true;
  //     this.HabilitarGraficas();
  //   }
  // }
  // //end secondary tabs
  // switchFilter(data: string) {
  //   this.Filtro = data;
  //   switch (data) {
  //     case 'Hoy':
  //       console.log('Hoy');
  //       this.showFlag = true;
  //       let fechaactual = new Date();
  //       console.log('fechaactual', fechaactual);
  //       this.FechaI = moment(fechaactual).format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual).format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Ayer':
  //       console.log('Ayer');
  //       this.showFlag = true;
  //       let fechaactual1 = new Date();
  //       console.log('fechaactual', fechaactual1);
  //       let opfecha = moment(fechaactual1).subtract(1, 'days');
  //       this.FechaI = moment(opfecha).format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(opfecha).format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Esta semana':
  //       console.log('Esta semana');
  //       this.showFlag = true;
  //       let fechaactual2 = new Date();
  //       console.log('fechaactual', fechaactual2);
  //       this.FechaI = moment(fechaactual2).startOf("isoWeek").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual2).endOf("isoWeek").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Este mes':
  //       console.log('Esta mes');
  //       this.showFlag = true;
  //       let fechaactual3 = new Date();
  //       console.log('fechaactual', fechaactual3);
  //       this.FechaI = moment(fechaactual3).startOf("month").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual3).endOf("month").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Este año':
  //       console.log('Este año');
  //       this.showFlag = true;
  //       let fechaactual4 = new Date();
  //       console.log('fechaactual', fechaactual4);
  //       this.FechaI = moment(fechaactual4).startOf("year").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual4).endOf("year").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Última hora':
  //       console.log('Última hora');
  //       this.showFlag = true;
  //       let fechaactual5 = new Date();
  //       console.log('fechaactual', fechaactual5);
  //       this.FechaI = moment(fechaactual5).subtract(1, "hour").format('YYYY-MM-DD HH:mm');
  //       this.FechaF = moment(fechaactual5).format('YYYY-MM-DD HH:mm');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Últimas 3 horas':
  //       console.log('Últimas 3 horas');
  //       this.showFlag = true;
  //       let fechaactual6 = new Date();
  //       console.log('fechaactual', fechaactual6);
  //       this.FechaI = moment(fechaactual6).subtract(3, "hour").format('YYYY-MM-DD HH:mm');
  //       this.FechaF = moment(fechaactual6).format('YYYY-MM-DD HH:mm');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Últimos 7 días':
  //       console.log('Últimos 7 días');
  //       this.showFlag = true;
  //       let fechaactual7 = new Date();
  //       console.log('fechaactual', fechaactual7);
  //       this.FechaI = moment(fechaactual7).subtract(7, "day").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual7).subtract(1, "day").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Últimos 30 días':
  //       console.log('Últimos 30 días');
  //       this.showFlag = true;
  //       let fechaactual8 = new Date();
  //       console.log('fechaactual', fechaactual8);
  //       this.FechaI = moment(fechaactual8).subtract(30, "day").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual8).subtract(1, "day").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Semana pasada':
  //       console.log('Semana pasada');
  //       this.showFlag = true;
  //       let fechaactua = new Date();
  //       console.log('fechaactual', fechaactua);
  //       this.FechaI = moment(fechaactua).subtract(1, "week").startOf("isoWeek").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactua).subtract(1, "week").endOf("isoWeek").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Mes pasado':
  //       console.log('Mes pasado');
  //       this.showFlag = true;
  //       let fechaactual31 = new Date();
  //       console.log('fechaactual', fechaactual31);
  //       this.FechaI = moment(fechaactual31).subtract(1, "month").startOf("month").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual31).subtract(1, "month").endOf("month").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;
  //     case 'Año pasado':
  //       console.log('Año pasado');
  //       this.showFlag = true;
  //       let fechaactual41 = new Date();
  //       console.log('fechaactual', fechaactual41);
  //       this.FechaI = moment(fechaactual41).subtract(1, "year").startOf("year").format('YYYY-MM-DD 00:00');
  //       this.FechaF = moment(fechaactual41).subtract(1, "year").endOf("year").format('YYYY-MM-DD 23:59');
  //       console.log('fechainicial', this.FechaI);
  //       console.log('fechafinal', this.FechaF);
  //       this.ConsultarInformacion();
  //       break;

  //     default:

  //       this.showFlag = false;
  //       break;
  //   }
  // }

  // ConsultarInformacion() {
  //   this.spinner.show();
  //   this.Inv1 = new Array();
  //   this.Inv2 = new Array();
  //   this.Inv3 = new Array();
  //   this.Inv4 = new Array();
  //   this.Bandera1 = true;
  //   this.Bandera2 = true;
  //   this.Bandera3 = true;
  //   this.Bandera4 = true;
  //   const pdata9 = { option: 'Data_Providencia', fechai: this.FechaI, fechaf: this.FechaF };
  //   console.log('Consultando informacion', pdata9);
  //   this.global.consultarSENA(pdata9, (err9, response9) => {
  //     console.log('Información Cartagena', response9);
  //     let data = response9;
  //     for (let index = 0; index < data.length; index++) {
  //       if (data[index][4] == '66f3334505043135321') {
  //         this.Inv1.push(data[index])
  //       }
  //       if (data[index][4] == '66c3930574157090827') {
  //         this.Inv2.push(data[index])
  //       }
  //       if (data[index][4] == '66c3334505043135627') {
  //         this.Inv3.push(data[index])
  //       }
  //       if (data[index][4] == '66d3234505043104439') {
  //         this.Inv4.push(data[index])
  //       }
  //     }
  //     setTimeout(() => {
  //       console.log('Result', this.Inv1, this.Inv2, this.Inv3, this.Inv4);
  //       this.PrepararInformación();
  //     }, 500);


  //   });
  // }

  // Inicializar(cb) {
  //   this.Time1 = new Array(14);
  //   for (let index = 0; index < this.Time1.length; index++) {
  //     this.Time1[index] = new Array();
  //   }
  //   this.Time2 = new Array(14);
  //   for (let index2 = 0; index2 < this.Time2.length; index2++) {
  //     this.Time2[index2] = new Array();
  //   }
  //   this.Time3 = new Array(14);
  //   for (let index3 = 0; index3 < this.Time3.length; index3++) {
  //     this.Time3[index3] = new Array();
  //   }
  //   this.Time4 = new Array(14);
  //   for (let index4 = 0; index4 < this.Time4.length; index4++) {
  //     this.Time4[index4] = new Array();
  //   }
  //   setTimeout(() => {
  //     cb(true);
  //   }, 500);
  // }

  // PrepararInformación() {
  //   this.Inicializar((respuesta) => {
  //     //Inversor1
  //     for (let index = 0; index < this.Inv1.length; index++) {
  //       this.Time1[0].push(this.Inv1[index][3]);//FECHA
  //       this.Time1[1].push(this.Inv1[index][5] / 100);//V3
  //       this.Time1[2].push(this.Inv1[index][6] / 100);//V2
  //       this.Time1[3].push(this.Inv1[index][7] / 100);//V1
  //       this.Time1[4].push(this.Inv1[index][8] / 10);//I3
  //       this.Time1[5].push(this.Inv1[index][9] / 10);//I2
  //       this.Time1[6].push(this.Inv1[index][10] / 10);//I1
  //       this.Time1[7].push(this.Inv1[index][11] / 100);//VPV
  //       this.Time1[8].push(this.Inv1[index][12] / 10);//IPV
  //       this.Time1[9].push(this.Inv1[index][13]);//PPV
  //       this.Time1[10].push(this.Inv1[index][14]);//E_G
  //       this.Time1[11].push(this.Inv1[index][15]);//E_C1
  //       this.Time1[12].push(this.Inv1[index][16]);//E_C2
  //       this.Time1[13].push(this.Inv1[index][17]);//E_C3
  //     }
  //     //Inversor2
  //     for (let index2 = 0; index2 < this.Inv2.length; index2++) {
  //       this.Time2[0].push(this.Inv2[index2][3]);//FECHA
  //       this.Time2[1].push(this.Inv2[index2][5] / 100);//V3
  //       this.Time2[2].push(this.Inv2[index2][6] / 100);//V2
  //       this.Time2[3].push(this.Inv2[index2][7] / 100);//V1
  //       this.Time2[4].push(this.Inv2[index2][8] / 10);//I3
  //       this.Time2[5].push(this.Inv2[index2][9] / 10);//I2
  //       this.Time2[6].push(this.Inv2[index2][10] / 10);//I1
  //       this.Time2[7].push(this.Inv2[index2][11] / 100);//VPV
  //       this.Time2[8].push(this.Inv2[index2][12] / 10);//IPV
  //       this.Time2[9].push(this.Inv2[index2][13]);//PPV
  //       this.Time2[10].push(this.Inv2[index2][14]);//E_G
  //       this.Time2[11].push(this.Inv2[index2][15]);//E_C1
  //       this.Time2[12].push(this.Inv2[index2][16]);//E_C2
  //       this.Time2[13].push(this.Inv2[index2][17]);//E_C3
  //     }
  //     //Inversor3
  //     for (let index3 = 0; index3 < this.Inv3.length; index3++) {
  //       this.Time3[0].push(this.Inv3[index3][3]);//FECHA
  //       this.Time3[1].push(this.Inv3[index3][5] / 100);//V3
  //       this.Time3[2].push(this.Inv3[index3][6] / 100);//V2
  //       this.Time3[3].push(this.Inv3[index3][7] / 100);//V1
  //       this.Time3[4].push(this.Inv3[index3][8] / 10);//I3
  //       this.Time3[5].push(this.Inv3[index3][9] / 10);//I2
  //       this.Time3[6].push(this.Inv3[index3][10] / 10);//I1
  //       this.Time3[7].push(this.Inv3[index3][11] / 100);//VPV
  //       this.Time3[8].push(this.Inv3[index3][12] / 10);//IPV
  //       this.Time3[9].push(this.Inv3[index3][13]);//PPV
  //       this.Time3[10].push(this.Inv3[index3][14]);//E_G
  //       this.Time3[11].push(this.Inv3[index3][15]);//E_C1
  //       this.Time3[12].push(this.Inv3[index3][16]);//E_C2
  //       this.Time3[13].push(this.Inv3[index3][17]);//E_C3
  //     }
  //     //Inversor4
  //     for (let index4 = 0; index4 < this.Inv4.length; index4++) {
  //       this.Time4[0].push(this.Inv4[index4][3]);//FECHA
  //       this.Time4[1].push(this.Inv4[index4][5] / 100);//V3
  //       this.Time4[2].push(this.Inv4[index4][6] / 100);//V2
  //       this.Time4[3].push(this.Inv4[index4][7] / 100);//V1
  //       this.Time4[4].push(this.Inv4[index4][8] / 10);//I3
  //       this.Time4[5].push(this.Inv4[index4][9] / 10);//I2
  //       this.Time4[6].push(this.Inv4[index4][10] / 10);//I1
  //       this.Time4[7].push(this.Inv4[index4][11] / 100);//VPV
  //       this.Time4[8].push(this.Inv4[index4][12] / 10);//IPV
  //       this.Time4[9].push(this.Inv4[index4][13]);//PPV
  //       this.Time4[10].push(this.Inv4[index4][14]);//E_G
  //       this.Time4[11].push(this.Inv4[index4][15]);//E_C1
  //       this.Time4[12].push(this.Inv4[index4][16]);//E_C2
  //       this.Time4[13].push(this.Inv4[index4][17]);//E_C3
  //     }
  //     setTimeout(() => {
  //       console.log('Datos a Graficar', this.Time1, this.Time2, this.Time3, this.Time4);
  //       this.HabilitarGraficas();
  //     }, 500);
  //   });
  // }

  // HabilitarGraficas() {
  //   if (this.Inv1.length == 0) {
  //     this.Bandera1 = true;
  //   } else {
  //     this.Bandera1 = false;
  //     setTimeout(() => {
  //       this.Inversor1a();
  //       this.Inversor1b();
  //     }, 200);
  //   }
  //   if (this.Inv2.length == 0) {
  //     this.Bandera2 = true;
  //   } else {
  //     this.Bandera2 = false;
  //     setTimeout(() => {
  //       this.Inversor2a();
  //       this.Inversor2b();
  //     }, 400);
  //   }
  //   if (this.Inv3.length == 0) {
  //     this.Bandera3 = true;
  //   } else {
  //     this.Bandera3 = false;
  //     setTimeout(() => {
  //       this.Inversor3a();
  //       this.Inversor3b();
  //     }, 600);
  //   }
  //   if (this.Inv4.length == 0) {
  //     this.Bandera4 = true;
  //   } else {
  //     this.Bandera4 = false;
  //     setTimeout(() => {
  //       this.Inversor4a();
  //       this.Inversor4b();
  //     }, 800);
  //   }
  //   this.spinner.hide();
  // }

  // Inversor1a() {
  //   const canvas = document.getElementById('inv1g') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time1[0],
  //       datasets: [{
  //         label: 'Voltaje Panel',
  //         data: this.Time1[7],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0.3)',
  //         borderColor: 'rgb(163, 199, 255, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Corriente Panel',
  //         data: this.Time1[8],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.3)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.5,
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0, // animation duration after a resize
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 10
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor1b() {
  //   const canvas = document.getElementById('inv1c') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: this.Time1[0],
  //       datasets: [{
  //         label: 'Voltaje Linea',
  //         data: this.Time1[3],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6,
  //         type: 'line',
  //       },
  //       {
  //         label: 'Corriente Linea',
  //         data: this.Time1[6],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.5)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 1
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor2a() {
  //   const canvas = document.getElementById('inv2g') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time2[0],
  //       datasets: [{
  //         label: 'Voltaje Panel',
  //         data: this.Time2[7],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0.3)',
  //         borderColor: 'rgb(163, 199, 255, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Corriente Panel',
  //         data: this.Time2[8],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.3)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.5,
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 10
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor2b() {
  //   const canvas = document.getElementById('inv2c') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: this.Time2[0],
  //       datasets: [{
  //         label: 'Voltaje Linea',
  //         data: this.Time2[3],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6,
  //         type: 'line',
  //       },
  //       {
  //         label: 'Corriente Linea',
  //         data: this.Time2[6],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.5)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 1
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor3a() {
  //   const canvas = document.getElementById('inv3g') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time3[0],
  //       datasets: [{
  //         label: 'Voltaje Panel',
  //         data: this.Time3[7],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0.3)',
  //         borderColor: 'rgb(163, 199, 255, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Corriente Panel',
  //         data: this.Time3[8],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.3)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.5,
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 10
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor3b() {
  //   const canvas = document.getElementById('inv3c') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: this.Time3[0],
  //       datasets: [{
  //         label: 'Voltaje Linea',
  //         data: this.Time3[3],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6,
  //         type: 'line',
  //       },
  //       {
  //         label: 'Corriente Linea',
  //         data: this.Time3[6],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.5)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 1
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor4a() {
  //   const canvas = document.getElementById('inv4g') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time4[0],
  //       datasets: [{
  //         label: 'Voltaje Panel',
  //         data: this.Time4[7],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0.3)',
  //         borderColor: 'rgb(163, 199, 255, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Corriente Panel',
  //         data: this.Time4[8],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.3)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.5,
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 10
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor4b() {
  //   const canvas = document.getElementById('inv4c') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: this.Time4[0],
  //       datasets: [{
  //         label: 'Voltaje Linea',
  //         data: this.Time4[3],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6,
  //         type: 'line',
  //       },
  //       {
  //         label: 'Corriente Linea',
  //         data: this.Time4[6],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(139, 201, 100, 0.5)',
  //         borderColor: 'rgb(139, 201, 100, 1)',
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 1
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }


  // Energia() {
  //   let energiagenerada = 0;
  //   let e1 = this.Time1[10][this.Time1[10].length - 1];
  //   let e2 = this.Time2[10][this.Time2[10].length - 1];
  //   let e3 = this.Time3[10][this.Time3[10].length - 1];
  //   let e4 = this.Time4[10][this.Time4[10].length - 1];
  //   let e1a = this.Time1[10][0];
  //   let e2a = this.Time2[10][0];
  //   let e3a = this.Time3[10][0];
  //   let e4a = this.Time4[10][0];
  //   if (e1 == undefined) {
  //     e1 = 0;
  //   }
  //   if (e2 == undefined) {
  //     e2 = 0;
  //   }
  //   if (e3 == undefined) {
  //     e3 = 0;
  //   }
  //   if (e4 == undefined) {
  //     e4 = 0;
  //   }
  //   if (e1a == undefined) {
  //     e1a = 0;
  //   }
  //   if (e2a == undefined) {
  //     e2a = 0;
  //   }
  //   if (e3a == undefined) {
  //     e3a = 0;
  //   }
  //   if (e4a == undefined) {
  //     e4a = 0;
  //   }
  //   energiagenerada = ((e1 - e1a) + (e2 - e2a) + (e3 - e3a) + (e4 - e4a)) / 10;
  //   console.log('Energia Generada', e1, e2, e3, e4, e1a, e2a, e3a, e4a);

  //   // EC1

  //   let energiaconsumida1 = 0;
  //   let ec1 = this.Time1[11][this.Time1[11].length - 1];
  //   let ec2 = this.Time2[11][this.Time2[11].length - 1];
  //   let ec3 = this.Time3[11][this.Time3[11].length - 1];
  //   let ec4 = this.Time4[11][this.Time4[11].length - 1];
  //   let ec1a = this.Time1[11][0];
  //   let ec2a = this.Time2[11][0];
  //   let ec3a = this.Time3[11][0];
  //   let ec4a = this.Time4[11][0];
  //   if (ec1 == undefined) {
  //     ec1 = 0;
  //   }
  //   if (ec2 == undefined) {
  //     ec2 = 0;
  //   }
  //   if (ec3 == undefined) {
  //     ec3 = 0;
  //   }
  //   if (ec4 == undefined) {
  //     ec4 = 0;
  //   }
  //   if (ec1a == undefined) {
  //     ec1a = 0;
  //   }
  //   if (ec2a == undefined) {
  //     ec2a = 0;
  //   }
  //   if (ec3a == undefined) {
  //     ec3a = 0;
  //   }
  //   if (ec4a == undefined) {
  //     ec4a = 0;
  //   }
  //   energiaconsumida1 = ((ec1 - ec1a) + (ec2 - ec2a) + (ec3 - ec3a) + (ec4 - ec4a)) / 10;
  //   console.log('Energia Consumida', ec1, ec2, ec3, ec4, ec1a, ec2a, ec3a, ec4a);


  //   //EC2

  //   this.Egen = energiagenerada;
  //   this.Econ1 = energiaconsumida1;
  //   const canvas = document.getElementById('energy') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   if (energiagenerada == 0 && energiaconsumida1 == 0) {
  //     this.donutFlag = true;
  //   } else {
  //     var myDoughnutChart = new Chart(ctx, {
  //       type: 'doughnut',
  //       data: {
  //         datasets: [{
  //           data: [energiagenerada, energiaconsumida1],
  //           backgroundColor: ['rgb(139, 201, 100, 1)', 'rgb(71, 144, 208, 1)', 'rgb(247, 171, 62, 1)', 'rgb(255, 107, 0, 1)']
  //         }],

  //         // These labels appear in the legend and in the tooltips when hovering different arcs
  //         labels: [
  //           'Energia Generada Total',
  //           'Energia Consumida Total',
  //         ]
  //       }
  //     });
  //   }
  // }

  // changeFrom(data) {
  //   data = moment(data).format("yyyy-MM-ddTHH:mm");
  //   let arrdata = data.split(' ');
  //   let today = new Date().toLocaleString();
  //   let arrToday = today.split(' ');
  //   let date = arrdata[0].split('/');
  //   let todayDat = arrToday[0].split('/');


  //   if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) > parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) > parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) > parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) < parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //   } else if (parseInt(date[0]) == parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //     if (parseInt(arrdata[1]) > parseInt(arrToday[1]) || parseInt(arrdata[1].split(':')[1]) > parseInt(arrToday[1].split(':')[1])) {
  //       this.alerta.descErr('La hora que ingresaste es mayor a la actual');
  //       this.showFlag = false;
  //       this.toDate = "";
  //     } else {
  //       if (this.fromDate != undefined && this.toDate != undefined) {
  //         let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //         let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //         this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //         this.showFlag = true;
  //       }
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) < parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) < parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //   }

  // }
  // changeTo(data) {
  //   data = moment(data).format("yyyy-MM-ddTHH:mm");
  //   let arrdata = data.split(' ');
  //   let today = new Date().toLocaleString();
  //   let arrToday = today.split(' ');
  //   let date = arrdata[0].split('/');
  //   let todayDat = arrToday[0].split('/');

  //   if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) > parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) > parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) > parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) < parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //     }
  //   } else if (parseInt(date[0]) == parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.showFlag = true;
  //     }
  //     if (parseInt(arrdata[1]) > parseInt(arrToday[1]) || parseInt(arrdata[1].split(':')[1]) > parseInt(arrToday[1].split(':')[1])) {
  //       this.alerta.descErr('La hora que ingresaste es mayor a la actual');
  //       this.showFlag = false;
  //       this.toDate = "";
  //     } else {
  //       if (this.fromDate != undefined && this.toDate != undefined) {
  //         this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //         this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       }
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) < parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) < parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //     }
  //   }
  // }
  // searchDate(from, to) {
  //   this.spinner.show();
  //   setTimeout(() => {
  //     this.textoBoton = 'Desde: ' + moment(from).format("DD/MM/YYYY HH:mm:ss") + ' hasta: ' + moment(to).format("DD/MM/YYYY HH:mm:ss");
  //     this.showFlag = true;
  //     this.spinner.hide();
  //     this.switchFilter('Personalizado');
  //   }, 3000);
  // }

  // leftClick(data: string) {
  //   for (let i = 0; i < this.filterContent.length; i++) {
  //     if (data !== this.filterContent[i].nombre) {
  //       this.switchFilter(this.filterContent[0].nombre);
  //       this.textoBoton = this.filterContent[0].nombre;
  //     } else {
  //       continue;
  //     }
  //   }
  //   for (let i = 0; i < this.filterContent.length; i++) {
  //     if (data === this.filterContent[0].nombre) {
  //       this.switchFilter(this.filterContent[this.filterContent.length - 1].nombre);
  //       this.textoBoton = this.filterContent[this.filterContent.length - 1].nombre;
  //     } else if (i > 0 && data === this.filterContent[i].nombre) {
  //       this.switchFilter(this.filterContent[i - 1].nombre);
  //       this.textoBoton = this.filterContent[i - 1].nombre;

  //     } else {
  //       continue;
  //     }
  //   }
  // }
  // rightClick(data: string) {
  //   for (let i = 0; i < this.filterContent.length; i++) {
  //     if (data !== this.filterContent[i].nombre) {
  //       this.switchFilter(this.filterContent[0].nombre);
  //       this.textoBoton = this.filterContent[0].nombre;
  //     } else {
  //       continue;
  //     }
  //   }
  //   for (let i = 0; i < this.filterContent.length; i++) {
  //     if (data === this.filterContent[this.filterContent.length - 1].nombre) {
  //       this.switchFilter(this.filterContent[0].nombre);
  //       this.textoBoton = this.filterContent[0].nombre;
  //     } else if (data === this.filterContent[i].nombre) {
  //       this.switchFilter(this.filterContent[i + 1].nombre);
  //       this.textoBoton = this.filterContent[i + 1].nombre;
  //     } else {
  //       continue;
  //     }
  //   }
  // }



}
