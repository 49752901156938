import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ExcelService } from 'src/app/excel.service';
import { GlobalService } from 'src/app/global.service';
import { GlobalAlertService } from 'src/app/global-alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listado-preliminar-nazareth',
  templateUrl: './listado-preliminar-nazareth.component.html'
})
export class ListadoPreliminarNazarethComponent implements OnInit {
  clientClass;
  benefits = [];
  review = [];
  tableOn: boolean = false;
  Toexcel;
  Excel;

  constructor(public global: GlobalService,
    private route: Router,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata2 = { option: 'porcentaje-nazareth' };
      console.log('porcentaje-nazareth', pdata2);
      this.global.consultar(pdata2, (err2, response2) => {
        this.global.porcentaje_nazareth = response2;
        const pdata1 = { option: 'beneficiarios-nazareth' };
        console.log('Beneficiarios', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
        //  this.spinner.hide();  //     --->         SOLUCION MOMENTANEA 
          
          for (let i = 0; i < this.global.porcentaje_nazareth.length; i++) {
            for (let j = 0; j < response1.length; j++) {
              
              if (this.global.porcentaje_nazareth[i][0] == response1[j][0]) {
                console.log('este es mi console', response1[j]);  //   --> NO SE EJECUTA 
                this.benefits.push(response1[j])
              }
            }
          }
          if (this.benefits.length == 0) { //   ==   --->    2     SOLUCION MOMENTANEA 
            $(function () {
              this.table = $('#TUsuariosNazareth').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
            this.tableOn = true;
            this.spinner.hide(); // NUNCA SE LLEGA A EJECUTAR POR ESO HAY UN SPINNER INFINITO
          }
        });
      });
    }, 1000);
    setTimeout(() => {      
      const pdata10 = {option: 'excellpn'};
      this.global.consultar(pdata10, (err10, response10) => {
        console.log('REVISIÓN EXCEL', response10);
        this.Excel = response10;
      });
    }, 1000);
  }
  verificarPorcentaje(idFormulario, caso: number): number {
    for (let i = 0; i < this.global.porcentaje.length; i++) {
      if (idFormulario == this.global.porcentaje[i][0]) {
        switch (caso) {
          case 1:
            if (this.global.porcentaje[i][1] == 0) {
              return 0;
            } else {
              return 1;
            }

          case 2:

            if (this.global.porcentaje[i][2] == 0) {
              return 0;
            } else {
              return 1;
            }

          case 3:

            if (this.global.porcentaje[i][3] == 0) {
              return 0;
            } else {
              return 1;
            }


          default:
            break;
        }
      } else {
        continue;
      }

    }
  }
  revisionCedula(idBenefit, idForm) {
    console.log('id beneficiario', idBenefit);
    console.log('id formulario', idForm);
    this.global.isCC = true;
    this.global.isLc = false;
    this.global.isDp = false;
    this.global.idBenefit = idBenefit;
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar');
  }
  revisionLocalizacion(idBenefit, idForm) {
    console.log('id beneficiario', idBenefit);
    console.log('id formulario', idForm);
    this.global.isCC = false;
    this.global.isLc = true;
    this.global.isDp = false;
    this.global.idBenefit = idBenefit;
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar');
  }
  revisionDispositivos(idBenefit, idForm) {
    console.log('id beneficiario', idBenefit);
    console.log('id formulario', idForm);
    this.global.isCC = false;
    this.global.isLc = false;
    this.global.isDp = true;
    this.global.idBenefit = idBenefit;
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar');
  }
  activarListPre(idBenefit) {
    console.log('id beneficiario', idBenefit);
  }
  desactivarListPre(idBenefit) {

    console.log('id beneficiario', idBenefit);
  }
  deleteValQ(idBenefit) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el usuario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        try {
          setTimeout(() => {
            var query = "UPDATE db_nazareth.consentimiento SET IsDelete = '1' WHERE Id_Encuesta ='" + idBenefit + "';";
            const pdata1 = { option: 'insertar3', texto: query };
            this.global.consultar(pdata1, async (err, response) => {
              if (err == null && await response == true) {
                this.alert.descValid('Registro eliminado correctamente');
                $(function () {
                  this.table = $('#TUsuariosNazareth').DataTable({
                    'responsive': false,
                    'destroy': true,
                    'retrieve': true,
                    'paging': true,
                    'info': false,
                    'pagingType': 'numbers',
                    'language': {
                      'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                    }
                  });
                });

                this.route.navigateByUrl('/listado-preliminar-nazareth');
                this.spinner.hide();
              } else {
                this.alert.descErr('Error, intente de nuevo');
                $(function () {
                  this.table = $('#TUsuariosNazareth').DataTable({
                    'responsive': false,
                    'destroy': true,
                    'retrieve': true,
                    'paging': true,
                    'info': false,
                    'pagingType': 'numbers',
                    'language': {
                      'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                    }
                  });
                });
                this.ngOnInit();
                this.spinner.hide();
              }
            });
          }, 1000);
        } catch (error) {
          console.log(error);
        }

      } else {
        this.ngOnInit();
      }
    });
    console.log('id beneficiario', idBenefit);
  }
  downloadExcel() {
    console.log('Excel', this.Excel);
    this.Toexcel = [['Id', 'Nombre del beneficiario', 'Nombre del encuestado', 'Cedula', 'Fecha de actualización']];
    for (let p = 0; p < this.Excel.length; p++) {
      this.Toexcel.push(this.Excel[p]);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Listado Preliminar', 'Listado Preliminar');
      setTimeout(() => {
        this.Toexcel = [['Id', 'Nombre del beneficiario', 'Nombre del encuestado', 'Cedula', 'Fecha de actualización']];
      }, 1000);
    }, 1500);
  }
  ir(idBenefit) {
    this.global.idBenefit = idBenefit;
    this.route.navigateByUrl('/formulario-preliminar-nazareth');
  }

}
