import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from "jquery";
import { GlobalService } from "src/app/global.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalAlertService } from "src/app/global-alert.service";
import * as moment from "moment";

@Component({
  selector: "app-informacion-usuario",
  templateUrl: "./informacion-usuario.component.html",
  styleUrls: ["./informacion-usuario.component.css"],
})
export class InformacionUsuarioComponent implements OnInit {
  constructor(
    public global: GlobalService,
    public ruta: Router,
    // protected route: Router,
    private modalService: NgbModal,
    public spinner: NgxSpinnerService,
    private alert: GlobalAlertService
  ) {}

  clientClass;
  informacionBeneficiario;
  Historial = Array();
  Archivos;
  Mantenimiento = Array();
  banderaActualizar;
  Estados = Array();
  listActived: boolean = true;
  listForschedule: boolean = false;
  ticketsBeneficiario;
  banderaTickets: boolean = false;
  editableQuestion = [{}];
  Questions: [];
  datosMantenimiento = [];
  banderaMantenice: boolean = false;
  edit: boolean = false;
  Fecha;
  Nombre_Completo: string;
  TipoDoc: string;
  Cedula: number;
  Telefono: number;
  Sexo: string;
  Correo: string;
  Ocupacion: string;
  idEncuesta;
  banderaComentaruioTecnico: boolean = false;
  comentariaTecnico;
  // localInfo = JSON.parse(window.localStorage.getItem('informacion beneficiario'))

  ngOnInit(): void {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.global.Id_Ticket = "";
    this.global.banderaModificaionTicket = false;

    this.informacionBeneficiario = this.global.informacionBeneficiario
      ? this.global.informacionBeneficiario
      : JSON.parse(window.localStorage.getItem("informacion beneficiario"));
    console.log("este", this.informacionBeneficiario);

    if (this.informacionBeneficiario[0][71] === this.global.USERDATALIWA[1]) {
      const pdata1 = {
        option: "insertar2",
        texto: `
          SELECT
            A.*,
            S.Nombre AS Nombre_seccional,
            P.Nombre AS Nombre_proyecto,
            A.Id_Proyecto
          FROM
            db_liwa.AOM A
            INNER JOIN db_liwa.Seccionales S ON A.Id_Seccional = S.Id_seccional
            INNER JOIN db_liwa.tipoProyecto P ON A.Id_Proyecto = P.id_tipoProyecto
          WHERE
            A.IsDelete = 0 AND A.Id = '${this.informacionBeneficiario[0][0]}' AND P.Id_Client = '${this.global.USERDATALIWA[1]}'    
          `,
      };

      this.global.consultar(pdata1, async (err, response) => {
        this.informacionBeneficiario = await response;
      });

      const consultaBeneficiario = {
        option: "insertar2",
        texto: `SELECT *,(db_liwa.getdays(Creation_Date, NOW())) FROM db_liwa.Tickets_New WHERE IsDelete=0 AND Id_Beneficiario = '${this.informacionBeneficiario[0][0]}';`,
      };
      this.global.consultar(consultaBeneficiario, async (err, response1) => {
        this.ticketsBeneficiario = await response1;
        if (this.ticketsBeneficiario.length > 0) {
          this.consultandoMantenimientos();
          $(function () {
            this.table = $("#userTicket").DataTable({
              responsive: false,
              // "order": [[ 6, 'asc' ]],
              destroy: true,
              retrieve: true,
              paging: true,
              info: false,
              pagingType: "numbers",
              language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
              },
            });
          });
          this.banderaTickets = true;
          this.spinner.hide();
        } else {
          this.consultandoMantenimientos();
          this.islistmaintenance();
          this.banderaTickets = true;
          this.spinner.hide();
        }
      });

      const pdata3 = { option: "estados" };
      $.ajax({
        data: pdata3,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data3, textStatus3, jQxhr3) {
          console.warn("bien3", data3, textStatus3);
        },
        error(jqXhr3, textStatus3, errorThrown3) {
          console.warn("mal3");
        },
      })
        .then((response3) => {
          console.log("Estados", response3);
          this.Estados = response3;
        })
        .catch((err3) => {});
    } else {
      /* aqui avisa que el ticket esta solo en dispower */

      this.alert.descErr(
        `lo sentimos pero esta informacion esta en sunpower`
      );
      this.ruta.navigateByUrl("/crear-ticket");
      return;
    }
  }
  islistmaintenance() {
    if (this.listActived === false) {
      this.listForschedule = !this.listForschedule;

      $(function () {
        this.table = $("#TableVisitasMantenimientos").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.listActived = false;
      this.listForschedule = !this.listForschedule;

      $(function () {
        this.table = $("#TableVisitasMantenimientos").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  islisttickets() {
    if (this.listForschedule === false) {
      this.listActived = !this.listActived;
      $(function () {
        this.table = $("#userTicket").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.listForschedule = false;
      this.listActived = !this.listActived;
      $(function () {
        this.table = $("#userTicket").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }

  consultandoMantenimientos() {
    const pdataM = {
      option: "insertar2",
      texto: `SELECT M.*, U.UserName,
      (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical) AS NombreTecnico,
      (db_liwa.getdays(M.LastInsert, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M
      INNER JOIN db_liwa.User U ON M.Id_Beneficiario = U.Id_User Where M.IsDelete=0 AND M.Id_Beneficiario ="${this.informacionBeneficiario[0][0]}";`,
    };
    this.global.consultar(pdataM, async (err9, response9) => {
      console.log(response9, "mantenimiento");
      this.Mantenimiento = await response9;
    });
  }
  stateBadge2(data: any) {
    if (data == "Agendado") {
      return "badge badge-warning";
    } else if (data == "Terminado") {
      return "badge badge-success";
    } else if (data == "Ejecución") {
      return "badge badge-leve";
    } else {
      return "badge badge-warning";
    }
  }
  openEditRate(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-editRate" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  editModule(numb: number) {
    numb == 0 ? (this.edit = !this.edit) : console.log("");
  }

  DatosModalEdicion(
    nombre,
    tipoDoc,
    cedula,
    telefono,
    correo,
    fecha,
    idEncuesta
  ) {
    this.Nombre_Completo = nombre;
    this.TipoDoc = tipoDoc;
    this.Cedula = cedula;
    this.Telefono = telefono;
    this.Correo = correo;
    this.Fecha = fecha;
    this.idEncuesta = idEncuesta;
  }

  confirmEdit() {
    if (
      this.Nombre_Completo == undefined ||
      this.TipoDoc == undefined ||
      this.Cedula == undefined ||
      this.Telefono == undefined ||
      this.Correo == undefined
    ) {
      this.alert.descErr("Verifique que no hayan campos vacíos");
      this.edit = false;
    } else {
      this.spinner.show();
      setTimeout(() => {
        try {
          const query =
            "UPDATE db_liwa.AOM SET " +
            "Nombre_Completo = " +
            "'" +
            this.Nombre_Completo +
            "'," +
            "TipoDoc = " +
            "'" +
            this.TipoDoc +
            "'," +
            "Cedula = " +
            "'" +
            this.Cedula +
            "'," +
            "Telefono = " +
            "'" +
            this.Telefono +
            "'," +
            "Sexo = " +
            "'" +
            this.Sexo +
            "'," +
            "Correo = " +
            "'" +
            this.Correo +
            "'," +
            "Ocupacion = " +
            "'" +
            this.Ocupacion +
            "'" +
            " WHERE Id_Encuesta =" +
            "'" +
            this.idEncuesta +
            "';";
          const pdata1 = { option: "insertar3", texto: query };
          console.log(pdata1);
          this.global.consultar(pdata1, async (err, response) => {
            // console.log(response, query);
            if (err == null && (await response) == true) {
              this.alert.descValid("Los datos se actualizaron con éxito!");
              this.edit = false;
              this.spinner.hide();
            } else {
              this.alert.descErr();
              this.edit = false;
              this.spinner.hide();
            }
          });
          const query2 =
            "REPLACE INTO db_liwa.Update_beneficiarios_Sac (Id_Beneficiario,Description,Date,Id_Usuario,IsDelete,IsActive)" +
            " VALUES ('" +
            this.informacionBeneficiario[0][0] +
            "','" +
            "opcional" +
            "','" +
            moment().format("YYYY-MM-DD HH:mm:ss") +
            "','" +
            this.global.Id_Usuario +
            "','" +
            0 +
            "','" +
            1 +
            "');";
          const pdata2 = { option: "insertar3", texto: query2 };
          this.global.consultar(pdata2, (err, response) => {
            // console.log(response, 'esta es el backup de la modificacion');
            this.ngOnInit();
          });
        } catch (e) {
          console.log(e);
        }
      }, 600);
    }
  }

  stateBadge(data: any, estado: any, posicion: any) {
    if (estado != 10 && estado != 11) {
      if (data >= 1 && data <= 6) {
        this.ticketsBeneficiario[posicion][13] = "Leve";
        return "badge badge-leve"; // am
      } else if (data >= 7 && data <= 13) {
        this.ticketsBeneficiario[posicion][13] = "Moderado";
        return "badge badge-warning"; //
      } else if (data >= 14) {
        this.ticketsBeneficiario[posicion][13] = "Critico";
        return "badge badge-danger"; // rj
      }
    } else {
      this.ticketsBeneficiario[posicion][13] = "Cerrado";
      return "badge badge-success";
    }
  }
  botonReutilizable(casosEmergentes) {
    switch (casosEmergentes) {
      case "nuevaConsulta":
        // console.log('dos')
        window.localStorage.setItem(
          "informacion beneficiario",
          JSON.stringify([])
        );
        this.ruta.navigateByUrl("/crear-ticket");
        break;
      case "nuevaPeticion":
        // console.log('est es mi console ', this.localInfo)
        this.ruta.navigateByUrl("/historico-usuario");
        break;
      default:
        console.log("que intentas??");
        break;
    }
  }
  openEditQuestion(content: string, id_ticket: string, option: number) {
    // option 1 es el modal de ticket por la tanto el 2 del mantenimiento
    this.spinner.show();
    if (option == 1) {
      const pdata1 = { option: "Update_ticket", ticket: id_ticket };
      $.ajax({
        data: pdata1,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data, textStatus, jQxhr) {
          console.warn("bien", data, textStatus);
        },
        error(jqXhr, textStatus, errorThrown) {
          console.warn("mal");
        },
      }).then((response) => {
        console.log(response, "este es el que necesitAAo");
        this.Historial = response;
        this.banderaActualizar =
          this.Historial.filter(
            (e) => e[3] == "11" || e[3] == "10" || e[3] == "6" || e[3] == "7"
          ).length > 0
            ? true
            : false;
        // console.log("estes es mi console", this.banderaActualizar);
        const pdata2 = { option: "files", ticket: id_ticket };
        $.ajax({
          data: pdata2,
          cache: false,
          dataType: "json",
          type: "post",
          url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
          success(data2, textStatus2, jQxhr2) {
            console.warn("bien", data2, textStatus2);
          },
          error(jqXhr2, textStatus2, errorThrown2) {
            console.warn("mal");
          },
        })
          .then((response2) => {
            console.log("este mismo es ", response2);
            this.Archivos = response2;
            // this.flagS = true;
            this.spinner.hide();
          })
          .catch((err) => {});
      });
    } else if (option == 2) {
      const pdata11 = {
        option: "Update_mantenimiento",
        mantenimiento: id_ticket,
      };
      this.global.consultar(pdata11, async (err, response) => {
        this.Historial = await response;
        const pdate = {
          option: "insertar2",
          texto: `SELECT * FROM db_liwa.Tecnico  WHERE codmuni = '${this.Historial[0][1]}';`,
        };
        this.global.consultar(pdate, (err, respuesta) => {
          if (respuesta.length > 0) {
            this.banderaComentaruioTecnico = true;
            this.comentariaTecnico = respuesta[0][45];
          }
          console.log(respuesta, "la historico");
        });
        //  console.log('estettttttttt', moment(this.Historial[0][27]).format('YYYY/MM/DD HH:mm') )
      });
    }
    this.spinner.hide();
    this.modalService
      .open(content, { ariaLabelledBy: "modal-editQuestion" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.Historial = []; // esto es por buena practica limpio el estado de la bariable pero no es necesario
          this.banderaComentaruioTecnico = false;
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  formatt(item) {
    return moment(item).format("YYYY/MM/DD HH:mm");
  }
}
