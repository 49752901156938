import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { GlobalService } from 'src/app/global.service';


@Component({
  selector: 'app-calibracion',
  templateUrl: './calibracion.component.html'
})
export class CalibracionComponent implements OnInit {
  clientClass;
  wInfo: boolean = false;

  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA;
      $(function () {
        this.table = $('#TableFabricacion').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
      this.spinner.hide();
    }, 600);
  }
  goList(data: string) {
    switch (data) {
      case '1':
        this.route.navigateByUrl('/alistamiento');
        break;
      case '2':
        this.route.navigateByUrl('/calibracion');
        break;
      case '3':
        this.route.navigateByUrl('/proceso-pruebas');
        break;

      default:
        break;
    }
  }
}
