import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-medidores',
  templateUrl: './medidores.component.html',
  styleUrls: ['./medidores.component.scss']
})
export class MedidoresComponent implements OnInit {
  medidores = [];
  MEDIDORESEXCEL;
  clientClass;
  Subir = false;
  Registrar = false;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idProsec == undefined || this.global.nombreProyecto == undefined) {
      let valTemp: any;
      valTemp = sessionStorage.getItem('secTemp');
      if (valTemp != null) {
        valTemp = valTemp.split(',');
        this.global.idProsec = valTemp[0];
        this.global.nombreProyecto = valTemp[1];
        setTimeout(() => {
          let tempVar: any = [];
          tempVar.push(this.global.idProsec);
          tempVar.push(this.global.nombreProyecto);
          sessionStorage.setItem('secTemp', tempVar);
          this.clientClass = this.global.CLIENTDATALIWA[8];
          const pdata1 = { option: 'medidores_table', idProyecto: this.global.idSeccional };
          console.log('medidores', pdata1);
          this.global.consultar(pdata1, (err1, response1) => {
            console.log('Datos medidores', response1);
            this.medidores = response1;
            $(function () {
              this.table = $('#seccionalTable').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
          });

          this.spinner.hide();
        }, 600);
      } else {
        this.ruta.navigateByUrl('/proyecto');
        this.spinner.hide();
      }
    } else {
      setTimeout(() => {
        let tempVar: any = [];
        tempVar.push(this.global.idProsec);
        tempVar.push(this.global.nombreProyecto);
        sessionStorage.setItem('secTemp', tempVar);
        this.clientClass = this.global.CLIENTDATALIWA[8];
        const pdata1 = { option: 'medidores_table', idProyecto: this.global.idSeccional };
        console.log('medidores', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos medidores', response1);
          this.medidores = response1;
          $(function () {
            this.table = $('#seccionalTable').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
        });

        this.spinner.hide();
      }, 600);
    }
  }

  SubirExcel() {
    this.Subir = true;
  }
  arrayBuffer:any;
  file:File;
  incomingfile(event) 
  {
  this.file= event.target.files[0]; 
  }

  Upload() {
    let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
          var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet,{raw:true});
          if(MEDIDORESEXCEL[0].hasOwnProperty('5t')){
            console.log('Si 5t')
            if(MEDIDORESEXCEL[0].hasOwnProperty('FECHA ENSAMBLE')){
              console.log('SI FECHA ENSABLE')
              if(MEDIDORESEXCEL[0].hasOwnProperty('ID_STM FR')){
                console.log('SI ID_STM FR')
                if(MEDIDORESEXCEL[0].hasOwnProperty('ID_STM MB')){
                  console.log('SI ID_STM MB')
                  if(MEDIDORESEXCEL[0].hasOwnProperty('PROYECTO')){
                    console.log('SI PROYECTO')
                    if(MEDIDORESEXCEL[0].hasOwnProperty('SN')){
                      console.log('SI SN')
                      if(MEDIDORESEXCEL[0].hasOwnProperty('UID')){
                        console.log('SI UID')
                        if(MEDIDORESEXCEL[0].hasOwnProperty('VH')){
                          console.log('SI VH')
                          if(MEDIDORESEXCEL[0].hasOwnProperty('ÚLTIMA FECHA DE REVISIÓN')){
                            console.log('SI ÚLTIMA FECHA DE REVISIÓN')
                            this.MEDIDORESEXCEL = MEDIDORESEXCEL;
                            this.Registrar = true;
                          } else {
                            console.log('No ÚLTIMA FECHA DE REVISIÓN')
                            this.alerta.excelErr('ÚLTIMA FECHA DE REVISIÓN');
                          }
                        } else {
                          console.log('No VH')
                          this.alerta.excelErr('VH');
                        }
                      } else {
                        console.log('No UID')
                        this.alerta.excelErr('UID');
                      }
                    } else {
                      console.log('No SN')
                      this.alerta.excelErr('SN');
                    }
                  } else {
                    console.log('No PROYECTO')
                    this.alerta.excelErr('PROYECTO');
                  }
                } else {
                  console.log('No ID_STM MB')
                  this.alerta.excelErr('ID_STM MB');
                }
              } else {
                console.log('No ID_STM FR')
                this.alerta.excelErr('ID_STM FR');
              }
            } else {
              console.log('No FECHA ENSAMBLE')
              this.alerta.excelErr('FECHA ENSAMBLE');
            }
          } else {
            console.log('No 5t')
            this.alerta.excelErr('5t');
          }
      }
      fileReader.readAsArrayBuffer(this.file);
}

RegistrarMedidores(){

  let query = 'INSERT INTO db_liwa.Medidores(FECHA_ENSAMBLE, ID_STM_FR, ID_STM_MB, PROYECTO, SN, UID, VH, ULT_FECHA_REVISION, ID_FABRICACION, Seccional) VALUES ';
  if(this.Registrar == true) {
    for(let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
      query = query + "('" + this.MEDIDORESEXCEL[i]['FECHA ENSAMBLE'] +"','" + this.MEDIDORESEXCEL[i]['ID_STM FR'] + "','" + this.MEDIDORESEXCEL[i]['ID_STM MB'] + "','" + this.MEDIDORESEXCEL[i]['PROYECTO'] + "','" + this.MEDIDORESEXCEL[i]['SN'] + "','" + this.MEDIDORESEXCEL[i]['UID'] + "','" + this.MEDIDORESEXCEL[i]['VH'] + "','" + this.MEDIDORESEXCEL[i]['ÚLTIMA FECHA DE REVISIÓN'] + "','" + this.MEDIDORESEXCEL[i]['SN'] + "','" + this.global.idSeccional+ "'),";
    }
    setTimeout(() => {
      var queryDEF = query.substring(0, query.length - 1);
      queryDEF = queryDEF + ';'
      console.log(queryDEF);
      const pdata3 = { option: 'insertar', texto: queryDEF };
      this.global.consultar(pdata3, (err3, response3) => {
        console.log(response3, queryDEF);
        if (err3 == null && response3 == true) {
          let query2 = 'REPLACE INTO db_demodispac.cards(Card_UID, ManufInfo, Card_Status_Id, Card_ExpirationDate, Card_CreationDate) VALUES ';
            for(let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
              query2 = query2 + "('" + this.MEDIDORESEXCEL[i]['UID'] +"','" + this.MEDIDORESEXCEL[i]['UID'] + "','" + "1" + "','" + moment(new Date()).format("YYYY-MM-DD HH:mm:ss") + "','" + moment(new Date()).format("YYYY-MM-DD HH:mm:ss") + "'),";
            }
            setTimeout(() => {
              var queryDEF2 = query2.substring(0, query2.length - 1);
              queryDEF2 = queryDEF2 + ';'
              console.log(queryDEF2);
              const pdata4 = { option: 'insertar', texto: queryDEF2 };
              this.global.consultar(pdata4, (err4, response4) => {
                console.log(response4, queryDEF2);
                if (err4 == null && response4 == true) {
                  let query3 = 'REPLACE INTO db_demodispac.ems(Beneficiary_Id, Facility_Id, Card_UID, Date_update, STM_ID, ID_fabricacion, EMS_status_id, Id_Seccional) VALUES ';
                  for(let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
                    query3 = query3 + "('1-B-1614262171268','1-B-1614262171268','" + this.MEDIDORESEXCEL[i]['UID'] +"','" + moment(new Date()).format("YYYY-MM-DD HH:mm:ss") + "','" + this.MEDIDORESEXCEL[i]['ID_STM MB'] + "','" + this.MEDIDORESEXCEL[i]['SN'] + "','1','" + this.global.idSeccional + "'),";
                  }
                  setTimeout(() => {
                    var queryDEF3 = query3.substring(0, query3.length - 1);
                    queryDEF3 = queryDEF3 + ';'
                    console.log(queryDEF3);
                    const pdata5 = { option: 'insertar', texto: queryDEF3 };
                    this.global.consultar(pdata5, (err5, response5) => {
                      console.log(response5, queryDEF3);
                      if (err5 == null && response5 == true) {
                        this.alerta.closeValid();
                        this.ngOnInit();
                        this.MEDIDORESEXCEL = new Array();
                        this.Registrar = false;
                        this.Subir = false;
                      } else {
                        console.log(err5);
                      }
                    });
                  }, 500);
                } else {
                  console.log(err4);
                }
              });
            }, 500);
        } else {
          console.log(err3);
        }
    });
    }, 500);
  } else {

  }
}

  createSeccional() {
    this.ruta.navigateByUrl('/crear-seccional');
  }

  IsActiveView(data) {
    if (data == 1) {
      return 'Activado';
    }
    if (data == 0) {
      return 'Desactivado';
    }
  }
  isActiveBadge(data) {
    if (data == 1) {
      return 'badge badge-success';
    }
    if (data == 0) {
      return 'badge badge-danger';
    }
    this.ngOnInit();
  }

  editSec(idSec) {
    this.global.idSeccional = idSec;
    this.ruta.navigateByUrl('/editar-seccional')
  }
  activar(idSec) {
    setTimeout(() => {
      var query = "UPDATE db_liwa.Seccionales SET IsActive = '1' WHERE Id_seccional ='" + idSec + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Seccional activada correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);
  }
  desactivar(idSec) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Seccionales SET IsActive = '0' WHERE Id_seccional ='" + idSec + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Seccional desactivada correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);
  }
  deleteSec(idSec) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el medidor?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idSec);
      } else {
        this.ngOnInit();
      }
    });
  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Medidores SET IsDelete = '1' WHERE Id ='" + data + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid(' Medidor eliminado correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);

  }


}
