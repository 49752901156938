import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ajax } from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';

@Component({
  selector: 'app-fenoge2',
  templateUrl: './fenoge2.component.html',
  styleUrls: ['./fenoge2.component.scss']
})

export class Fenoge2Component implements OnInit {
  p = 1;
  clientClass;
  DataUser;
  latitude = 6.3241465;
  longitude = -76.8321544;
  zoom = 7;
  Images;
  Id;
  State;
  Pines;
  constructor(public global: GlobalService,
    public route: Router, private spinner: NgxSpinnerService, public excelService: ExcelService) { 
      this.global.initData();
      this.Id = this.global.ID_Enelar;
    }

    ngOnInit() {
      this.spinner.show();
      this.clientClass = this.global.CLIENTDATALIWA[8];
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      const pdata1 = {option: 'fenoge2', Id_B: this.Id};
      console.log(pdata1);
      ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
      url: 'https://www.php.engenius.com.co/DatabaseFenoge.php',
      success( data, textStatus, jQxhr ) {
      console.log('Datos Recibidos:', data);
      },
      error( jqXhr, textStatus, errorThrown ) {
      console.log(JSON.stringify(errorThrown));
      }
      }).then((response) => {
      console.log('DataUser',response);
      this.DataUser = response[0];
      });
    }


    Atras(){
      this.route.navigateByUrl('/fenoge');
    }
  
}