import { Component, OnInit } from "@angular/core";
import { GlobalAlertService } from "src/app/global-alert.service";
import { GlobalService } from "../../global.service";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";
import { Swiper, Navigation, Pagination } from "swiper";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { async } from "rxjs/internal/scheduler/async";
import { ExcelService } from "src/app/excel.service";
import { SubirArchivosService } from "src/app/subir-archivos.service";
Swiper.use([Navigation, Pagination]);

@Component({
  selector: "app-ver-ticket",
  templateUrl: "./ver-ticket.component.html",
  styleUrls: ["./ver-ticket.component.scss"],
})
export class VerTicketComponent implements OnInit {
  valorMenu1;
  valorSubMenu1: String = '-';
  valorSubMenu2: String = '-';
  valorSubMenu3: String = '-';
  codigo;
  File;
  descriptionBenefit;
  banderaCrearTicket: boolean = false;

  types = [
    { type: "Comprobantes" },
    { type: "Novedad Solucion" },
    { type: "Pagos" },
    { type: "Suspension" },
    { type: "Pqrs" },
    { type: "llamada outbound" },
  ];
  //  SUB MENUS 1 APARTIR DEL PRIMER CASO
  opcionPqrs: boolean = false;
  typesSubMenu1Pqrs = [
    { type: "Tipo de Tramite" },
    { type: "Tipo de Respuesta" },
    { type: "Facturacion" },
    { type: "Prestacion" },
  ];
  opcionComprobantes: boolean = false;
  typesSubMenu1Comprobantes = [
    { type: "Pagos" },
    { type: "disponibilidad de servicio" },
    { type: "Estado de cuenta mes actaul" },
    { type: "Estado de cuenta mes anterios" },
    { type: "Estado de cuenta mes post anterior" },
  ];
  opcionNovedad: boolean = false;
  typesSubMenu1Novedad = [{ type: "Daño" }, { type: "Traslado" }, { type: "Acceso restringido" }, { type: "Abandono de Vivienda"} ];
  opcionPagos: boolean = false;
  typesSubMenu1Pagos = [
    { type: "Pago no reflejado" },
    { type: "Usuario no habilitado" },
    { type: "Recaudacion sin servicio" },
    { type: "Acuerdo de pago" },
    { type: "Solicitud aplicacion fecha de pago" },
  ];
  opcionSuspension: boolean = false;
  typesSubMenu1Suspension = [{ type: "Reconexcion Fallida" }];
  //  SUB MENUS 2 APARTIR DEL SEGUNDO CASO
  opcionSubMenu2Facturacion: boolean = false;
  typesSubMenu2Facturacion = [
    { type: "Inconformidad con el aforo" },
    {
      type: "Inconformidad con la medicion del consumo o produccion facturado",
    },
    { type: "Cobros inoportunos" },
    { type: "cobro por servicios no prestados" },
    { type: "datos generales incorrectos" },
    { type: "cobro multiple y/o acumulado" },
    { type: "entrega inoportuna o no entrega de factura" },
    { type: "cobros por conexión, reconexion, reinstalacion" },
    { type: "incorformidad con el cambio o con el cobro del medidor" },
    {
      type: "cobro de cargos relacionados con intereses de mora, refinanciacion, cartera o acuerdos de pago",
    },
    { type: "subsidios y contribuciones" },
    {
      type: "cobro de otros bienes o servicios en la factura no autorizados por el usuario",
    },
    { type: "incumplimiento o negacion del acuerdo o suspension del servicio" },
    { type: "estrato incorrecto" },
    { type: "clase de uso incorrecto (industrial, comercial, oficial, otros)" },
    { type: "tarifa incorrecta" },
    { type: "costos por promedio" },
    { type: "cobro de consumo registrado por medidor de otro promedio" },
    {
      type: "suscriptor que efectua el pago pero este no es aplicado por la empresa en la facturacion",
    },
    { type: "solicitud de rompimiento de solidaridad" },
    { type: "cobro de revisiones" },
    { type: "inconformidad por desviacion significativa" },
    { type: "cobro por recuperacion de consumos" },
    { type: "inconformidad por la normalizacion del servicio" },
    { type: "cobro por reconexion no autorizada porla empresa" },
    { type: "negacion de la solicitud de suspension" },
  ];

  opcionSubMenu2llamadaOutbound: boolean = false;
  typesSubMenu2llamadaOutbound = [
    { type: "no contesta" },
    { type: "ocupado" },
    { type: "telefono dañado" },
    { type: "buzon de Mensajes" },
    { type: "llamada de Bienvenida" },
    { type: "equivocado" },
  ];

  opcionSubMenu2TipoTramite: boolean = false;
  typesSubMenu2TipoTramite = [
    { type: "Reclamacion" },
    { type: "Queja" },
    { type: "Recursos de reposicion" },
    { type: "Recurso de reposicion y subsidio de apelacion" },
  ];
  opcionSubMenu2Prestacion: boolean = false;
  typesSubMenu2Prestacion = [
    { type: "Interrupciones en la prestacion del servicio" },
    {
      type: "Variaciones en las caracteristicas del suministro o prestacion del servicio",
    },
    { type: "No atencion de condiciones de seguridad o riesgo" },
    { type: "Terminacion del contrato" },
    {
      type: "Suspension o corte del servicio sin previo aviso o si causa aparente",
    },
    { type: "Quejas administrativas" },
    { type: "Estado de la infraestructura" },
    { type: "Fallas en la conexión del servicio" },
    { type: "No conexión del servicio" },
    { type: "Hurto parte UC" },
    { type: "Hurto solucion" },
    { type: "Cambio de titularidad" },
    { type: "Desistimiento" },
    { type: "Reposición" },
    { type: "Garantía" },
  ];
  opcionSubMenu2TipoRespuesta: boolean = false;
  typesSubMenu2TipoRespuesta = [
    { type: "Accede" },
    { type: "Accede parcialmente" },
    { type: "No accede" },
    { type: "Confirma" },
    { type: "Modifica" },
    { type: "Revoca" },
    { type: "Rechaza" },
    { type: "Translada competencias" },
    { type: "Pendiente de respuesta" },
    { type: "Sin respuesta" },
    { type: "Archiva" },
  ];
  opcionSubMenu2Pagos: boolean = false;
  typesSubMenu2Pagos = [
    // este se aplica a varios casos por raxones internas
    { type: "Envio comprobante" },
  ];
  opcionSubMenu2Danos: boolean = false;
  typesSubMenu2Danos = [
    { type: "% Carga Bateria" },
    { type: "Apertura gabinete" },
    { type: "Datos Tension" },
    { type: "Gestor" },
    { type: "otro" },
    { type: "Panel con daño fisico" },
    { type: "sin servicio" },
    { type: "Tarjeta datos" },
    { type: "Tecnico (CRM)" },
    // { type: "Novedad Solución / Abandono de Vivienda"}
  ];
  opcionSubMenu2acceso: boolean = false;
  typesSubMenu2acceso = [
    { type: "Orden público" },
    { type: "Orden Social" }
  ];
  optioSubMenu2SolicitudAplicaciones: Boolean = false;
  typesSubMenu2SolicitudAplicaciones = [
    { type: "Capacidad de pago" },
    { type: "Olvido" },
    { type: "Reclamacion pendiente" },
    { type: "No recibio factura" },
  ];
  opcionSubMenu2ReconexionFallida: boolean = false;
  typesSubMenu2ReconexionFallida = [{ type: "Agendamiento visita" }];
  //  SUB MENUS 3 APARTIR DEL tercer CASO
  opcionSubMenu3TipoTramite: boolean = false;
  typesSubMenu3TipoTramite = [{ type: "Facturacion" }, { type: "Prestacion" }];
  opcionSubMenu3TipoRespuesta: boolean = false;
  typesSubMenu3Respuesta = [
    {
      type: "Notificación Personal (Artículos 67 y 68 de la Ley 1437 de 2011)",
    },
    { type: "Notificación por Aviso (Artículo 69 de la Ley 1437 de 2011)." },
    {
      type: "Notificación por Conducta Concluyente (Artículo 72 de la Ley 1437 de2011)",
    },
    { type: "No Requiere Notificación" },
    { type: "Pendiente o en trámite de notificación" },
  ];
  opcionSubMenu3Gestor: boolean = false;
  typesSubMenu3Gestor = [{ type: "Alarma Sonora" }, { type: "Sin senal" }];
  opcionSubMenu3Panel: boolean = false;
  typesSubMenu3Panel = [{ type: "Agendamiento visita" }];
  opcionSubMenu3Tarjeta = false;
  typesSubMenu3Tarjeta = [
    { type: "Daño fisico" },
    { type: "No permite lectura" },
    { type: "Perdida" },
  ];
  Historial;
  banderaActualizar: boolean = false;
  Archivos;
  clientClass;
  Estados = Array();
  description;
  descriptionModal;
  Toexcel = new Array();
  estado;
  srcImg = [];
  file: any;
  imgsUrls = [];
  ticketArchived: boolean = false;
  ticketActive: boolean = false;
  TipificaActive: boolean = false;
  windowB: boolean = true;
  windowA: boolean = false;
  windowC: boolean = true;
  windowD: boolean = true;
  NickName;
  NameP;
  Evidence: boolean = false;
  flagS: boolean = false;
  informacionBeneficiario;
  Mantenimiento = Array();
  listActived: boolean = true;
  listForschedule: boolean = false;
  ticketsBeneficiario;
  banderaTickets: boolean = false;
  editableQuestion = [{}];
  Questions: [];
  datosMantenimiento = [];
  comentarios = [];
  DataTipifica: any;
  DataTipifica2: any;
  banderaMantenice: boolean = false;
  edit: boolean = false;
  Fecha;
  Nombre_Completo: string;
  TipoDoc: string;
  Cedula: number;
  Telefono: number;
  Sexo: string;
  Correo: string;
  Ocupacion: string;
  idEncuesta;
  creadopor;
  banderaComentaruioTecnico: boolean = false
  comentariaTecnico;
  comentario;
  constructor(
    private subir:SubirArchivosService,
    private excelService: ExcelService,
    public global: GlobalService,
    public ruta: Router,
    private spinner: NgxSpinnerService,
    public alerta: GlobalAlertService,
    public modalService: NgbModal
  ) {
    this.global.initData();
  }
  handleClick(data: Number) {
    if (data === 1) {
      this.windowA = !this.windowA;
      this.windowB = true;
      this.windowC = true;
      this.windowD = true;
    } else if (data === 2) {
      this.windowA = true;
      this.windowB = !this.windowB;
      this.windowC = true;
      this.windowD = true;
    } else if (data === 3) {
      this.windowA = true;
      this.windowB = true;
      this.windowC = !this.windowC;
      this.windowD = true;
    }else if (data === 4) {
      this.windowA = true;
      this.windowB = true;
      this.windowC = true;
      this.windowD = !this.windowD;
    }
  }
  ngOnInit(): void {
    if (this.global.Id_Ticket == undefined) {
      this.ruta.navigateByUrl("/pagina-principal");
    } else {
      this.spinner.show();
      this.informacionBeneficiario = this.global.informacion
        ? this.global.informacion
        : JSON.parse(window.localStorage.getItem("informacion"));
      console.log('este', this.informacionBeneficiario)
      const pdatainfo = {
        option: 'insertar2',
        texto: `SELECT A.*,
        (SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,
        (SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto,
         A.Id_Proyecto FROM db_liwa.AOM A WHERE  A.IsDelete=0 AND  A.Id = '${this.informacionBeneficiario[0][0]}';`
      }
      this.global.consultar(pdatainfo, async (err, response) => {
        this.informacionBeneficiario = await response
      })
      const commentarios = { option: "tickets_comentarios", idticket: this.global.Id_Ticket };
      this.global.consultar(commentarios, async(err, response) => {
        this.comentarios = await response;
        if(this.comentarios.length > 0 ){
          return this.comentarios;
        }else{
          return "no hay comentarios para este ticket"

        }
      });
      const tipifica = { option: "insertar2", texto: `SELECT * FROM db_liwa.Tickets_New WHERE IsDelete=0 AND Id_Tickets = '${this.global.Id_Ticket}';`};
      this.global.consultar(tipifica, async(err, responset) => {
        if(responset.length > 0 ){
           this.DataTipifica = responset;

          this.valorMenu1 = this.DataTipifica[0][6];
          this.handleChangeSubMenu1();
          this.valorSubMenu1 = this.DataTipifica[0][7];
          this.handleChangeSubMenu2();
          this.valorSubMenu2 = this.DataTipifica[0][8];
          this.handleChangeSubMenu3();
          this.valorSubMenu3 = this.DataTipifica[0][9];
          this.handleChangeDescriction();
          this.descriptionBenefit = this.DataTipifica[0][10];
           console.log("Data ticket:",this.DataTipifica); 
           
      const creado = { option: "insertar2", texto: `SELECT UserName FROM db_liwa.User WHERE Id_User = '${this.DataTipifica[0][1]}';`};
      console.log("Data ticket2:",creado); 
          this.global.consultar(creado, async(err, responset2) => {
            if(responset2.length > 0 ){
              this.DataTipifica2 = responset2;
               this.creadopor = this.DataTipifica2[0][0];
               console.log("Data creado:",this.creadopor)
            }
          });         
        }
      });
      
      
      const consultaBeneficiario = {
        option: "insertar2",
        texto: `SELECT *,(db_liwa.getdays(Creation_Date, NOW())) FROM db_liwa.Tickets_New WHERE IsDelete=0 AND Id_Beneficiario = '${this.informacionBeneficiario[0][0]}';`,
      };
      this.global.consultar(consultaBeneficiario, async (err, response1) => {
        this.ticketsBeneficiario = await response1;
        if (this.ticketsBeneficiario.length > 0) {
          this.consultandoMantenimientos()
          $(function () {
            this.table = $("#userTicket").DataTable({
              responsive: false,
              // "order": [[ 6, 'asc' ]],
              destroy: true,
              retrieve: true,
              paging: true,
              info: false,
              pagingType: "numbers",
              language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
              },
            });
          });
          this.banderaTickets = true;
          this.spinner.hide();
        } else {
          this.consultandoMantenimientos()
          this.islistmaintenance()
          this.banderaTickets = true;
          this.spinner.hide();
        }
      });
      const pdata8 = { option: "estados" };
      $.ajax({
        data: pdata8,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data3, textStatus3, jQxhr3) {
          console.warn("bien3", data3, textStatus3);
        },
        error(jqXhr3, textStatus3, errorThrown3) {
          console.warn("mal3");
        },
      })
        .then((response3) => {
          console.log("Estados", response3);
          this.Estados = response3;
        })
        .catch((err3) => { });
      this.clientClass = this.global.CLIENTDATALIWA;
      console.log("Seleccionado", this.global.Id_Ticket);
      const pdata1 = { option: "Update_ticket", ticket: this.global.Id_Ticket };
      $.ajax({
        data: pdata1,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data, textStatus, jQxhr) {
          console.warn("bien", data, textStatus);
        },
        error(jqXhr, textStatus, errorThrown) {
          console.warn("mal");
        },
      })
        .then((response) => {
          console.log(response, "este es el que necesitAAo");
          this.Historial = response;
          this.banderaActualizar = true
          console.log("estes es mi console", this.banderaActualizar);
          const pdata2 = { option: "files", ticket: this.global.Id_Ticket };
          $.ajax({
            data: pdata2,
            cache: false,
            dataType: "json",
            type: "post",
            url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
            success(data2, textStatus2, jQxhr2) {
              console.warn("bien", data2, textStatus2);
            },
            error(jqXhr2, textStatus2, errorThrown2) {
              console.warn("mal");
            },
          })
            .then((response2) => {
              console.log("este mismo es ", response2);
              this.Archivos = response2;
              this.flagS = true;
              this.spinner.hide();
            })
            .catch((err) => { });
        })
        .catch((err) => { });

      const pdata3 = { option: "estados" };
      $.ajax({
        data: pdata3,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data3, textStatus3, jQxhr3) {
          console.warn("bien3", data3, textStatus3);
        },
        error(jqXhr3, textStatus3, errorThrown3) {
          console.warn("mal3");
        },
      })
        .then((response3) => {
          console.log("Estados", response3);
          this.Estados = response3;
        })
        .catch((err3) => { });
    }
  }
  Actualizar() {
    if (
      this.description == undefined ||
      this.description == null ||
      this.estado == undefined ||
      this.estado == null
    ) {
      this.alerta.descErr();
    } else if (this.estado == "0") {
      this.alerta.descErr();
    } else {
      const query2 =
        "REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive,Id_user)" +
        " VALUES ('" +
        this.global.Id_Ticket +
        "','" +
        this.description +
        "','" +
        moment().format("YYYY-MM-DD HH:mm:ss") +
        "','" +
        this.estado +
        "','" +
        0 +
        "','" +
        1 +
        "','" +
        this.global.Id_Usuario +
        "');";
      const pdata2 = { option: "insertar3", texto: query2 };
      this.global.consultar(pdata2, async (err, response) => {
        console.log(response, query2);
        if (err == null && response == true) {
          // alertFunctions.UserSuccess();

          const query5 =
            "UPDATE db_liwa.Tickets_New SET Id_Statuses='" +
            this.estado +
            "'" +
            "WHERE Id_Tickets='" +
            this.global.Id_Ticket +
            "'";
          const pdata5 = { option: "insertar3", texto: query5 };
          this.global.consultar(pdata5, async (err5, response5) => {
            console.log(response5, query5);
            if (err5 == null && response5 == true) {
              this.eviarSMS(this.estado, this.global.Id_Ticket);

              setTimeout(() => {
                this.alerta.descValid("El ticket se actualizó con éxito");
                this.description = undefined;
                this.estado = 0;
                this.ngOnInit();
              }, 1000);

              this.ruta.navigateByUrl("/user-ticket");
            } else {
              this.alerta.descErr("Error al actualizar el ticket");
            }
          });
        } else {
          this.alerta.descErr("Error al actualizar el ticket");
        }
      });
      this.enviarcorreo();
    }
  }

  /* funcion para descargar comentarios formato excel */
  exportAsXLSX() {
    this.spinner.show();
    this.Toexcel = [['idTicket:', "Creador por", 'Comentario']];

    if (this.comentarios.length == 0) {
      this.Toexcel.push(['NO DATA', 'NO DATA', 'NO DATA']);
    } else {
      for (let index = 0; index < this.comentarios.length; index++) {
        this.Toexcel.push([this.comentarios[index][3], this.comentarios[index][4], this.comentarios[index][1] ]);
      }
    }

    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'datos', 'comentarios_' + this.global.Id_Ticket);
      setTimeout(() => {
        this.spinner.hide();
        this.Toexcel = [['idTicket:', "Creador por", 'Comentario']];
      }, 1000);
    }, 1500);
  }
  enviarcorreo() {
    const pdata = { option: "estado", texto: this.estado };
    this.global.consultar(pdata, async (err, response) => {
      this.NameP = response[0];
      this.NameP = JSON.stringify(this.NameP[0]);
      this.NameP = this.NameP.replace('"', "");
      this.NameP = this.NameP.replace('"', "");
    });
    setTimeout(() => {
      const pdata9 = {
        option: "mailA",
        ticket: this.global.Id_Ticket,
        texto: this.NameP,
        user: this.global.USERDATALIWA[8],
        novedad: this.descriptionModal,
      };
      this.global.consultar(pdata9, async (err2, response2) => {
        console.log("Datos correo", response2);
      });
    }, 500);
  }
  eviarSMS(idTk, statusTk) {
    setTimeout(() => {
      const pdata1 = {
        option: "insertar2",
        texto: `SELECT T.Id_Proyecto, T.Id_Seccional,T.Id_Beneficiario,
        (SELECT M.Id_Maintenance FROM db_liwa.Maintenance_new M WHERE M.Id_Ticket = T.Id_Tickets),
        (SELECT M.Id_Technical FROM db_liwa.Maintenance_new M WHERE M.Id_Ticket = T.Id_Tickets),
        (SELECT M.Status FROM db_liwa.Maintenance_new M WHERE M.Id_Ticket = T.Id_Tickets),
        (SELECT S.Name_Status FROM db_liwa.Ticket_Statuses S WHERE S.Id_Statuses = T.Id_Statuses),
        (SELECT B.Telefono FROM db_liwa.AOM B WHERE B.Id = T.Id_Beneficiario)
         FROM db_liwa.Tickets_New T WHERE T.Id_Tickets = '${idTk}';`,
      };
      this.global.consultar(pdata1, async (err9, response9) => {
        const pdata9 = {
          number: "57" + response9[0][0],
          message: `DISPOWER le informa que cambio de estado PQR No ${idTk} , a ${statusTk} cualquier inquietud comunicarce a la linea 0180000413824`,
          type: "1",
        };
        const pdata7 = {
          number: "57" + "3153583711",
          message: `DISPOWER le informa que cambio de estado PQR No ${idTk} , a ${statusTk} cualquier inquietud comunicarce a la linea 0180000413824`,
          type: "1",
        };
        const pdata6 = {
          number: "57" + "3164946552",
          message: `DISPOWER le informa que cambio de estado PQR No ${idTk} , a ${statusTk} cualquier inquietud comunicarce a la linea 0180000413824`,
          type: "1",
        };
        let query = `REPLACE INTO db_liwa.MensajesAOM (Id_Proyecto,Id_Seccional,Id_Beneficiario,Id_Maintenance,Id_Ticket,Id_User,Id_Tecnico,Numero_Dest,Tipo_Mantenimiento,Tipo_Ticket,ContenidoSMS)
        VALUES ('${response9[0][0]}','${response9[0][1]}','${response9[0][2]}','${response9[0][3]}','${idTk}','${this.global.Id_Usuario}','${response9[0][4]}','${pdata9.number}','${response9[0][5]}','${statusTk}','${pdata6.message}');`;
        const pdata5 = { option: "insertar3", texto: query };
        this.global.consultarSMS(pdata9, (err2, response2) => {
          this.global.consultarSMS(pdata7, (err2, response2) => {
            this.global.consultarSMS(pdata6, (err2, response2) => {
              this.global.consultar(pdata5, async (err2, response2) => { });
            });
          });
        });
      });
    }, 500);
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.Evidence = true;
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }
  loadPhoto(dataentry: any) {
    if (this.descriptionModal == undefined || this.descriptionModal == null) {
      this.alerta.descErr();
    } else {
      this.spinner.show();
      this.NickName = this.global.Id_Ticket;
      if (this.srcImg == null || this.srcImg == undefined) {
        this.alerta.descErr("Error al subir la foto, verifique nuevamente");
      } else {
        this.NickName = this.global.Id_Ticket;
        let con1 = 0;
        let con2 = 0;

        for (let i = 0; i < this.srcImg.length; i++) {
          con1++;
          console.log("Foto #" + i, this.srcImg[i]);
          const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
          // nombre de la imagen en el servidor this.Photo
          const imageName = i + "-" + moment().unix() + ".jpg";
          const imageBlob = this.dataURItoBlob(FOTOO);
          const imageFile = FOTOO;
          const data = new FormData();
          console.log(imageFile, imageBlob);
          data.append("image", imageFile);
          data.append("nombre", imageName);
          data.append("ruta", this.NickName);
          $.ajax({
            url: "https://fotos.engenius.com.co/subir_archivo_TicketLW.php",
            type: "post",
            dataType: "json",
            data,
            processData: false,
            contentType: false,
          }).then((data1) => {
            console.log(data, data1);
            this.imgsUrls[i] = data1;
            con2++;
            if (
              this.imgsUrls[i] == "BADEXT" ||
              this.imgsUrls[i] == null ||
              this.imgsUrls[i] == undefined ||
              this.imgsUrls[i] == "" ||
              this.imgsUrls[i] == "NULL"
            ) {
              this.alerta.descErr(
                "Error al subir la foto, verifique nuevamente"
              );
            } else {
              const query =
                "REPLACE INTO db_liwa.Ticket_Files (Id_Ticket,Name_File,Type_File,File,IsDelete,IsActive)" +
                " VALUES ('" +
                this.NickName +
                "','" +
                imageName +
                "','" +
                "jpg" +
                "','" +
                data1 +
                "','" +
                0 +
                "','" +
                1 +
                "');";
              const pdata1 = { option: "inserta3r", texto: query };
              this.global.consultar(pdata1, async (err, response) => {
                console.log(response, query);
                if (err == null && response == true) {
                  // alertFunctions.UserSuccess();
                  this.alerta.descValid("El ticket se creo con éxito");
                } else {
                  this.alerta.descErr(
                    "Error al crear el ticket, verifique nuevamente que no hayan campos vacíos"
                  );
                }
              });
              // goto crear ticket
              if (con1 == con2 && con2 == this.srcImg.length) {
                // this.actualizarPhoto(dataentry);
              }
            }
          });
        }
      }
    }
  }

  referenciaArchivo(ev){
    console.log('file',  ev)
    this.File = ev.target;
    console.log('AA', this.File)
  }

  actualizarPhoto() {
    if(this.descriptionModal == null || this.descriptionModal == undefined || this.File == null || this.File  == undefined){
      this.alerta.descErr(`Es necesario colocar una descripcion o  el archivo.`);
    }
    this.spinner.show();


    let img:any = this.File;
    let form = new FormData();

    console.log("AAA",img.files[0]);

    form.append('opcion','documents');
    form.append('file',img.files[0]);
    form.append('idTicket',this.global.Id_Ticket);

    console.log(form)
    this.subir.subirarchivo(form).subscribe(resp => {
        if(resp.status){
          const Update = { option: "insertar3", texto: `UPDATE db_liwa.Update_Tickets SET Description = "${this.descriptionModal}",url = ${resp.url} WHERE Id_Update = "${this.global.idEdit}"`};
          this.global.consultar(Update, async(errU, responseU) => {
            if (errU == null && responseU == true) {
              this.alerta.descValid(`Se cargo con exito el archivo.`);
              this.spinner.hide();
              this.ngOnInit();
              this.file = undefined
            }else{
              this.alerta.descErr('Se encontro algun error, intenta de nuevo.')
              this.spinner.hide();
              this.ngOnInit();
            }
          });
        }else if (resp === null || resp === undefined || resp === false) {
          this.alerta.descErr('Algo anda mal! vuelva a intentar carga el archivo y valide que sea el tipo de formato requerido')
          this.spinner.hide()
          this.file = undefined
        }
      },
      error => {
        this.alerta.descErr('Revisa bien el formato que seleccionaste porfavor');
        this.spinner.hide()
        this.file = undefined
      }
    );
  }
  openFormtoUpdate(content, data: any, comentario) {
    this.global.idEdit = data;
    this.descriptionModal = comentario
    console.log(this.global.idEdit);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-updateVisit" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  consultandoMantenimientos() {
    const pdataM = {
      option: "insertar2",
      texto: `SELECT M.*, U.UserName,
      (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical) AS NombreTecnico,
      (db_liwa.getdays(M.LastInsert, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M
      INNER JOIN db_liwa.User U ON M.Id_Beneficiario = U.Id_User Where M.IsDelete=0 AND M.Id_Beneficiario="${this.informacionBeneficiario[0][0]}";`,
    };
    this.global.consultar(pdataM, async (err9, response9) => {
      console.log(response9, "mantenimiento")
      this.Mantenimiento = await response9;
    });
  }

  islistmaintenance() {
    if (this.listActived === false) {
      this.listForschedule = !this.listForschedule

      $(function () {
        this.table = $("#TableVisitasMantenimientos").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.listActived = false;
      this.listForschedule = !this.listForschedule

      $(function () {
        this.table = $("#TableVisitasMantenimientos").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  islisttickets() {
    if (this.listForschedule === false) {
      this.listActived = !this.listActived;
      $(function () {
        this.table = $("#userTicket").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.listForschedule = false
      this.listActived = !this.listActived;
      $(function () {
        this.table = $("#userTicket").DataTable({
          responsive: false,
          // "order": [[ 6, 'asc' ]],
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  stateBadge(data: any, estado: any, posicion: any) {
    if (estado != 10 && estado != 11) {
      if (data >= 1 && data <= 6) {
        this.ticketsBeneficiario[posicion][13] = "Leve";
        return "badge badge-leve"; // am
      } else if (data >= 7 && data <= 13) {
        this.ticketsBeneficiario[posicion][13] = "Moderado";
        return "badge badge-warning"; //
      } else if (data >= 14) {
        this.ticketsBeneficiario[posicion][13] = "Critico";
        return "badge badge-danger"; // rj
      }
    } else {
      this.ticketsBeneficiario[posicion][13] = "Cerrado";
      return "badge badge-success";
    }
  }
  stateBadge2(data: any) {
    if (data == "Agendado") {
      return "badge badge-warning";
    } else if (data == "Terminado") {
      return "badge badge-success";
    } else if (data == "Ejecución") {
      return "badge badge-leve";
    } else {
      return "badge badge-warning";
    }
  }
  formatt(item) {
    return moment(item).format('YYYY/MM/DD HH:mm')
  }

  openEditQuestion(content:string, id_ticket:string, option:number) {
    // option 1 es el modal de ticket por la tanto el 2 del mantenimiento
  this.spinner.show()
    if (option == 1) {
      const pdata1 = { option: "Update_ticket", ticket: id_ticket };
      $.ajax({
        data: pdata1,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data, textStatus, jQxhr) {
          console.warn("bien", data, textStatus);
        },
        error(jqXhr, textStatus, errorThrown) {
          console.warn("mal");
        },
      })
        .then((response) => {
          console.log(response, "este es el que necesitAAo");
          this.Historial = response;
          this.banderaActualizar = true;
            // this.Historial.filter(
            //   (e) => e[3] == "11" || e[3] == "10" || e[3] == "6" || e[3] == "7"
            // ).length > 0
            //   ? true
            //   : false;
          // console.log("estes es mi console", this.banderaActualizar);
          const pdata2 = { option: "files", ticket: id_ticket };
          $.ajax({
            data: pdata2,
            cache: false,
            dataType: "json",
            type: "post",
            url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
            success(data2, textStatus2, jQxhr2) {
              console.warn("bien", data2, textStatus2);
            },
            error(jqXhr2, textStatus2, errorThrown2) {
              console.warn("mal");
            },
          })
            .then((response2) => {
              console.log("este mismo es ", response2);
              this.Archivos = response2;
              // this.flagS = true;
              this.spinner.hide();
            })
            .catch((err) => {});
        })
    } else if (option == 2) {
      const pdata11 = { option: 'Update_mantenimiento', mantenimiento: id_ticket };
        this.global.consultar(pdata11, async (err,response) => {
          this.Historial = await response;
         const pdate = {option: 'insertar2', texto: `SELECT * FROM db_liwa.Tecnico  WHERE instalMant = '${this.Historial[0][1]}';`}
         this.global.consultar( pdate, (err, respuesta) => {
           if (respuesta.length > 0) {
            this.banderaComentaruioTecnico = true
           this.comentariaTecnico = respuesta[0][45]
           }
           console.log(respuesta, 'la historico')
         })
        //  console.log('estettttttttt', moment(this.Historial[0][27]).format('YYYY/MM/DD HH:mm') )
        });
    }
    this.spinner.hide()
    this.modalService
      .open(content, { ariaLabelledBy: "modal-editQuestion" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.Historial = [] // esto es por buena practica limpio el estado de la bariable pero no es necesario
          this.banderaComentaruioTecnico = false
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openDescripcion(content, itemValue) {
    this.comentario = itemValue;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-descripcion" });
  }

  verdetalle(id){
    const pdata12 = {
      option: "insertar2",
      texto:
        `SELECT M.*, U.UserName,
         (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical),
          (db_liwa.getdays(M.LastInsert, NOW())) AS Semaforo,
          (SELECT Vereda FROM db_liwa.AOM  WHERE id = M.Id_Beneficiario ) as vereda,
          (SELECT Nombre_Completo FROM db_liwa.AOM  WHERE id = M.Id_Beneficiario ) as NombreBeneficiario
           FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.Id_Beneficiario = U.Id_User Where
            (SELECT C.Id_Client FROM db_liwa.tipoProyecto C Where C.Id_Client = ${this.global.Id_Client} and C.id_tipoProyecto = M.idProyecto)
            AND M.IsDelete=0 AND M.Id_Maintenance = "${id}";`,
    };
    this.global.consultar(pdata12, async (err9, response9) => {
      if (response9) {
        this.global.Id_Mantenimiento = response9[0];
        this.ruta.navigateByUrl("/ver-visita");
      } else if (response9 == undefined || response9 == null) {
        this.alerta.descErr(
          "Lastimosamente el registro aun no se encuentra!"
        );
      }

    })
  }
  modificar(){
    this.spinner.show();
    const modifica = { option: "insertar3", texto: `UPDATE db_liwa.Tickets_New SET Id_Statuses = "2", Menu = "${this.valorMenu1}", SubMenu1  = "${this.valorSubMenu1}", SubMenu2 = "${this.valorSubMenu2}" , SubMenu3 = "${this.valorSubMenu3}", Description = "${this.descriptionBenefit}" WHERE  Id_Tickets = "${this.global.Id_Ticket}";`};
    this.global.consultar(modifica, async(errm, responsem) => {
      if (errm == null && responsem == true) {
        const Update = { option: "insertar3", texto: `INSERT INTO db_liwa.Update_Tickets (Id_Ticket,Description,Id_Statuses,Date,IsDelete,IsActive,Id_user) VALUES ("${this.global.Id_Ticket}","Cambio de tipificación generada por asesor.","2","${moment().format("YYYY-MM-DD HH:mm:ss")}",0,1,"${this.global.Id_Usuario}")`};
        this.global.consultar(Update, async(errU, responseU) => {
          if (errU == null && responseU == true) {
            this.alerta.descValid(`Se modifico con exito la tipificacion del ticket: ${this.global.Id_Ticket}.`);
            this.spinner.hide();
            this.ngOnInit();
          }else{
            this.alerta.descErr('Se encontro algun error, intenta de nuevo.')
            this.spinner.hide();
            this.ngOnInit();
          }
        });
      }else{
        this.alerta.descErr('Se encontro algun error, intenta de nuevo.')
        this.spinner.hide();
        this.ngOnInit();
      }
    });
  }

  handleChangeSubMenu1() {
    //  console.log('mis vales',this.valorMenu1 )

    this.banderaCrearTicket = false;

    this.opcionSubMenu2ReconexionFallida = false;
    this.opcionSubMenu2Pagos = false;
    this.opcionSubMenu2Danos = false;
    this.opcionSubMenu2acceso = false;
    this.optioSubMenu2SolicitudAplicaciones = false;

    this.opcionSubMenu2TipoTramite = false;
    this.opcionSubMenu2TipoRespuesta = false;
    this.opcionSubMenu2ReconexionFallida = false;
    this.opcionSubMenu2Pagos = false;
    this.opcionSubMenu2Danos = false;
    this.opcionSubMenu2acceso = false;
    this.optioSubMenu2SolicitudAplicaciones = false;

    this.opcionSubMenu3Gestor = false;
    this.opcionSubMenu3Panel = false;
    this.opcionSubMenu3Tarjeta = false;
    this.opcionSubMenu3TipoRespuesta = false;
    this.opcionSubMenu3TipoTramite = false;
    this.valorSubMenu1 = "-";
    this.valorSubMenu2 = "-";
    this.valorSubMenu3 = "-";

    switch (this.valorMenu1) {
      case "Comprobantes":
        this.opcionComprobantes = true;
        this.opcionNovedad = false;
        this.opcionPagos = false;
        this.opcionSuspension = false;
        this.opcionPqrs = false;
        this.opcionSubMenu2llamadaOutbound = false;
        break;
      case "Novedad Solucion":
        this.opcionNovedad = true;
        this.opcionComprobantes = false;
        this.opcionPagos = false;
        this.opcionSuspension = false;
        this.opcionPqrs = false;
        this.opcionSubMenu2llamadaOutbound = false;
        break;
      case "Pagos":
        this.opcionPagos = true;
        this.opcionComprobantes = false;
        this.opcionNovedad = false;
        this.opcionSuspension = false;
        this.opcionPqrs = false;
        this.opcionSubMenu2llamadaOutbound = false;
        break;
      case "Suspension":
        this.opcionSuspension = true;
        this.opcionComprobantes = false;
        this.opcionNovedad = false;
        this.opcionPagos = false;
        this.opcionPqrs = false;
        this.opcionSubMenu2llamadaOutbound = false;
        break;
      case "Pqrs":
        this.opcionPqrs = true;
        this.opcionSuspension = false;
        this.opcionComprobantes = false;
        this.opcionNovedad = false;
        this.opcionPagos = false;
        this.opcionSubMenu2llamadaOutbound = false;
        break;
      case "llamada outbound":
        this.opcionSubMenu2llamadaOutbound = true;
        this.opcionPqrs = false;
        this.opcionSuspension = false;
        this.opcionComprobantes = false;
        this.opcionNovedad = false;
        this.opcionPagos = false;
        break;
      default:
        this.opcionSubMenu2llamadaOutbound = false;
        this.opcionComprobantes = false;
        this.opcionNovedad = false;
        this.opcionPagos = false;
        this.opcionSuspension = false;

        break;
    }
  }
  handleChangeSubMenu2() {
    console.log('mis vales 2 ', this.valorSubMenu1)
    this.banderaCrearTicket = false;
    this.valorSubMenu2 = "";

    this.opcionSubMenu2ReconexionFallida = false;
    this.opcionSubMenu2Pagos = false;
    this.opcionSubMenu2Danos = false;
    this.opcionSubMenu2acceso = false;
    this.optioSubMenu2SolicitudAplicaciones = false;


    this.opcionSubMenu2TipoTramite = false;
    this.opcionSubMenu2TipoRespuesta = false;
    this.opcionSubMenu2ReconexionFallida = false;
    this.opcionSubMenu2Pagos = false;
    this.opcionSubMenu2Danos = false;
    this.opcionSubMenu2acceso = false;
    this.optioSubMenu2SolicitudAplicaciones = false;
    this.opcionSubMenu2Prestacion = false;
    this.opcionSubMenu2Facturacion = false;
    this.opcionSubMenu3Gestor = false;
    this.opcionSubMenu3Panel = false;
    this.opcionSubMenu3Tarjeta = false;
    this.opcionSubMenu3TipoRespuesta = false;
    this.opcionSubMenu3TipoTramite = false;

    switch (this.valorSubMenu1) {
      case "Pagos":
        this.opcionSubMenu2Pagos = true;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "disponibilidad de servicio":
        this.opcionSubMenu2Pagos = true;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Reconexcion Fallida":
        this.opcionSubMenu2ReconexionFallida = true;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Estado de cuenta mes actaul":
        this.opcionSubMenu2Pagos = true;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Estado de cuenta mes anterios":
        this.opcionSubMenu2Pagos = true;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Estado de cuenta mes post anterior":
        this.opcionSubMenu2Pagos = true;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Daño":
        this.opcionSubMenu2Danos = true;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Traslado":
        this.opcionSubMenu2Danos = true;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Acceso restringido":
        this.opcionSubMenu2acceso = true;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2Pagos = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Solicitud aplicacion fecha de pago":
        this.optioSubMenu2SolicitudAplicaciones = true;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Tipo de Tramite":
        this.opcionSubMenu2TipoTramite = true;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Tipo de Respuesta":
        this.opcionSubMenu2TipoRespuesta = true;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Facturacion":
        this.opcionSubMenu2Facturacion = true;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2Prestacion = false;
        break;
      case "Prestacion":
        this.opcionSubMenu2Prestacion = true;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        this.opcionSubMenu2TipoTramite = false;
        break;
      case "Pago no reflejado":
      case "Usuario no habilitado":
      case "Recaudacion sin servicio":
      case "Acuerdo de pago":
      case "no contesta":
      case "ocupado":
      case "telefono dañado":
      case "buzon de Mensajes":
      case "equivocado":
      case "llamada de Bienvenida":
        this.banderaCrearTicket = true;
        break;
      default:
        this.opcionSubMenu2Prestacion = false;
        this.opcionSubMenu2Facturacion = false;
        this.opcionSubMenu2TipoTramite = false;
        this.opcionSubMenu2TipoRespuesta = false;
        this.opcionSubMenu2ReconexionFallida = false;
        this.opcionSubMenu2Pagos = false;
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2acceso = false;
        this.optioSubMenu2SolicitudAplicaciones = false;
        break;
    }
  }
  handleChangeSubMenu3() {
    switch (this.valorSubMenu2) {
      case "Gestor":
        this.opcionSubMenu3Gestor = true;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3TipoTramite = false;
        break;
      case "Panel con daño fisico":
        this.opcionSubMenu3Panel = true;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Tarjeta = false;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3TipoTramite = false;
      case "otro":
        this.banderaCrearTicket = true;

        this.opcionSubMenu3Panel = true;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Tarjeta = false;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3TipoTramite = false;
      case "sin servicio":
        this.opcionSubMenu3Panel = true;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Tarjeta = false;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3TipoTramite = false;
        break;
      case "Tarjeta datos":
        this.opcionSubMenu3Tarjeta = true;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3TipoTramite = false;
        break;
      case "Reclamacion":
        this.opcionSubMenu3TipoTramite = true;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        break;
      case "Queja":
        this.opcionSubMenu3TipoTramite = true;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        break;
      case "Recursos de reposicion":
        this.opcionSubMenu3TipoTramite = true;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        break;
      case "Recurso de reposicion y subsidio de apelacion":
        this.opcionSubMenu3TipoTramite = true;
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        break;
      case "Accede":
      case "Accede parcialmente":
      case "No accede":
      case "Confirma":
      case "Modifica":
      case "Revoca":
      case "Rechaza":
      case "Translada competencias":
      case "Pendiente de respuesta":
      case "Sin respuesta":
      case "Archiva":
        this.opcionSubMenu3TipoRespuesta = true;
        this.opcionSubMenu3TipoTramite = false;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        break;
      case "Envio comprobante":
      case "Tecnico (CRM)":
      case "Inconformidad con el aforo":
        this.codigo = 101;
        this.banderaCrearTicket = true;
        break
      case "Inconformidad con la medicion del consumo o produccion facturado":
        this.codigo = 102;
        this.banderaCrearTicket = true;
        break
      case "Cobros inoportunos":
        this.codigo = 103;
        this.banderaCrearTicket = true;
        break
      case "cobro por servicios no prestados":
        this.codigo = 105;
        this.banderaCrearTicket = true;
        break
      case "datos generales incorrectos":
        this.codigo = 106;
        this.banderaCrearTicket = true;
        break
      case "cobro multiple y/o acumulado":
        this.codigo = 107;
        this.banderaCrearTicket = true;
        break
      case "entrega inoportuna o no entrega de factura":
        this.codigo = 108;
        this.banderaCrearTicket = true;
        break
      case "cobros por conexión, reconexion, reinstalacion":
        this.codigo = 109;
        this.banderaCrearTicket = true;
        break
      case "incorformidad con el cambio o con el cobro del medidor":
        this.codigo = 110;
        this.banderaCrearTicket = true;
        break
      case "cobro de cargos relacionados con intereses de mora, refinanciacion, cartera o acuerdos de pago":
        this.codigo = 111;
        this.banderaCrearTicket = true;
        break
      case "subsidios y contribuciones":
        this.codigo = 112;
        this.banderaCrearTicket = true;
        break
      case "cobro de otros bienes o servicios en la factura no autorizados por el usuario":
        this.codigo = 113;
        this.banderaCrearTicket = true;
        break
      case "incumplimiento o negacion del acuerdo o suspension del servicio":
        this.codigo = 115;
        this.banderaCrearTicket = true;
        break
      case "estrato incorrecto":
        this.codigo = 117;
        this.banderaCrearTicket = true;
        break
      case "clase de uso incorrecto (industrial, comercial, oficial, otros)":
        this.codigo = 118;
        this.banderaCrearTicket = true;
        break
      case "tarifa incorrecta":
        this.codigo = 119;
        this.banderaCrearTicket = true;
        break
      case "costos por promedio":
        this.codigo = 120;
        this.banderaCrearTicket = true;
        break
      case "cobro de consumo registrado por medidor de otro promedio":
        this.codigo = 121;
        this.banderaCrearTicket = true;
        break
      case "suscriptor que efectua el pago pero este no es aplicado por la empresa en la facturacion":
        this.codigo = 122;
        this.banderaCrearTicket = true;
        break
      case "solicitud de rompimiento de solidaridad":
        this.codigo = 123;
        this.banderaCrearTicket = true;
        break
      case "cobro de revisiones":
        this.codigo = 124;
        this.banderaCrearTicket = true;
        break
      case "inconformidad por desviacion significativa":
        this.codigo = 127;
        this.banderaCrearTicket = true;
        break
      case "cobro por recuperacion de consumos":
        this.codigo = 129;
        this.banderaCrearTicket = true;
        break
      case "inconformidad por la normalizacion del servicio":
        this.codigo = 131;
        this.banderaCrearTicket = true;
        break
      case "cobro por reconexion no autorizada porla empresa":
        this.codigo = 133;
        this.banderaCrearTicket = true;
        break
      case "negacion de la solicitud de suspension":
        this.codigo = 301;
        this.banderaCrearTicket = true;
        break
      case "Interrupciones en la prestacion del servicio":
        this.codigo = 303;
        this.banderaCrearTicket = true;
        break
      case "Variaciones en las caracteristicas del suministro o prestacion del servicio":
        this.codigo = 304;
        this.banderaCrearTicket = true;
        break
      case "No atencion de condiciones de seguridad o riesgo":
        this.codigo = 306;
        this.banderaCrearTicket = true;
        break
      case "Terminacion del contrato":
        this.codigo = 308;
        this.banderaCrearTicket = true;
        break
      case "Suspension o corte del servicio sin previo aviso o si causa aparente":
        this.codigo = 309;
        this.banderaCrearTicket = true;
        break
      case "Quejas administrativas":
        this.codigo = 315;
        this.banderaCrearTicket = true;
        break
      case "Estado de la infraestructura":
        this.codigo = 316;
        this.banderaCrearTicket = true;
        break
      case "Fallas en la conexión del servicio":
        this.codigo = 401;
        this.banderaCrearTicket = true;
        break
      case "No conexión del servicio":
        this.codigo = 402;
        this.banderaCrearTicket = true;
        break
      case "Hurto parte UC":
        this.banderaCrearTicket = true;
        break
      case "Hurto solucion":
        this.banderaCrearTicket = true;
        break
      case "Cambio de titularidad":
        this.banderaCrearTicket = true;
        break
      case "Desistimiento":
        this.banderaCrearTicket = true;
        break
      case "Reposición":
        this.banderaCrearTicket = true;
        break
      case "Garantía":
        this.banderaCrearTicket = true;
        break
      case "Orden Social":
        this.banderaCrearTicket = true;
        break
      case "Orden público":
        this.banderaCrearTicket = true;
        break
      case "% Carga Bateria":
      case "Apertura gabinete":
      case "Datos Tension":

      case "Capacidad de pago":
      case "Olvido":
      case "TReclamacion pendiente":
      case "No recibio factura":
      case "Agendamiento visita":
        this.banderaCrearTicket = true;
        break;
      default:
        this.banderaCrearTicket = true
        this.opcionSubMenu3TipoRespuesta = false;
        this.opcionSubMenu3TipoTramite = false;
        this.opcionSubMenu3Gestor = false;
        this.opcionSubMenu3Panel = false;
        this.opcionSubMenu3Tarjeta = false;
        break;
    }
  }
  handleChangeDescriction() {
    this.valorSubMenu3 ? (this.banderaCrearTicket = true) : console.log();
  }
}
