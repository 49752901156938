import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-comunicaciones',
  templateUrl: './comunicaciones.component.html',
  styleUrls: ['./comunicaciones.component.scss']
})
export class ComunicacionesComponent implements OnInit {
  phone;
  message;
  clientClass;

  constructor(private spinner: NgxSpinnerService, 
              private alert: GlobalAlertService, 
              private global: GlobalService, 
              private route: Router) {  
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
  }
  goBack(){
    window.history.back();
  }
  sendSms(){
    if(this.message == undefined ||  this.phone == undefined ){
      this.alert.descErr();
    }else{
        this.spinner.show();  
        let messageModel= {
          "number":"57"+this.phone.toString(),
          "message":this.message
        }


        console.log(messageModel);
        setTimeout(() => {
          this.global.sendMessage(messageModel).subscribe((response:any) => {
           
            if(response.success == true){
              this.alert.descValid('El mensaje se envió correctamente');
              this.phone ='';
              this.message = '';
              this.spinner.hide();
            }else{
              this.alert.descErr('Error al enviar msj, intente nuevamente');
              this.phone='';
              this.message = '';
              this.spinner.hide();
            }
          
          
          })
           
        }, 60);

        }
     
  } 

}
