import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pipeline-mayo',
  templateUrl: './pipeline-mayo.component.html',
  styleUrls: ['./pipeline-mayo.component.scss']
})
export class PipelineMayoComponent implements OnInit {
  clientClass;
  Items = new Array();
  listActive;
  mapActive;
  sena = new Array();
  latitude = 6.1840589;
  longitude = -67.5008332;
  zoom = 12.5;
  Style;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    setTimeout(() => {
      this.Style = this.global.mapStyle;
      const pdata9 = { option: 'mayo' };
      this.global.consultar(pdata9, (err9, response9) => {
        console.log('Datos pipeline', response9);
        this.sena = response9;
        $(function () {
          this.table = $('#carrenoTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
        this.isList();
      });
    }, 1500);
  }
  ver(data) {
    console.log(data);
    this.global.selectedSede = data;
    this.ruta.navigateByUrl('/ver-sede');
  }

  isList() {
    this.spinner.show();
    if (this.mapActive == false) {
      this.listActive = !this.listActive;
      setTimeout(() => {
        $(function () {
          this.table = $('#carrenoTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      }, 60);
    } else {
      this.mapActive = false;
      this.listActive = true;
      this.spinner.hide();
      //MAPA
    }
  }

  isMap() {
    this.spinner.show();
    if (this.listActive == false) {
      this.mapActive = !this.mapActive;
      this.spinner.hide();
      //MAPA
    } else {
      this.listActive = false;
      this.mapActive = true;
      setTimeout(() => {
        $(function () {
          this.table = $('#carrenoTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      }, 60);
    }
  }

  irSede(sede) {
    this.global.selectedSede = sede;
    this.ruta.navigateByUrl('/ver-sede');

  }

}
