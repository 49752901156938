import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Router } from '@angular/router';
// import Chart from 'chart.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { GlobalAlertService } from 'src/app/global-alert.service';


@Component({
  selector: 'app-inventario-de-red',
  templateUrl: './inventario-de-red.component.html'
})
export class InventarioDeRedComponent implements OnInit {
  clientClass;
  constructor(private spinner: NgxSpinnerService,
    private route: Router,
    private global: GlobalService,) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
  }

  goList(data) {
    switch (data) {
      case '1':
        this.route.navigateByUrl('/listado-preliminar-redes-nazareth');
        break;
      case '2':
        this.route.navigateByUrl('/listado-definitivo-redes-nazareth');
        break;

      default:
        break;
    }
  }

}
