import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ExcelService } from 'src/app/excel.service';
import { GlobalService } from 'src/app/global.service';
import { GlobalAlertService } from 'src/app/global-alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gensa-puerto-carreno',
  templateUrl: './gensa-puerto-carreno.component.html'
})
export class GensaPuertoCarrenoComponent implements OnInit {
  clientClass;
  records: any[] = [];
  pines: any[] = [];
  tableOn: boolean = false;
  Toexcel;
  Excel;
  listActive: boolean = true;
  mapActive: boolean = false;
  latitude = 4.66125;
  longitude = -73.994817;
  zoom: number = 6;
  Style: any[];
  previous;
  constructor(public global: GlobalService,
    private route: Router,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.Style = this.global.mapStyle;
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata2 = { option: 'Gpuerto_carreno' };
      this.global.consultar(pdata2, (err2, response2) => {
        this.records = response2;
        console.log('Gpuerto_carreno', this.records);
        const pdata3 = { option: 'Gpuerto_carreno_mapa' };
        this.global.consultar(pdata3, (err3, response3) => {
          this.pines = response3;
          try {
            const pdata10 = { option: 'excelpc' };
            this.global.consultar(pdata10, (err10, response10) => {
              console.log('LISTADO EXCEL', response10);
              this.Excel = response10;
              $(function () {
                this.table = $('#TGensaPtoCarreno').DataTable({
                  'responsive': false,
                  'destroy': true,
                  'retrieve': true,
                  'paging': true,
                  'info': false,
                  'pagingType': 'numbers',
                  'language': {
                    'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                  }
                });
              });
              this.spinner.hide();
              this.tableOn = true;
            });
          } catch (error) {
            console.log(error);
          }
          console.log('Gpuerto_carreno_mapa', this.pines);
        });
      });
    }, 200);
  }

  downloadExcel() {
    console.log('Excel', this.Excel);
    this.Toexcel = [['Id', 'Id_Formulario', 'Id_Beneficiario', 'Fecha', 'U_latitud', 'U_longitud', 'U_altitud', 'Nombre', 'TipoDoc', 'NumeroDoc', 'Telefono', 'Email', 'Ciudad', 'Ocupacion', 'Operador', 'Cobertura', 'Depto', 'Municipio', 'Vereda', 'Descripcion', 'Marca', 'Referencia', 'Serial', 'Tipo', 'Estado', 'Vivienda', 'Material', 'Otro_V', 'Uso', 'Ubicacion', 'Otro_U', 'Apartado', 'Altura', 'Cableado', 'Caja', 'Equipo_M', 'Riesgo', 'Pago_Consumo', 'Esquema', 'Calidad', 'Percepcion', 'Credito', 'NuevoMedidor', 'UltimaLectura', 'LastInsert']];
    for (let p = 0; p < this.Excel.length; p++) {
      this.Toexcel.push(this.Excel[p]);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Listado ', 'Listado');
      setTimeout(() => {
        this.Toexcel = [['Id', 'Id_Formulario', 'Id_Beneficiario', 'Fecha', 'U_latitud', 'U_longitud', 'U_altitud', 'Nombre', 'TipoDoc', 'NumeroDoc', 'Telefono', 'Email', 'Ciudad', 'Ocupacion', 'Operador', 'Cobertura', 'Depto', 'Municipio', 'Vereda', 'Descripcion', 'Marca', 'Referencia', 'Serial', 'Tipo', 'Estado', 'Vivienda', 'Material', 'Otro_V', 'Uso', 'Ubicacion', 'Otro_U', 'Apartado', 'Altura', 'Cableado', 'Caja', 'Equipo_M', 'Riesgo', 'Pago_Consumo', 'Esquema', 'Calidad', 'Percepcion', 'Credito', 'NuevoMedidor', 'UltimaLectura', 'LastInsert']];
      }, 1000);
    }, 1500);
  }

  detalle(index, idform, nuevoMed) {
    console.log(nuevoMed);
    this.global.idBenefit = index;
    this.global.idForm = idform;
    this.global.nuevoMed = nuevoMed;
    this.global.onlyView = false;
    this.route.navigateByUrl('/formulario-puerto-carreno');
  }
  view(index, idform, nuevoMed) {
    console.log(nuevoMed);
    this.global.idBenefit = index;
    this.global.idForm = idform;
    this.global.nuevoMed = nuevoMed;
    this.global.onlyView = true;
    this.route.navigateByUrl('/formulario-puerto-carreno');
  }
  isList() {
    if (this.listActive == false) {
      this.listActive = true;
      this.mapActive = false;
      this.spinner.show();
      setTimeout(() => {
        $(function () {
          this.table = $('#TGensaPtoCarreno').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      }, 60);
    } else {
      this.listActive = false;
    }
  }
  isMap() {
    if (this.mapActive == false) {
      this.mapActive = true;
      this.listActive = false;
    } else {
      this.mapActive = false;
    }
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
}
