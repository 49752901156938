import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
})
export class FacturacionComponent implements OnInit {
  medidores = [];
  CentroCostos = new Array();
  Productos = new Array();
  MEDIDORESEXCEL;
  clientClass;
  seccionales = new Array();
  months = ['Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  Subir = false;
  Registrar = false;
  MesSel;
  AnoSel;
  IdSeccional;
  IdProducto;
  IdCentroCostos;
  VFacturar;
  VCU;
  VSubsidio;
  Cantidad;
  id_User;
  CodeProducto;
  VMCU;
  Oportuno;
  Suspende;
  Pe_inicio;
  Pe_final;
  newid;
  Cm;
  Dm;
  Gm;
  MesSelm;
  AnoSelm;
  IdSeccionalm;
  IdProductom;
  IdCentroCostosm;
  VFacturarm;
  VCUm;
  VSubsidiom;
  Cantidadm;
  id_Userm;
  CodeProductom;
  VMCUm;
  Oportunom;
  Suspendem;
  Pe_iniciom;
  Pe_finalm;
  Cmm;
  Dmm;
  Gmm;
  constructor(public global: GlobalService,
    public ruta: Router,
    public modalService: NgbModal,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    console.log(this.clientClass);
    this.id_User = this.global.CLIENTDATALIWA[0];
    const pdata1 = { option: 'insertar2', texto: 'SELECT * FROM db_liwa.Solicitudes_Facturacion WHERE IsDelete = 0' };
    console.log('listado', pdata1);
    this.global.consultar(pdata1, (err1, response1) => {
      console.log('Datos listado', response1);
      this.medidores = response1;
      $(function () {
        this.table = $('#seccionalTable').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
      const pdata5 = { option: 'consultar-costos' };
      console.log("UP", pdata5);
      this.global.consultar_siigo(pdata5, async (err5, response5) => {
        console.log("CENTROS DE COSTOS", response5);
        this.CentroCostos = new Array();
        for (let index = 0; index < response5.length; index++) {
          console.log(response5[index]['name'], response5[index]['name'].indexOf("COMERCIAL"));
          var veri = response5[index]['name'].indexOf("COMERCIAL");
          if (veri == -1) {

          } else {
            this.CentroCostos.push(response5[index]);
          }
        }
        const pdata6 = { option: 'consultar-productos' };
        console.log("UP", pdata6);
        this.global.consultar_siigo(pdata6, async (err6, response6) => {
          console.log("PRODUCTOS", response6['results']);
          this.Productos = new Array();;
          for (let index = 0; index < response6['results'].length; index++) {
            console.log(response6['results'][index]['name'], response6['results'][index]['name'].indexOf("ZNI"));
            var veri = response6['results'][index]['name'].indexOf("ZNI");
            if (veri == -1) {

            } else {
              this.Productos.push(response6['results'][index]);
            }
          }
          const pdata4 = { option: 'insertar2', texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '47' AND IsDelete=0 AND IsActive=1;` };
          this.global.consultar(pdata4, async (err4, response4) => {
            let res: any[] = await response4;
            if (res.length == undefined || res.length == 0) {
              this.seccionales = [];
            } else {
              this.seccionales = response4;
            }
          });
        });
      });
    });
  }

  SubirExcel() {
    this.Subir = true;
  }
  arrayBuffer: any;
  file: File;
  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Confirmar(){
    this.spinner.show();
    var query = "INSERT INTO db_liwa.Solicitudes_Facturacion (`id_user`,`mes`,`ano`,`id_seccional`,`id_producto`,`id_centro_costos`,`valor_dia`,`cantidad`, `subsidio`,`prestacion`,`estado`, `code_producto`,cu_por_mes,pago_oportuno,suspension,periodo_inicio,periodo_final,Gm,Dm,Cm) VALUES ('"+ this.id_User +
    "','"+ this.MesSel +
    "','"+ this.AnoSel +
    "','"+ this.IdSeccional +
    "','"+ this.IdProducto +
    "','"+ this.IdCentroCostos +
    "','"+ this.VFacturar +
    "','"+ this.Cantidad +
    "','"+ this.VSubsidio+
    "','"+ this.VCU +"','0',"+this.CodeProducto+","+this.VMCU+",'"+moment(this.Oportuno).format('YYYY-MM-DD HH:mm:ss') +"','"+moment(this.Suspende).format('YYYY-MM-DD HH:mm:ss')+"','"+moment(this.Pe_inicio).format('YYYY-MM-DD HH:mm:ss')+"','"+moment(this.Pe_final).format('YYYY-MM-DD HH:mm:ss')+"','"+this.Gm+"','"+this.Dm+"','"+this.Cm+"');";
    const pdatac = { option: 'insertar', texto: query };
    console.log("DATA", pdatac)
    this.global.consultar(pdatac, async (err4, response4) => {
      console.log("RES", response4)
      if(response4){
        this.alerta.descValid('Registro Facturado Correctamente!');
        this.ngOnInit();
        this.MesSel = " ";
        this.AnoSel = " ";
        this.IdSeccional = " ";
        this.IdProducto = " ";
        this.IdCentroCostos = " ";
        this.VFacturar = " ";
        this.Cantidad = " ";
        this.VSubsidio = " ";
        this.VCU = " ";
        this.CodeProducto = " ";
        this.VMCU = " ";
        this.Oportuno = " ";
        this.Suspende = " ";
        this.Pe_inicio = " ";
        this.Pe_final = " ";
        this.Gm = " ";
        this.Dm = " ";
        this.Cm = " ";
        $(function () {
          this.table = $('#seccionalTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      }else if(response4 == null || response4 == undefined){
        this.alerta.descErr('Ups.. Error intente de nuevo.');
        this.ngOnInit();
        this.spinner.hide();
      }
    });


  }
  prueba(){
    const data = {option:"APPP"}
    setTimeout(() => {
      this.global.consultar_siigo(data, async (err7, response7) => {
        console.log("Prueba", response7);
        if(response7){
          this.spinner.hide();
          this.alerta.descValid('Proceso iniciado,Prueba!');
          this.ngOnInit();
        }else if(response7 == null || response7 == undefined){
          this.spinner.hide();
          this.alerta.descErr('Error, por favor intente de nuevo!');
        }
      });
    }, 60);
  }

  RegistrarMedidores() {
    let masivo = new Array();
    /*
    Apellidos: "Hernandez Osorio"
    Cedula: 1000687826
    Comunidad: "NA"
    Departamento: "Boyacá"
    Latitud: 1223456
    Longitud: 314234235
    Municipio: "Tunja"
    Nombres: "Miguel Felipe"
    Vereda: "Centro"
    */
    if (this.Registrar == true) {
      for (let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
        masivo.push({
          identificacion: this.MEDIDORESEXCEL[i].identification,
          name: this.MEDIDORESEXCEL[i].name,
          last_name: this.MEDIDORESEXCEL[i].last_name,
          address: this.MEDIDORESEXCEL[i].address,
          state_code: this.MEDIDORESEXCEL[i].state_code,
          city_code: this.MEDIDORESEXCEL[i].city_code,
          postal_code: this.MEDIDORESEXCEL[i].postal_code,
          tel: this.MEDIDORESEXCEL[i].number,
          correo: this.MEDIDORESEXCEL[i].email,
          comments: this.MEDIDORESEXCEL[i].comments
        })
      }
      setTimeout(() => {
        console.log('MASIVO DEF', masivo);

        const pdata3 = { option: 'crear-clientes', datos: masivo };
        this.global.consultar_siigo(pdata3, async (err3, response3) => {
          console.log(response3);
        });
      }, 500);
    } else {

    }
  }

  IsActiveView(data) {
    if (data == 1) {
      return 'Activado';
    }
    if (data == 0) {
      return 'Desactivado';
    }
  }
  isActiveBadge(data) {
    if (data == 1) {
      return 'badge badge-success';
    }
    if (data == 0) {
      return 'badge badge-danger';
    }
    this.ngOnInit();
  }

  openEditNumRate(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-editNRate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEdit(content,id) {
    this.newid = id;
    const pdata1 = { option: 'insertar2', texto: 'SELECT * FROM db_liwa.Solicitudes_Facturacion WHERE IsDelete = "0" AND id = "'+id+'";' };
    console.log('listado', pdata1);
    this.global.consultar(pdata1, (err1, response1) => {
      console.log('Datos listado', response1);
      if(response1.length == 0){
      }else{
        this.modalService.open(content, { ariaLabelledBy: 'modal-editNRate' }).result.then((result) => {
          // this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.MesSelm = response1[0][2];
        this.AnoSelm = response1[0][3];
        this.IdSeccionalm = response1[0][4];
        this.IdProductom = response1[0][5];
        this.IdCentroCostosm = response1[0][6];
        this.VFacturarm = response1[0][7];
        this.VSubsidiom = response1[0][9];
        this.VCUm = response1[0][10];
        this.VMCUm = response1[0][17];
        this.Cantidadm = response1[0][8];
        this.Oportunom = moment(response1[0][18]).format('yyyy-MM-DD');
        this.Suspendem = moment(response1[0][19]).format('yyyy-MM-DD');
        this.Pe_iniciom = moment(response1[0][20]).format('yyyy-MM-DD');
        this.Pe_finalm = moment(response1[0][21]).format('yyyy-MM-DD');
        this.Gmm = response1[0][22];
        this.Dmm = response1[0][23];
        this.Cmm = response1[0][24];
      }
    });
  }

  modificarsol(){
    this.AsignarCode();
    var query = "UPDATE  db_liwa.Solicitudes_Facturacion  SET mes ='"+ this.MesSelm +
    "', ano ='"+ this.AnoSelm +
    "', id_seccional ='"+ this.IdSeccionalm +
    "', id_producto ='"+ this.IdProductom +
    "', id_centro_costos ='"+ this.IdCentroCostosm +
    "', valor_dia ='"+ this.VFacturarm +
    "', cantidad ='"+ this.Cantidadm +
    "', subsidio ='"+ this.VSubsidiom+
    "', prestacion='"+ this.VCUm +"', code_producto ='"+this.CodeProductom+"', cu_por_mes='"+this.VMCUm+"', pago_oportuno ='"+moment(this.Oportunom).format('YYYY-MM-DD HH:mm:ss') +"', suspension ='"+moment(this.Suspendem).format('YYYY-MM-DD HH:mm:ss')+"', periodo_inicio ='"+moment(this.Pe_iniciom).format('YYYY-MM-DD HH:mm:ss')+"', periodo_final='"+moment(this.Pe_finalm).format('YYYY-MM-DD HH:mm:ss')+"', Gm ='"+this.Gmm+"', Dm ='"+this.Dmm+"', Cm= '"+this.Cmm+"' WHERE id = '"+this.newid+"';";
    const pdatam = { option: 'insertar', texto: query };
    console.log(query)
    this.global.consultar(pdatam, async (err4, response4) => {
      console.log("RES", response4)
      if(response4){
        this.alerta.descValid('Se realizo la modificacion Correctamente!');
        this.ngOnInit();
        $(function () {
          this.table = $('#seccionalTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      }else if(response4 == null || response4 == undefined){
        this.alerta.descErr('Ups.. Error intente de nuevo.');
        this.ngOnInit();
        this.spinner.hide();
      }
    });
  }

  deleteSec(idSec) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el registro?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idSec);
      } else {
        this.ngOnInit();
      }
    });
  }


  EnviarAprobacion(Info){
    this.spinner.show();
    console.log("Enviar Aprobación", Info);
    let data = {
      option: 'enviar-aprobacion',
      mes: Info[2],
      ano: Info[3],
      id_seccional: Info[4],
      name_seccional: this.nombreSeccional(Info[4]),
      id_producto: Info[5],
      id_centro_costos: Info[6],
      code_producto: Info[16],
      name_centro_costos: this.nombreCostos(Info[6]),
      valor_facturar: Info[7],
      cantidad: Info[8],
      valor_subsidio: Info[9],
      valor_prestacion: Info[10]
    }
    console.log('DATA APROBACION', data);
    setTimeout(() => {
    this.global.consultar_siigo(data, async (err7, response7) => {
      console.log("Envio Aprobación", response7);
      if(response7){
        this.spinner.hide();
        this.alerta.descValid('Se envió correo de aprobación correctamente!');
        this.ngOnInit();
      }else if(response7 == null || response7 == undefined){
        this.spinner.hide();
        this.alerta.descErr('Error, por favor intente de nuevo!');
      }
    });
  }, 60);
  }


  GenerarFacturacion(Info){
    this.spinner.show();
    console.log("Enviar Generacion", Info);
    let data = {
      option: 'crear-factura-dian',
      id_solicitud: Info[0],
      mes: Info[2],
      ano: Info[3],
      id_seccional: Info[4],
      name_seccional: this.nombreSeccional(Info[4]),
      id_producto: Info[5],
      id_centro_costos: Info[6],
      code_producto: Info[16],
      name_centro_costos: this.nombreCostos(Info[6]),
      valor_facturar: Info[7],
      cantidad: Info[8],
      valor_subsidio: Info[9],
      valor_prestacion: Info[10]
    }
    console.log('DATA Factura', data);
    setTimeout(() => {
    this.global.consultar_siigo(data, async (err7, response7) => {
      console.log("Envio Factura", response7);
      if(response7){
        // setTimeout(() => {
        //   var query = "UPDATE db_liwa.Solicitudes_Facturacion SET IsActive = '3' WHERE id ='" + Info[0] + "';";
        //   const pdata1 = { option: 'insertar', texto: query };
        //   this.global.consultar(pdata1, async (err4, response4) => {
        //     console.log("RES", response4)
        //     if(response4){
              this.spinner.hide();
              this.alerta.descValid('Proceso iniciado, el resultado se comunicará por correo electronico!');
              this.ngOnInit();
        //     }else if(response4 == null || response4 == undefined){
        //       this.spinner.hide();
        //       this.alerta.descErr('Error, Al Actualizar!');
        //     }
        //   });
        // }, 60);
      }else if(response7 == null || response7 == undefined){
        this.spinner.hide();
        this.alerta.descErr('Error, por favor intente de nuevo!');
      }
    });
  }, 60);
  }

  RegFacturacion(Info){
    this.spinner.show();
    console.log("Enviar Generacion", Info);
    let data = {
      option: 're-factura-dian',
      id_solicitud: Info[0],
      mes: Info[2],
      ano: Info[3],
      id_seccional: Info[4],
      name_seccional: this.nombreSeccional(Info[4]),
      id_producto: Info[5],
      id_centro_costos: Info[6],
      code_producto: Info[16],
      name_centro_costos: this.nombreCostos(Info[6]),
      valor_facturar: Info[7],
      cantidad: Info[8],
      valor_subsidio: Info[9],
      valor_prestacion: Info[10]
    }
    console.log('DATA Factura', data);
    setTimeout(() => {
    this.global.consultar_siigo(data, async (err8, response8) => {
      console.log("Envio Factura", response8);
      if(response8.status == 200){
        // setTimeout(() => {
        //   var query = "UPDATE db_liwa.Solicitudes_Facturacion SET IsActive = '3' WHERE id ='" + Info[0] + "';";
        //   const pdata1 = { option: 'insertar', texto: query };
        //   this.global.consultar(pdata1, async (err4, response4) => {
        //     console.log("RES", response4)
        //     if(response4){
              this.spinner.hide();
              this.alerta.descValid('Proceso iniciado, el resultado se comunicará por correo electronico!');
              this.ngOnInit();
        //     }else if(response4 == null || response4 == undefined){
        //       this.spinner.hide();
        //       this.alerta.descErr('Error, Al Actualizar!');
        //     }
        //   });
        // }, 60);
      }else if(response8.status == 301){
        this.spinner.hide();
        this.alerta.descValid('No se encontraron registros para esta solicitud');
      }else if(response8 == null || response8 == undefined){
        this.spinner.hide();
        this.alerta.descErr('Error, por favor intente de nuevo!');
      }
    });
  }, 60);
  }
  AsignarCode(){
    for (let index = 0; index < this.Productos.length; index++) {
      console.log(this.IdProducto,this.Productos[index]['id'])
      if(this.IdProducto == this.Productos[index]['id']){
        this.CodeProducto = this.Productos[index]['code']
      }
      if(this.IdProductom == this.Productos[index]['id']){
        this.CodeProductom = this.Productos[index]['code']
      }
    }

  }

  nombreSeccional(id){
    for (let index = 0; index < this.seccionales.length; index++) {
      // console.log(id,this.seccionales[index][1])
      if(id == this.seccionales[index][1]){
        return this.seccionales[index][0]
      }
    }
  }

  nombreProducto(id){
    for (let index = 0; index < this.Productos.length; index++) {
      // console.log(id,this.Productos[index]['id'])
      if(id == this.Productos[index]['id']){
        return this.Productos[index]['name']
      }
    }
  }

  nombreCostos(id){
    for (let index = 0; index < this.CentroCostos.length; index++) {
      // console.log(id,this.CentroCostos[index]['id'])
      if(id == this.CentroCostos[index]['id']){
        return this.CentroCostos[index]['name']
      }
    }
  }

  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Solicitudes_Facturacion SET IsDelete = '1' WHERE id ='" + data + "';";
      const pdata1 = { option: 'insertar', texto: query };
      this.global.consultar(pdata1, async (err4, response4) => {
        console.log("RES", response4)
        if(response4){
          this.spinner.hide();
          this.alerta.descValid('Registro Eliminado Correctamente!');
          this.ngOnInit();
        }else if(response4 == null || response4 == undefined){
          this.spinner.hide();
          this.alerta.descErr('Error, por favor intente de nuevo!');
        }
      });
    }, 60);

  }



}
