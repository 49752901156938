import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class GlobalAlertService {
  htmlSwal;

  constructor(public route: Router) {
  }



  ///////////////////////////////////////////////////////////////////// GENERAL ALERT /////////////////////////////////////////////////////////////////////////
  /* METHOD FOR ERROR ALERT   -----   PARAMS: TEXT:STRING */
  descErr(text?: string) {
    if (text == undefined) {
      Swal.fire({
        title: '<strong>Oops...</strong>',
        icon: 'error',
        html: ' <b>Algo anda mal</b>, ' +
          '<h3> Verifique que no haya campos vacíos e intente nuevamente <h3> ',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#00bba4',
        confirmButtonText: '¡Entendido!',
      });
    } else {
      Swal.fire({
        title: '<strong>Oops...</strong>',
        icon: 'error',
        html: ' <b>Algo anda mal</b>, ' +
          '<h3>' + text + '<h3> ',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#00bba4',
        confirmButtonText: '¡Entendido!',
      });
    }
  }

  descinfo(text?: string) {
    if (text == undefined) {
      Swal.fire({
        title: '<strong>Informacion</strong>',
        icon: 'error',
        html: ' <b>Algo anda mal</b>, ' +
          '<h3> Verifique que no haya campos vacíos e intente nuevamente <h3> ',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#00bba4',
        confirmButtonText: '¡Entendido!',
      });
    } else {
      Swal.fire({
        title: '<strong>Informacion</strong>',
        icon: 'info',
        html: ' <b>Es necesario validar</b>, ' +
          '<h3>' + text + '<h3> ',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#00bba4',
        confirmButtonText: '¡Entendido!',
      });
    }
  }


  /* METHOD FOR VALID ALERT   -----   PARAMS: TEXT:STRING */
  descValid(text?: string) {
    if (text == undefined) {
      Swal.fire({
        title: '<strong>¡Genial!</strong>',
        icon: 'success',
        html: ' <b>¡El registro se ha creado con éxito!</b> ',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#00bba4',
        confirmButtonText: '¡Entendido!',
      });
    } else {
      Swal.fire({
        title: '<strong>¡Genial!</strong>',
        icon: 'success',
        html: ' <b>' + text + '</b> ',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#00bba4',
        confirmButtonText: '¡Entendido!',
      });
    }
  }
  ///////////////////////////////////////////////////////END GENERAL ALERT//////////////////////////////////////////////////////////////////////


closeValid(){
  Swal.fire({
    title: '<strong>¡Visita cerrada con éxito!</strong>',
    icon: 'success',
    html: ' <b>La visita de mantenimiento se cerró con éxito</b>, ' ,
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonColor:'#00bba4',
    confirmButtonText: '¡Entendido!',
  });
}

excelErr(campo){
  Swal.fire({
    title: '<strong>Oops...</strong>',
    icon: 'error',
    html: ' <b>Algo anda mal</b>, ' +
      '<h3>  El excel cargado no inlcuye el Campo '+ campo +' <h3> ',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonColor:'#00bba4',
    confirmButtonText: '¡Entendido!',
  });
}

}
