import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { OnDestroy } from '@angular/core';
import * as moment from "moment";
import { GlobalAlertService } from 'src/app/global-alert.service';
import { ColorEvent } from 'ngx-color';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-crear-cliente',
  templateUrl: './crear-cliente.component.html',
  styleUrls: ['./crear-cliente.component.scss']
})
export class CrearClienteComponent implements OnInit {

selectedFile;
finalPhone;
clientName;
clientDocument;
clientPhone:[];
clientAddress;
clientEmail;
clientWeb;
clientCountry;
clientLogo;
clientStyle;
Logo='';
srcImg='../../../assets/img/gallery/imgPlaceholder.png';
ext;
clientClass;
// ngx-color states
title = 'app';
  primaryColor = '#fff';
  state = {
    h: 150,
    s: 0.50,
    l: 0.20,
    a: 1,
  };
  colors = [
    "#5e72e4",
    "#2dce89",
    "#11cdef",
    "#f5365c",
    "#212529",
    "#172b4d",
    "#093579",
    "#5603ad",
    "#8965e0",
    "#f3a4b5",
    "#fb6340",
    "#ffd600",
    "#00bba4",
  ];
  constructor(public global: GlobalService, public routes: Router,public alerta:GlobalAlertService, public spinner:NgxSpinnerService) { 
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    let inputs = document.querySelectorAll('input');
    inputs.forEach(input=> input.value = '');
  }
  ngOnDestroy() {
  }
  exploreImg(){
   let elem:HTMLElement = document.querySelector('#exPhoto');
   elem.click();
  }
  onFileSelected(event){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {

        let imagen = new Image();
        imagen.src = event.target.result;
        imagen.onload = () => {
        this.clientLogo = event.target.result;
        this.srcImg = this.clientLogo;
        } 
      };
      reader.readAsDataURL(event.target.files[0]);
     }
    
  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }
  isArray(obj : any ) {
    return Array.isArray(obj);
 }

    Guardar(){
      this.spinner.show();
      if(this.isArray(this.clientPhone)){
        this.finalPhone = this.clientPhone.join('-');
      }else{
        this.finalPhone = this.clientPhone;
      }
      try {
        setTimeout(() => {
          if (this.clientName == undefined || this.clientName == '' || this.clientDocument == undefined || this.clientAddress == '' ||  this.clientEmail == undefined || this.clientEmail == '' || this.clientWeb == undefined || this.clientWeb == '' || this.clientCountry == '') {
            this.alerta.descErr('Error al crear nuevo usuario, verifique nuevamente que no hayan campos vacíos');
          } else {
            const query = 'INSERT INTO db_liwa.Client (ClientName,ClientDocument,ClientPhone,ClientAddress,ClientEmail,ClientWebPage,ClientCountry,ClientLogo,IsDelete,IsActive,ClientImage)' +
            ' VALUES (\'' +
            this.clientName + '\',\'' +
            this.clientDocument + '\', \'' +
            this.clientPhone + '\',\'' +
            this.clientAddress + '\',\'' +
            this.clientEmail + '\',\'' +
            this.clientWeb + '\',\'' +
            this.clientCountry + '\',\'' +
            this.clientStyle + '\',\'' +
            0  + '\',\'' +
            1  + '\',\'' +
            this.Logo + '\');';
            const pdata1 = {option: 'insertar', texto: query};
            this.global.consultar(pdata1, (err, response) => {
              console.log(response, query);
              if (err == null && response == true) {
                this.spinner.hide();
                this.alerta.descValid('Cliente creado con éxito')
                this.routes.navigateByUrl('/Usuarios');
              } else {
                this.alerta.descErr('Error al crear cliente, verifique nuevamente que no hayan campos vacíos');
                let inputs = document.querySelectorAll('input');
                
              }
            });
          }
        }, 400);
        
      } catch (error) {
        console.log("error:" , +error);
      }
    }

    loadLogo(){
      this.spinner.show();
      if(this.clientLogo == null || this.clientLogo == undefined || this.clientLogo == ''){
        this.alerta.descErr('Error al subir la foto, verifique nuevamente');
       }else{
        console.log('Foto', this.clientLogo);
        const FOTOO = this.clientLogo.slice(23, this.clientLogo.length);
        //nombre de la imagen en el servidor this.Photo
        const imageName = this.clientName + '_' + moment().unix()  + this.ext;
        //const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        const imageFile = FOTOO;
        const data = new FormData();
        data.append('image', imageFile);
        data.append('nombre', imageName );
        data.append('ruta', this.clientName);
        $.ajax({url: 'https://fotos.engenius.com.co/subir_foto_ClientLW.php',
            type: 'post',
            dataType: 'json',
            data,
            processData: false,
            contentType: false }).then((data1) => {
            console.log(data, data1);
            this.Logo = data1;
            if(this.Logo == 'BADEXT' ||  this.Logo == null || this.Logo == undefined || this.Logo == "" || this.Logo == 'NULL'){
              this.alerta.descErr('Error al subir la foto, verifique nuevamente');
            }else{
              this.Guardar();
              this.spinner.hide();
            }
          });
       }
                          
  }
  goBack() {
    window.history.back();
  }
  changeComplete($event: ColorEvent): void {
    this.state = $event.color.hsl;
    this.primaryColor = $event.color.hex;
    console.log('changeComplete', $event);
    console.log(this.primaryColor);
    for (let i = 0; i < this.global.Clientcolors.length; i++) {
      if(this.global.Clientcolors[i].color == this.primaryColor ){
        this.clientStyle = this.global.Clientcolors[i].clase;
        document.querySelector('#gradient').setAttribute("class", this.clientStyle);
      }
    }
    
  }
  

}
