import { Component, ViewChild, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/global.service";
import * as $ from "jquery";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalAlertService } from "src/app/global-alert.service";
import * as moment from "moment";
import * as XLSX from "xlsx";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FullCalendarComponent } from "@fullcalendar/angular";
import esLocale from "@fullcalendar/core/locales/es";

@Component({
  selector: "app-visitas",
  templateUrl: "./visitas.component.html",
})
export class VisitasComponent implements OnInit {
  // visitas;
  srcImg: any[] = [];
  imgsUrls: any[] = [];
  usrId;
  nombreProyecto;
  updateM;
  photos: boolean = false;
  Mantenimiento: any[] = [];
  importance;
  clientClass = Array();
  maintDesc;
  updateF;
  arrayBuffer: any;
  file: File;
  MEDIDORESEXCEL;
  Registrar: boolean = false;
  Toexcel = new Array();
  idTicket;
  sinAgendar;
  proyectos;
  seccionales;
  tecnicos;
  proyectoSeleccionado = "ninguno";
  seccionalSeleccionada = "ninguno";
  tecnicoSeleccionado = "ninguno";
  calendarActived: boolean = true;
  listActived: boolean = false;
  listForschedule: boolean = false;
  calendarOptions: CalendarOptions;
  eventsModel: any; // este no eta en devluis2
  color1 = '#027fc2'
  color2 = '#027fc2'
  Subir = false;

  constructor(
    public global: GlobalService,
    protected route: Router,
    public ruta: Router,
    protected modalService: NgbModal,
    protected alerta: GlobalAlertService,
    protected spinner: NgxSpinnerService
  ) {
    this.global.initData();
  }

  @ViewChild("fullcalendar") fullcalendar: FullCalendarComponent;

  ngOnInit(): void {
    this.spinner.show();
    this.global.selectedForVisit = [];
    this.global.Id_Ticket = "-";
    this.global.banderaReagendamiento = false;
    this.global.selectedDate = "";
    this.global.Id_Mantenimiento = "";
    this.clientClass = this.global.CLIENTDATALIWA[8];
    let pdata9;
    // console.log('ya vi', this.global.Id_Client)
    if (this.global.idRole == 15) {
      pdata9 = {
        option: "insertar2",
        texto:
          "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical), (db_liwa.getdays(M.`LastInsert`, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User INNER JOIN db_liwa.Tickets_New W ON M.`Id_Ticket` = W.`Id_Tickets` Where M.IsDelete=0 AND W.`Id_Usuario` = '" +
          this.global.Id_Usuario +
          "'",
      };
    } else {
      pdata9 = {
        option: "insertar2",
        texto:
          `SELECT M.*, U.UserName,
           (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical),
            (db_liwa.getdays(M.LastInsert, NOW())) AS Semaforo,
            (SELECT Vereda FROM db_liwa.AOM  WHERE id = M.Id_Beneficiario ) as vereda,
            (SELECT Nombre_Completo FROM db_liwa.AOM  WHERE id = M.Id_Beneficiario ) as NombreBeneficiario
             FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.Id_Beneficiario = U.Id_User Where
              (SELECT C.Id_Client FROM db_liwa.tipoProyecto C Where C.Id_Client = ${this.global.Id_Client} and C.id_tipoProyecto = M.idProyecto)
              AND M.IsDelete=0;`,
      };
    }
    this.global.consultar(pdata9, async (err9, response9) => {
      console.log(pdata9, 'esta es la mia a ver donde xze ejecuta')
      this.Mantenimiento = await response9;
      let evts: any[] = [];
      if (this.Mantenimiento.length == 0) this.updateEvents([]);
      else {
        for (let i = 0; i < this.Mantenimiento.length; i++) {
          if (this.Mantenimiento[i][10] == "Correctivo") {
            if (this.Mantenimiento[i][11] == "Terminado")
              this.color1 = "#800080";
            else if (this.Mantenimiento[i][19] <= 6) this.color1 = "#E5BE01";
            else if (
              this.Mantenimiento[i][19] >= 7 &&
              this.Mantenimiento[i][19] <= 13
            )
              this.color1 = "#FF8000";
            else if (this.Mantenimiento[i][19] >= 14) this.color1 = "#FF0000";
            let titulo = `${this.Mantenimiento[i][20]}  ${this.Mantenimiento[i][21]}`;
            let fecha = new Date(moment(this.Mantenimiento[i][6]).format('YYYY/MM/DD'))
            evts.push({
              id: this.Mantenimiento[i][0],
              // start: this.Mantenimiento[i][6],
              start: fecha.setDate(fecha.getDate() + 1),
              title: titulo,
              color: this.color1,
            });
            this.updateEvents(evts);
          } else if (this.Mantenimiento[i][10] == "Preventivo") {
            if (this.Mantenimiento[i][11] == "Terminado")
              this.color2 = "#800080";
            else if (this.Mantenimiento[i][19] <= 6) this.color2 = "#E5BE01";
            else if (
              this.Mantenimiento[i][19] >= 7 &&
              this.Mantenimiento[i][19] <= 13
            )
              this.color2 = "#FF8000";
            else if (this.Mantenimiento[i][19] >= 14) this.color2 = "#FF0000";
            let titulo = `${this.Mantenimiento[i][20]}` + `${this.Mantenimiento[i][21]}`;
            let fecha = new Date(moment(this.Mantenimiento[i][6]).format('YYYY/MM/DD'))

            evts.push({
              id: this.Mantenimiento[i][0],
              start: fecha.setDate(fecha.getDate() + 1), // esto se hace porque el calendario los marca un dia antes del agendamiento
              // start:this.Mantenimiento[i][6],
              title: titulo,
              color: this.color2,
            });
            this.updateEvents(evts);
          }
        }

        const dataProyect = {
          option: "insertar2",
          texto: `SELECT id_tipoProyecto, Nombre FROM db_liwa.tipoProyecto WHERE Id_Client = ${this.global.Id_Client} AND IsDelete = 0;`,
        };
        this.global.consultar(dataProyect, async (err, response) => {
          this.proyectos = await response;
          await this.spinner.hide()
        });
      }
    });

    const pdata = {
      option: "insertar2",
      texto:
        `SELECT T.Id_Tickets,T.Id_Proyecto,T.Id_Seccional,(SELECT UserName FROM db_liwa.User WHERE Id_User = T.Id_usuario) AS NombreGestionador, T.Menu,T.Description,
        (SELECT Nombre FROM db_liwa.Seccionales WHERE id_Seccional = T.Id_Seccional)As Nombre_S,
        (SELECT Nombre FROM db_liwa.tipoProyecto WHERE id_tipoProyecto = T.Id_Proyecto) As Nombre_P,
        (db_liwa.getdays(Creation_Date, NOW())) AS Semaforo,
        (SELECT P.Cedula FROM db_liwa.AOM P WHERE P.Id=T.Id_Beneficiario) AS cedula_Benefi,
         IF (T.SubMenu2 IN (SELECT SubMenu2 From db_liwa.Tickets_New WHERE SubMenu2 = 'Agendamiento visita'),'Visita', 'No visita'),
         IF (T.SubMenu3 IN (SELECT SubMenu3 From db_liwa.Tickets_New WHERE SubMenu3 = 'Agendamiento visita'),'Visita', 'No visita'),
         IF (T.Id_Tickets IN(SELECT Id_Ticket FROM db_liwa.Maintenance_new),'Agendado', 'Sin Agendar'),
         (SELECT Nombre_Completo FROM db_liwa.AOM WHERE Id=T.Id_Beneficiario)As Nombre_AOM,
         (SELECT Vereda FROM db_liwa.AOM WHERE Id=T.Id_Beneficiario)As vereda
         FROM db_liwa.Tickets_New T Where (SELECT C.Id_Client FROM db_liwa.tipoProyecto C Where C.Id_Client = ${this.global.Id_Client} and id_tipoProyecto = T.Id_Proyecto) AND T.IsDelete=0 AND T.IsActive=1;`,
    };
    this.global.consultar(pdata, async (err9, response) => {
      this.sinAgendar = await response.filter(
        (e) =>
          (e[10] === "Visita" || e[11] === "Visita") && e[12] !== "Agendado"
      );
    });

    setTimeout(() => {
      $(function () {
        this.table = $("#visitTable").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }, 800);
    // forwardRef(() => Calendar); no entendemos porque usar esta referencia en el calendario??>>??
  }
  modal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-vinculate" })
      .result.then(
        (result) => {
        },
        (reason) => {
        }
      );
  }
  verArchivo() {
    window.open("https://php.engenius.com.co/Formatos/Excel/Ejemplo_Carga_visitas_masiva20-02-23.xlsx", "_blank");
  }
  referenciaArchivo(ev) {
    this.file = ev.target.files[0];
  }
  enviarcorreoCreado(infoCorreo, idTk) {
    setTimeout(() => {
      const pdata9 = {
        option: "mail",
        ticket: idTk,
        Seccional: "hg",
        descr: infoCorreo[8],
        type: 1,
        contacto: infoCorreo[11],
        tel: infoCorreo[10],
        user: this.global.UserName,
        municipio: infoCorreo,
      };
      this.global.consultar(pdata9, async (err2, response2) => {
      });
    }, 500);
  }
  eviarSMS(infoSMS, idTk, idBenefi) {
    setTimeout(() => {
      const pdata8 = {
        option: "insertar2",
        texto: `SELECT UserName FROM db_liwa.User WHERE Id_User = '${infoSMS[1]}';`,
      };
      this.global.consultar(pdata8, async (err9, response9) => {
        console.log("INFOMACION SMS", pdata8, response9);
        const pdata9 = {
          number: `57${infoSMS[8]}`,
          message: `DISPOWER le informa que el tecnico: ${response9[0][0]}, hara visita tecnica a su planta solar el dia : ${infoSMS[4]} la visita dara inicio a las ${infoSMS[2]}`,
          type: "1",
        };
        const pdata7 = {
          number: "57" + "3153583711",
          message: `DISPOWER le informa que el tecnico: ${response9[0][0]}, hara visita tecnica a su planta solar el dia : ${infoSMS[4]} la visita dara inicio a las ${infoSMS[2]}`,
          type: "1",
        };
        const pdata6 = {
          number: "57" + "3164946552",
          message: `DISPOWER le informa que el tecnico: ${response9[0][0]}, hara visita tecnica a su planta solar el dia : ${infoSMS[4]} la visita dara inicio a las ${infoSMS[2]}`,
          type: "1",
        };
        let query = `REPLACE INTO db_liwa.MensajesAOM (Id_Proyecto,Id_Seccional,Id_Beneficiario,Id_Maintenance,Id_Ticket,Id_User,Id_Tecnico,Numero_Dest,Tipo_Mantenimiento,Tipo_Ticket,ContenidoSMS) VALUES ('${infoSMS[6]}','${infoSMS[9]}','${idBenefi}','${idTk}','${infoSMS[8]}','${this.global.Id_Usuario}','${infoSMS[2]}','${pdata9.number}','${infoSMS[7]}','-','${pdata6.message}');`;
        const pdata5 = { option: "insertar3", texto: query }
        this.global.consultarSMS(pdata9, (err2, response2) => {
          this.global.consultarSMS(pdata7, (err2, response2) => {
            this.global.consultarSMS(pdata6, (err2, response2) => {
              this.global.consultar(pdata5, async (err2, response2) => {
              });
            });
          });
        });
      })
    }, 500);
  }
  // async subiendoandoExcel() {
  //   this.spinner.show();
  //   let fileReader = new FileReader();
  //   fileReader.onload = async (e) => {
  //     this.arrayBuffer = fileReader.result;
  //     let data = new Uint8Array(this.arrayBuffer);
  //     let arr = new Array();
  //     for (let i = 0; i != data.length; ++i)
  //       arr[i] = String.fromCharCode(data[i]);
  //     let bstr = arr.join("");
  //     let workbook = XLSX.read(bstr, { type: "binary" });
  //     let first_sheet_name = workbook.SheetNames[0];
  //     let worksheet = workbook.Sheets[first_sheet_name];
  //     let MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet, { raw: false });
  //     let contadorStoper = 0
  //     let distanciaArchivo = MEDIDORESEXCEL.length;
  //     for (let casilla = 0; casilla < MEDIDORESEXCEL.length; casilla++) {
  //       if (MEDIDORESEXCEL[casilla].hasOwnProperty("NUI")) {
  //         console.log("Nui", MEDIDORESEXCEL[casilla].hasOwnProperty("NUI"))
  //         if (MEDIDORESEXCEL[casilla].hasOwnProperty("id_Tecnico")) {
  //           console.log("id_Tecnico", MEDIDORESEXCEL[casilla].hasOwnProperty("id_Tecnico"))
  //           if (MEDIDORESEXCEL[casilla].hasOwnProperty("hora_inicio_v")) {
  //             console.log("hora_inicio_v", MEDIDORESEXCEL[casilla].hasOwnProperty("hora_inicio_v"))
  //             if (MEDIDORESEXCEL[casilla].hasOwnProperty("hora_final_v")) {
  //               console.log("hora_final_v", MEDIDORESEXCEL[casilla].hasOwnProperty("hora_final_v"))
  //               if (MEDIDORESEXCEL[casilla].hasOwnProperty("Dia_visita")) {
  //                 console.log("Dia_visita", MEDIDORESEXCEL[casilla].hasOwnProperty("Dia_visita"))
  //                 if (MEDIDORESEXCEL[casilla].hasOwnProperty("Tipo_Mantenimiento")) {
  //                   console.log("Tipo_Mantenimiento", MEDIDORESEXCEL[casilla].hasOwnProperty("Tipo_Mantenimiento"))
  //                   if (MEDIDORESEXCEL[casilla].hasOwnProperty("Id_Ticket")) {
  //                     console.log("Id_Ticket", MEDIDORESEXCEL[casilla].hasOwnProperty("Id_Ticket"))
  //                     if (MEDIDORESEXCEL[casilla].hasOwnProperty("Descripcion")) {
  //                       console.log("Descripcion", MEDIDORESEXCEL[casilla].hasOwnProperty("Descripcion"))
  //                       if (MEDIDORESEXCEL[casilla].hasOwnProperty("Telefono")) {
  //                         console.log("Telefono", MEDIDORESEXCEL[casilla].hasOwnProperty("Telefono"))
  //                         if (MEDIDORESEXCEL[casilla].hasOwnProperty("id_Mantenimiento")) {
  //                           console.log("id_Mantenimiento", MEDIDORESEXCEL[casilla].hasOwnProperty("id_Mantenimiento"))
  //                           let Datos = Object.values(MEDIDORESEXCEL[casilla]);
  //                           // if (Datos.length <= 13) {
  //                           const pdata9 = {
  //                             option: "insertar2",
  //                             texto: `SELECT Id_Encuesta, Id_Proyecto, Id_Seccional FROM db_liwa.EncuestasAOM WHERE NUI = '${Datos[0]}';`,
  //                           };
  //                           this.global.consultar(pdata9, async (err9, response9) => {
  //                             console.log('esas son cosas del pasado ', Datos[0])
  //                             console.log(await response9.length >= 1, "este es el .length ")
  //                             if (await response9.length >= 1) {
  //                               console.log('este es la que trae proyectos y seccionales', response9[0])
  //                               const id_Encuesta = await response9[0][0];
  //                               const id_proyecto = await response9[0][1];
  //                               const id_seccional = await response9[0][2];
  //                               const exMoment = Datos[9].length > 1 ? Datos[9] : this.global.Id_Usuario + "_" + moment.now();
  //                               const query =
  //                                 "REPLACE INTO db_liwa.Maintenance_new ( Id_Maintenance, Id_Beneficiario, Id_Client, Id_Technical, Visit_Hour_Init, Visit_Hour_finish, Visit_day, idSeccional, idProyecto, Description, Maintenance_Type, Status, Id_Ticket, IsDelete, IsActive )" +
  //                                 " VALUES ('" +
  //                                 exMoment +
  //                                 "','" +
  //                                 id_Encuesta +
  //                                 "','" +
  //                                 this.global.Id_Client +
  //                                 "','" +
  //                                 Datos[1] +
  //                                 "','" +
  //                                 Datos[2] +
  //                                 "','" +
  //                                 Datos[3] +
  //                                 "','" +
  //                                 moment(Datos[4]).format('YYYY-MM-DD') +
  //                                 "','" +
  //                                 id_seccional +
  //                                 "','" +
  //                                 id_proyecto +
  //                                 "','" +
  //                                 Datos[7] +
  //                                 "', '" +
  //                                 Datos[5] +
  //                                 "','" +
  //                                 "Agendado" +
  //                                 "','" +
  //                                 Datos[6] +
  //                                 "', '" +
  //                                 0 +
  //                                 "','" +
  //                                 1 +
  //                                 "');";

  //                               const pdata1 = { option: "insertar3", texto: query };
  //                               this.global.consultar(pdata1, async (err, response) => {
  //                                 console.log(response, "primera consulta")
  //                                 const query2 = 'REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)' +
  //                                   ' VALUES (\'' +
  //                                   this.global.Id_Usuario + "_" + moment.now() + '\',\'' +
  //                                   exMoment + '\',\'' +
  //                                   this.global.Id_Usuario + '\',\'' +
  //                                   moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
  //                                   "La visita ha sido agendada" + '\', \'' +
  //                                   "Agendado" + '\',\'' +
  //                                   0 + '\',\'' +
  //                                   1 + '\');';
  //                                 const pdata2 = {
  //                                   option: "insertar3",
  //                                   texto: query2,
  //                                 };
  //                                 this.global.consultar(
  //                                   pdata2,
  //                                   async (err2, response2) => {
  //                                     console.log(await response2, "segunda es la del Update consulta")
  //                                     this.MEDIDORESEXCEL = MEDIDORESEXCEL;
  //                                     this.Registrar = true;
  //                                     this.eviarSMS(Datos, this.idTicket, id_Encuesta);
  //                                   }
  //                                 );
  //                               });
  //                             } else {
  //                               console.log('no entro')
  //                               this.alerta.descErr(`Lo sentimos pero el numero de indetificacion nui: ${Datos[0]} NO SE ENCUENTRA REGISTRADO, todos los resgistros anteriores fueron subidos con exito hasta este punto`);
  //                               const pdata90 = {
  //                                 option: "insertar2",
  //                                 texto:
  //                                   "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical), (db_liwa.getdays(M.`LastInsert`, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User Where M.IsDelete=0",
  //                               };
  //                               this.global.consultar(pdata90, async (err9, response9) => {
  //                                 // this.spinner.show()
  //                                 this.Mantenimiento = await response9;
  //                                 let evts: any[] = [];
  //                                 if (this.Mantenimiento.length == 0) this.updateEvents([]);
  //                                 else {
  //                                   for (let i = 0; i < this.Mantenimiento.length; i++) {
  //                                     if (this.Mantenimiento[i][10] == "Correctivo") {
  //                                       if (this.Mantenimiento[i][11] == "Terminado")
  //                                         this.color1 = "#800080";
  //                                       else if (this.Mantenimiento[i][19] <= 6) this.color1 = "#E5BE01";
  //                                       else if (
  //                                         this.Mantenimiento[i][19] >= 7 &&
  //                                         this.Mantenimiento[i][19] <= 13
  //                                       )
  //                                         this.color1 = "#FF8000";
  //                                       else if (this.Mantenimiento[i][19] >= 14) this.color1 = "#FF0000";
  //                                       let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
  //                                       evts.push({
  //                                         id: this.Mantenimiento[i][0],
  //                                         start: this.Mantenimiento[i][6],
  //                                         title: titulo,
  //                                         color: this.color1,
  //                                       });
  //                                       this.updateEvents(evts);
  //                                     } else if (this.Mantenimiento[i][10] == "Preventivo") {
  //                                       if (this.Mantenimiento[i][11] == "Terminado")
  //                                         this.color2 = "#800080";
  //                                       else if (this.Mantenimiento[i][19] <= 6) this.color2 = "#E5BE01";
  //                                       else if (
  //                                         this.Mantenimiento[i][19] >= 7 &&
  //                                         this.Mantenimiento[i][19] <= 13
  //                                       )
  //                                         this.color2 = "#FF8000";
  //                                       else if (this.Mantenimiento[i][19] >= 14) this.color2 = "#FF0000";
  //                                       let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
  //                                       evts.push({
  //                                         id: this.Mantenimiento[i][0],
  //                                         start: this.Mantenimiento[i][6],
  //                                         title: titulo,
  //                                         color: this.color2,
  //                                       });
  //                                       this.updateEvents(evts);
  //                                     }
  //                                   }
  //                                 }
  //                               });

  //                             }
  //                           });

  //                           // } else {
  //                           //   this.alerta.descErr("El archivo contiene casillas demas de las permitidas, porfavor verifique que el aechivo sea igual al definido por la empresa");
  //                           // }
  //                         } else {
  //                           this.alerta.excelErr("id_Mantenimiento");
  //                         }
  //                       } else {
  //                         this.alerta.excelErr("Telefono");
  //                       }
  //                     } else {
  //                       this.alerta.excelErr("Descripcion");
  //                     }
  //                   } else {
  //                     this.alerta.excelErr("Id_Ticket");
  //                   }
  //                 } else {
  //                   this.alerta.excelErr("Tipo_Mantenimiento");
  //                 }
  //               } else {
  //                 this.alerta.excelErr("Dia_visita");
  //               }
  //             } else {
  //               this.alerta.excelErr("hora_final_v");
  //             }
  //           } else {
  //             this.alerta.excelErr("hora_inicio_v");
  //           }
  //         } else {
  //           this.alerta.excelErr("id_Tecnico");
  //         }
  //       } else {
  //         this.alerta.excelErr("NUI");
  //       }
  //       contadorStoper++
  //       if (contadorStoper === distanciaArchivo) {
  //         console.log(contadorStoper, "vs ", distanciaArchivo)
  //         this.alerta.descValid('Los Mantenimientos fueron agregados de forma exitosa! Felicidades si no ve los cambios reflejados porfavor refrescar el navegador')
  //         this.spinner.hide();
  //       }
  //     }
  //   };
  //   fileReader.readAsArrayBuffer(this.file);

  // }

  SubirExcel() {
    this.Subir = true;
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: false }));
      var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      if (MEDIDORESEXCEL[0].hasOwnProperty('NUI')) {
        console.log('Si Nui')
        if (MEDIDORESEXCEL[0].hasOwnProperty('id_Tecnico')) {
          console.log('SI id_Tecnico')
          if (MEDIDORESEXCEL[0].hasOwnProperty('hora_inicio_v')) {
            console.log('SI hora_inicio_v')
            if (MEDIDORESEXCEL[0].hasOwnProperty('hora_final_v')) {
              console.log('SI hora_final_v')
              if (MEDIDORESEXCEL[0].hasOwnProperty('Dia_visita')) {
                console.log('SI Dia_visita')
                if (MEDIDORESEXCEL[0].hasOwnProperty('Tipo_Mantenimiento')) {
                  console.log('SI Tipo_Mantenimiento')
                  if (MEDIDORESEXCEL[0].hasOwnProperty('Id_Ticket')) {
                    console.log('SI Id_Ticket')
                    if (MEDIDORESEXCEL[0].hasOwnProperty('Descripcion')) {
                      console.log('SI Descripcion')
                      if (MEDIDORESEXCEL[0].hasOwnProperty('Telefono')) {
                        console.log('SI Telefono')
                        if (MEDIDORESEXCEL[0].hasOwnProperty('id_Mantenimiento')) {
                          console.log('SI id_Mantenimiento')
                          console.log(MEDIDORESEXCEL)
                          this.MEDIDORESEXCEL = MEDIDORESEXCEL;

                          this.Registrar = true;
                        } else {
                          console.log('No id_Mantenimiento')
                          this.alerta.excelErr('Error en la columna id_Mantenimiento');
                        }
                      } else {
                        console.log('No Telefono')
                        this.alerta.excelErr('Error en la columna Telefono');
                      }
                    } else {
                      console.log('No Descripcion')
                      this.alerta.excelErr('Error en la columna Descripcion');
                    }
                  } else {
                    console.log('No Id_Ticket')
                    this.alerta.excelErr('Error en la columna Id_Ticket');
                  }
                } else {
                  console.log('No Tipo_Mantenimiento')
                  this.alerta.excelErr('Error en la columna Tipo_Mantenimiento');
                }
              } else {
                console.log('No Dia_visita')
                this.alerta.excelErr('Error en la columna Dia_visita');
              }
            } else {
              console.log('No hora_final_v')
              this.alerta.excelErr('Error en la columna hora_final_v');
            }
          } else {
            console.log('No hora_inicio_v')
            this.alerta.excelErr('Error en la columna hora_inicio_v');
          }
        } else {
          console.log('No id_Tecnico')
          this.alerta.excelErr('Error en la columna id_Tecnico');
        }
      } else {
        console.log('No NUI')
        this.alerta.excelErr('Error en la columna NUI');
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }


  RegistrarMedidores() {
    this.spinner.show();
    if (this.Registrar == true) {
      const pdata3 = { option: 'carga-masiva-agenda', ArrayData: this.MEDIDORESEXCEL, id_user: this.global.Id_Usuario, id_cliente: this.global.Id_Client};
      this.global.consultarLocal(pdata3, async (err3, response3) => {
        console.log(response3.status);
        if (response3.status == 200) {
          this.alerta.descValid('Los Mantenimientos fueron agregados de forma exitosa! Felicidades si no ve los cambios reflejados por favor refrescar el navegador.')
          this.spinner.hide();
          this.ngOnInit();
          this.MEDIDORESEXCEL = new Array();
          this.Registrar = false;
          this.Subir = false;
        } else if(response3.status == 301) {
          let resultado = '';
          for (let i = 0; i < response3.errors.length; i++) {
            resultado += response3.errors[i].idNui;
            if (i < response3.errors.length - 1) {
              resultado += ', ';
            }
          }
          this.alerta.descinfo(`No se logro cargar toda la agenda esto por que no se encontraron algunos NUI: ${resultado}`)
          this.spinner.hide();
          this.ngOnInit();
          this.MEDIDORESEXCEL = new Array();
          this.Registrar = false;
          this.Subir = false;
        }else if(response3.status == 500){
          this.alerta.descErr('Error interno del servidor, por favor inténtelo de nuevo.')
          this.spinner.hide();
          this.ngOnInit();
          this.MEDIDORESEXCEL = new Array();
          this.Registrar = false;
          this.Subir = false;
        }
      });
    } else {

    }
  }


  cargarSeccionales() {
    this.spinner.show();
    let filtrado = this.proyectos.filter(
      (e) => e[0] == this.proyectoSeleccionado
    );
    this.nombreProyecto = filtrado[0][1];
    console.log(filtrado[0][1], "etetetettttttttttttttttttttttttttt");
    this.global.selectedForVisit = [
      "",
      this.nombreProyecto,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const pdata1 = {
      option: "insertar2",
      texto:
        "SELECT A.Nombre, A.id_seccional FROM db_liwa.Seccionales A JOIN db_liwa.tipoProyecto B ON A.Id_Proyecto = B.id_tipoProyecto Where B.`id_tipoProyecto` = '" +
        this.proyectoSeleccionado +
        "' AND A.IsDelete = 0;",
    };
    console.log("Seccionales data", pdata1);
    this.global.consultar(pdata1, async (err2, response1) => {
      setTimeout(() => {
        this.seccionales = response1;
        let textoQ;
        if (this.global.idRole == 15)
          textoQ =
            "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical) FROM db_liwa.Maintenance_new M  INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User  INNER JOIN db_liwa.Tickets_New W ON M.`Id_Ticket` = W.`Id_Tickets` Where M.IsDelete=0 AND M.`idProyecto` = '" +
            this.proyectoSeleccionado +
            "' AND W.`Id_Usuario` = '" +
            this.global.Id_Usuario +
            "'";
        else
          textoQ =
            "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical),(db_liwa.getdays(M.`LastInsert`, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User Where M.IsDelete=0 AND M.`idProyecto` = '" +
            this.proyectoSeleccionado +
            "'";
        const dataCalendario = { option: "insertar2", texto: textoQ };

        this.global.consultar(dataCalendario, async (err, response) => {
          this.Mantenimiento = response;
          let evts: any[] = [];
          if (this.Mantenimiento.length > 0) {
            for (let i = 0; i < this.Mantenimiento.length; i++) {
              if (this.Mantenimiento[i][10] == "Correctivo") {
                if (this.Mantenimiento[i][11] == "Terminado")
                  this.color1 = "#800080";
                else if (this.Mantenimiento[i][19] <= 6)
                  this.color1 = "#E5BE01";
                else if (
                  this.Mantenimiento[i][19] >= 7 &&
                  this.Mantenimiento[i][19] <= 13
                )
                  this.color1 = "#FF8000";
                else if (this.Mantenimiento[i][19] >= 14)
                  this.color1 = "#FF0000";
                let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
                evts.push({
                  id: this.Mantenimiento[i][0],
                  start: this.Mantenimiento[i][6],
                  title: titulo,
                  color: this.color1,
                });
                this.updateEvents(evts);
              } else if (this.Mantenimiento[i][10] == "Preventivo") {
                if (this.Mantenimiento[i][11] == "Terminado")
                  this.color2 = "#800080";
                else if (this.Mantenimiento[i][19] <= 6)
                  this.color2 = "#E5BE01";
                else if (
                  this.Mantenimiento[i][19] >= 7 &&
                  this.Mantenimiento[i][19] <= 13
                )
                  this.color2 = "#FF8000";
                else if (this.Mantenimiento[i][19] >= 14)
                  this.color2 = "#FF00e0";
                else this.color2 = "#FF0000";
                let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
                evts.push({
                  id: this.Mantenimiento[i][0],
                  start: this.Mantenimiento[i][6],
                  title: titulo,
                  color: this.color2,
                });
                this.updateEvents(evts);
              }
            }
          } else this.updateEvents([]);
        });

        this.spinner.hide();
      }, 500);
    });
  }
  consultarTecnicos() {
    this.global.selectedForVisit = [
      "",
      this.nombreProyecto,
      "",
      this.seccionalSeleccionada,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    this.spinner.show();

    const pdata1 = {
      option: "insertar2",
      texto:
        "SELECT A.Id_user, A.`UserName`, C.`Nombre`  FROM db_liwa.User A JOIN db_liwa.tipoProyecto B ON A.`Id_Client` = B.`Id_Client` JOIN db_liwa.Seccionales C ON B.`id_tipoProyecto`=C.`Id_Proyecto` Where C.`Id_seccional` = '" +
        this.seccionalSeleccionada +
        "' AND A.Id_Role = 3 AND A.IsActive = 1 AND  C.IsDelete = 0;",
    };
    console.log("Tecnicos data", pdata1);
    this.global.consultar(pdata1, async (err2, response1) => {
      this.tecnicos = response1;
      setTimeout(() => {
        let textoQ;
        if (this.global.idRole == 15)
          textoQ =
            "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical) FROM db_liwa.Maintenance_new M  INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User  INNER JOIN db_liwa.Tickets_New W ON M.`Id_Ticket` = W.`Id_Tickets` Where M.IsDelete=0 AND M.`idProyecto` = '" +
            this.proyectoSeleccionado +
            "' AND M.`idSeccional`='" +
            this.seccionalSeleccionada +
            "' AND W.`Id_Usuario` = '" +
            this.global.Id_Usuario +
            "'";
        else
          textoQ =
            "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical) ,(db_liwa.getdays(M.`LastInsert`, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User Where M.IsDelete=0 AND M.`idProyecto` = '" +
            this.proyectoSeleccionado +
            "' AND M.`idSeccional`='" +
            this.seccionalSeleccionada +
            "'";
        const dataCalendario = { option: "insertar2", texto: textoQ };
        this.global.consultar(dataCalendario, async (err, response) => {
          this.Mantenimiento = response;
          let evts: any[] = [];
          if (this.Mantenimiento.length > 0) {
            for (let i = 0; i < this.Mantenimiento.length; i++) {
              if (this.Mantenimiento[i][10] === "Correctivo") {
                if (this.Mantenimiento[i][11] == "Terminado")
                  this.color1 = "#800080";
                else if (this.Mantenimiento[i][19] <= 6)
                  this.color1 = "#E5BE01";
                else if (
                  this.Mantenimiento[i][19] >= 7 &&
                  this.Mantenimiento[i][19] <= 13
                )
                  this.color1 = "#FF8000";
                else if (this.Mantenimiento[i][19] >= 14)
                  this.color1 = "#FF0000";
                let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
                evts.push({
                  id: this.Mantenimiento[i][0],
                  start: this.Mantenimiento[i][6],
                  title: titulo,
                  color: this.color1,
                });
                this.updateEvents(evts);
              } else {
                if (this.Mantenimiento[i][11] == "Terminado")
                  this.color2 = "#800080";
                else if (this.Mantenimiento[i][19] <= 6)
                  this.color2 = "#E5BE01";
                else if (
                  this.Mantenimiento[i][19] >= 7 &&
                  this.Mantenimiento[i][19] <= 13
                )
                  this.color2 = "#FF8000";
                else if (this.Mantenimiento[i][19] >= 14)
                  this.color2 = "#FF0000";
                let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
                evts.push({
                  id: this.Mantenimiento[i][0],
                  start: this.Mantenimiento[i][6],
                  title: titulo,
                  color: this.color2,
                });
                this.updateEvents(evts);
              }
            }
          } else this.updateEvents([]);
        });
        this.spinner.hide();
      }, 500);
    });
  }
  updateCalendarioTecnico() {
    this.spinner.show();
    this.global.selectedForVisit = [
      "",
      this.nombreProyecto,
      "",
      this.seccionalSeleccionada,
      this.tecnicoSeleccionado,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    setTimeout(() => {
      let textoQ;
      if (this.global.idRole == 15)
        textoQ =
          "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical) FROM db_liwa.Maintenance_new M  INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User  INNER JOIN db_liwa.Tickets_New W ON M.`Id_Ticket` = W.`Id_Tickets` Where M.IsDelete=0 AND M.`idProyecto` = '" +
          this.proyectoSeleccionado +
          "' AND M.`idSeccional`='" +
          this.seccionalSeleccionada +
          "' AND Id_Technical = '" +
          this.tecnicoSeleccionado +
          "' AND W.`Id_Usuario` = '" +
          this.global.Id_Usuario +
          "'";
      else
        textoQ =
          "SELECT M.*, U.UserName, (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical),(db_liwa.getdays(M.`LastInsert`, NOW())) AS Semaforo FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.`Id_Beneficiario` = U.Id_User Where M.IsDelete=0 AND M.`idProyecto` = '" +
          this.proyectoSeleccionado +
          "' AND M.`idSeccional`='" +
          this.seccionalSeleccionada +
          "' AND Id_Technical = '" +
          this.tecnicoSeleccionado +
          "'";
      const dataCalendario = { option: "insertar2", texto: textoQ };
      console.log("esta es la query del tecnico", textoQ);
      this.global.consultar(dataCalendario, async (err, response) => {
        this.Mantenimiento = await response;
        console.log("esta es la respuesta de la query", this.Mantenimiento)
        let evts: any[] = [];
        if (this.Mantenimiento.length > 0) {
          for (let i = 0; i < this.Mantenimiento.length; i++) {
            if (this.Mantenimiento[i][10] == "Correctivo") {
              if (this.Mantenimiento[i][11] == "Terminado")
                this.color1 = "#800080";
              else if (this.Mantenimiento[i][19] <= 6) this.color1 = "#E5BE01";
              else if (
                this.Mantenimiento[i][19] >= 7 &&
                this.Mantenimiento[i][19] <= 13
              )
                this.color1 = "#FF8000";
              else if (this.Mantenimiento[i][19] >= 14) this.color1 = "#FF0000";
              let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
              evts.push({
                id: this.Mantenimiento[i][0],
                start: this.Mantenimiento[i][6],
                title: titulo,
                color: this.color1,
              });
              this.updateEvents(evts);
            } else if (this.Mantenimiento[i][10] == "Preventivo") {
              if (this.Mantenimiento[i][11] == "Terminado")
                this.color2 = "#800080";
              else if (this.Mantenimiento[i][19] <= 6) this.color2 = "#E5BE01";
              else if (
                this.Mantenimiento[i][19] >= 7 &&
                this.Mantenimiento[i][19] <= 13
              )
                this.color2 = "#FF8000";
              else if (this.Mantenimiento[i][19] >= 14) this.color2 = "#FF0000";
              let titulo = `${this.Mantenimiento[i][0]}  ${this.Mantenimiento[i][9]}`;
              evts.push({
                id: this.Mantenimiento[i][0],
                start: this.Mantenimiento[i][6],
                title: titulo,
                color: this.color2,
              });
              this.updateEvents(evts);
            }
          }
        } else this.updateEvents([]);
      });
      this.spinner.hide();
    }, 800);
  }
  handleDateClick(arg) {
    if (this.global.idRole != 15) {
      // let dateTime = moment(arg.date).format("yyyy-MM-DDThh:mm");
      let dateTime = moment(arg.date).format("yyyy-MM-DD");
      console.log(
        "este es mi console.log",
        moment(arg.date).format("DD-MM-yyyy")
      );
      Swal.fire({
        title: '<strong>¿Desea crear una nueva visita"?</strong>',
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: "#093579",
        cancelButtonText: "Cancelar",
        cancelButtonAriaLabel: "Thumbs up, great!",
        confirmButtonText: "Si, estoy seguro",
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(dateTime,'hgghghghghghg')
          this.global.withoutTicket = true;
          this.global.selectedDate = dateTime;
          // if (this.global.selectedDate == dateTime) {
          this.route.navigateByUrl("/crear-visita");
          // } else {
          // this.global.selectedDate = dateTime;
          // }
        } else {
          // this.ngOnInit();
        }
      });
    }
  }
  handleEventClick(arg) {
    Swal.fire({
      title: "<strong>¿Deseas Modificar la visita o Ver el detalle de esta?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Detalle",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Modificar",
    }).then((result) => {
      if (result.isConfirmed) {
        // this.borrarRegistroVisita(data);
        console.log(Object.values(arg.event._def)[2], 'chee este es el resultado')
        this.global.banderaReagendamiento = true;
        this.global.Id_Mantenimiento = Object.values(arg.event._def)[2]
        this.route.navigateByUrl("/crear-visita");
      } else {
        console.log("EVENTO PARA SELECCIONAR");
        console.log(arg);
        console.log("id de mantenimento", arg.event._def);
        const pdata12 = {
          option: "insertar2",
          texto:
            `SELECT M.*, U.UserName,
             (SELECT S.UserName FROM db_liwa.User S WHERE S.Id_User = M.Id_Technical),
              (db_liwa.getdays(M.LastInsert, NOW())) AS Semaforo,
              (SELECT Vereda FROM db_liwa.AOM  WHERE id = M.Id_Beneficiario ) as vereda,
              (SELECT Nombre_Completo FROM db_liwa.AOM  WHERE id = M.Id_Beneficiario ) as NombreBeneficiario
               FROM db_liwa.Maintenance_new M INNER JOIN db_liwa.User U ON M.Id_Beneficiario = U.Id_User Where
                (SELECT C.Id_Client FROM db_liwa.tipoProyecto C Where C.Id_Client = ${this.global.Id_Client} and C.id_tipoProyecto = M.idProyecto)
                AND M.IsDelete=0 AND M.Id_Maintenance = "${arg.event._def.publicId}";`,
        };
        this.global.consultar(pdata12, async (err9, response9) => {
          if (response9) {
            this.global.Id_Mantenimiento = response9[0];
            this.route.navigateByUrl("/ver-visita");
          } else if (response9 == undefined || response9 == null) {
            this.alerta.descErr(
              "Lastimosamente el registro aun no se encuentra!"
            );
          }

        })
      }
    });

  }
  updateHeader() {
    this.calendarOptions.headerToolbar = {
      left: "prev,next",
      center: "title",
      right: "",
    };
  }
  updateEvents(eventos: any[]) {

    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      // hiddenDays: [ 0 ],
      editable: true,
      // hiddenDays: [0],
      headerToolbar: {
        left: "prev",
        center: "title",
        right: "next",
      },
      locale: esLocale,
      events: eventos,

      dateClick: this.handleDateClick.bind(this),
      eventClick: this.handleEventClick.bind(this),
    };
  }
  ver(data) {
    // console.log(data, 'este es mi cocococococococouqiquqiquqi')
    this.global.Id_Mantenimiento = data;
    if (data[8] != "Terminado") {
      this.global.isEnd = false;
    } else {
      this.global.isEnd = true;
    }
    this.route.navigateByUrl("/ver-visita");
  }

  // vermant(data) {
  //   console.log("Datos mantenimiento!",data)
  //   this.global.edictMantenimiento = data[0];
  //   this.route.navigateByUrl("/ver-mantenimiento");
  // }
  ejecutar(data) {
    this.updateM = moment().unix();
    var query =
      "UPDATE db_liwa.Maintenance_new SET Status = 'Ejecución' WHERE Id_Maintenance ='" +
      data +
      "';";
    const pdata1 = { option: "insertar", texto: query };
    $.ajax({
      data: pdata1,
      cache: false,
      dataType: "json",
      type: "post",
      url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
      success(data, textStatus, jQxhr) {
        console.warn("bien", data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn("mal");
      },
    })
      .then((response) => {
        // this.ngOnInit();
      })
      .catch((err) => { });

    var query2 =
      "REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)" +
      " VALUES ('" +
      this.updateM +
      "','" +
      data[0] +
      "','" +
      this.global.Id_Usuario +
      "','" +
      moment().format("YYYY-MM-DD HH:mm:ss") +
      "','" +
      "El mantenimiento ha sido empezado" +
      "', '" +
      "En Ejecución" +
      "','" +
      0 +
      "','" +
      1 +
      "');";
    const pdata2 = { option: "insertar", texto: query2 };
    $.ajax({
      data: pdata2,
      cache: false,
      dataType: "json",
      type: "post",
      url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
      success(data, textStatus, jQxhr) {
        console.warn("bien", data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn("mal");
      },
    })
      .then((response) => {
        // location.reload();
        this.ngOnInit();
      })
      .catch((err) => { });
  }
  cerrarVisita(item) {
    this.spinner.show();
    let message: string = "El mantenimiento ha sido finalizado";
    let status: string = "Terminado";
    this.updateM = moment().unix();
    const query =
      'UPDATE db_liwa.Maintenance_new SET Status = "Terminado" ' +
      " WHERE (Id_Maintenance ='" +
      item[0] +
      "');";
    const pdata1 = { option: "insertar3", texto: query };
    this.global.consultar(pdata1, async (err, response) => {
      console.log(response, "estestetetetete", query);
      if (err == null && await response == true) {
        const query2 =
          "UPDATE db_liwa.Tickets_New SET Id_Statuses = 10 " +
          " WHERE (Id_Tickets ='" +
          item[9] +
          "');";
        const pdata2 = { option: "insertar3", texto: query2 };
        this.global.consultar(pdata2, async (err2, response2) => {
          console.log(response2, query2);
          if (err2 == null && await response2 == true) {
            const query3 =
              "REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)" +
              " VALUES ('" +
              this.updateM +
              "','" +
              item[0] +
              "','" +
              this.global.Id_Usuario +
              "','" +
              moment().format("YYYY-MM-DD HH:mm:ss") +
              "','" +
              "El mantenimiento ha sido finalizado" +
              "', '" +
              "Terminado" +
              "','" +
              0 +
              "','" +
              1 +
              "');";
            const pdata3 = { option: "insertar3", texto: query3 };
            this.global.consultar(pdata3, async (err3, response3) => {
              // console.log(response3, query3);
              if (err3 == null && await response3 == true) {
                this.alerta.descValid(
                  "La visita de mantenimiento se cerró con éxito"
                );
                this.enviarcorreo(item[0], message, status);
                this.spinner.hide();
                this.ngOnInit();
                // location.reload()
              } else {
                console.log(err3);
              }
            });
          }
        });
      }
    });
  }
  borrarRegistroVisita(item) {
    this.spinner.show();
    let message: string = "El registro del mantenimiento ha sido Eliminado";
    let status: string = "Terminado";
    this.updateM = moment().unix();
    const query =
      "UPDATE db_liwa.Maintenance_new SET IsDelete = 1 " +
      " WHERE (Id_Maintenance ='" +
      item[0] +
      "');";
    const pdata1 = { option: "insertar3", texto: query };
    this.global.consultar(pdata1, async (err, response) => {
      // console.log(response, "estestetetetete", query);
      if (err == null && response == true) {
        const query2 =
          "UPDATE db_liwa.Tickets_New SET Id_Statuses = 10 " +
          " WHERE (Id_Tickets ='" +
          item[9] +
          "');";
        const pdata2 = { option: "insertar3", texto: query2 };
        this.global.consultar(pdata2, async (err2, response2) => {
          // console.log(response2, query2);
          if (err2 == null && response2 == true) {
            const query3 =
              "REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)" +
              " VALUES ('" +
              this.updateM +
              "','" +
              item[0] +
              "','" +
              this.global.Id_Usuario +
              "','" +
              moment().format("YYYY-MM-DD HH:mm:ss") +
              "','" +
              "El mantenimiento ha sido finalizado" +
              "', '" +
              "Terminado" +
              "','" +
              0 +
              "','" +
              1 +
              "');";
            const pdata3 = { option: "insertar3", texto: query3 };
            this.global.consultar(pdata3, async (err3, response3) => {
              // console.log(response3, query3);
              if (err3 == null && response3 == true) {
                this.alerta.descValid(
                  "La visita de mantenimiento se elimino con éxito"
                );
                this.enviarcorreo(item[0], message, status);
                this.spinner.hide();
                this.ngOnInit();
                // location.reload()
              } else {
                console.log(err3);
              }
            });
          }
        });
      }
    });
  }
  openFormtoUpdate(content, item) {
    this.usrId = item[0];
    this.modalService
      .open(content, { ariaLabelledBy: "modal-updateVisit" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    // console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.photos = true;
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }
  loadPhoto() {
    this.updateM = moment().unix();

    if (this.srcImg == null || this.srcImg == undefined) {
      this.alerta.descErr("Error al subir la foto, verifique nuevamente");
    } else {
      this.updateF = this.global.Id_Usuario + "_" + moment().unix();
      //this.usrId = this.global.Id_Usuario + '_' + moment().unix();
      let con1 = 0;
      let con2 = 0;
      console.log(this.srcImg);
      for (let i = 0; i < this.srcImg.length; i++) {
        con1++;
        // console.log("Foto #" + i, this.srcImg[i]);
        const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
        // nombre de la imagen en el servidor this.Photo
        this.updateF = i + "_" + moment().unix();
        const imageName = i + "-" + moment().unix() + ".jpg";
        const imageBlob = this.dataURItoBlob(FOTOO);
        const imageFile = FOTOO;
        const data = new FormData();
        console.log(imageFile, imageBlob);
        data.append("image", imageFile);
        data.append("nombre", imageName);
        data.append("ruta", this.updateF);
        $.ajax({
          url: "https://fotos.engenius.com.co/subir_archivo_Mantenimiento.php",
          type: "post",
          dataType: "json",
          data,
          processData: false,
          contentType: false,
        }).then((data1) => {
          console.log(data, data1);
          this.imgsUrls[i] = data1;
          con2++;
          if (
            this.imgsUrls[i] == "BADEXT" ||
            this.imgsUrls[i] == null ||
            this.imgsUrls[i] == undefined ||
            this.imgsUrls[i] == "" ||
            this.imgsUrls[i] == "NULL"
          ) {
            this.alerta.descErr("Error al subir la foto, verifique nuevamente");
          } else {
            const query =
              "REPLACE INTO db_liwa.Maintenance_Update_Files (Id_FileM,Id_Maintenance,Id_Maintenance_Update,NameFile,TypeFile,File,IsDelete,IsActive)" +
              " VALUES ('" +
              this.updateF +
              "','" +
              this.usrId +
              "','" +
              this.updateM +
              "','" +
              imageName +
              "','" +
              "jpg" +
              "','" +
              data1 +
              "','" +
              0 +
              "','" +
              1 +
              "');";
            const pdata1 = { option: "insertar3", texto: query };
            this.global.consultar(pdata1, async (err, response) => {
              console.log(response, query);
              if (err == null && response == true) {
              } else {
                this.alerta.descErr(
                  "Error, verifique nuevamente que no hayan campos vacíos"
                );
              }
            });
            // goto crear ticket
            if (con1 == con2 && con2 == this.srcImg.length) {
              this.ngOnInit();
              this.actualizar();
            }
          }
        });
      }
    }
  }
  actualizar() {
    const query3 =
      "REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)" +
      " VALUES ('" +
      this.updateM +
      "','" +
      this.usrId +
      "','" +
      this.global.Id_Usuario +
      "','" +
      moment().format("YYYY-MM-DD HH:mm:ss") +
      "','" +
      "El mantenimiento ha sido actualizado" +
      "', '" +
      this.importance +
      "','" +
      0 +
      "','" +
      1 +
      "');";
    const pdata3 = { option: "insertar3", texto: query3 };
    this.global.consultar(pdata3, async (err3, response3) => {
      // console.log(response3, query3);
      if (err3 == null && response3 == true) {
        this.alerta.descValid("La visita de mantenimiento se cerró con éxito");
        this.ngOnInit();
      } else {
        console.log(err3);
      }
    });
    this.clear();
  }
  clear() {
    this.importance = "";
    this.maintDesc = "";
    this.srcImg = [];
  }
  newVisit() {
    this.global.withoutTicket = true;
    this.route.navigateByUrl("/crear-visita");
  }
  confirmState(data: any) {
    Swal.fire({
      title:
        '<strong>¿Está seguro que desea cambiar el estado de la visita a "En ejecución"?</strong>',
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ejecutar(data);
      } else {
        // this.ngOnInit();
      }
    });
  }
  deleteVal(data: any) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea cerrar la visita?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        this.cerrarVisita(data);
      } else {
        // this.ngOnInit();
      }
    });
  }
  deleteVal2(data: any) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea Eliminar este registro?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        this.borrarRegistroVisita(data);
      } else {
        // this.ngOnInit();
      }
    });
  }
  stateBadge(data: any) {
    if (data == "Agendado") {
      return "badge badge-warning";
    } else if (data == "Terminado") {
      return "badge badge-success";
    } else if (data == "Ejecución") {
      return "badge badge-leve";
    } else {
      return "badge badge-warning";
    }
  }
  isCalendar() {
    if (this.listActived === false && this.listForschedule === false) {
      this.calendarActived = !this.calendarActived;
      $(function () {
        this.table = $("#visitTable").DataTable({
          // defaultDate: '2022-08-15',
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.listActived = false;
      this.listForschedule = false;
      this.calendarActived = true;
      $(function () {
        this.table = $("#visitTable").DataTable({
          // defaultDate: '2022-08-15',
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  islist() {
    if (this.calendarActived === false && this.listForschedule === false) {
      this.listActived = !this.listActived;
      $(function () {
        this.table = $("#visitTable").DataTable({
          order: [[7, "asc"]],
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.calendarActived = false;
      this.listForschedule = false;
      this.listActived = true;
      $(function () {
        this.table = $("#visitTable").DataTable({
          order: [[7, "asc"]],
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  islistForschedule() {
    if (this.calendarActived === false && this.listActived === false) {
      this.listForschedule = !this.listForschedule;
      $(function () {
        this.table = $("#visitTable").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.calendarActived = false;
      this.listActived = false;
      this.listForschedule = true;
      $(function () {
        this.table = $("#visitTable").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  agendarVisita(
    idTickets,
    Cedula,
    idSeccional,
    Tipo,
    NProyecto,
    Nseccional,
    Descripcion
  ) {
    this.global.withoutTicket = false;
    // console.log(idSeccional, 'este deberia se el nombre de la mierda esa ')
    this.global.Id_Ticket = idTickets;
    this.global.selectedForVisit = [
      Tipo, // tipo de pqrs
      NProyecto, // nombre del proyecto
      Nseccional, // nombre de la Seccional
      idSeccional, // id seccional
      Cedula, // N id benefinicio cedula
      Descripcion,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    this.ruta.navigateByUrl("/crear-visita");
  }
  enviarcorreo(id_Mant, mssg, stts) {
    const pdata9 = {
      option: "mailVisitaC",
      mantenimiento: id_Mant.toString(),
      texto: stts.toString(),
      user: this.global.USERDATALIWA[8],
      novedad: mssg.toString(),
    };
    this.global.consultar2(pdata9, (err2, response2) => {
      if (response2) {
        console.log("Datos correo", response2);
      }
    });
  }
  formatt(item) {
    return moment(item).format('YYYY/MM/DD HH:mm')

  }
}
