import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-seccionales',
  templateUrl: './seccionales.component.html',
  styleUrls: ['./seccionales.component.scss']
})
export class SeccionalesComponent implements OnInit {
  seccionales = [];
  clientClass;
  flagSigModule: boolean = false;
  solanoFlag: boolean = false;
  latitude ;
  longitude;
  zoom;
  Style;
  tarifaKW; 
  Capa;
  AOM;
  distibucion;
  comercializacion_mes;
  yearSelected: number = parseInt(moment().format('yyyy'));
  months = ['Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Nombre',
    'Diciembre',
  ];
  monthSelected;
  constructor(public global: GlobalService,
    public modalService: NgbModal,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idProsec == undefined || this.global.nombreProyecto == undefined) {
      let valTemp: any;
      valTemp = sessionStorage.getItem('secTemp');
      if (valTemp != null) {
        valTemp = valTemp.split(',');
        this.global.idProsec = valTemp[0];
        this.global.nombreProyecto = valTemp[1];
        setTimeout(() => {
          let tempVar: any = [];
          tempVar.push(this.global.idProsec);
          tempVar.push(this.global.nombreProyecto);
          sessionStorage.setItem('secTemp', tempVar);
          this.clientClass = this.global.CLIENTDATALIWA[8];
          const pdata1 = { option: 'seccionales_table', idProyecto: this.global.idProsec };
          console.log('seccionales', pdata1);
          this.global.consultar(pdata1, (err1, response1) => {
            console.log('Datos seccionales', response1);
            this.seccionales = response1;
            $(function () {
              this.table = $('#seccionalTable').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
            this.spinner.hide();
          });
        }, 600);
      } else {
        this.ruta.navigateByUrl('/proyecto');
        this.spinner.hide();
      }
    } else {
      setTimeout(() => {
        let tempVar: any = [];
        tempVar.push(this.global.idProsec);
        tempVar.push(this.global.nombreProyecto);
        sessionStorage.setItem('secTemp', tempVar);
        this.clientClass = this.global.CLIENTDATALIWA[8];
        const pdata1 = { option: 'seccionales_table', idProyecto: this.global.idProsec };
        console.log('seccionales', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos seccionales', response1);
          this.seccionales = response1;
          $(function () {
            this.table = $('#seccionalTable').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
          this.spinner.hide();
        });
      }, 600);
    }
  }

  backBtn() {
    this.flagSigModule = false;
    $(function () {
      this.table = $('#seccionalTable').DataTable({
        'responsive': false,
        'destroy': true,
        'retrieve': true,
        'paging': true,
        'info': false,
        'pagingType': 'numbers',
        'language': {
          'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
        }
      });
    });
  }
  // ngOnChanges(changes):void{
  //   console.log(changes.init.currentValue)
  // }
  formatoMoneda() {
    new Intl.NumberFormat('de-DE').format(this.tarifaKW)
  }
  sigModule(idSec, nombreSec) {
    this.global.nombreSeccional = nombreSec;
    this.global.idSeccional = idSec;
    console.log(idSec);
    switch (idSec) {
      case '18':
        this.latitude = 4.5997156;
        this.longitude  = -71.4886296;
        this.zoom = 5;
        this.Style = [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ebe3cd"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#523735"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f1e6"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#c9b2a6"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#dcd2be"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#ae9e90"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dfd2ae"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dfd2ae"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#93817c"
              }
            ]
          },
          {
            "featureType": "poi.attraction",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.business",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.government",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.medical",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#a5b076"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#447530"
              }
            ]
          },
          {
            "featureType": "poi.place_of_worship",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.school",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.sports_complex",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f1e6"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#fdfcf8"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f8c967"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#e9bc62"
              }
            ]
          },
          {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e98d58"
              }
            ]
          },
          {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#db8555"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#806b63"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dfd2ae"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8f7d77"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#ebe3cd"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dfd2ae"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#c7f4ff"
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#92998d"
              }
            ]
          }
        ];
        this.flagSigModule = true;
        this.solanoFlag = true;
        break;

      default:
        break;
    }
  }
  createSeccional() {
    this.ruta.navigateByUrl('/crear-seccional');
  }

  IsActiveView(data) {
    if (data == 1) {
      return 'Activado';
    }
    if (data == 0) {
      return 'Desactivado';
    }
  }
  isActiveBadge(data) {
    if (data == 1) {
      return 'badge badge-success';
    }
    if (data == 0) {
      return 'badge badge-danger';
    }
    // this.ngOnInit();
  }

  editSec(idSec) {
    this.global.idSeccional = idSec;
    this.ruta.navigateByUrl('/editar-seccional')
  }
  verFormatos(idsec) {
    this.global.idSeccional = idsec
    this.ruta.navigateByUrl('/formato')
  }
  activar(idSec) {
    setTimeout(() => {
      var query = "UPDATE db_liwa.Seccionales SET IsActive = '1' WHERE Id_seccional ='" + idSec + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Seccional activada correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);
  }
  desactivar(idSec) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Seccionales SET IsActive = '0' WHERE Id_seccional ='" + idSec + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Seccional desactivada correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);
  }
  deleteSec(idSec) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar la seccional?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idSec);
      } else {
        // this.ngOnInit();
      }
    });
  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Seccionales SET IsDelete = '1' WHERE Id_seccional ='" + data + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid(' Seccional eliminada correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);

  }
  openEditRate(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-editRate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openEditNumRate(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-editNRate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  verBeneficiario(idSec, nombreSec) {
    this.global.nombreSeccional = nombreSec;
    this.global.idSeccional = idSec;
    this.ruta.navigateByUrl('/beneficiarios');
  }

  verMedidores(idSec, nombreSec) {
    this.global.nombreSeccional = nombreSec;
    this.global.idSeccional = idSec;
    this.ruta.navigateByUrl('/medidores');
  }

  verListados(idSec, nombreSec) {
    this.global.nombreSeccional = nombreSec;
    this.global.idSeccional = idSec;
    this.ruta.navigateByUrl('/inicial');
  }

  PuertoRico() {
    this.Capa = 'https://php.engenius.com.co/ArchivosKML/Puerto_Rico.kml';
  }
  Area() {
    this.Capa = 'https://php.engenius.com.co/ArchivosKML/Cartagena_del_Chaira.kml';
  }
  Inicial() {
    this.Capa = 'https://php.engenius.com.co/ArchivosKML/Cartagena_del_Chaira_Inicial.kml';
  }

}
