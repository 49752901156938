import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import domtoimage from 'dom-to-image';
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-geb-instalacion',
  templateUrl: './geb-instalacion.component.html'
})
export class GebInstalacionComponent implements OnInit {
  graphContent = [
    { titulo: 'PANEL SOLAR', imagen: '../../../assets/img/icons/common/svgEnergy/054-panel.svg' },
    { titulo: 'INVERSOR', imagen: '../../../assets/img/icons/common/svgEnergy/055-inversor.svg' },
    { titulo: 'SÁLIDA INSTALACIÓN', imagen: '../../../assets/img/icons/common/svgEnergy/056-salida.svg' },
    { titulo: 'CONTROLADOR', imagen: '../../../assets/img/icons/common/svgEnergy/042-gauge.svg' },
    { titulo: 'BATERÍA', imagen: '../../../assets/img/icons/common/svgEnergy/048-accumulator.svg' },
  ];
  imgInv1g;
  imgInv1c;
  imgInv2c;
  Filtro;
  infoFlag: boolean = false;
  valorPrueba = 350313;
  clientClass;
  diagramActive: boolean = true;
  historicActive: boolean = false;
  controlActive: boolean = false;
  floatOn: boolean;
  setDiagram: boolean = false;
  latitude = 4.56125;
  longitude = -72.994817;
  zoom = 14;
  mapType;
  FechaF;
  FechaI;
  Inv1;
  Style;
  Bandera1;
  imgEnergia = new Image();
  actualUser: any[] = [];
  arrDispac: any[] = [];
  Egen;
  Econ1;
  Toexcel = new Array();
  Time1 = new Array();
  filterContent = [{ nombre: 'Hoy' },
  { nombre: 'Ayer' },
  { nombre: 'Esta semana' },
  { nombre: 'Este mes' },
  { nombre: 'Este año' },
  { nombre: 'Última hora' },
  { nombre: 'Últimas 3 horas' },
  { nombre: 'Últimos 7 días' },
  { nombre: 'Últimos 30 días' },
  { nombre: 'Semana pasada' },
  { nombre: 'Mes pasado' },
  { nombre: 'Año pasado' },
  ];
  Interval;
  fromDate;
  toDate;
  showFlag: boolean = false;
  textoBoton = 'Hoy';
  consumeActive: boolean = true;
  generationActive: boolean = false;
  Dsips;
  PP = 0;
  PB = 0;
  PS = 0;
  color = 'rgb(102, 102, 102, 0.8)';
  colorg = 'rgb(102, 102, 102, 0.2)';
  Bandera = 0;
  picker;
  tema;
  SINDATOS = 0;
  Datos = new Array();
  Voltaje = new Array();
  Corriente = new Array();
  VoltajeP = new Array();
  CorrienteP = new Array();
  PotenciaP = new Array();
  VoltajeB = new Array();
  CorrienteB = new Array();
  PotenciaB = new Array();
  Fecha = new Array();
  Estadistico;
  ULTIMO = 'Cargando......';
  backgoru = new Array();
  Colore = new Array();
  backgoru2 = new Array();
  Colore2 = new Array();
  backgoru3 = new Array();
  Colore3 = new Array();
  backgoru4 = new Array();
  Colore4 = new Array();
  backgoru5 = new Array();
  Colore5 = new Array();
  backgoru6 = new Array();
  Colore6 = new Array();
  backgoru7 = new Array();
  Colore7 = new Array();
  backgoru8 = new Array();
  Colore8 = new Array();
  a = new Array();
  fechaH;
  date1;
  picker1 = new Array();
  GraficaCorriente;
  GraficaPotencia;
  Graficabateria;
  GraficaPanel;
  GraficaVoltaje;
  MAXFECHA = new Date();
  FECHA = new Date();
  Potencia = new Array();
  source = new Array();
  sma = new Array();
  constructor(private excelService: ExcelService,
    public global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    public alerta: GlobalAlertService,
    private sanitizer: DomSanitizer,) {
    this.global.initData();
  }

  ngOnInit(): void {
    // this.spinner.show();
    // this.Style = this.global.mapStyle;
    // this.clientClass = this.global.CLIENTDATALIWA[8];
    // setTimeout(() => {
    //   if (this.global.DeviceData == undefined || this.global.DeviceData == 'undefined') {
    //     this.global.DeviceData = localStorage.getItem('DEVICEDATA').split(',');
    //     console.log('DEVICEDATA', this.global.DeviceData);
    //     if (this.global.DeviceData == undefined) {
    //       this.global.TItulo = '';
    //     } else {
    //       this.global.TItulo = this.global.DeviceData[1]
    //     }
    //   } else {
    //     localStorage.setItem('DEVICEDATA', this.global.DeviceData);
    //     console.log('DEVICEDATA', this.global.DeviceData);
    //     if (this.global.DeviceData == undefined) {
    //       this.global.TItulo = '';
    //     } else {
    //       this.global.TItulo = this.global.DeviceData[1]
    //     }
    //   }
    //   if (this.global.ProjectData == undefined || this.global.ProjectData == 'undefined') {
    //     this.global.ProjectData = localStorage.getItem('PROJECTDATA').split(',');
    //     console.log('PROJECTDATA', this.global.ProjectData);
    //   } else {
    //     localStorage.setItem('PROJECTDATA', this.global.ProjectData);
    //     console.log('PROJECTDATA', this.global.ProjectData);
    //   }
    //   if (this.global.ProjectData[12] == 'EMS') {
    //     this.Dsips = this.global.DeviceData[15];
    //     console.log('IMEI', this.Dsips);
    //     this.Dsips = this.Dsips.split('-')[0];
    //     console.log('IMEI SPLIT', this.Dsips);
    //     //imei que se manda con un guión---
    //     this.Estadisticas();
    //     this.spinner.hide();
    //   }
    // }, 10000);
  }

  isDiagram() {
    if (this.diagramActive == false) {
      this.diagramActive = true;
      this.historicActive = false;
      this.setDiagram = true;
      this.showFlag = false;
    } else {
      this.diagramActive = false;
    }

  }
  // isHistoric() {
  //   if (this.historicActive == false) {
  //     this.historicActive = true;
  //     this.diagramActive = false;
  //     this.spinner.show();
  //     this.Estadisticas();
  //   } else {
  //     this.historicActive = false;
  //   }
  // }
  // Estadisticas() {
  //   this.Estadistico = new Array();
  //   this.Voltaje = new Array();
  //   this.Corriente = new Array();
  //   this.VoltajeP = new Array();
  //   this.CorrienteP = new Array();
  //   this.PotenciaP = new Array();
  //   this.VoltajeB = new Array();
  //   this.CorrienteB = new Array();
  //   this.PotenciaB = new Array();
  //   this.Fecha = new Array();
  //   this.backgoru = new Array();
  //   this.Colore = new Array();
  //   this.backgoru2 = new Array();
  //   this.Colore2 = new Array();
  //   this.backgoru3 = new Array();
  //   this.Colore3 = new Array();
  //   this.backgoru4 = new Array();
  //   this.Colore4 = new Array();
  //   this.backgoru5 = new Array();
  //   this.Colore5 = new Array();
  //   this.backgoru6 = new Array();
  //   this.Colore6 = new Array();
  //   this.backgoru7 = new Array();
  //   this.Colore7 = new Array();
  //   this.backgoru8 = new Array();
  //   this.Colore8 = new Array();
  //   const fechaI = moment(this.FECHA).format('YYYY-MM-DD 00:00');
  //   const fechaF = moment(this.FECHA).format('YYYY-MM-DD 23:59');
  //   const pdata1 = { option: 'devicedataemsfecha', device: this.Dsips, fecha: fechaI, fecha2: fechaF };
  //   this.fechaH = pdata1;
  //   console.log('##########33', pdata1);
  //   this.global.consultarvv(pdata1, (err, response) => {
  //     if (err == null) {
  //       console.log('DATA', response);
  //       if (response.length == 0) {
  //         this.SINDATOS = 1;
  //         this.Bandera = 4;
  //         const pdata4 = { option: 'devicedataemsfechaactual', device: this.Dsips };
  //         this.global.consultarvv(pdata4, (err4, response4) => {
  //           this.SINDATOS = 0;
  //           this.Datos = this.Estadistico = response4;
  //           this.PP = Math.round((this.Datos[0][8] / 100));
  //           this.PB = Math.round((this.Datos[0][4] / 100 * this.Datos[0][5] / 10));
  //           this.PS = Math.round((this.Datos[0][25] / 100 * this.Datos[0][26] / 10));
  //           const tiempo = moment().diff(this.Datos[0][22], 'minutes');
  //           if (tiempo <= 10) {
  //             this.Bandera = 1;
  //           } else if (tiempo > 10 && tiempo <= 60) {
  //             this.Bandera = 2;
  //           } else {
  //             this.Bandera = 3;
  //           }
  //           console.log('$$$$$$$$$$', fechaI, tiempo);
  //           for (let i = this.Estadistico.length - 1; i >= 0; i--) {
  //             if (i % 5 == 0) {
  //               this.Voltaje.push(this.Estadistico[i][25] / 100);
  //               this.Corriente.push(this.Estadistico[i][26] / 10);
  //               this.VoltajeP.push(this.Estadistico[i][6] / 100);
  //               this.CorrienteP.push(this.Estadistico[i][7] / 10);
  //               this.PotenciaP.push((this.Estadistico[i][8] / 100));
  //               this.VoltajeB.push(this.Estadistico[i][4] / 100);
  //               this.CorrienteB.push(this.Estadistico[i][5] / 10);
  //               this.PotenciaB.push(((this.Estadistico[i][4] / 100) * (this.Estadistico[i][5] / 10)));
  //               this.Fecha.push(moment(this.Estadistico[i][22]).format('YYYY-MM-DD HH:mm'));
  //               this.Potencia.push(((this.Estadistico[i][25] / 100) * (this.Estadistico[i][26] / 10)));
  //               this.backgoru.push('rgba(75, 200, 255, 0.5)');
  //               this.Colore.push('rgba(75, 200, 255, 1)');
  //               this.backgoru2.push('rgba(75, 200, 75, 0.5)');
  //               this.Colore2.push('rgba(75, 200, 75, 1)');
  //               this.backgoru3.push('rgba(255, 141, 96, 0.5)');
  //               this.Colore3.push('rgba(255, 141, 96, 1)');
  //               this.backgoru4.push('rgba(245, 124, 0, 0.2)');
  //               this.Colore4.push('rgba(245, 124, 0, 1)');
  //               this.backgoru5.push('rgba(31, 58, 255, 0.2)');
  //               this.Colore5.push('rgba(31, 58, 255, 1)');
  //               this.backgoru6.push('rgba(150, 100, 255, 0.3)');
  //               this.Colore6.push('rgba(150, 100, 255, 1)');
  //               this.backgoru7.push('rgba(134, 146, 159, 0.3)');
  //               this.Colore7.push('rgba(134, 146, 159, 1)');
  //               this.backgoru8.push('rgba(255, 59, 69, 0.3)');
  //               this.Colore8.push('rgba(255, 59, 69, 1)');
  //             }
  //           }
  //           let sum = 0;
  //           this.source = new Array();
  //           this.source[0] = (this.VoltajeP);
  //           this.source[1] = (this.CorrienteP);
  //           this.source[2] = (this.PotenciaP);
  //           this.source[3] = (this.VoltajeB);
  //           this.source[4] = (this.CorrienteB);
  //           this.source[5] = (this.PotenciaB);
  //           this.source[6] = (this.Potencia);
  //           console.log('source', this.source);
  //           if (this.source[0].length > 40) {
  //             for (let o = 0; o < this.source.length; o++) {
  //               this.sma[o] = new Array(this.source[o].length);
  //             }
  //             console.log('sma', this.sma);
  //             let period = Math.round(this.source[1].length / 4);
  //             let period2 = 10;
  //             if (period > 20) {
  //               period2 = 10;
  //               period = 15;
  //             }
  //             for (let t = 0; t < this.source.length; t++) {
  //               for (let p = 0; p < this.source[1].length; p++) {
  //                 for (let j = 0; j < period; j++) {
  //                   sum = sum + this.source[t][p - j];
  //                 }
  //                 this.sma[t][p] = (Math.round((sum / period) * 100)) / 100;
  //                 sum = 0;
  //               }
  //             }
  //             for (let t = 0; t < this.source.length; t++) {
  //               for (let p = 0; p < period; p++) {
  //                 for (let j = 0; j < period2; j++) {
  //                   sum = sum + this.source[t][p + j];
  //                 }
  //                 this.sma[t][p] = (Math.round((sum / period2) * 100)) / 100;
  //                 sum = 0;
  //               }
  //             }
  //             console.log('periodo', period);
  //             this.VoltajeP = this.sma[0];
  //             this.CorrienteP = this.sma[1];
  //             this.PotenciaP = this.sma[2];
  //             this.VoltajeB = this.sma[3];
  //             this.CorrienteB = this.sma[4];
  //             this.PotenciaB = this.sma[5];
  //             this.Potencia = this.sma[6];
  //           }
  //           console.log('Datos', this.VoltajeP);
  //           if (this.Datos.length > 0) {
  //             console.log('Datos---------------->', this.Datos);
  //             if (this.diagramActive == true) {
  //               this.setDiagram = true;
  //             } else {
  //               this.setDiagram = false;
  //             }
  //             setTimeout(() => {
  //               this.ProcesarData();
  //               this.ProcesarData2();
  //               this.ProcesarData4();
  //               this.ProcesarData5();
  //             }, 5000);
  //             this.spinner.hide();
  //           }
  //         });
  //       } else {
  //         this.SINDATOS = 0;
  //         this.Datos = this.Estadistico = response;
  //         this.PP = Math.round((this.Datos[0][8] / 100));
  //         this.PB = Math.round((this.Datos[0][4] / 100 * this.Datos[0][5] / 10));
  //         this.PS = Math.round((this.Datos[0][25] / 100 * this.Datos[0][26] / 10));
  //         const tiempo = moment().diff(this.Datos[0][22], 'minutes');
  //         if (tiempo <= 10) {
  //           this.Bandera = 1;
  //         } else if (tiempo > 10 && tiempo <= 60) {
  //           this.Bandera = 2;
  //         } else {
  //           this.Bandera = 3;
  //         }
  //         console.log('$$$$$$$$$$', fechaI, tiempo);
  //         for (let i = this.Estadistico.length - 1; i >= 0; i--) {
  //           if (i % 5 == 0) {
  //             this.Voltaje.push(this.Estadistico[i][25] / 100);
  //             this.Corriente.push(this.Estadistico[i][26] / 10);
  //             this.VoltajeP.push(this.Estadistico[i][6] / 100);
  //             this.CorrienteP.push(this.Estadistico[i][7] / 10);
  //             this.PotenciaP.push((this.Estadistico[i][8] / 100));
  //             this.VoltajeB.push(this.Estadistico[i][4] / 100);
  //             this.CorrienteB.push(this.Estadistico[i][5] / 10);
  //             this.PotenciaB.push(((this.Estadistico[i][4] / 100) * (this.Estadistico[i][5] / 10)));
  //             this.Fecha.push(moment(this.Estadistico[i][22]).format('YYYY-MM-DD HH:mm'));
  //             this.Potencia.push(((this.Estadistico[i][25] / 100) * (this.Estadistico[i][26] / 10)));
  //             this.backgoru.push('rgba(75, 200, 255, 0.5)');
  //             this.Colore.push('rgba(75, 200, 255, 1)');
  //             this.backgoru2.push('rgba(75, 200, 75, 0.5)');
  //             this.Colore2.push('rgba(75, 200, 75, 1)');
  //             this.backgoru3.push('rgba(255, 141, 96, 0.5)');
  //             this.Colore3.push('rgba(255, 141, 96, 1)');
  //             this.backgoru4.push('rgba(245, 124, 0, 0.2)');
  //             this.Colore4.push('rgba(245, 124, 0, 1)');
  //             this.backgoru5.push('rgba(31, 58, 255, 0.2)');
  //             this.Colore5.push('rgba(31, 58, 255, 1)');
  //             this.backgoru6.push('rgba(150, 100, 255, 0.3)');
  //             this.Colore6.push('rgba(150, 100, 255, 1)');
  //             this.backgoru7.push('rgba(134, 146, 159, 0.3)');
  //             this.Colore7.push('rgba(134, 146, 159, 1)');
  //             this.backgoru8.push('rgba(255, 59, 69, 0.3)');
  //             this.Colore8.push('rgba(255, 59, 69, 1)');
  //           }
  //         }
  //         let sum = 0;
  //         this.source = new Array();
  //         this.source[0] = (this.VoltajeP);
  //         this.source[1] = (this.CorrienteP);
  //         this.source[2] = (this.PotenciaP);
  //         this.source[3] = (this.VoltajeB);
  //         this.source[4] = (this.CorrienteB);
  //         this.source[5] = (this.PotenciaB);
  //         this.source[6] = (this.Potencia);
  //         console.log('source', this.source);
  //         if (this.source[0].length > 40) {
  //           for (let o = 0; o < this.source.length; o++) {
  //             this.sma[o] = new Array(this.source[o].length);
  //           }
  //           console.log('sma', this.sma);
  //           let period = Math.round(this.source[1].length / 4);
  //           let period2 = 10;
  //           if (period > 20) {
  //             period2 = 10;
  //             period = 15;
  //           }
  //           for (let t = 0; t < this.source.length; t++) {
  //             for (let p = 0; p < this.source[1].length; p++) {
  //               for (let j = 0; j < period; j++) {
  //                 sum = sum + this.source[t][p - j];
  //               }
  //               this.sma[t][p] = (Math.round((sum / period) * 100)) / 100;
  //               sum = 0;
  //             }
  //           }
  //           for (let t = 0; t < this.source.length; t++) {
  //             for (let p = 0; p < period; p++) {
  //               for (let j = 0; j < period2; j++) {
  //                 sum = sum + this.source[t][p + j];
  //               }
  //               this.sma[t][p] = (Math.round((sum / period2) * 100)) / 100;
  //               sum = 0;
  //             }
  //           }
  //           console.log('periodo', period);
  //           this.VoltajeP = this.sma[0];
  //           this.CorrienteP = this.sma[1];
  //           this.PotenciaP = this.sma[2];
  //           this.VoltajeB = this.sma[3];
  //           this.CorrienteB = this.sma[4];
  //           this.PotenciaB = this.sma[5];
  //           this.Potencia = this.sma[6];
  //         }
  //         console.log('Datos', this.VoltajeP);
  //         if (this.Datos.length > 0) {
  //           console.log('Datos---------------->', this.Datos);
  //           if (this.diagramActive == true) {
  //             this.setDiagram = true;
  //           } else {
  //             this.setDiagram = false;
  //           }
  //           setTimeout(() => {
  //             this.ProcesarData();
  //             this.ProcesarData2();
  //             this.ProcesarData4();
  //             this.ProcesarData5();
  //           }, 500);
  //           this.spinner.hide();
  //         }
  //       }
  //     }
  //   });
  // }
  // ProcesarData() {
  //   const canvas = document.getElementById('myChart') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.ColorLabels();
  //   this.GraficaVoltaje = null;
  //   this.GraficaVoltaje = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Fecha,
  //       datasets: [{
  //         label: 'Voltaje',
  //         data: this.Voltaje,
  //         yAxisID: 'VAC',
  //         backgroundColor: this.backgoru6,
  //         borderColor: this.Colore6,
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Corriente',
  //         data: this.Corriente,
  //         yAxisID: 'IAC',
  //         backgroundColor: this.backgoru,
  //         borderColor: this.Colore,
  //         borderWidth: 1,
  //         pointRadius: 0.5,
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 1
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  //   this.GraficaVoltaje.update();
  // }
  // ProcesarData2() {
  //   this.ColorLabels();
  //   const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.GraficaPanel = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Fecha,
  //       datasets: [{
  //         label: 'Voltaje Panel',
  //         data: this.VoltajeP,
  //         yAxisID: 'V',
  //         backgroundColor: this.backgoru,
  //         borderColor: this.Colore,
  //         borderWidth: 1,
  //         pointRadius: 0.8
  //       },
  //       {
  //         label: 'Corriente Panel',
  //         data: this.CorrienteP,
  //         yAxisID: 'I',
  //         backgroundColor: this.backgoru2,
  //         borderColor: this.Colore2,
  //         borderWidth: 1,
  //         pointRadius: 0.8
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           /* ticks: {
  //           fontColor: this.color,
  //           callback : function(item, index) {
  //               if (index % 5) { return ''; }
  //               return item;
  //           }
  //           }, */
  //           gridLines: {
  //             color: this.colorg, // Eje x color verde
  //           },
  //           display: true,
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Hora'
  //           }
  //         }],
  //         yAxes: [{
  //           id: 'V',
  //           type: 'linear',
  //           position: 'left',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false,
  //           },
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Voltaje(V)'
  //           }
  //         }, {
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //           id: 'I',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Corriente(A)'
  //           }
  //         }]
  //       }
  //     }
  //   });
  //   this.GraficaPanel.update();
  // }
  // ProcesarData4() {
  //   this.ColorLabels()
  //   const canvas = document.getElementById('myChart4') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.GraficaCorriente = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Fecha,
  //       datasets: [{
  //         label: 'Potencia Panel',
  //         data: this.PotenciaP,
  //         backgroundColor: this.backgoru4,
  //         borderColor: this.Colore4,
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Potencia Salida',
  //         data: this.Potencia,
  //         backgroundColor: this.backgoru5,
  //         borderColor: this.Colore5,
  //         borderWidth: 1,
  //         pointRadius: 0.5
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           }, gridLines: {
  //             color: this.colorg,
  //           },
  //           display: true,
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Hora'
  //           }
  //         }],
  //         yAxes: [{
  //           gridLines: {
  //             color: this.colorg,
  //             display: false,
  //           },
  //           type: 'linear',
  //           position: 'left',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //           },
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Potencia (W)'
  //           }
  //         }]
  //       }
  //     }
  //   });
  //   this.GraficaCorriente.update();
  // }
  // ProcesarData5() {
  //   this.ColorLabels()
  //   const canvas = document.getElementById('myChart5') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.Graficabateria = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Fecha,
  //       datasets: [{
  //         label: 'Voltaje Batería',
  //         data: this.VoltajeB,
  //         yAxisID: 'V',
  //         backgroundColor: this.backgoru7,
  //         borderColor: this.Colore7,
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       },
  //       {
  //         label: 'Corriente Batería',
  //         data: this.CorrienteB,
  //         yAxisID: 'I',
  //         backgroundColor: this.backgoru8,
  //         borderColor: this.Colore8,
  //         borderWidth: 1,
  //         pointRadius: 0.5
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //           display: true,
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Hora'
  //           }
  //         }],
  //         yAxes: [{
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //           id: 'V',
  //           type: 'linear',
  //           position: 'left',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //           }, scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Voltaje(V)'
  //           }
  //         }, {
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //           id: 'I',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Corriente(A)'
  //           }
  //         }]
  //       }
  //     }
  //   });
  //   this.Graficabateria.update();
  // }
  ColorLabels() {
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
  }

  switchFilter(data: string) {
    this.showFlag = true;
    this.Filtro = data;
    switch (data) {
      case 'Hoy':
        console.log('Hoy');
        this.showFlag = true;
        let fechaactual = new Date();
        console.log('fechaactual', fechaactual);
        this.FechaI = moment(fechaactual).format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual).format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Ayer':
        console.log('Ayer');
        this.showFlag = true;
        let fechaactual1 = new Date();
        console.log('fechaactual', fechaactual1);
        let opfecha = moment(fechaactual1).subtract(1, 'days');
        this.FechaI = moment(opfecha).format('YYYY-MM-DD 00:00');
        this.FechaF = moment(opfecha).format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Esta semana':
        console.log('Esta semana');
        this.showFlag = true;
        let fechaactual2 = new Date();
        console.log('fechaactual', fechaactual2);
        this.FechaI = moment(fechaactual2).startOf("isoWeek").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual2).endOf("isoWeek").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Este mes':
        console.log('Esta mes');
        this.showFlag = true;
        let fechaactual3 = new Date();
        console.log('fechaactual', fechaactual3);
        this.FechaI = moment(fechaactual3).startOf("month").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual3).endOf("month").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Este año':
        console.log('Este año');
        this.showFlag = true;
        let fechaactual4 = new Date();
        console.log('fechaactual', fechaactual4);
        this.FechaI = moment(fechaactual4).startOf("year").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual4).endOf("year").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Última hora':
        console.log('Última hora');
        this.showFlag = true;
        let fechaactual5 = new Date();
        console.log('fechaactual', fechaactual5);
        this.FechaI = moment(fechaactual5).subtract(1, "hour").format('YYYY-MM-DD HH:mm');
        this.FechaF = moment(fechaactual5).format('YYYY-MM-DD HH:mm');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Últimas 3 horas':
        console.log('Últimas 3 horas');
        this.showFlag = true;
        let fechaactual6 = new Date();
        console.log('fechaactual', fechaactual6);
        this.FechaI = moment(fechaactual6).subtract(3, "hour").format('YYYY-MM-DD HH:mm');
        this.FechaF = moment(fechaactual6).format('YYYY-MM-DD HH:mm');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Últimos 7 días':
        console.log('Últimos 7 días');
        this.showFlag = true;
        let fechaactual7 = new Date();
        console.log('fechaactual', fechaactual7);
        this.FechaI = moment(fechaactual7).subtract(7, "day").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual7).subtract(1, "day").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Últimos 30 días':
        console.log('Últimos 30 días');
        this.showFlag = true;
        let fechaactual8 = new Date();
        console.log('fechaactual', fechaactual8);
        this.FechaI = moment(fechaactual8).subtract(30, "day").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual8).subtract(1, "day").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Semana pasada':
        console.log('Semana pasada');
        this.showFlag = true;
        let fechaactua = new Date();
        console.log('fechaactual', fechaactua);
        this.FechaI = moment(fechaactua).subtract(1, "week").startOf("isoWeek").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactua).subtract(1, "week").endOf("isoWeek").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Mes pasado':
        console.log('Mes pasado');
        this.showFlag = true;
        let fechaactual31 = new Date();
        console.log('fechaactual', fechaactual31);
        this.FechaI = moment(fechaactual31).subtract(1, "month").startOf("month").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual31).subtract(1, "month").endOf("month").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Año pasado':
        console.log('Año pasado');
        this.showFlag = true;
        let fechaactual41 = new Date();
        console.log('fechaactual', fechaactual41);
        this.FechaI = moment(fechaactual41).subtract(1, "year").startOf("year").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual41).subtract(1, "year").endOf("year").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Personalizado':
        console.log('Personalizado');
        this.showFlag = true;
        let fechaactual51 = new Date();
        console.log('Personalizado fechaactual', fechaactual51);
        this.FechaI = moment(this.fromDate).format('YYYY-MM-DD 00:00');
        this.FechaF = moment(this.toDate).format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;

      default:

        this.showFlag = false;
        break;
    }

  }
  ConsultarInformacion() {
    const pdata1 = { option: 'devicedataemsfecha', device: this.Dsips, fecha: this.FechaI, fecha2: this.FechaF };
    this.global.consultarvv(pdata1, (err, response) => {
      if (err == null) {
        console.log('DATA devicedataemsfecha', response);
        if (response.length == 0) {
          this.SINDATOS = 1;
          this.Bandera = 4;const pdata4 = { option: 'devicedataemsfechaactual', device: this.Dsips };
          this.global.consultarvv(pdata4, (err4, response4) => {
            this.SINDATOS = 0;
            this.Datos = this.Estadistico = response4;
            this.PP = Math.round((this.Datos[0][8] / 100));
            this.PB = Math.round((this.Datos[0][4] / 100 * this.Datos[0][5] / 10));
            this.PS = Math.round((this.Datos[0][25] / 100 * this.Datos[0][26] / 10));
            const tiempo = moment().diff(this.Datos[0][22], 'minutes');
            if (tiempo <= 10) {
              this.Bandera = 1;
            } else if (tiempo > 10 && tiempo <= 60) {
              this.Bandera = 2;
            } else {
              this.Bandera = 3;
            }
            for (let i = this.Estadistico.length - 1; i >= 0; i--) {
              if (i % 5 == 0) {
                this.Voltaje.push(this.Estadistico[i][25] / 100);
                this.Corriente.push(this.Estadistico[i][26] / 10);
                this.VoltajeP.push(this.Estadistico[i][6] / 100);
                this.CorrienteP.push(this.Estadistico[i][7] / 10);
                this.PotenciaP.push((this.Estadistico[i][8] / 100));
                this.VoltajeB.push(this.Estadistico[i][4] / 100);
                this.CorrienteB.push(this.Estadistico[i][5] / 10);
                this.PotenciaB.push(((this.Estadistico[i][4] / 100) * (this.Estadistico[i][5] / 10)));
                this.Fecha.push(moment(this.Estadistico[i][22]).format('YYYY-MM-DD HH:mm'));
                this.Potencia.push(((this.Estadistico[i][25] / 100) * (this.Estadistico[i][26] / 10)));
                this.backgoru.push('rgba(75, 200, 255, 0.5)');
                this.Colore.push('rgba(75, 200, 255, 1)');
                this.backgoru2.push('rgba(75, 200, 75, 0.5)');
                this.Colore2.push('rgba(75, 200, 75, 1)');
                this.backgoru3.push('rgba(255, 141, 96, 0.5)');
                this.Colore3.push('rgba(255, 141, 96, 1)');
                this.backgoru4.push('rgba(245, 124, 0, 0.2)');
                this.Colore4.push('rgba(245, 124, 0, 1)');
                this.backgoru5.push('rgba(31, 58, 255, 0.2)');
                this.Colore5.push('rgba(31, 58, 255, 1)');
                this.backgoru6.push('rgba(150, 100, 255, 0.3)');
                this.Colore6.push('rgba(150, 100, 255, 1)');
                this.backgoru7.push('rgba(134, 146, 159, 0.3)');
                this.Colore7.push('rgba(134, 146, 159, 1)');
                this.backgoru8.push('rgba(255, 59, 69, 0.3)');
                this.Colore8.push('rgba(255, 59, 69, 1)');
              }
            }
            let sum = 0;
            this.source = new Array();
            this.source[0] = (this.VoltajeP);
            this.source[1] = (this.CorrienteP);
            this.source[2] = (this.PotenciaP);
            this.source[3] = (this.VoltajeB);
            this.source[4] = (this.CorrienteB);
            this.source[5] = (this.PotenciaB);
            this.source[6] = (this.Potencia);
            console.log('source', this.source);
            if (this.source[0].length > 40) {
              for (let o = 0; o < this.source.length; o++) {
                this.sma[o] = new Array(this.source[o].length);
              }
              console.log('sma', this.sma);
              let period = Math.round(this.source[1].length / 4);
              let period2 = 10;
              if (period > 20) {
                period2 = 10;
                period = 15;
              }
              for (let t = 0; t < this.source.length; t++) {
                for (let p = 0; p < this.source[1].length; p++) {
                  for (let j = 0; j < period; j++) {
                    sum = sum + this.source[t][p - j];
                  }
                  this.sma[t][p] = (Math.round((sum / period) * 100)) / 100;
                  sum = 0;
                }
              }
              for (let t = 0; t < this.source.length; t++) {
                for (let p = 0; p < period; p++) {
                  for (let j = 0; j < period2; j++) {
                    sum = sum + this.source[t][p + j];
                  }
                  this.sma[t][p] = (Math.round((sum / period2) * 100)) / 100;
                  sum = 0;
                }
              }
              console.log('periodo', period);
              this.VoltajeP = this.sma[0];
              this.CorrienteP = this.sma[1];
              this.PotenciaP = this.sma[2];
              this.VoltajeB = this.sma[3];
              this.CorrienteB = this.sma[4];
              this.PotenciaB = this.sma[5];
              this.Potencia = this.sma[6];
            }
            console.log('Datos', this.VoltajeP);
            if (this.Datos.length > 0) {
              console.log('Datos---------------->', this.Datos);
              if (this.diagramActive == true) {
                this.setDiagram = true;
              } else {
                this.setDiagram = false;
              }
              // setTimeout(() => {
              //   this.ProcesarData();
              //   this.ProcesarData2();
              //   this.ProcesarData4();
              //   this.ProcesarData5();
              // }, 500);
              this.spinner.hide();
            }
          });
        } else {
          this.SINDATOS = 0;
          this.Datos = this.Estadistico = response;
          this.PP = Math.round((this.Datos[0][8] / 100));
          this.PB = Math.round((this.Datos[0][4] / 100 * this.Datos[0][5] / 10));
          this.PS = Math.round((this.Datos[0][25] / 100 * this.Datos[0][26] / 10));
          const tiempo = moment().diff(this.Datos[0][22], 'minutes');
          if (tiempo <= 10) {
            this.Bandera = 1;
          } else if (tiempo > 10 && tiempo <= 60) {
            this.Bandera = 2;
          } else {
            this.Bandera = 3;
          }
          for (let i = this.Estadistico.length - 1; i >= 0; i--) {
            if (i % 5 == 0) {
              this.Voltaje.push(this.Estadistico[i][25] / 100);
              this.Corriente.push(this.Estadistico[i][26] / 10);
              this.VoltajeP.push(this.Estadistico[i][6] / 100);
              this.CorrienteP.push(this.Estadistico[i][7] / 10);
              this.PotenciaP.push((this.Estadistico[i][8] / 100));
              this.VoltajeB.push(this.Estadistico[i][4] / 100);
              this.CorrienteB.push(this.Estadistico[i][5] / 10);
              this.PotenciaB.push(((this.Estadistico[i][4] / 100) * (this.Estadistico[i][5] / 10)));
              this.Fecha.push(moment(this.Estadistico[i][22]).format('YYYY-MM-DD HH:mm'));
              this.Potencia.push(((this.Estadistico[i][25] / 100) * (this.Estadistico[i][26] / 10)));
              this.backgoru.push('rgba(75, 200, 255, 0.5)');
              this.Colore.push('rgba(75, 200, 255, 1)');
              this.backgoru2.push('rgba(75, 200, 75, 0.5)');
              this.Colore2.push('rgba(75, 200, 75, 1)');
              this.backgoru3.push('rgba(255, 141, 96, 0.5)');
              this.Colore3.push('rgba(255, 141, 96, 1)');
              this.backgoru4.push('rgba(245, 124, 0, 0.2)');
              this.Colore4.push('rgba(245, 124, 0, 1)');
              this.backgoru5.push('rgba(31, 58, 255, 0.2)');
              this.Colore5.push('rgba(31, 58, 255, 1)');
              this.backgoru6.push('rgba(150, 100, 255, 0.3)');
              this.Colore6.push('rgba(150, 100, 255, 1)');
              this.backgoru7.push('rgba(134, 146, 159, 0.3)');
              this.Colore7.push('rgba(134, 146, 159, 1)');
              this.backgoru8.push('rgba(255, 59, 69, 0.3)');
              this.Colore8.push('rgba(255, 59, 69, 1)');
            }
          }
          let sum = 0;
          this.source = new Array();
          this.source[0] = (this.VoltajeP);
          this.source[1] = (this.CorrienteP);
          this.source[2] = (this.PotenciaP);
          this.source[3] = (this.VoltajeB);
          this.source[4] = (this.CorrienteB);
          this.source[5] = (this.PotenciaB);
          this.source[6] = (this.Potencia);
          console.log('source', this.source);
          if (this.source[0].length > 40) {
            for (let o = 0; o < this.source.length; o++) {
              this.sma[o] = new Array(this.source[o].length);
            }
            console.log('sma', this.sma);
            let period = Math.round(this.source[1].length / 4);
            let period2 = 10;
            if (period > 20) {
              period2 = 10;
              period = 15;
            }
            for (let t = 0; t < this.source.length; t++) {
              for (let p = 0; p < this.source[1].length; p++) {
                for (let j = 0; j < period; j++) {
                  sum = sum + this.source[t][p - j];
                }
                this.sma[t][p] = (Math.round((sum / period) * 100)) / 100;
                sum = 0;
              }
            }
            for (let t = 0; t < this.source.length; t++) {
              for (let p = 0; p < period; p++) {
                for (let j = 0; j < period2; j++) {
                  sum = sum + this.source[t][p + j];
                }
                this.sma[t][p] = (Math.round((sum / period2) * 100)) / 100;
                sum = 0;
              }
            }
            console.log('periodo', period);
            this.VoltajeP = this.sma[0];
            this.CorrienteP = this.sma[1];
            this.PotenciaP = this.sma[2];
            this.VoltajeB = this.sma[3];
            this.CorrienteB = this.sma[4];
            this.PotenciaB = this.sma[5];
            this.Potencia = this.sma[6];
          }
          console.log('Datos', this.VoltajeP);
          if (this.Datos.length > 0) {
            console.log('Datos---------------->', this.Datos);
            if (this.diagramActive == true) {
              this.setDiagram = true;
            } else {
              this.setDiagram = false;
            }
            // setTimeout(() => {
            //   this.ProcesarData();
            //   this.ProcesarData2();
            //   this.ProcesarData4();
            //   this.ProcesarData5();
            // }, 500);
            this.spinner.hide();
          } else {
            console.log('no hay datos');
          }
        }
      }
    })
  }
}
