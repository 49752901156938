import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';

@Component({
  selector: 'app-historial-de-recargas',
  templateUrl: './historial-de-recargas.component.html'
})
export class HistorialDeRecargasComponent implements OnInit {
  clientClass;
  historyActive: boolean = false;
  DataRecargas = new Array();
  arrDispac: any[] = [];
  setDiagram: boolean = false;

  constructor(private spinner: NgxSpinnerService,
              public global: GlobalService
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 10000);
    const pdata2 = { option: 'ULT_Dispac' };
    this.global.consultar(pdata2, (err2, response2) => {
      this.arrDispac = response2;
      console.log(this.arrDispac);
      this.setDiagram = true;

    });
    this.clientClass = this.global.CLIENTDATALIWA[8];
    
    this.isHistory();
  }

  isHistory() {
    
      this.spinner.show();
      setTimeout(() => {
        const pdata2 = { option: 'inforec' };
        $.ajax({
          data: pdata2, cache: false, dataType: 'json', type: 'post',
          url: 'https://www.php.engenius.com.co/Database.php',
          success(data, textStatus, jQxhr) {
            console.log('Datos Recibidos:', data);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.log(JSON.stringify(errorThrown));
          }
        }).then((response) => {
          console.log('Data Recargas', response);
          this.DataRecargas = response;
          $(function () {
            var table = this.table = $('#listHistoryTable').DataTable({
              'responsive': true,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              //'orderCellsTop': true,
              'fixedHeader': false,
              "columns": [
                { title: 'Id Recarga' },
                { title: 'Usuario' },
                { title: 'Valor' },
                { title: 'Recaudador' },
                { title: 'Fecha' },
              ],
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              },
              'buttons': [
                'excel', 'pdf', 'print'
              ]
            });
            $('#listHistoryTable thead tr').clone(true).appendTo('#listHistoryTable thead');
            $('#listHistoryTable thead tr:eq(1) th').each(function (i) {
              var title = $(this).text();
              $(this).html('<input type="text" placeholder="Search ' + title + '" />');
              $('input', this).on('keyup change', function () {
                if (table.column(i).search() !== this.value) {
                  table.column(i)
                    .search(this.value)
                    .draw();
                }
              });
            });
          });
        });
        this.spinner.hide();
      }, 10000);
    } 
  }


