import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

import * as $ from "jquery";
import { ExcelService } from 'src/app/excel.service';


@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  clientClass;
  proyectos;
  seccionales;
  tenderos;
  tecnicos;
  pagos = new Array();
  Toexcel = new Array();
  nombreProyecto;
  buttonSeccional = false;
  tenderosSeccional = false;
  seccionalOn = false;
  tenderosOn = false;

  idSeccional = "Seleccione una seccional..."
  idProyecto = "Seleccione un proyecto..."

  idProyecto2: "Seleccione un proyecto...";
  idSeccional2: "Seleccione una seccional...";

  color1 = '#027fc2'
  color2 = '#027fc2'
  proyectoSeleccionado = "ninguno";
  seccionalSeleccionada = "ninguno";
  tenderoSeleccionado = "ninguno";

  text = "Seleccione un proyecto...";
  textS = "Seleccione una seccional...";

  Mantenimiento: any[] = [];

  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      const query = { option: 'insertar2', texto: `SELECT * FROM db_liwa.tipoProyecto Where Id_Client = ${this.global.Id_Client} AND IsDelete=0 AND IsActive=1 OR IsActive=0;` }
      this.global.consultar(query, (err, response) => {
        this.proyectos = response;
        this.spinner.hide();
      });
    }, 1000);
    setTimeout(() => {
      this.consultarPagos();
      $(function () {
        this.table = $("#pagos").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }, 800);
  }

  changeSelect(valueId) {
    this.spinner.show();
    this.idSeccional = "Seleccione una seccional...";

    const querySeccional = { option: 'insertar2', texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '${valueId}' AND IsDelete=0 AND IsActive=1;` }
    this.global.consultar(querySeccional, async (err4, response4) => {
      let res:any [] = await  response4;
      if (res.length == undefined || res.length == 0) {
        this.seccionales = [];
        // this.seccionalEdit = [];
        this.buttonSeccional = false;
        this.seccionalOn = false;
        this.tenderosOn = false;

        this.spinner.hide();
      } else {
        this.seccionales = response4;
        this.seccionalOn = true;
        this.buttonSeccional = true;
        this.tenderosOn = true;
        // this.seccionalEdit = response4;
        this.spinner.hide();
      }
    });
  }


  obtenerTenderos(valueId) {
    this.spinner.show();
    const query = {
      option: 'insertar2',
      texto: `SELECT *  FROM db_liwa.User A LEFT JOIN db_liwa.New_Tenderos B  ON B.Id_User =  A.Id_User  WHERE A.Id_Role = 14  AND A.IsDelete = 0 AND B.Id_Seccional = '${valueId}';`
    }

    this.global.consultar(query, async (err, response) => {
      const respuesta = await response;
      if (respuesta !== undefined) {
        this.tenderos = respuesta;
        this.tenderosSeccional = true;

        console.log("tenderos" + this.tenderos);
        setTimeout(() => {
          $(function () {
               this.table = $("#userTicket").DataTable({
                 order: true,
                 responsive: false,
                 destroy: true,
                 retrieve: true,
                 paging: true,
                 info: false,
                 pagingType: "numbers",
                 language: {
                   url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                 },
               });
             })
           // : console.log();
         },800);
        console.log(this.tenderos);
        this.spinner.hide();
      } else {
        this.tenderos = [];
        this.tenderosSeccional = false;
        this.spinner.hide();
      }
    });
  }


  async consultarPagos() {
    const query = {
      option: 'insertar2',
      texto: `
      SELECT Id_Liquidacion, Mes, Ano, Cupo_Asignado, Cupo_Gastado, N_Recargas, Comision, Id_User, Id_Proyecto, Id_Seccional, IsActive FROM db_liwa.Liquidacion;
      `
    };
    this.global.consultar(query, async (err, response) => {
      const respuesta = await response;
      if (respuesta !== undefined) {
        this.pagos = respuesta;
        console.log("tenderos" + this.pagos);
        setTimeout(() => {
          $(function () {
               this.table = $("#userTicket").DataTable({
                 order: true,
                 responsive: false,
                 destroy: true,
                 retrieve: true,
                 paging: true,
                 info: false,
                 pagingType: "numbers",
                 language: {
                   url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                 },
               });
             })
           // : console.log();
         },800)
        this.spinner.hide();
      } else {
        this.pagos = [];
        this.spinner.hide();
      }
    });
  }

  exportAsXLSX() {
    this.spinner.show();
    this.Toexcel = [[ 'id', 'Tendero', 'Identificacion', 'Cupo Total', 'Cupo Gastado', 'Usuarios Designados']];

    if(this.pagos.length == 0) {
      this.Toexcel.push(['NO DATA', 'NO DATA', 'NO DATA', 'NO DATA', 'NO DATA', 'NO DATA']);
    } else {
      for (let index = 0; index < this.pagos.length; index++) {
        this.Toexcel.push([this.pagos[index][1], this.pagos[index][1], this.pagos[index][1], this.pagos[index][1], this.pagos[index][1], this.pagos[index][1]]);
      }
    }

    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Datos', 'Recaudos');
      setTimeout(() => {
        this.spinner.hide();
        this.Toexcel = [[ 'id', 'Tendero', 'Identificacion', 'Cupo Total', 'Cupo Gastado', 'Usuarios Designados']];
      }, 1000);
    }, 1000);
  }

}
