import { Component, OnInit } from '@angular/core';
import { GlobalService } from "../../global.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from "xlsx"
 

@Component({
  selector: 'app-getion-llamadas',
  templateUrl: './getion-llamadas.component.html',
  styleUrls: ['./getion-llamadas.component.css']
})
export class GetionLlamadasComponent implements OnInit {

  clientClass
  file
  arrayBuffer

  constructor( public global: GlobalService,
               public modalService: NgbModal,
               private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
  }

  modal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-vinculate" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  referenciaArchivo(ev) {
    this.file = ev.target.files[0];
    // console.log(ev.target.files[0])
  }
  subiendoandoExcel() {
    this.spinner.show();
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      let MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      // console.log(MEDIDORESEXCEL.length, 'MEDIDORESEXCEL <--')
      MEDIDORESEXCEL.map(e => {
        console.log(e)
      })
      this.spinner.hide();
    };
    fileReader.readAsArrayBuffer(this.file);
  }
}
