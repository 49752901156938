import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { config } from 'rxjs';
// import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
// import * as moment from 'moment';
// import Swal from "sweetalert2";

@Component({
  selector: 'app-formulario-preliminar',
  templateUrl: './formulario-definitivo.component.html',
  styleUrls: ['./formulario-definitivo.component.scss']
})
@Component({
  selector: 'app-formulario-definitivo',
  templateUrl: './formulario-definitivo.component.html',
  styleUrls: ['./formulario-definitivo.component.scss']
})
export class FormularioDefinitivoComponent implements OnInit {
  clientClass;
  isEmpty: boolean = false;
  deviceFlag: boolean = false;
  openFlag: boolean = false;
  deviceUser: any[] = [];
  fotosFormulario: any[] = [];
  firma: any[] = [];
  devicePhotos: any[] = [];
  deviceData: any[] = [];
  formData: any[] = [];
  idDisp;
  editableDevice: any[] = [];
  deviceSelected:any[] = [];
  devPhotoSelected:any[] = [];
  marca;
  referencia;
  serial;
  tipo;
  estado;
  fechaInstalacion;
  pathDetailDevices;
  checkFirma: boolean = false;
  call: boolean = true;
  call1: boolean = true;
  call2: boolean = true;
  call3: boolean = true;
  call4: boolean = true;
  call5: boolean = true;
  call6: boolean = true;
  photoFlag:boolean = false;

  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idBenefit == undefined || this.global.idForm == undefined) {
      let valTemp: any;
      valTemp = sessionStorage.getItem('formTemp');
      if (valTemp != null) {
        valTemp = valTemp.split(',');
        this.global.idBenefit = valTemp[0];
        this.global.idForm = valTemp[1];

        setTimeout(() => {
          let tempVar: any = [];
          tempVar.push(this.global.idBenefit);
          tempVar.push(this.global.idForm);
          sessionStorage.setItem('formTemp', tempVar);

          this.clientClass = this.global.CLIENTDATALIWA;
          const pdata7 = { option: 'devices_user', idencuesta: this.global.idForm };
          console.log('devices_user', pdata7);
          this.global.consultar(pdata7, async (err7, response7) => {
            console.log('Datos devices_user', response7);
            this.deviceUser = await response7;
          });
          const pdata6 = { option: 'fotosformulario', idencuesta: this.global.idForm };
          console.log('fotosformulario', pdata6);
          this.global.consultar(pdata6, async (err6, response6) => {
            console.log('Datos fotosformulario', response6);
            this.fotosFormulario = await response6;
          });
          const pdata4 = { option: 'fotosfirma', idencuesta: this.global.idForm };
          console.log('fotosfirma', pdata4);
          this.global.consultar(pdata4, async (err4, response4) => {
            console.log('Datos fotosfirma', response4);
            this.firma = await response4;
            if (this.firma.length != 0) {
              this.isEmpty = false;
            }
            this.checkFirma = true;
          });
          const pdata3 = { option: 'devices_photos', idencuesta: this.global.idForm };
          console.log('devices_photos', pdata3);
          this.global.consultar(pdata3, async (err3, response3) => {
            console.log('devices_photos', response3);
            this.devicePhotos = await response3;
          });
          const pdata2 = { option: 'devices', idencuesta: this.global.idForm };
          console.log('devices', pdata2);
          this.global.consultar(pdata2, async (err2, response2) => {
            console.log('Datos devices', response2);
            this.deviceData = await response2;
            this.deviceFlag = true;
          });
          // setTimeout(() => {
            const pdata1 = { option: 'formulario', idencuesta: this.global.idBenefit };
            console.log('formulario', pdata1);
            this.global.consultar(pdata1, async (err1, response1) => {
              console.log('Datos formulario', response1);
              this.formData = await response1;
              this.openFlag = true;
              this.spinner.hide();
            });
          // }, 500);

        }, 500);
      } else {
        this.route.navigateByUrl('/proyecto');
        this.spinner.hide();
      }
    } else {
      console.log('entrando a else')
      setTimeout(() => {
        let tempVar: any = [];
        tempVar.push(this.global.idBenefit);
        tempVar.push(this.global.idForm);
        sessionStorage.setItem('formTemp', tempVar);

        this.clientClass = this.global.CLIENTDATALIWA;
        const pdata7 = { option: 'devices_user', idencuesta: this.global.idForm };
        console.log('devices_user', pdata7);
        this.global.consultar(pdata7, (err7, response7) => {
          console.log('Datos devices_user', response7);
          this.deviceUser = response7;
        });
        const pdata6 = { option: 'fotosformulario', idencuesta: this.global.idForm };
        console.log('fotosformulario', pdata6);
        this.global.consultar(pdata6, (err6, response6) => {
          console.log('Datos fotosformulario', response6);
          this.fotosFormulario = response6;
        });
        const pdata4 = { option: 'fotosfirma', idencuesta: this.global.idForm };
        console.log('fotosfirma', pdata4);
        this.global.consultar(pdata4, (err4, response4) => {
          console.log('Datos fotosfirma', response4);
          this.firma = response4;
          if (this.firma.length != 0) {
            this.isEmpty = false;
          }
          this.checkFirma = true;
        });
        const pdata3 = { option: 'devices_photos', idencuesta: this.global.idForm };
        console.log('devices_photos', pdata3);
        this.global.consultar(pdata3, (err3, response3) => {
          console.log('devices_photos', response3);
          this.devicePhotos = response3;
        });
        
        const pdata2 = { option: 'devices', idencuesta: this.global.idForm };
        console.log('devices', pdata2);
        this.global.consultar(pdata2, (err2, response2) => {
          console.log('Datos devices', response2);
          this.deviceData = response2;
          console.log('FOTOS EXISTENTES-----', this.deviceData);
          console.log('FOTOS EXISTENTES LENGHT-----', this.deviceData.length);
          this.deviceFlag = true;
        });
        setTimeout(() => {
          const pdata1 = { option: 'formulario', idencuesta: this.global.idBenefit };
          console.log('formulario', pdata1);
          this.global.consultar(pdata1, (err1, response1) => {
            console.log('Datos formulario', response1);
            this.formData = response1;
            this.openFlag = true;
            this.spinner.hide();
          });
        }, 500);

      }, 500);
    }
  }


  openViewDevice(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-editDevice' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  viewDevice(data, content) {
    console.log('Seleccionado', data, 'Id');
    this.idDisp = data;
    for (let i = 0; i < this.deviceData.length; i++) {
      if (this.deviceData[i][0] == data) {
        this.editableDevice[0] = this.deviceData[i];
      } else {
        continue;
      }
    }

    if (this.editableDevice[0] != undefined) {
      this.marca = this.editableDevice[0][2];
      this.referencia = this.editableDevice[0][3];
      this.serial = this.editableDevice[0][4];
      this.tipo = this.editableDevice[0][5];
      this.estado = this.editableDevice[0][6];
      this.fechaInstalacion = this.editableDevice[0][7];
    } else {
      this.editableDevice = [];
    }
    console.log(this.editableDevice);
    this.openViewDevice(content);
  }
  verFotoM3(data) {
    console.log('ruta', data);
    this.pathDetailDevices = data;
  }
  
  animateTab() {
    this.call = !this.call;
  }
  animateTab1() {
    this.call1 = !this.call1;
  }
  animateTab2() {
    this.call = !this.call;
  }
  animateTab3() {
    this.call = !this.call;
  }
  animateTab4() {
    this.call = !this.call;
  }
  animateTab5() {
    this.call = !this.call;
  }
  animateTab6() {
    this.call = !this.call;
  }
  animatedTab(numbr: number) {
    switch (numbr) {
      case 1:
        this.call = !this.call;
        break;
      case 2:
        this.call1 = !this.call1;
        break;
      case 3:
        this.call2 = !this.call2;
        break;
      case 4:
        this.call3 = !this.call3;
        break;
      case 5:
        this.call4 = !this.call4;
        break;
      case 6:
        this.call5 = !this.call5;
        break;
      case 7:
        this.call6 = !this.call6;
        break;

      default:
        break;
    }
  }
  deviceDetail(idDev, content) {
    for (let i = 0; i < this.deviceData.length; i++) {
      if(this.deviceData[i][0] == idDev){
       this.deviceSelected = this.deviceData[i];
      }
    }
    console.log(this.deviceSelected);
    this.openViewDetail(content);
  }
  openViewDetail(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-detDevice', size: 'xl', centered: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  detPhotoDetail (idDev, content){
    this.devPhotoSelected = [];
    const pdata3 = { option: 'device-detail-photos', idencuesta: idDev };
        console.log('device-detail-photos', pdata3);
        this.global.consultar(pdata3, (err3, response3) => {
          console.log('device-detail-photos', response3);
          this.devPhotoSelected = response3;
          this.photoFlag = true;
        });
    console.log(this.devPhotoSelected);
    this.openViewDevPhoto(content);
  }
  openViewDevPhoto(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-detPhDevice', size: 'xl', centered: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openSeeDetail(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-imgDetailDevices', size: 'xl', centered: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
