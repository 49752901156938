import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Router } from '@angular/router';
// import Chart from 'chart.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { GlobalAlertService } from 'src/app/global-alert.service';

@Component({
  selector: 'app-usuarios-nazareth',
  templateUrl: './usuarios-nazareth.component.html'
})
export class UsuariosNazarethComponent implements OnInit {
  clientClass;
  constructor(private spinner: NgxSpinnerService,
    private route: Router,
    private global: GlobalService,) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
  }

  goList(data) {
    switch (data) {
      case '1':
        this.route.navigateByUrl('/listado-preliminar-nazareth');
        break;
      case '2':
        this.route.navigateByUrl('/listado-definitivo-nazareth');
        break;

      default:
        break;
    }
  }
}
