import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import * as $ from 'jquery';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from "sweetalert2";
@Component({
  selector: 'app-listado-definitivo',
  templateUrl: './listado-definitivo.component.html',
  styleUrls: ['./listado-definitivo.component.scss']
})
export class ListadoDefinitivoComponent implements OnInit {
  clientClass;
  benefits = [];
  definitive = [];
  projects = [];
  idProject;
  idSeccional;
  seccionales = [];
  seccionalOn: boolean = false;
  verifyOn: boolean = false;
  tableOn: boolean = false;
  Excel;
  Toexcel;

  constructor(public global: GlobalService,
    public route: Router,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata3 = { option: 'tipoProyecto' };
      console.log('tipoProyecto', pdata3);
      this.global.consultar(pdata3, (err3, response3) => {
        console.log('Datos tipoProyecto', response3);
        this.projects = response3;
      });
      const pdata2 = { option: 'porcentaje' };
      console.log('porcentaje', pdata2);
      this.global.consultar(pdata2, (err2, response2) => {
        console.log('Datos porcentaje', response2);
        this.global.porcentaje = response2;
      });
      const pdata1 = { option: 'beneficiarios' };
      console.log('Beneficiarios', pdata1);
      this.global.consultar(pdata1, (err1, response1) => {
        console.log('Datos beneficiarios', response1);
        this.benefits = response1;
        for (let i = 0; i < this.global.porcentaje.length; i++) {
          if (this.global.porcentaje[i][1] == 1 && this.global.porcentaje[i][2] == 1 && this.global.porcentaje[i][3] == 1) {
            for (let j = 0; j < this.benefits.length; j++) {
              if (this.global.porcentaje[i][0] == this.benefits[j][1]) {
                if (this.benefits[j][15] == 0) {
                  this.definitive.push(this.benefits[j]);
                  console.log('definitivo', this.definitive);
                  this.tableOn = true;
                } else {
                  continue;
                }
              } else {
                continue;
              }
            }
          } else {
            continue;
          }
        }
        const pdata10 = { option: 'exceldef' };
        this.global.consultar(pdata10, (err10, response10) => {
          console.log('LISTADO EXCEL', response10);
          this.Excel = response10;
        });
        $(function () {
          this.table = $('#TableDefinitivo').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      });
    }, 1600);
  }
  verificarPorcentaje(idFormulario, caso: number): number {
    for (let i = 0; i < this.global.porcentaje.length; i++) {
      if (idFormulario == this.global.porcentaje[i][0]) {
        switch (caso) {
          case 1:
            if (this.global.porcentaje[i][1] == 0) {
              return 0;
            } else {
              return 1;
            }

          case 2:

            if (this.global.porcentaje[i][2] == 0) {
              return 0;
            } else {
              return 1;
            }

          case 3:

            if (this.global.porcentaje[i][3] == 0) {
              return 0;
            } else {
              return 1;
            }


          default:
            break;
        }
      } else {
        continue;
      }

    }
  }

  activarListPre(idBenefit) {

    console.log('id beneficiario', idBenefit);
  }
  desactivarListPre(idBenefit) {

    console.log('id beneficiario', idBenefit);
  }
  deleteValQ(idBenefit) {

    console.log('id beneficiario', idBenefit);
  }
  vinculateValQ(idBenefit, content) {
    this.global.idBenefit = idBenefit;
    console.log('id beneficiario', idBenefit);
    this.openFormVinc(content);
  }
  vinculate() {
    this.spinner.show();
    if (this.idSeccional == undefined || this.global.idBenefit == undefined || this.idProject == undefined) {
      this.alerta.descErr('Hay campos vacíos, verifique que exista el seccional o el proyecto');
    } else {
      const query = 'INSERT INTO db_liwa.Beneficiario_Seccional (Id_Beneficiario, Id_Seccional, Id_Proyecto, IsDelete)' +
        ' VALUES (\'' +
        this.global.idBenefit + '\',\'' +
        this.idSeccional + '\',\'' +
        this.idProject + '\',\'' +
        0 + '\');';
      const pdata1 = { option: 'insertar3', texto: query };
      this.global.consultar(pdata1, async (err, response) => {
        console.log(response, query);
        if (err == null && await response == true) {
          const query2 = 'UPDATE db_liwa.Beneficiario SET isSeccional = \'1' + '\' WHERE Id_Beneficiario = \'' + this.global.idBenefit + '\';';
          const pdata2 = { option: 'insertar3', texto: query2 };
          this.global.consultar(pdata2, async (err2, response2) => {
            console.log(response2, query2);
            if (err2 == null && await response2 == true) {
              this.idProject = '';
              this.idSeccional = '';
              $(function () {
                this.table = $('#TableDefinitivo').DataTable().clear().destroy();
              });
              this.alerta.descValid('El beneficiario se vinculó éxitosamente');
              this.ngOnInit();
            }
          });
        }
      });
    }
  }
  openFormVinc(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-vinculate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  searchSeccional(valueId) {
    const pdata4 = { option: 'seccionales', idProyecto: valueId };
    console.log('seccionales', pdata4);
    this.global.consultar(pdata4, async (err4, response4) => {
      console.log('Datos seccionales', response4);
      if (response4.legend) {
        this.seccionales = ['No hay registros de seccionales para este proyecto']
      } else {
        this.seccionales = await response4;
        this.seccionalOn = true;
      }
    });
  }

  downloadExcel() {
    console.log('Excel', this.Excel);
    this.Toexcel = [['Id', 'Nombre del beneficiario', 'Fecha', 'Departamento', 'Municipio', 'Latitud', 'Longitud', 'Altitud']];
    for (let p = 0; p < this.Excel.length; p++) {
      this.Toexcel.push(this.Excel[p]);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Listado Definitivo', 'Listado Definitivo');
      setTimeout(() => {
        this.Toexcel = [['Id', 'Nombre del beneficiario', 'Fecha', 'Departamento', 'Municipio', 'Latitud', 'Longitud', 'Altitud']];
      }, 1000);
    }, 1500);
  }
}
