import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Router } from '@angular/router';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as $ from 'jquery';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html'
})
export class InventarioComponent implements OnInit {
  inventario:any[] =[];
  clientClass;
  nameProduct;
  provProduct;
  kindProduct;
  refProduct;
  categProduct;
  minQuantity;
  constructor(public global: GlobalService,
    private modalService: NgbModal,
    public route: Router,
    public alerta:GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.spinner.show();
    setTimeout(() => {
      const pdata4 = { option: 'inventario'};
      console.log('inventario', pdata4);
      this.global.consultar(pdata4, (err4, response4) => {
      console.log('Datos inventario', response4);
      this.inventario = response4;
      $(function () {
        this.table = $('#TableInventario').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
        }
       });
     });
     this.spinner.hide();
      });
    }, 600);
   
  }
  deleteInv(idProd){
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el producto?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor:'#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          let query = 'UPDATE db_liwa.Inventario SET IsDelete = \'1\' WHERE Id_Producto =\'' + idProd +'\';';
          const pdata1 = {option: 'insertar', texto: query};
          $.ajax({data: pdata1,	cache: false,	dataType: 'json',	type:  'post',
            url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
            success( data, textStatus, jQxhr ) {
              console.warn('bien', data, textStatus);
            },
            error( jqXhr, textStatus, errorThrown ) {
            }
            }).then((response) => {
              this.alerta.descValid('Registro de producto eliminado con éxito');
              location.reload();
              
            }).catch((err) => {
  
            });
        } catch (error) {
          console.log(error);
        }
      }else{
        
        location.reload();
        
      }
    });
  }
  openFormCrear(content) {
    if( this.nameProduct != undefined || this.provProduct != undefined || this.kindProduct != undefined ||  this.refProduct != undefined || this.categProduct != undefined || this.minQuantity != undefined){
         this.nameProduct = '';
         this.provProduct = '';
         this.kindProduct = '';
         this.refProduct = '';
         this.categProduct = '';
         this.minQuantity = '';
         this.modalService.open(content, {ariaLabelledBy: 'modal-vinculate'}).result.then((result) => {
          // this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        }else{
            this.modalService.open(content, {ariaLabelledBy: 'modal-vinculate'}).result.then((result) => {
              // this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
  }
  create(){
    this.spinner.show();
    setTimeout(() => {
      try {
        setTimeout(() => {
          if (this.nameProduct == undefined || this.nameProduct == '' || this.provProduct == undefined || this.provProduct == '' || this.kindProduct == '' || this.refProduct == '' || this.categProduct == undefined || this.categProduct == '' || this.minQuantity == undefined || this.minQuantity == '') {
            this.alerta.descErr();
          } else {
            const query = 'INSERT INTO db_liwa.Inventario (Nombre_Producto,Proveedor,Categoria,Tipo,Referencia,IsDelete,IsActive,Cantidad_min)' +
            ' VALUES (\'' +
            this.nameProduct + '\',\'' +
            this.provProduct + '\',\'' +
            this.categProduct + '\',\'' +
            this.kindProduct + '\', \'' +
            this.refProduct + '\',\'' +
            0 + '\',\'' +
            1 + '\',\'' +
            this.minQuantity+ '\');';
            const pdata1 = {option: 'insertar', texto: query};
            this.global.consultar(pdata1, (err, response) => {
              console.log(response, query);
              if (err == null && response == true) {
                this.alerta.descValid('Producto creado con éxito');
                location.reload();
                this.spinner.hide();
              } else {
                 console.log(err);
              }
            });
          }
        }, 400);
        
      } catch (error) {
        console.log("error:" , +error);
        this.spinner.hide();
      }
    }, 60);
    
  }
  openFormEdit(content, idLinked){
    this.getLinked(idLinked);
    this.modalService.open(content, {ariaLabelledBy: 'modal-editvinculate'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getLinked(idLinked){
    if(idLinked == undefined){
      location.reload();
      }else{
        this.global.idLink = idLinked
        for (let i = 0; i < this.inventario.length; i++) {
          if(this.inventario[i][0] == idLinked){
          this.nameProduct = this.inventario[i][1];
          this.provProduct = this.inventario[i][2];
          this.kindProduct = this.inventario[i][4];
          this.refProduct = this.inventario[i][5];
          this.categProduct = this.inventario[i][3];
          this.minQuantity = this.inventario[i][11];
          }
        }
      }
  }
  edit(){
    this.spinner.show();
    // setTimeout(() => {
      try { 
        if( this.nameProduct == undefined || this.provProduct == undefined || this.kindProduct == undefined ||  this.refProduct == undefined || this.categProduct == undefined || this.minQuantity == undefined){
          this.alerta.descErr();
        }else{
          const query = 'UPDATE db_liwa.Inventario SET Nombre_Producto = \'' + this.nameProduct 
                                                       + '\',Proveedor = \'' + this.provProduct 
                                                       + '\',Categoria = \'' + this.categProduct 
                                                       + '\', Tipo = \'' + this.kindProduct 
                                                       + '\',Referencia = \'' + this.refProduct 
                                                       + '\',Cantidad_min = \'' + this.minQuantity 
                                                       +  '\' WHERE Id_Producto = \'' + this.global.idLink + '\'';
         
          const pdata1 = {option: 'insertar3', texto: query};
          this.global.consultar(pdata1,async (err, response) => {
            // console.log(response, query);
            if (err == null && await response == true) {
              //this.spinner.hide();
              this.alerta.descValid('Producto editado con éxito');
              location.reload();
              this.spinner.hide();
            } else {
               console.log(err);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    // }, 600);
   
  }


}
