import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-alistamiento',
  templateUrl: './alistamiento.component.html'
})
export class AlistamientoComponent implements OnInit {
  clientClass;
  kits = [];
  provKits = [];
  typeKit = [];
  checkProv: boolean = false;
  checkType: boolean = false;
  allOn: boolean = false;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) {
    this.global.initData();
  }
  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata3 = { option: 'proveedor_kit' };
      this.global.consultar(pdata3, (err3, response3) => {
        // console.log('proveedor_kit', response3);
        this.provKits = response3;
        this.checkProv = true;
      });
      const pdata4 = { option: 'tipo_kit' };
      this.global.consultar(pdata4, (err4, response4) => {
        this.typeKit = response4;
        // console.log('tipo_kit', response4);
        this.checkType = true;
      });
      const pdata5 = { option: 'kits' };
      this.global.consultar(pdata5, (err5, response5) => {
        this.kits = response5;
        // console.log('kits', response5);
        $(function () {
          this.table = $('#alisTable').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.allOn = true;
        this.spinner.hide();
      });

    }, 60);
  }
  ver(data) {
    // console.log(data);
  }
  deleteKit(data) {
    const query = 'UPDATE db_liwa.Kits SET IsDelete = \'1\' WHERE Id_Kit =\'' + data + '\';';
    // console.log('Seleccionado', query);
    const pdata1 = { option: 'insertar3', texto: query };
    $.ajax({
      data: pdata1, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn('mal');
      }
    }).then((response) => {
      // console.log(response);
      this.kits = [];
      this.ngOnInit();
    }).catch((err) => {

    });

  }
  //////////// DELETE VALIDATION ///////////////////////////

  deleteVal(data: any) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el kit?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteKit(data);
      } else {

        this.ngOnInit();

      }
    });
  }
  //END DELETE VALIDATION //
  nameProv(item) {
    for (let i = 0; i < this.provKits.length; i++) {
      if (item == this.provKits[i][0]) {
        return this.provKits[i][1];
      } else {
        continue;
      }
    }
  }
  nameType(item) {
    for (let i = 0; i < this.typeKit.length; i++) {
      if (item == this.typeKit[i][0]) {
        return this.typeKit[i][1];
      } else {
        continue;
      }
    }
  }

}
