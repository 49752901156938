import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  name;
  phone;
  mail;
  message;
  clientClass;

  constructor(private spinner: NgxSpinnerService, 
              private alert: GlobalAlertService, 
              private global: GlobalService, 
              private route: Router) {  
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
  }
  goBack(){
    window.history.back();
  }
  sendMail(){
    if(this.name == undefined || this.message == undefined ||  this.mail == undefined ||  this.phone == undefined ){
      this.alert.descErr();
    }else{
      setTimeout(() => {
        this.spinner.show();
        const pdata9 = { option: 'mailContact', contacto: this.name, texto: this.message, mailCont: this.mail, tel: this.phone}; 
        this.global.consultar(pdata9, (err2, response2) => {
        this.alert.descValid('Evaluaremos su mensaje y le daremos una pronta respuesta');
        this.name="";
        this.message="";
        this.mail="";
        this.phone="";
        this.route.navigateByUrl('/pagina-principal');
          this.spinner.hide();
          console.log('Datos correo', response2);
        });
        }, 500);  
    }
     
  } 
}
