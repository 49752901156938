import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-editar-seccional',
  templateUrl: './editar-seccional.component.html',
  styleUrls: ['./editar-seccional.component.scss']
})
export class EditarSeccionalComponent implements OnInit {
 
  nombreSeccional;
  deptoSeccional;
  municipioSeccional;
  entidadSeccional;
  comentariosSeccional;
  seccionales=[];
  clientClass;
  
  constructor(public global: GlobalService, public ruta: Router,public alerta:GlobalAlertService, public spinner:NgxSpinnerService ) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      if(this.global.idSeccional == undefined || this.global.idProsec == undefined){
        this.ruta.navigateByUrl('/proyecto');
        this.spinner.hide();
      }else{
        const pdata1 = { option: 'seccionales', idProyecto: this.global.idProsec};
        console.log('seccionales', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos seccionales', response1);
          this.seccionales = response1;
          for (let i = 0; i < this.seccionales.length; i++) {
            if(this.global.idSeccional == this.seccionales[i][0]){
              this.nombreSeccional = this.seccionales[i][2];
              this.deptoSeccional = this.seccionales[i][3];
              this.municipioSeccional = this.seccionales[i][4];
              this.entidadSeccional = this.seccionales[i][5];
              this.comentariosSeccional = this.seccionales[i][6];
            }else{
              continue;
            }
          }
        });
        this.spinner.hide();
      }
    }, 600);
   
  }

  goBack() {
    window.history.back();
  }
  editar(){
    this.spinner.show();
    // setTimeout(() => {
      if (this.nombreSeccional == undefined || this.deptoSeccional == undefined || this.municipioSeccional == undefined || this.entidadSeccional == undefined || this.comentariosSeccional == undefined) {
        
        this.alerta.descErr('Ningún campo puede ir vacío');
     
      } else {
        const query = 'UPDATE db_liwa.Seccionales SET Nombre =  \'' + this.nombreSeccional + '\', Departamento = \'' + this.deptoSeccional + '\', Municipio = \'' + this.municipioSeccional + '\', Entidad_Responsable = \'' + this.entidadSeccional + '\', Comentarios = \'' + this.comentariosSeccional + '\' WHERE Id_seccional = \'' + this.global.idSeccional + '\'';
        const pdata1 = {option: 'insertar3', texto: query};
        this.global.consultar(pdata1, async (err, response) => {
          console.log( await response, query);
          if (err == null && await response == true) {
            this.spinner.hide();
            this.alerta.descValid('El registro se ha actualizado correctamente');
            this.ruta.navigateByUrl('/seccionales');
          } 
        });
      }
      this.spinner.hide();
    // }, 500);
  }

}
