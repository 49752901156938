import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import domtoimage from 'dom-to-image';
import { ajax } from 'jquery';

import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-p-luminaria',
  templateUrl: './p-luminaria.component.html'
})
export class PLuminariaComponent implements OnInit {
  color = 'rgb(102, 102, 102, 0.8)';
  colorg = 'rgb(102, 102, 102, 0.2)';
  graphContent = [
    { titulo: 'PANEL SOLAR', imagen: '../../../assets/img/icons/common/svgEnergy/054-panel.svg' },
    { titulo: 'INVERSOR', imagen: '../../../assets/img/icons/common/svgEnergy/055-inversor.svg' },
    { titulo: 'SÁLIDA INSTALACIÓN', imagen: '../../../assets/img/icons/common/svgEnergy/056-salida.svg' },
    { titulo: 'CONTROLADOR', imagen: '../../../assets/img/icons/common/svgEnergy/042-gauge.svg' },
    { titulo: 'BATERÍA', imagen: '../../../assets/img/icons/common/svgEnergy/048-accumulator.svg' },
  ];
  imgInv1g;
  celula = "../../../assets/img/icons/common/svgEnergy/057-celula-solar.svg"
  imgInv1c;
  Filtro;
  infoFlag: boolean = false;
  valorPrueba = 350313;
  safeHtml: SafeHtml;
  clientClass;
  diagramActive: boolean = true;
  historicActive: boolean = false;
  photosActive: boolean = false;
  controlActive: boolean = false;
  floatOn: boolean;
  setDiagram: boolean = false;
  latitude = 4.56125;
  longitude = -72.994817;
  zoom = 4.5;
  mapType;
  FechaF;
  FechaI;
  Inv1;
  Style;
  Bandera1;
  imgEnergia = new Image();
  actualUser: any[] = [];
  arrDispac: any[] = [];
  Egen;
  Econ1;
  Toexcel = new Array();
  Time1 = new Array();
  filterContent = [{ nombre: 'Hoy' },
  { nombre: 'Ayer' },
  { nombre: 'Esta semana' },
  { nombre: 'Este mes' },
  { nombre: 'Este año' },
  { nombre: 'Última hora' },
  { nombre: 'Últimas 3 horas' },
  { nombre: 'Últimos 7 días' },
  { nombre: 'Últimos 30 días' },
  { nombre: 'Semana pasada' },
  { nombre: 'Mes pasado' },
  { nombre: 'Año pasado' },
  ];
  Url = 'ws://demo.engenius.com.co:8009/';
  Mensaje = '';
  SSID;
  PASS;
  websocket;
  Conectado = false;
  Chat = '';
  Mode = '';
  PWM = 0;
  Tipoo = '';
  Comandos;
  Comando = '';
  Principales;
  Comand = false;
  Cont = false;
  ComandGet;
  ComandSet;
  ComSel = '';
  ValSel = '';
  valor = false;
  Intervalo;
  fromDate;
  toDate;
  showFlag: boolean = false;
  textoBoton = 'Hoy';
  consumeActive: boolean = true;
  generationActive: boolean = false;
  graficos = [[0, '../../../assets/img/gallery/grapgh1.PNG'],
  [1, '../../../assets/img/gallery/grapgh2.PNG'],
  [2, '../../../assets/img/gallery/grapgh3.PNG'],
  [3, '../../../assets/img/gallery/grapgh4.PNG'],
  [4, '../../../assets/img/gallery/grapgh5.PNG'],
  ];
  indiceI;
  indiceJ;
  srcImgF;
  Datos = new Array();
  Poste = '';
  Poste2 = new Array();
  Poste5 = '';
  Poste4 = '';
  NewNdo = '';
  gridSolarData = [[0, 'A batería', '2.2 kWh', 'Uso directo', '1.2 kWh', 'Producción', '3.8 kWh', 'Consumo', '3.3 kWh'],
  [1, 'A batería', '3.2 kWh', 'Uso directo', '2.2 kWh', 'Producción', '4.8 kWh', 'Consumo', '4.3 kWh'],
  [2, 'A batería', '5.2 kWh', 'Uso directo', '4.2 kWh', 'Producción', '6.8 kWh', 'Consumo', '6.3 kWh'],
  [3, 'A batería', '6.2 kWh', 'Uso directo', '5.2 kWh', 'Producción', '7.8 kWh', 'Consumo', '7.3 kWh'],
  [4, 'A batería', '70.2 kWh', 'Uso directo', '63.2 kWh', 'Producción', '78.8 kWh', 'Consumo', '79.3 kWh']];
  dataGrid;
  Fecha = new Array();
  Dsips;
  FECHA = new Date();
  fechaH;
  bander = false;
  Postes;
  constructor(private excelService: ExcelService,
    public global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    public alerta: GlobalAlertService,
    private sanitizer: DomSanitizer,) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.Style = this.global.mapStyle;
    if (this.global.bSetup.length == 0) {
      this.route.navigateByUrl('/luminarias');
      this.spinner.hide();
    } else {
      setTimeout(() => {
        this.actualUser = this.global.bSetup;
        console.log(this.actualUser);
        this.clientClass = this.global.CLIENTDATALIWA[8];

        this.Dsips = this.actualUser[3];
        console.log('IDESP', this.Dsips);
        this.Estadisticas();
      }, 60);

    }

    const pdata1 = { option: 'getpostes', idpro: 8 };
    console.log(pdata1);
    ajax({
      data: pdata1, cache: false, dataType: 'json', type: 'post',
      url: ' https://www.php.engenius.com.co/DatabasePruebas.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Postes', response);
      this.Postes = response;
    });
    const pdata2 = { option: 'getcomand' };
    console.log(pdata2);
    ajax({
      data: pdata2, cache: false, dataType: 'json', type: 'post',
      url: ' https://www.php.engenius.com.co/DatabasePruebas.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Comandos', response);
      this.Comandos = response;
    });
    const pdata3 = { option: 'getprinc', idpro: 8 };
    console.log(pdata3);
    ajax({
      data: pdata3, cache: false, dataType: 'json', type: 'post',
      url: ' https://www.php.engenius.com.co/DatabasePruebas.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Comandos', response);
      this.Principales = response;
      this.GetComandos();
    });
  }

  doConnect() {
    this.websocket = new WebSocket(this.Url);
    this.websocket.onopen = (evt) => { this.onOpen(evt); };
    this.websocket.onclose = (evt) => { this.onClose(evt); };
    this.websocket.onmessage = (evt) => { this.onMessage(evt); };
    this.websocket.onerror = (evt) => { this.onError(evt); };
  }

  Seleccionar2() {
    console.log('Tipo', this.Tipoo);
    this.Comand = true;
  }

  Seleccionar() {
    console.log('Poste', this.Poste);
    this.Poste2 = this.Poste.split(',');
    this.Cont = true;
  }

  Seleccionar3() {
    console.log('Comando', this.ComSel);
    this.valor = true;
  }

  Cambio() {
    if (this.Mode == 'U') {

    }

  }

  Cambio2() {
    this.Cont = false;
  }
  Cambio4() {
    this.Comand = false;
  }

  Cambio3() {

  }


  SeleccionarC() {
    const idespp = this.Poste2[12];
    const idesp = this.Poste2[6];
    const idnode = this.Poste2[5];
    const idnodep = this.Poste2[11];
    let msgCMD = '';
    msgCMD = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tSINB-' + idnode + '-CMD-COM-' + this.Comando + '-id-' + idesp + '\r\nChecksum\t33\r\n';
    this.doSend(msgCMD);
  }

  SendSet() {
    console.log(this.ComSel, this.ValSel);
    for (let i = 0; i < this.Principales.length; i++) {
      console.log('Estoy en el for', i);
      const poste = this.Principales[i];
      const idespp = poste[12];
      const idesp = poste[6];
      const idnode = poste[5];
      const idnodep = poste[11];
      const comando = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tBRDD-' + idnode + '-SET-' + this.ComSel + '-' + this.ValSel + '-id-' + idesp + '\r\nChecksum\t33\r\n';
      this.doSend(comando);
    }
  }

  SendPCred() {
    console.log('SSID', this.SSID, 'PASS', this.PASS, 'POSTE', this.Poste4);
    if (this.SSID == undefined || this.SSID == null || this.SSID == '' || this.SSID == ' ' || this.PASS == undefined || this.PASS == null || this.PASS == '' || this.PASS == ' ') {
      console.log('CAMPOS VACIOS');
      alert('CAMPOS VACIOS');
    } else {
      // SINB-2132490624-SCRED_STA:FAMILIA HERNANDEZ[80070705064]
      const idnode = JSON.parse(this.Poste4);
      console.log('IDNODE', idnode, this.Poste4);
      const comando = 'USER\tSU_SERVER\r\nKW\t' + 'GET_ESP_CMD' + '\r\nPAYLOAD\tSINB-' + idnode + '-SCRED_STA:' + this.SSID + '[' + this.PASS + ']' + '\r\nChecksum\t33\r\n';
      this.doSend(comando);
      // alert(comando);
    }
  }

  SendGETCred() {
    const idnode = JSON.parse(this.Poste4);
    console.log('IDNODE', idnode, this.Poste4);
    const comando = 'USER\tSU_SERVER\r\nKW\t' + 'GET_ESP_CMD' + '\r\nPAYLOAD\tSINB-' + idnode + '-GCRED_STA' + '\r\nChecksum\t33\r\n';
    this.doSend(comando);
  }

  SendNewNodo() {
    console.log('NODO', this.NewNdo, 'POSTE', this.Poste5);
    if (this.NewNdo == undefined || this.NewNdo == null || this.NewNdo == '' || this.NewNdo == ' ') {
      console.log('CAMPOS VACIOS');
      alert('CAMPOS VACIOS');
    } else {
      // BRDD-3809837915-SET-NDR-3809837736-id-12157803
      const poste6 = this.Poste5.split(',');
      const idespp = poste6[12];
      const idesp = poste6[6];
      const idnode = poste6[5];
      const idnodep = poste6[11];
      console.log('IDNODE', idnode, poste6);
      const comando = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tSINB-' + idnode + '-SET-NDR-' + this.NewNdo + '-id-' + idesp + '\r\nChecksum\t33\r\n';
      this.doSend(comando);
      // alert(comando);
    }
  }

  SendNewNodoBRD() {
    console.log('NODO', this.NewNdo, 'POSTE', this.Poste5);
    if (this.NewNdo == undefined || this.NewNdo == null || this.NewNdo == '' || this.NewNdo == ' ') {
      console.log('CAMPOS VACIOS');
      alert('CAMPOS VACIOS');
    } else {
      // BRDD-3809837915-SET-NDR-3809837736-id-12157803
      for (let i = 0; i < this.Principales.length; i++) {
        console.log('Estoy en el for', i);
        const poste = this.Principales[i];
        const idespp = poste[12];
        const idesp = poste[6];
        const idnode = poste[5];
        const idnodep = poste[11];
        const comando = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tBRDD-' + idnode + '-SET-NDR-' + this.NewNdo + '-id-' + idesp + '\r\nChecksum\t33\r\n';
        this.doSend(comando);
      }
      // alert(comando);
    }
  }

  SendGet() {
    console.log(this.ComSel);
    for (let i = 0; i < this.Principales.length; i++) {
      console.log('Estoy en el for', i);
      const poste = this.Principales[i];
      const idespp = poste[12];
      const idesp = poste[6];
      const idnode = poste[5];
      const idnodep = poste[11];
      const comando = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tBRDD-' + idnode + '-GET-' + this.ComSel + '-' + 'id-' + idesp + '\r\nChecksum\t33\r\n';
      this.doSend(comando);
    }
  }

  SendSetNB() {
    console.log(this.ComSel, this.ValSel);
    const idespp = this.Poste2[12];
    const idesp = this.Poste2[6];
    const idnode = this.Poste2[5];
    const idnodep = this.Poste2[11];
    const comando = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tSINB-' + idnode + '-SET-' + this.ComSel + '-' + this.ValSel + '-id-' + idesp + '\r\nChecksum\t33\r\n';
    this.doSend(comando);
  }

  SendGetNB() {
    console.log(this.ComSel, this.ValSel);
    const idespp = this.Poste2[12];
    const idesp = this.Poste2[6];
    const idnode = this.Poste2[5];
    const idnodep = this.Poste2[11];
    const comando = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tSINB-' + idnode + '-GET-' + this.ComSel + '-id-' + idesp + '\r\nChecksum\t33\r\n';
    this.doSend(comando);
  }


  SeleccionarCBroad() {
    console.log('Encuentrame', this.Principales, this.Principales.length);
    for (let i = 0; i < this.Principales.length; i++) {
      console.log('Estoy en el for', i);
      const poste = this.Principales[i];
      const idespp = poste[12];
      const idesp = poste[6];
      const idnode = poste[5];
      const idnodep = poste[11];
      let msgCMD = '';
      msgCMD = 'USER\tSU_SERVER\r\nIDESP\t' + idespp + '\r\nID_NODE\tP_' + idnodep + '\r\nPAYLOAD\tBRDD-' + idnode + '-CMD-COM-' + this.Comando + '-id-' + idesp + '\r\nChecksum\t33\r\n';
      this.doSend(msgCMD);
    }
  }

  onMessage(evt) {
    const aux = evt.data;
    console.log(`Msg original: ${aux}`);
    if (aux.search('PAYLOAD\tEND-') > -1) {
      alert('COmando Recibido');
    } else if (aux.search('STATUS\tNEW_CONN') > -1) {
      //alert('Nueva Conexion');
    }
    this.writeToScreen('Rx: ' + evt.data + '\n');
    // Datos recibidos de un cliente ESP
    /*
      if (aux.search('NEW-CLIENT') > -1) {
        aux = aux.slice(aux.search('{') , aux.length);

        // console.log(`Aux: ${aux}`);
        const datos = JSON.parse(aux);

        // console.log(`MSG sin NEW-CLIENT: ${aux}, Msg JSON: ${datos.clientes[0]}`);
        //createClient(datos.clientes[0]);
      } else if (aux.search('ALL-CLIENTS') > -1) {
        aux = aux.slice(aux.search('{') , aux.length);

        const datos = JSON.parse(aux);

        const clientsNum = Object.keys(datos.clientes).length; // you get length json result
        // console.log(`MSG sin ALL-CLIENTS: ${aux}, N°CLient: ${clientsNum}`);
        // totalClientes = totalClientes +clientsNum;

        for (let i = 0; i < clientsNum ; i++) {
            createClient(datos.clientes[i]);
         }

      } else if (aux.search('REMOVE-CLIENT') > -1) {
        // REMOVE-CLIENT{ "clientes": [ {"IP":"127.0.0.1", "Puerto":51471}]}
        // Encuentra los primeros dos puntos,  quita [ { al inicio y al final }]]}
        // Mensaje recortado para formateo JSON {"IP":"127.0.0.1", "Puerto":51471}
        aux = aux.slice(aux.search(':') + 3 , aux.length - 2);
        removeClient(aux);
      }

    */
  }

  onOpen(evt) {
    console.log('Conectado');
    this.writeToScreen('connected\n');
    this.Conectado = true;
  }

  onClose(evt) {
    console.log('Descontado');
    this.writeToScreen('disconnected\n');
    this.Conectado = false;
  }

  onError(evt) {
    this.writeToScreen('error: ' + evt.data + '\n');
    this.websocket.close();
  }

  writeToScreen(message) {
    this.Chat += message;
    document.getElementById('MSG').scrollTop = document.getElementById('MSG').scrollHeight;
  }

  doDisconnect() {
    this.websocket.close();
  }

  doSend(message) {
    this.writeToScreen('sent: ' + message + '\n');
    console.log(`Enviando Msg : ${message}`);
    this.websocket.send(message);
  }

  clearText() {
    this.Chat = '';
  }

  Estadisticas() {
    this.spinner.show();
    const fechaI = moment(this.FECHA).format('YYYY-MM-DD 00:00');
    const fechaF = moment(this.FECHA).format('YYYY-MM-DD 23:59');
    const pdata1 = { option: 'devicedatalmsfecha', device: this.Dsips, fecha: fechaI, fecha2: fechaF };
    this.fechaH = pdata1;
    console.log('##########33', pdata1);
    this.global.consultarvv(pdata1, (err, response) => {
      if (err == null) {
        console.log('DATA', response);
        if (response.length == 0) {

        } else {
          this.Datos = response;
          this.fechaH = this.Datos[0][34];
          this.arrDispac = this.Datos[0];
          this.bander = true;
          console.log('#$#$#$#', this.fechaH, this.arrDispac);
          this.spinner.hide();
        }
      }
    });
  }

  isDiagram() {
    if (this.diagramActive == false) {
      this.diagramActive = true;
      this.historicActive = false;
      this.photosActive = false;
      this.controlActive = false;
      this.showFlag = false;
    } else {
      this.diagramActive = false;
    }

  }
  isHistoric() {
    if (this.historicActive == false) {
      this.historicActive = true;
      this.diagramActive = false;
      this.photosActive = false;
      this.controlActive = false;
    } else {
      this.historicActive = false;
    }

  }
  isPhotos() {
    if (this.photosActive == false) {
      this.photosActive = true;
      this.historicActive = false;
      this.diagramActive = false;
      this.controlActive = false;
      this.showFlag = false;
    } else {
      this.photosActive = false;
    }

  }

  GetComandos() {
    const pdata3 = { option: 'getcomandget' };
    console.log(pdata3);
    ajax({
      data: pdata3, cache: false, dataType: 'json', type: 'post',
      url: ' https://www.php.engenius.com.co/DatabasePruebas.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Comandos GET', response);
      this.ComandGet = response;
      this.GetComandos2();
    });
  }

  GetComandos2() {
    const pdata3 = { option: 'getcomandset' };
    console.log(pdata3);
    ajax({
      data: pdata3, cache: false, dataType: 'json', type: 'post',
      url: ' https://www.php.engenius.com.co/DatabasePruebas.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Comandos SET', response);
      this.ComandSet = response;
    });
  }
  showComp(numero: number) {
    for (let i = 0; i < this.graficos.length; i++) {
      if (numero == this.graficos[i][0]) {
        this.srcImgF = this.graficos[i][1];
        this.indiceI = i;
      }
    }
    for (let idx = 0; idx < this.gridSolarData.length; idx++) {
      if (this.gridSolarData[idx] !== undefined) {
        if (numero == this.gridSolarData[idx][0]) {
          this.dataGrid = this.gridSolarData[idx];
          this.indiceJ = idx;
        }
      }
    }
  }
  isControl() {
    if (this.controlActive == false) {
      this.controlActive = true;
      this.historicActive = false;
      this.photosActive = false;
      this.diagramActive = false;
      this.showFlag = false;
    } else {
      this.controlActive = false;
    }

  }

  // isGeneration() {
  //   if (this.consumeActive == false) {
  //     this.generationActive = !this.generationActive;

  //   } else {
  //     this.generationActive = true;
  //     this.consumeActive = false;
  //     setTimeout(() => {
  //       this.Energia();
  //     }, 500);
  //   }
  // }
  // isConsume() {
  //   if (this.generationActive == false) {
  //     this.consumeActive = !this.consumeActive;

  //   } else {

  //     this.generationActive = false;
  //     this.consumeActive = true;
  //     this.HabilitarGraficas();
  //   }
  // }
  //end secondary tabs

  PDFfotos() {
    this.imgInv1g = new Image();
    this.imgInv1c = new Image();
    this.spinner.show();
    try {
      const grafica1g = document.getElementById('inv1g');
      domtoimage.toPng(grafica1g).then((dataUrl1) => {
        this.imgInv1g.src = dataUrl1;
        const grafica1c = document.getElementById('inv1c');
        domtoimage.toPng(grafica1c).then((dataUrl1c) => {
          this.imgInv1c.src = dataUrl1c;
          setTimeout(() => {
            const imgData = new Image();
            imgData.crossOrigin = '';
            imgData.src = 'https://fotos.engenius.com.co' + this.global.CLIENTDATALIWA[13];
            const doc = new jsPDF();
            doc.setFontSize(14);
            const Title = ' Reporte Graficas Variables Electricas';
            doc.setTextColor(255, 107, 0);
            doc.text(Title, 105, 20, 'center');
            const SubTitle = 'Usuario: ' + this.actualUser[1];
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.text(SubTitle, 105, 30, 'center');
            doc.setFontSize(12);
            doc.autoTable({
              body: [
                ['Usuario: ' + this.actualUser[1], 'Filtro de Fechas: ' + this.Filtro],
                ['Fecha Inicial: ' + this.FechaI, 'Fecha Final: ' + this.FechaF],
              ], styles: { fontSize: 12 }
              , startY: 40
              , theme: 'grid'
            });
            doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
            doc.addImage(this.imgInv1g, 'PNG', 20, 70, 165, 88);
            doc.addImage(this.imgInv1c, 'PNG', 20, 178, 165, 88);
            doc.setTextColor(86, 86, 86);
            doc.setFontSize(10);
            doc.text('Consumo Inversor #1', 105, 170, 'center');
            doc.text('Generación Inversor #1', 105, 68, 'center');
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.addPage();
            const pageCount = doc.internal.getNumberOfPages();
            const arrr = new Array(pageCount);
            for (let j = 0; j < pageCount; j++) {
              arrr[j] = 'Hola';
            }
            setTimeout(() => {
              arrr.forEach((element, o) => {
                doc.setPage(o + 1);
                doc.setFontSize(8);
                const dia = moment();
                dia.locale('es');
                doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
                doc.setFontSize(8);
                doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
              });
            }, 100);
            setTimeout(() => {
              doc.save('INFORME_USER_' + this.actualUser[1] + '_' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
              setTimeout(() => {
                this.spinner.hide();
              }, 3500);
            }, 500);
          }, 500);
        });
      });
    } catch (err) {
      this.spinner.hide();
    }
  }

  PDFenergia() {
    this.spinner.show();
    try {
      const grafica4c = document.getElementById('energy');
      domtoimage.toPng(grafica4c).then((dataUrl4c) => {
        this.imgEnergia.src = dataUrl4c;
        setTimeout(() => {
          const imgData = new Image();
          imgData.crossOrigin = '';
          imgData.src = 'https://fotos.engenius.com.co' + this.global.CLIENTDATALIWA[13];
          const doc = new jsPDF();
          doc.setFontSize(14);
          const Title = ' Reporte Grafico de Energias';
          doc.setTextColor(255, 107, 0);
          doc.text(Title, 105, 20, 'center');
          const SubTitle = 'Usuario: ' + this.actualUser[1];
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(12);
          doc.text(SubTitle, 105, 30, 'center');
          doc.setFontSize(12);
          doc.autoTable({
            body: [
              ['Usuario: ' + this.actualUser[1], 'Filtro de Fechas: ' + this.Filtro],
              ['Fecha Inicial: ' + this.FechaI, 'Fecha Final: ' + this.FechaF],
            ], styles: { fontSize: 12 }
            , startY: 40
            , theme: 'grid'
          });
          doc.addImage(imgData, 'PNG', 10, 10, 25, 25);
          doc.addImage(this.imgEnergia, 'PNG', 0, 70, 204, 103);
          doc.setTextColor(86, 86, 86);
          doc.setFontSize(12);
          doc.text('Cuadro de Datos', 105, 185, 'center');
          doc.setTextColor(0, 0, 0);
          doc.autoTable({
            body: [
              ['Energía generada (Wh): ' + this.Egen, 'Energía Consumida (Wh): ' + this.Econ1]
            ], styles: { fontSize: 12 }
            , startY: 193
            , theme: 'grid'
          });

          const pageCount = doc.internal.getNumberOfPages();
          const arrr = new Array(pageCount);
          for (let j = 0; j < pageCount; j++) {
            arrr[j] = 'Hola';
          }
          setTimeout(() => {
            arrr.forEach((element, o) => {
              doc.setPage(o + 1);
              doc.setFontSize(8);
              const dia = moment();
              dia.locale('es');
              doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
              doc.setFontSize(8);
              doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
            });
          }, 100);
          setTimeout(() => {
            doc.save('INFORME_ENERGIAS_USER_' + this.actualUser[1] + '_' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
            setTimeout(() => {
              this.spinner.hide();
            }, 3500);
          }, 500);
        }, 500);
      });

    } catch (err) {
      this.spinner.hide();
    }
  }

  exportAsXLSX() {
    this.spinner.show();
    console.log('Excel', this.Inv1);
    this.Toexcel = [['SEDE:', this.actualUser[1], 'Rango de Fechas Seleccionado:', this.Filtro, 'Fecha Inicial:', this.FechaI, 'Fecha Final:', this.FechaF], [], ['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];

    if (this.Inv1.length == 0) {
      this.Toexcel.push(['NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS', 'NO DATA EN EL RANGO DE FECHAS']);
    } else {
      for (let index = 0; index < this.Inv1.length; index++) {
        this.Toexcel.push([this.Inv1[index][3], this.Inv1[index][5] / 100, this.Inv1[index][6] / 100, this.Inv1[index][7] / 100, this.Inv1[index][8] / 10, this.Inv1[index][9] / 10, this.Inv1[index][10] / 10, this.Inv1[index][11] / 100, this.Inv1[index][12] / 10, this.Inv1[index][13], this.Inv1[index][14] / 10, this.Inv1[index][15] / 10, this.Inv1[index][16] / 10, this.Inv1[index][17] / 10]);
      }
    }

    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Variables', 'Variables_Electricas_' + this.actualUser[1]);
      setTimeout(() => {
        this.spinner.hide();
        this.Toexcel = [['Fecha', 'Voltaje Linea 1 (V)', 'Voltaje Linea 2 (V)', 'Voltaje Linea 3 (V)', 'Corriente Linea 1 (A)', 'Corriente Linea 2 (A)', 'Corriente Linea 3 (A)', 'Voltaje de Panel (V)', 'Corriente de Panel (A)', 'Potencia de Panel (W)', 'Contador de Energia Generada (Wh)', 'Contador de Energia Consumida Linea 1 (Wh)', 'Contador de Energia Consumida Linea 2 (Wh)', 'Contador de Energia Consumida Linea 3 (Wh)']];
      }, 1000);
    }, 1500);
  }

  ColorLabels() {
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
  }

  switchFilter(data: string) {
    this.showFlag = true;
    this.Filtro = data;
    switch (data) {
      case 'Hoy':
        console.log('Hoy');
        this.showFlag = true;
        let fechaactual = new Date();
        console.log('fechaactual', fechaactual);
        this.FechaI = moment(fechaactual).format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual).format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Ayer':
        console.log('Ayer');
        this.showFlag = true;
        let fechaactual1 = new Date();
        console.log('fechaactual', fechaactual1);
        let opfecha = moment(fechaactual1).subtract(1, 'days');
        this.FechaI = moment(opfecha).format('YYYY-MM-DD 00:00');
        this.FechaF = moment(opfecha).format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Esta semana':
        console.log('Esta semana');
        this.showFlag = true;
        let fechaactual2 = new Date();
        console.log('fechaactual', fechaactual2);
        this.FechaI = moment(fechaactual2).startOf("isoWeek").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual2).endOf("isoWeek").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Este mes':
        console.log('Esta mes');
        this.showFlag = true;
        let fechaactual3 = new Date();
        console.log('fechaactual', fechaactual3);
        this.FechaI = moment(fechaactual3).startOf("month").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual3).endOf("month").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Este año':
        console.log('Este año');
        this.showFlag = true;
        let fechaactual4 = new Date();
        console.log('fechaactual', fechaactual4);
        this.FechaI = moment(fechaactual4).startOf("year").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual4).endOf("year").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Última hora':
        console.log('Última hora');
        this.showFlag = true;
        let fechaactual5 = new Date();
        console.log('fechaactual', fechaactual5);
        this.FechaI = moment(fechaactual5).subtract(1, "hour").format('YYYY-MM-DD HH:mm');
        this.FechaF = moment(fechaactual5).format('YYYY-MM-DD HH:mm');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Últimas 3 horas':
        console.log('Últimas 3 horas');
        this.showFlag = true;
        let fechaactual6 = new Date();
        console.log('fechaactual', fechaactual6);
        this.FechaI = moment(fechaactual6).subtract(3, "hour").format('YYYY-MM-DD HH:mm');
        this.FechaF = moment(fechaactual6).format('YYYY-MM-DD HH:mm');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Últimos 7 días':
        console.log('Últimos 7 días');
        this.showFlag = true;
        let fechaactual7 = new Date();
        console.log('fechaactual', fechaactual7);
        this.FechaI = moment(fechaactual7).subtract(7, "day").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual7).subtract(1, "day").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Últimos 30 días':
        console.log('Últimos 30 días');
        this.showFlag = true;
        let fechaactual8 = new Date();
        console.log('fechaactual', fechaactual8);
        this.FechaI = moment(fechaactual8).subtract(30, "day").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual8).subtract(1, "day").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Semana pasada':
        console.log('Semana pasada');
        this.showFlag = true;
        let fechaactua = new Date();
        console.log('fechaactual', fechaactua);
        this.FechaI = moment(fechaactua).subtract(1, "week").startOf("isoWeek").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactua).subtract(1, "week").endOf("isoWeek").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Mes pasado':
        console.log('Mes pasado');
        this.showFlag = true;
        let fechaactual31 = new Date();
        console.log('fechaactual', fechaactual31);
        this.FechaI = moment(fechaactual31).subtract(1, "month").startOf("month").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual31).subtract(1, "month").endOf("month").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;
      case 'Año pasado':
        console.log('Año pasado');
        this.showFlag = true;
        let fechaactual41 = new Date();
        console.log('fechaactual', fechaactual41);
        this.FechaI = moment(fechaactual41).subtract(1, "year").startOf("year").format('YYYY-MM-DD 00:00');
        this.FechaF = moment(fechaactual41).subtract(1, "year").endOf("year").format('YYYY-MM-DD 23:59');
        console.log('fechainicial', this.FechaI);
        console.log('fechafinal', this.FechaF);
        this.ConsultarInformacion();
        break;

      default:

        this.showFlag = false;
        break;
    }
  }

  ConsultarInformacion() {
    this.spinner.show();
    this.Inv1 = new Array();
    this.Bandera1 = true;
    const pdata9 = { option: 'devicedatalmsfecha', device: this.Dsips, fecha: this.FechaI, fecha2: this.FechaF };
    console.log('Data_Dispac', pdata9);
    this.global.consultarvv(pdata9, (err9, response9) => {
      console.log('Data_Dispac', response9);
      let data = response9;
      for (let index = 0; index < data.length; index++) {
        this.Inv1.push(data[index]);
      }
      setTimeout(() => {
        console.log('Result', this.Inv1);
        this.PrepararInformación();
      }, 500);


    });
  }

  Inicializar(cb) {
    this.Time1 = new Array(17);
    for (let index = 0; index < this.Time1.length; index++) {
      this.Time1[index] = new Array();
    }
    setTimeout(() => {
      cb(true);
    }, 500);
  }

  PrepararInformación() {
    this.Inicializar((respuesta) => {
      //Inversor1
      for (let index = 0; index < this.Inv1.length; index++) {
        this.Time1[0].push(this.Inv1[index][34]);//FECHA
        this.Time1[1].push(this.Inv1[index][5] / 100);//V3
        this.Time1[2].push(this.Inv1[index][6] / 100);//V2
        this.Time1[3].push(this.Inv1[index][29]);//V1
        this.Time1[4].push(this.Inv1[index][8] / 10);//I3
        this.Time1[5].push(this.Inv1[index][9] / 10);//I2
        this.Time1[6].push(this.Inv1[index][4] / 1000);//I1
        this.Time1[7].push(this.Inv1[index][6] / 1000);//VPV
        this.Time1[8].push(this.Inv1[index][12] / 10);//IPV
        this.Time1[9].push(this.Inv1[index][13]);//PPV
        this.Time1[10].push(this.Inv1[index][14]);//E_G
        this.Time1[11].push(this.Inv1[index][15]);//E_C1
        this.Time1[12].push(this.Inv1[index][16]);//E_C2
        this.Time1[13].push(this.Inv1[index][17]);//E_C3
        this.Time1[14].push(this.Inv1[index][4] / 1000);//V
        this.Time1[15].push(this.Inv1[index][5] / 1000);//I
        this.Time1[16].push(this.Inv1[index][17]);//SOC
      }
      setTimeout(() => {
        console.log('Datos a Graficar', this.Time1);
        // this.HabilitarGraficas();
      }, 500);
    });
  }

  // HabilitarGraficas() {
  //   if (this.Inv1.length == 0) {
  //     this.Bandera1 = true;
  //   } else {
  //     this.Bandera1 = false;
  //     setTimeout(() => {
  //       this.Inversor1a();
  //       this.Inversor1b();
  //       this.Inversor1c();
  //     }, 200);
  //   }
  //   this.spinner.hide();
  // }

  // Inversor1a() {
  //   const canvas = document.getElementById('inv1g') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time1[0],
  //       datasets: [{
  //         label: 'Voltaje Panel',
  //         data: this.Time1[7],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0.3)',
  //         borderColor: 'rgb(163, 199, 255, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0, // animation duration after a resize
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 50
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor1b() {
  //   const canvas = document.getElementById('inv1c') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time1[0],
  //       datasets: [{
  //         label: 'Voltaje Bateria',
  //         data: this.Time1[6],
  //         yAxisID: 'VAC',
  //         backgroundColor: 'rgb(163, 199, 255, 0.2)',
  //         borderColor: 'rgb(163, 199, 255, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6,
  //       },
  //       {
  //         label: 'PWM',
  //         data: this.Time1[3],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(100, 208, 0, 0.2)',
  //         borderColor: 'rgb(100, 208, 0, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Voltaje(V)',
  //             fontColor: this.color,
  //           },
  //           ticks: {
  //             fontColor: this.color, // Cambiar color de labels
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 20
  //           },
  //           gridLines: {
  //             color: this.color,
  //             display: false
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true,
  //             suggestedMin: 0,
  //             suggestedMax: 550
  //           },
  //           position: 'right',
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Corriente(A)',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }

  // Inversor1c() {
  //   const canvas = document.getElementById('inv1b') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let grafica = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.Time1[0],
  //       datasets: [{
  //         label: 'Voltaje Bateria',
  //         data: this.Time1[14],
  //         yAxisID: 'VBAT',
  //         backgroundColor: 'rgb(248, 97, 0, 0.2)',
  //         borderColor: 'rgb(248, 97, 0, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6,
  //         type: 'line',
  //       },
  //       {
  //         label: 'Corriente Bateria',
  //         data: this.Time1[15],
  //         yAxisID: 'IAC',
  //         backgroundColor: 'rgb(255, 208, 0, 0.2)',
  //         borderColor: 'rgb(255, 208, 0, 1)',
  //         borderWidth: 1,
  //         pointRadius: 0.6
  //       }]
  //     },
  //     options: {
  //       animation: {
  //         duration: 0 // general animation time
  //       },
  //       hover: {
  //         animationDuration: 0 // duration of animations when hovering an item
  //       },
  //       responsiveAnimationDuration: 0,
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           type: 'time',
  //           time: {
  //             parser: 'YYYY-MM-DD HH:mm',
  //             tooltipFormat: 'YYYY-MM-DD HH:mm',
  //             displayFormats: {
  //               minute: 'HH:mm',
  //             }
  //           },
  //           display: true,
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Hora',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //         }],
  //         yAxes: [
  //           {
  //             id: 'VBAT',
  //             type: 'linear',
  //             position: 'left',
  //             scaleLabel: {
  //               display: true,
  //               labelString: 'Voltaje(V)',
  //               fontColor: this.color,
  //             },
  //             ticks: {
  //               fontColor: this.color, // Cambiar color de labels
  //               beginAtZero: true,
  //               suggestedMin: 0,
  //               suggestedMax: 20
  //             },
  //             gridLines: {
  //               color: this.color,
  //               display: false
  //             }
  //           }, {
  //             id: 'IAC',
  //             type: 'linear',
  //             ticks: {
  //               fontColor: this.color,
  //               beginAtZero: true,
  //               suggestedMin: -10,
  //               suggestedMax: 10
  //             },
  //             position: 'right',
  //             scaleLabel: {
  //               display: true,
  //               labelString: 'Corriente(A)',
  //               fontColor: this.color,
  //             },
  //             gridLines: {
  //               color: this.colorg,
  //               display: false
  //             },
  //           }]
  //       }
  //     }
  //   });
  // }



  // Energia() {
  //   let energiagenerada = 0;
  //   let e1 = this.Time1[10][this.Time1[10].length - 1];
  //   energiagenerada = (e1) / 10;
  //   console.log('Energia Generada', e1);
  //   // EC1

  //   let energiaconsumida1 = 0;
  //   let ec1 = this.Time1[11][this.Time1[11].length - 1];



  //   this.Egen = energiagenerada;
  //   this.Econ1 = (ec1) / 10;;
  //   const canvas = document.getElementById('energy') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   var myDoughnutChart = new Chart(ctx, {
  //     type: 'doughnut',
  //     data: {
  //       datasets: [{
  //         data: [energiagenerada, energiaconsumida1],
  //         backgroundColor: ['rgb(139, 201, 100, 1)', 'rgb(71, 144, 208, 1)']
  //       }],

  //       // These labels appear in the legend and in the tooltips when hovering different arcs
  //       labels: [
  //         'Energia Generada',
  //         'Energia Consumida',
  //       ]
  //     }
  //   });
  // }

  // //  FIN DIAGRAMA FUNCTION //
  // changeFrom(data) {
  //   data = moment(data).format("yyyy-MM-ddTHH:mm");
  //   let arrdata = data.split(' ');
  //   let today = new Date().toLocaleString();
  //   let arrToday = today.split(' ');
  //   let date = arrdata[0].split('/');
  //   let todayDat = arrToday[0].split('/');


  //   if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) > parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) > parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) > parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) < parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //   } else if (parseInt(date[0]) == parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //     if (parseInt(arrdata[1]) > parseInt(arrToday[1]) || parseInt(arrdata[1].split(':')[1]) > parseInt(arrToday[1].split(':')[1])) {
  //       this.alerta.descErr('La hora que ingresaste es mayor a la actual');
  //       this.showFlag = false;
  //       this.toDate = "";
  //     } else {
  //       if (this.fromDate != undefined && this.toDate != undefined) {
  //         let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //         let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //         this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //         this.showFlag = true;
  //       }
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) < parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) < parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       let from = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       let to = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.textoBoton = 'Desde: ' + from + ' hasta: ' + to;
  //       this.showFlag = true;
  //     }
  //   }

  // }
  // changeTo(data) {
  //   data = moment(data).format("yyyy-MM-ddTHH:mm");
  //   let arrdata = data.split(' ');
  //   let today = new Date().toLocaleString();
  //   let arrToday = today.split(' ');
  //   let date = arrdata[0].split('/');
  //   let todayDat = arrToday[0].split('/');

  //   if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) > parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) > parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) > parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     this.alerta.descErr('La fecha que ingresaste es mayor a la actual');
  //     this.showFlag = false;
  //     this.fromDate = "";
  //   } else if (parseInt(date[0]) < parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //     }
  //   } else if (parseInt(date[0]) == parseInt(todayDat[0]) && parseInt(date[1]) == parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       this.showFlag = true;
  //     }
  //     if (parseInt(arrdata[1]) > parseInt(arrToday[1]) || parseInt(arrdata[1].split(':')[1]) > parseInt(arrToday[1].split(':')[1])) {
  //       this.alerta.descErr('La hora que ingresaste es mayor a la actual');
  //       this.showFlag = false;
  //       this.toDate = "";
  //     } else {
  //       if (this.fromDate != undefined && this.toDate != undefined) {
  //         this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //         this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //       }
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && parseInt(date[1]) < parseInt(todayDat[1]) && parseInt(date[2]) == parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //     }
  //   } else if ((parseInt(date[0]) > parseInt(todayDat[0]) || parseInt(date[0]) <= parseInt(todayDat[0])) && (parseInt(date[1]) > parseInt(todayDat[1]) || parseInt(date[1]) <= parseInt(todayDat[1])) && parseInt(date[2]) < parseInt(todayDat[2])) {
  //     if (this.fromDate != undefined && this.toDate != undefined) {
  //       this.fromDate = moment(this.fromDate).format("yyyy-MM-ddTHH:mm");
  //       this.toDate = moment(this.toDate).format("yyyy-MM-ddTHH:mm");
  //     }
  //   }
  // }
  // searchDate(from, to) {
  //   this.spinner.show();
  //   setTimeout(() => {
  //     this.textoBoton = 'Desde: ' + moment(from).format("DD/MM/YYYY HH:mm:ss") + ' hasta: ' + moment(to).format("DD/MM/YYYY HH:mm:ss");
  //     this.showFlag = true;
  //     this.spinner.hide();
  //     this.switchFilter('Personalizado');
  //   }, 3000);
  // }
  leftClick(data: string) {
    for (let i = 0; i < this.filterContent.length; i++) {
      if (data !== this.filterContent[i].nombre) {
        this.switchFilter(this.filterContent[0].nombre);
        this.textoBoton = this.filterContent[0].nombre;
      } else {
        continue;
      }
    }
    for (let i = 0; i < this.filterContent.length; i++) {
      if (data === this.filterContent[0].nombre) {
        this.switchFilter(this.filterContent[this.filterContent.length - 1].nombre);
        this.textoBoton = this.filterContent[this.filterContent.length - 1].nombre;
      } else if (i > 0 && data === this.filterContent[i].nombre) {
        this.switchFilter(this.filterContent[i - 1].nombre);
        this.textoBoton = this.filterContent[i - 1].nombre;

      } else {
        continue;
      }
    }
  }
  rightClick(data: string) {
    for (let i = 0; i < this.filterContent.length; i++) {
      if (data !== this.filterContent[i].nombre) {
        this.switchFilter(this.filterContent[0].nombre);
        this.textoBoton = this.filterContent[0].nombre;
      } else {
        continue;
      }
    }
    for (let i = 0; i < this.filterContent.length; i++) {
      if (data === this.filterContent[this.filterContent.length - 1].nombre) {
        this.switchFilter(this.filterContent[0].nombre);
        this.textoBoton = this.filterContent[0].nombre;
      } else if (data === this.filterContent[i].nombre) {
        this.switchFilter(this.filterContent[i + 1].nombre);
        this.textoBoton = this.filterContent[i + 1].nombre;
      } else {
        continue;
      }
    }
  }

  sanitize(data: any) {
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(data);
    return this.safeHtml;
  }
}
