import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-mantenimientos-tecnico',
  templateUrl: './mantenimientos-tecnico.component.html',
  styleUrls: ['./mantenimientos-tecnico.component.css']
})
export class MantenimientosTecnicoComponent implements OnInit {

  mantenimientos:any=[];
  title
  TotalVisitasTerminadasAyer
  TotalVisitasTerminadas;
  TotalVisitasAgendadas;
  TotalVisitasEjecucion
  MantetimientosPreventivos
  MantetimientosCorrectivo
  clientClass;
  valueRate
  monthRate
  closeResult
  OptionDespachada
  Labels
  A_Desp
  E_Gen;    
  to1;
  to2;
  to3;
  to4;
  to5;
  to6;
  to7:any[]=[];
  to8;
  
  dummyContent = [
    { titulo: 'Visitas en Total', imagen: '../../../assets/img/icons/common/svgEnergy/001-solar energy.svg' },
    { titulo: 'Visitas Terminadas Ayer', imagen: '../../../assets/img/icons/common/svgEnergy/010-wind turbine.svg' },
    { titulo: 'Visitas Terminadas en Total', imagen: '../../../assets/img/icons/common/svgEnergy/012-recycle.svg' },
    { titulo: 'Visitas Agendadas Pendientes', imagen: '../../../assets/img/icons/common/svgEnergy/006-electrical energy.svg' },
    { titulo: 'Mantenimientos Correctivos Totales', imagen: '../../../assets/img/icons/common/svgEnergy/009-battery.svg' },
    { titulo: 'Mantenimientos Preventivos Totales', imagen: '../../../assets/img/icons/common/svgEnergy/011-renewable.svg' },
    
  ];

  constructor(protected global: GlobalService,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    public route: Router,
    public alerta: GlobalAlertService,) { }

  ngOnInit(): void {
    this.spinner.show()
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.global.mantenimientos.length > 0 ? console.log():  this.route.navigateByUrl('/administracion-tecnicos');
    const pdata1 = { option: 'insertar2', texto:`SELECT * FROM db_liwa.Maintenance_new WHERE IsDelete = 0 AND Id_Technical = ${this.global.idTecnico};`}
    this.global.consultar(pdata1, async (err, response) => {
      this.mantenimientos = await response
      let resp = await response
      let fechaactual1 = new Date();
      let opfecha = moment(fechaactual1).subtract(1, 'days');
      let FechaI = moment(opfecha).format('YYYY-MM-DD');
      this.TotalVisitasTerminadasAyer = resp.filter( i => i[11] == 'Terminado' && i[16].substr(0,10) == FechaI ).length
      this.TotalVisitasTerminadas = resp.filter( i => i[11] == 'Terminado').length
      this.TotalVisitasAgendadas = resp.filter( i => i[11] == 'Agendado').length
      this.TotalVisitasEjecucion = resp.length
      this.MantetimientosCorrectivo = resp.filter( i => i[10] == 'Correctivo').length
      this.MantetimientosPreventivos = resp.filter( i => i[10] == 'Preventivo').length
      $(function () {
        this.table = $('#treplanteo').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
    })
    this.spinner.hide()
  }

  getDismissReason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      this.dismissValues();
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.dismissValues();
    } else {
      this.dismissValues();
    }
  }
  dismissValues() {
    this.valueRate = [];
    this.monthRate = [];
  }
  openViewChart3(content) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'chartContent3' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.OptionDespachada = {
      legend: {
        data: ['Energia Despachada KWh']
      },
      toolbox: {
        // y: 'bottom',
        feature: {
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      tooltip: {},
      xAxis: {
        data: this.Labels,
        splitLine: {
          show: false
        }
      },
      yAxis: {},
      series: [
        {
          name: 'Energia Despachada KWh',
          type: 'bar',
          data: this.A_Desp,
          emphasis: {
            focus: 'series'
          },
          animationDelay: function (idx) {
            return idx * 10;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };
  }
  details(idMantenimiento: any){
this.global.Id_Mantenimiento = idMantenimiento
this.route.navigateByUrl('/ver-visita');
  }
  stateBadge(data: any) {
    if (data == 'Agendado') {
      return 'badge badge-warning';
    }
   else if (data == 'Terminado') {
      return 'badge badge-success';
    }
   else if (data == 'Ejecución') {
      return 'badge badge-leve';
    }else {
      return 'badge badge-warning';
    }
  }
  formatt(item) {
    let retultadi = moment(item).format('YYYY/MM/DD HH:mm')
    return retultadi.slice(0, 10)
  
  }

}
