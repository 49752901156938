import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-adq-beneficiarios',
  templateUrl: './adq-beneficiarios.component.html',
  styleUrls: ['./adq-beneficiarios.component.scss']
})
export class AdqBeneficiariosComponent implements OnInit {
  clientClass;
  constructor(private spinner: NgxSpinnerService,
    private route: Router,
    private global: GlobalService,) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
  }

  goList(data) {
    switch (data) {
      case '1':
        this.route.navigateByUrl('/listado-preliminar');
        break;
      case '2':
        this.route.navigateByUrl('/listado-definitivo');
        break;

      default:
        break;
    }
  }

}
