import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historicos-e-informacion',
  templateUrl: './historicos-e-informacion.component.html'
})
export class HistoricosEInformacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
