import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {

  clientClass;
  proyectos =[];
  constructor(public global: GlobalService, public ruta: Router, public alerta: GlobalAlertService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    $(function () {
      this.table = $('#projectTable').DataTable({
        'responsive': false,
        'destroy': true,
        'retrieve': true,
        'paging': true,
        'pagingType': 'numbers',
        'language': {
          'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
      }
      });
  });
}



}
