import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-cartera',
  templateUrl: './gestion-cartera.component.html',
  styleUrls: ['./gestion-cartera.component.css']
})
export class GestionCarteraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
