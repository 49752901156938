import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import * as $ from 'jquery';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import  Swal from "sweetalert2";

@Component({
  selector: 'app-recepcion',
  templateUrl: './recepcion.component.html'
})
export class RecepcionComponent implements OnInit {
  clientClass;
  proveedores =[];
  tipoKit =[];
  proveedor;
  fechaEntrega;
  lote;
  etiqueta;
  tipo;
  Evidence:boolean = false;
  srcImg =[];
  pathname;
  imgsUrls =[];
  idKit;
  allkits =[];
  uploadedPhoto: boolean = false;
  
  constructor(public global: GlobalService,
    public route: Router,
     private modalService: NgbModal,
      private alerta: GlobalAlertService,
       private spinner: NgxSpinnerService) { 
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    const pdata3 = { option: 'proveedor_kit'};
      this.global.consultar(pdata3, (err3, response3) => {
        console.log('proveedores data',response3);
        this.proveedores = response3;
        const pdata4 = { option: 'tipo_kit'};
        this.global.consultar(pdata4, (err4, response4) => {
          console.log('tipo_kit data',response4);
          this.tipoKit = response4;
          this.spinner.hide();
        });
      });
    }, 600);
  }
  verificarPorcentaje(idFormulario, caso:number):number{
    for (let i = 0; i < this.global.porcentaje.length; i++) {
        if(idFormulario == this.global.porcentaje[i][0]){
          switch (caso) {
            case 1:
              if(this.global.porcentaje[i][1] == 0 ){
                return 0;
              }else{
                return 1;
              }
              
            case 2:
              
              if(this.global.porcentaje[i][2] == 0 ){
                return 0;
              }else{
                return 1;
              }
              
            case 3:
              
              if(this.global.porcentaje[i][3] == 0 ){
                return 0;
              }else{
                return 1;
              }
              
          
            default:
              break;
          }
        }else{
          continue;
        }
      
    }
  }

  activarListPre(idBenefit){

    console.log('id beneficiario', idBenefit );
  }
  desactivarListPre(idBenefit){

    console.log('id beneficiario', idBenefit );
  }
  deleteValQ(idBenefit){

    console.log('id beneficiario', idBenefit );
  }
  openRegisterForm(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-register'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  onFileSelected(event){
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.Evidence = true;
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  loadPhoto() {
    if ( this.Evidence == false) {
      this.alerta.descErr('Error al subir la foto, verifique nuevamente');
    } else {
      this.spinner.show();
    try {
      setTimeout(() => {
      this.idKit = this.global.Id_Usuario + '-K-' + moment().format('x');
      this.pathname = this.global.Id_Usuario+ '_' + moment().unix();
      let con1 = 0;
      let con2 = 0;
        console.log(this.idKit);
      if(this.idKit == undefined || this.proveedor == undefined || this.fechaEntrega == undefined || this.lote == undefined || this.etiqueta == undefined || this.tipo == undefined ){
        this.alerta.descErr('Verifique que no haya ningún campo vacío');
      }else{
        const query = 'INSERT INTO db_liwa.Kits (Id_Kit,Proveedor,Fecha_Entrega,Lote,Etiqueta,Tipo_Kit,Etapa,IsDelete,IsActive)' +
        ' VALUES (\'' +
        this.idKit + '\',\'' +
        this.proveedor + '\',\'' +
        this.fechaEntrega + '\',\'' +
        this.lote + '\',\'' +
        this.etiqueta + '\',\'' +
        this.tipo + '\',\'' +
        'Creación' + '\',\'' +
        0 + '\',\'' +
        1 + '\');';
        console.log('así se va el query',query);
       const pdata1 = { option: 'insertar', texto: query };
      this.global.consultar(pdata1, (err, response) => {
        console.log( 'datos insertados',response, query);
        if (err == null && response == true) {
             
      for (let i = 0; i < this.srcImg.length; i++) {
        con1++;
        console.log('Foto #' + i, this.srcImg[i]);
        const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
        // nombre de la imagen en el servidor this.Photo
        const imageName = i + '-' + moment().unix() + '.jpg';
        const imageBlob = this.dataURItoBlob(FOTOO);
        const imageFile = FOTOO;
        const data = new FormData();
        console.log(imageFile, imageBlob);
        data.append('image', imageFile);
        data.append('nombre', imageName);
        data.append('ruta', this.pathname);
        $.ajax({
          url: 'https://fotos.engenius.com.co/subir_foto_Kit.php',
          type: 'post',
          dataType: 'json',
          data,
          processData: false,
          contentType: false
        }).then((data1) => {
          console.log(data, data1);
          this.imgsUrls[i] = data1;
          con2++;
          if (this.imgsUrls[i] == 'BADEXT' || this.imgsUrls[i] == null || this.imgsUrls[i] == undefined || this.imgsUrls[i] == '' || this.imgsUrls[i] == 'NULL') {
            this.alerta.descErr('Error al subir la foto, verifique nuevamente');
          } else {
            // img subida
            if (con1 == con2 && con2 == this.srcImg.length) {
              
               //QUERY DE FOTOS PORQUE ESTÁ DENTRO DEL FOR
            const query1 = 'INSERT INTO db_liwa.Fotos_Kits (Id_Kit,Rutalocal,Rutaserver,Estado,Fecha,upload,edit,IsDelete,IsActive)'+
            ' VALUES (\'' +
            this.idKit + '\',\'' +
            imageName + '\',\'' +
            data1 + '\',\'' +
            1 + '\',\'' +
            moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
            1 + '\',\'' +
            0 + '\',\'' +
            0 + '\',\'' +
            1 + '\');'; 
            console.log('segundo query para fotos', query1);
            const pdata2 = { option: 'insertar', texto: query1 };
          this.global.consultar(pdata2, (err2, response2) => {
            console.log( 'datos insertados',response2, query1);
            if (err2 == null && response2 == true) {
              this.alerta.descValid('Kit de fase de ensamble registrado con éxito');
              this.route.navigateByUrl('/proceso-pruebas');
              this.uploadedPhoto = true;
              this.spinner.hide();
            } else {
              this.alerta.descErr('Error al registrar el kit, intente nuevamente');
              this.idKit = '';
              this.proveedor = '';
              this.fechaEntrega = '';
              this.lote = '';
              this.etiqueta = '';
              this.tipo = '';
              this.srcImg = [];
              this.imgsUrls = [];
              this.spinner.hide();
            }
          }); 
          }
        }
        });
    }
          
         
        } else {
           this.alerta.descErr('Error al registrar el kit, intente nuevamente');
        this.idKit = '';
        this.proveedor = '';
        this.fechaEntrega = '';
        this.lote = '';
        this.etiqueta = '';
        this.tipo = '';
        this.srcImg = [];
        this.imgsUrls = [];
        this.spinner.hide();
        }
      }); 

   
      }

      }, 400);
    } catch (error) {
      
    }
  }
  }


}
