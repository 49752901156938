import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
// import * as alertFunctions from '../../../shared/data/sweet-alerts';
import * as $ from 'jquery';
import { OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html'
})
export class EditarUsuarioComponent implements OnInit {
  usuarios = new Array();
  roles = new Array();
  selectedFile;
  Name;
  Identification;
  NickName;
  Occupation;
  Phone;
  Email;
  Password;
  Country;
  Photo;
  User;
  Id_User;
  IdClient;
  IdRole;
  fotoUrl = '';
  srcImg;
  confirmPass;
  isValid:boolean = false;
  ClientName;
  RoleName;
  flagA:boolean = false;
  clientes = new Array();
  clientClass;
  newPass1;
  newPass2;
  ext;
  constructor(public global: GlobalService,
    public routes: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      if (this.global.idEdit == undefined) {
        this.routes.navigateByUrl('/user-profile');
      } else {
        this.clientClass = this.global.CLIENTDATALIWA[8];
        const pdata8 = { option: 'user', user: this.global.idEdit };
        this.global.consultar(pdata8, (err8, response8) => {
          console.log('Datos Usuario', response8);
          this.usuarios = response8[0];
          this.Name = this.usuarios[3];
          this.Identification = this.usuarios[4];
          this.NickName = this.usuarios[5];
          this.Occupation = this.usuarios[6];
          this.Phone = this.usuarios[7];
          this.Email = this.usuarios[8];
          this.Password = this.usuarios[9];
          this.Country = this.usuarios[10];
          this.Photo = this.usuarios[11];
          this.IdClient = this.usuarios[1];
          this.IdRole = this.usuarios[2];
          this.flagA = true;
        });
        if (this.usuarios[11] == null || this.usuarios[11] == undefined) {
          this.srcImg = '../../../assets/img/gallery/imgPlaceholder.png';
        } else {
          this.srcImg = this.usuarios[11];
        }
        const pdata7 = { option: 'clientes' };
        this.global.consultar(pdata7, (err7, response7) => {
          console.log('Datos Cliente', response7);
          if (this.global.Id_Client == '2') {
            this.clientes.push(response7[1]);
          } else {
            this.clientes = response7;
          }
        });
        const pdata9 = { option: 'roles' };
        this.global.consultar(pdata9, (err9, response9) => {

          console.log('Datos Roles', response9);
          this.roles = response9;
          let restUser = [];
          for (let i = 0; i < this.roles.length; i++) {
            if (this.roles[i][0] != '6') {
              restUser.push(this.roles[i]);
            }
          }
          this.roles = [];
          this.roles = restUser;
          this.spinner.hide();
        });

      }
    }, 60);
  }

  ngOnDestroy() {

  }
  exploreImg() {
    const elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let imagen = new Image();
        imagen.src = event.target.result;
        imagen.onload = () => {
          this.Photo = event.target.result;
          this.srcImg = this.Photo;
          this.validExt(this.Photo.split('/')[1]);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }

  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }

  Guardar() {
    this.spinner.show();
    try {
      // setTimeout(() => {
        if (this.Name == undefined || this.Name == '' || this.Identification == undefined || this.NickName == '' || this.Occupation == '' || this.Phone == '' || this.Email == undefined || this.Email == '' || this.Password == undefined || this.Password == '' || this.Country == '') {
          this.alerta.descErr('Error al crear nuevo usuario, verifique nuevamente que no hayan campos vacíos');
        } else {
          const query = 'UPDATE db_liwa.User SET Id_Client = \'' + this.IdClient + '\', Id_Role = \'' + this.IdRole + '\', UserName = \'' + this.Name + '\',UserIdentification = \'' + this.Identification + '\',UserNickName = \'' + this.NickName +
            '\',UserOccupation = \'' + this.Occupation + '\',UserPhone = \'' + this.Phone + '\',UserEmail = \'' + this.Email + '\',UserCountry = \'' + this.Country +
            '\',UserPhoto = \'' + this.fotoUrl + '\'' + ' WHERE Id_User = \'' + this.usuarios[0] + '\'';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            console.log(await response, query);
            if (err == null && await response == true) {
              this.alerta.descValid('El perfil se actualizó con éxito');
              this.spinner.hide();
              this.routes.navigateByUrl('/user-profile');
            } else {

            }
          });
        }
      // }, 400);

    } catch (error) {
      console.log('error:', +error);
    }
  }

  loadPhoto() {
    this.spinner.show();
    if (this.Photo == null || this.Photo == undefined || this.Photo == '') {
      this.alerta.descErr('Error al subir la foto, verifique nuevamente');
    } else if (this.Photo == this.usuarios[11]) {
      this.Guardar();
      this.fotoUrl = this.Photo;
    } else {
      console.log('Foto', this.Photo);
      const FOTOO = this.Photo.slice(23, this.Photo.length);
      // nombre de la imagen en el servidor this.Photo
      const imageName = this.NickName + '_' + moment().unix() + this.ext;
      // const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
      const imageFile = FOTOO;
      const data = new FormData();
      data.append('image', imageFile);
      data.append('nombre', imageName);
      data.append('ruta', this.NickName);
      $.ajax({
        url: 'https://fotos.engenius.com.co/subir_foto_User.php',
        type: 'post',
        dataType: 'json',
        data,
        processData: false,
        contentType: false
      }).then((data1) => {
        console.log(data, data1);
        this.fotoUrl = data1;
        if (this.fotoUrl == 'BADEXT' || this.fotoUrl == null || this.fotoUrl == undefined || this.fotoUrl == '' || this.fotoUrl == 'NULL') {
          this.alerta.descErr('Error al subir la foto, verifique nuevamente');
        } else {
          this.Guardar();
        }
      });
    }

  }
  changePass() {
    this.spinner.show();
    if (this.newPass1 == undefined || this.newPass1 == undefined) {
      this.alerta.descErr('Verifique que no hayan campos vacíos');
      this.spinner.hide();
    } else if (this.newPass1 != this.newPass2) {
      this.alerta.descErr('No coinciden las contraseñas, verifique nuevamente');
      this.spinner.hide();
    } else if (this.newPass1.length < 7) {
      this.alerta.descErr('La contraseña no debe tener menos de 7 carácteres');
      this.spinner.hide();
    } else if (this.newPass2 == this.newPass1) {

      setTimeout(() => {
        const query = 'UPDATE db_liwa.User SET UserPassword = ' + 'MD5(\'' + this.newPass1 + '\')' + ' WHERE Id_User = \'' + this.usuarios[0] + '\'';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async  (err, response) => {
          console.log( await response, query);
          if (err == null && await response == true) {
            this.alerta.descValid('La contraseña se ha cambiado con éxito');
            this.routes.navigateByUrl('/user-profile');
            this.spinner.hide();
          }
        });
      }, 60);
    }
  }
  openFormPass(content) {
    this.newPass1 = '';
    this.newPass2 = '';
    this.modalService.open(content, { ariaLabelledBy: 'modal-changePass' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  goBack() {
    window.history.back();
  }

}
