import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';

@Component({
  selector: 'app-fenoge',
  templateUrl: './fenoge.component.html',
  styleUrls: ['./fenoge.component.scss']
})

export class FenogeComponent implements OnInit {
  Items = new Array();
  clientClass;
  mapType;
  Seleccion = '0';
  Style;
  Pines = new Array();
  listActive = true;
  mapActive = false;
  informesActive = false;
  sena = new Array();
  latitude = 6.3241465;
  longitude = -76.8321544;
  zoom = 7;
  PinesOri = new Array();
  Proyecto;
  Dispositivos;
  Instalacion;
  TipoReporte;
  GraficaEneGen;
  mov = 'MOVISTAR';
  SeleccionMes;
  FechaMes;
  Labels;
  Data;
  Data2;
  Graf = false;
  Graf2 = false;
  Tipo1 = new Array();
  Tipo2 = new Array();
  Datos = new Array();
  DIA = new Array();
  color;
  colorg;
  DataCue;
  Graficas = false;
  chart;
  chart2;
  chart3;
  Pgenerada = new Array();
  previous;
  Pconsumida = new Array();
  V = new Array();
  I = new Array();
  VP = new Array();
  IP = new Array();
  VACG = new Array();
  IACG = new Array();
  Horas;
  ValoresHoras;
  ContGen;
  ContCon;
  ValInstantaneo;
  TagData;
  ESP;
  InstalacionE = '';
  Instalacion2 = '';
  DatosSel;
  bandera = false;
  DataHistory;
  ALERTA = false;
  Bandera2 = false;
  ESCUELAA;
  VECTOR = new Array();
  Toexcel = [['HORA', 'Voltaje Panel', 'Corriente Panel', 'Voltaje Bateria', 'Corriente Bateria', 'Voltaje de Salida', 'Corriente de Salida']];
  Toexcel2 = [];
  constructor(public global: GlobalService,
    public route: Router, private spinner: NgxSpinnerService, public excelService: ExcelService) {

    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.mapType = 'roadmap';
    this.Style = this.global.mapStyle;

    const pdata3 = { option: 'fenoge' };
    console.log(pdata3);
    $.ajax({
      data: pdata3, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseFenoge.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response2) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.PinesOri = this.Pines = response2;
        this.ngOnInit3();
      }, 300);
    });

  }

  ngOnInit3() {
    this.ESP = new Array();
    const pdata2 = { option: 'ofline' };
    $.ajax({
      data: pdata2, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseFenoge.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Data', response);
      this.ESP = response;
    });
  }

  IRA(item) {
    console.log(item);
    this.global.ID_Enelar = item[0];
    this.route.navigateByUrl('/fenoge2');
  }

  // generarGrafico2() {
  //   setTimeout(() => {
  //     //window.scrollTo(0, document.body.scrollHeight);
  //   }, 300);
  //   const canvas = document.getElementById('History2') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.chart2 = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
  //       datasets: [
  //         {
  //           label: 'Potencia Generada',
  //           yAxisID: 'PTC',
  //           backgroundColor: 'rgba(0, 102, 255, 0.1)',
  //           borderColor: 'rgba(0, 102, 255)',
  //           data: this.Pgenerada,
  //           type: 'line'
  //         },
  //         {
  //           label: 'Voltaje Panel',
  //           yAxisID: 'VP',
  //           backgroundColor: 'rgba(255, 206, 0)',
  //           borderColor: 'rgba(255, 206, 0)',
  //           data: this.VP
  //         },
  //         {
  //           label: 'Corriente Panel',
  //           yAxisID: 'IP',
  //           backgroundColor: 'rgba(185, 39, 38)',
  //           borderColor: 'rgba(185, 39, 38)',
  //           data: this.IP
  //         }]
  //     },
  //     options: {
  //       scales: {
  //         yAxes: [{
  //           id: 'VP',
  //           type: 'linear',
  //           position: 'left',
  //         }, {
  //           id: 'IP',
  //           type: 'linear',
  //           position: 'right',
  //         }, {
  //           id: 'PTC',
  //           type: 'linear',
  //           position: 'left',
  //         }]
  //       }
  //     }
  //   });
  // }

  FechaNueva(fecha) {
    this.VECTOR[3] = fecha;
  }


  PDFfotosH() {
    try {
      setTimeout(() => {
        const imgData = new Image();
        imgData.crossOrigin = '';
        imgData.src = '../../assets/img/Suncolombia.png';
        const imgData2 = new Image();
        imgData2.crossOrigin = '';
        imgData2.src = '../../assets/img/Iconos2-19.png';
        const doc = new jsPDF
        const Title = ' Reporte de Datos Instalación Solar Fotovoltaica';
        const fechadia = moment(this.VECTOR[3]);
        fechadia.locale('es');
        console.log(fechadia);
        doc.setTextColor(3, 137, 246);
        doc.text(Title, 100, 45, 'center');
        const SubTitle = this.VECTOR[2];
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text('Usuario: ' + SubTitle, 100, 53, 'center');
        doc.text('Fecha de Lectura: ' + this.VECTOR[3], 100, 60, 'center');
        doc.addImage(imgData, 'PNG', 10, 1, 50, 50);
        doc.addImage(imgData2, 'PNG', 170, 5, 40, 40);
        let DATA = new Array();
        for (let index = 0; index < this.VP.length; index++) {
          DATA[index] = [index, this.VP[index] + ' V', this.IP[index] + ' A', this.V[index] + ' V', this.I[index] + ' A', this.VACG[index] + ' V', this.IACG[index] + ' A']
        }
        doc.autoTable({
          headStyles: { fillColor: [0, 132, 179], textColor: [255, 255, 255], fontSize: 11, lineColor: [255, 255, 255], lineWidth: 0.2 },
          head: [['HORA', 'Voltaje Panel', 'Corriente Panel', 'Voltaje Bateria', 'Corriente Bateria', 'Voltaje de Salida', 'Corriente de Salida']],
          body: DATA, styles: { fontSize: 10, lineColor: [0, 132, 179], lineWidth: 0.2, textColor: [0, 0, 0] }
          , startY: 70
          , theme: 'grid'
          , margin: { top: 40 }
          , showHead: 'firstPage'
        });
        const pageCount = doc.internal.getNumberOfPages();
        const arrr = new Array(pageCount);
        for (let j = 0; j < pageCount; j++) {
          arrr[j] = 'Hola';
        }
        setTimeout(() => {
          arrr.forEach((element, o) => {
            const dia = moment();
            dia.locale('es');
            doc.setTextColor(0, 0, 0);
            doc.setPage(o + 1);
            doc.setFontSize(8);
            doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
            doc.setFontSize(8);
            doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
          });
        }, 100);
        setTimeout(() => {
          doc.save('Reporte de Datos Instalación Solar Fotovoltaica' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
        }, 500);
      }, 500);
    } catch (err) {

    }
  }


  PDFfotosE() {
    for (let index = 0; index < this.VP.length; index++) {
      this.Toexcel.push([index.toString(), this.VP[index] + ' V', this.IP[index] + ' A', this.V[index] + ' V', this.I[index] + ' A', this.VACG[index] + ' V', this.IACG[index] + ' A']);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile(this.Toexcel, this.Toexcel2, 'Hoja 1', 'Hoja 2', 'Reporte de Datos Instalación Solar Fotovoltaica' + moment().format('_MM_DD_YY-h_mm_ss'));
      setTimeout(() => {
        this.Toexcel = [['HORA', 'Voltaje Panel', 'Corriente Panel', 'Voltaje Bateria', 'Corriente Bateria', 'Voltaje de Salida', 'Corriente de Salida']];
        this.Toexcel2 = [];
      }, 10000);
    }, 500);
  }

  // generarGrafico3() {
  //   setTimeout(() => {
  //     //window.scrollTo(0, document.body.scrollHeight);
  //   }, 300);
  //   const canvas = document.getElementById('History3') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.chart3 = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
  //       datasets: [
  //         {
  //           label: 'Voltaje',
  //           yAxisID: 'V',
  //           backgroundColor: 'rgba(38, 185, 154, 0.4)',
  //           borderColor: 'rgba(38, 185, 154)',
  //           data: this.V,
  //           type: 'line'
  //         }
  //       ]
  //     },
  //     options: {
  //       scales: {
  //         yAxes: [{
  //           id: 'V',
  //           type: 'linear',
  //           position: 'left',
  //           ticks: {
  //             suggestedMin: 0,
  //             suggestedMax: 30
  //           }
  //         }]
  //       }
  //     }
  //   });
  // }


  // generarGrafico() {
  //   const canvas = document.getElementById('History') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   this.chart = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
  //       datasets: [
  //         {
  //           label: 'Corriente AC',
  //           yAxisID: 'IAC',
  //           backgroundColor: 'rgba(104, 41, 177)',
  //           borderColor: 'rgba(104, 41, 177)',
  //           data: this.IACG
  //         },
  //         {
  //           label: 'Voltaje AC',
  //           yAxisID: 'VAC',
  //           backgroundColor: 'rgba(255, 206, 0, 0.3)',
  //           borderColor: 'rgba(255, 206, 0)',
  //           data: this.VACG,
  //           type: 'line'
  //         }
  //       ]
  //     },
  //     options: {
  //       scales: {
  //         yAxes: [{
  //           id: 'VAC',
  //           type: 'linear',
  //           position: 'left',
  //           ticks: {
  //             suggestedMin: 0,
  //             suggestedMax: 125
  //           }
  //         }, {
  //           id: 'IAC',
  //           type: 'linear',
  //           position: 'right',
  //           ticks: {
  //             suggestedMin: 0,
  //             suggestedMax: 8
  //           }
  //         }]
  //       }
  //     }
  //   });
  //   setTimeout(() => {
  //     //window.scrollTo(0, document.body.scrollHeight);
  //   }, 300);
  // }

  isMap() {
    if (this.mapActive == false) {
      this.mapActive = true;
      this.informesActive = false;
      this.listActive = false;
    } else {
      this.mapActive = false;
    }
  }

  isInforme() {
    if (this.informesActive == false) {
      this.mapActive = false;
      this.informesActive = true;
      this.listActive = false;
      this.Graficas = true;
      $(function () {
        this.table = $('#seatsTable').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });

    } else {
      this.informesActive = false;
    }
  }

  ObtenerDatosss() {
    this.VECTOR = this.ESCUELAA;
    console.log(this.VECTOR);
    const pdata2 = { option: 'dataf', Id_B: this.VECTOR };
    console.log('TAGDATA', pdata2);
    $.ajax({
      data: pdata2, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseFenoge.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      this.DataHistory = response;
      console.log('DATA HISTORY', response);
      this.ObtenerHistoricos();
    });
  }

  ObtenerHistoricos() {
    this.ALERTA = false;
    this.Horas = new Array();
    this.Horas = this.DataHistory;
    console.log('Horas Vector', this.Horas);
    this.ValoresHoras = new Array();
    for (let i = 0; i < this.Horas.length; i++) {
      const V = this.Horas[i][2];
      const VAC = this.Horas[i][3];
      const IAC = this.Horas[i][4];

      const objt = {
        V: V,
        VAC: VAC,
        IAC: IAC
      };
      this.V[i] = V;
      this.VACG[i] = VAC;
      this.IACG[i] = IAC;
      this.ValoresHoras[i] = objt;
      if (this.V[i] <= 24) {
        this.ALERTA = true;
      }
    }
    console.log(this.ValoresHoras);
    this.ObtDatosAux();
  }

  ObtDatosAux() {
    this.bandera = true;
    if (this.ALERTA == true) {
      this.Bandera2 = true;
    } else {
      this.Bandera2 = true;
    }
  }


  ObtenerDatosss2() {
    console.log(this.DatosSel);
    this.Bandera2 = false;
    console.log('SELCCIONADA', this.DatosSel);
    this.TagData = (this.DatosSel).split(',');
    console.log(this.TagData);
    setTimeout(() => {
      this.ObtenerHistoricos();
    }, 300);
    this.Bandera2 = false;
  }

  Cambio() {
    this.bandera = false;
    console.log('Instalacion: ', this.ESCUELAA);
  }

  isList() {
    if (this.listActive == false) {
      this.mapActive = false;
      this.informesActive = false;
      this.listActive = true;
    } else {
      this.listActive = false;
    }
  }

  ColorLabels() {
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
  }

  // ProcesarData() {
  //   const series = new Array();
  //   const labels = new Array();
  //   const color = new Array();
  //   const background = new Array();
  //   this.ColorLabels();
  //   for (let index = 0; index < this.Datos.length; index++) {
  //     series.push(this.Datos[index][0]);
  //     labels.push(this.Datos[index][2] + '-' + this.Datos[index][3]);
  //     background.push('rgba(153, 102, 255, 0.2)');
  //     color.push('rgba(153, 102, 255, 1');
  //   }
  //   this.DataCue = {
  //     'labels': labels,
  //     'series': series
  //   };
  //   const canvas = document.getElementById('myChart') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: labels,
  //       datasets: [{
  //         label: 'Recargas',
  //         data: series,
  //         backgroundColor: background,
  //         borderColor: color,
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       scales: {
  //         xAxes: [{
  //           gridLines: {
  //             color: this.colorg, // Eje x color verde
  //           },
  //           ticks: {
  //             fontColor: this.color,
  //           },

  //           display: true,
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Mes'
  //           }
  //         }],
  //         yAxes: [{
  //           ticks: {

  //             fontColor: this.color,
  //             beginAtZero: true
  //           },
  //           scaleLabel: {
  //             display: true,
  //             labelString: 'Número de Recargas',
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           }
  //         }]
  //       }
  //     }
  //   });
  // }


  // ProcesarData3() {
  //   const series = new Array();
  //   const labels = new Array();
  //   const color = new Array();
  //   const background = new Array();
  //   this.ColorLabels();
  //   for (let index = 0; index < this.DIA.length; index++) {
  //     series.push(this.DIA[index][0]);
  //     labels.push('Día ' + this.DIA[index][2]);
  //     background.push('rgba(75, 192, 192, 0.2)');
  //     color.push('rgba(75, 192, 192, 1)');
  //   }
  //   this.DataCue = {
  //     'labels': labels,
  //     'series': series
  //   };
  //   const canvas = document.getElementById('myChart3') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: labels,
  //       datasets: [{
  //         label: 'Recargas',
  //         data: series,
  //         backgroundColor: background,
  //         borderColor: color,
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         xAxes: [{
  //           ticks: {
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //           display: true,
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Día del Mes'
  //           }
  //         }],
  //         yAxes: [{
  //           gridLines: {
  //             color: this.colorg,
  //             display: false
  //           },
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true
  //           },
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Número de Recargas'
  //           },
  //         }]
  //       }
  //     }
  //   });
  // }


  // ProcesarData2() {
  //   this.ColorLabels();
  //   const series = new Array();
  //   const labels = new Array();
  //   const color = new Array();
  //   const background = new Array();
  //   this.ColorLabels();
  //   for (let index = 0; index < this.Tipo1.length; index++) {
  //     series.push(this.Tipo1[index][0]);
  //     labels.push(this.Tipo1[index][2] + '-' + this.Tipo1[index][3]);
  //     background.push('rgba(255, 99, 132, 0.2)');
  //     color.push('rgba(255, 99, 132, 1)');
  //   }
  //   const series2 = new Array();
  //   const labels2 = new Array();
  //   const color2 = new Array();
  //   const background2 = new Array();
  //   for (let index = 0; index < this.Tipo2.length; index++) {
  //     series2.push(this.Tipo2[index][0]);
  //     labels2.push(this.Tipo2[index][2] + '-' + this.Tipo2[index][3]);
  //     background2.push('rgba(54, 162, 235, 0.2)');
  //     color2.push('rgba(54, 162, 235, 1)');
  //   }
  //   const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   let myChart = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: labels,
  //       datasets: [{
  //         label: 'Recargas $20.000',
  //         data: series,
  //         backgroundColor:
  //           background
  //         ,
  //         borderColor:
  //           color
  //         ,
  //         borderWidth: 1
  //       },
  //       {
  //         label: 'Recargas $30.000',
  //         data: series2,
  //         backgroundColor:
  //           background2
  //         ,
  //         borderColor:
  //           color2
  //         ,
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       legend: {
  //         labels: {
  //           fontColor: this.color,
  //         }
  //       },
  //       scales: {
  //         xAxes: [{
  //           ticks: {
  //             fontColor: this.color,
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //           },
  //           display: true,
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Mes'
  //           }
  //         }],
  //         yAxes: [{
  //           ticks: {
  //             fontColor: this.color,
  //             beginAtZero: true
  //           },
  //           scaleLabel: {
  //             fontColor: this.color,
  //             display: true,
  //             labelString: 'Número de Recargas'
  //           },
  //           gridLines: {
  //             color: this.colorg,
  //             display: false,
  //           },
  //         }]
  //       }
  //     }
  //   });
  //   myChart.update();
  // }

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }


}
