import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
// import { FooterComponent } from './footer/footer.component'; se comento el 20/02/23 a peticion de lider teth
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
  ],
  declarations: [
    // FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
  exports: [
    // FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }
