import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';

declare var require: any

require( 'jquery' );
@Component({
  selector: 'app-tenderos',
  templateUrl: './tenderos.component.html',
  styleUrls: ['./tenderos.component.scss']
})
export class TenderosComponent implements OnInit {

  tenderos = new Array();
  table: any;
  idtecnico;
  proyectos: any[] = [];
  seccionales: any[] = [];

  proyectosEdit: any[] = [];
  seccionalesEdit: any[] = [];

  idSeccional = "Seleccione una seccional..."
  idProyecto = "Seleccione un proyecto..."

  idProyecto2: "Seleccione un proyecto...";
  idSeccional2: "Seleccione una seccional...";

  buttonSeccional:boolean = false;
  seccionalOn: boolean = false;

  seccionalEdit: any
  unseccional;
  iduser;
  newcupogastado;
  idProject

  Cupo;
  Cupogastado;

  newpersonas;
  newcupo;

  infoCupo: any;
  infoPersonas: any;
  text = "Seleccione un proyecto...";
  textS = "Seleccione una seccional...";

  public cupo: any;
  public personas: any;
  public isNewCupo: boolean = false;
  public isEditingCupo: boolean = false;

  constructor(
    private global: GlobalService,
    public ruta: Router,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    protected alerta: GlobalAlertService
  ) { }


  ngOnInit(): void {
    this.spinner.show();
    this.consultarseccionales();
    setTimeout(() => {
      const query = { option: 'insertar2', texto: `SELECT * FROM db_liwa.tipoProyecto Where Id_Client = ${this.global.Id_Client} AND IsDelete=0 AND IsActive=1 OR IsActive=0;` }
      this.global.consultar(query, (err, response) => {
        this.proyectos = response;
        this.proyectosEdit = response;
        this.spinner.hide();
      });
    }, 1000);
    setTimeout(() => {
      this.obtenerTenderos();
      $(function () {
           this.table = $("#userTicket").DataTable({
             order: true,
             responsive: false,
             destroy: false,
             retrieve: true,
             paging: true,
             info: false,
             pagingType: "numbers",
             language: {
               url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
             },
           });
         })
       // : console.log();
     },800)
  }
  consultarseccionales(){
    const querySeccional = { option: 'insertar2', texto: `SELECT S.Nombre, S.Id_Seccional  FROM db_liwa.tipoProyecto P INNER JOIN db_liwa.Seccionales S ON  P.id_tipoProyecto = S.Id_Proyecto WHERE P.Id_Client =${this.global.Id_Client}` }
    this.global.consultar(querySeccional, async (err4, response4) => {
      let res:any [] = await  response4;
      if (res.length == undefined || res.length == 0) {
        this.seccionalEdit = [];
      } else {
        this.seccionalEdit = response4;

      }
    });
  }

  nombreSeccional(id){
    console.log(this.seccionalEdit)
    for (let index = 0; index < this.seccionalEdit.length; index++) {
      console.log(id,this.seccionalEdit[index][1])
      if(id == this.seccionalEdit[index][1]){
        return this.seccionalEdit[index][0]
      }
    }
  }

  changeSelect(valueId) {
    this.spinner.show();
    this.idSeccional = "Seleccione una seccional...";

    this.buttonSeccional = false;

    const querySeccional = { option: 'insertar2', texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '${valueId}' AND IsDelete=0 AND IsActive=1;` }
    this.global.consultar(querySeccional, async (err4, response4) => {
      let res:any [] = await  response4;
      if (res.length == undefined || res.length == 0) {
        this.seccionales = [];
        // this.seccionalEdit = [];
        this.seccionalOn = true;
        this.buttonSeccional = false;
        this.spinner.hide();
      } else {
        this.seccionales = response4;
        // this.seccionalEdit = response4;
        this.seccionalOn = true;
        this.personas = true;
        this.spinner.hide();
      }
    });
  }

  async obtenerTenderos() {
    const query = {
      option: 'insertar2',
      texto: `SELECT *,A.Id_User AS Id_Usuario   FROM db_liwa.User A LEFT JOIN db_liwa.New_Tenderos B  ON B.Id_User =  A.Id_User WHERE A.Id_Role = 14  AND A.IsDelete = 0 AND A.Id_Client =  ${this.global.Id_Client};`
    }

    this.global.consultar(query, async (err, response) => {
      const respuesta = await response;
      if (respuesta !== undefined) {
        this.tenderos = respuesta;

        for (let i = 0; i < this.tenderos.length; i++) {
          console.log("data:",this.tenderos[i][26] ,  this.tenderos[i][27])
          let res = (this.tenderos[i][27]/this.tenderos[i][26])
          console.log(res)
          let total = Math.round( res * 100)
          this.tenderos[i][18] = total;
          console.log("Cupo " ,this.tenderos[i][18] )
        }

        setTimeout(() => {
          $(function () {
               this.table = $("#userTicket").DataTable({
                 order: true,
                 responsive: false,
                 destroy: false,
                 retrieve: true,
                 paging: true,
                 info: false,
                 pagingType: "numbers",
                 language: {
                   url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                 },
               });
             })
           // : console.log();
         },800)
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  modalRegisterCupo(content:any, data:any) {
    this.modalService
    .open(content, { ariaLabelledBy: "modal-vinculate" });
    console.log("Informacion: ",data);

    this.iduser = data[32]

  }

  modalEditCupo(content: any, id: any,cupo: any, personas: any, idSeccional: any, idProyecto: any) {
    this.modalService
    .open(content, { ariaLabelledBy: "modal-vinculate" });
    this.consultarseccionales();
    this.infoCupo = cupo;
    this.infoPersonas = personas;
    this.idProyecto2 = idProyecto;
    this.idSeccional2 = idSeccional;
    this.idtecnico = id
  }

  guardar(){
    this.spinner.show();
    const query = 'INSERT INTO db_liwa.New_Tenderos (Id_User,Id_Proyecto,Id_Seccional,Cupo_Asignado,Cupo_Gastado,IsBlock,N_pagos,Comision,IsDelete,IsActive,Usuarios_Asignados)' +
    ' VALUES (\'' +
    this.iduser + '\',\'' +
    this.idProject + '\',\'' +
    this.idSeccional + '\',\'' +
    this.newcupo+ '\',\'' +
    0 + '\',\'' +
    0 + '\',\'' +
    0 + '\',\'' +
    0 + '\',\'' +
    0 + '\',\'' +
    1 + '\',\'' +
    this.newpersonas + '\');';
    const pdata1 = { option: "insertar", texto: query };
    console.log(query, "este es la query");
    this.global.consultar(pdata1, (err, response) => {
      if(response.protocol41 == true){
        this.ngOnInit();
        this.alerta.descValid("Se agrego correctamente!");
        this.spinner.hide();
      }else{
        this.ngOnInit();
        this.alerta.descErr("Errror!");
        this.spinner.hide();
      }
    })
  }


  modificar(){
    this.spinner.show();
    const query = 'UPDATE db_liwa.New_Tenderos SET '
    + 'Id_Proyecto = ' + '\'' +this.idProyecto2 + '\','
    + 'Id_Seccional = ' + '\'' + this.idSeccional2 + '\','
    + 'Cupo_Asignado = ' + '\'' + this.infoCupo + '\','
    + 'Usuarios_Asignados = ' + '\'' + this.infoPersonas + '\''
    + 'WHERE Id_Tenderos =' + '\'' + this.idtecnico + '\';';
    const pdata1 = { option: "insertar", texto: query };
    console.log(query, "este es la query");
    this.global.consultar(pdata1, (err, response) => {
      if(response.protocol41 == true){
        this.ngOnInit();
        this.alerta.descValid("Se realizo  la  modificacion!");
        this.spinner.hide();
      }else{
        this.ngOnInit();
        this.alerta.descErr("Errror!");
        this.spinner.hide();
      }
    })
  }

  Desa(id){
    console.log('ID LLEBANDO', id);
    const query = 'UPDATE db_liwa.New_Tenderos SET IsBlock= \'0\' where Id_Tenderos = \'' + id + '\'';
    console.log('QUERY', query);
    const pdata1 = { option: 'insertar', texto: query };
    this.global.consultar( pdata1, (err, response) => {
      if(response.protocol41 == true){
        this.ngOnInit();
        this.alerta.descValid("Se realizo  la  modificacion!");
        this.spinner.hide();
      }else{
        this.ngOnInit();
        this.alerta.descErr("Errror!");
        this.spinner.hide();
      }
    });
  }

  Acti(id){
    console.log('ID LLEBANDO', id);
    const query = 'UPDATE db_liwa.New_Tenderos SET IsBlock= \'1\' where Id_Tenderos = \'' + id + '\'';
    console.log("Query",query )
    const pdata1 = { option: 'insertar', texto: query };
    this.global.consultar( pdata1, (err, response) => {
      if(response.protocol41 == true){
        this.ngOnInit();
        this.alerta.descValid("Se realizo  la  modificacion!");
        this.spinner.hide();
      }else{
        this.ngOnInit();
        this.alerta.descErr("Errror!");
        this.spinner.hide();
      }
    });

  }

}
