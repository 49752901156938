import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-inventario-proyecto',
  templateUrl: './inventario-proyecto.component.html'
})
export class InventarioProyectoComponent implements OnInit {
  inventario = new Array();
  inventarioResult = [];
  clientClass;
  project;
  nameProject;
  proyectos = [];
  proyectosResult = [];
  Productos = [];
  noProject: boolean = false;
  historyProduct = [];
  idProject;
  idProduct;
  nameProduct;
  qProduct;
  actualQuantity;
  idRegistro;
  proDesc;
  minQuantity;
  constructor(public global: GlobalService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alerta: GlobalAlertService,
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    console.log(this.global.idProyectoSelect);
    if (this.global.idProyectoSelect == undefined || this.global.idProyectoSelect == null || this.global.idProyectoSelect == '') {
      setTimeout(() => {
        this.clientClass = this.global.CLIENTDATALIWA[8];
        this.noProject = false;
        this.inventarioResult = [];
        this.qProduct = undefined;
        this.project = undefined;
        this.inventario = [];
        const pdata3 = { option: 'tipoProyecto' };
        console.log('tipoProyecto', pdata3);
        this.global.consultar(pdata3, (err3, response3) => {
          console.log('Datos tipoProyecto', response3);
          this.proyectos = response3;
          this.spinner.hide();
        });
      }, 600);
    } else {
      setTimeout(() => {
        try {
          this.clientClass = this.global.CLIENTDATALIWA[8];
          this.project = this.global.idProyectoSelect;
          this.noProject = false;
          this.inventarioResult = [];
          this.qProduct = undefined;
          this.inventario = [];
          const pdata3 = { option: 'tipoProyecto' };
          console.log('tipoProyecto', pdata3);
          this.global.consultar(pdata3, (err3, response3) => {
            console.log('Datos tipoProyecto', response3);
            this.proyectos = response3;
            this.Aplicar();
            this.spinner.hide();
            $(function () {
              this.table = $('#TableInventarioPr').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
              })
            });
          });
        } catch (error) {
          console.log(error);
        }

      }, 600);
    }
  }

  create() {
    if (this.idProject == undefined || this.idProduct == undefined || this.qProduct == undefined) {
      this.alerta.descErr();
    } else {
      const query = 'INSERT INTO db_liwa.Inv_Pro (Id_Inventario,Id_Proyecto,Cantidad,IsDelete,IsActive)' +
        ' VALUES (\'' +
        this.idProduct + '\',\'' +
        this.idProject + '\',\'' +
        this.qProduct + '\',\'' +
        0 + '\',\'' +
        1 + '\');';
      const pdata1 = { option: 'insertar3', texto: query };
      this.global.consultar(pdata1, async (err, response) => {
        console.log(response, query);
        if (err == null && response == true) {
          //this.spinner.hide();
          this.alerta.descValid('Producto registrado con éxito');
          this.ngOnInit();
        } else {
          console.log(err);
        }
      });
    }
  }
  addInv(content) {
    this.idProduct = undefined;
    this.modalService.open(content, { ariaLabelledBy: 'modal-vinculate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  Reiniciar() {
    this.project = undefined;
    this.proyectos = this.proyectos;
    this.noProject = false;
    this.ngOnInit();
  }
  Aplicar() {
    this.spinner.show();
    console.log(this.project);
    this.inventarioResult = [];
    this.inventario = [];
    this.idProject = this.project;
    this.global.idProyectoSelect = this.project;
    if (this.project == 'default' || this.project == undefined) {
      this.alerta.descErr('Seleccione un proyecto válido');
      this.spinner.hide();
    } else {
      try {
        for (let i = 0; i < this.proyectos.length; i++) {
          if (this.project == this.proyectos[i][0]) {
            this.nameProject = this.proyectos[i][1];
          }

        }
        const pdata4 = { option: 'inventario_proyecto', idProyecto: this.project };
        console.log('inventario_proyecto', pdata4);
        this.global.consultar(pdata4, (err4, response4) => {
          console.log('Datos inventario', response4);
          this.inventarioResult = response4;
          console.log(this.inventarioResult);
          const pdata5 = { option: 'invent', idProyecto: this.project };
          console.log('invent', pdata5);
          this.global.consultar(pdata5, (err5, response5) => {
            console.log('Datos inventario', response5);
            this.inventario = response5;
            console.log(this.inventario);
            if (this.inventario.length != 0) {
              $(function () {
                this.table = $('#TableInventarioPr').DataTable({
                  'responsive': false,
                  'destroy': true,
                  'retrieve': true,
                  'paging': true,
                  'info': false,
                  'pagingType': 'numbers',
                  'language': {
                    'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
                })
              });
            }


            this.noProject = true;
            this.spinner.hide();
          });
        });
      } catch (error) {

        this.spinner.hide();
        console.log(error);
      }
    }
  }

  addQ() {
    this.spinner.show();
    if (this.qProduct == undefined || this.proDesc == undefined) {
      this.alerta.descErr('Verifique que no hayan campos vacíos');
      this.qProduct = '';
      this.proDesc = '';
      this.spinner.hide();
    } else {
      try {
        const query = 'INSERT INTO db_liwa.Reg_Inv (Id_Inventario, Cantidad, Operacion, Descripcion, Id_usuario, IsDelete)' +
          ' VALUES (\'' +
          this.idProduct + '\',\'' +
          this.qProduct + '\',\'' +
          'Añadir' + '\',\'' +
          this.proDesc + '\',\'' +
          this.global.Id_Usuario + '\',\'' +
          0 + '\');';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            let total = (parseInt(this.actualQuantity) + parseInt(this.qProduct))
            const query2 = 'UPDATE db_liwa.Inv_Pro SET Cantidad =  \'' + total + '\' WHERE Id = \'' + this.idRegistro + '\'';
            const pdata2 = { option: 'insertar3', texto: query2 };
            this.global.consultar(pdata2, async (err2, response2) => {
              console.log(response2, query2);
              if (err2 == null && response2 == true) {
                this.qProduct = '';
                this.proDesc = '';
                this.spinner.hide();
                this.alerta.descValid('Adición éxitosa');
                this.ngOnInit();
              } else {
                console.log(err);
              }
            });
          } else {
            console.log(err);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  quitQ() {
    this.spinner.show();
    console.log(this.idRegistro);
    if (this.qProduct == undefined || this.qProduct > this.actualQuantity || this.inventario == null || this.inventario == undefined) {
      this.alerta.descErr('Verifique que la cantidad no sea mayor a la actual o que esté vacía');
      this.qProduct = '';
      this.proDesc = '';
      this.spinner.hide();
    } else {
      try {
        this.global.arrInventario = this.inventario
        for (let i = 0; i < this.inventarioResult.length; i++) {
          console.log(this.inventarioResult[i]);
          if (this.inventarioResult[i][0] == this.idRegistro) {
            for (let j = 0; j < this.inventario.length; j++) {
              if (this.inventarioResult[i][0] == this.inventario[j][0]) {

                console.log('cantidad mínima', this.inventario[i][11]);
                this.minQuantity = this.inventario[i][11];

              } else {
                continue;
              }
            }
          } else {
            continue;
          }
        }
        const query = 'INSERT INTO db_liwa.Reg_Inv (Id_Inventario, Cantidad, Operacion, Descripcion, Id_usuario, IsDelete)' +
          ' VALUES (\'' +
          this.idProduct + '\',\'' +
          this.qProduct + '\',\'' +
          'Reducir' + '\',\'' +
          this.proDesc + '\',\'' +
          this.global.Id_Usuario + '\',\'' +
          0 + '\');';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            let total = (parseInt(this.actualQuantity) - parseInt(this.qProduct));
            const query2 = 'UPDATE db_liwa.Inv_Pro SET Cantidad =  \'' + total + '\' WHERE Id = \'' + this.idRegistro + '\'';
            const pdata2 = { option: 'insertar3', texto: query2 };
            this.global.consultar(pdata2, async (err2, response2) => {
              console.log(response2, query2);
              if (err2 == null && response2 == true) {
                console.log(this.qProduct);
                console.log(this.minQuantity);
                if (total < this.minQuantity) {
                  this.sendMailAlert();
                }
                this.spinner.hide();
                this.qProduct = '';
                this.proDesc = '';
                this.alerta.descValid('Reducción éxitosa');
                this.ngOnInit();
              } else {
                console.log(err);
              }
            });
          } else {
            console.log(err);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  addQuant(id, name, content, actuallyQ, idReg) {
    this.idRegistro = idReg;
    this.idProduct = id;
    this.actualQuantity = actuallyQ;
    this.nameProduct = name;
    this.modalService.open(content, { ariaLabelledBy: 'modal-add' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  quitQuant(id, name, content, actuallyQ, idReg) {
    this.idRegistro = idReg;
    this.idProduct = id;
    this.actualQuantity = actuallyQ;
    this.nameProduct = name;
    this.modalService.open(content, { ariaLabelledBy: 'modal-quit' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  sendMailAlert() {
    setTimeout(() => {
      const pdata9 = { option: 'mailQuantityAlert', producto: this.nameProduct, idpro: this.idProduct, idProyecto: this.nameProject };
      this.global.consultar(pdata9, (err2, response2) => {
        console.log('Datos correo', response2);
      });
    }, 500);
  }
  viewHistory(content, idReg) {
    this.idRegistro = idReg;
    this.spinner.show();
    console.log(this.idRegistro);
    setTimeout(() => {
      const pdata10 = { option: 'registro_producto', idpro: this.idRegistro };
      this.global.consultar(pdata10, (err, response) => {
        try {
          console.log('Datos registro_producto', response);
          this.historyProduct = response;
          $(function () {
            this.table = $('#TableHistory').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
            })
          });
          this.modalService.open(content, { ariaLabelledBy: 'modal-viewhistory', size: 'lg' }).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
          this.spinner.hide();
        } catch (error) {
          console.log(err, error);
          this.spinner.hide();
        }
      });
    }, 600);
  }
}
