import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ExcelService } from 'src/app/excel.service';
import { GlobalService } from 'src/app/global.service';
import { GlobalAlertService } from 'src/app/global-alert.service';

@Component({
  selector: 'app-replanteo',
  templateUrl: './replanteo.component.html'
})
export class ReplanteoComponent implements OnInit {
  clientClass;
  records: any[] = [];
  tableOn: boolean = false;
  project: string;
  section: string;
  optionBar;
  total;
  totalEncuestasInterventoria:Number = 0
  // Toexcel;
  Excel;
  Style;
  listActive: boolean = true;
  informesActive: boolean = false;
  mapActive: boolean = false;
  latitude = 4.66125;
  longitude = -73.994817;
  zoom: number = 6;
  pines: any[] = [];
  previous;
  Fecha: any[] = [];
  firstGraphic;
  labelsDate: any[] = [];
  dataDates: any[] = [];
  flagChart: boolean = false;
  totalchartflag: boolean = false;
  totalaprobedchartflag: boolean = false;
  dailychartflag: boolean = false;
  operatorchartflag: boolean = false;
  pollsters: any[] = [];
  encuestadorSelect = "Seleccione un encuestador...";
  encuestadorSelect1 = "Seleccione un encuestador...";
  texto = "Seleccione un encuestador...";
  generalFilter: boolean = false;
  pollsterFilter: boolean = false;
  apollFilter: boolean = false;
  everFilter: string;
  everFilter0: string;
  everFilter1: boolean = false;
  everFilter2: boolean = false;
  everFilter3: boolean = false;
  everFilter4: boolean = false;
  everFilter5: boolean = false;
  everFilter6: boolean = false;
  replanteotime;
  replanteotime1;
  idSelected;
  totalAprobadas: number;
  actualText: string;
  nameLabel:string;

  constructor(public global: GlobalService,
    private route: Router,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idSeccional == undefined) {
      this.global.idSeccional = sessionStorage.getItem('replanteoSeccional');
      this.init();
    } else {
      this.init();
    }
  }
  init() {
      this.global.onlyView = false
      this.clientClass = this.global.CLIENTDATALIWA[8];
      this.Style = this.global.mapStyle;
      const pdata = { option: 'replanteo-seccional', idSeccional: this.global.idSeccional };
      // console.log(pdata,"a ver que trae")
      this.global.consultar(pdata, async  (err, response) => {
        console.log('este es el problema',await response.length )
        if ( await response.length === 0) {
          this.spinner.hide()
          this.alert.descErr('No hay datos relacionados con esta petición')
          this.route.navigateByUrl('/replanteo-usuarios')
        } else if ( await response != undefined ||  await response != null) {
          // el 16 es un rol de interventor este solo debe ver las aprobadas por el equipo por lo tanto filtramos si el global tiene este rol
          this.records = this.global.idRole === '16' ? await response.filter( e => e[9] === 1 ) :  await response;
          this.project = this.records[0][0];
          this.section = this.records[0][1];
          this.totalEncuestasInterventoria = response.filter( e => e[10] === 1 ).length
          this.total = response.length;
          let userMap: any[] = [];
          for (let i = 0; i < this.records.length; i++) {
            userMap.push(this.records[i][2]);
            userMap.push(this.records[i][7]);
            userMap.push(this.records[i][15]);
            userMap.push(this.records[i][19]);
            userMap.push(this.records[i][20]);
            this.pines.push(userMap);
            userMap = [];
          }
          // console.log(this.records, 'esto es el this.pines que se supone')
          const pdata1 = { option: 'replanteo-chart-total-aprobadas', idSeccional: this.global.idSeccional };
          // console.log(this.global.idSeccional , 'esty es hndfjklhsfl')
          this.global.consultar(pdata1, async (er1, response1) => {
            this.totalAprobadas = await response1[0][0];

            $(function () {
              this.table = $('#treplanteo').DataTable({
                'responsive': false,
                // order: [[5, "desc"]], ordenado por revisar activo ??
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
            this.tableOn = true;
            this.spinner.hide();
          });
        } else {
          this.tableOn = false;
          this.spinner.hide();
        }
      });

  }
  view(data) {
    // console.log(data , 'este es el que esta en view')
    this.global.idBenefit = data;
    this.global.donePoll = '';
    this.route.navigateByUrl('/replanteo-form');
  }
  details(data, forPdf) {
    // console.log(data , 'este es el que esta en details asi que es el 2do')
    this.global.donePoll = forPdf;
    this.global.idBenefit = data;
    this.global.onlyView = true;
    this.route.navigateByUrl('/replanteo-form');
  }
  isList() {
    if (this.listActive == false) {
      this.mapActive = false;
      this.informesActive = false;
      this.listActive = true;
      this.restartChart();
      $(function () {
        this.table = $('#treplanteo').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
    } else {
      this.listActive = false;
    }
  }
  isInforme() {
    if (this.informesActive == false) {
      this.spinner.show();
      setTimeout(() => {
        this.actualText = 'Total de encuestas diarias';
        this.mapActive = false;
        this.informesActive = true;
        this.listActive = false;
        this.totalchartflag = true;
        this.totalaprobedchartflag = true;
        const pdata2 = { option: 'replanteo-chart', idSeccional: this.global.idSeccional };
        this.global.consultar(pdata2, (err2, response2) => {
          for (let i = 0; i < response2.length; i++) {
            this.labelsDate.push(response2[i][0]);
            this.dataDates.push(response2[i][1]);
          }
          this.flagChart = true;
          setTimeout(() => {
            this.processData('Encuestas por día');
          }, 300);
          const pdata3 = { option: 'replanteo-chart-encuestadores' }
          this.global.consultar(pdata3, (err3, response3) => {
            console.log(response3);
            this.pollsters = response3;
            this.spinner.hide();
          });
        });
      }, 1300);
    } else {
      this.informesActive = false;
    }
  }
  isMap() {
    if (this.mapActive == false) {
      this.mapActive = true;
      this.informesActive = false;
      this.listActive = false;
      this.restartChart();
    } else {
      this.mapActive = false;
    }
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  processData(nameLa:string) {
    this.optionBar = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: this.dataDates
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: nameLa,
          data: this.labelsDate,
          type: 'bar'
        }
      ]
    };
  }
  confingDate(date):string {

    switch (parseInt(date)) {
      case 1:
        return 'Enero';
      case 2:
        return 'Febrero';
      case 3:
        return 'Marzo';
      case 4:
        return 'Abril';
      case 5:
        return 'Mayo';
      case 6:
        return 'Junio';
      case 7:
        return 'Julio';
      case 8:
        return 'Agosto';
      case 9:
        return 'Septiembre';
      case 10:
        return 'Octubre';
      case 11:
        return 'Noviembre';
      case 12:
        return 'Diciembre';

      default:
        break;
    }

  }
  restartChart() {
    this.labelsDate = [];
    this.dataDates = [];
    this.flagChart = false;
  }
  totalChart() {
    this.restartChart();
    this.actualText = 'Total de encuestas en seccional';
    this.dailychartflag = true;
    this.totalchartflag = false;
    this.totalaprobedchartflag = true;
    this.encuestadorSelect = '';
    this.spinner.show();
    // setTimeout(() => {
      const pdata2 = { option: 'replanteo-chart-total', idSeccional: this.global.idSeccional };
      this.global.consultar(pdata2, async (err2, response2) => {
        // console.log(await response2);
        for (let i = 0; i < await response2.length; i++) {
          this.labelsDate.push(await response2[i][0]);
          this.dataDates.push(await response2[i][1]);
        }
        this.flagChart = true;
        // setTimeout(() => {
          this.processData('Total de encuestas a la fecha de hoy');
        // }, 300);
        this.spinner.hide();
      });
    // }, 600);
  }
  dailyChart() {
    this.restartChart();
    this.actualText = 'Total de encuestas diarias';
    this.totalchartflag = true;
    this.totalaprobedchartflag = true;
    this.dailychartflag = false;
    this.encuestadorSelect = '';
    this.spinner.show();
    // setTimeout(() => {
      const pdata2 = { option: 'replanteo-chart', idSeccional: this.global.idSeccional };
      this.global.consultar(pdata2, async  (err2, response2) => {
        for (let i = 0; i < await response2.length; i++) {
          this.labelsDate.push(await response2[i][0]);
          this.dataDates.push(await response2[i][1]);
        }
        this.flagChart = true;
        // setTimeout(() => {
          this.processData('Día');
        // }, 300);
        this.spinner.hide();
      });
    // }, 600);
  }
  totalAprobedChart() {
    this.restartChart();
    this.actualText = 'Total de encuestas aprobadas';
    this.totalchartflag = true;
    this.totalaprobedchartflag = false;
    this.dailychartflag = true;
    this.encuestadorSelect = '';
    this.spinner.show();
    // setTimeout(() => {
      const pdata2 = { option: 'replanteo-chart-total-aprobadas', idSeccional: this.global.idSeccional };
      console.log(pdata2);
      this.global.consultar(pdata2, async (err2, response2) => {
        for (let i = 0; i < await response2.length; i++) {
          this.labelsDate.push( await response2[i][0]);
          this.dataDates.push( await response2[i][1]);
        }
        this.flagChart = true;
        // setTimeout(() => {
          this.processData('Encuestas aprobadas a la fecha de hoy');
        // }, 300);
        this.spinner.hide();
      });
    // }, 600);
  }
  byPollster(enc: string, ever: string, dater) {
    this.actualText = ever + ' de encuestas generales ' + '- Encuestador: ' + this.pollvalue(enc);
    if (enc == 'Seleccione un encuestador...' || enc == undefined || ever == undefined || ever == null || dater == undefined || dater == '') {
      this.alert.descErr('Seleccione un encuestador y el filtro correspondiente');
    } else {
      switch (ever) {
        case 'Diario':
          console.log(dater, 'este es el dato que esta llegando Dia')

          this.restartChart();
          this.dailychartflag = true;
          this.totalchartflag = true;
          this.spinner.show();
          // setTimeout(() => {
            const pdata2 = { option: 'replanteo-chart-dia', idSeccional: this.global.idSeccional, idEncuestador: enc, replanteoFecha: dater };
            this.global.consultar(pdata2, async (err2, response2) => {
              console.log(pdata2);
              if (await response2.length == 0) {
                this.flagChart = false;
                this.spinner.hide();
                this.alert.descErr(`Este encuestad@r no registra encuestas para la fecha del Dia ${dater.substr(0,7)}`)
              } else {
                let day;
                for (let i = 0; i < await response2.length; i++) {
                  this.labelsDate.push(await response2[i][0]);
                  this.dataDates.push(await response2[i][1]);
                  day = await response2[i][1];
                }
                this.flagChart = true;
                // setTimeout(() => {
                  this.processData(dater);
                // }, 300);
                this.spinner.hide();
              }
            });
          // }, 600);
          break;
        case 'Mensual':
          console.log(dater, 'este es el dato que esta llegando mes')
          this.restartChart();
          dater = dater ;
          let desde = dater.substr(0,7) + '-01'
          let hasta = dater.substr(0,7) + '-29'
          console.log(desde, 'este des desde y este hasta:', hasta);
          this.dailychartflag = true;
          this.totalchartflag = true;
          this.spinner.show();
          // setTimeout(() => {
            const pdata3 = { option: 'replanteo-chart-solution',  idEncuestador: enc, fecha: desde, fecha2: hasta };
            // console.log(pdata2,'sdadasdasdasdadasdasdasdasdadasdas')
            this.global.consultar(pdata3, async (err2, response2) => {

              if (await response2.length == 0) {
                this.flagChart = false;
                this.spinner.hide();
                this.alert.descErr(`Este encuestad@r no registra encuestas para la fecha del mes ${dater.substr(0,7)}`)
              } else {
                for (let i = 0; i < await response2.length; i++) {
                  this.labelsDate.push(await response2[i][0]);
                  this.dataDates.push(await response2[i][1]);
                }
                this.flagChart = true;
                // setTimeout(() => {
                  this.processData(this.confingDate(dater.split('-')[1]));
                // }, 300);
                this.spinner.hide();
              }
            });
          // }, 600);
          break;
        case 'Anual':
          this.restartChart();
          this.dailychartflag = true;
          this.totalchartflag = true;
          let desdeyear = dater.substr(0,4) + '-01-01'
          let hastayear = dater.substr(0,4) + '-12-29'
          this.spinner.show();
          // console.log(hastayear,desdeyear, 'este es el dato que esta llegando anio')
          // setTimeout(() => {
            const pdata4 = { option: 'replanteo-chart-solution-anio',  idEncuestador: enc, fecha: desdeyear, fecha2: hastayear };
            this.global.consultar(pdata4, async  (err2, response2) => {
              if (await response2.length == 0) {
                this.flagChart = false;
                this.spinner.hide();
                this.alert.descErr(`Este encuestad@r no registra encuestas para la fecha del año ${dater.substr(0,7)}`)
              } else {
                for (let i = 0; i < await response2.length; i++) {
                  // console.log(await response2[i][1].substr(5) , 'luis', );
                  this.labelsDate.push(await response2[i][0]);
                  this.dataDates.push(this.confingDate(await response2[i][1].split('-',2)[1]));
                }
                this.flagChart = true;
                // setTimeout(() => {
                  this.processData(dater.split('-')[0]);
                // }, 300);
                this.spinner.hide();
              }
            });
          // }, 600);

          break;

        default:
          break;
      }
    }
  }
  byPollsterAprobed(enc: string, ever: string, dater) {
    this.actualText = ever + ' de encuestas aprobadas ' + '- Encuestador: ' + this.pollvalue(enc);
    if (enc == 'Seleccione un encuestador...' || enc == undefined || ever == undefined || ever == null || dater == undefined || dater == '') {
      this.alert.descErr('Seleccione un encuestador y el filtro correspondiente');
    } else {
      switch (ever) {
        case 'Diario':
          this.restartChart();
          this.dailychartflag = true;
          this.totalchartflag = true;
          this.spinner.show();
          // setTimeout(() => {
            const pdata2 = { option: 'replanteo-chart-encuestadores-dia', idSeccional: this.global.idSeccional, idEncuestador: enc, replanteoFecha: dater };
            this.global.consultar(pdata2, async (err2, response2) => {
              // console.log(await response2);
              if (await response2.length == 0) {
                this.flagChart = false;
                this.spinner.hide();
              } else {
                for (let i = 0; i < await response2.length; i++) {
                  this.labelsDate.push(await response2[i][0]);
                  this.dataDates.push(await response2[i][1]);
                }
                this.flagChart = true;
                // setTimeout(() => {
                  this.processData(dater);
                // }, 300);
                this.spinner.hide();
              }
            });
          // }, 600);
          break;
        case 'Mensual':
          this.restartChart();
          dater = dater + '-01';
          this.dailychartflag = true;
          this.totalchartflag = true;
          this.spinner.show();
          // setTimeout(() => {
            console.log(dater, 'este pana')
            const pdata5 = { option: 'replanteo-chart-encuestadores-mes', idSeccional: this.global.idSeccional, idEncuestador: enc, replanteoFecha: dater };
            this.global.consultar(pdata5, async (err2, response2) => {
              if (await response2.length == 0) {
                this.flagChart = false;
                this.spinner.hide();
              } else {
                for (let i = 0; i < await response2.length; i++) {
                  this.labelsDate.push(await response2[i][0]);
                  this.dataDates.push(await response2[i][1]);
                }
                this.flagChart = true;
                // setTimeout(() => {
                  this.processData(this.confingDate(dater.split('-')[1]));
                // }, 300);
                this.spinner.hide();
              }
            });
          // }, 600);
          break;
        case 'Anual':
          this.restartChart();
          this.dailychartflag = true;
          this.totalchartflag = true;
          this.spinner.show();
          // setTimeout(() => {
            const pdata6 = { option: 'replanteo-chart-encuestadores-anio', idSeccional: this.global.idSeccional, idEncuestador: enc, replanteoFecha: dater };
            this.global.consultar(pdata6, async  (err2, response2) => {
              // console.log(pdata2);
              if (await response2.length == 0) {
                this.flagChart = false;
                this.spinner.hide();
              } else {
                for (let i = 0; i < await response2.length; i++) {
                  this.labelsDate.push(await response2[i][0]);
                  this.dataDates.push(this.confingDate(await response2[i][1]));
                }
                this.flagChart = true;
                // setTimeout(() => {
                  this.processData(dater.split('-')[0]);
                // }, 300);
                this.spinner.hide();
              }
            });
          // }, 600);

          break;

        default:
          break;
      }
    }
  }
  filterTab(num: number) {
    switch (num) {
      case 1:
        if (this.generalFilter == false) {
          this.generalFilter = true;
          this.pollsterFilter = false;
          this.apollFilter = false;
        } else {
          this.generalFilter = false;
        }
        break;
      case 2:
        if (this.pollsterFilter == false) {
          this.pollsterFilter = true;
          this.generalFilter = false;
          this.apollFilter = false;
        } else {
          this.pollsterFilter = false;
        }
        break;
      case 3:
        if (this.apollFilter == false) {
          this.apollFilter = true;
          this.pollsterFilter = false;
          this.generalFilter = false;
        } else {
          this.apollFilter = false;
        }
        break;

      default:
        break;
    }
  }
  pollvalue(idpollster): string {
    let name: string;
    for (let i = 0; i < this.pollsters.length; i++) {
      if (this.pollsters[i][0] == idpollster) {
        name = this.pollsters[i][1]
      } else {
        continue;
      }
    }
    return name;
  }
  delete(idEncuesta:any) {
    const pdata = { option: 'insertar3', texto: `UPDATE db_liwa.PorcentajeAOM SET IsDelete = 1 WHERE Id ='${idEncuesta}';` };
    this.global.consultar(pdata, async (err4, response4) => {

     this.alert.descValid('Encuesta eliminada con exito')
     this.ngOnInit()
    //  window.location.reload()
    })

  }
}
