import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-editar-proyecto',
  templateUrl: './editar-proyecto.component.html'
})
export class EditarProyectoComponent implements OnInit {
  proyectos=[];
  sls;
  shs;
  monitoreo;
  software;
  idTipo;
  nombreProyecto;
  tipoProyecto;
  clienteProyecto;
  comentarios;
  clientClass;
  tipos=[
    {tipo: 'SLS'},
    {tipo: 'SHS'},
    {tipo: 'MONITOREO'},
    {tipo: 'SOFTWARE'}
  ];
  constructor(public global: GlobalService, public ruta: Router,public alerta:GlobalAlertService, private spinner: NgxSpinnerService ) { 
    this.global.initData();
  }

  ngOnInit(): void {
    if(this.global.idEditProject == undefined){
      this.ruta.navigateByUrl('/proyecto');
    }else{
      this.clientClass = this.global.CLIENTDATALIWA[8];

      console.log(this.global.idEditProject);
      const datos = {option: 'tipoProyecto'};
      $.ajax({data: datos,	cache: false,	dataType: 'json',	type:  'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success( data, textStatus, jQxhr ) {
          console.warn('bien', data, textStatus);
        },
        error( jqXhr, textStatus, errorThrown ) {
          console.warn('mal');
        }
        }).then((response) => {
          this.proyectos = response;
          for (let i = 0; i < this.proyectos.length; i++) {
            if(this.global.idEditProject === this.proyectos[i][0]){
              console.log(this.proyectos[i][0]);
              this.nombreProyecto = this.proyectos[i][1];
              this.tipoProyecto = this.proyectos[i][2];
              this.clienteProyecto = this.proyectos[i][3];
              this.comentarios = this.proyectos[i][4];
            }else{continue;}
          }     
        });
        
    }
  }
  
  goBack() {
    window.history.back();
  }
  editar(){
    this.spinner.show();
    setTimeout(() => {
      if (this.nombreProyecto == undefined || this.tipoProyecto == undefined || this.clienteProyecto == undefined || this.comentarios == undefined) {

        this.alerta.descErr('Error al crear nuevo usuario, verifique nuevamente que no hayan campos vacíos');
     
      } else {
        const query = 'UPDATE db_liwa.tipoProyecto SET Nombre =  \'' + this.nombreProyecto + '\', Tipo = \'' + this.tipoProyecto + '\', Cliente = \'' + this.clienteProyecto + '\', Comentarios = \'' + this.comentarios + '\' WHERE id_tipoProyecto = \'' + this.global.idEditProject + '\'';
        const pdata1 = {option: 'insertar3', texto: query};
        this.global.consultar(pdata1, (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.spinner.hide();
            this.alerta.descValid('El registro se ha actualizado correctamente');
            this.ruta.navigateByUrl('/proyecto');
          } 
        });
      }
      this.spinner.hide();
    }, 500);
  }

}
