import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { CrearUsuarioComponent } from './pages/crear-usuario/crear-usuario.component';
import {RecoverComponent} from 'src/app/pages/recover/recover.component';
import { EditarUsuarioComponent } from './pages/editar-usuario/editar-usuario.component';
import { CrearClienteComponent } from './pages/crear-cliente/crear-cliente.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdmTicketComponent } from './pages/adm-ticket/adm-ticket.component';
import { AdmPqmnComponent } from './pages/adm-pqmn/adm-pqmn.component';
import { UserTicketComponent } from './pages/user-ticket/user-ticket.component';
import { UserFaqComponent } from './pages/user-faq/user-faq.component';
import { UserManualComponent } from './pages/user-manual/user-manual.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ColorCircleModule } from 'ngx-color/circle';
import { CreateTicketComponent } from './pages/create-ticket/create-ticket.component';
import { VerTicketComponent } from './pages/ver-ticket/ver-ticket.component';
import { EditarClienteComponent } from './pages/editar-cliente/editar-cliente.component';
import { AdmFvComponent } from './pages/adm-fv/adm-fv.component';
import { MapComponent } from './pages/map/map.component';
import { PaginaPrincipalComponent } from './pages/pagina-principal/pagina-principal.component';
import { AdmMantenimientoComponent } from './pages/adm-mantenimiento/adm-mantenimiento.component';
import { VisitasComponent } from './pages/visitas/visitas.component';
import { VerVisitaComponent } from './pages/ver-visita/ver-visita.component';
import { CrearVisitaComponent } from './pages/crear-visita/crear-visita.component';
import { SwiperModule } from 'swiper/angular';
import { AgmCoreModule } from '@agm/core';
import { CrearTipoProyectoComponent } from './pages/crear-tipo-proyecto/crear-tipo-proyecto.component';
import { ProyectoComponent } from './pages/proyecto/proyecto.component';
import { EditarProyectoComponent } from './pages/editar-proyecto/editar-proyecto.component';
import { AdmAomComponent } from './pages/adm-aom/adm-aom.component';
import { AdqBeneficiariosComponent } from './pages/adq-beneficiarios/adq-beneficiarios.component';
import { ListadoPreliminarComponent } from './pages/listado-preliminar/listado-preliminar.component';
import { FormularioPreliminarComponent } from './pages/formulario-preliminar/formulario-preliminar.component';
import { ListadoDefinitivoComponent } from './pages/listado-definitivo/listado-definitivo.component';
import { RegistroFabricacionComponent } from './pages/registro-fabricacion/registro-fabricacion.component';
import { ProcesoPruebasComponent } from './pages/proceso-pruebas/proceso-pruebas.component';
import { SeccionalesComponent } from './pages/seccionales/seccionales.component';
import { CrearSeccionalComponent } from './pages/crear-seccional/crear-seccional.component';
import { EditarSeccionalComponent } from './pages/editar-seccional/editar-seccional.component';
import { InventarioComponent } from './pages/inventario/inventario.component';
import { InventarioProyectoComponent } from './pages/inventario-proyecto/inventario-proyecto.component';
import { ProyectosComponent } from './pages/proyectos/proyectos.component';
import { SedeProvidenciaComponent } from './pages/sede-providencia/sede-providencia.component';
import { EnelarComponent } from './pages/enelar/enelar.component';
import { PipelineMayoComponent } from './pages/pipeline-mayo/pipeline-mayo.component';
import { Enelar2Component } from './pages/enelar2/enelar2.component';
import { RecepcionComponent } from './pages/recepcion/recepcion.component';
import { CalibracionComponent } from './pages/calibracion/calibracion.component';
import { AlistamientoComponent } from './pages/alistamiento/alistamiento.component';
import { DemoDispacComponent } from './pages/demo-dispac/demo-dispac.component';
import { PInstalacionComponent } from './pages/p-instalacion/p-instalacion.component';
import { ArchwizardModule } from 'angular-archwizard';
import { LuminariasComponent } from './pages/luminarias/luminarias.component';
import { FenogeComponent } from './pages/fenoge/fenoge.component';
import { Fenoge2Component } from './pages/fenoge2/fenoge2.component';
import { PLuminariaComponent } from './pages/p-luminaria/p-luminaria.component';
import { BrowserModule } from '@angular/platform-browser';
// import { JdComponent } from './pages/jd/jd.component';
import { ComunicacionesComponent } from './pages/comunicaciones/comunicaciones.component';
import { RegistroTarjetasComponent } from './pages/registro-tarjetas/registro-tarjetas.component';
import { SIGComponent } from './pages/sig/sig.component';
import { ClaroComponent } from './pages/claro/claro.component';
import { CInstalacionComponent } from './pages/c-instalacion/c-instalacion.component';
import { BibliotecaMapasComponent } from './pages/biblioteca-mapas/biblioteca-mapas.component';
import { AulasGEPComponent } from './pages/aulas-gep/aulas-gep.component';
import { BeneficiariosComponent } from './pages/beneficiarios/beneficiarios.component';
import { FormularioDefinitivoComponent } from './pages/formulario-definitivo/formulario-definitivo.component';
import { DemoDupComponent } from './pages/demo-dup/demo-dup.component';
import { UsuariosNazarethComponent } from './pages/usuarios-nazareth/usuarios-nazareth.component';
import { InventarioDeRedComponent } from './pages/inventario-de-red/inventario-de-red.component';
import { ListadoDispositivosComponent } from './pages/listado-dispositivos/listado-dispositivos.component';
import { MapaProyectoComponent } from './pages/mapa-proyecto/mapa-proyecto.component';
import { FormularioPreliminarNazarethComponent } from './pages/formulario-preliminar-nazareth/formulario-preliminar-nazareth.component';
import { ListadoPreliminarNazarethComponent } from './pages/listado-preliminar-nazareth/listado-preliminar-nazareth.component';
import { ListadoDefinitivoNazarethComponent } from './pages/listado-definitivo-nazareth/listado-definitivo-nazareth.component';
import { ListadoPreliminarRedesNazarethComponent } from './pages/listado-preliminar-redes-nazareth/listado-preliminar-redes-nazareth.component';
import { ListadoPreliminarDispNazarethComponent } from './pages/listado-preliminar-disp-nazareth/listado-preliminar-disp-nazareth.component';
import { FormularioPreliminarRedesNazarethComponent } from './pages/formulario-preliminar-redes-nazareth/formulario-preliminar-redes-nazareth.component';
import { FormularioPreliminarDispNazarethComponent } from './pages/formulario-preliminar-disp-nazareth/formulario-preliminar-disp-nazareth.component';
import { ListadoDefinitivoDispNazarethComponent } from './pages/listado-definitivo-disp-nazareth/listado-definitivo-disp-nazareth.component';
import { ListadoDefinitivoRedesNazarethComponent } from './pages/listado-definitivo-redes-nazareth/listado-definitivo-redes-nazareth.component';
import { GensaPuertoCarrenoComponent } from './pages/gensa-puerto-carreno/gensa-puerto-carreno.component';
import { GensaPcRevisionComponent } from './pages/gensa-pc-revision/gensa-pc-revision.component';
import { GetDatatableDirective } from './directives/get-datatable.directive';
import { InformesDeRecargasComponent } from './pages/informes-de-recargas/informes-de-recargas.component';
import { HistorialDeRecargasComponent } from './pages/historial-de-recargas/historial-de-recargas.component';
import { HistoricosEInformacionComponent } from './pages/historicos-e-informacion/historicos-e-informacion.component';
import { RevisionFotosEnelarComponent } from './pages/revision-fotos-enelar/revision-fotos-enelar.component';
import { NgSelect2Module } from 'ng-select2';
import { NgxHorizontalTimelineModule } from 'ngx-horizontal-timeline';
import { FasesGepComponent } from './pages/fases-gep/fases-gep.component';
import { GebInstalacionComponent } from './pages/geb-instalacion/geb-instalacion.component';
import { ReplanteoUsuariosComponent } from './pages/replanteo-usuarios/replanteo-usuarios.component';
import { ReplanteoComponent } from './pages/replanteo/replanteo.component';
import { ReplanteoFormComponent } from './pages/replanteo-form/replanteo-form.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MedidoresComponent } from './pages/medidores/medidores.component';
import { InicialComponent } from './pages/inicial/inicial.component';
import { YutahoComponent } from './pages/yutaho/yutaho.component';
import { FormatosComponent } from './pages/formatos/formatos.component';
import { FormatoSuiComponent } from './pages/formato-sui/formato-sui.component';
import { InformacionUsuarioComponent } from './pages/informacion-usuario/informacion-usuario.component';
import { HistoricoDatosComponent } from './pages/historico-datos/historico-datos.component';
import { TenderosComponent } from './pages/tenderos/tenderos.component';
import { FacturacionComponent } from './pages/facturacion/facturacion.component';
import { PagosComponent } from './pages/pagos/pagos.component';
import { GestionCarteraComponent } from './pages/gestion-cartera/gestion-cartera.component';
import { DetalleTenderosComponent } from './pages/detalle-tenderos/detalle-tenderos.component';
import { ActualizacioDatosComponent } from './pages/actualizacio-datos/actualizacio-datos.component';
import { AdministracionTecnicosComponent } from './pages/administracion-tecnicos/administracion-tecnicos.component';
import { MantenimientosTecnicoComponent } from './pages/mantenimientos-tecnico/mantenimientos-tecnico.component';
import { DescargablesComponent } from './pages/descargables/descargables.component';
// import { PagesComponent } from './pages/pages.component';
import { GetionLlamadasComponent } from './pages/getion-llamadas/getion-llamadas.component';
import { ClienteComponent } from './pages/cliente/cliente.component';
import { ComisionesComponent } from './pages/comisiones/comisiones.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgxHorizontalTimelineModule,
    ColorCircleModule,
    SwiperModule,
    NgSelect2Module,
    ArchwizardModule,
    FullCalendarModule, // register FullCalendar with you app
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDDNunlrlRApoo4bo5L8V5fMcBCTjZDbK8',
      libraries: ['places']}),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    CrearUsuarioComponent,
    RecoverComponent,
    EditarUsuarioComponent,
    CrearClienteComponent,
    AdmTicketComponent,
    AdmPqmnComponent,
    UserTicketComponent,
    UserFaqComponent,
    UserManualComponent,
    ContactComponent,
    CreateTicketComponent,
    HistoricoDatosComponent,
    VerTicketComponent,
    EditarClienteComponent,
    AdmFvComponent,
    MapComponent,
    PaginaPrincipalComponent,
    AdmMantenimientoComponent,
    VisitasComponent,
    VerVisitaComponent,
    CrearVisitaComponent,
    CrearTipoProyectoComponent,
    ProyectoComponent,
    EditarProyectoComponent,
    AdmAomComponent,
    AdqBeneficiariosComponent,
    ListadoPreliminarComponent,
    FormularioPreliminarComponent,
    ListadoDefinitivoComponent,
    RegistroFabricacionComponent,
    ProcesoPruebasComponent,
    SeccionalesComponent,
    CrearSeccionalComponent,
    EditarSeccionalComponent,
    InventarioComponent,
    InventarioProyectoComponent,
    ProyectosComponent,
    SedeProvidenciaComponent,
    EnelarComponent,
    PipelineMayoComponent,
    Enelar2Component,
    RecepcionComponent,
    CalibracionComponent,
    AlistamientoComponent,
    DemoDispacComponent,
    PInstalacionComponent,
    LuminariasComponent,
    FenogeComponent,
    Fenoge2Component,
    PLuminariaComponent,
    // JdComponent,
    ComunicacionesComponent,
    RegistroTarjetasComponent,
    SIGComponent,
    ClaroComponent,
    MedidoresComponent,
    InicialComponent,
    ClienteComponent,
    CInstalacionComponent,
    BibliotecaMapasComponent,
    AulasGEPComponent,
    BeneficiariosComponent,
    FormularioDefinitivoComponent,
    DemoDupComponent,
    UsuariosNazarethComponent,
    InventarioDeRedComponent,
    ListadoDispositivosComponent,
    MapaProyectoComponent,
    FormularioPreliminarNazarethComponent,
    ListadoPreliminarNazarethComponent,
    ListadoDefinitivoNazarethComponent,
    ListadoPreliminarRedesNazarethComponent,
    ListadoPreliminarDispNazarethComponent,
    FormularioPreliminarRedesNazarethComponent,
    FormularioPreliminarDispNazarethComponent,
    ListadoDefinitivoDispNazarethComponent,
    ListadoDefinitivoRedesNazarethComponent,
    GensaPuertoCarrenoComponent,
    GensaPcRevisionComponent,
    GetDatatableDirective,
    InformesDeRecargasComponent,
    HistorialDeRecargasComponent,
    HistoricosEInformacionComponent,
    RevisionFotosEnelarComponent,
    FasesGepComponent,
    GebInstalacionComponent,
    ReplanteoUsuariosComponent,
    ReplanteoComponent,
    ReplanteoFormComponent,
    YutahoComponent,
    FormatosComponent,
    FormatoSuiComponent,
    InformacionUsuarioComponent,
    HistoricoDatosComponent,
    TenderosComponent,
    FacturacionComponent,
    PagosComponent,
    GestionCarteraComponent,
    DetalleTenderosComponent,
    ActualizacioDatosComponent,
    AdministracionTecnicosComponent,
    MantenimientosTecnicoComponent,
    DescargablesComponent,
    // PagesComponent,
    GetionLlamadasComponent,
    ComisionesComponent,

  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
