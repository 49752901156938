import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-faq',
  templateUrl: './user-faq.component.html',
  styleUrls: ['./user-faq.component.scss']
})
export class UserFaqComponent implements OnInit {
USERDATALIWA;
clientClass;
Questions;
Theme;
nameModal;
productModal;
questionModal;
phoneModal;
mailModal;
themeQuestion;
questionDesc;
questionActive;
questionsflag = false;
productos;
product = '0';
QuestionsOri;
noQuestion = false;
  constructor(public global: GlobalService, private spinner: NgxSpinnerService, private alerta: GlobalAlertService, private route: Router) {
   this.global.initData();
   }
  ngOnInit(): void {
    this.spinner.show();
    const pdata9 = { option: 'productos' };
    this.global.consultar(pdata9, (err9, response9) => {
      console.log('Datos Productos', response9);
      this.productos = response9;
    });
    this.clientClass = this.global.CLIENTDATALIWA[8] ;
    const pdata8 = { option: 'questions', client: this.global.CLIENTDATALIWA[0] === "1" ? "7" : this.global.CLIENTDATALIWA[0]};
    console.log('PREGUNTAS', pdata8);
    this.global.consultar(pdata8, (err8, response8) => {
    console.log('Datos Preguntas', response8);
      this.QuestionsOri = this.Questions = response8;
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 100);
  }
  showQuestion(idx: number) {
    this.questionActive = idx;
    this.questionsflag = !this.questionsflag;
  }

  Reiniciar() {
    this.Questions = this.QuestionsOri;
    this.product = '0';
  }

  Reiniciar2() {
    this.Questions = this.QuestionsOri;
  }

  Aplicar() {
    console.log('este es el console log del 1000dollares', this.product)
    if (this.product == '0') {
      this.Questions = this.QuestionsOri;
      console.log('no hay nada');
    } else {
      this.Reiniciar2();
      console.log('Buscando', this.product);
      const val = this.product;
      if (val && val.trim() != '') {
        this.Questions = this.QuestionsOri.filter((item) => {
         if((item[2] == val)==false){
          this.noQuestion= true;
         }else{
          this.noQuestion=false;
         }
          return (item[2] == val);
        });
      }
    }
  }
  productTitle(data){
    for (let i = 0; i < this.productos.length; i++) {
      if(data == this.productos[i][0]){
          return this.productos[i][1]
      }else{
        continue;
      }
    }
  }
  sendMail(){
    if( this.nameModal == undefined ||  this.productModal == undefined || this.questionModal == undefined || this.phoneModal == undefined || this.mailModal == undefined){
      this.alerta.descErr();

    }else{
      for (let i = 0; i < this.productos.length; i++) {
        if(this.productModal == this.productos[i][0]){
          this.productModal = this.productos[i][1];
        }else{
          continue;
        }
      } setTimeout(() => {
        this.spinner.show();
        const pdata9 = { option: 'mailFaq', contacto: this.nameModal, novedad: this.productModal, texto: this.questionModal, tel:this.phoneModal, mailCont: this.mailModal  }; 
        this.global.consultar(pdata9, (err2, response2) => {
        this.alerta.descValid('Evaluaremos su mensaje y le daremos una pronta respuesta');
        this.nameModal="";
        this.productModal="";
        this.questionModal="";
        this.phoneModal="";
        this.mailModal="";
        this.ngOnInit();
          this.spinner.hide();
          console.log('Datos correo', response2);
        });
        }, 500); 
    }


   
  }
}
