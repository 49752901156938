import { Component, OnInit, ElementRef } from '@angular/core';
import { INTERNAL, ROUTES, ROUTES2, ROUTES3, ROUTES4, ROUTES5,ROUTES6,ROUTES7, TITLES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  usuarios = new Array ();
  srcImg;
  inPrincipal:boolean = true;
  public focus;
  public listInternal: any[];
  public listTitles: any[];
  public listTitles1: any[];
  public listTitles2: any[];
  public listTitles3: any[];
  public listTitles4: any[];
  public listTitles5: any[];
  public listTitles6: any[];
  public listTitles7: any[];
  public location: Location;
  constructor(location: Location,  private element: ElementRef, private router: Router, public global: GlobalService) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = TITLES.filter(listTitle => listTitle);
    this.listTitles1 = ROUTES.filter(listTitle => listTitle);
    this.listTitles2 = ROUTES2.filter(listTitle => listTitle);
    this.listTitles3 = ROUTES3.filter(listTitle => listTitle);
    this.listTitles4 = ROUTES4.filter(listTitle => listTitle);
    this.listTitles5 = ROUTES5.filter(listTitle => listTitle);
    this.listTitles6 = ROUTES6.filter(listTitle => listTitle);
    this.listTitles7 = ROUTES7.filter(listTitle => listTitle);
    this.listInternal = INTERNAL.filter(listTitle => listTitle);
    
    const pdata8 = { option: 'user', user: this.global.Id_Usuario};
    this.global.consultar(pdata8, (err8, response8) => {
      // console.log('Datos Usuario WWWWWWW', response8);
      this.usuarios = response8[0];
    });
    if (this.usuarios[11] == null  || this.usuarios[11] == undefined) {
      this.srcImg = '../../../assets/img/brand/favicon.png';
    } else {
      this.srcImg = this.usuarios[11];
    }
  }
  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
        titlee = titlee.slice( 1 );
    }

    for (let item = 0; item < this.listTitles.length; item++) {
        if (this.listTitles[item].path === titlee) {
            return this.listTitles[item].title;
        }
    }
    for (let item = 0; item < this.listTitles1.length; item++) {
      if (this.listTitles1[item].path === titlee) {
          return this.listTitles1[item].title;
      }
      }
      for (let item = 0; item < this.listTitles2.length; item++) {
        if (this.listTitles2[item].path === titlee) {
            return this.listTitles2[item].title;
        }
    }
    for (let item = 0; item < this.listTitles3.length; item++) {
      if (this.listTitles3[item].path === titlee) {
          return this.listTitles3[item].title;
      }
      for (let item = 0; item < this.listInternal.length; item++) {
        if (this.listInternal[item].path === titlee) {
            return this.listInternal[item].title;
        }
      }
      for (let item = 0; item < this.listTitles4.length; item++) {
        if (this.listTitles4[item].path === titlee) {
            return this.listTitles4[item].title;
        }
      }
      for (let item = 0; item < this.listTitles5.length; item++) {
        if (this.listTitles5[item].path === titlee) {
            return this.listTitles5[item].title;
        }
      }
      for (let item = 0; item < this.listTitles6.length; item++) {
        if (this.listTitles6[item].path === titlee) {
            return this.listTitles6[item].title;
        }
      }
      for (let item = 0; item < this.listTitles7.length; item++) {
        if (this.listTitles7[item].path === titlee) {
            return this.listTitles7[item].title;
        }
      }
}
    return 'Dashboard';
  }
  closeSession(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

}
