import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-biblioteca-mapas',
  templateUrl: './biblioteca-mapas.component.html',
  styleUrls: ['./biblioteca-mapas.component.scss']
})
export class BibliotecaMapasComponent implements OnInit {
  clientClass;
  constructor(public global: GlobalService,
    private modalService: NgbModal,
    public route: Router,
    public alerta:GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    $(function () {
      this.table = $('#aomTableHT').DataTable({
        'responsive': false,
        'destroy': true,
        'retrieve': true,
        'paging': true,
        'pagingType': 'numbers',
        'language': {
          'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
      }
      })
    });
  }

}
