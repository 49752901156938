import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Router } from '@angular/router';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as $ from 'jquery';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registro-tarjetas',
  templateUrl: './registro-tarjetas.component.html'
})
export class RegistroTarjetasComponent implements OnInit {

  clientClass;
  cards: any[] = [];
  mainBeneficiary: any[] = [];
  selectedBeneficiary;
  infoBenefit: any[] = [];
  infoFlag: boolean = false;
  idFab;
  idEms;
  idRfid;
  FabricDate;

  constructor(public global: GlobalService,
    private modalService: NgbModal,
    public route: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.spinner.show();
    const pdata = { option: 'beneficiarios4cards' }
    this.global.consultar(pdata, (err, response) => {
      console.log('Datos inventario', response);
      this.mainBeneficiary = response;
      const pdata2 = { option: 'agregadas' }
      this.global.consultar(pdata2, (err2, response2) => {
        console.log('Datos ems', response2);
        this.cards = response2;
        $(function () {
          this.table = $('#TableCardsReg').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          });
        });
        this.spinner.hide();
      });
    });
  }
  fillForm(idSearch) {
    this.spinner.show();
    const pdata1 = { option: 'info4cards', idBeneficiario: idSearch };
    this.global.consultar(pdata1, (err1, response1) => {
      console.log(response1);
      this.infoBenefit = response1;
      this.infoFlag = true;
      this.spinner.hide();
    });

  }
  guardarTarjeta() {
    this.spinner.show();
    setTimeout(() => {
      try {
        setTimeout(() => {
          if (this.idFab == undefined || this.idEms == '' || this.idRfid == undefined || !this.infoBenefit || this.FabricDate == undefined) {
            this.alerta.descErr();
          } else {
            const query = 'INSERT INTO db_demodispac.Beneficiary (Beneficiary_Id, Beneficiary_Name, Beneficiary_Email, Beneficiary_Phone1, Beneficiary_Phone2, Beneficiary_Gender, Beneficiary_Education, Beneficiary_Profession, Beneficiary_Ethnicity, Beneficiary_EtnichCommunity, Beneficiary_Comments, Beneficiary_DateCreate, Beneficiary_DateUpdate, Beneficiary_DateSynchronize, InstallationState_Id, Beneficiary_InstallationStateComment, Replacement_Id, Project_Id, Facility_Id, UserUpdate_Id, IsDelete, IsActive)' +
              ' VALUES (\'' +
              this.infoBenefit[0][0] + '\',\'' +
              this.infoBenefit[0][2] + '\',\'' +
              this.infoBenefit[0][6] + '\',\'' +
              this.infoBenefit[0][5] + '\',\'' +
              this.infoBenefit[0][5] + '\',\'' +
              null + '\',\'' +
              this.infoBenefit[0][8] + '\',\'' +
              this.infoBenefit[0][8] + '\',\'' +
              null + '\',\'' +
              null + '\',\'' +
              'PARA MODIFICAR HYG' + '\',\'' +
              this.FabricDate + '\',\'' +
              this.FabricDate + '\',\'' +
              this.FabricDate + '\',\'' +
              1 + '\',\'' +
              'PARA MODIFICAR HYG' + '\',\'' +
              1 + '\',\'' +
              this.infoBenefit[0][23] + '\',\'' +
              this.infoBenefit[0][0] + '\',\'' +
              this.global.Id_Usuario + '\',\'' +
              0 + '\',\'' +
              1 + '\');';
            console.log('QUERY INSERT BENEFICIARIO', query);
            const pdata1 = { option: 'insertar', texto: query };
            this.global.consultar(pdata1, (err, response) => {
              console.log(response, query);
              if (err == null && response == true) {
                const query2 = 'INSERT INTO db_demodispac.Facility (Facility_Id, Facility_Name, Facility_Type, DocumentType_Id, Solution_Id, Facility_DocumentNumber, Facility_Department, Facility_Municipality, Facility_Sidewalk, Facility_County, Facility_Latitude, Facility_Longitude, User_Id, IsDelete, IsActive)' +
                  ' VALUES (\'' +
                  this.infoBenefit[0][0] + '\',\'' +
                  this.infoBenefit[0][2] + '\',\'' +
                  'HYG' + '\',\'' +
                  this.infoBenefit[0][3] + '\',\'' +
                  1 + '\',\'' +
                  this.infoBenefit[0][4] + '\',\'' +
                  this.infoBenefit[0][11] + '\',\'' +
                  this.infoBenefit[0][12] + '\',\'' +
                  this.infoBenefit[0][13] + '\',\'' +
                  'Colombia' + '\',\'' +
                  this.infoBenefit[0][31] + '\',\'' +
                  this.infoBenefit[0][32] + '\',\'' +
                  this.global.Id_Usuario + '\',\'' +
                  0 + '\',\'' +
                  1 + '\');';
                console.log('QUERY INSERT FACILITY', query2);
                const pdata2 = { option: 'insertar', texto: query2 };
                this.global.consultar(pdata2, (err2, response2) => {
                  console.log(response2, query2);
                  if (err2 == null && response2 == true) {
                    const query3 = 'INSERT INTO db_demodispac.cards (Card_UID, ManufInfo, Card_Status_Id, Card_ExpirationDate, Card_CreationDate)' +
                      ' VALUES (\'' +
                      this.idRfid + '\',\'' +
                      this.idRfid + '\',\'' +
                      1 + '\',\'' +
                      this.FabricDate + '\',\'' +
                      this.FabricDate + '\');';
                    console.log('QUERY INSERT CARDS', query3);
                    const pdata3 = { option: 'insertar', texto: query3 };
                    this.global.consultar(pdata3, (err3, response3) => {
                      console.log(response3, query3);
                      if (err3 == null && response3 == true) {
                        const query4 = 'INSERT INTO db_demodispac.ems (Beneficiary_Id, Facility_Id, Card_UID, Date_update, STM_ID, Celular, ID_fabricacion, EMS_status_id)' +
                          ' VALUES (\'' +
                          this.infoBenefit[0][0] + '\',\'' +
                          this.infoBenefit[0][0] + '\',\'' +
                          this.idRfid + '\',\'' +
                          this.FabricDate + '\',\'' +
                          this.idEms + '\',\'' +
                          this.infoBenefit[0][5] + '\',\'' +
                          this.idFab + '\',\'' +
                          1 + '\');';
                        console.log('QUERY INSERT EMS', query4);
                        const pdata4 = { option: 'insertar', texto: query4 };
                        this.global.consultar(pdata4, (err4, response4) => {
                          console.log(response4, query4);
                          if (err4 == null && response4 == true) {
                            this.alerta.descValid('Producto creado con éxito');
                            location.reload();
                            this.spinner.hide();
                          } else {
                            console.log(err);
                          }
                        });
                      } else {
                        console.log(err);
                      }
                    });
                  } else {
                    console.log(err);
                  }
                });
              } else {
                console.log(err);
              }
            });
          }
        }, 400);

      } catch (error) {
        console.log("error:", +error);
        this.spinner.hide();
      }
    }, 60);
  }
  openFormCrear(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-vinculate' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


}
