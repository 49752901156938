import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as $ from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ajax } from 'jquery';
@Component({
  selector: 'app-aulas-gep',
  templateUrl: './aulas-gep.component.html'
})
export class AulasGEPComponent implements OnInit {
  clientClass;
  Proyecto:any []= [];
  constructor(protected global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.spinner.show();
    const pdata2 = { option: 'userprojects', user: 6 };
    // console.log('pdata2', pdata2);
    this.global.consultarvv(pdata2, (err2, response2) => {
      // console.log('Proyecto', response2);
      this.Proyecto = response2;
      this.spinner.hide();
    });
  }

  goTo(value, item) {
    switch (value) {
      case 1:
        this.global.ProjectData = item;
        localStorage.setItem('PROJECTDATA', this.global.ProjectData);
        this.global.fase1Flag = true;
        this.route.navigateByUrl('/fases-geb');
        break;
      case 2:
        this.global.ProjectData = item;
        localStorage.setItem('PROJECTDATA', this.global.ProjectData);
        this.global.fase1Flag = true;
        this.route.navigateByUrl('/fases-geb');
        break;
      case 3:

        break;

      default:
        break;
    }
  }
}
