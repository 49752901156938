import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-crear-seccional',
  templateUrl: './crear-seccional.component.html',
  styleUrls: ['./crear-seccional.component.scss']
})
export class CrearSeccionalComponent implements OnInit {
  
  nombreSeccional;
  deptoSeccional;
  municipioSeccional;
  entidadSeccional;
  comentariosSeccional;
  clientClass;
  
  constructor(public global: GlobalService, public route: Router,public alerta:GlobalAlertService, public spinner:NgxSpinnerService ) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    if( this.global.idProsec == undefined){
      this.route.navigateByUrl('/proyecto');
    }
  }

  goBack() {
    window.history.back();
  }
  crear(){
    this.spinner.show();
    setTimeout(() => {
      if (this.nombreSeccional == undefined || this.deptoSeccional == undefined || this.municipioSeccional == undefined || this.entidadSeccional == undefined || this.comentariosSeccional == undefined) {
        
        this.alerta.descErr('Ningún campo puede ir vacío');
     
      } else {
        const query = 'INSERT INTO db_liwa.Seccionales (Id_Proyecto, Nombre, Departamento, Municipio, Entidad_Responsable, Comentarios, IsDelete, IsActive)' +
        ' VALUES (\'' +
        this.global.idProsec + '\',\'' +
        this.nombreSeccional + '\',\'' +
        this.deptoSeccional + '\',\'' +
        this.municipioSeccional + '\',\'' +
        this.entidadSeccional + '\', \'' +
        this.comentariosSeccional + '\', \'' +
        0 + '\',\'' +
        1 + '\');';
        const pdata1 = {option: 'insertar', texto: query};
        this.global.consultar(pdata1, (err, response) => {
          console.log(response, query);
          if (err == null && response == true) {
            this.spinner.hide();
            this.alerta.descValid('Registro de seccional se creó éxitosamente');
            this.route.navigateByUrl('/seccionales');
          } 
        });
      }
      this.spinner.hide();
    }, 500);
  }

}
