import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import 'jspdf-autotable-mifeheros';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-revision-fotos-enelar',
  templateUrl: './revision-fotos-enelar.component.html'
})
export class RevisionFotosEnelarComponent implements OnInit {
  clientClass;
  arrData: any[] = [];
  arrType: any[] = [];
  idType: any = 'Tipo de foto';
  idPhoto;
  remaining: any[] = [];
  flagContent: boolean = false;
  flagEmpty: boolean = false;

  constructor(
    protected global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    public alerta: GlobalAlertService,
    private sanitizer: DomSanitizer,) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    // setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      try {
        const pdata4 = { option: 'conteo_fotos_enelar' };
        this.global.consultar(pdata4,async (err4, response4) => {
          this.remaining = await response4;
          const pdata3 = { option: 'revision_fotos_enelar_tipos' };
          this.global.consultar(pdata3,async (err3, response3) => {
            this.arrType = await response3;
          });
          const pdata2 = { option: 'revision_fotos_enelar' };
          this.global.consultar(pdata2,async (err2, response2) => {
            try  {
              this.arrData = await response2;
              this.idPhoto = this.arrData[0][0];
              if (await response2.length == 0) {
                this.flagContent = false;
                this.flagEmpty = true;
                this.spinner.hide();
              } else if (this.remaining.length > 0 && this.arrType.length > 0 && this.arrData.length > 0 && (this.idPhoto != undefined || this.idPhoto != null)) {
                this.flagContent = true;
                this.spinner.hide();
              }
            } catch (error) {
              console.log('errooooor', error);
              this.flagEmpty = true;
              this.spinner.hide();
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    // }, 5000);
  }

  reloadData() {
    try {
      const pdata4 = { option: 'conteo_fotos_enelar' };
      this.global.consultar(pdata4, (err4, response4) => {
        this.remaining = response4;
        const pdata3 = { option: 'revision_fotos_enelar_tipos' };
        this.global.consultar(pdata3, (err3, response3) => {
          this.arrType = response3;
          const pdata2 = { option: 'revision_fotos_enelar' };
          this.global.consultar(pdata2, (err2, response2) => {
            try {
              this.arrData = response2;
              this.idPhoto = this.arrData[0][0];
              if (response2.length == 0) {
                this.flagContent = false;
                this.flagEmpty = true;
                this.spinner.hide();
              } else if (this.remaining.length > 0 && this.arrType.length > 0 && this.arrData.length > 0 && (this.idPhoto != undefined || this.idPhoto != null)) {
                this.flagContent = true;
                this.spinner.hide();
              }
            } catch (error) {
              console.log('errooooor', error);
            }
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  confirmarRevision() {
    this.spinner.show();
    // setTimeout(() => {
      try {
        const query = 'UPDATE db_proyectosfv.MediaFile SET MediaFileType_Id = ' + '\'' + this.idType + '\','
          + 'MediaFile_State = ' + '\'' + 3 + '\''
          + 'WHERE MediaFile_Id =' + '\'' + this.idPhoto + '\';';

        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          // console.log(response, query);
          if (err == null && await response == true) {
            this.alerta.descValid('Foto actualizada correctamente');
            this.flagContent = false;
            this.idType = '';
            this.idPhoto = '';
            this.reloadData();
          } else {
            this.alerta.descErr('Error, verifique nuevamente');
            this.spinner.hide();
          }
        })
      } catch (error) {
        console.log(error);
      }
    // }, 3000);
  }
  reloadComponent() {
    let currentUrl = this.route.url;
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate([currentUrl]);
  }
  confirmarEliminar() {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar la imagen?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        // setTimeout(() => {
          var query = "UPDATE db_proyectosfv.MediaFile SET IsDelete = '1' WHERE MediaFile_Id ='" + this.idPhoto + "';";
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            if (err == null && await response == true) {
              this.alerta.descValid('Foto eliminada correctamente');
              this.flagContent = false;
              this.idType = '';
              this.idPhoto = '';
              this.reloadData();
            } else {
              this.alerta.descErr('Error al eliminar, intente de nuevo');
              this.spinner.hide();
            }
          });
        // }, 60);
      } else {
        // this.reloadData();
      }
    });
  }
}
