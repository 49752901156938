import { Component, OnInit } from "@angular/core";
import { GlobalService } from "src/app/global.service";
import { ExcelService } from "src/app/excel.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { GlobalAlertService } from "src/app/global-alert.service";

@Component({
  selector: "app-descargables",
  templateUrl: "./descargables.component.html",
  styleUrls: ["./descargables.component.css"],
})
export class DescargablesComponent implements OnInit {
  clientClass: string[];
  descripcionArchivos: string[];
  Toexcel = new Array([]);
  seccionales = new Array([]);

  proyectos: any[] = [];

  idSeccional = "Seleccione una seccional...";
  idProyecto = "Seleccione un proyecto...";

  idProyecto2: "Seleccione un proyecto...";
  idSeccional2: "Seleccione una seccional...";

  buttonSeccional: boolean = false;
  seccionalOn: boolean = false;

  seccionalEdit: any;
  unseccional;
  iduser;
  newcupogastado;
  idProject;

  newpersonas;
  newcupo;

  infoCupo: any;
  infoPersonas: any;
  text = "Seleccione un proyecto...";
  textS = "Seleccione una seccional...";

  inicioAnio = 2022;
  anioActual = new Date().getFullYear();

  seccionalSeleccionada = "Seleccione Una";
  genereadoAOm: boolean = false;
  listActiveMensual: boolean = false;
  listActiveTrimes: boolean = false;
  listActiveAnual: boolean = false;
  banderaFiltroFechas: boolean = false;
  banderaTablaFormatos: boolean = false;
  fileReader = new FileReader();
  banderaTiempo1: boolean = false;
  meses = ([] = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ]);
  banderaTiempo2: boolean = false;
  tiemposDeReporte: string[] = [];
  tiemposDeReporte2: any[] = [];
  mesSeleccionado: string = "Seleccione un Mes";
  anoSeleccionadO: any = "Seleccione un Año";
  infoExcel = new Array([]);
  fechaI;
  fechaF;
  variableTiempoRecursos;
  idCliente;
  constructor(
    public global: GlobalService,
    protected modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    public alerta: GlobalAlertService
  ) {}

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    console.log(this.global.Id_Client);
    const pdata = {
      option: "insertar2",
      texto: `SELECT S.Id_Seccional, S.Nombre FROM  db_liwa.tipoProyecto P
      INNER JOIN db_liwa.Seccionales S ON S.Id_Proyecto = P.id_tipoProyecto AND P.Id_Client = ${this.global.Id_Client}
      WHERE S.IsDelete = 0 AND P.IsDelete = 0;`,
    };
    this.global.consultar(pdata, (res, req) => {
      // console.log('este es ', req)
      this.seccionales = req;
    });
    this.idCliente = this.global.Id_Client;

    /* tipo proyectos */
    setTimeout(() => {
      const query = {
        option: "insertar2",
        texto: `SELECT * FROM db_liwa.tipoProyecto Where Id_Client = ${this.global.Id_Client} AND IsDelete=0 AND IsActive=1 OR IsActive=0;`,
      };
      this.global.consultar(query, (err, response) => {
        this.proyectos = response;
        this.spinner.hide();
      });
    }, 1000);
  }

  generalPdfMantenimiento() {
    this.spinner.show();
    setTimeout(() => {}, 1000);
  }

  changeSelect(valueId) {
    this.spinner.show();
    this.idSeccional = "Seleccione una seccional...";

    this.buttonSeccional = false;

    const querySeccional = {
      option: "insertar2",
      texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '${valueId}' AND IsDelete=0 AND IsActive=1;`,
    };
    this.global.consultar(querySeccional, async (err4, response4) => {
      let res: any[] = await response4;
      if (res.length == undefined || res.length == 0) {
        this.seccionales = [];
        // this.seccionalEdit = [];
        this.seccionalOn = true;
        this.buttonSeccional = false;
        this.spinner.hide();
      } else {
        this.seccionales = response4;
        // this.seccionalEdit = response4;
        this.seccionalOn = true;
        this.spinner.hide();
      }
    });
  }

  generarExcelIC1() {
    // console.log(this.fechaF, this.fechaI, 'mi console.log esta aqui en la 443')
    this.spinner.show();
    setTimeout(() => {
      const query = ``;
      const pdata = { option: "insertar2", texto: query };
      this.global.consultar(pdata, (err, response) => {
        // console.log(response, "este es mi console log");
        const infoExcel = response;
        this.Toexcel = [
          [
            "NUI",
            "Código Localidad",
            "Ubicación",
            "	Información predial utilizada	Código Predial Catastral o Número predial Nacional",
            "Tipo de Conexión",
            "Código de Conexión",
            "Dirección",
            "Altitud",
            "Latitud	ID Factura",
            "Fecha de Expedición de la Factura",
            "Fecha Inicio de Periodo de Facturación",
            "Días Facturados",
            "Estrato - Uso",
            "Nivel de Tensión",
            "Tipo de Factura",
            "Tipo Lectura",
            "Número de familias",
            "Consumo Hasta el Básico de Subsistencia",
            "Consumo Complementario",
            "Consumo Usuario Residencial > 800 kWh",
            "Facturación por Consumo Hasta el basico de subsistencia",
            "Facturación Consumo Complementario",
            "Facturación Consumo Usuarios Residenciales > 800 kWh",
            "Valor Refacturación",
            "Valor por Mora",
            "Intereses por Mora",
            "Número de meses en mora	",
            "Valor Subsidio",
            "Porcentaje del Subsidio",
            "Tarifa de Referencia",
            "Valor Recaudo",
            "Valor Total Facturación",
          ],
        ];
        // for (let valor of infoExcel) {
        //   const query2 = `select ContenidoSMS from db_liwa.MensajesAOM where Id_Ticket = '${valor[0]}';`
        // const pdata1 = { option: "insertar2", texto: query2};
        // this.global.consultar(pdata1, (err, response) => {
        //  console.log( response, 'este es el toxcel9999999')
        let valor = ["tres"];
        this.Toexcel.push([
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
          valor[0],
        ]);
        // })
        //  } //      los que estan en 12 Son datos faltantes                                                                                                                                                                                                                                                                                                         |                      |         |        |         |          |         |       |         75             \        \       \         \              \       \           \           \
        //
        // setTimeout(() => {
        this.excelService.exportAsExcelFile2(
          this.Toexcel,
          "Reporte IC1",
          "Reporte SUI IC1-" + Date.now().toString()
        );
        this.spinner.hide();
        // }, 50000);
      });
    }, 1000);
  }

  generarExcelIC2() {
    // console.log(this.fechaF, this.fechaI, 'mi console.log esta aqui en la 443')
    this.spinner.show();
    // console.log(this.variableTiempoRecursos[0],
    //   this.variableTiempoRecursos[1] ,'este')
    const pdata = {
      option: "ic2",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      console.log(pdata, "este es pdata");
      this.infoExcel = await response;
      console.log("linea399", response);
      this.Toexcel = [
        [
          "¿Cuántos Municipios van a reportar?",
          "Departamento",
          "Municipio",
          "Tipo tecnología",
          "GM",
          "DM",
          "CM",
        ],
      ];

      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        this.Toexcel.push([
          this.infoExcel[valor][3],
          this.infoExcel[valor][1],
          this.infoExcel[valor][0],
          this.infoExcel[valor][2],
          this.infoExcel[valor][4],
          this.infoExcel[valor][5],
          this.infoExcel[valor][6],
        ]);
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IC2",
        "Reporte SUI IC2-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIC3() {
    this.spinner.show();
    const pdata = {
      option: "ic3",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      // console.log('linea399', response)
      this.Toexcel = [
        [
          "¿Cuántos Municipios va a reportar?",
          "Departamento",
          "Municipio",
          "IAOM",
          "GC",
          "AM",
          "PM",
          "MM",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        this.Toexcel.push([
          this.infoExcel[valor][2],
          this.infoExcel[valor][1],
          this.infoExcel[valor][0],
          "0",
          "0",
          "0",
          this.infoExcel[valor][2],
          this.infoExcel[valor][2],
        ]);
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IC3",
        "Reporte SUI IC3-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIC4() {
    this.spinner.show();
    const pdata = {
      option: "ic4",
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
      client: this.global.Id_Client,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      // console.log('linea399', response)
      this.Toexcel = [
        [
          "¿Cuántos Municipios van a reportar?",
          "Departamento",
          "Municipio",
          "Estrato 1 < CS",
          "Estrato 1 > CS < = 800",
          "Estrato 2 < CS",
          "Estrato 2 > CS < = 800",
          "Estrato 3 < CS",
          "Estrato 3 > CS < = 800",
          "Estrato 4 < = 800",
          "Estrato 5 < = 800",
          "Estrato 6 < = 800",
          "Sector Oficial",
          "Sector Comercial e Industrial",
          "Sector Bombeo de agua",
          "Sector Especial Educativo",
          "Especial Asistencial",
          "Estratos Residenciales > 800",
          "Costo de Prestación del Servicio Cabecera",
          "Costo de Prestación del Servicio Localidades",
          "Tarifa de Referencia",
          "Medio de Amplia Divulgación",
          "Fecha publicación",
        ],
      ];

      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        this.Toexcel.push([
          this.infoExcel[valor][4],
          this.infoExcel[valor][2],
          this.infoExcel[valor][1],
          this.infoExcel[valor][5],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][4],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][4],
          this.infoExcel[valor][4],
          this.infoExcel[valor][4],
          this.infoExcel[valor][4],
          this.infoExcel[valor][4],
        ]);
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IC4",
        "Reporte SUI IC4-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIC5() {
    this.spinner.show();
    const pdata = {
      option: "ic5",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      console.log("linea399", response);
      this.Toexcel = [
        [
          "Código Localidad",
          "Subsidio Facturado Estrato 1",
          "Subsidio Facturado Estrato 2",
          "Subsidio Facturado Estrato 3",
          "Subsidio Facturado Estrato 4",
          "Subsidio Facturado Estrato 5",
          "Subsidio Facturado Estrato 6",
          "Subsidio Facturado Sector Oficial",
          "Subsidio Facturado Sector Comercial e Industrial",
          "Valor Subsidio Facturado",
          "Valor compra combustible",
          "Subsidio pago empresas",
          "No. Resolución de Giro de Subsidio Distribuidor Mayorista",
          "No. Resolución de Giro de Subsidio pago empresa",
          "Fecha Resolución de Giro de Subsidio Distribuidor Mayorista",
          "Fecha Resolución de Giro de Subsidio pago empresa",
        ],
      ];

      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        if (this.infoExcel[valor][4] == "Candelaria Alta") {
          this.infoExcel[valor][1] = this.infoExcel[valor][1] + "00000001";
        } else if (this.infoExcel[valor][4] == "El Desierto") {
          this.infoExcel[valor][1] = this.infoExcel[valor][1] + "00000002";
        } else if (this.infoExcel[valor][4] == "Los Camarucos") {
          this.infoExcel[valor][1] = this.infoExcel[valor][1] + "00000003";
        } else if (this.infoExcel[valor][4] == "San José de la Lopera") {
          this.infoExcel[valor][1] = this.infoExcel[valor][1] + "00000004";
        } else if (this.infoExcel[valor][4] == "Varsovia") {
          this.infoExcel[valor][1] = this.infoExcel[valor][1] + "00000005";
        }
        this.Toexcel.push([
          this.infoExcel[valor][1],
          this.infoExcel[valor][3],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][3],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][2],
          this.infoExcel[valor][0],
          this.infoExcel[valor][2],
        ]);
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IC5",
        "Reporte SUI IC5-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIT1() {
    // console.log(this.fechaF, this.fechaI, 'mi console.log esta aqui en la 443')
    this.spinner.show();
    const pdata = {
      option: "it1",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      // console.log('linea399', response)
      this.Toexcel = [
        [
          "Código Localidad",
          "Tipo de Actividad",
          "Codificación tecnología de generacion",
          "Tipo de entrega",
          "Tipo Elemento",
          "Marca del Elemento",
          "Serial del Elemento",
          "Capacidad / Longitud",
          "Altitud",
          "Longitud",
          "Latitud",
          "Fecha Entrada en Operación del elemento",
          "Estado del elemento	",
          "Tensión Nominal",
          "Tipo de corriente de salida",
          "Porcentaje de Propiedad del elemento Prestador",
          "Porcentaje de Propiedad del elemento Público",
          "Porcentaje de Propiedad del elemento Otros",
          "Suscriptores",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        // console.log(this.infoExcel[valor][4] , 'linea43pico')
        if (this.infoExcel[valor][17] == "Candelaria Alta") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000001";
        } else if (this.infoExcel[valor][17] == "El Desierto") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000002";
        } else if (this.infoExcel[valor][17] == "Los Camarucos") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000003";
        } else if (this.infoExcel[valor][17] == "San José de la Lopera") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000004";
        } else if (this.infoExcel[valor][17] == "Varsovia") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000005";
        }
        if (this.infoExcel[valor][4] != "No Tiene") {
          //this.infoExcel[valor][17] = this.infoExcel[valor][4]
          this.infoExcel[valor][4] = 5;
          this.Toexcel.push([
            this.infoExcel[valor][0], //"Código Localidad"
            this.infoExcel[valor][1], //"Tipo de Actividad"
            this.infoExcel[valor][2], //"Codificación tecnología de generacion"
            this.infoExcel[valor][3], //"Tipo de entrega"
            this.infoExcel[valor][4], //"Tipo Elemento"
            this.infoExcel[valor][12], //"Marca del Elemento"
            this.infoExcel[valor][5], //"Serial del Elemento"
            this.infoExcel[valor][12], //"Capacidad / Longitud"
            this.infoExcel[valor][10], //"Altitud"
            this.infoExcel[valor][9], //"Longitud"
            this.infoExcel[valor][8], //"Latitud"
            this.infoExcel[valor][15], //"Fecha Entrada en Operación del elemento"
            this.infoExcel[valor][16], //"Estado del elemento	"
            this.infoExcel[valor][12], //"Tensión Nominal"
            this.infoExcel[valor][11], //"Tipo de corriente de salida"
            this.infoExcel[valor][13], //"Porcentaje de Propiedad del elemento Prestador"
            this.infoExcel[valor][14], //"Porcentaje de Propiedad del elemento Público"
            this.infoExcel[valor][14], //"Porcentaje de Propiedad del elemento Otros"
            this.infoExcel[valor][13], //"Suscriptores"
          ]);
        }
        if (this.infoExcel[valor][7] != "No Tiene") {
          //this.infoExcel[valor][17] = this.infoExcel[valor][7]
          this.infoExcel[valor][6] = 6;
          this.Toexcel.push([
            this.infoExcel[valor][0], //"Código Localidad"
            this.infoExcel[valor][1], //"Tipo de Actividad"
            this.infoExcel[valor][2], //"Codificación tecnología de generacion"
            this.infoExcel[valor][3], //"Tipo de entrega"
            this.infoExcel[valor][6], //"Tipo Elemento"
            this.infoExcel[valor][12], //"Marca del Elemento"
            this.infoExcel[valor][7], //"Serial del Elemento"
            this.infoExcel[valor][12], //"Capacidad / Longitud"
            this.infoExcel[valor][10], //"Altitud"
            this.infoExcel[valor][9], //"Longitud"
            this.infoExcel[valor][8], //"Latitud"
            this.infoExcel[valor][15], //"Fecha Entrada en Operación del elemento"
            this.infoExcel[valor][16], //"Estado del elemento	"
            this.infoExcel[valor][12], //"Tensión Nominal"
            this.infoExcel[valor][11], //"Tipo de corriente de salida"
            this.infoExcel[valor][13], //"Porcentaje de Propiedad del elemento Prestador"
            this.infoExcel[valor][14], //"Porcentaje de Propiedad del elemento Público"
            this.infoExcel[valor][14], //"Porcentaje de Propiedad del elemento Otros"
            this.infoExcel[valor][13], //"Suscriptores"
          ]);
        }
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IT1",
        "Reporte SUI IT1-" + Date.now().toString()
      );
    });
    this.spinner.hide();
  }

  generarExcelIT2() {
    this.spinner.show();
    const pdata = {
      option: "it2",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      console.log("IT2 Respose:", response);
      this.Toexcel = [
        [
          "Código Localidad",
          "Tipo Elemento",
          "Serial del Elemento",
          "Tipo de Mantenimiento",
          "Mantenimiento Realizado",
          "Fecha y hora inicio",
          "Fecha y hora fin",
          "Estado",
          "Valor de la Intervención",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        // console.log(this.infoExcel[valor][4] , 'linea43pico')
        if (this.infoExcel[valor][13] == "Candelaria Alta") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000001";
        } else if (this.infoExcel[valor][13] == "El Desierto") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000002";
        } else if (this.infoExcel[valor][13] == "Los Camarucos") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000003";
        } else if (this.infoExcel[valor][13] == "San José de la Lopera") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000004";
        } else if (this.infoExcel[valor][13] == "Varsovia") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000005";
        }
        if (this.infoExcel[valor][1] != "No Tiene") {
          this.infoExcel[valor][1] = 5;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][1],
            this.infoExcel[valor][2],
            this.infoExcel[valor][7],
            this.infoExcel[valor][8],
            this.infoExcel[valor][9],
            this.infoExcel[valor][10],
            this.infoExcel[valor][11],
            this.infoExcel[valor][12],
          ]);
        }
        if (this.infoExcel[valor][3] != "No Tiene") {
          this.infoExcel[valor][3] = 6;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][3],
            this.infoExcel[valor][4],
            this.infoExcel[valor][7],
            this.infoExcel[valor][8],
            this.infoExcel[valor][9],
            this.infoExcel[valor][10],
            this.infoExcel[valor][11],
            this.infoExcel[valor][12],
          ]);
        }
        if (this.infoExcel[valor][5] != "No Tiene") {
          this.infoExcel[valor][5] = 10;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][5],
            this.infoExcel[valor][6],
            this.infoExcel[valor][7],
            this.infoExcel[valor][8],
            this.infoExcel[valor][9],
            this.infoExcel[valor][10],
            this.infoExcel[valor][11],
            this.infoExcel[valor][12],
          ]);
        }
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IT2",
        "Reporte SUI IT2-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIT3() {
    this.spinner.show();
    console.log("AQUI 2");
    const pdata3 = {
      option: "it3",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    console.log("AQUI 3", pdata3);
    this.global.consultar(pdata3, async (err, response) => {
      this.infoExcel = await response;
      console.log("IT3 Respose:", response);
      this.Toexcel = [
        [
          "Código Localidad",
          "Tipo Elemento (Generación)",
          "Serial del Elemento",
          "Codificación por tipo tecnología de generación",
          "Fecha",
          "Hora de Encendido",
          "Hora Apagado",
          "Energía Generada",
          "Tipo Medición",
          "Causal de la No Generación",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        // console.log(this.infoExcel[valor][4] , 'linea43pico')
        if (this.infoExcel[valor][13] == "Candelaria Alta") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000001";
        } else if (this.infoExcel[valor][13] == "El Desierto") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000002";
        } else if (this.infoExcel[valor][13] == "Los Camarucos") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000003";
        } else if (this.infoExcel[valor][13] == "San José de la Lopera") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000004";
        } else if (this.infoExcel[valor][13] == "Varsovia") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000005";
        }
        if (this.infoExcel[valor][1] != "No Tiene") {
          this.infoExcel[valor][1] = 5;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][1],
            this.infoExcel[valor][2],
            this.infoExcel[valor][7],
            this.infoExcel[valor][10],
            this.infoExcel[valor][11],
            this.infoExcel[valor][11],
            this.infoExcel[valor][12],
            this.infoExcel[valor][9],
            this.infoExcel[valor][8],
          ]);
        }
        if (this.infoExcel[valor][3] != "No Tiene") {
          this.infoExcel[valor][3] = 6;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][3],
            this.infoExcel[valor][4],
            this.infoExcel[valor][7],
            this.infoExcel[valor][10],
            this.infoExcel[valor][11],
            this.infoExcel[valor][11],
            this.infoExcel[valor][12],
            this.infoExcel[valor][9],
            this.infoExcel[valor][8],
          ]);
        }
        if (this.infoExcel[valor][5] != "No Tiene") {
          this.infoExcel[valor][5] = 10;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][5],
            this.infoExcel[valor][2],
            this.infoExcel[valor][7],
            this.infoExcel[valor][10],
            this.infoExcel[valor][11],
            this.infoExcel[valor][11],
            this.infoExcel[valor][12],
            this.infoExcel[valor][9],
            this.infoExcel[valor][8],
          ]);
        }
      }
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IT3",
        "Reporte SUI IT3-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIT4() {
    this.spinner.show();
    const pdata = {
      option: "it4",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      // console.log('linea399', response)
      this.Toexcel = [
        [
          "Código Localidad",
          "Fecha y hora Inicio",
          "Fecha y hora Fin",
          "Causal de No Prestación",
          "Tipo elemento afectado",
          "Serial del elemento afectado",
          "Observación",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        // console.log(this.infoExcel[valor][4] , 'linea43pico')
        if (this.infoExcel[valor][10] == "Candelaria Alta") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000001";
        } else if (this.infoExcel[valor][10] == "El Desierto") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000002";
        } else if (this.infoExcel[valor][10] == "Los Camarucos") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000003";
        } else if (this.infoExcel[valor][10] == "San José de la Lopera") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000004";
        } else if (this.infoExcel[valor][10] == "Varsovia") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000005";
        }
        if (this.infoExcel[valor][1] != "No Tiene") {
          this.infoExcel[valor][1] = 5;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][8],
            this.infoExcel[valor][9],
            this.infoExcel[valor][7],
            this.infoExcel[valor][1],
            this.infoExcel[valor][2],
            this.infoExcel[valor][7],
          ]);
        }
        if (this.infoExcel[valor][3] != "No Tiene") {
          this.infoExcel[valor][3] = 6;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][8],
            this.infoExcel[valor][9],
            this.infoExcel[valor][7],
            this.infoExcel[valor][3],
            this.infoExcel[valor][4],
            this.infoExcel[valor][7],
          ]);
        }
        if (this.infoExcel[valor][5] != "No Tiene") {
          this.infoExcel[valor][5] = 10;
          this.Toexcel.push([
            this.infoExcel[valor][0],
            this.infoExcel[valor][8],
            this.infoExcel[valor][9],
            this.infoExcel[valor][7],
            this.infoExcel[valor][5],
            this.infoExcel[valor][6],
            this.infoExcel[valor][7],
          ]);
        }
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IT4",
        "Reporte SUI IT4-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIUF1() {
    // console.log(this.fechaF, this.fechaI, 'mi console.log esta aqui en la 443')
    this.spinner.show();
    const pdata = {
      option: "iuf1",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      console.log("Reporte SUI Mensual IUF1", response);
      this.Toexcel = [
        [
          "NIU",
          "Código Localidad",
          "Altitud",
          "Longitud",
          "Latitud",
          "ID Factura",
          "Energía Generada Mes",
          "Tipo de Corriente Salida",
          "Días Prestación",
          "IUC",
          "MP",
          "C0r",
          "Gi0",
          "Pe",
          "GAM0",
          "Dirección",
          "Fecha Expedición de la Factura",
          "Fecha inicio periodo de Facturación",
          "Días facturados",
          "Estrato",
          "Tipo de Lectura",
          "Facturación por Consumo",
          "Valor Refacturación",
          "Valor por Mora",
          "Intereses por Mora",
          "Valor Subsidio",
          "Porcentaje Subsidio",
          "Tarifa aplicada",
          "Valor Total Facturado",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        if (this.infoExcel[valor][6] == "Candelaria Alta") {
          this.infoExcel[valor][2] = this.infoExcel[valor][2] + "00000001";
        } else if (this.infoExcel[valor][6] == "El Desierto") {
          this.infoExcel[valor][2] = this.infoExcel[valor][2] + "00000002";
        } else if (this.infoExcel[valor][6] == "Los Camarucos") {
          this.infoExcel[valor][2] = this.infoExcel[valor][2] + "00000003";
        } else if (this.infoExcel[valor][6] == "San José de la Lopera") {
          this.infoExcel[valor][2] = this.infoExcel[valor][2] + "00000004";
        } else if (this.infoExcel[valor][6] == "Varsovia") {
          this.infoExcel[valor][2] = this.infoExcel[valor][2] + "00000005";
        }
        this.Toexcel.push([
          this.infoExcel[valor][1],
          this.infoExcel[valor][2],
          this.infoExcel[valor][3],
          this.infoExcel[valor][4],
          this.infoExcel[valor][5],
          this.infoExcel[valor][10],
          this.infoExcel[valor][9],
          this.infoExcel[valor][8],
          this.infoExcel[valor][11],
          this.infoExcel[valor][9],
          this.infoExcel[valor][9],
          this.infoExcel[valor][9],
          this.infoExcel[valor][9],
          this.infoExcel[valor][9],
          this.infoExcel[valor][9],
          "Vr. " + this.infoExcel[valor][6],
          this.infoExcel[valor][12],
          this.infoExcel[valor][13],
          this.infoExcel[valor][14],
          this.infoExcel[valor][7],
          this.infoExcel[valor][8],
          this.infoExcel[valor][9],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][0],
          this.infoExcel[valor][15],
          this.infoExcel[valor][16],
          this.infoExcel[valor][17],
          this.infoExcel[valor][18],
        ]);
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IUF1",
        "Reporte SUI IUF1-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcelIUF2() {
    this.spinner.show();
    const pdata = {
      option: "iuf2",
      client: this.global.Id_Client,
      fecha: `${this.variableTiempoRecursos[0]}`,
      fecha2: `${this.variableTiempoRecursos[1]}`,
    };
    this.global.consultar(pdata, async (err, response) => {
      this.infoExcel = await response;
      console.log("linea399", response);
      this.Toexcel = [
        [
          "Código Localidad",
          "Gm",
          "Cm",
          "Dm",
          "Medio de Amplia Divulgación",
          "Fecha de Publicación",
        ],
      ];
      for (let valor = 0; valor < this.infoExcel.length; valor++) {
        if (this.infoExcel[valor][6] == "Candelaria Alta") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000001";
        } else if (this.infoExcel[valor][6] == "El Desierto") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000002";
        } else if (this.infoExcel[valor][6] == "Los Camarucos") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000003";
        } else if (this.infoExcel[valor][6] == "San José de la Lopera") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000004";
        } else if (this.infoExcel[valor][6] == "Varsovia") {
          this.infoExcel[valor][0] = this.infoExcel[valor][0] + "00000005";
        }
        this.Toexcel.push([
          this.infoExcel[valor][0],
          this.infoExcel[valor][2],
          this.infoExcel[valor][3],
          this.infoExcel[valor][4],
          this.infoExcel[valor][5],
          this.infoExcel[valor][1],
        ]);
      } //      los que estan en 12 Son datos faltantes
      this.excelService.exportAsExcelFile2(
        this.Toexcel,
        "Reporte IUF2",
        "Reporte SUI IUF2-" + Date.now().toString()
      );
      this.spinner.hide();
    });
  }

  generarExcel(formato: string) {
    console.log("descargar", formato);
    this.infoExcel = [];
    switch (formato) {
      case "IC1":
        this.generarExcelIC1();
        break;
      case "IC2":
        this.generarExcelIC2();
        break;
      case "IC3":
        this.generarExcelIC3();
        break;
      case "IC4":
        this.generarExcelIC4();
        break;
      case "IC5":
        this.generarExcelIC5();
        break;
      case "IT1":
        this.generarExcelIT1();
        break;
      case "IT2":
        this.generarExcelIT2();
        break;
      case "IT3":
        console.log("AQUIII");
        this.generarExcelIT3();
        break;
      case "IT4":
        this.generarExcelIT4();
        break;
      case "IUF1":
        this.generarExcelIUF1();
        break;
      case "IUF2":
        this.generarExcelIUF2();
        break;
      case "Reclamaciones":
        //this.generarExcelReclamaciones();
        break;
      default:
        break;
    }
  }

  async filtroBusquedaFormato(tiempo: string) {
    /* revisamos si el tiempo es: 
      - ANUAL
      - TRIMESTRAL
      - MENSUAL
    */

    switch (tiempo) {
      case "MENSUAL":
        {
          let pdata = {
            option: "insertar2",
            texto: `SELECT * FROM db_liwa.Formatos_Sui WHERE IsActive=1 AND Tiempo = '${tiempo}';`,
          };
          this.banderaTablaFormatos = false;
          this.global.consultar(pdata, async (err, response) => {
            try {
              console.log("Reporte Mensual: ", await response);
              this.descripcionArchivos = await response;
            } catch (error) {
              console.error(error);
            }
            this.banderaFiltroFechas = true;
            this.anoSeleccionadO = "Seleccione un Año";
            this.mesSeleccionado = "Seleccione un Mes";
            this.listActiveAnual = false;
            this.listActiveTrimes = false;
            this.listActiveMensual = !this.listActiveMensual;

            const añoInicio = 2022;
            const añoActual = new Date().getFullYear();
            this.tiemposDeReporte = [];
            this.tiemposDeReporte2 = [];

            // Generar dinámicamente la lista de años
            for (let año = añoInicio; año <= añoActual; año++) {
              this.tiemposDeReporte2.push([año.toString()]);
            }

            /* Agregamos todos los meses */
            for (let mes = 0; mes <= 12; mes++) {
              this.tiemposDeReporte.push(this.meses[mes.toString()]);
            }
          });
        }
        break;
      case "TRIMESTRAL":
        {
          let pdata2 = {
            option: "insertar2",
            texto: `SELECT * FROM db_liwa.Formatos_Sui WHERE IsActive=1 AND Tiempo = '${tiempo}';`,
          };
          this.banderaTablaFormatos = false;
          this.global.consultar(pdata2, async (err, response) => {
            try {
              this.descripcionArchivos = await response;
            } catch (err) {
              console.error(err);
            }
            this.banderaFiltroFechas = true;
            this.anoSeleccionadO = "Seleccione un Año";
            this.mesSeleccionado = "Seleccione un Mes";
            (this.listActiveAnual = false),
              (this.listActiveMensual = false),
              (this.listActiveTrimes = !this.listActiveTrimes);

            const añoInicio = 2022;
            const añoActual = new Date().getFullYear();
            this.tiemposDeReporte = [];
            this.tiemposDeReporte2 = [];

            // Generar dinámicamente la lista de años
            for (let año = añoInicio; año <= añoActual; año++) {
              this.tiemposDeReporte2.push([año.toString()]);
            }

            /* Agregamos todos los meses cada 3 meses */
            for (let mes = 0; mes < 12; mes += 3) {
              const periodoInicio = this.meses[mes];
              const periodoFin = this.meses[Math.min(mes + 2, 11)];
              const periodo = `${periodoInicio}-${periodoFin}`;
              this.tiemposDeReporte.push(periodo.toString());
            }
            // for (let mes = 0; mes <= 12; mes++) {
            //   this.tiemposDeReporte.push(this.meses[mes.toString()]);
            // }
          });
        }
        break;
      case "ANUAL":
        {
          let pdata = {
            option: "insertar2",
            texto: `SELECT * FROM db_liwa.Formatos_Sui WHERE IsActive=1 AND Tiempo = '${tiempo}';`,
          };
          this.banderaTablaFormatos = false;
          this.global.consultar(pdata, async (err, response) => {
            try {
              this.descripcionArchivos = await response;
            } catch (error) {
              console.log(error);
            }
            this.anoSeleccionadO = "Seleccione un Año";
            const añoInicio = 2022;
            const añoActual = new Date().getFullYear();
            this.tiemposDeReporte2 = [];

            // Generar dinámicamente la lista de años
            for (let año = añoInicio; año <= añoActual; año++) {
              this.tiemposDeReporte2.push([año.toString()]);
            }
          });
          this.banderaFiltroFechas = true;
          this.listActiveMensual = false;
          this.listActiveTrimes = false;
          this.listActiveAnual = !this.listActiveAnual;
        }
        break;
      default:
        console.error("Faltan datos para proceder");
        break;
    }
  }

  burcarFormatos() {
    console.log(
      "Año seleccionado: ",
      this.anoSeleccionadO + " Mes Seleccionado: ",
      this.mesSeleccionado
    );

    if (!this.listActiveTrimes) {
      switch (this.anoSeleccionadO) {
        case "2022":
        case "2023":
        case "2024":
          {
            switch (this.mesSeleccionado) {
              case "enero":
              case "febrero":
              case "marzo":
              case "abril":
              case "mayo":
              case "junio":
              case "julio":
              case "agosto":
              case "septiembre":
              case "octubre":
              case "noviembre":
              case "diciembre":
                {
                  const ultimoDia = moment()
                    .month(this.mesSeleccionado)
                    .endOf("month")
                    .format("DD");

                  const meses = {
                    enero: 1,
                    febrero: 2,
                    marzo: 3,
                    abril: 4,
                    mayo: 5,
                    junio: 6,
                    julio: 7,
                    agosto: 8,
                    septiembre: 9,
                    octubre: 10,
                    noviembre: 11,
                    diciembre: 12,
                  };

                  const numeroDeMes =
                    meses[this.mesSeleccionado.toLocaleLowerCase()];

                  const numeroDeMesFormateado = numeroDeMes
                    .toString()
                    .padStart(2, "0");

                  console.log(
                    numeroDeMes,
                    this.anoSeleccionadO,
                    this.mesSeleccionado
                  );

                  this.variableTiempoRecursos = [
                    `${numeroDeMes}`,
                    `${this.anoSeleccionadO}`,
                  ];

                  // this.variableTiempoRecursos = [`${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-${numeroDeMesFormateado}-01`, `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-${numeroDeMesFormateado}-${ultimoDia}`];
                  this.banderaTablaFormatos = true;
                  console.log(this.variableTiempoRecursos);
                }
                break;
              default:
                /* Solo busqueda por años */
                this.variableTiempoRecursos = [
                  `${this.anoSeleccionadO}-01-01`,
                  `${this.anoSeleccionadO}-12-31`,
                ];
                this.banderaTablaFormatos = true;
                break;
            }
          }
          break;
        default:
          this.banderaTablaFormatos = false;
          this.alerta.descErr(
            "tienes que ingresar alguna fecha para poder seguir"
          );
          break;
      }
    } else {
      console.log("entro al else")

      switch(this.anoSeleccionadO) {
        case '2022':
        case '2023':
        case '2024':
          {
            switch (this.mesSeleccionado) {
              case 'enero-marzo': {
                this.variableTiempoRecursos = [
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-01-01`,
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-03-30`,
                ];
                
                this.banderaTablaFormatos = true;
              }
                break;
              case 'abril-junio': {
                this.variableTiempoRecursos = [
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-04-01`,
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-06-30`,
                ];
                
                this.banderaTablaFormatos = true;
              }
                break;
              case 'julio-septiembre': {
                this.variableTiempoRecursos = [
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-07-01`,
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-09-30`,
                ];
                
                this.banderaTablaFormatos = true;
              }
                break;
              case 'octubre-diciembre': {
                this.variableTiempoRecursos = [
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-10-01`,
                  `${moment().month(this.mesSeleccionado).format(this.anoSeleccionadO)}-12-30`,
                ];
                
                this.banderaTablaFormatos = true;
              }
            }
          }
      } 
    }
  }

  modal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-vinculate" })
      .result.then(
        (result) => {
          this.genereadoAOm = false;
        },
        (reason) => {}
      );
  }
  gereneadoExcelsJP() {
    if (this.genereadoAOm) {
      this.GestionAOMJP();
      //  console.log('este es el de aom')
    } else {
      this.GestionTecnicosJP();
      //  console.log('este es el de tecnicos')
      this.genereadoAOm = false;
    }
    this.fechaF = "";
    this.fechaI = "";
    this.banderaTiempo1 = false;
    this.banderaTiempo2 = false;
    this.Toexcel = [];
    this.idSeccional;
    this.idProject;
  }
  async GestionTecnicosJP() {
    this.spinner.show();
    console.log("FI", this.fechaI, "FF", this.fechaF, "CLI", this.idCliente);
    console.log("IdSeccional", this.idSeccional, "IdProject", this.idProject);
    const pdata2 =
      this.banderaTiempo1 === true && this.banderaTiempo2 === true
        ? {
            option: "todomantenimiento-filtro",
            idCliente: this.idCliente,
            fechaI: this.fechaI,
            fechaF: this.fechaF,
            // option: "insertar2",
            // texto: `SELECT K.NUI, M.Id_Encuesta,M.Id_Proyecto, M.Id_Seccional,REPLACE(CAST(M.Fecha AS CHAR),"-","/") AS Fecha,M.nombre,M.tipoDoc,M.numeroDoc,M.telefono,M.sexo,M.email,M.ocupacion,M.depto,M.municipio,M.codmuni,M.vereda,M.Latitud,M.Longitud,M.Altitud,M.tipo,M.usopredio,M.propio,M.estrato,M.medidor,M.marcaM,M.serialM,M.fotovoltaico,M.estadoGabinete,M.paneles,M.puestaTierra,M.portaT,M.inversor,M.mppt,M.bateria,M.protecciones,M.instalaciones,M.soporte,M.nfc,M.marcame,M.referencia,M.numero,M.recarga,M.localizacion,M.observacionesF,M.equipos,M.cuales,M.falla,M.labores,M.tecnicos,M.externos,M.observaciones,M.pregunta,M.Id_Inicial,M.modificado,M.quien, O.panel,O.panelesv,O.panelesc,O.baterias,O.bateriasv,O.bateriasc,O.inversorS,O.inversorvs,O.inversorcs,O.proteccionesEs,O.comentarios, A.Description,A.Maintenance_Type,A.Status,A.Id_Ticket, U.Id_User,U.UserName,U.UserIdentification,U.UserNickName
            // FROM db_liwa.Maintenance_new A
            // LEFT JOIN db_liwa.Tecnico M ON M.Id_Inicial = A.Id_Beneficiario
            // LEFT JOIN db_liwa.Tecnico_Manten O ON M.Id_Encuesta = O.Id_Encuesta
            // INNER JOIN db_liwa.User U ON U.Id_User = M.Id_Encuestador
            // INNER JOIN db_liwa.AOM K ON K.Id_Encuesta = A.Id_Beneficiario
            // INNER JOIN db_liwa.PorcentajeAOM P ON O.Id_Encuesta = M.Id_Encuesta
            // WHERE P.IsDelete=0 AND A.Id_Client='${this.idCliente}' AND M.LastInsert BETWEEN '${this.fechaI}' AND '${this.fechaF}' GROUP BY A.Id_Maintenance;`,

            // client: this.global.Id_Client
          }
        : {
            option: "todomantenimiento",
            idCliente: this.idCliente,
          };
    // console.log(this.fechaI,this.fechaF,'este es el problema')
    await this.global.consultar(pdata2, async (err, response2) => {
      console.log("response2", response2, pdata2);
      if (
        response2 == null ||
        response2 == undefined ||
        response2.length == 0
      ) {
        this.alerta.descErr(
          "Los sentimos a ocurrido un error, por favor intente de nuevo."
        );
        this.spinner.hide();
      } else {
        const infoExcel = await response2;
        this.Toexcel = [
          [
            "NUI",
            "Id_Encuesta",
            "Id_Proyecto",
            "Id_Seccional",
            "Fecha",
            "nombre",
            "numeroDoc",
            "telefono",
            "sexo",
            "email",
            "ocupacion",
            "depto",
            "municipio",
            "codmuni",
            "vereda",
            "Latitud",
            "Longitud",
            "Altitud",
            "tipo",
            "usopredio",
            "propio",
            "estrato",
            "medido",
            "marcaM",
            "serialM",
            "fotovoltaico",
            "estadoGabinete",
            "paneles",
            "puestaTierra",
            "inversor",
            "mppt",
            "bateria",
            "protecciones",
            "instalaciones",
            "soporte",
            "marcame",
            "referencia",
            "numero",
            "recarga",
            "localizacion",
            "observacionesF",
            "equipos",
            "cuales",
            "falla",
            "labores",
            "tecnicos",
            "externos",
            "observaciones",
            "pregunta",
            "Id_Inicial",
            "modificado",
            "quien",
            "panel",
            "panelesv",
            "panelesc",
            "baterias",
            "bateriasv",
            "bateriasc",
            "inversorS",
            "inversorvs",
            "inversorcs",
            "proteccionesEs",
            "comentarios",
            "Description",
            "Maintenance_Type",
            "Status",
            "Id_Ticket",
            "Id_User",
            "UserName",
            "UserIdentification",
          ],
        ];
        for (let valor of infoExcel) {
          this.Toexcel.push([
            valor[0],
            valor[1],
            valor[2],
            valor[3], // n proyecto
            valor[4], // n seccional
            valor[5],
            valor[6],
            valor[7],
            valor[8],
            valor[9],
            valor[10],
            valor[11],
            valor[12],
            valor[13],
            valor[14],
            valor[15],
            valor[16],
            valor[17],
            valor[18],
            valor[19],
            valor[20],
            valor[21],
            valor[22],
            valor[23],
            valor[24],
            valor[25],
            valor[26],
            valor[27],
            valor[28],
            valor[29],
            valor[30],
            valor[31],
            valor[32],
            valor[33],
            valor[34],
            valor[35],
            valor[36],
            valor[37],
            valor[38],
            valor[39],
            valor[40],
            valor[41],
            valor[42],
            valor[43],
            valor[44],
            valor[45],
            valor[46],
            valor[47],
            valor[48],
            valor[49],
            valor[50],
            valor[51],
            valor[52],
            valor[53],
            valor[54],
            valor[55],
            valor[56],
            valor[57],
            valor[58],
            valor[59],
            valor[60],
            valor[61],
            valor[62],
            valor[63], // nombre del tecnico
            valor[64],
            valor[65],
            valor[66],
            valor[67],
            valor[68],
            valor[69],
            valor[70],
            valor[71],
            valor[72],
          ]);
        }
        //  los que estan en 12 Son datos faltante
        this.excelService.exportAsExcelFile2(
          this.Toexcel,
          "Reporte Tecnicos",
          "Reporte de Gestion Tecnicos " + Date.now().toString()
        );
        this.spinner.hide();
      }
    });
  }
  async GestionAOMJP() {
    this.spinner.show();
    this.seccionalSeleccionada = "Seleccione Una Opcion";
    const pdata =
      this.banderaTiempo1 === true && this.banderaTiempo2 === true
        ? {
            option: "todoreplanteo-filtro",
            idCliente: this.idCliente,
            seccionalSeleccionada: this.seccionalSeleccionada,
            fechaI: this.fechaI,
            fechaF: this.fechaF,
            // option: "insertar2",
            // texto: `SELECT A.Id_Encuesta, T.Nombre AS Proyecto, S.Nombre AS Seccional, REPLACE(CAST(A.Fecha AS CHAR),"-","/") AS Fecha, A.Nombre_Completo, A.TipoDoc, A.Cedula, A.Telefono, A.Sexo, A.Correo, A.Ocupacion, A.Depto, A.Municipio, A.Cod_Mun, A.Vereda, A.Latitud, A.Longitud, A.Altitud, A.Tipo, A.Uso_Predio, A.Posecion, A.Estrato, A.Medidor, A.MarcaM, A.SerialM, A.Fotovoltaico, A.Gabinete, A.Paneles, A.SPT, A.PortaT, A.Inversor, A.MPPT, A.Bateria, A.Protecciones, A.Instalaciones, A.Soporte, A.NFC, A.Percepcion, A.TelefonoIn, A.Celulares, A.Internet, A.Lavadora, A.Nevera, A.Licuadora, A.Ventilador, A.AireAcondicionado, A.Sanduchera, A.Ducha, A.Cercas, A.NumLED, A.NumIncand, A.TV, A.Otras, A.VoluntadPago, A.Tarifa, A.Observaciones, A.Id_Inicial, A.NUI FROM db_liwa.AOM A
            // INNER JOIN db_liwa.PorcentajeAOM O ON O.Id_Encuesta = A.Id_Encuesta
            // INNER JOIN db_liwa.User U ON U.Id_User = A.Id_Encuestador
            // INNER JOIN db_liwa.tipoProyecto T ON T.id_tipoProyecto = A.Id_Proyecto
            // INNER JOIN db_liwa.Seccionales S ON S.Id_seccional = A.Id_Seccional
            // WHERE O.IsDelete=0 AND T.Id_Client = '${this.idCliente}' AND A.Id_Seccional ='${this.seccionalSeleccionada}' AND A.Fecha BETWEEN '${this.fechaI}' AND '${this.fechaF}';`,
          }
        : {
            option: "todoreplanteo",
            idCliente: this.idCliente,
            // option: "insertar2",
            // texto: `SELECT A.Id_Encuesta, T.Nombre AS Proyecto, S.Nombre AS Seccional, REPLACE(CAST(A.Fecha AS CHAR),"-","/") AS Fecha, A.Nombre_Completo, A.TipoDoc, A.Cedula, A.Telefono, A.Sexo, A.Correo, A.Ocupacion, A.Depto, A.Municipio, A.Cod_Mun, A.Vereda, A.Latitud, A.Longitud, A.Altitud, A.Tipo, A.Uso_Predio, A.Posecion, A.Estrato, A.Medidor, A.MarcaM, A.SerialM, A.Fotovoltaico, A.Gabinete, A.Paneles, A.SPT, A.PortaT, A.Inversor, A.MPPT, A.Bateria, A.Protecciones, A.Instalaciones, A.Soporte, A.NFC, A.Percepcion, A.TelefonoIn, A.Celulares, A.Internet, A.Lavadora, A.Nevera, A.Licuadora, A.Ventilador, A.AireAcondicionado, A.Sanduchera, A.Ducha, A.Cercas, A.NumLED, A.NumIncand, A.TV, A.Otras, A.VoluntadPago, A.Tarifa, A.Observaciones, A.Id_Inicial, A.NUI FROM db_liwa.AOM A
            // INNER JOIN db_liwa.PorcentajeAOM O ON O.Id_Encuesta = A.Id_Encuesta
            // INNER JOIN db_liwa.User U ON U.Id_User = A.Id_Encuestador
            // INNER JOIN db_liwa.tipoProyecto T ON T.id_tipoProyecto = A.Id_Proyecto
            // INNER JOIN db_liwa.Seccionales S ON S.Id_seccional = A.Id_Seccional
            // WHERE O.IsDelete=0 AND T.Id_Client = '${this.idCliente}';`,
          };
    console.log(pdata, "este es el problema");
    await this.global.consultar(pdata, async (err, response) => {
      if (response == null || response == undefined || response.length == 0) {
        this.alerta.descErr(
          "Lo sentimos puede que hayas digitado una fecha donde aun no hay registros, Intente de nuevo con otra fecha por favor."
        );
        this.spinner.hide();
      } else {
        const infoExcel = await response;
        //      los que estan en 12 Son datos faltante
        this.Toexcel = [
          [
            "ID_Encuesta",
            "Proyecto",
            "Seccional",
            "Fecha",
            "Nombre",
            "Tipo Documento",
            "Cedula",
            "Telefono",
            "Sexo",
            "Correo",
            "Ocupacion",
            "Departamento",
            "Municipio",
            "Codigo de Municipio",
            "Vereda",
            "Latitud",
            "Longitud",
            "Altitud",
            "Tipo",
            "Uso Predio",
            "Estado de Propiedad",
            "Estrato",
            "Medidor",
            "Marca Medidor",
            "Serial Medidor",
            "Fotovoltaico",
            "Estado Gabinete",
            "Paneles",
            "SPT",
            "Porta T",
            "Inversor",
            "MPPT",
            "Bateria",
            "Proteccion",
            "Instalaciones",
            "Soporte",
            "NFC",
            "Percepcion",
            "Telefono In",
            "Celulares",
            "Internet",
            "Lavadora",
            "Nevera",
            "Licuadora",
            "Ventilador",
            "Aire Acondicionado",
            "Sanduchera",
            "Ducha",
            "Cercas",
            "Numero de LED",
            "Numero in Cand",
            "TV",
            "Otros",
            "Voluntad de Pago",
            "Tarifa",
            "Observacion",
            "Id Inicial",
            "NUI",
            "Revisión",
          ],
        ];
        for (let valor of infoExcel) {
          this.Toexcel.push([
            valor[0],
            valor[1],
            valor[2],
            valor[3],
            valor[4],
            valor[5],
            valor[6],
            valor[7],
            valor[8],
            valor[9],
            valor[10],
            valor[11],
            valor[12],
            valor[13],
            valor[14],
            valor[15],
            valor[16],
            valor[17],
            valor[18],
            valor[19],
            valor[20],
            valor[21],
            valor[22],
            valor[23],
            valor[24],
            valor[25],
            valor[26],
            valor[27],
            valor[28],
            valor[29],
            valor[30],
            valor[31],
            valor[32],
            valor[33],
            valor[34],
            valor[35],
            valor[36],
            valor[37],
            valor[38],
            valor[39],
            valor[40],
            valor[41],
            valor[42],
            valor[43],
            valor[44],
            valor[45],
            valor[46],
            valor[47],
            valor[48],
            valor[49],
            valor[50],
            valor[51],
            valor[52],
            valor[53],
            valor[54],
            valor[55],
            valor[56],
            valor[57],
            valor[58],
            valor[59],
          ]);
        }
        this.excelService.exportAsExcelFile2(
          this.Toexcel,
          "Reporte AOM",
          "Reporte de Gestion AOM " + Date.now().toString()
        );
        this.spinner.hide();
      }
    });
  }

  async GestionTecnicoJPDrive() {
    this.spinner.show();
    console.log("IdSeccional", this.idSeccional, "IdProject", this.idProject);

    const pdata = {
      option: "insertar2",
      texto: `SELECT S.Nombre AS Seccional, T.Nombre AS Proyecto FROM db_liwa.Seccionales S
      INNER JOIN db_liwa.tipoProyecto T ON T.id_tipoProyecto = S.Id_Proyecto
      WHERE S.Id_seccional = ${this.idSeccional} AND S.Id_Proyecto = ${this.idProject};`,
    };

    await this.global.consultar(pdata, async (err, response) => {
      console.log("response", response, pdata);
      const idEncuestas = await response;
      if (
        idEncuestas == null ||
        idEncuestas == undefined ||
        idEncuestas.length == 0
      ) {
        this.alerta.descErr(
          "Los sentimos Pero no hay registros para esta seccional y proyecto, por favor intente de nuevo."
        );
        this.spinner.hide();
      } else {
        console.log(idEncuestas, "este es el id de las encuestas");
        console.log(
          "primer id",
          idEncuestas[0][0],
          "segundo id",
          idEncuestas[0][1]
        );
        let mesNumber = 0;
        console.log(this.mesSeleccionado, "este es el mes");
        if (this.mesSeleccionado === "ENERO") {
          console.log("entro a enero");
          mesNumber = 1;
        } else if (this.mesSeleccionado === "FEBRERO") {
          console.log("entro a febrero");
          mesNumber = 2;
        } else if (this.mesSeleccionado === "MARZO") {
          console.log("entro a marzo");
          mesNumber = 3;
        } else if (this.mesSeleccionado === "ABRIL") {
          console.log("entro a abril");
          mesNumber = 4;
        } else if (this.mesSeleccionado === "MAYO") {
          console.log("entro a mayo");
          mesNumber = 5;
        } else if (this.mesSeleccionado === "JUNIO") {
          console.log("entro a junio");
          mesNumber = 6;
        } else if (this.mesSeleccionado === "JULIO") {
          console.log("entro a julio");
          mesNumber = 7;
        } else if (this.mesSeleccionado === "AGOSTO") {
          console.log("entro a agosto");
          mesNumber = 8;
        } else if (this.mesSeleccionado === "SEPTIEMBRE") {
          console.log("entro a septiembre");
          mesNumber = 9;
        } else if (this.mesSeleccionado === "OCTUBRE") {
          mesNumber = 10;
        } else if (this.mesSeleccionado === "NOVIEMBRE") {
          mesNumber = 11;
        } else if (this.mesSeleccionado === "DICIEMBRE") {
          mesNumber = 12;
        }
        console.log(mesNumber, "este es el mes");
        const queryEncuestas = {
          option: "insertar2",
          texto: `
          SELECT A.Id_Encuesta
          FROM db_liwa.Tecnico A
          INNER JOIN db_liwa.Maintenance_new B ON A.instalMant = B.Id_Maintenance
          INNER JOIN db_liwa.User C ON A.Id_Encuestador = C.Id_User
          INNER JOIN db_liwa.AOM D ON B.Id_Beneficiario = D.Id_Encuesta
          WHERE A.Id_Seccional = "${idEncuestas[0][0]}" AND A.Id_Proyecto = "${idEncuestas[0][1]}"
          AND MONTH(A.fecha) = ${mesNumber};`,
        };
        await this.global.consultar(queryEncuestas, async (err, response) => {
          const encuestas = await response;
          if (
            encuestas == null ||
            encuestas == undefined ||
            encuestas.length == 0
          ) {
            this.alerta.descErr(
              "Los sentimos no hay actas para el mes " +
                this.mesSeleccionado +
                " por favor intente de nuevo."
            );
            this.spinner.hide();
          } else {
            console.log(encuestas, "estos son las respuestas");
            this.subirPdf(encuestas);
          }
        });
      }
    });
  }

  subirPdf(ids: any) {
    let arrId = ids.map((id: any) => id[0]);
    this.global.generarPdfsPost(arrId).then(async (response) => {
      if (
        response === null ||
        response === undefined ||
        response.length === 0
      ) {
        this.alerta.descErr(
          "Los sentimos a ocurrido un error, por favor intente de nuevo."
        );
        this.spinner.hide();
      } else {
        const responseUrl = await response;
        console.log(responseUrl, "este es el response");

        this.alerta.descValid("Se han generado los pdfs correctamente.");
        this.spinner.hide();
      }
    });
  }

  referenciaArchivoFI(ev) {
    let FI = ev.target.value;
    this.fechaI = moment(FI).format("YYYY-MM-DD 00:00:00");
    this.banderaTiempo1 = true;
    console.log(this.fechaI, "init");
  }
  referenciaArchivoFF(ev) {
    let FF = ev.target.value;
    this.fechaF = moment(FF).format("YYYY-MM-DD 23:59:59");
    this.banderaTiempo2 = true;
    // console.log(moment(this.fechaF), 'este es el man')
  }
}
