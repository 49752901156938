import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ExcelService } from 'src/app/excel.service';
import { GlobalService } from 'src/app/global.service';
import { GlobalAlertService } from 'src/app/global-alert.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-listado-preliminar-redes-nazareth',
  templateUrl: './listado-preliminar-redes-nazareth.component.html'
})
export class ListadoPreliminarRedesNazarethComponent implements OnInit {
  clientClass;
  network = [];
  tableOn: boolean = false;
  Toexcel;
  Excel;
  constructor(public global: GlobalService,
    private route: Router,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private alert: GlobalAlertService) {
    this.global.initData();
  }
  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata2 = { option: 'porcentaje-nazareth' };
      console.log('porcentaje-nazareth', pdata2);
      this.global.consultar(pdata2, (err2, response2) => {
        const pdata = { option: 'redes-nazareth' };
        this.global.consultar(pdata, (err2, response) => {
          for (let i = 0; i < response2.length; i++) {
            for (let j = 0; j < response.length; j++) {
              if (response2[i][0] == response[j][0]) {
                this.network.push(response[j]);
              } else {
                continue;
              }
            }
          }
          $(function () {
            this.table = $('#TRedesNazareth').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
          this.tableOn = true;
          this.spinner.hide();
        });
      });
    }, 200);
    setTimeout(() => {      
      const pdata10 = {option: 'excellpr'};
      this.global.consultar(pdata10, (err10, response10) => {
        console.log('REVISIÓN EXCEL', response10);
        this.Excel = response10;
      });
    }, 1000);
  }
  ir(idForm) {
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar-redes-nazareth');
  }
  downloadExcel() {
    console.log('Excel', this.Excel);
    this.Toexcel = [['Id', 'Nombre del beneficiario', 'Nombre del funcionario', 'Tipo de red', 'Fecha inicio del proyecto']];
    for (let p = 0; p < this.Excel.length; p++) {
      this.Toexcel.push(this.Excel[p]);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Listado Preliminar', 'Listado Preliminar');
      setTimeout(() => {
        this.Toexcel = [['Id', 'Nombre del beneficiario', 'Nombre del funcionario', 'Tipo de red', 'Fecha inicio del proyecto']];
      }, 1000);
    }, 1500);
  }

}
