import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ExcelService } from 'src/app/excel.service';
import { GlobalService } from 'src/app/global.service';
import { GlobalAlertService } from 'src/app/global-alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listado-preliminar',
  templateUrl: './listado-preliminar.component.html'
})
export class ListadoPreliminarComponent implements OnInit {
  clientClass;
  benefits = [];
  review = [];
  tableOn: boolean = false;
  Toexcel;
  table;
  Excel;

  constructor(public global: GlobalService,
    private route: Router,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      const pdata2 = { option: 'porcentaje' };
      console.log('porcentaje', pdata2);
      this.global.consultar(pdata2, (err2, response2) => {
        this.global.porcentaje = response2;
        const pdata1 = { option: 'beneficiarios' };
        console.log('Beneficiarios', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos beneficiarios', response1);
          this.benefits = response1;
          for (let i = 0; i < this.global.porcentaje.length; i++) {
            if (this.global.porcentaje[i][1] == 1 && this.global.porcentaje[i][2] == 1 && this.global.porcentaje[i][3] == 1) {
            } else {
              for (let j = 0; j < this.benefits.length; j++) {
                if (this.global.porcentaje[i][0] == this.benefits[j][1]) {
                  this.review.push(this.benefits[j]);
                } else {
                  continue;
                }
              }
            }
          }
          const pdata10 = { option: 'excelpre' };
          this.global.consultar(pdata10, (err10, response10) => {
            console.log('LISTADO EXCEL', response10);
            this.Excel = response10;
          });
          $(function () {
            this.table = $('#TablePreliminar').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
            let value;
            value = sessionStorage.getItem('barrabuscar');
            this.table.search(value).draw();
            if (value != undefined || value != null || value != 'undefined') {
              this.table.search(value).draw();
            } else {
              this.table.search('').draw();
            }
          });
          this.tableOn = true;
          this.spinner.hide();
        });
      });
    }, 10000);
  }
  verificarPorcentaje(idFormulario, caso: number): number {
    for (let i = 0; i < this.global.porcentaje.length; i++) {
      if (idFormulario == this.global.porcentaje[i][0]) {
        switch (caso) {
          case 1:
            if (this.global.porcentaje[i][1] == 0) {
              return 0;
            } else {
              return 1;
            }

          case 2:

            if (this.global.porcentaje[i][2] == 0) {
              return 0;
            } else {
              return 1;
            }

          case 3:

            if (this.global.porcentaje[i][3] == 0) {
              return 0;
            } else {
              return 1;
            }


          default:
            break;
        }
      } else {
        continue;
      }

    }
  }
  revisionCedula(idBenefit, idForm) {
    console.log('id beneficiario', idBenefit);
    console.log('id formulario', idForm);
    this.global.searchValue = $('.dataTables_filter input').val();
    sessionStorage.setItem('barrabuscar', this.global.searchValue);
    this.global.isCC = true;
    this.global.isLc = false;
    this.global.isDp = false;
    this.global.idBenefit = idBenefit;
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar');
  }
  revisionLocalizacion(idBenefit, idForm) {
    console.log('id beneficiario', idBenefit);
    console.log('id formulario', idForm);
    this.global.isCC = false;
    this.global.isLc = true;
    this.global.isDp = false;
    this.global.idBenefit = idBenefit;
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar');
  }
  revisionDispositivos(idBenefit, idForm) {
    console.log('id beneficiario', idBenefit);
    console.log('id formulario', idForm);
    this.global.isCC = false;
    this.global.isLc = false;
    this.global.isDp = true;
    this.global.idBenefit = idBenefit;
    this.global.idForm = idForm;
    this.route.navigateByUrl('/formulario-preliminar');
  }
  activarListPre(idBenefit) {
    console.log('id beneficiario', idBenefit);
  }
  desactivarListPre(idBenefit) {

    console.log('id beneficiario', idBenefit);
  }
  deleteValQ(idBenefit) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el usuario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        // setTimeout(() => {
          try {
            var query = "UPDATE db_liwa.Beneficiario SET IsDelete = '1' WHERE Id_Beneficiario ='" + idBenefit + "';";
            const pdata1 = { option: 'insertar3', texto: query };
            this.global.consultar(pdata1,async  (err, response) => {
              if (err == null && await response == true) {
                this.alert.descValid('Registro eliminado correctamente');
                this.ngOnInit();
                this.spinner.hide();
              }
            });
          } catch (error) {
            console.log(error);
          }
        // }, 1000);
      } else {
        this.ngOnInit();
      }
    });
  }
  downloadExcel() {
    console.log('Excel', this.Excel);
    this.Toexcel = [['Id', 'Nombre del beneficiario', 'Fecha', 'Departamento', 'Municipio', 'Latitud', 'Longitud', 'Altitud', 'Revisión Cédula', 'Revisión Localización', 'Revisión Dispositivos']];
    for (let p = 0; p < this.Excel.length; p++) {
      this.Toexcel.push(this.Excel[p]);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile2(this.Toexcel, 'Listado Preliminar', 'Listado Preliminar');
      setTimeout(() => {
        this.Toexcel = [['Id', 'Nombre del beneficiario', 'Fecha', 'Departamento', 'Municipio', 'Latitud', 'Longitud', 'Altitud', 'Revisión Cédula', 'Revisión Localización', 'Revisión Dispositivos']];
      }, 1000);
    }, 1500);
  }

}
