import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crear-visita',
  templateUrl: './crear-visita.component.html'
})
export class CrearVisitaComponent implements OnInit {
  visitDescription;
  Correo
  Telefono
  Municipio 
  visitType;
  isTicket: boolean = false;
  isSelected: boolean = false;
  seat = "ninguno"; //sede
  seccional ;
  seccionalId = "ninguna";
  idSeat; //idsede
  nameUser;
  visitDateTime;
  tecnico ="ninguna";
  tecnicos: any[] = [];
  sedes: any[] = [];
  selectedData;
  Id_Mantenimiento;
  updateM;
  NameP;
  NameS;
  clientClass;
  tecnicosDisponibles;
  agendasTecnicos = new Array();
  horaVisita ="ninguno"
  tiempoEstimado = "ninguno"
  userid;
  agenda = [];
  horas = [];
  responseData= Array()

  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.selectedForVisit == undefined && this.global.withoutTicket == false && !this.global.isAgended) {
      this.ruta.navigateByUrl('/pagina-principal');
      this.spinner.hide();
    } else {
      // setTimeout(() => {
        this.clientClass = this.global.CLIENTDATALIWA[8];
        if (this.global.withoutTicket == true) {
          // console.log("Entro al primer if")
          this.isTicket = false;
          this.visitDateTime = this.global.selectedDate ? this.global.selectedDate : this.visitDateTime;
          if (this.global.selectedForVisit[1] != undefined) {
            this.seat = this.global.selectedForVisit[1]
            this.cargarSeccionales();
            if (this.global.selectedForVisit[3] != "") {
              this.seccionalId = this.global.selectedForVisit[3]
              this.consultarTecnicos()
              // console.log(this.global.selectedForVisit[4] != "", "taltaltal")
              if (this.global.selectedForVisit[4] != "") {
                this.tecnico = this.global.selectedForVisit[4]
                this.consultarFecha()
              }
            }
          }
 
          this.tecnicosDisponibles
          this.userid="";
          this.visitDescription = "";
          const pdata9 = { option: 'insertar2', texto: `SELECT id_tipoProyecto, Nombre FROM db_liwa.tipoProyecto WHERE Id_Client = ${this.global.Id_Client}` };
          // console.log("Proyetos" , this.seat);
          this.global.consultar(pdata9, (err9, response9) => {
            // console.log('Datos Proyectos', response9);
            this.sedes = response9;
            this.isTicket = true;
            // this.spinner.hide();
            });    
          this.spinner.hide();
        } 
        else if (this.global.isAgended){
          // console.log("Entro al segundo if")
          this.spinner.show()
          // setTimeout(() => {
            const pdata8 = { option: 'insertar2', texto:`select A.*, (SELECT Nombre from db_liwa.tipoProyecto Where id_tipoProyecto = A.Id_Proyecto AND Tipo = 'AOM'),(SELECT Nombre from db_liwa.Seccionales Where Id_seccional = A.Id_Seccional),(SELECT Cedula from db_liwa.EncuestasAOM Where Id = A.Id_Beneficiario) from db_liwa.Tickets_New  A WHERE (SELECT Nombre from db_liwa.tipoProyecto Where id_tipoProyecto = A.Id_Proyecto AND Id_Client = ${this.global.Id_Client}) AND A.Id_Tickets = '${this.global.Id_Ticket}'`}
            this.global.consultar(pdata8, async (err8, response8) => {
            // console.log("Esto es mi pes", response8);
            // console.log(this.global.withoutTicket)
            this.isSelected = true;
            let datos = new Array();
            datos = [await response8[0][6], await response8[0][19], await response8[0][20],await response8[0][3],await response8[0][21],await response8[0][10]]
            this.selectedData = datos;
            this.consultarTecnicos();
            this.userid = this.selectedData[4]
            this.visitDescription = this.selectedData[5]
            this.visitType = "Correctivo"
            this.seat = datos[1];
            this.global.isAgended = false;
            this.spinner.hide();
          })
          // }, 1000)
          
        } else if (this.global.banderaReagendamiento == true) {
          this.clientClass = this.global.CLIENTDATALIWA[8];
          // console.log(this.global.Id_Mantenimiento,'este es el mio quye tal que pin')
          this.Id_Mantenimiento = this.global.Id_Mantenimiento
          this.spinner.show()
          // // setTimeout(() => {
            const pdata81 = { option: 'insertar2', texto:`SELECT (SELECT P.Nombre FROM db_liwa.tipoProyecto P WHERE P.id_tipoProyecto = M.idProyecto), M.idSeccional, M.Id_Technical, M.Visit_day, (SELECT E.Cedula FROM db_liwa.EncuestasAOM E WHERE id_Encuesta = M.Id_Beneficiario),
            M.Description FROM db_liwa.Maintenance_new M WHERE (SELECT Id_Client from db_liwa.tipoProyecto Where id_tipoProyecto = M.idProyecto AND Id_Client = ${this.global.Id_Client}) AND M.Id_Maintenance = '${this.Id_Mantenimiento}' AND M.IsDelete = 0;`};
            this.global.consultar(pdata81, (err8, response8) => {
              this.seat = response8[0][0]
              // console.log(this.seat,'otrotrototroo')
              this.cargarSeccionales();
              this.seccionalId = response8[0][1]
              this.consultarTecnicos()
              this.tecnico = response8[0][2]
              this.visitDateTime = moment(response8[0][3]).format("yyyy-MM-DD");
              this.consultarFecha()
            this.tecnicosDisponibles
            this.visitDescription = response8[0][5];
            this.userid = response8[0][4];
            const pdata9 = { option: 'insertar2', texto: `SELECT id_tipoProyecto, Nombre FROM db_liwa.tipoProyecto WHERE Id_Client = ${this.global.Id_Client}` };
            // console.log("Proyetos" , this.userid);
            this.global.consultar(pdata9, (err9, response9) => {
              // console.log('Datos Proyectos', response9);
              this.sedes = response9;
              // this.isTicket = true;
          //     // this.spinner.hide();
              });    
            this.spinner.hide();
          })
        } else {
          // console.log("Entro al cuarto if")
          this.selectedData = this.global.selectedForVisit;
          this.consultarTecnicos();
          // console.log(this.selectedData, 'este es el arreglo que mandamos desde la modificacion')
          this.seat = this.selectedData[1]
          this.seccionalId = this.selectedData[3]
          this.userid = this.selectedData[4]
          this.visitDescription = this.selectedData[5]
          this.isSelected = true;
          this.sedes = this.global.sedes;
          const pdata8 = { option: 'tecnico', client: this.global.CLIENTDATALIWA[0] };
          // console.log(pdata8);
          this.global.consultar(pdata8, (err8, response8) => {
            this.tecnicos = response8;
            const pdata9 = { option: 'insertar2', texto: `SELECT id_tipoProyecto, Nombre FROM db_liwa.tipoProyecto WHERE Id_Client = ${this.global.Id_Client}` };
            // console.log(pdata9);
            this.global.consultar(pdata9, (err9, response9) => {
              this.sedes = response9;
              this.nameUser = this.selectedData[9];
              this.idSeat = this.selectedData[8];
              this.isTicket = true;
              this.visitType = "Correctivo"
              this.spinner.hide();
            });
          });
        }

      // }, 1000);
    }


  }
  validadorTickets() {
    alert(`este es evento`)
    // console.log("hola mi amor me encastas ")
  }
  atras() {
    window.history.back();
    this.global.withoutTicket = false;
  }
  nombreProyecto(idx) {
    // console.log(idx);
    // console.log(this.global.selectedForVisit);
  }
  cargarSeccionales(){
    this.spinner.show();
    const pdata1 = { option: 'insertar2', texto: "SELECT A.Nombre, A.id_seccional FROM db_liwa.Seccionales A JOIN db_liwa.tipoProyecto B ON A.Id_Proyecto = B.id_tipoProyecto Where B.Nombre = '"+this.seat+"';" };
    this.global.consultar(pdata1, (err2, response1)=>{
    setTimeout(()=>{
      this.seccional = response1;
      this.spinner.hide()
    },500)
     
    });
  }
  consultarFecha(){
    if(this.visitDateTime == undefined){
      // console.log("sin fecha seleccionada")
    }
    else{
      // console.log("bank bank", this.visitDateTime)
    this.spinner.show();
    let agenda
    // setTimeout(()=>{
      console.log(this.tecnico,this.visitDateTime,'nuestro console')
        const pdata1 = { option: 'insertar2', texto:"SELECT Visit_Hour_Init, Visit_Hour_finish  FROM db_liwa.Maintenance_new WHERE Id_Technical = '"+this.tecnico+"' AND Visit_day = '"+this.visitDateTime+"' AND IsDelete = 0"}
        this.global.consultar(pdata1, async (err2, response1)=>{
          agenda = await response1;
          let agendaTecnico = [{hora: 8, estado : 'disponible'},
          {hora: 9, estado : 'disponible'},
          {hora: 10, estado : 'disponible'},
          {hora: 11, estado : 'disponible'},
          {hora: 12, estado : 'disponible'},
          {hora: 13, estado : 'disponible'},
          {hora: 14, estado : 'disponible'},
          {hora: 15, estado : 'disponible'},
          {hora: 16, estado : 'disponible'},
          {hora: 17, estado : 'disponible'}]
          
          for(let cita of agenda){
            let horaInicio = cita[0].split(":")[0]
            let horaFinal = cita[1].split(":")[0]
            let diferenciaHora = (parseInt(horaFinal) - parseInt(horaInicio))
            let j = 0
            for(let i = 0; i < diferenciaHora; i++) {
              for(let valor of agendaTecnico){
                if(valor.hora == parseInt(horaInicio) + j){
                   valor.estado = 'agendado'
                }
              }
              j++
            }
          }
          // this.agendasTecnicos.push({tecnico: this.tecnico, agenda: agendaTecnico})
          let h = 0
          this.agenda = agendaTecnico;
          this.agendasTecnicos= new Array()
          for (let valor of agendaTecnico) {
            if(valor.estado == "disponible")
              this.agendasTecnicos.push(valor.hora.toString()+":00:00")
            h++
          }
          // console.log(this.agendasTecnicos)
          this.spinner.hide()
          
      });
      
    // },1000) 
  }

  }
  horarioDisponible(){
    this.horas = []
    // console.log(this.agenda)
    let i = 0;
    for (let valor of this.agenda){
        if(valor.hora.toString() == this.horaVisita.split(":")[0])
          break
        i = i + 1;    
    }
    let h = 1;
    for (let j = i; j< this.agenda.length ; j++){
      if (this.agenda[j].estado != "disponible")
        break;
      if(h == 1)  
        this.horas[h-1] = {index : h, hora: h.toString()+" hora"}
      else
      this.horas[h-1] = {index : h, hora: h.toString()+" horas"}
      h++;
    }
    // console.log(this.horas)

    
  }
  consultarTecnicos(){
    if(this.selectedData != undefined){
      this.seccionalId = this.selectedData[3]
    }
    this.spinner.show();
    setTimeout(()=>{     
      const pdata1 = { option: 'insertar2', texto:"SELECT A.Id_user, A.`UserName`, C.`Nombre`  FROM db_liwa.User A JOIN db_liwa.tipoProyecto B ON A.`Id_Client` = B.`Id_Client` JOIN db_liwa.Seccionales C ON B.`id_tipoProyecto`=C.`Id_Proyecto` Where C.`Id_seccional` = '"+this.seccionalId+"' AND A.Id_Role = 3 "}
      // console.log(pdata1,"datos que vienen y van buscando amor ");
      this.global.consultar(pdata1, (err2, response1)=>{
          this.tecnicosDisponibles = response1
      });
      this.spinner.hide();
    },1000)
  }
  async generadorVisitas(informacion) {
    this.Correo = informacion[0][2]
    this.Telefono = informacion[0][1]
    this.Municipio = informacion[0][3]
    // console.log(informacion, 'estetetetettetetetet')
    this.spinner.show();
    if (this.global.Id_Ticket == undefined) {
      this.global.Id_Ticket = '-';
    }
    const idTicket = this.global.Id_Ticket
    //Quita la tilde para hacer la consulta
    this.seat = this.seat.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const pdata2 = { option: 'insertar2', texto:"SELECT `id_tipoProyecto` from db_liwa.tipoProyecto WHERE `Nombre` = '"+this.seat+"'"}
    // console.log("Que es ",this.seat)
    await this.global.consultar(pdata2, async (err2, response2)=>{
      const idProyecto = await response2[0][0]
      let idBeneficiario = informacion[0][4]
      // console.log("id Proyecto", idProyecto)
      const query = 'REPLACE INTO db_liwa.Maintenance_new (Id_Maintenance,Id_Beneficiario,Id_Client,Id_Technical,Visit_Hour_Init,Visit_Hour_finish,Visit_day,idSeccional,idProyecto,Description,Maintenance_Type,Status,Id_Ticket,IsDelete,IsActive)' +
      ' VALUES (\'' +
      this.Id_Mantenimiento + '\',\'' +
      informacion[0][4] + '\',\'' +
      this.global.CLIENTDATALIWA[0] + '\',\'' +
      this.tecnico + '\',\'' +
      this.horaVisita + '\',\'' +
      (parseInt(this.horaVisita.split(':')[0])+ parseInt(this.tiempoEstimado)).toString()+':00:00' + '\',\'' +
      this.visitDateTime + '\',\'' +
      this.seccionalId + '\',\'' +
      idProyecto + '\',\'' +
      this.visitDescription + '\', \'' +
      this.visitType + '\',\'' +
      "Agendado" + '\',\'' +
      this.global.Id_Ticket + '\', \'' +
      0 + '\',\'' +
      1 + '\');';
      const pdata1 = { option: 'insertar3', texto: query };
      // console.log("a veeeeeeeeeeeeeeeer" ,query)
      this.global.consultar(pdata1, async (err, response) => {
        const query2 = 'REPLACE INTO db_liwa.Maintenance_Update (Id_MaintenanceU,Id_Maintenance,Id_User,Date,Description,Updates,IsDelete,IsActive)' +
        ' VALUES (\'' +
        this.updateM + '\',\'' +
        this.Id_Mantenimiento + '\',\'' +
        this.global.Id_Usuario + '\',\'' +
        moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
        "La visita ha sido agendada" + '\', \'' +
        "Agendado" + '\',\'' +
        0 + '\',\'' +
        1 + '\');';
        const pdata2 = { option: 'insertar3', texto: query2 };
        this.global.consultar(pdata2,async (err2, response2) => {
          // console.log( await response2, query2);
          if (err2 == null && await response2 == true) {
            // console.log('ImPORTABNTETTETE',this.global.Id_Ticket.length)
            if (this.global.Id_Ticket.length > 1){
            const query3 =
        "REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)" +
        " VALUES ('" +
        this.global.Id_Ticket +
        "','" +
        "Agendamiento del ticket Creado con exito" +
        "','" +
        moment().format("YYYY-MM-DD HH:mm:ss") +
        "','" +
        9 +
        "','" +
        0 +
        "','" +
        1 +
        "');";
      const pdata2 = { option: "insertar3", texto: query3 };
      this.global.consultar(pdata2, async (err2, response2) => {
      })
      const pdata222 = { option: "insertar3", texto: `UPDATE db_liwa.Tickets_New SET Id_Statuses = '9' 
      WHERE Id_Tickets = '${this.global.Id_Ticket}' AND Id_Proyecto = '${idProyecto}' AND Id_Seccional = '${this.seccionalId}'` };
    this.global.consultar(pdata222, async (err23, response23) => {
    // console.log('estamos???',await response23, err23 )
    })
    }
            // console.log(this.tecnico, 'console linea 313') 
            // setTimeout(() => {
              this.alerta.descValid('La visita de mantenimiento fue agendada con éxito');
              this.eviarSMS(idBeneficiario , idTicket, idProyecto)
              // this.enviarcorreo()
              this.global.withoutTicket = false;
              // this.global.Id_Ticket = '-'
              this.spinner.hide();
              this.ruta.navigateByUrl('/visitas');
            // }, 1000);
          } else {
          }
        });
        
      })
  });  
  }
  crearNew(){
    this.Id_Mantenimiento = this.global.banderaReagendamiento === true ? this.Id_Mantenimiento : this.global.Id_Usuario + '_' + moment().unix();
    this.updateM = moment().unix();
    if(this.userid == null || this.userid == undefined){
      this.alerta.descErr("Por favor ingrese El documento de la persona a la que se visitara")
    }
    else if(this.visitType == undefined || this.visitType == null) {
      this.alerta.descErr('Falta definir si el mantenimiento es Preventivo o Correctivo');
    }
    else{
      const pdata1 = { option: 'insertar2', texto:`SELECT M.Id_Seccional,M.Telefono, M.Correo, M.Municipio, M.Id FROM db_liwa.AOM M WHERE(SELECT Id_Client from db_liwa.tipoProyecto Where id_tipoProyecto = M.id_Proyecto AND Id_Client = ${this.global.Id_Client}) AND M.Cedula = '${this.userid}'`}
      this.global.consultar(pdata1, async (err2, response1)=>{
        const informacionDelUsuario = await response1
            if (informacionDelUsuario == null || informacionDelUsuario == undefined || informacionDelUsuario.length == 0){
              this.alerta.descErr("No se encuentra el documento registrado")
            }
            else if(informacionDelUsuario[0][0] != this.seccionalId) {
              this.alerta.descErr('EL usuario no corresponde a la seccional seleccionada');
            } else {
              const pdata3 = { option: 'insertar2', texto:`SELECT Id_Tickets FROM db_liwa.Tickets_New WHERE Id_Beneficiario = '${informacionDelUsuario[0][4]}' AND Id_Statuses = 8;`}
              this.global.consultar(pdata3, async (err3, response3) => {
                 console.log(this.global.Id_Ticket.length, 'id ticket global' )
                 this.responseData = response3
                 if ( this.responseData.length > 0 && this.global.Id_Ticket.length <= 1){
                  const idTicket = this.responseData[0][0]
                  Swal.fire({
                    title: '<strong>Este Usuario tiene un Ticket pendiente por agendamiento de visita tecnica ¿Desea usted relacionar esta visita tecnica con dicho ticket?</strong>',
                    icon: "question",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: "#093579",
                    cancelButtonText: "No, Gracias!!",
                    cancelButtonAriaLabel: "Thumbs up, great!",
                    confirmButtonText: "Si, estoy seguro",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.global.Id_Ticket = idTicket
                      this.generadorVisitas(informacionDelUsuario)
                    } else {
                      this.spinner.hide();
                      this.ruta.navigateByUrl("/visitas");
                    }
                  });
                 } else {
                   this.generadorVisitas(informacionDelUsuario)
                 }
               }) 
            }
        });
        
        
    }
  }
  enviarcorreo() {
    setTimeout(() => {
      const pdata9 = {
        option: "mail",
        ticket: this.idSeat,
        Seccional: this.seccionalId,
        descr: this.visitDescription,
        type: this.visitType,
        contacto:  this.global.UserName,
        tel: this.Telefono,
        user: this.Correo,
        municipio: this.Municipio ,
      };
      this.global.consultar(pdata9, (err2, response2) => {
        // console.log('Datos correo', response2);
      });
      
    }, 500);
  }
  eviarSMS(idBeneficiario, idTicket, idProyecto) {
    // setTimeout(() => {
      const pdata6 = {
        option: "insertar2",
        texto: `SELECT UserName FROM db_liwa.User WHERE Id_User = '${this.tecnico}';`,
      };
      this.global.consultar(pdata6, (err9, response9) => {
        const pdata9 = {
          number: "57" + this.Telefono,
          message: `DISPOWER ESP le informa que el tecnico: ${response9[0][0]}, hara visita tecnica a su planta solar el dia : ${this.visitDateTime} la visita dara inicio a las ${this.horaVisita}`,
          type: "1",
        };
        const pdata8 = {
          number: "57" + "3153583711",
          message: `DISPOWER ESP le informa que el tecnico: ${response9[0][0]}, hara visita tecnica a su planta solar el dia : ${this.visitDateTime} la visita dara inicio a las ${this.horaVisita}`,
          type: "1",
        };
        // const pdata7 = {
        //   number: "57" + "3164946552",
        //   message: `DISPOWER ESP le informa que el tecnico: ${response9[0][0]}, hara visita tecnica a su planta solar el dia : ${this.visitDateTime} la visita dara inicio a las ${this.horaVisita}`,
        //   type: "1",
        // };
        // console.log(this.global.Id_Ticket,idTicket, 'tintintin6666')
        this.global.consultarSMS(pdata9, (err2, response2) => {
          this.global.consultarSMS(pdata8, (err2, response2) => {
            // this.global.consultarSMS(pdata7, (err2, response2) => {
              let query = `REPLACE INTO db_liwa.MensajesAOM (Id_Proyecto,Id_Seccional,Id_Beneficiario,Id_Maintenance,Id_Ticket,Id_User,Id_Tecnico,Numero_Dest,Tipo_Mantenimiento,Tipo_Ticket,ContenidoSMS) 
            VALUES ('${idProyecto}','${this.seccionalId}','${idBeneficiario}','${this.Id_Mantenimiento}','${idTicket}','${this.global.Id_Usuario}','${this.tecnico}','${pdata9.number}','${this.visitType}','Visita Agendada','${pdata9.message}');`;    
            const pdata5 = { option: "insertar3", texto: query }
            this.global.consultar(pdata5, async (err2, response2) => {
            });
            // });
          });
        });
      })
    // }, 500);
  }
}
