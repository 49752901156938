import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/global.service";
import * as $ from "jquery";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { GlobalAlertService } from "src/app/global-alert.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-adm-pqmn",
  templateUrl: "./adm-pqmn.component.html",
})
export class AdmPqmnComponent implements OnInit {
  clientClass;
  Manuals: [];
  Questions = Array();
  Tables;
  private selectedFile: any;
  closeResult = "";
  manualName;
  manualFile;
  manualState;
  Id_Product;
  Id_Client;
  manualActive: boolean = true;
  questionActive: boolean = false;
  manualPdfUrl;
  questionName;
  questionTheme;
  questionDesc;
  questionState;
  editableManual = [{}];
  editableQuestion = [{}];
  modal: NgbModalRef;
  uploadFlag :boolean= false;
  isLoaded = false;
  Products = Array();
  constructor(
    protected global: GlobalService,
    protected route: Router,
    private modalService: NgbModal,
    private alerta: GlobalAlertService,
    private spinner: NgxSpinnerService
  ) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.Id_Client = this.global.CLIENTDATALIWA[0];
    const pdata9 = {
      option: "manuales",
      client: this.global.CLIENTDATALIWA[0],
    };
    // console.log('MANUALES', pdata9);
    this.global.consultar(pdata9, async (err9, response9) => {
      // console.log('Datos Manual', response9);
      this.Manuals = await response9;
      setTimeout(() => {
        $(function () {
          this.table = $("#TableManual").DataTable({
            responsive: false,
            destroy: true,
            retrieve: true,
            paging: true,
            info: false,
            pagingType: "numbers",
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
            },
          });
        });
      }, 900);
    });
    const pdata10 = {
      option: "productos",
      client: this.global.CLIENTDATALIWA[0],
    };
    // console.log('Productos', pdata10);
    this.global.consultar(pdata10, async (err10, response10) => {
      // console.log('Datos Producto', response10);
      this.Products = await response10;
    });

    const pdata8 = {
      option: "questions",
      client: this.global.CLIENTDATALIWA[0],
    };
    // console.log('PREGUNTAS', pdata8);
    this.global.consultar(pdata8, async (err8, response8) => {
      // console.log('Datos Preguntas', response8);
      this.Questions = await response8;
    });

    this.spinner.hide();
    this.isLoaded = true;
  }

  isManual() {
    if (this.questionActive == false) {
      this.manualActive = !this.manualActive;
      $(function () {
        this.table = $("#TableManual").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.questionActive = false;
      this.manualActive = true;
      $(function () {
        this.table = $("#TableManual").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  isQuestion() {
    if (this.manualActive == false) {
      this.questionActive = !this.questionActive;
      $(function () {
        this.table = $("#TableQuestion").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    } else {
      this.manualActive = false;
      this.questionActive = true;
      $(function () {
        this.table = $("#TableQuestion").DataTable({
          responsive: false,
          destroy: true,
          retrieve: true,
          paging: true,
          info: false,
          pagingType: "numbers",
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
      });
    }
  }
  eliminarManual(data: any) {
    const query =
      "UPDATE db_liwa.Manuals SET IsDelete = '1' WHERE Id_Manual ='" +
      data +
      "';";
    const pdata1 = { option: "insertar", texto: query };
    $.ajax({
      data: pdata1,
      cache: false,
      dataType: "json",
      type: "post",
      url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
      success(data, textStatus, jQxhr) {},
      error(jqXhr, textStatus, errorThrown) {},
    })
      .then((response) => {
        this.ngOnInit()
      })
      .catch((err) => {});
  }
  activarManual(data) {
    // console.log('Seleccionado', data, 'Id');
    Swal.fire({
      title: "<strong>¿Está seguro que desea activar este Manual?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        const query =
          "UPDATE db_liwa.Manuals SET IsActive = '1' WHERE Id_Manual ='" +
          data +
          "';";
        const pdata1 = { option: "insertar", texto: query };
        $.ajax({
          data: pdata1,
          cache: false,
          dataType: "json",
          type: "post",
          url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
          success(data, textStatus, jQxhr) {
            console.warn("bien", data, textStatus);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.warn("mal");
          },
        })
          .then((response) => {
            this.ngOnInit()
          })
          .catch((err) => {});
      } else {
      }
    });
  }
  desactivarManual(data) {
    // console.log('Seleccionado', data, 'Id');
    Swal.fire({
      title: "<strong>¿Está seguro que desea desactivar este manual?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        const query =
          "UPDATE db_liwa.Manuals SET IsActive = '0' WHERE Id_Manual ='" +
          data +
          "';";
        const pdata1 = { option: "insertar", texto: query };
        $.ajax({
          data: pdata1,
          cache: false,
          dataType: "json",
          type: "post",
          url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
          success(data, textStatus, jQxhr) {
            console.warn("bien", data, textStatus);
          },
          error(jqXhr, textStatus, errorThrown) {},
        })
          .then((response) => {
           
            this.ngOnInit()
          })
          .catch((err) => {});
      } else {
      }
    });
  }

  // CREATE MODALS
  openFormManual(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-Manual" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openFormQuestion(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-Question" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }
  loadPdf() {
    // this.spinner.show();
    if (this.selectedFile == null || this.selectedFile == undefined) {
      this.alerta.descErr(
        "El archivo no es adecuado, intente nuevamente con un archivo de tipo PDF"
      );
    } else if (
      this.manualName == undefined ||
      this.manualName == "" ||
      this.Id_Product == undefined ||
      this.Id_Product == ""
    ) {
      this.alerta.descErr(
        "El archivo no es adecuado, intente nuevamente con un archivo de tipo PDF"
      );
    } else {
      // console.log('PDF', this.selectedFile);
      let archivoPDF = "";
      this.getBase64(this.selectedFile, (respuesta) => {
        archivoPDF = respuesta;
        // console.log(respuesta);
        const fileName = this.manualName + "_" + moment().unix() + ".pdf";
        const fileBlob = this.selectedFile;
        const finalFile = archivoPDF;
        const data = new FormData();
        // console.log(finalFile, fileBlob);
        data.append("image", finalFile);
        data.append("nombre", fileName);
        data.append("ruta", this.Id_Product);
        $.ajax({
          url: "https://fotos.engenius.com.co/subir_pdf_manual.php", // pendiente
          type: "post",
          dataType: "json",
          data,
          processData: false,
          contentType: false,
        }).then((data1) => {
          // console.log(data, data1);
          this.manualPdfUrl = data1;
          if (
            this.manualPdfUrl == "BADEXT" ||
            this.manualPdfUrl == null ||
            this.manualPdfUrl == undefined ||
            this.manualPdfUrl == "" ||
            this.manualPdfUrl == "NULL"
          ) {
            this.alerta.descErr("Error al subir la foto, verifique nuevamente");
          } else {
            this.GuardarManual();
            this.spinner.hide();
          }
        });
      });
      // nombre del pdf en el servidor this.selectedFile
    }
  }

  getBase64(event, cb) {
    const me = this;
    const file = event;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error);
    };
  }

  GuardarManual() {
    if (this.manualState == true) {
      this.manualState = 1;
    }
    if (this.manualState == false || this.manualState == undefined) {
      this.manualState = 0;
    }
    try {
      // setTimeout(() => {
      if (
        this.manualName == undefined ||
        this.manualName == "" ||
        this.Id_Product == undefined ||
        this.Id_Product == ""
      ) {
        this.alerta.descErr("Verifique nuevamente que no hayan campos vacíos");
      } else {
        const query =
          "INSERT INTO db_liwa.Manuals (Id_Client,ManualName,Id_Product,File,IsDelete,IsActive)" +
          " VALUES ('" +
          this.Id_Client +
          "', '" +
          this.manualName +
          "','" +
          this.Id_Product +
          "','" +
          this.manualPdfUrl +
          "','" +
          0 +
          "','" +
          this.manualState +
          "');";
        const pdata1 = { option: "insertar3", texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          // console.log(response, query);
          if (err == null && (await response) == true) {
            this.route.navigateByUrl("/adm-pqmn");
            this.questionTheme = "";
            this.questionName = "";
            this.questionDesc = "";
            this.questionState = "";
            this.alerta.descValid("El manual se creo con éxito");
            const pdata9 = {
              option: "manuales",
              client: this.global.CLIENTDATALIWA[0],
            };
            this.global.consultar(pdata9, async (err9, response9) => {
              // console.log('Datos Manual', response9);
              this.Manuals = await response9;
              setTimeout(() => {
                $(function () {
                  this.table = $("#TableManual").DataTable({
                    responsive: false,
                    destroy: true,
                    retrieve: true,
                    paging: true,
                    info: false,
                    pagingType: "numbers",
                    language: {
                      url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                    },
                  });
                });
              }, 700);
            });
          } else {
          }
        });
      }
      // }, 400);
    } catch (error) {
      // console.log('error:', +error);
    }
  }

  GuardarQuestion() {
    // this.spinner.show();
    try {
      setTimeout(() => {
        if (
          this.questionName == undefined ||
          this.questionName == "" ||
          this.questionTheme == undefined ||
          this.questionTheme == ""
        ) {
          this.alerta.descErr(
            "Verifique nuevamente que no hayan campos vacíos"
          );
        } else {
          if (this.questionState == true) {
            this.questionState = 1;
          }
          if (this.questionState == false || this.questionState == undefined) {
            this.questionState = 0;
          }
          const query =
            "INSERT INTO db_liwa.Questions (Id_Client,Theme,Question,Answer,IsDelete,IsActive)" +
            " VALUES ('" +
            this.Id_Client +
            "', '" +
            this.questionTheme +
            "','" +
            this.questionName +
            "','" +
            this.questionDesc +
            "','" +
            0 +
            "','" +
            this.questionState +
            "');";
          const pdata1 = { option: "insertar3", texto: query };
          this.global.consultar(pdata1, async (err, response) => {
            // console.log(response, query);
            if (err == null && (await response) == true) {
              this.alerta.descValid("La pregunta se creo con éxito");
              this.spinner.hide();
              this.route.navigateByUrl("/adm-pqmn");
              this.questionTheme = "";
              this.questionName = "";
              this.questionDesc = "";
              this.questionState = "";
              this.ngOnInit();
            }
          });
        }
      }, 400);
    } catch (error) {
      // console.log('error:', +error);
    }
  }

  // END Create MODALS

  // EDIT MODALS

  updtateManual() {
    // console.log(this.editableManual);
    if (this.editableManual[0][6] == true) {
      this.editableManual[0][6] = 1;
    }
    if (
      this.editableManual[0][6] == false ||
      this.editableManual[0][6] == undefined
    ) {
      this.editableManual[0][6] = 0;
    }
    try {
      // setTimeout(() => {
      if (
        this.editableManual[0][2] == undefined ||
        this.editableManual[0][2] == "" ||
        this.editableManual[0][3] == undefined ||
        this.editableManual[0][3] == ""
      ) {
        this.alerta.descErr("Verifique nuevamente que no hayan campos vacíos");
      } else {
        const query =
          "UPDATE db_liwa.Manuals SET ManualName='" +
          this.editableManual[0][2] +
          "',Id_Product ='" +
          this.editableManual[0][3] +
          "',File = '" +
          this.manualPdfUrl +
          "' WHERE Id_Manual='" +
          this.editableManual[0][0] +
          "' ;";
        const pdata1 = { option: "insertar3", texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          // console.log(response, query);
          if (err == null && (await response) == true) {
            this.spinner.hide();
            this.alerta.descValid("El manual se editó con éxito");
            this.route.navigateByUrl("/adm-pqmn");
            this.manualPdfUrl = "";
            this.ngOnInit();
            
          } else {
          }
        });
      }
      // }, 400);
    } catch (error) {
      // console.log('error:', +error);
    }
  }
  loadnewPdf() {
    // this.spinner.show();
    console.log('este')
    if (this.selectedFile == null || this.selectedFile == undefined) {
      this.spinner.hide()
      this.alerta.descErr(
        "No se detecta ningun archivo, intente nuevamente con un archivo de tipo PDF"
      );
    } else if (
      this.editableManual[0][2] == undefined ||
      this.editableManual[0][2] == "" ||
      this.editableManual[0][3] == undefined ||
      this.editableManual[0][3] == ""
    ) {
      this.alerta.descErr(
        "Hay algun campo vacio debes rellenar todos los campos para que sea valido el manual"
      );
      this.spinner.hide()
    } else {
      // console.log('PDF', this.selectedFile);
      let archivoPDF = "";
      this.getBase64(this.selectedFile, (respuesta) => {
        archivoPDF = respuesta;
        // console.log(respuesta);
        const fileName =
          this.editableManual[0][2] + "_" + moment().unix() + ".pdf";
        const fileBlob = this.selectedFile;
        const finalFile = archivoPDF;
        const data = new FormData();
        // console.log(finalFile, fileBlob);
        data.append("image", finalFile);
        data.append("nombre", fileName);
        data.append("ruta", this.editableManual[0][3]);
        $.ajax({
          url: "https://fotos.engenius.com.co/subir_pdf_manual.php", // pendiente
          type: "post",
          dataType: "json",
          data,
          processData: false,
          contentType: false,
        }).then((data1) => {
          // console.log(data, data1);
          this.manualPdfUrl = data1;
          if (
            this.manualPdfUrl == "BADEXT" ||
            this.manualPdfUrl == null ||
            this.manualPdfUrl == undefined ||
            this.manualPdfUrl == "" ||
            this.manualPdfUrl == "NULL"
          ) {
            this.spinner.hide()
            // this.isLoaded = true;
            // this.uploadFlag  = false
            console.log('este2')
            this.alerta.descErr(
              "El archivo no es adecuado, intente nuevamente con un archivo de tipo PDF"
            );
          } else {
            this.updtateManual();
          }
        });
      });
    }
    this.spinner.hide()
  }

  openEditManual(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-editManual" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  editManual(data, content) {
    // console.log('Seleccionado', data, 'Id');
    for (let i = 0; i < this.Manuals.length; i++) {
      if (this.Manuals[i][0] == data) {
        this.editableManual[0] = this.Manuals[i];
      }
    }
    this.openEditManual(content);
  }

  updateQuestion() {
    // console.log(this.editableQuestion);
    try {
      // setTimeout(() => {
      if (
        this.editableQuestion[0][3] == undefined ||
        this.editableQuestion[0][3] == "" ||
        this.editableQuestion[0][2] == undefined ||
        this.editableQuestion[0][2] == ""
      ) {
        this.alerta.descErr("Verifique nuevamente que no hayan campos vacíos");
      } else {
        if (this.questionState == true) {
          this.questionState = 1;
        }
        if (this.questionState == false || this.questionState == undefined) {
          this.questionState = 0;
        }
        const query =
          "UPDATE db_liwa.Questions SET Theme ='" +
          this.editableQuestion[0][2] +
          "',Question ='" +
          this.editableQuestion[0][3] +
          "'   ,Answer = '" +
          this.editableQuestion[0][4] +
          "' ,IsDelete = '" +
          0 +
          "' WHERE Id_Question = '" +
          this.editableQuestion[0][0] +
          "';";
        const pdata1 = { option: "insertar3", texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          // console.log(response, query);
          if (err == null && (await response) == true) {
            this.alerta.descValid("La pregunta se actualizó con éxito");
            this.route.navigateByUrl("/adm-pqmn");
            this.questionTheme = "";
            this.questionName = "";
            this.questionDesc = "";
            this.questionState = "";
            this.ngOnInit();
          }
        });
      }
      // }, 400);
    } catch (error) {
      // console.log('error:', +error);
    }
  }
  openEditQuestion(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-editQuestion" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  // END EDIT MODALS

  editQuestion(data, content) {
    // console.log('Seleccionado', data, 'Id');
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i][0] == data) {
        // console.log(this.Questions[i]);
        this.editableQuestion[0] = this.Questions[i];
        console.table(this.editableQuestion);
      }
    }
    this.openEditQuestion(content);
  }
  productTitle(data) {
    for (let i = 0; i < this.Products.length; i++) {
      if (data == this.Products[i][0]) {
        return this.Products[i][1];
      } else {
        continue;
      }
    }
  }
  eliminaQuestion(data) {
    const query =
      "UPDATE db_liwa.Questions SET IsDelete = '1' WHERE Id_Question ='" +
      data +
      "';";
    const pdata1 = { option: "insertar", texto: query };
    $.ajax({
      data: pdata1,
      cache: false,
      dataType: "json",
      type: "post",
      url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
      success(data, textStatus, jQxhr) {},
      error(jqXhr, textStatus, errorThrown) {},
    })
      .then((response) => {
        this.ngOnInit();
      })
      .catch((err) => {});
  }
  activarQuestion(data) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea Activar la pregunta?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        const query =
      "UPDATE db_liwa.Questions SET IsActive = '1' WHERE Id_Question ='" +
      data +
      "';";
    // console.log('Seleccionado', query);
    const pdata1 = { option: "insertar", texto: query };
    $.ajax({
      data: pdata1,
      cache: false,
      dataType: "json",
      type: "post",
      url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
      success(data, textStatus, jQxhr) {},
      error(jqXhr, textStatus, errorThrown) {},
    })
      .then((response) => {
        this.ngOnInit();
      })
      .catch((err) => {});
      } else {
      }
    });
    
  }
  desactivarQuestion(data) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea Desactivar la pregunta?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        const query =
      "UPDATE db_liwa.Questions SET IsActive = '0' WHERE Id_Question ='" +
      data +
      "';";
    // console.log('Seleccionado', query);
    const pdata1 = { option: "insertar", texto: query };
    $.ajax({
      data: pdata1,
      cache: false,
      dataType: "json",
      type: "post",
      url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
      success(data, textStatus, jQxhr) {},
      error(jqXhr, textStatus, errorThrown) {},
    })
      .then((response) => {
        this.ngOnInit();
      })
      .catch((err) => {});
      } else {
      }
    });
    // console.log('Seleccionado', data, 'Id');
   
  }

  IsActiveView(data) {
    if (data == 1) {
      return "Activado";
    }
    if (data == 0) {
      return "Desactivado";
    }
  }
  //////////// DELETE VALIDATION ///////////////////////////

  deleteVal(data: any) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea eliminar el manual?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminarManual(data);
      } else {
      }
    });
  }
  deleteValQ(data: any) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea eliminar la pregunta?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminaQuestion(data);
      } else {
      }
    });
  }
  //END DELETE VALIDATION //
  uploadRequest() {
    this.uploadFlag = !this.uploadFlag;
  }
  manualAccept() {
    if (this.uploadFlag == true) {
      this.loadnewPdf();
    } else {
      this.updtateNManual();
    }
  }
  updtateNManual() {
    // console.log(this.editableManual);
    if (this.editableManual[0][6] == true) {
      this.editableManual[0][6] = 1;
    }
    if (
      this.editableManual[0][6] == false ||
      this.editableManual[0][6] == undefined
    ) {
      this.editableManual[0][6] = 0;
    }
    try {
      // setTimeout(() => {
      if (
        this.editableManual[0][2] == undefined ||
        this.editableManual[0][2] == "" ||
        this.editableManual[0][3] == undefined ||
        this.editableManual[0][3] == ""
      ) {
        this.alerta.descErr("Verifique nuevamente que no hayan campos vacíos");
      } else {
        const query =
          "UPDATE db_liwa.Manuals SET ManualName='" +
          this.editableManual[0][2] +
          "',Id_Product ='" +
          this.editableManual[0][3] +
          "' WHERE Id_Manual='" +
          this.editableManual[0][0] +
          "' ;";
        const pdata1 = { option: "insertar3", texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          // console.log(response, query);
          if (err == null && (await response) == true) {
            this.spinner.hide();
            this.alerta.descValid("El manual se editó con éxito");
            this.route.navigateByUrl("/adm-pqmn");
            const pdata9 = {
              option: "manuales",
              client: this.global.CLIENTDATALIWA[0],
            };
            // console.log('MANUALES', pdata9);
            this.global.consultar(pdata9, async (err9, response9) => {
              // console.log('Datos Manual', response9);
              this.Manuals = await response9;
              setTimeout(() => {
                $(function () {
                  this.table = $("#TableManual").DataTable({
                    responsive: false,
                    destroy: true,
                    retrieve: true,
                    paging: true,
                    info: false,
                    pagingType: "numbers",
                    language: {
                      url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                    },
                  });
                });
              }, 700);
            });
          } else {
          }
        });
      }
      // }, 400);
    } catch (error) {
      // console.log('error:', +error);
    }
  }
}
